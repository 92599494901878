<template>
  <mosaic-select
    :readonly="props.readonly"
    :min-width="props.minWidth"
    :no-margin="props.noMargin"
    :prepend-icon="props.prependIcon"
    :variant="props.variant"
    :label="props.label"
    :multiple="props.multiple"
    :items="props.items"
    :item-value="props.itemValue"
    :item-title="props.itemTitle"
    :rules="props.rules"
    :hide-details="props.hideDetails"
    :no-items-text="props.noItemsText"
    :dense="props.dense"
    :model-value="props.modelValue"
    @update:model-value="$emit('update:modelValue', $event)"
  >
    <template #custom-selection="{ item }">
      <mosaic-role-completed-chip
        class="pr-1"
        :role-id="item.value"
        :completed="false"
        :role-scoped-tooltip="(role:RoleWithStudent) => role.name"
      />
    </template>
  </mosaic-select>
</template>

<script setup lang="ts">
import MosaicSelect from './MosaicSelect.vue';
import type { RoleWithStudent } from '@/store/map-store';

interface Props {
  readonly?: boolean;
  minWidth?: string;
  noMargin?: boolean;
  prependIcon?: string;
  variant?: string;
  noIcon?: boolean;
  halfWidth?: boolean;
  name?: string;
  label?: string;
  modelValue: unknown;
  multiple?: boolean;
  items?: unknown[];
  itemValue?: string;
  itemTitle?: string;
  rules?: [];
  required?: boolean;
  hideDetails?: boolean;
  dense?: boolean;
  noItemsText?: string;
}

const props = withDefaults(defineProps<Props>(), {
  readonly: false,
  minWidth: 'unset',
  variant: 'underlined',
  noIcon: false,
  halfWidth: false,
  name: '',
  multiple: false,
  items: () => [],
  itemValue: 'value',
  itemTitle: 'text',
  rules: () => [],
  required: false,
  hideDetails: false,
  dense: false,
});

defineEmits(['update:modelValue']);
</script>
