<template>
  <mosaic-loading-and-error-cards :load="load" object-type="Module">
    <div style="width: 100%; height: 99vh; overflow: hidden">
      <iframe
        v-if="response"
        ref="wrapperFrame"
        title="Loading course"
        id="wrapper-frame"
        name="content"
        width="100%"
        height="100%"
        scrolling="no"
        frameborder="0"
        :src="response.url"
        allowfullscreen
        @load="onLoad"
      ></iframe>
    </div>
  </mosaic-loading-and-error-cards>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { useRoute } from 'vue-router';
import { useApi } from '@/composables/api';
import { getAuthToken } from '@/utils/auth';

const route = useRoute();
const api = useApi();

type Response = { url: string; cmiData: Record<string, string> };
const response = ref<Response>();
async function load() {
  const r = await api.get<Response>(`/scorm-packages/${route.params.id}`);
  response.value = r.data;
}

const wrapperFrame = ref<HTMLIFrameElement>();

function onLoad() {
  console.log('onLoad', response.value?.cmiData);
  wrapperFrame.value?.contentWindow?.postMessage(
    {
      token: getAuthToken(),
      //cmiData: response.value?.cmiData,
    },
    new URL(response.value!.url).origin
  );
}
</script>
