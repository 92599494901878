<template>
  <div>
    <v-card v-if="folderTemplate" class="mb-4">
      <v-card-text>
        <div class="mb-2">
          <span class="text-h6">Template</span>
          <span class="pl-2">
            (Cohorts using this template: {{ folderTemplate.cohorts.map(c => c.name).join(', ') || 'none' }})
          </span>
        </div>
        <div class="pb-2">Add folders to the "{{ folderTemplate.name }}" folder structure template here</div>
        <files-list
          v-if="folder"
          :load-items="loadItems"
          files-storage-type="Mosaic"
          :get-file-url="() => {}"
          :can-add-as-evidence="false"
          :can-add-file="false"
          :initial-folder="folder"
          :page-breadcrumbs="breadcrumbs"
        />
      </v-card-text>
    </v-card>
    <v-card v-if="folderTemplate">
      <v-card-text>
        <div class="text-h6 mb-3">Linked Cohorts</div>
        <div v-if="folderTemplate.cohorts.length === 0">Not added to any cohorts</div>
        <mosaic-list v-else :items="folderTemplate.cohorts" density="compact">
          <template #item="{ item: cohort }">
            <mosaic-list-item icon="mdi-google-classroom" :title="cohort.name">
              <template #actions>
                <ndt-icon-button icon="delete" tooltip="Remove from Cohort" @click.prevent="removeFromCohort(cohort)" />
              </template>
            </mosaic-list-item>
          </template>
        </mosaic-list>
        <mosaic-error-alert :override-error-message="error" />
        <div class="py-2 d-flex align-center">
          <v-select
            v-model="selectedCohortId"
            :items="unselectedCohorts"
            item-title="name"
            item-value="id"
            label="Add to Cohort"
            prepend-icon="mdi-google-classroom"
          ></v-select>
          <v-btn class="ml-4" :disabled="processing || !selectedCohortId" @click.prevent="addToCohort()">Add</v-btn>
        </div>
      </v-card-text>
    </v-card>

    <ndt-dialog
      v-model:active="confirmDialog.active"
      title="Confirm Adding to Cohort"
      :error-message="confirmDialog.error"
    >
      <span>This cohort already has a folder template added. Adding this template will override the previous one.</span>
      <template #buttons>
        <v-btn variant="text" ripple :disabled="confirmDialog.processing" @click.prevent="submitAddToCohort()"
          >Confirm</v-btn
        >
      </template>
    </ndt-dialog>
  </div>
</template>

<script>
import FilesList from '../../components/files-list/FilesList.vue';
import { mapGetters } from 'vuex';
import NdtIconButton from '../../components/NdtIconButton.vue';
import NdtDialog from '../../components/NdtDialog.vue';

export default {
  name: 'TutorAdminFolderTemplatePage',
  data: () => ({
    folderTemplate: null,
    folder: null,
    selectedCohortId: null,
    error: null,
    processing: false,
    confirmDialog: {
      active: false,
      processing: false,
      error: '',
    },
  }),
  async created() {
    this.$store.dispatch('loadCohorts');
    this.loadFolderStructure();
  },
  computed: {
    ...mapGetters(['activeCohorts']),
    unselectedCohorts() {
      if (!this.folderTemplate) return this.activeCohorts;
      const selectedCohortIds = this.folderTemplate.cohorts.map(x => x.id);
      return this.activeCohorts.filter(x => !selectedCohortIds.includes(x.id));
    },
    breadcrumbs() {
      return [
        {
          text: 'Folder Structure Templates',
          to: {
            name: 'TutorAdminFolderTemplatesListPage',
          },
        },
        {
          text: this.folderTemplate?.name,
          // This is needed for the FilesList
          to: {
            name: 'TutorAdminFolderTemplatePage',
            params: {
              templateId: this.folderTemplate?.id || -1,
            },
          },
        },
      ];
    },
  },
  methods: {
    async loadFolderStructure() {
      const response = await this.$api.get(`folder-structure-templates/${this.$route.params.templateId}`);
      this.folderTemplate = response.data;
      this.folder = { ...response.data.root_folder, isRootFolder: true };
    },
    async loadItems(folder) {
      const response = await this.$api.get(`/folder-structure-folder-templates/${folder.id}`);

      // Add editable at the API instead
      return {
        items: response.data.child_folders.map(x => ({
          ...x,
          sortName: x.name,
          editable: true,
          icon: {
            name: 'folder',
            color: null,
            tooltip: 'This folder contains files uploaded by your course leads',
          },
          filesApiPath: '/folder-structure-folder-templates',
          type: 'folder',
        })),
        folder: {
          id: folder.id || response.data.id,
          name: folder.name || response.data.name,
          isRootFolder: folder.isRootFolder || false,
          icon: {
            name: 'folder',
            color: null,
            tooltip: '',
          },
          editable: true,
          filesApiPath: '',
          folderApiPath: '/folder-structure-folder-templates',
        },
      };
    },
    async addToCohort() {
      const selectedCohort = this.activeCohorts.find(x => x.id == this.selectedCohortId);
      if (selectedCohort.folder_structure_template_id) {
        this.confirmDialog.active = true;
      } else {
        await this.submitAddToCohort();
      }
    },
    async submitAddToCohort() {
      this.error = null;
      this.confirmDialog.error = null;
      this.processing = true;
      this.confirmDialog.processing = true;
      try {
        await this.$api.post(`/cohorts/${this.selectedCohortId}/folder-structure`, {
          folderStructureTemplateId: this.folderTemplate.id,
        });
        this.selectedCohortId = null;
        this.loadFolderStructure();
        this.confirmDialog.active = false;
        this.$store.dispatch('loadCohorts', { force: true });
      } catch (e) {
        console.log(e);
        this.error = 'Sorry, cannot add to this cohort at the moment';
        this.confirmDialog.error = 'Sorry, cannot add to this cohort at the moment';
      }
      this.processing = false;
      this.confirmDialog.processing = false;
    },
    async removeFromCohort(cohort) {
      this.error = null;
      try {
        await this.$api.delete(`/cohorts/${cohort.id}/folder-structure`);
        this.loadFolderStructure();
        this.$store.dispatch('loadCohorts', { force: true });
      } catch (e) {
        console.log(e);
        this.error = 'Sorry, cannot remove from this cohort at the moment';
      }
    },
  },
  components: { FilesList, NdtIconButton, NdtDialog },
};
</script>
