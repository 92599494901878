<template>
  <v-btn-toggle v-model="modeInternal" color="accent" variant="outlined" divided mandatory>
    <v-btn v-for="m in modes" :key="m.value" class="px-6" :value="m.value">{{ m.label }}</v-btn>
  </v-btn-toggle>
</template>

<script>
import { syncQueryParamsMixin } from '../../mixins/query-mixins';
import { useQueryStore } from '@/stores/query';

export default {
  name: 'MonitoringModeSelect',
  setup() {
    const queryStore = useQueryStore();
    return { queryStore };
  },
  mixins: [syncQueryParamsMixin({ modeInternal: { query: 'mode' } })],
  props: {
    modes: {
      type: Array, // [{ label: string, value: string }]
      required: true,
    },
    mode: {
      type: String,
      required: true,
    },
  },
  emits: ['update:mode'],
  data() {
    return {
      modeInternal: this.mode,
    };
  },
  watch: {
    modeInternal(x) {
      this.$emit('update:mode', x);
    },
  },
};
</script>
