import { defineStore } from 'pinia';
import { mapActions, mapState } from '@/store/map-store';
import { computed } from 'vue';
import { useStore } from './common';

export const useRoleStore = useStore(
  defineStore('role', () => {
    const { roles } = mapState();
    const studentScopedRoles = computed(() => roles.value.filter(r => r.student_scope));

    const roleItems = computed(() => [{ id: -1, name: 'All Roles' }, ...roles.value]);

    const { loadRoles } = mapActions();

    return { roles, studentScopedRoles, roleItems, loadRoles };
  })
);
