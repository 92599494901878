<template>
  <review-role-select
    :label="label"
    :roles="roles"
    :disabled-tooltip="disabledTooltip"
    :is-editing="isEditing"
    @selected-roles-updated="onSelectedRolesUpdated"
  />
</template>

<script setup>
import { computed } from 'vue';
import ReviewRoleSelect from '../ReviewRoleSelect.vue';

const props = defineProps({
  label: { type: String, required: true },
  isEditing: { type: Boolean, required: true },
  isTraineeAnOption: { type: Boolean, default: true },
  canTrainee: { type: Boolean, required: false },
  traineeDisabled: { type: Boolean, required: false },
  reviewRoles: { type: Array, required: true },
  reviewRoleKey: { type: String, required: true },
  roleDisabled: { type: Function, default: r => !r.viewer },
  disabledTooltip: { type: String, default: 'is not a Viewer' },
});

const emit = defineEmits(['updateReviewPermission', 'update:can-trainee']);

const roles = computed(() => {
  const r = [];
  if (props.isTraineeAnOption) {
    r.push({
      roleId: 'student',
      selected: props.canTrainee,
      // canView doesn't make sense in the TutorAdminReviewEditPage (but does in the template pages) as we want to control
      // the disabled state separately from the value (e.g. Contributors)
      canView: true,
      disabled: props.traineeDisabled,
    });
  }
  return r.concat(
    props.reviewRoles.map(r => ({
      roleId: r.id,
      selected: r[props.reviewRoleKey],
      // canView doesn't make sense in the TutorAdminReviewEditPage (see above)
      canView: true,
      disabled: props.roleDisabled(r),
    }))
  );
});

function onSelectedRolesUpdated(roles) {
  for (const r of roles.filter(r => r.roleId !== 'student')) {
    emit('updateReviewPermission', { roleId: r.roleId, value: r.selected });
  }
  if (props.isTraineeAnOption) {
    emit('update:can-trainee', roles.find(r => r.roleId === 'student').selected);
  }
}
</script>
