export function regionalisedYearGroups(region: string): string[] {
  return yearGroupsByRegion.find(x => x.region === region)?.yearGroups || [];
}

interface YearGroupsForRegion {
  region: string;
  yearGroups: string[];
}
const yearGroupsByRegion: YearGroupsForRegion[] = [
  {
    region: 'english',
    yearGroups: [
      'Babies',
      'Toddlers',
      'Pre-School',
      'Reception',
      'Year 1',
      'Year 2',
      'Year 3',
      'Year 4',
      'Year 5',
      'Year 6',
      'Year 7',
      'Year 8',
      'Year 9',
      'Year 10',
      'Year 11',
      'Year 12',
      'Year 13',
      'Other',
    ],
  },
  {
    region: 'australian',
    yearGroups: [
      'Pre-School',
      'Reception',
      'ELC',
      'ELC 0-3',
      'ELC 3-5',
      'Kindergarten',
      'Kaizen',
      'Stage 1',
      'Stage 2',
      'Stage 3',
      'Stage 4',
      'Stage 5',
      'Stage 6',
      'Year 1',
      'Year 2',
      'Year 3',
      'Year 4',
      'Year 5',
      'Year 6',
      'Year 7',
      'Year 8',
      'Year 9',
      'Year 10',
      'Year 11',
      'Year 12',
      'Year 13',
      'Other',
    ],
  },
];
