<template>
  <mosaic-tooltip-chip :color="chip.color">
    <template #text>
      {{ chip.text }}
    </template>

    <template #tooltip>
      {{ chip.tooltip }}
    </template>
  </mosaic-tooltip-chip>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { createAnnouncementStatusChip } from './announcements';
import { mapGetters } from '@/store/map-store';

const props = defineProps<{
  read?: boolean;
}>();

const { traineeNounCapitalised } = mapGetters();

const chip = computed(() => createAnnouncementStatusChip(traineeNounCapitalised.value(), props.read));
</script>
