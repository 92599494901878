<template>
  <div>
    <v-card>
      <v-card-text>
        <full-calendar :events="fullCalendarEvents" @on-event-click="onEventClick($event)" />
      </v-card-text>
    </v-card>

    <ndt-dialog v-model:active="eventDialog.active" :title="eventDialog.title" close-button-text="Close">
      <template v-if="eventDialog.event">
        <h4>When</h4>
        <div>{{ formatDateTime(eventDialog.event) }}</div>
        <div v-if="eventDialog.description" class="mt-4">
          <h4>Details</h4>
          <mosaic-quill :contents="eventDialog.description" :read-only="true" />
        </div>
      </template>
    </ndt-dialog>
  </div>
</template>

<script>
import { DateTime } from 'luxon';
import NdtDialog from '@/components/NdtDialog.vue';
import MosaicQuill from '@/components/quill/MosaicQuill.vue';
import FullCalendar from '@/components/calendar/FullCalendar.vue';

export default {
  name: 'MosaicCalendar',
  components: { NdtDialog, MosaicQuill, FullCalendar },
  props: {
    events: {
      required: true,
      type: Array,
    },
  },
  data: () => ({
    eventDialog: {
      active: false,
      title: '',
      description: null,
    },
  }),
  computed: {
    fullCalendarEvents() {
      return this.events.map(e => {
        return {
          ...e,
          title: e.name,
          backgroundColor: this.$vuetify.theme.global.current.value?.colors['primary-lighten-1'],
          borderColor: this.$vuetify.theme.global.current.value?.colors.primary,
        };
      });
    },
  },
  methods: {
    onEventClick(x) {
      // Deliberately ==
      const event = this.events.find(e => e.id == x.event.id);
      this.eventDialog = {
        ...this.eventDialog,
        active: true,
        title: x.event.title,
        description: event.description || null,
        event: x.event,
      };
    },
    formatDateTime(event) {
      const start = DateTime.fromJSDate(event.start);
      // Event doesn't have an end if it's the same as the start
      const end = DateTime.fromJSDate(event.end || event.start);

      let endString = '';
      if (start.year === end.year && start.month === end.month && start.day === end.day) {
        endString = end.toLocaleString(DateTime.TIME_SIMPLE);
      } else {
        endString = end.toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY);
      }

      return `${start.toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY)} - ${endString}`;
    },
  },
};
</script>
