<template>
  <div>
    <review-template-header>
      <template #configure>
        <v-card>
          <v-card-text>
            <div class="pa-2">
              <div class="text-h6">Configuring "{{ reviewTemplate.name }}" Part 2</div>
              <div>
                Here you can set whether you'd like a Part 2 section to your
                {{ selectedInstitution.config.review_noun }}. This is made up of a set of statements that you'd like the
                {{ traineeNoun() }} to be assessed whether they've met or not (and space for a comment and whether
                concerns have been raised if they've not). You can configure who can edit this information.
              </div>
              <review-template-read-only review-template-in-use tooltip="left" offset="8">
                <template #default="{ disabled }">
                  <v-switch
                    v-model="reviewTemplate.use_part2"
                    label="Use Part 2?"
                    :disabled="disabled"
                    class="pl-2"
                    density="compact"
                    color="primary"
                    hide-details
                    @update:model-value="updateUsePart2"
                  />
                </template>
              </review-template-read-only>

              <div v-if="reviewTemplate.use_part2" class="mt-2">
                <review-role-select
                  :roles="editPermissions"
                  label="Who can make judgements"
                  @selected-roles-updated="rolesEditChanged($event)"
                />
                <div class="text-h6 pt-4 pb-2">Part 2 Statements</div>
                <div>
                  <div v-for="s in sortedStatements" :key="s.id" class="d-flex align-center mb-4">
                    <review-template-read-only review-template-in-use>
                      <template #default="{ disabled }">
                        <v-text-field
                          v-model="s.statement"
                          prepend-icon="mdi-format-list-bulleted-square"
                          hide-details
                          density="compact"
                          :disabled="disabled"
                          @update:focused="x => !x && updateStatement(s, s.statement)"
                        ></v-text-field>
                      </template>
                    </review-template-read-only>

                    <div class="pl-2">
                      <ndt-icon-button
                        icon="delete"
                        :disabled="reviewTemplateInUse"
                        :tooltip="reviewTemplateInUse ? 'Cannot delete as template is in use' : 'Delete Statement'"
                        @click.prevent="deleteStatement(s)"
                      />
                    </div>
                  </div>

                  <div v-if="!reviewTemplateInUse" class="d-flex align-center">
                    <v-text-field
                      v-model="newStatement"
                      prepend-icon="mdi-format-list-bulleted-square"
                      hide-details
                      density="compact"
                      label="New Part 2 Statement"
                    ></v-text-field>

                    <v-btn class="ml-4" :disabled="!newStatement.length" @click.prevent="addStatement()">Add</v-btn>
                  </div>
                </div>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </template>
      <template #preview>
        <review-part-2-card
          v-if="reviewTemplate.use_part2"
          :student-review="reviewTemplateAsStudentReview"
          :staff-viewer="previewUser.staff"
          :student-viewer="previewUser.student"
          :student="previewStudent"
          :review-path="reviewPath"
        />
        <v-card v-else>
          <v-card-text>You are not using Part 2. Enable it via the "Configure" tab.</v-card-text>
        </v-card>
      </template>
    </review-template-header>
  </div>
</template>

<script>
import ReviewPart2Card from '@/components/ReviewPart2Card.vue';
import { mapState, mapGetters } from 'vuex';
import NdtIconButton from '@/components/NdtIconButton.vue';
import ReviewTemplateHeader from '@/components/ReviewTemplateHeader.vue';
import ReviewTemplateReadOnly from '@/components/ReviewTemplateReadOnly.vue';
import ReviewRoleSelect from './ReviewRoleSelect.vue';

export default {
  name: 'TutorAdminReviewTemplatePart2Page',
  data: () => ({
    newStatement: '',
  }),
  async created() {
    await this.$store.dispatch('loadReviewTemplate', { id: this.$route.params.templateId });
  },
  computed: {
    ...mapState(['user', 'reviewTemplate', 'selectedInstitution']),
    ...mapGetters([
      'reviewTemplateRoles',
      'reviewTemplateAsStudentReview',
      'previewUser',
      'previewStudent',
      'reviewNounCapitalised',
      'reviewTemplateInUse',
      'createPermissionsArray',
    ]),
    reviewPath() {
      return {
        text: this.reviewTemplate?.name,
        to: {
          name: 'TutorAdminReviewTemplatePage',
        },
      };
    },
    breadcrumbs() {
      return [
        {
          text: `${this.reviewNounCapitalised} Templates`,
          to: {
            name: 'TutorAdminReviewTemplatesListPage',
          },
        },
        this.reviewPath,
        {
          text: 'Part 2',
        },
      ];
    },
    sortedStatements() {
      return [...this.reviewTemplate.review_template_part2_statements].sort((a, b) => (a.order > b.order ? 1 : -1));
    },
    editPermissions() {
      return this.createPermissionsArray('Part2');
    },
  },
  methods: {
    updateUsePart2(x) {
      this.$store.dispatch('updateReviewTemplate', { use_part2: x });
    },
    addStatement() {
      this.$store.dispatch('addReviewTemplatePart2Statement', {
        statement: this.newStatement,
      });
      this.newStatement = '';
    },
    deleteStatement(x) {
      this.$store.dispatch('deleteReviewTemplatePart2Statement', { id: x.id });
    },
    updateStatement(x, statement) {
      this.$store.dispatch('updateReviewTemplatePart2Statement', { id: x.id, statement });
    },
    rolesEditChanged(roles) {
      this.$store.dispatch('updateReviewTemplatePermissions', {
        roles: roles.map(role => ({
          roleId: role.roleId,
          edit: role.selected,
          permission: 'Part2',
        })),
      });
    },
  },
  components: { ReviewPart2Card, ReviewTemplateHeader, NdtIconButton, ReviewTemplateReadOnly, ReviewRoleSelect },
};
</script>
