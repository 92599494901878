<template>
  <v-select
    v-bind="$attrs"
    v-model="internalSelectedColor"
    label="Color"
    class="pl-4"
    :items="colors"
    hide-details
    item-title="name"
    item-value="hex"
  >
    <template #item="{ item, props }">
      <v-list-item v-bind="props" title="">
        <div :style="{ color: item.raw.hex }">{{ item.raw.name }}</div>
      </v-list-item>
    </template>
    <template #selection="{ item }">
      <div :style="{ color: item.raw.hex }">{{ item.raw.name }}</div>
    </template>
  </v-select>
</template>

<script>
export default {
  props: {
    colors: {
      type: Array,
      required: true,
    },
    selectedColor: {
      type: String,
      required: true,
    },
  },
  emits: ['update:selectedColor'],
  data() {
    return {
      internalSelectedColor: this.selectedColor,
    };
  },
  watch: {
    internalSelectedColor(x) {
      this.$emit('update:selectedColor', x);
    },
  },
};
</script>
