<template>
  <div>
    <div v-if="approved">
      This {{ reviewNounCapitalised }} has been approved and can no longer be
      {{ isStudent ? 'submitted for approval' : 'marked as complete' }}
    </div>
    <div v-else-if="!completableNow">
      You can only {{ isStudent ? 'submit for approval' : 'mark as complete' }} from {{ completionWindowInWeeks }} weeks
      before the due date
    </div>
    <div v-else-if="incompleteSections.length > 0">
      <div>You need to complete the following sections:</div>
      <ul>
        <li v-for="item in incompleteSections" :key="item">{{ item }}</li>
      </ul>
    </div>
  </div>
</template>

<script setup lang="ts">
import { mapGetters } from '@/store/map-store';

const { reviewNounCapitalised } = mapGetters();
defineProps<{
  approved: boolean;
  completableNow: boolean;
  completionWindowInWeeks?: number;
  incompleteSections: string[];
  isStudent?: boolean;
}>();
</script>
