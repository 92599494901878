<template>
  <v-card>
    <div class="text-h6 px-8 pt-4 pb-2">Roles and Permissions for {{ selectedInstitution.name }}*</div>
    <div class="px-8 pb-2" v-if="!stateError && !stateProcessing">
      <mosaic-table
        table-id="ap"
        :columns="columns"
        :rows="permissionRows"
        object-type-pluralised="Permissions"
        min-width="90px"
        no-overflow
      />
    </div>
    <div class="pb-4 pl-8">
      * Please contact
      <span class="font-weight-bold">support@penrose.education</span> if you would like to discuss your roles and
      permissions setup.
    </div>
  </v-card>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { mapStateProcessingAndError } from '@/store/map-store';
import MosaicTable from '@/components/monitoring/MosaicTable.vue';

export default {
  name: 'TutorAdminPermissionsPage',
  components: { MosaicTable },
  async created() {
    await this.$store.dispatch('loadRoles');
    await this.$store.dispatch('loadInstitutionFeatureConfiguration');
    await this.$store.dispatch('loadPermissionsForInstitution');
  },
  computed: {
    ...mapState(['roles', 'selectedInstitution']),
    ...mapGetters(['friendlyPermissions', 'friendlyInstitutionFeatureConfiguration']),
    ...mapStateProcessingAndError([
      'loadRoles',
      'loadInstitutionFeatureConfiguration',
      'loadPermissionsForInstitution',
    ]),
    breadcrumbs() {
      return [{ text: `Roles and Permissions` }];
    },
    headers() {
      const headers = [
        {
          key: 'permission',
          label: 'Permission',
        },
        {
          key: 'description',
          label: 'Description',
        },
        'scope',
      ];
      return headers.concat(
        this.roles.map((r, index) => ({
          key: `role_${index}`,
          label: r.name,
          thStyle: 'min-width: 100px;',
        }))
      );
    },
    columns() {
      return [
        {
          name: 'Permission',
          key: 'permission',
          text: r => r.permission,
          prependHelpText: r =>
            r.disabledByFeatureConfigDependency
              ? `This permission is not active because it depends on the "${r.disabledByFeatureConfigDependency.really_friendly_name}" feature config, which is switched off`
              : undefined,
          noFilter: true,
        },
        { name: 'Description', key: 'description', text: r => r.description, noFilter: true },
        {
          name: 'Scope',
          key: 'scope',
          text: r => r.scope,
          noFilter: true,
          helpTextHtml: `<div>Scopes describe what the permission applies to. For example, whether it allows an Instructor</div>
          <div>to perform specific actions for a ${this.traineeNounCapitalised()} or to setup an Institution configuration.</div>`,
        },
        ...this.roles.map(role => ({
          name: role.name,
          key: role.name,
          chip: r => ({
            text: r[role.name] ? 'On' : 'Off',
            color: r[role.name] ? 'green' : 'red',
            value: r[role.name] ? 'on' : 'off',
          }),
        })),
      ];
    },
    permissionRows() {
      const permissionRows = [];
      this.friendlyPermissions.forEach(p => {
        const disabledByFeatureConfigDependency = this.disabledByFeatureConfigDependency(p);
        const permissionRow = {
          permission: p.really_friendly_name,
          description: p.really_friendly_description,
          scope: this.renderScope(p),
          disabledByFeatureConfigDependency,
          disabled: !!disabledByFeatureConfigDependency,
        };
        this.roles.forEach(r => {
          permissionRow[r.name] = this.hasPermission(r, p);
        });
        permissionRows.push(permissionRow);
      });
      return permissionRows;
    },
  },
  methods: {
    hasPermission(role, permission) {
      return role.permissions.some(p => p.id === permission.id);
    },
    disabledByFeatureConfigDependency(permission) {
      if (!permission.feature_config_dependency_id) return null;
      return this.friendlyInstitutionFeatureConfiguration
        .filter(f => !f.switched_on)
        .find(f => f.id === permission.feature_config_dependency_id);
    },
    renderScope(permission) {
      if (permission.institution_scope && !permission.cohort_scope && !permission.student_scope)
        return 'Institution setup';
      if (permission.institution_scope && permission.cohort_scope && !permission.student_scope) return 'Cohort actions';
      if (permission.institution_scope && permission.cohort_scope && permission.student_scope)
        return `All or specific ${this.traineeNounCapitalisedAndPluralised}`;
      if (!permission.institution_scope && !permission.cohort_scope && permission.student_scope)
        return `Specific ${this.traineeNounCapitalisedAndPluralised}`;
    },
  },
};
</script>
