<template>
  <mosaic-card>
    <mosaic-card-title class="mb-2">{{ teachingStrategy.name }}</mosaic-card-title>
    <mosaic-content-layout
      :content-layout="teachingStrategy.contentLayout"
      get-resource-url-prefix="/curriculum-statements/teaching-strategies/resources"
      :comments="[]"
      :tasks="[]"
    />
  </mosaic-card>
</template>

<script setup lang="ts">
import type { CurriculumTeachingStrategy } from '@/stores/curriculum';
import MosaicContentLayout from '@/components/mosaic-content-layout/MosaicContentLayout.vue';

const props = defineProps<{
  teachingStrategy: CurriculumTeachingStrategy;
}>();

if (props.teachingStrategy.strategyType === 'external_link') {
  throw 'The CurriculumTeachingStrategyCard does not display external links';
}
</script>
