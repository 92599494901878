<template>
  <mosaic-save-dialog
    :active="active"
    @update:active="emit('update:active', $event)"
    title="Edit Page"
    object-type="Page"
    :save="saveEditSection"
    :can-save="canEditSection"
    @save="updateCourseSectionDetails()"
  >
    <template #default="{ onKeyupEnter }">
      <mosaic-text-field
        v-model="name"
        name="update-section-name"
        label="Name"
        prepend-icon="mdi-pencil"
        @keyup.enter="onKeyupEnter"
      />
      <div class="d-flex align-center">
        <div class="flex-grow-1 mr-2">
          <mosaic-select
            v-model="subjectId"
            :disabled="published"
            name="update-section-subject"
            label="Subject"
            :items="subjects"
            item-title="name"
            item-value="id"
            prepend-icon="mdi-pencil-ruler"
            ><template #item="{ item, props: slotProps }">
              <v-divider v-if="item.raw.divider" />
              <v-list-item v-else v-bind="slotProps" :title="item.raw.name" /> </template
          ></mosaic-select>
        </div>
        <mosaic-help v-if="published"
          >Page Subjects cannot be edited for published {{ cohortCourseWeekNounCapitalisedAndPluralised }} as this would
          interfere with visibility for {{ traineeNounPluralised }}.</mosaic-help
        >
      </div>
      <mosaic-alert type="warning" v-if="sectionCurriculumStatementSubjectConflict"
        >This Page contains a Subject-specific Curriculum Link that is incompatible with the Subject selected for this
        Page. This is not allowed as it would prevent Users from ever seeing the Curriculum Link</mosaic-alert
      >
      <mosaic-alert type="warning" v-else-if="savedSectionCurriculumStatementSubjectConflict"
        >This Page has unsaved changes. The saved version of the Page contains a Subject-specific Curriculum Link that
        is incompatible with the selected Subject. This is not allowed as it would prevent Users from ever seeing the
        Curriculum Link. Please save your changes to the Page before editing the Subject</mosaic-alert
      ></template
    >
  </mosaic-save-dialog>
</template>

<script setup lang="ts">
import { useApi } from '@/composables/api';
import { computed, watchEffect } from 'vue';
import { ref } from 'vue';
import {
  sectionCurriculumSubjectConflict,
  type WeekSection,
  useCohortCourseWeekStore,
  useCohortCourseStore,
} from '@/stores/cohort-course';
import type { ContentLayout } from '@/utils/content-layout';
import { mapGetters, mapActions, mapState } from '@/store/map-store';
import { useStudentStore } from '@/stores/student';

const { traineeNounPluralised } = useStudentStore();

const { subjectsWithNullOptionAndPrimarySeparation: subjects } = mapGetters();
const { loadSubjects } = mapActions();
const { curriculumStatements } = mapState();

const { selectedCohortCourse, cohortCourseWeekNounCapitalisedAndPluralised } = useCohortCourseStore();
const { week } = useCohortCourseWeekStore();
loadSubjects();

// props.contentLayout is the bound contentLayout on contentLayout builder so may include unsaved state
// props.section.contentLayout is the saved state
const props = defineProps<{
  contentLayout?: ContentLayout;
  active: boolean;
  section: WeekSection;
  published?: boolean;
  isTemplateSection?: boolean;
}>();

const emit = defineEmits<{
  'update:active': [active: boolean];
  save: [void];
}>();

const api = useApi();

const name = ref('');
const subjectId = ref<number | null>(null);
watchEffect(() => {
  name.value = props.section.title;
  subjectId.value = props.section.subjectId;
});

const sectionCurriculumStatementSubjectConflict = computed(() =>
  sectionCurriculumSubjectConflict(
    props.contentLayout || props.section.contentLayout,
    curriculumStatements.value,
    subjectId.value
  )
);

const savedSectionCurriculumStatementSubjectConflict = computed(() =>
  sectionCurriculumSubjectConflict(props.section.contentLayout, curriculumStatements.value, subjectId.value)
);

const canEditSection = computed(() => {
  const s = props.section;
  return (
    !!name.value &&
    (name.value !== s.title || subjectId.value !== s.subjectId) &&
    !sectionCurriculumStatementSubjectConflict.value &&
    !savedSectionCurriculumStatementSubjectConflict.value
  );
});

function updateCourseSectionDetails() {
  if (props.isTemplateSection) {
    updateTemplateSection();
  } else {
    updateSection();
  }
}

function updateSection() {
  const section = week.value?.cohortCourseWeekSections.find(s => s.id === props.section.id);
  if (section) {
    section.title = name.value;
    section.subjectId = subjectId.value;
    section.subjectName = subjects.value.find(s => s.id === subjectId.value)?.name || '';
  }
}

function updateTemplateSection() {
  const section = selectedCohortCourse.value?.cohortCourseWeekTemplateSections.find(s => s.id === props.section.id);
  if (section) {
    section.title = name.value;
    section.subjectId = subjectId.value;
    section.subjectName = subjects.value.find(s => s.id === subjectId.value)?.name || '';
  }
}
async function saveEditSection() {
  const url = props.isTemplateSection
    ? `/cohort-course/week-template-sections/${props.section.id}/details`
    : `/cohort-course-weeks/sections/${props.section.id}/details`;
  await api.put(url, {
    title: name.value,
    subjectId: subjectId.value,
  });

  emit('update:active', false);
}
</script>
