import moment from 'moment';
import { formatDate } from '../mixins/global-mixins';

export function createPlacementsDateItems(placements) {
  return [
    { title: 'Today', value: moment().format('YYYY-MM-DD') },
    {
      title: 'Custom Date',
      value: 'other',
    },
    ...placements.map(x => ({
      title: `Start of ${x.name} (${formatDate(x.start_date)})`,
      value: x.start_date,
    })),
  ];
}

export function createFuturePlacementsDateItems(placements) {
  return createPlacementsDateItems(placements.filter(x => x.end_date > moment().toISOString()));
}
