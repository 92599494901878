<template>
  <assignments-list
    url="/student-assignments"
    :click-student-assignment-to="
      sa => ({ name: 'StudentAssignmentLatestVersionPage', params: { id: sa.assignment.id.toString() } })
    "
    :can-edit-evidence="showEvidence"
  />
</template>

<script setup lang="ts">
import { setBreadcrumbs } from '@/utils/breadcrumbs';
import AssignmentsList from './AssignmentsList.vue';
import { mapGetters } from '@/store/map-store';

setBreadcrumbs([
  {
    text: 'Course Activities',
  },
]);

const { showEvidence } = mapGetters();
</script>
