<template>
  <div>
    <v-card>
      <v-card-text>
        <div class="pa-2">
          <div class="d-flex align-center flex-wrap" style="row-gap: 8px">
            <div class="text-h6">{{ studentReview.name }}</div>
            <div class="pl-4" v-if="!studentReview.approved">
              <mosaic-role-completed-chip
                v-if="studentReview.review.isTraineeContributor"
                class="pr-1"
                role-id="student"
                :completed="!!studentReview.studentSubmittedAt"
                verb="Submitted"
              />
              <mosaic-role-completed-chip
                v-for="role of rolesThatCanComplete"
                :key="role.id"
                class="pr-1"
                :role-id="role.id"
                :completed="!!submission(role)"
              />
            </div>
            <div v-else class="pl-4">
              <v-chip color="secondary">Approved</v-chip>
              <span class="pl-1">This {{ reviewNoun }} has been approved and can no longer be edited</span>
            </div>
            <div class="grow"></div>
            <div
              v-if="!showGuidance && !canMarkAnyAsComplete"
              class="clickable mr-6"
              @click.prevent="showGuidance = !showGuidance"
            >
              Show Guidance
            </div>
            <div class="flex-grow-1" />
            <v-btn
              v-if="allowedToExport"
              :disabled="exportProcessing"
              :loading="exportProcessing"
              @click.prevent="exportReview()"
              >Export</v-btn
            >
          </div>
          <div
            v-if="!studentReview.approved && showGuidance && !canMarkAnyAsComplete && !haveCompletedForAllMyRoles"
            class="guidance-box mx-2 mt-4"
          >
            <div class="pa-4 d-flex align-top">
              <div><v-icon class="pr-4" color="primary">mdi-information</v-icon></div>
              <div style="width: 100%">
                <div class="font-weight-bold">How to complete this {{ reviewNounCapitalised }}</div>
                <div v-if="myRolesThatCanComplete.length > 0">
                  <ol>
                    <li class="guidance-list-item">
                      <div class="d-flex align-center">
                        <div class="pr-2">Click into each section below marked with the badge(s) for your role(s):</div>
                        <mosaic-role-completed-chip
                          class="pl-1"
                          v-for="x in myRolesThatCanComplete"
                          :key="x.name"
                          :role-id="x.id"
                          :completed="false"
                        />
                      </div>
                    </li>
                    <li class="guidance-list-item">
                      Within each section, complete any text boxes or assessments as required.
                    </li>
                    <li class="guidance-list-item">
                      <div class="d-flex align-center">
                        <div class="pr-2">
                          Once each section is complete, you will see the badge(s) change colour, e.g. :
                        </div>
                        <mosaic-role-completed-chip
                          class="pl-1"
                          v-for="x in myRolesThatCanComplete"
                          :key="x.name"
                          :role-id="x.id"
                          :completed="true"
                        />
                      </div>
                    </li>
                    <li class="guidance-list-item">
                      <div class="d-flex align-center">
                        <div class="pr-2">
                          When you have completed sections, you will be able to
                          {{
                            studentViewer
                              ? `submit the ${reviewNoun} for approval`
                              : `mark the ${reviewNoun} as complete`
                          }}
                        </div>
                      </div>
                    </li>
                  </ol>
                </div>
                <div v-else>
                  <div>
                    You have not been assigned any Roles on Mosaic that are required to complete sections of this
                    {{ reviewNoun }}.
                  </div>
                  <div>
                    However, you may have permission to make optional comments, so please do check each section.
                  </div>
                </div>
                <div v-if="allowedToApprove">
                  <div class="font-weight-bold mt-4">Approval</div>
                  <div>
                    Once you have reviewed all sections and are happy everything has been entered correctly you can
                    approve the {{ reviewNoun }} at the bottom of the page.
                  </div>
                  <div>
                    <span class="font-weight-bold">N.B.</span> This will prevent any further edits to the
                    {{ reviewNoun }} by other users.
                  </div>
                </div>
                <div class="d-flex justify-end">
                  <div class="clickable" @click.prevent="showGuidance = !showGuidance">
                    {{ showGuidance ? 'Hide' : 'Show' }} Guidance
                  </div>
                </div>
              </div>
            </div>
          </div>
          <v-alert v-if="canMarkAnyAsComplete && completableNow" density="compact" type="info" class="mt-4">
            It looks like you've completed all required sections of this {{ reviewNoun }}. Don't forget to mark as
            complete (at the bottom of the page), so that your course leads know you're done.
          </v-alert>
          <div class="mt-6">
            <mosaic-card-subheading>Details</mosaic-card-subheading>
            <div class="d-flex align-center py-4 flex-wrap" style="row-gap: 4px">
              <div class="d-flex align-center pl-2 pr-4">
                <mosaic-text-field
                  :readonly="true"
                  name="student-due-date"
                  :label="`${reviewNounCapitalised} due:`"
                  :model-value="formatDate(studentReview.dueDate)"
                  prepend-icon="mdi-calendar-clock"
                  :class="{ 'mr-2': userStaffHasPermissionForSelectedStudent('reviews.edit') }"
                />
                <ndt-icon-button
                  v-if="userStaffHasPermissionForSelectedStudent('reviews.edit')"
                  icon="pencil"
                  tooltip="Edit due date"
                  @click.prevent="$emit('editDueDateClicked')"
                />
                <mosaic-help v-if="studentReview.dueDate != studentReview.review.dueDate"
                  >This due date has been edited for this {{ traineeNounCapitalised() }} since
                  {{ reviewNounCapitalised }} creation</mosaic-help
                >
              </div>
              <div v-if="studentReview.isEarlyCareers" class="d-flex align-center pl-2">
                <mosaic-text-field
                  :readonly="true"
                  name="ect-school"
                  :label="`School where ${reviewNounCapitalised} was assigned`"
                  :model-value="studentReview.ectSchool && studentReview.ectSchool.display_name"
                  :link="
                    studentReview.ectSchool &&
                    (hasPermissionForSchool(userStaff, 'schools.edit', studentReview.ectSchool) ||
                      hasPermissionForSchool(userStaff, 'schools.view', studentReview.ectSchool))
                      ? {
                          name: 'TutorSchoolEditPage',
                          params: {
                            institutionId: selectedInstitution.id,
                            schoolId: studentReview.ectSchool.id,
                          },
                        }
                      : null
                  "
                  prepend-icon="mdi-domain"
                  :class="{ 'mr-2': allowedToEditSchool }"
                />
                <ndt-icon-button
                  v-if="allowedToEditSchool"
                  icon="pencil"
                  tooltip="Edit School"
                  @click.prevent="$emit('editSchoolClicked')"
                />
              </div>
            </div>
            <mosaic-card-subheading>Sections to complete</mosaic-card-subheading>
            <mosaic-list :items="reviewStandards">
              <template #item="{ item: s }">
                <mosaic-list-item
                  :key="s.id"
                  two-line
                  icon="mdi-playlist-edit"
                  :title="s.title"
                  @click="$router.push(s.clickRoute)"
                >
                  <template #information>
                    <div v-if="s.canAddTargets" class="pr-2 d-flex align-center flex-grow-0 flex-shrink-0">
                      <v-tooltip location="top">
                        <template #activator="{ props }">
                          <div v-bind="props">
                            <span>
                              <v-icon>mdi-near-me</v-icon>
                            </span>
                            <span>{{ s.targetCount }}</span>
                          </div>
                        </template>
                        {{
                          `${s.targetCount === 0 ? 'No' : s.targetCount} target${
                            s.targetCount === 1 ? '' : 's'
                          } recorded`
                        }}
                      </v-tooltip>
                    </div>
                    <div v-else></div>

                    <div
                      v-if="statementJudgementCounts(s).length > 0"
                      class="pl-2 d-flex align-center flex-grow-0 flex-shrink-0"
                    >
                      <template v-for="c in statementJudgementCounts(s)" :key="c.id">
                        <v-tooltip v-if="c.count !== null" location="top">
                          <template #activator="{ props }">
                            <v-chip :color="c.color" class="ml-1" v-bind="props">{{ c.count }}</v-chip>
                          </template>
                          <span v-if="c.id"
                            >{{ c.count }} statement{{ c.count === 1 ? '' : 's' }} marked as {{ c.descriptor }}</span
                          >
                          <span v-else>{{ c.count }} statement{{ c.count === 1 ? '' : 's' }} not filled in</span>
                        </v-tooltip>
                        <div v-else style="width: 36px"></div>
                      </template>
                    </div>
                    <div v-else style="width: 44px"></div>

                    <div
                      class="ml-2 jquery-judgement-chip d-flex justify-center"
                      :style="{
                        minWidth: maxJudgementChipWidth || 'default',
                      }"
                    >
                      <v-tooltip
                        v-if="s.id === 'overall' && s.judgementDescriptor && canViewOverallJudgement"
                        location="top"
                      >
                        <template #activator="{ props }">
                          <v-chip :color="s.judgementDescriptor.color" v-bind="props">
                            {{ s.judgementDescriptor.descriptor }}
                          </v-chip>
                        </template>
                        <span v-if="s.judgementDescriptor.id"
                          >Overall assessment of progress marked as {{ s.judgementDescriptor.descriptor }}</span
                        >
                        <span v-else>Overall assessment of progress has not been set</span>
                      </v-tooltip>

                      <v-tooltip v-if="s.id !== 'overall' && s.judgementDescriptor && canViewJudgement" location="top">
                        <template #activator="{ props }">
                          <v-chip :color="s.judgementDescriptor.color" v-bind="props">
                            {{ s.judgementDescriptor.descriptor }}
                          </v-chip>
                        </template>
                        <span v-if="s.judgementDescriptor.id">
                          {{ traineeNounCapitalised() }} marked as {{ s.judgementDescriptor.descriptor }} for this
                          {{ standardNoun }}
                        </span>
                        <span v-else>{{ standardNounCapitalised }} assessment of progress has not been set</span>
                      </v-tooltip>
                    </div>

                    <div v-if="!studentReview.approved" class="pl-1 d-flex align-center">
                      <div
                        v-for="x in s.roleCompletions"
                        :key="x.name"
                        :style="{ minWidth: roleCompletedChipWidths[x.roleId] || '0' }"
                        :class="`d-flex justify-end ml-1 jquery-completed-chip-${x.roleId}`"
                      >
                        <mosaic-role-completed-chip v-if="x.required" :role-id="x.roleId" :completed="!!x.completed" />
                      </div>
                    </div>
                  </template>
                </mosaic-list-item>
              </template>
            </mosaic-list>
          </div>
        </div>
        <v-alert density="compact" class="mb-4 mx-4" v-if="error" type="error">{{ error }}</v-alert>
        <v-alert v-if="!completableNow" density="compact" class="mb-4 mx-4" type="info"
          >This {{ reviewNounCapitalised }} can only be {{ studentViewer ? 'submitted' : 'marked as complete' }} from
          {{ studentReview.review.completionWindowInWeeks }} weeks before the due date.
        </v-alert>
        <div class="d-flex flex-wrap px-4 text-subtitle-2">
          <div
            v-if="studentReview.review.isTraineeContributor"
            :style="{ 'flex-basis': smallScreen ? '100%' : '50%' }"
            class="pb-2"
          >
            <div>{{ traineeNounCapitalised() }} Submission</div>
            <div class="d-flex align-center">
              <div v-if="studentReview.studentSubmittedAt" class="px-3 text-button bg-primary rounded">
                Submitted for approval ({{ formatDate(studentReview.studentSubmittedAt) }})
              </div>

              <div v-else-if="allowedToSubmit">
                <mosaic-disabled-tooltip
                  :disabled="!canSubmit || !completableNow || submitProcessing"
                  :loading="submitProcessing"
                  :tooltip-list="completableNow ? incompleteSections() : []"
                  :tooltip-list-before-text="'You need to complete the following sections:'"
                  :tooltip="`You only submit from ${studentReview.review.completionWindowInWeeks} weeks before the due date`"
                >
                  <template #default="{ disabled }">
                    <mosaic-btn :disabled="disabled" :loading="submitProcessing" @click.prevent="processSubmission()">
                      {{ studentReview.approvedAt ? 'Not Submitted for Approval' : 'Submit for Approval' }}
                    </mosaic-btn>
                  </template>
                  <template #tooltip>
                    <mosaic-mark-as-complete-disabled-tooltip-message
                      :approved="studentReview.approved"
                      :completable-now="completableNow"
                      :completion-window-in-weeks="studentReview.review.completionWindowInWeeks"
                      :incomplete-sections="incompleteSections()"
                      is-student
                    />
                  </template>
                </mosaic-disabled-tooltip>
              </div>

              <div v-else class="px-3 text-button bg-grey rounded">
                {{ studentReview.approvedAt ? 'Not Submitted for Approval' : 'Pending Submission' }}
              </div>
              <v-btn
                :disabled="!studentReview.studentSubmittedAt || unsubmitProcessing"
                :loading="unsubmitProcessing"
                v-if="studentReview.studentSubmittedAt && allowedToSubmit && !isApproved() && !submitProcessing"
                class="px-3 ml-2 text-button rounded"
                @click.prevent="processSubmission(true)"
              >
                Undo
              </v-btn>
            </div>
          </div>
          <div
            v-for="role of rolesThatCanComplete"
            :key="role.id"
            :style="{ 'flex-basis': smallScreen ? '100%' : '50%' }"
            class="pb-2"
          >
            <div>{{ role.name }} Completion</div>
            <div class="d-flex align-center">
              <div
                v-if="submission(role)"
                class="px-3 text-button bg-primary rounded"
                :style="`color: ${selectedInstitution.config.early_careers ? 'white' : 'rgba(0,0,0,.6)'}`"
              >
                Marked as Complete ({{ formatDate(getSubmittedAt(role)) }})
              </div>

              <div v-else-if="allowedToComplete(role)">
                <mosaic-disabled-tooltip
                  :disabled="
                    !canMarkAsComplete(role, markAsCompleteProcessing) ||
                    !completableNow ||
                    markAsCompleteProcessing[role.id]
                  "
                  :loading="markAsCompleteProcessing[role.id]"
                >
                  <template #tooltip>
                    <mosaic-mark-as-complete-disabled-tooltip-message
                      :approved="studentReview.approved"
                      :completable-now="completableNow"
                      :completion-window-in-weeks="studentReview.review.completionWindowInWeeks"
                      :incomplete-sections="incompleteSections(role)"
                    />
                  </template>
                  <template #default="{ disabled }">
                    <mosaic-btn
                      :disabled="disabled"
                      @click.prevent="markAsComplete(role)"
                      :loading="markAsCompleteProcessing[role.id]"
                    >
                      {{ studentReview.approvedAt ? 'Not Marked as Complete' : 'Mark as Complete' }}
                    </mosaic-btn>
                  </template>
                </mosaic-disabled-tooltip>
              </div>

              <div v-else class="px-3 text-button bg-grey rounded">
                {{ studentReview.approvedAt ? 'Not Marked as Complete' : 'Pending Completion' }}
              </div>

              <v-btn
                v-if="
                  allowedToComplete(role) && submission(role) && !isApproved() && !markAsCompleteProcessing[role.id]
                "
                :disabled="!getSubmittedAt(role) || unmarkCompletedProcessing[role.id]"
                :loading="unmarkCompletedProcessing[role.id]"
                class="px-3 ml-2 text-button rounded"
                @click.prevent="unmarkCompleted(role)"
              >
                Undo
              </v-btn>
            </div>
          </div>
        </div>

        <v-divider class="my-2" />
        <div class="d-flex justify-end align-center">
          <div class="flex-grow-1" />
          <div class="mx-4 my-2">
            <div class="pb-2">{{ reviewNounCapitalised }} Approval</div>
            <div class="d-flex align-center">
              <div class="d-flex align-center mr-2">
                <div v-if="studentReview.approvedAt" class="d-flex">
                  <div class="text-button bg-primary rounded px-3">
                    Approved ({{ formatDate(studentReview.approvedAt) }})
                  </div>

                  <v-btn v-if="allowedToApprove" :disabled="!canApprove" class="ml-3" @click.prevent="unapprove()"
                    >Unapprove</v-btn
                  >
                </div>
                <v-btn v-else-if="allowedToApprove" :disabled="!canApprove" class="ml-1" @click.prevent="approve()"
                  >Approve</v-btn
                >
                <div v-else class="px-3 text-button bg-grey rounded">Pending approval</div>
              </div>

              <mosaic-help
                >Approval of {{ reviewNounCapitalisedAndPluralised }} is managed by
                {{
                  listItems(
                    studentReview.review.roleIdsThatCanApprove.map(id => pluralise(getRoleNameFromId(roles, id)))
                  )
                }}
                - Approved {{ reviewNounCapitalisedAndPluralised }} cannot be edited</mosaic-help
              >
            </div>
          </div>
        </div>
        <mosaic-snackbar v-model="snackbar.active" :color="snackbar.color" :message="snackbar.message" />
      </v-card-text>
    </v-card>
    <mosaic-mark-as-complete-dialog
      v-model:active="markAsCompleteDialog.active"
      :roles-that-can-complete="myRolesThatCanMarkAsComplete"
      :student-viewer="studentViewer"
      :staff-viewer="staffViewer"
      :next="markAsCompleteDialog.next"
      :student-review-id="studentReview.id"
    ></mosaic-mark-as-complete-dialog>
  </div>
</template>

<script>
import { roleHasPermission } from '@/utils/student-review';
import { mapState, mapGetters } from 'vuex';
import NdtIconButton from './NdtIconButton.vue';
import MosaicMarkAsCompleteDialog from './library/dialogs/reviews/MosaicMarkAsCompleteDialog.vue';
import MosaicMarkAsCompleteDisabledTooltipMessage from './reviews/MosaicMarkAsCompleteDisabledTooltipMessage.vue';
import { studentEditedStandardStatementJudgements } from '@/utils/student-review';
import moment from 'moment';
import { listItems } from '../utils/text';
import { getRoleNameFromId } from '../utils/role';
import { DateTime } from 'luxon';
import $ from 'jquery';
import MosaicDisabledTooltip from './library/helpers/MosaicDisabledTooltip.vue';
export default {
  name: 'ReviewCard',
  components: {
    NdtIconButton,
    MosaicMarkAsCompleteDialog,
    MosaicDisabledTooltip,
    MosaicMarkAsCompleteDisabledTooltipMessage,
  },
  props: {
    /*
    review: {
      name: string;
      reviewStandards: [
        { id: number; title: string; clickRoute: { name: string; params: object; query: object; } }
      ]
    }
    */
    studentReview: {
      type: Object,
      required: true,
    },
    staffViewer: {
      type: Object,
      default: null,
    },
    studentViewer: {
      type: Object,
      default: null,
    },
    student: {
      type: Object,
      required: true,
    },
    allowedToEditSchool: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['editDueDateClicked', 'editSchoolClicked'],
  data: () => ({
    snackbar: {
      active: false,
      color: 'success',
      message: '',
    },
    error: null,
    approveProcessing: false,
    submitProcessing: false,
    unsubmitProcessing: false,
    exportProcessing: false,
    markAsCompleteProcessing: {},
    unmarkCompletedProcessing: {},
    showGuidance: true,
    markAsCompleteDialog: {
      active: false,
      next: null,
      afterSave: true,
    },
    listItems: listItems,
    getRoleNameFromId: getRoleNameFromId,
    maxJudgementChipWidth: null,
    roleCompletedChipWidths: {},
  }),
  async created() {
    if (this.studentReview.review.usePart2) {
      if (this.studentReview.review.useStPhilipsLegacyPart2JudgementSet) {
        await this.$store.dispatch('loadStPhilipsLegacyPart2JudgementSet');
      } else {
        await this.$store.dispatch('loadPart2JudgementSet');
      }
    }
    if (this.completableNow && this.canMarkAnyAsComplete && this.studentReviewPages.showCompletedModal) {
      this.markAsCompleteDialog = {
        active: true,
        next: null,
        afterSave: true,
      };
    }
  },
  mounted() {
    const judgementChipWidths = $.map($('.jquery-judgement-chip'), el => $(el).width());
    this.maxJudgementChipWidth = Math.max(...judgementChipWidths) + 'px';

    ['student', ...this.studentReview.review.roleIdsThatAreContributors].forEach(roleId => {
      const completedChipWidths = $.map($(`.jquery-completed-chip-${roleId}`), el => $(el).width());
      this.roleCompletedChipWidths[roleId] = Math.max(...completedChipWidths) + 'px';
    });
  },
  computed: {
    ...mapState([
      'roles',
      'selectedInstitution',
      'userStaff',
      'part2JudgementSet',
      'stPhilipsLegacyPart2JudgementSet',
      'studentReviewPages',
    ]),
    ...mapGetters(['reviewNoun', 'institutionNoun']),
    canApprove() {
      return !this.approveProcessing;
    },
    completableNow() {
      return (
        !this.studentReview.review.completionWindowInWeeks ||
        moment(this.studentReview.dueDate).isBefore(
          moment().add(this.studentReview.review.completionWindowInWeeks, 'weeks')
        )
      );
    },
    allowedToApprove() {
      if (this.studentViewer) return false;
      return roleHasPermission(this.studentReview.review.roleIdsThatCanApprove, this.staffViewer, this.student);
    },
    allowedToExport() {
      if (this.studentViewer) return this.selectedInstitution.config.students_can_export_review_pdfs;
      return this.userStaffHasPermissionForSelectedStudent('reviews.exportToPdf');
    },
    canSubmit() {
      const completedSJ = !this.studentViewer || studentEditedStandardStatementJudgements(this.studentReview);
      const completedRS = this.studentReview.reviewStandards.every(rs => {
        return rs.completed.filter(x => x.trainee).every(x => x.completed);
      });
      return completedSJ && completedRS && !this.submitProcessing && !this.studentReview.approved;
    },
    canViewOverallJudgement() {
      if (!this.studentViewer) return true;
      return this.studentReview.review.canTraineesViewOverallJudgement;
    },
    canViewJudgement() {
      if (!this.studentViewer) return true;
      return this.studentReview.review.canTraineesViewJudgements;
    },
    allowedToSubmit() {
      return this.studentViewer;
    },
    rolesThatCanComplete() {
      return this.roles.filter(x => this.studentReview.review.roleIdsThatAreContributors.includes(x.id));
    },
    myRolesThatCanComplete() {
      if (this.studentViewer)
        return [
          {
            completed: false,
            name: this.traineeNounCapitalised(),
            id: 'student',
            trainee: true,
          },
        ];
      else {
        return this.rolesThatCanComplete.filter(x =>
          this.staffViewer.staff_roles
            .filter(
              sr =>
                (sr.student_id && sr.student_id === this.student.id) ||
                (sr.institution_id && sr.institution_id === this.student.institution_id) ||
                (sr.cohort_id && sr.cohort_id === this.student.cohort.id)
            )
            .map(sr => sr.role.id)
            .includes(x.id)
        );
      }
    },
    myRolesThatCanMarkAsComplete() {
      return this.myRolesThatCanComplete.filter(
        r =>
          this.allowedToComplete(r) && !this.submission(r) && this.canMarkAsComplete(r, this.markAsCompleteProcessing)
      );
    },
    canMarkAnyAsComplete() {
      if (this.allowedToSubmit && this.studentReview.review.isTraineeContributor) {
        return !this.studentReview.studentSubmittedAt && this.canSubmit;
      }
      return this.myRolesThatCanMarkAsComplete.length > 0;
    },
    haveCompletedForAllMyRoles() {
      return this.myRolesThatCanComplete.every(r => this.submission(r));
    },
    reviewStandards() {
      return this.studentReview.reviewStandards.map(rs => {
        const roleCompletions = ['student', ...this.studentReview.review.roleIdsThatAreContributors].map(roleId => {
          const canCompleteThisStandard = rs.completed.find(c => c.roleId === roleId);
          return { ...(canCompleteThisStandard || { roleId }), required: !!canCompleteThisStandard };
        });
        return { ...rs, roleCompletions };
      });
    },
  },
  methods: {
    statementJudgementCounts(reviewStandard) {
      // move this to student-reviews.js?
      let statements,
        judgementSet = null;
      let descriptors = [];
      const notSetDescriptor = {
        color: 'secondary',
        descriptor: 'Not Recorded',
        id: null,
        order: -1,
      };
      if (reviewStandard.id === 'overall') {
        statements = this.studentReview.overallStatementGroups
          .map(x => x.statements)
          .flat()
          .filter(this.canViewOverallStatement);
        judgementSet = this.studentReview.review.overallStatementJudgementSet;
        if (!judgementSet) return [];
        const descriptorsInUse = statements.map(x => x.judgementDescriptorId).unique();
        descriptors = judgementSet.institutionJudgementDescriptors
          .concat([notSetDescriptor])
          .filter(x => descriptorsInUse.includes(x.id));
      } else if (reviewStandard.id === 'part2') {
        statements = this.studentReview.part2Statements;
        judgementSet = this.studentReview.review.useStPhilipsLegacyPart2JudgementSet
          ? this.stPhilipsLegacyPart2JudgementSet
          : this.part2JudgementSet;
        if (!judgementSet) return [];
        const descriptorsInUse = this.studentReview.part2Statements.map(x => x.judgementDescriptorId).unique();
        descriptors = judgementSet.judgement_descriptors
          .concat([notSetDescriptor])
          .filter(x => descriptorsInUse.includes(x.id));
      } else {
        statements = reviewStandard.statementGroups.map(x => x.statements).flat();
        judgementSet = this.studentReview.review.standardStatementJudgementSet;
        if (!judgementSet) return [];
        const descriptorsInUse = this.studentReview.reviewStandards
          .filter(x => x.id !== 'overall' && x.id !== 'part2')
          .map(x => x.statementGroups)
          .flat()
          .map(x => x.statements)
          .flat()
          .map(x => x.judgementDescriptorId)
          .unique();
        descriptors = judgementSet.institutionJudgementDescriptors
          .concat([notSetDescriptor])
          .filter(x => descriptorsInUse.includes(x.id));
      }

      const initialCounts = descriptors.map(x => ({ ...x, count: null }));
      return statements
        .reduce((judgements, statement) => {
          const j = judgements.find(x => x.id === statement.judgementDescriptorId);
          if (j.count === null) {
            j.count = 0;
          }
          j.count += 1;
          return judgements;
        }, initialCounts)
        .sort((a, b) => (a.order > b.order ? 1 : -1));
    },
    canViewOverallStatement(statement) {
      if (this.studentViewer) return statement.canTraineeView;
      return roleHasPermission(statement.roleIdsThatCanView, this.staffViewer, this.student);
    },
    allowedToComplete(role) {
      if (this.studentViewer) return false;
      return roleHasPermission([role.id], this.staffViewer, this.student);
    },
    incompleteSections(role) {
      const incompleteStandards = this.studentReview.reviewStandards
        .filter(x => x.completed.some(c => ((role && c.roleId === role.id) || (!role && c.trainee)) && !c.completed))
        .map(x => (x.id === 'overall' ? x.title : x.id === 'part2' ? 'Part 2' : x.code));
      return incompleteStandards;
    },
    canMarkAsComplete(role, markAsCompleteProcessing) {
      const completedOverallComments = this.studentReview.overallReviewCompleted
        .filter(x => x.roleId === role.id)
        .every(x => x.completed);

      const completedStandards = this.studentReview.reviewStandards.every(rs =>
        rs.completed.filter(x => x.roleId === role.id).every(x => x.completed)
      );

      const completedPart2 =
        !this.studentReview.review.usePart2 ||
        !this.studentReview.review.roleIdsThatCanEditPart2.includes(role.id) ||
        this.studentReview.part2Statements.every(s => s.judgementDescriptorId);

      return (
        completedOverallComments &&
        completedStandards &&
        completedPart2 &&
        !markAsCompleteProcessing[role.id] &&
        !this.studentReview.approved
      );
    },
    submission(role) {
      if (role.id == 'student') return !!this.studentReview.studentSubmittedAt;
      return !!this.studentReview.submissions.find(x => x.roleId === role.id);
    },
    getSubmittedAt(role) {
      return this.studentReview.submissions.find(x => x.roleId === role.id).submittedAt;
    },
    isApproved() {
      return this.studentReview.approved;
    },
    async approve() {
      this.error = null;
      this.approveProcessing = true;
      try {
        await this.$api.post(`student-reviews/${this.studentReview.id}/approve`);
        this.$store.commit('updateStudentReview', { approved_at: DateTime.now().toISO() });
      } catch (e) {
        console.log(e);
        this.error = `Sorry, cannot approve the ${this.reviewNoun} at the moment`;
      }
      this.approveProcessing = false;
    },
    async unapprove() {
      this.error = null;
      this.approveProcessing = true;
      try {
        await this.$api.post(`student-reviews/${this.studentReview.id}/unapprove`);
        this.$store.commit('updateStudentReview', { approved_at: null });
      } catch (e) {
        console.log(e);
        this.error = `Sorry, cannot unapprove the ${this.reviewNoun} at the moment`;
      }
      this.approveProcessing = false;
    },
    async markAsComplete(role) {
      this.error = null;
      this.markAsCompleteProcessing = { ...this.markAsCompleteProcessing, [role.id]: true };
      try {
        await this.$api.post(`student-reviews/${this.studentReview.id}/mark-roles-as-complete`, {
          role_ids: [role.id],
        });
        this.$store.commit('submitStudentReview', { roleIds: [role.id] });
      } catch (e) {
        console.log(e);
        this.error = `Sorry, cannot mark the ${this.reviewNounCapitalised} as complete at the moment`;
      }
      this.markAsCompleteProcessing = { ...this.markAsCompleteProcessing, [role.id]: false };
    },
    async unmarkCompleted(role) {
      this.error = null;
      this.unmarkCompletedProcessing = { ...this.unmarkCompletedProcessing, [role.id]: true };
      try {
        await this.$api.post(`student-reviews/${this.studentReview.id}/unmark-completed-roles`, {
          role_ids: [role.id],
        });
        this.$store.commit('undoSubmissionStudentReview', { roleIds: [role.id] });
      } catch (e) {
        console.log(e);
        this.error = `Sorry, cannot unmark the ${this.reviewNounCapitalised} at the moment`;
      }
      this.unmarkCompletedProcessing = { ...this.unmarkCompletedProcessing, [role.id]: false };
    },
    //submits the review point or reverts it if undo is truthy
    async processSubmission(undo) {
      this.error = null;
      undo ? (this.unsubmitProcessing = true) : (this.submitProcessing = true);
      try {
        await this.$api.post(`student-reviews/${this.studentReview.id}/submit`, {
          undo,
        });
        this.$store.commit('updateStudentReview', { student_submitted_at: undo ? null : DateTime.now().toISO() });
      } catch (e) {
        console.log(e);
        this.error = `Sorry, cannot ${undo ? 'undo the submission of' : 'submit'} your ${
          this.reviewNoun
        } at the moment`;
      }
      undo ? (this.unsubmitProcessing = false) : (this.submitProcessing = false);
    },
    async exportReview() {
      try {
        this.exportProcessing = true;
        const r = await this.$api.post(`/student-reviews/${this.studentReview.id}/export-async`);
        const pollResult = await this.$api.pollGet(r.data.poll_url);
        window.open(pollResult.data.download_url);

        this.exportProcessing = false;
        this.snackbar = {
          active: true,
          color: 'success',
          message: 'Export successful',
        };
      } catch (e) {
        console.log(e);
        this.exportProcessing = false;
        this.snackbar = {
          active: true,
          color: 'error',
          message: `Sorry, could not export this ${this.reviewNounCapitalised} at the moment`,
        };
      }
    },
  },
};
</script>

<style scoped>
.list-item {
  display: contents;
}
.list-item > * {
  height: 100%;
}
.list-item:hover > * {
  background-color: rgb(var(--v-theme-secondary-lighten-1));
  cursor: pointer;
}
.list-item-content {
  padding-left: 16px;
  position: relative;
}
.list-item-background {
  width: 100%;
  height: 100px;
  position: absolute;
  top: 0;
}

.grid {
  display: grid;
  grid-template-columns: 1fr auto auto auto auto 48px 0px;
  grid-template-rows: auto;
  align-items: center;
}

.guidance-box {
  border: 1px solid rgb(var(--v-theme-accent));
  border-radius: 10px;
  width: 95%;
}

.guidance-list-item {
  margin-top: 2px;
}
.clickable {
  text-decoration: underline;
  color: rgb(var(--v-theme-primary));
  cursor: pointer;
}
</style>
