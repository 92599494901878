<template>
  <div>
    <v-card v-if="busy">
      <v-progress-linear v-if="totalEctCount" :model-value="(ectLoadCount / totalEctCount) * 100"></v-progress-linear>
      <v-skeleton-loader type="table-heading, table-thead, table-tbody@3, table-tfoot" />
    </v-card>
    <v-card v-if="error && !busy">
      <v-card-text>
        <div class="pa-4">{{ error }}</div>
      </v-card-text>
    </v-card>
    <div v-if="!error && !busy">
      <v-card class="mb-4">
        <v-card-text>
          <mosaic-card-title
            >ECTs Overview
            <template #actions>
              <template v-if="selectedInstitution.establishment_id">
                <v-btn class="mr-2" @click.prevent="traExports()"
                  ><v-icon class="pr-2">mdi-table-arrow-down</v-icon><span>TRA Exports</span></v-btn
                >
              </template>
              <template v-else>
                <v-tooltip location="bottom">
                  <template #activator="{ props }">
                    <div v-bind="props">
                      <v-btn class="mr-2" :disabled="true">
                        <v-icon class="pr-2">mdi-table-arrow-down</v-icon>
                        <span>Export for TRA</span>
                      </v-btn>
                    </div>
                  </template>
                  <div>TRA data cannot be exported as your Institution is missing an establishment ID.</div>
                  <div>Please contact support to amend this.</div>
                </v-tooltip>
              </template>
              <v-btn class="mr-2" @click.prevent="setDefaultFilters"
                ><v-icon class="pr-2">mdi-filter-off</v-icon><span>Clear Filters</span></v-btn
              >
              <v-btn @click.prevent="addRemoveColumn"
                ><v-icon class="pr-2">mdi-table-column-plus-before</v-icon><span>Add/Remove Columns</span></v-btn
              >
            </template>
          </mosaic-card-title>
          <div class="d-flex align-center">
            <div class="flex-grow-1">
              <mosaic-select
                v-model="preset"
                name="presets"
                hide-details
                label="Presets"
                :items="presetItems"
                no-icon
              />
            </div>
            <div class="flex-grow-1" />
          </div>
        </v-card-text>
      </v-card>

      <v-card>
        <v-card-text>
          <div class="d-flex align-center pb-2">
            <div class="flex-grow-1" />
            <div class="pr-2" :style="{ color: loadTimeColor }">
              Data last refreshed: {{ loadTime.format('MMM Do, h:mm:ss a') }}
              <mosaic-help
                >If you have updated any ECT information recently, it may not show until refreshed</mosaic-help
              >
            </div>
            <v-tooltip location="bottom">
              <template #activator="{ props }">
                <v-btn v-bind="props" @click.prevent="loadEcts(true)"><v-icon>mdi-refresh</v-icon></v-btn>
              </template>
              <div class="text-center">Refresh data</div>
            </v-tooltip>
          </div>
          <v-table>
            <thead>
              <tr>
                <th
                  v-for="h in filteredHeaders"
                  :key="h.key"
                  class="px-2"
                  style="vertical-align: top; height: auto !important; font-size: 14px"
                >
                  <div
                    class="d-flex align-start"
                    :style="{ cursor: !h.sort ? 'default' : 'pointer !important' }"
                    v-on="h.sort ? { click: () => sortByHeader(h) } : {}"
                  >
                    <div class="flex-grow-1">
                      <span>{{ h.name }}</span>
                      <mosaic-help v-if="h.helpText">{{ h.helpText }}</mosaic-help>
                    </div>
                    <div v-if="h.sort">
                      <v-icon v-if="sortBy !== h.key">mdi-swap-vertical</v-icon>
                      <v-icon v-else-if="sortDesc">mdi-arrow-down</v-icon>
                      <v-icon v-else>mdi-arrow-up</v-icon>
                    </div>
                  </div>
                </th>
              </tr>
              <tr>
                <th v-for="h in filteredHeaders" :key="h.key" class="px-2" style="vertical-align: top">
                  <div v-if="h.filter" class="pb-2 d-flex align-top">
                    <v-text-field
                      v-if="h.filter.type === 'text'"
                      hide-details
                      density="compact"
                      :model-value="getFilterValue(h.key, filterValues)"
                      @update:model-value="filterUpdated(h.key, $event)"
                    />
                    <div v-if="h.filter.type === '>='" class="d-flex align-center flex-grow-1">
                      <div class="pr-1 pt-2">&geq;</div>
                      <v-text-field
                        style="width: 40px"
                        class="shrink"
                        type="number"
                        hide-details
                        density="compact"
                        :model-value="getFilterValue(h.key, filterValues)"
                        @update:model-value="filterUpdated(h.key, $event)"
                      />
                    </div>
                    <v-select
                      v-if="h.filter.type === 'select'"
                      class="v-select-auto-width"
                      :items="h.filter.items"
                      hide-details
                      density="compact"
                      :model-value="getFilterValue(h.key, filterValues)"
                      @update:model-value="filterUpdated(h.key, $event)"
                    />
                    <div
                      v-if="h.filter.type === 'induction'"
                      class="flex-grow-1 d-flex flex-wrap align-center"
                      style="row-gap: 16px; column-gap: 8px"
                    >
                      <div class="flex-grow-1">
                        <v-select
                          class="v-select-auto-width"
                          :items="h.filter.items"
                          hide-details
                          density="compact"
                          :model-value="getFilterValue('inductionStatus', filterValues)"
                          @update:model-value="filterUpdated('inductionStatus', $event)"
                        />
                      </div>
                      <div v-if="['year1', 'year2'].includes(getFilterValue('inductionStatus', filterValues))">
                        <mosaic-date-picker
                          hide-details
                          density="compact"
                          :date="getFilterValue('inductionDate', filterValues)"
                          label="Before"
                          :exact-width="true"
                          @update:date="filterUpdated('inductionDate', $event)"
                        />
                      </div>
                    </div>
                    <div
                      v-if="h.filter.type === 'reviews'"
                      class="flex-grow-1 d-flex flex-wrap align-center"
                      style="row-gap: 16px; column-gap: 8px"
                    >
                      <div class="d-flex align-center">
                        <v-select
                          :items="reviewsOperatorItems"
                          class="v-select-auto-width"
                          density="compact"
                          hide-details
                          :model-value="getFilterValue('reviewsOperator', filterValues)"
                          @update:model-value="filterUpdated('reviewsOperator', $event)"
                        />
                      </div>
                      <div class="d-flex align-center">
                        <v-text-field
                          style="width: 40px"
                          class="shrink"
                          type="number"
                          hide-details
                          density="compact"
                          :model-value="getFilterValue('reviewsCount', filterValues)"
                          @update:model-value="filterUpdated('reviewsCount', $event)"
                        />
                      </div>
                      <mosaic-date-picker
                        hide-details
                        density="compact"
                        :date="getFilterValue('reviewsAfter', filterValues)"
                        label="After"
                        :exact-width="true"
                        @update:date="filterUpdated('reviewsAfter', $event)"
                      />
                      <mosaic-date-picker
                        hide-details
                        density="compact"
                        :date="getFilterValue('reviewsBefore', filterValues)"
                        label="Before"
                        :exact-width="true"
                        @update:date="filterUpdated('reviewsBefore', $event)"
                      />
                      <div class="d-flex align-center">
                        <v-select
                          :items="reviewsStatusItems"
                          class="v-select-auto-width"
                          style="min-width: 105px"
                          label="Status"
                          density="compact"
                          hide-details
                          :model-value="getFilterValue('reviewsStatus', filterValues)"
                          @update:model-value="filterUpdated('reviewsStatus', $event)"
                        />
                      </div>
                    </div>
                    <div class="mt-2">
                      <v-icon class="ml-1">mdi-filter</v-icon>
                    </div>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="paginatedEcts.length === 0">
                <td colspan="100" class="text-center">There are no {{ traineeNounPluralised() }} for these filters</td>
              </tr>
              <tr v-for="ect in paginatedEcts" :key="ect.id">
                <td
                  v-for="h in filteredHeaders"
                  :key="`${ect.id}-${h.key}`"
                  class="px-2"
                  :class="{
                    'highlight-cell-on-hover': h.clickRoute && h.clickRoute(ect),
                  }"
                  :style="{ cursor: h.clickRoute && h.clickRoute(ect) ? 'pointer' : 'default' }"
                  v-on="h.clickRoute && h.clickRoute(ect) ? { click: () => $router.push(h.clickRoute(ect)) } : {}"
                >
                  <div
                    v-if="h.text"
                    :class="h.textColor && h.textColor(ect) ? { ['text-' + h.textColor(ect)]: true } : {}"
                    class="d-flex align-center"
                    style="white-space: nowrap"
                  >
                    <span>{{ h.text(ect) }}</span>
                    <v-tooltip v-if="h.trailingTextIcon && h.trailingTextIcon(ect)" location="top">
                      <template #activator="{ props }">
                        <v-icon size="small" class="ml-1" v-bind="props">{{ h.trailingTextIcon(ect).icon }}</v-icon>
                      </template>
                      <span>{{ h.trailingTextIcon(ect).tooltip }}</span>
                    </v-tooltip>
                  </div>
                  <div v-else-if="h.chip && !h.chip(ect).hide">
                    <v-tooltip v-if="h.chip(ect).tooltip" location="top">
                      <template #activator="{ props }">
                        <v-chip
                          :color="h.chip(ect).color"
                          v-on="
                            h.clickRoute && h.clickRoute(ect) ? { click: () => $router.push(h.clickRoute(ect)) } : {}
                          "
                          v-bind="props"
                          ><v-icon v-if="h.chip(ect).icon" :color="h.chip(ect).iconColor">{{ h.chip(ect).icon }}</v-icon
                          ><span v-if="h.chip(ect).text">{{ h.chip(ect).text }}</span></v-chip
                        >
                      </template>
                      <span>{{ h.chip(ect).tooltip }}</span>
                    </v-tooltip>
                    <v-chip
                      v-else
                      :color="h.chip(ect).color"
                      v-on="h.clickRoute && h.clickRoute(ect) ? { click: () => $router.push(h.clickRoute(ect)) } : {}"
                      ><v-icon v-if="h.chip(ect).icon">{{ h.chip(ect).icon }}</v-icon
                      ><span v-if="h.chip(ect).text">{{ h.chip(ect).text }}</span></v-chip
                    >
                  </div>
                  <div v-else-if="h.staff" class="mt-1">
                    <mosaic-staff-badge
                      v-for="staff of h.staff(ect)"
                      :key="staff.id"
                      :on-click="staff.clickRoute ? () => $router.push(staff.clickRoute) : null"
                      class="mb-1"
                      :staff="staff"
                    />
                  </div>
                  <div v-else-if="h.endOfYearBadge">
                    <mosaic-end-of-year-badge
                      :on-click="h.clickRoute && h.clickRoute(ect) ? () => $router.push(h.clickRoute(ect)) : null"
                      :year="h.endOfYearBadge(ect).year"
                      :date="h.endOfYearBadge(ect).date"
                      :no-date-reason="h.endOfYearBadge(ect).noDateReason"
                      :show-error-if-date-in-past="true"
                    />
                  </div>
                  <div v-if="h.secondaryText" style="font-size: 0.75rem">{{ h.secondaryText(ect) }}</div>
                </td>
              </tr>
            </tbody>
          </v-table>
          <mosaic-pagination
            v-model="page"
            v-model:page-size="pageSize"
            class="mt-2"
            :total="filteredAndSortedEcts.length"
            :include-top-margin="false"
          />
        </v-card-text>
      </v-card>
    </div>

    <ndt-dialog v-model:active="addRemoveColumnDialog.active" title="Add/Remove Columns" close-button-text="Close">
      <div class="pl-4 mb-2">
        <mosaic-checkbox
          v-for="h of headerDefinitions"
          :key="h.key"
          :label="h.name"
          :model-value="showingColumns.includes(h.key)"
          hide-details
          no-icon
          density="compact"
          @update:model-value="showingColumnUpdated(h.key, $event)"
        />
      </div>
    </ndt-dialog>
    <mosaic-dialog
      v-model:active="traExportDialog.active"
      title="Exports for TRA"
      :error-message="traExportDialog.error"
    >
      <mosaic-info-alert
        ><div v-if="anyFiltersApplied">
          You have filters acting on your ECT data. This export will only include filtered data.
        </div>
        <div v-if="loadTime">
          ECT data was last refreshed: {{ loadTime.format('MMM Do, h:mm:ss a') }}
        </div></mosaic-info-alert
      >
      <div>
        You are about to export data for the TRA across all ECTs <strong>for the currently applied filters.</strong>
      </div>
      <div class="mt-2">Any data in an invalid format for the TRA will be noted in an extra "Errors" column.</div>
      <div class="mt-2">
        For convenience, contact emails are included as extra columns. Please ensure these are removed prior to TRA
        upload.
      </div>
      <v-divider class="mt-4" />
      <div class="mt-2">
        Please select whether all ECTs should be included or just those who have completed induction:
      </div>
      <div class="pl-4 mb-2">
        <mosaic-radio-buttons
          v-model="traExportDialog.selectedOption.value"
          :radios="traExportDialog.options"
        ></mosaic-radio-buttons>
      </div>
      <template #buttons>
        <v-btn
          color="primary"
          :disabled="!traExportDialog.selectedOption || traExportDialog.processing"
          variant="text"
          class="mr-2"
          @click.prevent="exportEcts()"
        >
          Export
        </v-btn>
      </template>
    </mosaic-dialog>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import NdtDialog from '../../components/NdtDialog.vue';
import MosaicEndOfYearBadge from '../../components/MosaicEndOfYearBadge.vue';
import MosaicStaffBadge from '../../components/MosaicStaffBadge.vue';
import { syncQueryParamsMixin } from '../../mixins/query-mixins';
import { useQueryStore } from '@/stores/query';
import moment from 'moment';
import { exportEcts } from '../../utils/ect/tra-exports';
import { getEmailStatusChipParams } from '@/utils/email-verification';
import { fromSnakeCaseToCamelCase } from '@/utils/transforms';

export default {
  name: 'TutorEctsOverviewPage',
  setup() {
    const queryStore = useQueryStore();
    return { queryStore };
  },
  mixins: [
    syncQueryParamsMixin({
      showingColumnsCsv: { query: 'columns', type: 'string' },
      filtersCsv: { query: 'filters', type: 'string' },
      sortBy: { query: 'sort', type: 'string' },
      sortDesc: { query: 'desc', type: 'boolean' },
      page: { query: 'page', type: 'integer' },
      pageSize: { query: 'pagesize', type: 'integer' },
    }),
  ],
  components: { MosaicEndOfYearBadge, NdtDialog, MosaicStaffBadge },
  data: function () {
    return {
      error: null,
      busy: true,
      ectLoadCount: 0,
      totalEctCount: null,
      page: 1,
      pageSize: 10,
      sortBy: 'name',
      sortDesc: false,
      addRemoveColumnDialog: {
        active: false,
      },
      traExportDialog: {
        active: false,
        processing: false,
        error: '',
        selectedOption: { value: 'all', label: 'All ECTs' },
        options: [
          { value: 'all', label: 'All ECTs' },
          { value: 'passes', label: 'Only ECTs with approved End of Year 2 Reviews (Passes)' },
        ],
      },
      filterValues: [],
      showingColumns: [],
      headerDefinitions: [],
      preset: null,
      reviewsOperatorItems: [
        { value: 'leq', title: '≤' },
        { value: 'geq', title: '≥' },
      ],
      reviewsStatusItems: [
        { value: 'assigned', title: 'All' },
        { value: 'notApproved', title: 'Not Approved' },
      ],
    };
  },
  computed: {
    ...mapState(['selectedInstitution', 'roles']),
    ...mapState({
      ects: state => state.activeEcts.ects,
      loadTime: state => state.activeEcts.loadTime,
    }),
    ...mapGetters(['isEmailVerificationOnForSelectedInstitution']),
    breadcrumbs() {
      return [{ text: 'ECTs Overview' }];
    },
    studentScopedRoles() {
      return this.roles.filter(r => r.student_scope);
    },
    anyFiltersApplied() {
      return this.totalEctCount !== this.filteredAndSortedEcts.length;
    },
    presetItems() {
      return [
        {
          text: `View induction status`,
          value: 'inductionStatus',
        },
        { text: `View account status`, value: `accountStatus` },
        {
          text: `Who has their end of year 1 in the next month?`,
          value: 'endOfYear1Due',
        },
        {
          text: `Who has their end of year 2 in the next month?`,
          value: 'endOfYear2Due',
        },
        {
          text: `Who has not had a ${this.reviewNounCapitalised} in the last 3 months?`,
          value: 'reviewLast3Months',
        },
        {
          text: `Who has overdue ${this.reviewNounCapitalisedAndPluralised}?`,
          value: 'overdueReviews',
        },
        {
          text: `Who has mulitple not approved ${this.reviewNounCapitalisedAndPluralised}?`,
          value: 'multipleNotApprovedReviews',
        },
        ...this.studentScopedRoles.map(r => ({
          text: `Who doesn't have a${['a', 'e', 'i', 'o', 'u'].includes(r.name[0].toLowerCase()) ? 'n' : ''} ${
            r.name
          }?`,
          value: `noRole-${r.id}`,
        })),
        {
          text: `Who has more than 25 days of absence?`,
          value: 'absences',
        },
        {
          text: `Who is paused?`,
          value: 'paused',
        },
        {
          text: `Who is leaving?`,
          value: 'leaving',
        },
        {
          text: `View QTS information`,
          value: 'qts',
        },
        this.selectedInstitution.config.show_projected_reviews
          ? {
              text: `Who is missing ${this.reviewNounCapitalisedAndPluralised}?`,
              value: 'missingReviews',
            }
          : null,
      ].filter(x => x);
    },
    pageStart() {
      return (this.page - 1) * this.pageSize;
    },
    pageStop() {
      return this.page * this.pageSize;
    },
    headers() {
      return this.headerDefinitions.map(h => ({
        ...h,
        chip: h.reviewChip
          ? x => {
              switch (h.reviewChip(x)?.status) {
                case 'multiple':
                  return {
                    color: 'error',
                    icon: 'mdi-check-circle-outline',
                    tooltip: `Has multiple ${this.reviewNounCapitalisedAndPluralised}, please get in touch if this is expected as Mosaic is not designed for this`,
                    value: 'multiple',
                  };
                case 'approved':
                  return {
                    color: 'secondary',
                    icon: 'mdi-check-circle-outline',
                    tooltip: `${this.reviewNounCapitalised} has been approved`,
                    value: 'approved',
                  };
                case 'completed':
                  return {
                    color: 'primary',
                    icon: 'mdi-check-circle-outline',
                    tooltip: `${this.reviewNounCapitalised} has been marked as complete, but not approved`,
                    value: 'completed',
                  };
                case 'assigned':
                  return {
                    color: 'accent',
                    icon: 'mdi-check-circle-outline',
                    tooltip: `${this.reviewNounCapitalised} has been assigned, but not marked as complete`,
                    value: 'assigned',
                  };
                default:
                  return { hide: true, value: 'none' };
              }
            }
          : h.chip,
        clickRoute:
          h.reviewChip && !h.clickRoute
            ? x => {
                if (!h.reviewChip(x)?.status) return null;
                if (h.reviewChip(x).status === 'multiple')
                  return { name: 'TutorReviewsListPage', params: { studentId: x.student.id } };
                return { name: 'TutorReviewPage', params: { studentId: x.student.id, id: h.reviewChip(x).id } };
              }
            : h.clickRoute,
        sort: h.sort || (h.text ? x => h.text(x) : null),
        filter:
          h.reviewChip && !h.filter
            ? {
                type: 'select',
                items: [
                  { title: 'All', value: null },
                  { title: 'Approved', value: 'approved' },
                  //{ title: 'Completed', value: 'completed' },
                  { title: 'Assigned', value: 'assigned' },
                  { title: `No  ${this.reviewNounCapitalised}`, value: 'none' },
                  { title: `Multiple ${this.reviewNounCapitalisedAndPluralised}`, value: 'multiple' },
                ],
              }
            : h.filter || (h.text ? { type: 'text' } : null),
      }));
    },
    filteredHeaders() {
      return this.headers.filter(h => this.showingColumns.includes(h.key));
    },
    filteredAndSortedEcts() {
      if (this.headers.length === 0) return [];
      const sortSelector = this.headers.find(h => h.key === this.sortBy).sort;
      const headersWithFilters = this.filteredHeaders
        .filter(h => h.filter)
        .map(h => ({
          ...h,
          filterValue: this.getFilterValue(h.key, this.filterValues),
        }));
      return this.ects
        .filter(ect => {
          return headersWithFilters.every(h => {
            if (h.filter.type === 'text') {
              return (h.filter.value ? h.filter.value(ect) : h.text(ect))
                .toString()
                .toLowerCase()
                .includes(h.filterValue.toLowerCase());
            }
            if (h.filter.type === '>=') {
              return (h.filter.value ? h.filter.value(ect) : h.text(ect)) >= (parseFloat(h.filterValue) || 0);
            }
            if (h.filter.type === 'select') {
              return (
                h.filterValue === null || (h.filter.value ? h.filter.value(ect) : h.chip(ect).value) === h.filterValue
              );
            }
            if (h.filter.type === 'induction') {
              const inductionStatusFilterValue = this.getFilterValue('inductionStatus', this.filterValues);
              const inductionDateFilterValue = this.getFilterValue('inductionDate', this.filterValues);

              const value = h.filter.value(ect);
              return (
                (inductionStatusFilterValue === null || inductionStatusFilterValue === value.status) &&
                (inductionDateFilterValue === null ||
                  !['year1', 'year2'].includes(value.status) ||
                  value.date <= inductionDateFilterValue)
              );
            }
            if (h.filter.type === 'reviews') {
              const reviewsCountFilterValue = this.getFilterValue('reviewsCount', this.filterValues);
              const value = h.text(ect);
              const reviewsOperator = this.getFilterValue('reviewsOperator', this.filterValues);
              return reviewsCountFilterValue === null || reviewsCountFilterValue === '' || reviewsOperator === 'geq'
                ? value >= reviewsCountFilterValue
                : value <= reviewsCountFilterValue;
            }
            return true;
          });
        })
        .sortBy(sortSelector, this.sortDesc ? 'desc' : 'asc');
    },
    paginatedEcts() {
      return this.filteredAndSortedEcts.slice(this.pageStart, this.pageStop);
    },
    showingColumnsCsv: {
      get() {
        return this.showingColumns.join(',');
      },
      set(showingColumnsCsv) {
        this.showingColumns = showingColumnsCsv.split(',');
      },
    },
    filtersCsv: {
      get() {
        return this.filterValues.map(x => `${x.header}:${x.value}`).join(',');
      },
      set(filtersCsv) {
        this.filterValues = filtersCsv.split(',').map(x => {
          const split = x.split(':');
          return { header: split[0], value: split[1] === 'null' ? null : split[1] };
        });
      },
    },
    loadTimeColor() {
      const yesterday = moment().subtract(1, 'day');
      if (this.loadTime.isSame(yesterday, 'day')) return 'red';
      if (this.loadTime < moment().subtract(3, 'hours')) return 'orange';
      return 'default';
    },
  },
  watch: {
    filteredAndSortedEcts() {
      this.page = 1;
    },
    preset(x) {
      this.setDefaultFilters();
      switch (x) {
        case 'inductionStatus': {
          const columns = ['name', 'trn', 'induction', 'inductionReview', 'paused', 'leaving', 'absences', 'extension'];
          const inductionTutorRole = this.studentScopedRoles.find(x => x.name === 'Induction Tutor');
          if (inductionTutorRole) {
            columns.push(`role-${inductionTutorRole.id}`);
          }
          this.showingColumns = columns;
          break;
        }
        case 'accountStatus': {
          this.showingColumns = ['name', 'email', 'loggedIn'];
          if (this.isEmailVerificationOnForSelectedInstitution) this.showingColumns.push('emailVerificationStatus');
          this.showingColumns.push('demo');
          break;
        }
        case 'reviewLast3Months':
          this.showingColumns = ['name', 'induction', 'reviews', 'paused', 'leaving'];
          this.filterUpdated('reviewsCount', 0);
          this.filterUpdated('reviewsOperator', 'leq');
          this.filterUpdated('reviewsAfter', moment().subtract(3, 'months').format('YYYY-MM-DD'));
          this.filterUpdated('reviewsBefore', null);
          this.filterUpdated('reviewsStatus', 'assigned');
          break;
        case 'multipleNotApprovedReviews':
          this.showingColumns = ['name', 'induction', 'reviews', 'paused', 'leaving'];
          this.filterUpdated('reviewsCount', 2);
          this.filterUpdated('reviewsOperator', 'geq');
          this.filterUpdated('reviewsAfter', null);
          this.filterUpdated('reviewsBefore', null);
          this.filterUpdated('reviewsStatus', 'notApproved');
          break;
        case 'overdueReviews':
          this.showingColumns = ['name', 'induction', 'overdueReviews', 'paused', 'leaving'];
          this.filterUpdated('overdueReviews', 'overdue');
          break;
        case 'endOfYear1Due':
          this.showingColumns = ['name', 'induction', 'inductionReview'];
          this.filterUpdated('inductionStatus', 'year1');
          this.filterUpdated('inductionDate', moment().add(1, 'months').format('YYYY-MM-DD'));
          break;
        case 'endOfYear2Due':
          this.showingColumns = ['name', 'induction', 'inductionReview'];
          this.filterUpdated('inductionStatus', 'year2');
          this.filterUpdated('inductionDate', moment().add(1, 'months').format('YYYY-MM-DD'));
          break;
        case 'absences':
          this.showingColumns = ['name', 'induction', 'absences', 'extension'];
          this.filterUpdated('absences', 25);
          break;
        case 'paused':
          this.showingColumns = ['name', 'induction', 'paused'];
          this.filterUpdated('paused', 'paused');
          break;
        case 'leaving':
          this.showingColumns = ['name', 'induction', 'leaving', 'leavingReview'];
          this.filterUpdated('leaving', 'future');
          break;
        case 'qts':
          this.showingColumns = ['name', 'induction', 'qtsCheck', 'qtsBody'];
          break;
        case 'missingReviews':
          this.showingColumns = ['name', 'induction', 'missingReviews'];
          this.filterUpdated('missingReviews', 1);
          break;
      }
      if (x.startsWith('noRole')) {
        const roleId = x.split('-')[1];
        this.showingColumns = ['name', 'induction', ...this.studentScopedRoles.map(r => `role-${r.id}`)];
        this.filterUpdated(`role-${roleId}`, 'doesNotHave');
      }
    },
  },
  async created() {
    await this.$store.dispatch('loadRoles');
    this.createHeaderDefinitions();
    this.loadEcts();
    // showingColumns will have a length when it's already been set from the URL
    if (this.showingColumns.length === 0) {
      this.preset = 'inductionStatus';
    }
  },
  methods: {
    createHeaderDefinitions() {
      this.headerDefinitions = [
        {
          name: 'Name',
          key: 'name',
          text: x => x.name || x.email,
          filter: {
            type: 'text',
            value: x => `${x.name}${x.email}${x.trn}`.toLowerCase(),
          },
          //secondaryText: x => x.currentSchool?.displayName || 'No current school',
          secondaryText: x => (x.name ? x.email : ''),
          clickRoute: x => ({ name: 'TutorStudentDetailsPage', params: { studentId: x.student.id } }),
        },
        {
          name: 'Email',
          key: 'email',
          text: x => x.email,
          filter: {
            type: 'text',
            value: x => x.email.toLowerCase(),
          },
          clickRoute: x => ({ name: 'TutorStudentDetailsPage', params: { studentId: x.student.id } }),
        },
        {
          name: 'TRN',
          key: 'trn',
          text: x => x.trn,
          filter: {
            type: 'text',
            value: x => x.trn.toLowerCase(),
          },
          clickRoute: x => ({ name: 'TutorStudentDetailsPage', params: { studentId: x.student.id } }),
        },
        {
          name: 'Logged In',
          key: 'loggedIn',
          chip: x => {
            if (x.activated) {
              return {
                color: 'primary',
                icon: 'mdi-check',
                tooltip: `User has logged in`,
                value: 'loggedIn',
              };
            } else {
              return {
                color: 'accent',
                icon: 'mdi-close',
                tooltip: `User has not yet logged in`,
                value: 'notLoggedIn',
              };
            }
          },
          filter: {
            type: 'select',
            items: [
              { title: 'All', value: null },
              { title: 'Logged in', value: 'loggedIn' },
              { title: 'Not logged in', value: 'notLoggedIn' },
            ],
          },
          clickRoute: x => ({ name: 'TutorStudentDetailsPage', params: { studentId: x.student.id } }),
        },
        {
          name: 'Is Demo',
          key: 'demo',
          chip: x => {
            if (x.isDemo) {
              return {
                color: 'accent',
                icon: 'mdi-account-star',
                tooltip: `Demo user - will not be sent emails by Mosaic`,
                value: 'demo',
              };
            }
            return { hide: true, value: 'real' };
          },
          filter: {
            type: 'select',
            items: [
              { title: 'All', value: null },
              { title: 'Yes', value: 'demo' },
              { title: 'No', value: 'real' },
            ],
          },
          clickRoute: x => ({ name: 'TutorStudentDetailsPage', params: { studentId: x.student.id } }),
        },

        {
          name: 'Induction Status',
          key: 'induction',
          endOfYearBadge: x => {
            if (x.currentYear === 'year_1') {
              if (!x.year1End) return { year: '1', date: 'none', noDateReason: x.noEndDateReason };
              return { year: '1', date: x.year1End };
            }

            if (x.currentYear === 'year_2') {
              if (!x.year2End) return { year: '2', date: 'none', noDateReason: x.noEndDateReason };
              return { year: '2', date: x.year2End };
            }

            // I think this is just for safety
            return { year: '1', date: 'none', noDateReason: x.noEndDateReason };
          },
          filter: {
            type: 'induction',
            value: x => {
              if (x.noEndDateReason === 'error') return { status: 'error', date: null };
              if (!x.year1End) return { status: 'noDate', date: null };
              if (x.currentYear === 'year_1') return { status: 'year1', date: x.year1End };
              if (!x.year2End) return { status: 'noDate', date: null };
              return { status: 'year2', date: x.year2End };
            },
            items: [
              { title: 'All', value: null },
              { title: 'Year 1', value: 'year1' },
              { title: 'Year 2', value: 'year2' },
              { title: 'No date for current year', value: 'noDate' },
              { title: 'Errored', value: 'error' },
            ],
          },
          sort: x => {
            if (!x.year1End) return '3000-01-01';
            if (x.currentYear === 'year_1') return x.year1End;
            if (!x.year2End) return '3000-01-01';
            return x.year2End;
          },
          clickRoute: x => ({ name: 'TutorEctProgressPage', params: { studentId: x.student.id } }),
        },
        {
          name: `Induction ${this.reviewNounCapitalised}`,
          key: 'inductionReview',
          helpText: `End of year ${this.reviewNounCapitalised} for the current year of induction`,
          reviewChip: x => {
            if (x.currentYear === 'year_1') {
              return x.endOfYear1Review;
            }
            if (x.currentYear === 'year_2') {
              return x.endOfYear2Review;
            }
          },
        },
        {
          name: `${this.reviewNounCapitalisedAndPluralised}`,
          key: 'reviews',
          text: x => {
            let studentReviews = x.student.studentReviews;
            const reviewsStatusFilterValue = this.getFilterValue('reviewsStatus', this.filterValues);
            studentReviews = studentReviews.filter(x => reviewsStatusFilterValue === 'assigned' || !x.approved);

            const reviewsAfterFilterValue = this.getFilterValue('reviewsAfter', this.filterValues);
            if (reviewsAfterFilterValue) {
              studentReviews = studentReviews.filter(x => x.dueDate >= reviewsAfterFilterValue);
            }
            const reviewsBeforeFilterValue = this.getFilterValue('reviewsBefore', this.filterValues);
            if (reviewsBeforeFilterValue) {
              studentReviews = studentReviews.filter(x => x.dueDate <= reviewsBeforeFilterValue);
            }
            return studentReviews.length;
          },
          filter: {
            type: 'reviews',
          },
          clickRoute: x => ({ name: 'TutorReviewsListPage', params: { studentId: x.student.id } }),
        },
        {
          name: `Overdue ${this.reviewNounCapitalisedAndPluralised}`,
          key: 'overdueReviews',
          chip: x => {
            const now = moment().format('YYYY-MM-DD');
            if (x.student.studentReviews.some(sr => sr.dueDate < now && !sr.approved)) {
              return {
                color: 'red',
                icon: 'mdi-alert-circle-outline',
                iconColor: 'white',
                tooltip: `Has overdue ${this.reviewNounCapitalisedAndPluralised}`,
                value: 'overdue',
              };
            }

            return { hide: true, value: 'none' };
          },
          filter: {
            type: 'select',
            items: [
              { title: 'All', value: null },
              { title: 'Some overdue', value: 'overdue' },
              { title: 'None overdue', value: 'none' },
            ],
          },
          clickRoute: x => ({ name: 'TutorReviewsListPage', params: { studentId: x.student.id } }),
        },
        {
          name: 'Paused',
          key: 'paused',
          chip: x => {
            if (x.pauseDate) {
              return {
                color: 'secondary',
                icon: 'mdi-pause-circle-outline',
                tooltip: `Paused since ${this.formatDate(x.pauseDate)}`,
                value: 'paused',
              };
            }
            if (x.futurePauseDate) {
              return {
                color: 'secondary',
                icon: 'mdi-clock-outline',
                tooltip: `Will be paused from ${this.formatDate(x.futurePauseDate)}`,
                value: 'future',
              };
            }
            return { hide: true, value: 'none' };
          },
          filter: {
            type: 'select',
            items: [
              { title: 'All', value: null },
              { title: 'Paused', value: 'paused' },
              { title: 'Will be paused', value: 'future' },
              { title: 'No pauses', value: 'none' },
            ],
          },
          clickRoute: x => ({ name: 'TutorEctProgressPage', params: { studentId: x.student.id } }),
        },
        {
          name: 'Leaving Status',
          key: 'leaving',
          chip: x => {
            if (x.futureLeavingDate) {
              return {
                color: 'accent',
                icon: 'mdi-exit-run',
                tooltip: `Will be leaving on ${this.formatDate(x.futureLeavingDate)}`,
                value: 'future',
              };
            }
            if (x.futureReturnDate) {
              return {
                color: 'primary',
                icon: 'mdi-star',
                tooltip: `Due to return on ${this.formatDate(x.futureReturnDate)}`,
                value: 'returning',
              };
            }
            if (x.leftDate) {
              return {
                color: 'secondary',
                icon: 'mdi-exit-run',
                tooltip: `Left on ${this.formatDate(x.leftDate)}`,
                value: 'left',
              };
            }
            return { hide: true, value: 'none' };
          },
          filter: {
            type: 'select',
            items: [
              { title: 'All', value: null },
              { title: 'Left', value: 'left' },
              { title: 'Will be leaving', value: 'future' },
              { title: 'Due to return', value: 'returning' },
              { title: 'Not leaving', value: 'none' },
            ],
          },
          clickRoute: x => ({ name: 'TutorEctProgressPage', params: { studentId: x.student.id } }),
        },
        {
          name: `Leaving ${this.reviewNounCapitalised}`,
          key: 'leavingReview',
          reviewChip: x => x.leavingReview,
        },
        {
          name: 'Absences',
          key: 'absences',
          helpText: `Total absences in current year. Year 2: if the number of absences since the end of year 1 ${this.reviewNounCapitalised} is different to the year 2 total, then it will show in brackets.`,
          text: x =>
            `${x.absences}${
              x.currentYear === 'year_2' && x.absences !== x.absencesSinceEndOfYear1ReviewApproved
                ? ` (${x.absencesSinceEndOfYear1ReviewApproved})`
                : ''
            }`,
          textColor: x => {
            if (x.absences >= 30) return 'error';
            if (x.absences >= 25) return 'accent';
          },
          filter: {
            type: '>=',
            value: x => x.absences,
          },
          clickRoute: x => ({ name: 'TutorEctProgressPage', params: { studentId: x.student.id } }),
        },
        {
          name: 'Extension',
          key: 'extension',
          helpText: `First year of induction: has an extension.
Second year of induction: has an extension since end of year 1 ${this.reviewNounCapitalised} was approved.`,
          chip: x => {
            if (x.hasExtension) {
              return {
                color: 'success',
                icon: 'mdi-check',
                tooltip: `Has extension`,
                value: 'hasExtension',
              };
            }
            return { hide: true, value: 'none' };
          },
          filter: {
            type: 'select',
            items: [
              { title: 'All', value: null },
              { title: 'Has extension', value: 'hasExtension' },
              { title: `No extension`, value: 'none' },
            ],
          },
          clickRoute: x => ({ name: 'TutorEctProgressPage', params: { studentId: x.student.id } }),
        },
        {
          name: 'QTS Check',
          key: 'qtsCheck',
          chip: x => {
            if (x.traQtsDate) return { text: 'Has QTS', color: 'success', value: 'qts' };
            if (!x.traQtsDate && x.qtsCheckedAt) return { text: 'Does not have QTS', color: 'error', value: 'noQts' };
            if (x.trainedOutsideEngland)
              return { text: 'Trained outside England', color: 'secondary', value: 'trainedOutsideEngland' };
            return { text: 'Not checked', color: 'secondary', value: 'notChecked' };
          },
          filter: {
            type: 'select',
            items: [
              { title: 'All', value: null },
              { title: 'Has QTS', value: 'success' },
              { title: 'Does not have QTS', value: 'noQts' },
              { title: 'Trained outside England', value: 'trainedOutsideEngland' },
              { title: 'Not Checked', value: 'notChecked' },
            ],
          },
          clickRoute: x => ({
            name: 'TutorStudentDetailsPage',
            params: { studentId: x.student.id },
            query: { tab: 1 },
          }),
        },
        {
          name: 'QTS Awarding Body',
          key: 'qtsBody',
          helpText:
            "Shows the QTS awarding body retrieved from the TRA. If that doesn't exist, then it will show manually entered awarding body.",
          text: x => x.traIttEstablishment || x.teacherTrainingInstitution || 'Not set',
          trailingTextIcon: x => {
            if (x.traIttEstablishment || !x.teacherTrainingInstitution) return;
            return { icon: 'mdi-account-edit', tooltip: 'Manually entered (not retrieved from the TRA)' };
          },
          clickRoute: x => ({
            name: 'TutorStudentDetailsPage',
            params: { studentId: x.student.id },
            query: { tab: 2 },
          }),
        },
        ...this.studentScopedRoles.map(r => ({
          name: r.name,
          key: `role-${r.id}`,
          staff: x =>
            x.student.staffRoles
              .filter(sr => sr.roleId === r.id)
              .map(sr => ({
                ...sr.staff,
                clickRoute: this.userStaffHasPermission('staff.edit')
                  ? { name: 'TutorStaffPage', params: { id: sr.staff.id } }
                  : undefined,
              })),
          filter: {
            type: 'select',
            value: x => (x.student.staffRoles.some(sr => sr.roleId === r.id) ? 'has' : 'doesNotHave'),
            items: [
              { title: 'All', value: null },
              { title: `Has`, value: 'has' },
              { title: `Does not have`, value: 'doesNotHave' },
            ],
          },
        })),
        {
          name: 'Missing Review Count',
          key: 'missingReviews',
          helpText: `Where Mosaic thinks the ECT should have a ${this.reviewNounCapitalised}, but it's missing`,
          text: x => x.pastProjectedReviewsCount,
          textColor: x => {
            if (x.pastProjectedReviewsCount >= 1) return 'error';
          },
          filter: {
            type: '>=',
            value: x => x.pastProjectedReviewsCount,
          },
          clickRoute: x => ({ name: 'TutorEctProgressPage', params: { studentId: x.student.id } }),
        },
      ];
      if (this.isEmailVerificationOnForSelectedInstitution)
        this.headerDefinitions.splice(4, 0, {
          name: 'Email Verification Status',
          key: 'emailVerificationStatus',
          chip: x => getEmailStatusChipParams(x.emailVerified, x.emailBounced, x.optedOutOfEmails),
          filter: {
            type: 'select',
            items: [
              { title: 'All', value: null },
              { title: 'Not yet verified', value: 'notVerified' },
              { title: 'Email bounced', value: 'bounced' },
              { title: 'Opted out of emails', value: 'optedOut' },
              { title: 'Verified', value: 'verified' },
            ],
          },
          clickRoute: x => ({ name: 'TutorStudentDetailsPage', params: { studentId: x.student.id } }),
        });
    },
    async loadEcts(force = false) {
      if (this.loadTime && !force) {
        this.busy = false;
        return;
      }
      this.busy = true;
      try {
        this.loadEctCount();
        let after = 0;
        this.ectLoadCount = 0;
        let ects = [];
        while (true) {
          const r = await this.$api.get(`/institutions/${this.selectedInstitution.id}/active-ects?after=${after}`);
          ects = ects.concat(fromSnakeCaseToCamelCase(r.data));
          this.ectLoadCount = ects.length;
          if (ects.length >= this.totalEctCount || r.data.length === 0) break;
          after = r.data[r.data.length - 1].id;
        }
        this.$store.commit('updateActiveEcts', ects);
      } catch (e) {
        this.error = 'Sorry, cannot load your ECTs at the moment';
        console.log(e);
      }
      this.busy = false;
    },
    async loadEctCount() {
      const countResponse = await this.$api.get(`/institutions/${this.selectedInstitution.id}/active-ects/count`);
      this.totalEctCount = countResponse.data.count;
    },
    setDefaultFilters() {
      this.filterValues = [
        ...this.headers
          .filter(h => h.filter || h.key === 'induction')
          .map(h => {
            const defaultValue = h.filter.defaultValue
              ? h.filter.defaultValue
              : h.filter.type === 'text'
              ? ''
              : h.filter.type === 'select'
              ? null
              : undefined;
            return { header: h.key, value: defaultValue };
          }),
        { header: `inductionStatus`, value: null },
        { header: `inductionDate`, value: null },
        { header: `reviewsOperator`, value: 'leq' },
        { header: `reviewsCount`, value: null },
        { header: `reviewsAfter`, value: null },
        { header: `reviewsBefore`, value: null },
        { header: `reviewsStatus`, value: 'assigned' },
      ];
    },
    sortByHeader(h) {
      if (h.key === this.sortBy) {
        this.sortDesc = !this.sortDesc;
      } else {
        this.sortBy = h.key;
        this.sortDesc = false;
      }
    },
    addRemoveColumn() {
      this.addRemoveColumnDialog.active = true;
    },
    // filterValues are a param to get reactivity to work
    getFilterValue(headerKey, filterValues) {
      return filterValues.find(f => f.header === headerKey)?.value;
    },
    filterUpdated(headerKey, value) {
      if (headerKey === 'inductionStatus' && !['year1', 'year2'].includes(value)) {
        this.filterUpdated('inductionDate', null);
      }

      this.filterValues = this.filterValues.map(f => {
        if (f.header === headerKey) {
          return { ...f, value };
        }
        return f;
      });
    },
    showingColumnUpdated(headerKey, showing) {
      if (showing) {
        this.showingColumns = [...this.showingColumns, headerKey];
      } else {
        this.showingColumns = this.showingColumns.filter(x => x !== headerKey);
      }
    },
    traExports() {
      this.traExportDialog.active = true;
    },
    exportEcts() {
      this.traExportDialog.processing = true;
      this.traExportDialog.error = '';
      const type = this.traExportDialog.selectedOption.value;
      let successfulExport;
      try {
        successfulExport = exportEcts(type, this.filteredAndSortedEcts, this.selectedInstitution);
        if (!successfulExport)
          this.traExportDialog.error =
            'Your export includes invalid data and will not be accepted by the TRA. Please check errors in the exported file.';
        else this.traExportDialog.active = false;
      } catch (e) {
        console.log(e);
        this.traExportDialog.error = 'Sorry, there was an error exporting your ECTs';
      }

      this.traExportDialog.processing = false;
    },
  },
};
</script>

<style>
.v-select-auto-width .v-select__selections input {
  display: none;
}

td.highlight-cell-on-hover {
  border-left: 1px solid white !important;
  border-right: 1px solid white !important;
}

td.highlight-cell-on-hover:hover {
  border: 1px solid rgba(0, 0, 0, 0.6) !important;
  border-radius: 4px;
}
</style>
