<template>
  <div>
    <v-card v-if="error">
      <v-card-text>
        <div class="pa-4">
          {{ error }}
        </div>
      </v-card-text>
    </v-card>
    <review-standard-card
      v-else-if="studentReviewForStaff && studentReviewStandard"
      :student-review-standard="studentReviewStandard"
      :student-review="studentReviewForStaff"
      :staff-viewer="userStaff"
      :student="selectedStudent"
      :review-path="reviewPath"
      @update:dirty="dirty = $event"
    />
    <unsaved-changes-dialog
      v-model:unsaved-changes-dialog="unsavedChangesDialog"
      :object-type="reviewNounCapitalised"
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import ReviewStandardCard from '@/components/ReviewStandardCard.vue';
import unsavedChangesMixin from '@/components/unsaved-changes-mixin';
import UnsavedChangesDialog from '@/components/UnsavedChangesDialog.vue';
import clearStudentReviewMixin from '@/mixins/clear-student-review';

export default {
  name: 'TutorReviewStandardPage',
  mixins: [unsavedChangesMixin, clearStudentReviewMixin],
  ...unsavedChangesMixin,
  created() {
    this.$store.dispatch('loadStudentReview', { id: this.$route.params.id });
  },
  computed: {
    ...mapState(['selectedStudent', 'userStaff']),
    ...mapState({
      error: state => state.studentReviewPages.error,
    }),
    ...mapGetters(['studentReviewForStaff']),
    studentReviewStandard() {
      return this.studentReviewForStaff?.reviewStandards.find(x => x.id == this.$route.params.standardId);
    },
    reviewPath() {
      return {
        text: this.studentReviewForStaff?.name,
        to: {
          name: 'TutorReviewPage',
          params: { id: this.studentReviewForStaff?.id },
        },
      };
    },
    breadcrumbs() {
      return [
        {
          text: this.reviewNounCapitalisedAndPluralised,
          to: {
            name: 'TutorReviewsListPage',
          },
        },
        this.reviewPath,
        {
          text: this.studentReviewStandard?.code,
        },
      ];
    },
  },
  components: { ReviewStandardCard, UnsavedChangesDialog },
};
</script>
