<template>
  <mosaic-save-dialog
    :active="active"
    @update:active="emit('update:active', $event)"
    title="Edit Training Module"
    object-type="Training Module"
    :save="saveEditModule"
    :can-save="canEditModule"
    @save="emit('save')"
  >
    <template #default="{ onKeyupEnter }">
      <div class="edit-module-content">
        <mosaic-text-field
          v-model="name"
          name="edit-module-name"
          label="Name"
          prepend-icon="mdi-pencil"
          @keyup.enter="onKeyupEnter"
        />
        <mosaic-time-picker v-model="duration" label="Expected duration" mode="duration" allow-zero-duration />
        <staff-training-competency-multi-select
          v-if="institutionStaffTrainingFrameworks"
          v-model="competencyIds"
          :frameworks="institutionStaffTrainingFrameworks"
          label="Competencies this Module meets"
        />
      </div>
      <div class="info-alert">
        <mosaic-info-alert class="mt-2" v-if="competencyIds.length !== 0 && module.status === 'unpublished'"
          >This Module has not yet been published. The Competencies will not show as Met for the mentor untill all
          Modules have been published and completed.</mosaic-info-alert
        >
      </div>
    </template>
  </mosaic-save-dialog>
</template>

<script setup lang="ts">
import { useApi } from '@/composables/api';
import { useStaffTrainingStore } from '@/stores/staff-training';
import { formatTime, splitTimeIntoHoursAndMinutes } from '@/utils/time';
import { useInstitutionStaffTrainingStore } from '@/stores/institution-staff-training';
import type { StaffTrainingModule } from './InstitutionStaffTrainingModulePage.vue';
import StaffTrainingCompetencyMultiSelect from '@/pages/staff-training/StaffTrainingCompetencyMultiSelect.vue';
import { computed, watchEffect } from 'vue';
import { ref } from 'vue';

const props = defineProps<{
  active: boolean;
  module: StaffTrainingModule;
}>();

const emit = defineEmits<{
  'update:active': [active: boolean];
  save: [void];
}>();

const api = useApi();

const {
  actions: { clearAllStaffTraining },
} = useStaffTrainingStore();

const {
  institutionStaffTrainingFrameworks,
  actions: { loadInstitutionStaffTrainingFrameworks },
} = useInstitutionStaffTrainingStore();

loadInstitutionStaffTrainingFrameworks();

const name = ref('');
const duration = ref('');
const competencyIds = ref<number[]>([]);

watchEffect(() => {
  name.value = props.module.name;
  duration.value = formatTime(props.module.durationHours || 0, props.module.durationMinutes || 0);
  competencyIds.value = props.module?.staffTrainingCompetencies
    ? props.module?.staffTrainingCompetencies.map(c => c.id)
    : [];
});

const canEditModule = computed(() => {
  const m = props.module;
  const initialCIds = props.module?.staffTrainingCompetencies
    ? props.module?.staffTrainingCompetencies.map(c => c.id)
    : [];
  const areCompetenciesTheSame =
    initialCIds.length === competencyIds.value.length && competencyIds.value.every(c => initialCIds.includes(c));

  return (
    !!name.value &&
    (name.value !== m.name ||
      duration.value !== formatTime(m.durationHours || 0, m.durationMinutes || 0) ||
      !areCompetenciesTheSame)
  );
});

async function saveEditModule() {
  const { hours, minutes } = splitTimeIntoHoursAndMinutes(duration.value);
  await api.put(`/staff-training/modules/${props.module.id}/details`, {
    name: name.value,
    durationHours: hours,
    durationMinutes: minutes,
    competencies: competencyIds.value.map(c => {
      return {
        staffTrainingCompetencyId: c,
      };
    }),
  });
  clearAllStaffTraining();
  emit('save');
  emit('update:active', false);
}
</script>
<style scoped>
.edit-module-content {
  max-height: 50vh;
  overflow: auto;
}
.info-alert {
  min-height: 20px;
}
</style>
