<template>
  <v-card v-if="error">
    <v-card-text>{{ error }}</v-card-text>
  </v-card>
  <div v-else-if="reviewTemplate">
    <div class="d-flex flex-wrap justify-space-between align-center mb-4">
      <!-- this is for balancing the layout -->
      <div v-if="previewToggle === 0 || !showPreviewUser" style="visbility: hidden; width: 200px"></div>
      <div v-else style="width: 200px" class="pl-3">
        <v-select
          v-model="selectedRoleId"
          hide-details
          density="compact"
          prepend-icon="mdi-account"
          :items="rolesWithTrainee"
          item-title="name"
          item-value="id"
          label="Preview as"
        ></v-select>
      </div>
      <v-btn-toggle v-model="previewToggle" class="pr-2" color="accent" variant="outlined" divided mandatory>
        <v-btn class="px-6">
          Configure
          <v-icon class="pl-2" dark>mdi-pencil</v-icon>
        </v-btn>
        <v-btn class="px-6">
          Preview
          <v-icon class="pl-2" dark>mdi-eye</v-icon>
        </v-btn>
      </v-btn-toggle>
      <div class="d-flex justify-center align-center" style="width: 200px">
        <div v-if="saving">
          <span class="text-overline">Saving</span>
          <v-icon class="pl-1">mdi-loading mdi-spin</v-icon>
        </div>
        <div v-else-if="saveError">
          <v-btn variant="text" @click.prevent="retry()">Retry Save</v-btn>
        </div>
        <div v-else :class="{ 'text-overline': true, hidden: !visible }">Saved to Mosaic</div>
      </div>
    </div>
    <mosaic-error-alert
      v-if="saveError"
      override-error-message="Sorry, cannot save your review template at the moment. Any further changes you make will not be saved (until we
      can successfully save again)."
    />
    <slot v-if="previewToggle === 0" name="configure" />
    <template v-else>
      <v-card v-if="!canViewReview">
        <v-card-text class="pa-5"
          >The selected previewer does not have permissions to view the {{ reviewNoun }}</v-card-text
        >
      </v-card>
      <slot v-else name="preview" />
    </template>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'ReviewTemplateHeader',
  props: {
    showPreviewUser: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    visible: false,
    // consider moving this to the store so it persists between page transitions
    // probably needed when we implement error handling
    timeout: null,
  }),
  computed: {
    ...mapState({
      error: state => state.reviewTemplatePage.error,
      saveError: state => state.reviewTemplatePage.saveError,
      reviewTemplate: state => state.reviewTemplate,
      roles: state => state.roles,
      saving: state => state.reviewTemplatePage.saving,
    }),
    ...mapGetters(['reviewTemplateRoles', 'reviewTemplateAsStudentReview']),
    rolesWithTrainee() {
      return [{ id: 'trainee', name: this.traineeNounCapitalised() }].concat(this.roles);
    },
    selectedRoleId: {
      get() {
        return this.$store.state.reviewTemplatePage.previewRoleId;
      },
      set(x) {
        this.$store.commit('updateReviewTemplatePage', { previewRoleId: x });
      },
    },
    previewToggle: {
      get() {
        return this.$store.state.reviewTemplatePage.previewToggle;
      },
      set(x) {
        this.$store.commit('updateReviewTemplatePage', { previewToggle: x });
      },
    },
    canViewReview() {
      if (this.$store.state.reviewTemplatePage.previewRoleId === 'trainee')
        return this.reviewTemplateAsStudentReview.review.canTraineeViewReview;
      return this.reviewTemplateAsStudentReview.review.roleIdsThatCanViewReview.includes(
        this.$store.state.reviewTemplatePage.previewRoleId
      );
    },
  },
  watch: {
    saving(x) {
      if (x) {
        if (this.timeout) {
          clearTimeout(this.timeout);
        }

        this.visible = true;
      } else {
        this.timeout = setTimeout(() => (this.visible = false), 10000);
      }
    },
  },
  methods: {
    retry() {
      this.$store.dispatch('saveReviewTemplate');
    },
  },
};
</script>

<style scoped>
.hidden {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 1s, opacity 1s linear;
}
</style>
