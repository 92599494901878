<template>
  <lesson-observations-list
    :student-id="userStudent.id"
    :can-create-lesson-observations="selectedInstitution.config.students_can_add_lesson_observations"
    :can-edit-evidence="true"
    :lesson-observation-click-to="lessonObservationClickTo"
    @click-create-lesson-observation="createLessonObservationClick"
  >
  </lesson-observations-list>
</template>

<script>
import { mapState } from 'vuex';
import LessonObservationsList from './LessonObservationsList.vue';

export default {
  name: 'StudentLessonObservationsListPage',
  components: { LessonObservationsList },
  data: () => ({}),
  computed: {
    ...mapState(['selectedInstitution', 'userStudent']),
    breadcrumbs() {
      return [
        {
          text: 'Lesson Observations',
        },
      ];
    },
  },
  methods: {
    lessonObservationClickTo(id) {
      return { name: 'LessonObservationPage', params: { id: id } };
    },
    createLessonObservationClick() {
      this.$router.push({ name: 'StudentLessonObservationCreatePage' });
    },
  },
};
</script>
