<template>
  <div>
    <mosaic-loading-card v-if="busy" type="list" />
    <mosaic-load-error-card v-else-if="error" :object-type="reviewNounCapitalisedAndPluralised" @retry="loadReviews" />
    <reviews-list v-else :reviews="reviews" :review-click-to="reviewClickTo">
      <div class="py-4">You have no open {{ reviewNounCapitalisedAndPluralised }}. {{ contactProviderMessage }}</div>
    </reviews-list>
  </div>
</template>

<script>
import ReviewsList from '@/components/ReviewsList.vue';
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'StudentReviewsListPage',
  data: function () {
    return {
      reviews: [],
      busy: true,
      error: false,
    };
  },
  computed: {
    ...mapState(['selectedInstitution']),
    ...mapGetters(['institutionNoun', 'contactProviderMessage']),
    breadcrumbs() {
      return [
        {
          text: this.reviewNounCapitalisedAndPluralised,
        },
      ];
    },
  },
  async created() {
    await this.loadReviews();
  },
  methods: {
    async loadReviews() {
      this.busy = true;
      this.error = false;
      try {
        const response = await this.$api.get(`/student-reviews`);
        this.reviews = response.data;
      } catch (e) {
        console.log(e);
        this.error = true;
      }
      this.busy = false;
    },
    reviewClickTo(id) {
      return { name: 'StudentReviewPage', params: { id: id } };
    },
  },
  components: {
    ReviewsList,
  },
};
</script>
