<template>
  <!-- Keys are applied so that vue correctly re-renders the (slots of the) mosaic-tooltip-chip on prop change -->
  <div v-if="emailBounced" key="2">
    <mosaic-tooltip-chip
      color="error"
      help-site-url="http://help.mosaic.penrose.education/books/troubleshooting/page/automated-emails-from-mosaic"
    >
      <template #text>
        <v-icon>mdi-email-remove</v-icon>
      </template>
      <template #tooltip
        >An email from Mosaic has failed to reach this user. Click here to find out why this might have
        happened.</template
      >
    </mosaic-tooltip-chip>
  </div>
  <div v-else key="4">
    <mosaic-tooltip-chip :color="chipParams.color">
      <template #text>
        <v-icon>{{ chipParams.icon }}</v-icon>
      </template>
      <template #tooltip> {{ chipParams.tooltip }} </template>
    </mosaic-tooltip-chip>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { getEmailStatusChipParams } from '@/utils/email-verification';

const props = defineProps<{
  emailVerified: boolean;
  emailBounced: boolean;
  optedOutOfEmails?: boolean;
  isDemo?: boolean;
}>();

const chipParams = computed(() =>
  props.isDemo
    ? {
        color: 'accent',
        icon: 'mdi-account-star',
        tooltip: `Demo user`,
      }
    : getEmailStatusChipParams(props.emailVerified, props.emailBounced, props.optedOutOfEmails)
);
</script>
