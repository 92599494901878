<template>
  <div>
    <div v-if="showWhenReadonly || canEdit" class="d-flex align-center">
      <mosaic-help :icon-name="iconName" :icon-color="iconColor">{{ renderTooltip }}</mosaic-help>
      <div class="flex-grow-1 ml-2">
        <slot></slot>
      </div>
    </div>
    <slot v-else> </slot>
  </div>
</template>

<script>
export default {
  name: 'MosaicCompletionLabel',
  props: {
    canEdit: {
      type: Boolean,
      required: true,
    },
    completed: {
      type: Boolean,
      required: true,
    },
    showWhenReadonly: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    iconColor() {
      return this.canEdit ? (this.completed ? 'primary' : 'accent') : 'secondary';
    },
    iconName() {
      return this.canEdit ? 'pencil' : 'eye';
    },
    renderTooltip() {
      return this.canEdit ? (this.completed ? 'Completed' : 'To be completed by you') : 'To be completed by others';
    },
  },
};
</script>
