<template>
  <div>
    <v-card class="mb-4">
      <v-card-text>
        <div class="d-flex align-center">
          <v-text-field
            v-model="searchTerm"
            density="compact"
            hide-details
            variant="outlined"
            :label="`Search for ECT by email, name or TRN`"
            prepend-inner-icon="mdi-magnify"
          />
          <div class="flex-grow-1"></div>
        </div>
      </v-card-text>
    </v-card>
    <v-card v-if="searchTerm && (ects.length || error || !busy)">
      <v-card-text>
        <mosaic-list v-if="ects.length" :two-line="!smallScreen" :three-line="smallScreen" :items="ects">
          <template #item="{ item: e }">
            <mosaic-list-item
              :key="e.id"
              icon="mdi-account-check"
              :title="e.name"
              :subtitle="renderSubtitle(e)"
              :to="e.reactivated ? goToStudent(e.student_id) : null"
            >
              <template #actions v-if="!e.reactivated">
                <mosaic-icon-btn
                  icon="account-reactivate-outline"
                  tooltip="Reactivate ECT"
                  @click.prevent="reactivateEct(e)"
                />
              </template>
            </mosaic-list-item>
          </template>
        </mosaic-list>
        <div v-else-if="error">{{ error }}</div>
        <div v-else-if="searchTerm && !busy">No completed ECTs found</div>
        <mosaic-error-snackbar
          v-model="errorSnackbar"
          message="Sorry, cannot reactivate this ECT at the moment"
        ></mosaic-error-snackbar>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import axios from 'axios';
import { mapState } from 'vuex';

export default {
  name: 'TutorAdminCompletedEctsPage',
  data: () => ({
    searchTerm: null,
    ects: [],
    cancelSource: null,
    error: '',
    errorSnackbar: false,
    busy: false,
    institution: {
      cohorts: [],
    },
  }),
  created() {
    this.institution = this.selectedInstitution;
  },
  computed: {
    ...mapState([`selectedInstitution`]),
    breadcrumbs() {
      return [{ text: this.traineeNounCapitalisedAndPluralised }];
    },
  },
  watch: {
    async searchTerm(x) {
      this.error = '';
      this.busy = true;
      if (this.cancelSource) this.cancelSource.cancel();
      if (!x) {
        this.ects = [];
        return;
      }
      this.cancelSource = axios.CancelToken.source();
      try {
        const r = await this.$api.get(
          `institutions/${this.selectedInstitution.id}/ects/get-completed-ects-for-search?q=${encodeURIComponent(x)}`,
          this.cancelSource.token
        );
        this.ects = r.data;
      } catch (e) {
        if (!axios.isCancel(e)) {
          console.log(e);
          this.ects = [];
          this.error = `Sorry, cannot load ${this.traineeNounCapitalisedAndPluralised} at the moment`;
        }
      }
      this.busy = false;
    },
  },
  methods: {
    goToStudent(studentId) {
      return {
        name: 'TutorStudentDetailsPage',
        params: { studentId: studentId },
      };
    },
    async reactivateEct(ect) {
      try {
        await this.$api.put(`ects/${ect.id}/reactivate`);

        ect = {
          ...ect,
          reactivated: true,
        };
        const index = this.ects.findIndex(e => e.id === ect.id);
        this.ects.splice(index, 1, ect);
      } catch (e) {
        this.errorSnackbar = true;
        console.log(e);
      }
    },

    renderSubtitle(e) {
      return `${e.email} - ${e.trn ? e.trn : 'No TRN recorded'}`;
    },
  },
};
</script>
