<template>
  <v-card v-if="error">
    <v-card-text>
      <div class="pa-4">{{ error }}</div>
    </v-card-text>
  </v-card>
  <router-view />
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'TutorStudentWrapper',
  computed: {
    ...mapState(['userStudent', 'selectedStudent']),
    error() {
      return this.selectedStudent || this.userStudent
        ? ''
        : `Sorry, cannot load ${this.traineeNounCapitalised()} data right now. Please try again later.`;
    },
  },
};
</script>
