<template>
  <div>
    <div class="d-flex align-center">
      <review-template-read-only review-template-in-use offset="20">
        <template #default="{ disabled }">
          <v-text-field
            :model-value="props.comment.label"
            class="mr-4"
            :disabled="disabled"
            @update:model-value="value = $event"
            @update:focused="x => !x && emit('update:label', value)"
          />
        </template>
      </review-template-read-only>
      <ndt-icon-button
        icon="delete"
        :tooltip="reviewTemplateInUse ? 'Cannot delete as template is in use' : 'Delete Comment'"
        :disabled="reviewTemplateInUse"
        @click.prevent="emit('delete')"
      />
    </div>
    <div class="font-weight-bold mb-1">Placeholder (optional)</div>
    <mosaic-quill :contents="comment.placeholder" :read-only="false" @change="emit('update:placeholder', $event)" />
    <div class="d-flex align-center mt-2">
      <span>Mark comment as optional?</span>
      <div>
        <v-checkbox
          v-model="internalOptional"
          class="ml-4"
          hide-details
          density="compact"
          color="primary"
          @update:model-value="emit('update:optionality', internalOptional)"
        />
      </div>
      <div class="pl-1">
        <mosaic-help>Optional comments provide users with an easy option to mark a comment as "n/a"</mosaic-help>
      </div>
    </div>
    <review-role-select
      :roles="internalRoles"
      label="Who can answer"
      @selected-roles-updated="emit('selectedRolesUpdated', $event)"
    />
  </div>
</template>

<script setup lang="ts">
import MosaicQuill from './quill/MosaicQuill.vue';
import NdtIconButton from './NdtIconButton.vue';
import ReviewTemplateReadOnly from './ReviewTemplateReadOnly.vue';
import ReviewRoleSelect, { type ReviewRoleSelectRole } from '@/pages/reviews/ReviewRoleSelect.vue';
import { computed, ref } from 'vue';

const props = defineProps<{
  comment: {
    optional: boolean;
    label: string;
    placeholder: string;
    [property: `${number}_edit`]: boolean;
    can_trainee_edit: boolean;
  };
  reviewTemplateInUse: boolean;
  canTraineeViewReview: boolean;
  roles: {
    id: number;
    canView: boolean;
    name: string;
  }[];
}>();

const emit = defineEmits([
  'update:label',
  'update:placeholder',
  'update:optionality',
  'selectedRolesUpdated',
  'delete',
]);

const value = ref('');
const internalOptional = ref(props.comment.optional);

const internalRoles = computed<ReviewRoleSelectRole[]>(() => [
  { roleId: 'student', selected: props.comment.can_trainee_edit, canView: props.canTraineeViewReview },
  ...props.roles.map(r => {
    return {
      roleId: r.id,
      selected: props.comment[`${r.id}_edit`],
      canView: r.canView,
    };
  }),
]);
</script>
