import type { Role, SelectedStudent, UserStaff } from '@/store/map-store';
import moment from 'moment';

export const statuses = [
  { title: 'Not Started', value: 'notstarted', order: 0 },
  { title: 'Started', value: 'started', order: 1 },
  { title: 'Partially Completed', value: 'partiallycompleted', order: 2 },
  { title: 'Completed', value: 'completed', order: 3 },
  { title: 'Approved', value: 'approved', order: 4 },
];

export function roleHasPermission(roleIds: number[], staff: UserStaff, student: SelectedStudent) {
  return staff.staff_roles.some(
    x =>
      roleIds.includes(x.role.id) &&
      ((x.student_id && x.student_id === student.id) ||
        (x.institution_id && x.institution_id === student.institution_id) ||
        (x.cohort_id && x.cohort_id === student.cohort.id))
  );
}

export function rolesThatCanComplete(
  roles: Role[],
  studentReview: StudentReview,
  staffViewer: UserStaff,
  student: SelectedStudent
) {
  const contributorRoleIds = studentReview.review.roleIdsThatAreContributors;
  let myRolesThatCanComplete = [];

  const contributorRoles = roles.filter(x => contributorRoleIds.includes(x.id));
  myRolesThatCanComplete = contributorRoles.filter(x => roleHasPermission([x.id], staffViewer, student));

  return myRolesThatCanComplete.filter(r => canMarkAsComplete(r, studentReview) && !submission(r, studentReview));
}

function canMarkAsComplete(role: Role, studentReview: StudentReview) {
  if (
    studentReview.review.completionWindowInWeeks &&
    !moment(studentReview.dueDate).isBefore(moment().add(studentReview.review.completionWindowInWeeks, 'weeks'))
  ) {
    return false;
  }

  const completedOverall = studentReview.overallReviewCompleted
    .filter(x => x.roleId === role.id)
    .every(x => x.completed);

  const completedStandards = studentReview.reviewStandards.every(rs =>
    rs.completed.filter(x => x.roleId === role.id).every(x => x.completed)
  );

  const completedPart2 =
    !studentReview.review.usePart2 ||
    !studentReview.review.roleIdsThatCanEditPart2.includes(role.id) ||
    studentReview.part2Statements.every(s => s.judgementDescriptorId);

  return completedOverall && completedStandards && completedPart2 && !studentReview.approved;
}

function submission(role: Role, studentReview: StudentReview) {
  return !!studentReview.submissions.find(x => x.roleId === role.id);
}

export function studentEditedStandardStatementJudgements(studentReview: StudentReview) {
  return (
    !studentReview.review.canTraineesEditStandardStatementJudgements ||
    studentReview.reviewStandards.every(
      rs => !rs.statementGroups || rs.statementGroups.every(sg => sg.statements.every(s => s.judgementDescriptorId))
    )
  );
}

export function canStudentMarkAsComplete(studentReview: StudentReview) {
  if (
    studentReview.review.completionWindowInWeeks &&
    !moment(studentReview.dueDate).isBefore(moment().add(studentReview.review.completionWindowInWeeks, 'weeks'))
  ) {
    return false;
  }
  const completedSJ = studentEditedStandardStatementJudgements(studentReview);

  const completedOverall = studentReview.overallReviewCompleted.find(x => x.trainee)?.completed;
  const completedStandards = studentReview.reviewStandards.every(rs => rs.completed.find(x => x.trainee)?.completed);
  return completedSJ && completedOverall && completedStandards && !studentReview.approved;
}

type StudentReview = {
  dueDate: string;
  approved: boolean;
  overallReviewCompleted: { completed: boolean; roleId: number; trainee: boolean }[];
  part2Statements: { judgementDescriptorId?: number }[];
  review: {
    roleIdsThatAreContributors: number[];
    roleIdsThatCanEditPart2: number[];
    usePart2: boolean;
    completionWindowInWeeks: number | null;
    canTraineesEditStandardStatementJudgements: boolean;
  };
  reviewStandards: {
    statementGroups: [{ statements: [{ judgementDescriptorId: number }] }];
    completed: { completed: boolean; roleId: number; trainee: boolean }[];
  }[];
  submissions: { roleId: number }[];
};
