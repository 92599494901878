<template>
  <div class="d-flex align-center">
    <mosaic-btn @click.prevent="addStaff">
      <div class="d-flex align-center">
        <mosaic-icon icon="mdi-plus" color="black" />
        <span>Instructor</span>
      </div>
    </mosaic-btn>

    <div class="d-flex pl-1">
      <v-tooltip location="bottom">
        <template #activator="{ props }">
          <v-icon color="primary" dark v-bind="props">mdi-information</v-icon>
        </template>
        <div class="py-2">
          Default password:
          <span class="font-weight-bold">{{ userStaff.institution_staff_default_password }}</span>
          <br />(Instructors will be prompted to change their password on first login)
        </div>
      </v-tooltip>
    </div>
  </div>
  <mosaic-save-dialog
    v-model:active="addStaffDialog.active"
    title="Add Instructor"
    :save="submitAddStaff"
    action="add"
    object-type="Instructor"
    :save-errors="addStaffErrors"
  >
    <v-text-field v-model="addStaffDialog.name" prepend-icon="mdi-account" name="name" label="Name" type="text" />
    <v-text-field v-model="addStaffDialog.email" prepend-icon="mdi-at" name="email" label="Email" type="text" />
    <role-select
      v-model:role="addStaffDialog.selectedRole"
      @update:scope="addStaffDialog.selectedScope = $event"
      @update:no-student-yet-cohort-id="addStaffDialog.selectedNoStudentYetCohortId = $event"
      @update:valid="addStaffDialog.roleAndScopeValid = $event"
    >
    </role-select>
    <div class="d-flex align-center">
      <mosaic-checkbox
        v-model="addStaffDialog.isDemo"
        class="pr-2"
        no-icon
        label="Create as demo account"
      /><mosaic-help
        ><div>Demo accounts are intended for testing or demonstrating functionality</div>
        <div>and will not trigger any automated emails from Mosaic.</div></mosaic-help
      >
    </div>
    <force-microsoft-sso-checkbox v-model="addStaffDialog.forceMicrosoftSso" />
    <div class="pl-2 pb-4" v-if="!addStaffDialog.forceMicrosoftSso">
      Note: the default password for new Instructors is
      <span class="font-weight-bold">{{ userStaff.institution_staff_default_password }}</span>
      <br />(Instructors will be prompted to change their password on first login)
    </div>
    <div class="pl-4">If this Instructor already exists on Mosaic then they will keep their current name.</div>
  </mosaic-save-dialog>
</template>

<script setup lang="ts">
import RoleSelect from '@/components/RoleSelect.vue';
import { validateEmail } from '@/utils/email';
import { useInstitutionStaffStore } from '@/stores/institution-staff';
import ForceMicrosoftSsoCheckbox from '@/components/ForceMicrosoftSsoCheckbox.vue';
import { ref, computed } from 'vue';
import { mapState, mapGetters } from '@/store/map-store';
import { useApi } from '@/composables/api';
import store from '@/store';

const addStaffDialogDefaults = {
  active: false,
  email: '',
  name: '',
  selectedRole: { id: null },
  selectedScope: { type: null, id: null },
  selectedNoStudentYetCohortId: null,
  roleAndScopeValid: false,
  isDemo: false,
  forceMicrosoftSso: false,
};

const addStaffDialog = ref<{
  active: boolean;
  email: string;
  name: string;
  selectedRole: { id: null | number };
  selectedScope: { type: null | string; id: number | null };
  selectedNoStudentYetCohortId: null | number;
  roleAndScopeValid: boolean;
  isDemo: boolean;
  forceMicrosoftSso: boolean;
}>({
  active: false,
  email: '',
  name: '',
  selectedRole: { id: null },
  selectedScope: { type: null, id: null },
  selectedNoStudentYetCohortId: null,
  roleAndScopeValid: false,
  isDemo: false,
  forceMicrosoftSso: false,
});

const api = useApi();

const emit = defineEmits<{
  (e: 'updateStaff'): void;
}>();

const {
  actions: { clearInstitutionStaff },
} = useInstitutionStaffStore();
const { user, selectedInstitution, userStaff } = mapState();
const { traineeNoun, traineeNounArticleCapitalised } = mapGetters();

const addStaffErrors = computed(() => {
  return {
    email_belongs_to_student: `${
      traineeNounArticleCapitalised.value
    } ${traineeNoun.value()} with that email already exists`,
    demo_user_cant_be_in_multiple_institutions: `An Instructor with this email already exists in a different Institution and demo Instructors cannot be in multiple Institutions`,
    user_in_other_institution_is_demo: `A demo Instructor with this email already exists in a different Institution and cannot be added as demo Instructors cannot be in multiple Institutions`,
  };
});

const addStaff = () => {
  addStaffDialog.value.active = true;
  addStaffDialog.value.forceMicrosoftSso = selectedInstitution.value.config.allow_forcing_microsoft_sso;
};

const submitAddStaff = async () => {
  addStaffDialog.value.email = addStaffDialog.value.email?.trim();
  if (!validateEmail(addStaffDialog.value.email)) {
    return 'Please supply a valid email';
  }
  const scope = addStaffDialog.value.selectedScope;
  await api.post(`/institutions/${selectedInstitution.value.id}/staff`, {
    email: addStaffDialog.value.email,
    name: addStaffDialog.value.name,
    roleId: addStaffDialog.value.selectedRole.id,
    institutionId: scope?.type === 'institution' ? scope.id : null,
    studentId: scope?.type === 'student' ? scope.id : null,
    noStudentYetCohortId:
      scope?.type === 'student' && scope?.id === -1 ? addStaffDialog.value.selectedNoStudentYetCohortId : null,
    cohortId: scope?.type === 'cohort' ? scope.id : null,
    schoolId: scope?.type === 'school' ? scope.id : null,
    isDemo: addStaffDialog.value.isDemo,
    forceMicrosoftSso: addStaffDialog.value.forceMicrosoftSso,
  });
  emit('updateStaff');
  clearInstitutionStaff();
  if (addStaffDialog.value.email === user.value.email) {
    await store.dispatch('refreshUser');
  }
  addStaffDialog.value = {
    ...addStaffDialogDefaults,
  };
};
</script>
