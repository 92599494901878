import _ from 'lodash';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function fromSnakeCaseToCamelCase<T>(data: any): T {
  if (_.isArray(data)) {
    return _.map(data, fromSnakeCaseToCamelCase) as T;
  }

  if (_.isObject(data)) {
    return _(data)
      .mapKeys((v, k) => _.camelCase(k))
      .mapValues(v => fromSnakeCaseToCamelCase(v))
      .value() as T;
  }

  return data as T;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function fromCamelCaseToSnakeCase(data: any): any {
  if (_.isArray(data)) {
    return _.map(data, fromCamelCaseToSnakeCase);
  }

  if (_.isObject(data)) {
    return _(data)
      .mapKeys((v, k) => _.snakeCase(k))
      .mapValues(v => fromCamelCaseToSnakeCase(v))
      .value();
  }

  return data;
}
