<template>
  <div>
    <mosaic-loading-card v-if="busy" type="list-no-filters" />
    <mosaic-load-error-card v-else-if="loadError" object-type="portfolio" @retry="load" />
    <standards-list v-else title="Portfolio" :standards="standards" :standard-click-to="standardClickTo" />
  </div>
</template>

<script>
import StandardsList from '../../components/StandardsList.vue';
import { mapState } from 'vuex';

export default {
  name: 'StudentPortfolioOverviewPage',
  data: function () {
    return {
      standards: [],
      busy: true,
      loadError: false,
    };
  },
  computed: {
    ...mapState(['selectedInstitution']),
    breadcrumbs() {
      return [
        {
          text: 'Portfolio',
        },
      ];
    },
  },
  async created() {
    await this.load();
  },
  methods: {
    async load() {
      this.busy = true;
      this.loadError = false;
      try {
        const response = await this.$api.get(`/institutions/${this.selectedInstitution.id}/standards`);
        this.standards = response.data;
      } catch (e) {
        console.log(e);
        this.loadError = true;
      }
      this.busy = false;
    },
    standardClickTo(standardId) {
      return {
        name: 'StudentPortfolioStandardPage',
        params: { standardId: standardId },
      };
    },
  },
  components: { StandardsList },
};
</script>
