<template>
  <div>
    <mosaic-filter-list-page
      v-model:trigger-background-load="triggerBackgroundLoad"
      object-type="Course Activity Template"
      short-object-type="Template"
      :load="load"
      :items="templates"
      :filtered-items="filteredTemplates"
      @add="add"
    >
      <template #list-snackbar>
        <mosaic-error-snackbar
          v-model="publishError"
          :action="`${publishing ? 'publish' : 'unpublish'} this Course Activity Template`"
        />
      </template>
      <template #filters>
        <mosaic-text-field
          v-model="nameFilter"
          name="name-filter"
          label="Filter by Template name"
          style="width: 250px"
          prepend-icon="magnify"
          hide-details
        />
        <mosaic-select
          name="status-filter"
          :items="['All', 'Published', 'Draft']"
          v-model="statusFilter"
          label="Filter by status"
          density="compact"
          hide-details
          no-icon
        />
      </template>

      <template #list-item="{ item: at }">
        <mosaic-list-item
          :key="at.id"
          :title="at.name"
          icon="mdi-clipboard-text"
          :to="{ name: 'TutorAdminAssignmentTemplateEditPage', params: { id: at.id } }"
        >
          <template #information>
            <mosaic-role-completed-chip
              class="pr-2"
              :completed="false"
              :role-id="at.roleId || 'student'"
              :role-scoped-tooltip="(role: RoleWithStudent) => `Activities created using this Template are to be completed by ${role.pluralisedName}`"
            />
            <assignment-templates-published-draft-chip :published="at.status === 'published'" />
          </template>
          <template #actions>
            <mosaic-icon-btn
              v-if="at.status === 'draft'"
              icon="publish"
              :tooltip="
                !at.noPublishReason ? `Publish Course Activity Template` : cannotPublishTooltip(at.noPublishReason)
              "
              :disabled="!!at.noPublishReason && !publishProcessing"
              @click.prevent="publishTemplate(at, true)"
            ></mosaic-icon-btn>
            <mosaic-icon-btn
              v-else
              icon="publish-off"
              :tooltip="
                at.assignmentCount === 0
                  ? `Unpublish Course Activity Template`
                  : `Cannot unpublish Course Activity Template as it's assigned to some Cohorts`
              "
              :disabled="at.assignmentCount > 0 && !publishProcessing"
              @click.prevent="publishTemplate(at, false)"
            ></mosaic-icon-btn>
            <mosaic-icon-btn
              icon="content-copy"
              tooltip="Copy Course Activity Template"
              @click.prevent="copyTemplate(at)"
            />
            <mosaic-icon-btn
              icon="mdi-pencil"
              tooltip="Edit Course Activity Template"
              @click.prevent="editTemplate(at)"
            />
            <mosaic-delete-icon-btn
              v-if="!allTemplatesPublished"
              object-type="Course Activity Template"
              :hidden="at.status === 'published'"
              @click.prevent="deleteTemplate(at)"
            />
          </template>
        </mosaic-list-item>
      </template>
    </mosaic-filter-list-page>

    <assignment-template-edit-dialog
      v-model:active="editTemplateDialog.active"
      :template="editTemplateDialog.template"
      @save="triggerBackgroundLoad = true"
    />

    <mosaic-save-dialog
      v-model:active="copyDialog.active"
      title="Copy Course Activity Template"
      object-type="Course Activity Template"
      action="copy"
      :save="submitCopyTemplate"
      :can-save="!!copyDialog.name"
      @save="triggerBackgroundLoad = true"
    >
      <template #default="{ onKeyupEnter }">
        <mosaic-text-field
          v-model="copyDialog.name"
          name="copy-name"
          label="Name"
          prepend-icon="mdi-pencil"
          @keyup.enter="onKeyupEnter"
        />
      </template>
    </mosaic-save-dialog>

    <mosaic-delete-dialog
      v-model:active="deleteDialog.active"
      :url="deleteDialog.url"
      object-type="Course Activity Template"
      :object-name="deleteDialog.name"
      @delete="triggerBackgroundLoad = true"
    />
  </div>
</template>

<script setup lang="ts">
import { useRouter } from 'vue-router';
import { mapState, mapActions } from '@/store/map-store';
import { setBreadcrumbs } from '@/utils/breadcrumbs';
import { ref } from 'vue';
import MosaicFilterListPage from '@/components/library/pages/MosaicFilterListPage.vue';
import AssignmentTemplatesPublishedDraftChip from './AssignmentTemplatesPublishedDraftChip.vue';
import AssignmentTemplateEditDialog from './AssignmentTemplateEditDialog.vue';
import { computed } from 'vue';
import { cannotPublishTooltip } from './assignment-templates';
import { withProcessingAndError } from '@/composables/processing-and-errors';
import { useApi } from '@/composables/api';
import { capitaliseFirstLetters } from '@/utils/text';
import type { AssignmentTemplate } from './assignment-templates';
import { useEditAssignmentTemplate } from './assignment-templates';
import type { RoleWithStudent } from '@/store/map-store';

const api = useApi();

setBreadcrumbs([
  {
    text: `Course Activity Templates`,
  },
]);

// This is needed for the role chips. Roles are loaded within the chip component as well, but the api call is made once per list-item.
// Callin on page load prevents the api call from being made multiple times.
const { loadRoles } = mapActions();
loadRoles();

const templates = ref<AssignmentTemplate[]>([]);

const { selectedInstitution } = mapState();
async function load() {
  const r = await api.get<AssignmentTemplate[]>(`institutions/${selectedInstitution.value.id}/assignment-templates`);
  templates.value = r.data;
}

const nameFilter = ref('');
const statusFilter = ref('All');
const filteredTemplates = computed(() => {
  return templates.value.filter(
    at =>
      at.name.toLowerCase().includes(nameFilter.value.toLowerCase()) &&
      (capitaliseFirstLetters(at.status) === statusFilter.value || statusFilter.value === 'All')
  );
});

const router = useRouter();
function add() {
  router.push({
    name: 'TutorAdminAssignmentTemplateCreatePage',
  });
}

const triggerBackgroundLoad = ref(false);

const allTemplatesPublished = computed(() => templates.value.every(at => at.status === 'published'));
const deleteDialog = ref({
  active: false,
  name: '',
  url: '',
});
function deleteTemplate(template: AssignmentTemplate) {
  deleteDialog.value = {
    active: true,
    name: template.name,
    url: `/assignment-templates/${template.id}`,
  };
}

const { editTemplateDialog, editTemplate } = useEditAssignmentTemplate();

// Publish/unpublish
const publishing = ref(true);
const {
  action: publishTemplate,
  processing: publishProcessing,
  error: publishError,
} = withProcessingAndError(async (template: AssignmentTemplate, publish: boolean) => {
  publishing.value = publish;
  await api.post(`assignment-templates/${template.id}/publish`, {
    publish,
  });

  templates.value = templates.value.map(t => {
    if (t.id !== template.id) return t;
    return { ...t, status: publish ? 'published' : 'draft' };
  });
});

// Copy
const copyDialog = ref({
  active: false,
  templateId: 0,
  name: '',
});
function copyTemplate(template: AssignmentTemplate) {
  copyDialog.value = {
    active: true,
    templateId: template.id,
    name: `${template.name} (copy)`,
  };
}

async function submitCopyTemplate() {
  await api.post(`assignment-templates/${copyDialog.value.templateId}/copy`, {
    name: copyDialog.value.name,
  });
}
</script>
