<template>
  <v-tooltip location="top">
    <template #activator="{ props }">
      <v-chip :color="color" v-on="onClick ? { click: onClick } : {}" v-bind="props"
        ><v-icon class="mr-1">mdi-calendar-blank</v-icon
        ><span class="font-weight-bold" style="position: relative; left: -19px; top: 2px; font-size: 10px">
          {{ year }}
        </span>
        <span
          v-if="date === 'already-completed' || date === 'already-assigned' || date === 'none'"
          style="width: 50px"
          class="text-center mr-2"
        >
          <v-icon v-if="date === 'already-completed' || date === 'already-assigned'">mdi-check</v-icon>
          <v-icon v-else>mdi-close</v-icon>
        </span>
        <span v-else>{{ formatDate(date) }}</span>
      </v-chip>
    </template>
    <span v-if="date === 'already-completed'">End of Year {{ year }} already completed</span>
    <span v-else-if="date === 'already-assigned'">End of Year {{ year }} already assigned</span>
    <span v-else-if="date === 'none'"
      >No End of Year {{ year }} projected<span v-if="noDateReason && noDateReason === 'leaving'">
        (as this {{ traineeNoun() }} is leaving)</span
      ><span v-if="noDateReason && noDateReason === 'paused'"> (as this {{ traineeNoun() }} is paused)</span
      ><span v-if="noDateReason && noDateReason === 'error'"> (due to an error)</span></span
    >
    <span v-else
      >End of Year {{ year }} projected to be {{ formatDate(date) }}
      <div v-if="dateIsInThePast">
        (this is in the past and the end of year {{ reviewNounCapitalised }} has not been approved)
      </div></span
    >
  </v-tooltip>
</template>

<script>
import moment from 'moment';

export default {
  name: 'MosaicEndOfYearBadge',
  props: {
    year: {
      type: String, // "1" or "2"
      required: true,
    },
    date: {
      type: String, // "already-completed" | "already-assigned" | "none" | date
      required: true,
    },
    noDateReason: {
      type: String,
    },
    showErrorIfDateInPast: {
      type: Boolean,
      default: false,
    },
    onClick: {
      type: Function,
      required: false,
    },
  },
  computed: {
    dateIsInThePast() {
      return moment(this.date) < moment() && this.showErrorIfDateInPast;
    },
    color() {
      if (this.date === 'already-completed') return 'secondary';
      if (this.date === 'already-assigned') return 'primary';
      if (this.date === 'none') return 'secondary';
      if (this.dateIsInThePast) return 'error';
      return 'accent';
    },
  },
};
</script>
