<template>
  <div>
    <legacy-mosaic-inline-creation-list-page
      object-type="Page"
      :title="(module && module.name) || ''"
      :subtitle="staffTrainingModuleExpectedDuration(module)"
      :load="load"
      :items="sortedSections"
      icon="mdi-list-box-outline"
      :hide-add="published"
      :can-add-item="canAddSection"
      :add-item="addSection"
      :can-retitle-item="canRetitleSection"
      :retitle-item-dialog-opened="retitleSection"
      :retitle-item="saveRetitleSection"
      delete-url-stem="/staff-training/modules/sections"
      :move-item="moveSectionListItem"
      :edit-title="editTitle"
    >
      <template #title-chip>
        <mosaic-published-draft-chip
          mode="card"
          :published="published"
          object-type="Training Module"
          :show-published-message="true"
        />
      </template>

      <template #subtitle>
        <div v-if="published && module">
          This Module is a requirement for the following Competencies:
          <span v-for="(c, index) of module.staffTrainingCompetencies" :key="c.id">
            <mosaic-router-link
              :to="{
                name: 'InstitutionStaffTrainingCompetencyPage',
                params: { frameworkId: c.frameworkId, themeId: c.themeId, id: c.id },
              }"
              >{{ c.name }} ({{ c.frameworkName }})</mosaic-router-link
            ><span v-if="index !== module.staffTrainingCompetencies.length - 1">, </span>
          </span>
        </div>
      </template>

      <template #add-item-fields>
        <mosaic-text-field
          v-model="addSectionName"
          name="add-section-name"
          label="Name"
          prepend-icon="mdi-pencil"
          class="flex-grow-1"
        />
      </template>
      <template #retitle-item-fields="{ onKeyupEnter }">
        <mosaic-text-field
          v-model="renameSectionDialog.name"
          name="rename-section-name"
          label="Name"
          prepend-icon="mdi-pencil"
          @keyup.enter="onKeyupEnter"
        />
      </template>
    </legacy-mosaic-inline-creation-list-page>

    <institution-staff-training-module-edit-dialog
      v-if="editDialog.module"
      v-model:active="editDialog.active"
      :module="editDialog.module"
      @save="load"
    />
  </div>
</template>

<script setup lang="ts">
import { setBreadcrumbs } from '@/utils/breadcrumbs';
import { computed, ref } from 'vue';
import type { Item } from '@/components/library/mosaic-list/list-item-helpers';
import { useMoveListItem, useRetitleListItem } from '@/components/library/mosaic-list/list-item-helpers';
import { parseRouteId } from '@/composables/vue-router';
import { useApi } from '@/composables/api';
import InstitutionStaffTrainingModuleEditDialog from './InstitutionStaffTrainingModuleEditDialog.vue';
import { staffTrainingModuleExpectedDuration } from '@/pages/staff-training/staff-training';

const api = useApi();

const moduleId = parseRouteId('id');

export type StaffTrainingModule = {
  id: number;
  name: string;
  durationHours: number | null;
  durationMinutes: number | null;
  themeId: number;
  frameworkId: number;
  status: 'published' | 'unpublished';
  hasStaffCompletions: boolean;
  staffTrainingModuleSections: Section[];
  staffTrainingCompetencies: {
    id: number;
    name: string;
    frameworkName: string;
    themeId: number;
    frameworkId: number;
  }[];
};

type Section = {
  id: number;
  name: string;
  order: number;
};

const module = ref<StaffTrainingModule | null>(null);
const name = computed(() => module.value?.name || '');

const published = computed(() => module.value?.status === 'published');

async function load() {
  const r = await api.get<StaffTrainingModule>(`/staff-training/modules/${moduleId.value}`);
  module.value = r.data;
}

setBreadcrumbs(
  computed(() => [
    {
      text: 'Instructors',
      to: {
        name: 'InstitutionStaffListPage',
      },
    },
    {
      text: 'Training',
      to: {
        name: 'InstitutionStaffTrainingPage',
      },
    },
    {
      text: 'Modules',
      to: {
        name: 'InstitutionStaffTrainingPage',
        query: { tab: 'modules' },
      },
    },
    {
      text: name.value,
    },
  ])
);

// Edit
const editDialog = ref<{
  active: boolean;
  module: StaffTrainingModule | null;
}>({
  active: false,
  module: null,
});

function editTitle() {
  editDialog.value = {
    active: true,
    module: module.value,
  };
}

// Transform Sections to Simple List Items

const sortedSections = computed(() => {
  let sections: Item[] = [];
  if (module.value) {
    sections = module.value.staffTrainingModuleSections
      .map(s => ({
        id: s.id,
        order: s.order,
        title: s.name,
        hideMove: published.value,
        disableDelete: published.value,
        deleteDisabledTooltip: published.value ? 'Cannot delete Pages from published Training Modules' : '',
        clickTo: {
          name: 'InstitutionStaffTrainingModuleSectionPage',
          params: { moduleId: moduleId.value.toString(), id: s.id.toString() },
        },
      }))
      .sortBy('order');
  }
  return sections;
});

// Add Section

const addSectionName = ref('');
const canAddSection = computed(() => !!addSectionName.value);

async function addSection() {
  await api.post(`/staff-training/modules/${moduleId.value}/sections`, { name: addSectionName.value });
}

// Move Section

const { moveListItem: moveSectionListItem } = useMoveListItem('staff-training/modules/sections', api);

// Retitle Section

const renameSectionDialog = ref({
  name: '',
  originalName: '',
});

function retitleSection(sectionItem: Item) {
  const section = module.value?.staffTrainingModuleSections.find(s => s.id === sectionItem.id);
  if (!section) return;
  renameSectionDialog.value = {
    name: section.name,
    originalName: section.name,
  };
}

const canRetitleSection = computed(
  () => !!renameSectionDialog.value.name && renameSectionDialog.value.name !== renameSectionDialog.value.originalName
);

const body = computed(() => ({
  name: renameSectionDialog.value.name,
}));
const { retitleListItem: saveRetitleSection } = useRetitleListItem('staff-training/modules/sections', body, api);
</script>
