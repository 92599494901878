<template>
  <div class="monitoring-v-select">
    <mosaic-select
      v-model="selectedPlacementIdInternal"
      name="placement-filter"
      :items="sortedPlacements"
      item-value="id"
      item-title="name"
      label="Select"
      hide-details
      single-line
      prepend-icon="mdi-calendar"
      class="py-0 mt-0"
    >
      <template #item="{ item, props }">
        <v-list-item v-bind="props" title="">
          <div>
            {{ item.raw.name }}
            ({{ formattedPlacementDates(item) }})
          </div>
        </v-list-item>
      </template>
      <template #selection="{ item }">
        <div>
          {{ item.raw.name }}
          ({{ formattedPlacementDates(item) }})
        </div>
      </template>
    </mosaic-select>
  </div>
</template>

<script>
import moment from 'moment';
import { syncQueryParamsMixin } from '../../mixins/query-mixins';
import { useQueryStore } from '@/stores/query';
import { mapState } from 'vuex';

export default {
  name: 'MonitoringPlacementSelect',
  setup() {
    const queryStore = useQueryStore();
    return { queryStore };
  },
  mixins: [
    syncQueryParamsMixin({
      selectedPlacementIdInternal: { query: 'placement', type: 'integer' },
    }),
  ],
  props: {
    selectedPlacementId: {
      type: Number,
      required: true,
    },
  },
  emits: ['update:selectedPlacementId'],
  data() {
    return {
      selectedPlacementIdInternal: this.selectedPlacementId,
    };
  },
  computed: {
    ...mapState(['selectedCohort']),
    placements() {
      return this.selectedCohort.placements;
    },
    sortedPlacements() {
      return this.placements.sortBy('start_date');
    },
  },
  watch: {
    selectedPlacementIdInternal(x) {
      this.$emit('update:selectedPlacementId', x);
    },
  },
  methods: {
    formattedPlacementDates(placement) {
      return (
        moment(placement.raw.start_date).format('DD/MM/YY') + ' - ' + moment(placement.raw.end_date).format('DD/MM/YY')
      );
    },
  },
};
</script>

<style>
.monitoring-v-select .v-select__selections input {
  display: none;
}
</style>
