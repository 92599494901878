<template>
  <legacy-mosaic-inline-creation-list-page
    object-type="Certificate Type"
    title="Certificate Types"
    :load="load"
    :items="items"
    :icon="icons.certificate"
    :hide-add="false"
    :can-add-item="canAdd"
    :add-item="addItem"
    :can-retitle-item="canRetitle"
    :retitle-item-dialog-opened="retitleItemDialogOpened"
    :retitle-item="retitleItem"
    delete-url-stem="/admin/certificate-types"
  >
    <template #add-item-fields>
      <mosaic-text-field
        prepend-icon="pencil"
        name="new-certificate-type"
        label="Name"
        class="flex-grow-1"
        v-model="newCertificateTypeName"
      />
    </template>

    <template #retitle-item-fields="{ onKeyupEnter }">
      <mosaic-text-field
        prepend-icon="pencil"
        name="rename-certificate-type"
        label="Name"
        class="flex-grow-1"
        @keyup.enter="onKeyupEnter"
        v-model="renameDialog.name"
    /></template>
  </legacy-mosaic-inline-creation-list-page>
</template>

<script setup lang="ts">
import { useApi } from '@/composables/api';
import { computed, ref } from 'vue';
import { icons } from '@/utils/icons';
import type { Item } from '@/components/library/mosaic-list/list-item-helpers';

const api = useApi();

interface CertificateTypeAdminResponse {
  id: number;
  name: string;
  inUse: boolean;
}

type CertificateTypeItem = CertificateTypeAdminResponse & Item;

const certificateTypes = ref<CertificateTypeAdminResponse[]>([]);
const items = computed<CertificateTypeItem[]>(() =>
  certificateTypes.value
    .sortBy(c => c.name)
    .map((c, i) => ({
      ...c,
      title: c.name,
      disableDelete: c.inUse,
      deleteDisabledTooltip: 'Cannot delete Certificate Types that are in use',
      hideMove: true,
      order: i,
    }))
);
async function load() {
  const r = await api.get<CertificateTypeAdminResponse[]>(`/admin/certificate-types`);
  certificateTypes.value = r.data;
}

const newCertificateTypeName = ref('');
const canAdd = computed(() => !!newCertificateTypeName.value);

async function addItem() {
  await api.post(`/admin/certificate-types`, { name: newCertificateTypeName.value });
}

const renameDialog = ref({ name: '', originalName: '' });
const canRetitle = computed(
  () => !!renameDialog.value.name && renameDialog.value.name !== renameDialog.value.originalName
);

function retitleItemDialogOpened(certificateType: CertificateTypeItem) {
  renameDialog.value = {
    name: certificateType.name,
    originalName: certificateType.name,
  };
}

async function retitleItem(id: number) {
  await api.put(`/admin/certificate-types/${id}`, { name: renameDialog.value.name });
}
</script>
