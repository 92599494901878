<template>
  <mosaic-tab-item-list
    :items="sortedEvents"
    :empty-text="`There are no Events${
      events.length === 0 ? '' : ' for these filters'
    }. Add one by clicking the button above.`"
    :action="{
      icon: 'plus',
      text: 'Event',
      click: addEvent,
    }"
    mosaic-key="events"
  >
    <template #filters>
      <mosaic-text-field
        dense
        no-icon
        hide-details
        name="name-filter"
        label="Filter by name"
        v-model="nameFilter"
        style="width: 200px"
      />
      <mosaic-select label="Filter by status" :items="statusItems" v-model="statusFilter" hide-details dense no-icon />

      <mosaic-date-range-filter
        object-type-plural="Events"
        v-model:start-date="startDateFilter"
        v-model:end-date="endDateFilter"
      />
    </template>

    <template #item="{ item }">
      <mosaic-list-item
        :title="item.name"
        :icon="icons.instructorTrainingEvent"
        :subtitle="`${formatDateTime(item.startsAt)} - Duration ${formatDuration(
          item.durationHours,
          item.durationMinutes
        )}${
          item.status === 'published' && dateTimeIsInThePast(item.startsAt)
            ? ` - ${enumerateItems(item.attendanceCount, 'Instructor')} attended`
            : ''
        }`"
        :to="{
          name: 'InstitutionStaffTrainingEventEditPage',
          params: { id: item.id },
        }"
      >
        <template #information>
          <mosaic-warning-icon
            v-if="item.status === 'published' && item.attendanceCount === 0 && dateTimeIsInThePast(item.startsAt)"
            tooltip="This Event is in the past and has no attendance recorded"
          />
          <mosaic-published-draft-chip
            :published="item.status === 'published'"
            object-type="Event"
            published-tooltip="This Event has been published and is visible to Instructors"
            draft-tooltip="This Event has not been published and is not visible to Instructors"
          />
        </template>

        <template #actions>
          <mosaic-error-snackbar :model-value="item.id == publishEventId && publishError" action="publish" contained />
          <mosaic-icon-btn
            v-if="item.status !== 'published'"
            icon="mdi-publish"
            tooltip="Publish Event"
            @click.prevent="publish(item)"
            :disabled="publishProcessing || unpublishProcessing"
            :loading="item.id == publishEventId && publishProcessing"
          />
          <mosaic-icon-btn
            v-else
            icon="mdi-publish-off"
            tooltip="Unpublish Event"
            @click.prevent="unpublish(item)"
            :disabled="publishProcessing || unpublishProcessing"
            :loading="item.id == unpublishEventId && unpublishProcessing"
          />
          <mosaic-error-snackbar
            :model-value="item.id == unpublishEventId && unpublishError"
            action="unpublish"
            contained
          />
          <mosaic-delete-icon-btn object-type="Event" @click.prevent="deleteEvent(item)" />
        </template>
      </mosaic-list-item>
    </template>
  </mosaic-tab-item-list>

  <mosaic-delete-dialog
    v-model:active="deleteItemDialog.active"
    :url="deleteItemDialog.url"
    object-type="Event"
    :object-name="deleteItemDialog.title"
    @delete="eventDeleted"
  >
    <mosaic-alert type="warning" v-if="deleteItemDialog.inUse">
      This Event already has attendance recorded and will be removed from Instructor's training records.
    </mosaic-alert>
  </mosaic-delete-dialog>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import { icons } from '@/utils/icons';
import { useStaffTrainingStore } from '@/stores/staff-training';
import { withProcessingAndError } from '@/composables/processing-and-errors';
import { useApi } from '@/composables/api';
import { formatDateTime, dateTimeIsInThePast } from '@/utils/date';
import { formatDuration } from '@/utils/time';
import { enumerateItems } from '@/utils/text';
import { filterByDateRange, filterByString } from '@/components/library/filters/filters';

const props = defineProps<{
  events: StaffTrainingEventSlimResponse[];
  triggerBackgroundLoad?: boolean;
}>();

const emit = defineEmits<{
  'update:triggerBackgroundLoad': [triggerBackgroundLoad: boolean];
}>();

interface StaffTrainingEventSlimResponse {
  id: number;
  name: string;
  status: 'draft' | 'published';
  startsAt: string;
  durationHours: number;
  durationMinutes: number;
  attendanceCount: number;
}

const api = useApi();

const {
  actions: { clearAllStaffTraining },
} = useStaffTrainingStore();

const nameFilter = ref('');
const startDateFilter = ref<string | null>(null);
const endDateFilter = ref<string | null>(null);

const statusFilter = ref(null);
const statusItems = [
  { text: 'All statuses', value: null },
  { text: 'Published', value: 'published' },
  { text: 'Draft', value: 'draft' },
];

const sortedEvents = computed(() =>
  props.events
    .filter(
      e =>
        (statusFilter.value == null ||
          (statusFilter.value == 'published' && e.status == 'published') ||
          (statusFilter.value == 'draft' && e.status == 'draft')) &&
        filterByString(e.name, nameFilter.value) &&
        filterByDateRange(e.startsAt, startDateFilter.value, endDateFilter.value)
    )
    .sortBy(e => e.startsAt, 'desc')
);

const router = useRouter();
function addEvent() {
  router.push({
    name: 'InstitutionStaffTrainingEventCreatePage',
  });
}

const deleteItemDialog = ref({ active: false, title: '', url: '', inUse: false });
function deleteEvent(event: StaffTrainingEventSlimResponse) {
  deleteItemDialog.value = {
    active: true,
    title: event.name,
    inUse: event.status == 'published' && event.attendanceCount > 0,
    url: `/staff-training/events/${event.id}`,
  };
}

function eventDeleted() {
  clearAllStaffTraining();
  emit('update:triggerBackgroundLoad', true);
}

// #region publish
const publishEventId = ref(-1);
const {
  action: publish,
  processing: publishProcessing,
  error: publishError,
} = withProcessingAndError(async (event: StaffTrainingEventSlimResponse) => {
  publishEventId.value = event.id;
  await api.post(`/staff-training/events/${event.id}/publish`, {});
  clearAllStaffTraining();
  emit('update:triggerBackgroundLoad', true);
});

const unpublishEventId = ref(-1);
const {
  action: unpublish,
  processing: unpublishProcessing,
  error: unpublishError,
} = withProcessingAndError(async (event: StaffTrainingEventSlimResponse) => {
  unpublishEventId.value = event.id;
  await api.post(`/staff-training/events/${event.id}/unpublish`, {});
  clearAllStaffTraining();
  emit('update:triggerBackgroundLoad', true);
});

// #endregion
</script>
