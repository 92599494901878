import { DateTime } from 'luxon';
import type { InstitutionRegion } from '@/store/map-store';
import { formatTwoDigits } from '@/utils/text';

export function institutionTimeNow(region: InstitutionRegion): DateTime {
  if (region === 'english') {
    return DateTime.utc().setZone('Europe/London');
  } else {
    return DateTime.utc().setZone('Australia/Sydney');
  }
}

export function institutionTimeTomorrowAsDate(region: InstitutionRegion): string {
  return institutionTimeNow(region).plus({ day: 1 }).toISODate();
}

export function formatDuration(hours: number, minutes: number) {
  const durationMinutes = minutes > 0 ? ` ${formatTwoDigits(minutes)}m` : '';
  return `${hours}h${durationMinutes}`;
}

export function formatTime(hours: number, minutes: number) {
  return `${hours || 0}:${formatTwoDigits(minutes || 0)}`;
}

export function formatTimeToString(hours: number, minutes: number): string {
  return `${hours}h ${minutes}min`;
}

export function formatTimeToStringEmptyIfZero(hours: number, minutes: number): string {
  const h = hours > 0 ? `${hours}h` : '';
  const m = minutes > 0 ? `${minutes}min` : '';
  return [h, m].filter(Boolean).join(' ');
}

export function formatTimeStringInParenthesis(hours: number, minutes: number): string {
  const time = formatTimeToStringEmptyIfZero(hours, minutes);
  return time.trim() !== '' ? `(${time})` : '';
}

export function splitTimeIntoHoursAndMinutes(time: string) {
  const [hours, minutes] = time.split(':');
  return { hours: parseInt(hours), minutes: parseInt(minutes) };
}

export function convertMinutesToHoursAndMinutes(totalMinutes: number): { hours: number; minutes: number } {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  return { hours, minutes };
}

export function calculateTotalTime(hours: number, minutes: number): number {
  return hours + (minutes > 0 ? parseFloat(((minutes * 10) / 60 / 10).toFixed(2)) : 0);
}

export function addTime(
  time1: { hours: number; minutes: number },
  time2: { hours: number; minutes: number }
): { hours: number; minutes: number } {
  const totalMinutes = time1.hours * 60 + time1.minutes + time2.hours * 60 + time2.minutes;
  return convertMinutesToHoursAndMinutes(totalMinutes);
}
