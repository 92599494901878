import type { CurriculumStatementSlim, CurriculumStatementSlimLegacy } from '@/store/map-store';
import type { Ref } from 'vue';
import { ref, watchEffect } from 'vue';
import { fromSnakeCaseToCamelCase } from '@/utils/transforms';

export function useCurriculumLinks(
  artefact: Ref<
    | {
        curriculumStatements: CurriculumStatementSlim[];
      }
    | { curriculum_statements: CurriculumStatementSlimLegacy[] }
  >
) {
  const curriculumStatements = ref<CurriculumStatementSlim[]>([]);
  watchEffect(() => {
    const camelArtefact = fromSnakeCaseToCamelCase<{ curriculumStatements: CurriculumStatementSlim[] }>(artefact.value);
    curriculumStatements.value = [...camelArtefact.curriculumStatements];
  });
  function addCurriculumLink(x: CurriculumStatementSlim) {
    curriculumStatements.value.push(x);
  }
  function removeCurriculumLink(x: CurriculumStatementSlim) {
    const index = curriculumStatements.value.findIndex(s => s.id === x.id);
    curriculumStatements.value.splice(index, 1);
  }
  return { curriculumStatements, addCurriculumLink, removeCurriculumLink };
}
