<template>
  <div>
    <v-card v-if="error">
      <v-card-text>
        <div class="pa-4">{{ error }}</div>
      </v-card-text>
    </v-card>
    <template v-else-if="!busy">
      <mosaic-card>
        <div class="mb-2">
          <mosaic-card-title>Training Plan</mosaic-card-title>
          <div class="mb-2">
            A Cohort's Training Plan is a sequence of content and activities<span
              v-if="selectedInstitution.config.has_curriculum"
              >, linked to parts of your Curriculum,</span
            >
            designed to structure each {{ traineeNoun() }}'s learning journey. A Training Plan is made up of
            {{ cohortCourseTermNounCapitalisedAndPluralised }} and then
            {{ cohortCourseWeekNounCapitalisedAndPluralised }}. Any common content can be configured using the
            {{ cohortCourseWeekNounCapitalised }} Template.
          </div>

          <mosaic-card-heading>Configuration</mosaic-card-heading>
          <v-list lines="two">
            <mosaic-list-item
              :to="editWeekTemplateTo()"
              icon="mdi-calendar-edit"
              :title="`Edit ${cohortCourseWeekNounCapitalised} Template`"
              :subtitle="`This template will be copied into all newly created ${cohortCourseWeekNounCapitalisedAndPluralised}`"
              ><template #information
                ><mosaic-warning-icon
                  v-if="emptyTemplateWarningCapitalised"
                  :tooltip="emptyTemplateWarningCapitalised" /></template
            ></mosaic-list-item>

            <mosaic-list-item
              @click="editCourseNouns()"
              icon="mdi-format-letter-starts-with"
              title="Edit Training Plan Nouns"
              :subtitle="`Configure the language used for ${cohortCourseTermNounCapitalisedAndPluralised} and ${cohortCourseWeekNounCapitalisedAndPluralised} in this Training Plan`"
            />
          </v-list>
        </div>

        <div>
          <mosaic-card-heading>{{ cohortCourseTermNounCapitalisedAndPluralised }}</mosaic-card-heading>
          <div v-if="selectedCohortCourse.cohortCourseTerms.length === 0" class="pa-2">
            There are no {{ cohortCourseTermNounCapitalisedAndPluralised }} for this Training Plan
          </div>
          <mosaic-list v-else :items="sortedTerms">
            <template #item="{ item: term }">
              <mosaic-list-item
                :to="clickTermTo(term)"
                icon="mdi-hexagon-slice-2"
                :title="term.name"
                :subtitle="
                  term.cohortCourseWeeks.length
                    ? `${formatDate(term.startDate)} - ${formatDate(term.endDate)} (${term.cohortCourseWeeks.length} ${
                        term.cohortCourseWeeks.length > 1
                          ? cohortCourseWeekNounCapitalisedAndPluralised
                          : cohortCourseWeekNounCapitalised
                      })`
                    : `No ${cohortCourseWeekNounCapitalisedAndPluralised}`
                "
              >
                <template #actions>
                  <ndt-icon-button
                    icon="pencil"
                    :tooltip="`Edit ${cohortCourseTermNounCapitalised}`"
                    @click.prevent="editTerm(term)"
                  ></ndt-icon-button>
                  <ndt-icon-button
                    icon="delete"
                    :tooltip="
                      canDeleteTerm(term)
                        ? 'Delete Term'
                        : `Cannot delete ${cohortCourseTermNounCapitalised} with published ${cohortCourseWeekNounCapitalisedAndPluralised}`
                    "
                    :disabled="!canDeleteTerm(term)"
                    @click.prevent="deleteTerm(term)"
                  ></ndt-icon-button>
                </template>
              </mosaic-list-item>
            </template>
          </mosaic-list>
        </div>

        <div class="text-h6 mt-2">Add New {{ cohortCourseTermNounCapitalised }}</div>
        <div class="d-flex align-end">
          <div class="flex-grow-1">
            <mosaic-text-field
              v-model="termName"
              name="term-name"
              label="Name"
              prepend-icon="mdi-pencil"
              hide-details
            />
          </div>
          <div class="pl-4">
            <mosaic-btn :disabled="!canAddTerm" :loading="addTermProcessing" @click.prevent="addTerm()"
              >Add {{ cohortCourseTermNounCapitalised }}</mosaic-btn
            >
          </div>
        </div>
        <div class="d-flex align-center">
          <div class="mt-3">
            <mosaic-checkbox
              v-model="addWeeks"
              name="add-weeks"
              :label="`Pre-populate ${cohortCourseWeekNounCapitalisedAndPluralised} every 7 days?`"
              :help-text="`If selected, ${cohortCourseWeekNounCapitalisedAndPluralised} will be created every 7 days between ${cohortCourseTermNounCapitalised} start and end dates chosen by you. Otherwise, you will need to manually create ${cohortCourseWeekNounCapitalisedAndPluralised}`"
            />
          </div>
          <template v-if="addWeeks">
            <div class="ml-4 pb-2">
              <mosaic-date-picker v-model:date="termStartDate" name="term-start-date" label="Start date" hide-details />
            </div>
            <div class="ml-4 pb-2">
              <mosaic-date-picker v-model:date="termEndDate" name="term-end-date" label="End date" hide-details />
            </div>
            <mosaic-error-icon
              v-if="isSameOrAfter(termStartDate, termEndDate)"
              tooltip="The start date must be before the end date"
            />
            <mosaic-error-icon v-if="emptyTemplateAddWeekWarning" :tooltip="emptyTemplateAddWeekWarning" />
          </template>
        </div>
        <mosaic-error-alert :override-error-message="addTermError" />
      </mosaic-card>
    </template>

    <ndt-dialog v-model:active="editTermDialog.active" title="Edit Term" :error-message="editTermDialog.error">
      <mosaic-text-field v-model="editTermDialog.name" name="term-name" label="Name" prepend-icon="mdi-pencil" />
      <template #buttons>
        <v-btn variant="text" ripple :disabled="!canEditTerm" @click.prevent="submitEditTerm()">Save</v-btn>
      </template>
    </ndt-dialog>
    <ndt-dialog
      v-model:active="editCourseNounsDialog.active"
      title="Edit Training Plan Nouns"
      :error-message="editCourseNounsDialog.error"
    >
      <div class="pb-4">
        Below, the "Week noun" is used to describe the basic unit of a course, containing elements for
        {{ traineeNounPluralised() }} to engage with. The "Term noun" refers to a collection of these basic Training
        Plan units, ordered by date.
      </div>
      <div>All appearances of these nouns across Mosaic will be updated for all users.</div>

      <mosaic-text-field
        v-model="editCourseNounsDialog.termNoun"
        name="term-noun"
        label="Term Noun"
        prepend-icon="mdi-pencil"
      />
      <mosaic-text-field
        v-model="editCourseNounsDialog.termNounPlural"
        name="term-noun-plural"
        label="Term Noun Plural"
        prepend-icon="mdi-pencil"
      />
      <mosaic-text-field
        v-model="editCourseNounsDialog.weekNoun"
        name="week-noun"
        label="Week Noun"
        prepend-icon="mdi-pencil"
      />
      <mosaic-text-field
        v-model="editCourseNounsDialog.weekNounPlural"
        name="week-noun-plural"
        label="Week Noun Plural"
        prepend-icon="mdi-pencil"
      />
      <template #buttons>
        <v-btn variant="text" ripple :disabled="!canEditCourseNouns" @click.prevent="submitEditCourseNouns()"
          >Save</v-btn
        >
      </template>
    </ndt-dialog>
    <mosaic-delete-dialog
      v-model:active="deleteTermDialog.active"
      :url="deleteTermDialog.url"
      object-type="term"
      :object-name="deleteTermDialog.name"
      @delete="loadCohortCourse(true)"
    />
  </div>
</template>

<script>
import NdtDialog from '@/components/NdtDialog.vue';
import NdtIconButton from '@/components/NdtIconButton.vue';
import { useCohortCourseStore } from '@/stores/cohort-course';
import { mapState } from 'vuex';

const defaultEditTermDialog = {
  active: false,
  processing: false,
  error: '',
  id: null,
  name: '',
};

export default {
  name: 'TutorAdminCohortCoursePage',
  components: {
    NdtIconButton,
    NdtDialog,
  },
  setup() {
    const {
      selectedCohortCourse,
      cohortCourseWeekNounCapitalised,
      cohortCourseTermNounCapitalised,
      cohortCourseWeekNoun,
      cohortCourseTermNoun,
      cohortCourseWeekNounPluralised,
      cohortCourseTermNounPluralised,
      cohortCourseTermNounCapitalisedAndPluralised,
      cohortCourseWeekNounCapitalisedAndPluralised,
      actions: { loadCohortCourse },
    } = useCohortCourseStore();
    return {
      selectedCohortCourse,
      cohortCourseTermNounCapitalised,
      loadCohortCourse,
      cohortCourseWeekNounCapitalised,
      cohortCourseWeekNoun,
      cohortCourseTermNoun,
      cohortCourseWeekNounPluralised,
      cohortCourseTermNounPluralised,
      cohortCourseTermNounCapitalisedAndPluralised,
      cohortCourseWeekNounCapitalisedAndPluralised,
    };
  },
  data: function () {
    return {
      busy: true,
      error: null,
      termName: '',
      termStartDate: null,
      termEndDate: null,
      addWeeks: true,
      addTermProcessing: false,
      addTermError: '',
      editTermDialog: {
        ...defaultEditTermDialog,
      },
      editCourseNounsDialog: {
        active: false,
        processing: false,
        error: '',
        weekNoun: '',
        weekNounPlural: '',
        termNoun: '',
        termNounPlural: '',
      },

      deleteTermDialog: {
        active: false,
        url: '',
        name: '',
      },
    };
  },

  computed: {
    ...mapState(['selectedCohort', 'selectedInstitution']),
    breadcrumbs() {
      return [
        {
          text: 'Training Plan',
        },
      ];
    },
    canAddTerm() {
      return (
        this.termName &&
        (!this.addWeeks ||
          (this.termStartDate && this.termEndDate && this.termStartDate < this.termEndDate && !this.addTermProcessing))
      );
    },
    canEditTerm() {
      return this.editTermDialog.name && !this.editTermDialog.processing;
    },
    canEditCourseNouns() {
      return (
        !this.editCourseNounsDialog.processing &&
        this.editCourseNounsDialog.weekNoun &&
        this.editCourseNounsDialog.weekNounPlural &&
        this.editCourseNounsDialog.termNoun &&
        this.editCourseNounsDialog.termNounPlural
      );
    },
    emptyTemplateAddWeekWarning() {
      if (!this.addWeeks || !this.emptyTemplateWarning) return '';
      const stem = `You are pre-populating ${this.cohortCourseWeekNounCapitalisedAndPluralised}, but `;
      return stem + this.emptyTemplateWarning;
    },
    emptyTemplateWarning() {
      return this.selectedCohortCourse.cohortCourseWeekTemplateSections.length === 0
        ? 'the Template has no Pages'
        : this.selectedCohortCourse.cohortCourseWeekTemplateSections.some(x => x.contentLayout.sections.length === 0)
        ? `at least one of the ${this.cohortCourseWeekNounCapitalised} Template Pages is empty`
        : this.selectedCohortCourse.cohortCourseWeekTemplateSections.some(x =>
            x.contentLayout.sections.some(s => s.items.length === 0 && !s.heading)
          )
        ? 'at least one of the Template Pages has empty Sections with no Heading'
        : '';
    },
    emptyTemplateWarningCapitalised() {
      return this.emptyTemplateWarning.charAt(0).toUpperCase() + this.emptyTemplateWarning.slice(1);
    },
    sortedTerms() {
      return this.selectedCohortCourse.cohortCourseTerms.sortBy('startDate');
    },
  },
  async created() {
    if (this.selectedCohort.cohort_course) {
      await this.loadCourse();
      if (this.selectedCohortCourse) {
        this.editCourseNounsDialog = {
          active: false,
          processing: false,
          error: '',
          weekNoun: this.cohortCourseWeekNoun,
          weekNounPlural: this.cohortCourseWeekNounPluralised,
          termNoun: this.cohortCourseTermNoun,
          termNounPlural: this.cohortCourseTermNounPluralised,
        };
      }
    } else {
      this.busy = false;
    }
  },
  methods: {
    async loadCourse() {
      this.busy = true;
      try {
        await this.loadCohortCourse();
        this.addWeeks = this.selectedCohortCourse.cohortCourseWeekTemplateSections.some(
          x => x.contentLayout.sections.length > 0
        );
      } catch (e) {
        console.log(e);
        this.error = 'Sorry, cannot load this course at the moment';
      }
      this.busy = false;
    },
    async addTerm() {
      this.addTermProcessing = true;
      this.addTermError = '';
      try {
        await this.$api.post(`/cohorts/${this.selectedCohort.id}/course/terms`, {
          name: this.termName,
          startDate: this.termStartDate,
          endDate: this.termEndDate,
          addWeeks: this.addWeeks,
        });
        await this.loadCohortCourse(true);
        this.termName = '';
        this.termStartDate = null;
        this.termEndDate = null;
      } catch (e) {
        console.log(e);
        this.addTermError = 'Sorry, cannot add a term at the moment';
      }
      this.addTermProcessing = false;
    },
    deleteTerm(term) {
      this.deleteTermDialog = {
        active: true,
        url: `/cohorts/${this.selectedCohort.id}/course/terms/${term.id}`,
        name: term.name,
      };
    },
    editTerm(term) {
      this.editTermDialog = {
        ...this.editTermDialog,
        active: true,
        id: term.id,
        name: term.name,
      };
    },
    editCourseNouns() {
      this.editCourseNounsDialog.active = true;
    },
    async submitEditCourseNouns() {
      this.editCourseNounsDialog.processing = true;
      const nouns = {
        weekNoun: this.editCourseNounsDialog.weekNoun.toLowerCase().trim(),
        weekNounPlural: this.editCourseNounsDialog.weekNounPlural.toLowerCase().trim(),
        termNoun: this.editCourseNounsDialog.termNoun.toLowerCase().trim(),
        termNounPlural: this.editCourseNounsDialog.termNounPlural.toLowerCase().trim(),
      };
      try {
        await this.$api.put(`/cohorts/${this.selectedCohort.id}/course/nouns`, nouns);
        this.loadCohortCourse(true);
        this.editCourseNounsDialog = {
          ...nouns,
          active: false,
          processing: false,
          error: '',
        };
      } catch (e) {
        console.log(e);
      }
      this.editCourseNounsDialog.processing = false;
    },

    async submitEditTerm() {
      this.editTermDialog.processing = true;
      this.editTermDialog.error = '';
      try {
        await this.$api.put(`/cohorts/${this.selectedCohort.id}/course/terms/${this.editTermDialog.id}`, {
          name: this.editTermDialog.name,
          startDate: this.editTermDialog.startDate,
          endDate: this.editTermDialog.endDate,
        });
        this.loadCohortCourse(true);
        this.editTermDialog = {
          ...defaultEditTermDialog,
        };
      } catch (e) {
        console.log(e);
        this.editTermDialog.error = 'Sorry, cannot edit this term at the moment';
      }
      this.editTermDialog.processing = false;
    },
    clickTermTo(term) {
      return {
        name: 'TutorAdminCohortCourseTermPage',
        params: { cohortId: this.selectedCohort.id, termId: term.id },
      };
    },
    canDeleteTerm(term) {
      return term.cohortCourseWeeks.every(x => x.status !== 'published');
    },
    editWeekTemplateTo() {
      return {
        name: 'TutorAdminCohortCourseWeekTemplatePage',
      };
    },
  },
};
</script>
