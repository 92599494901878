<template>
  <div style="visibility: hidden"></div>
</template>

<script>
import { syncQueryParamsMixin } from '@/mixins/query-mixins';
import { useQueryStore } from '@/stores/query';

export default {
  props: {
    name: { type: String, required: true },
    display: { type: String, required: true },
    curriculumLinkedStatus: { type: String, required: true },
    actionStatus: { type: String, required: true },
  },
  emits: ['update:name', 'update:display', 'update:curriculumLinkedStatus', 'update:actionStatus'],
  setup() {
    const queryStore = useQueryStore();
    return { queryStore };
  },
  mixins: [
    syncQueryParamsMixin({
      internalName: { query: 'name', type: 'string' },
      internalDisplay: { query: 'display', type: 'string' },
      internalCurriculumLinkedStatus: { query: 'curriculumLinkedStatus', type: 'string' },
      internalActionStatus: { query: 'actionStatus', type: 'string' },
    }),
  ],
  data() {
    return {
      internalName: this.name,
      internalDisplay: this.display,
      internalCurriculumLinkedStatus: this.curriculumLinkedStatus,
      internalActionStatus: this.actionStatus,
    };
  },
  created() {
    this.$emit('update:name', this.internalName);
    this.$emit('update:display', this.internalDisplay);
    this.$emit('update:curriculumLinkedStatus', this.internalCurriculumLinkedStatus);
    this.$emit('update:actionStatus', this.internalActionStatus);
  },
  watch: {
    name(x) {
      this.internalName = x;
    },
    internalName(x) {
      this.$emit('update:name', x);
    },
    display(x) {
      this.internalDisplay = x;
    },
    internalDisplay(x) {
      this.$emit('update:display', x);
    },
    curriculumLinkedStatus(x) {
      this.internalCurriculumLinkedStatus = x;
    },
    internalCurriculumLinkedStatus(x) {
      this.$emit('update:curriculumLinkedStatus', x);
    },
    actionStatus(x) {
      this.internalActionStatus = x;
    },
    internalActionStatus(x) {
      this.$emit('update:actionStatus', x);
    },
  },
};
</script>
