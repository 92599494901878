<template>
  <div style="min-width: max-content">
    <v-select
      v-model="roleId"
      hide-details
      :items="roleWithAllRoles"
      item-title="name"
      item-value="id"
      label="Filter by Role"
    ></v-select>
  </div>
</template>

<script>
import { syncQueryParamsMixin } from '@/mixins/query-mixins';
import { useQueryStore } from '@/stores/query';

export default {
  name: 'RoleFilter',
  setup() {
    const queryStore = useQueryStore();
    return { queryStore };
  },
  mixins: [
    syncQueryParamsMixin({
      roleId: { query: 'roleId', type: 'integer' },
    }),
  ],
  props: {
    roles: {
      type: Array,
      required: true,
    },
  },
  emits: ['update:roleId'],
  data() {
    return { roleId: -1 };
  },
  computed: {
    roleWithAllRoles() {
      return [{ id: -1, name: 'All Roles' }].concat(this.roles);
    },
  },
  watch: {
    roleId(x) {
      this.$emit('update:roleId', x);
    },
  },
};
</script>
