<template>
  <div>
    <v-card class="mb-4">
      <v-card-text>
        <div class="d-flex align-center pl-4 pr-2">
          <div class="pl-2 flex-grow-1">
            <v-text-field
              v-model="newJudgementSet"
              class="pr-4"
              label="New Judgement Set"
              prepend-icon="mdi-pencil-box"
            />
          </div>
          <div class="d-flex align-center">
            <v-btn ripple @click.prevent="addJudgementSet()">
              <div class="d-flex align-center">
                <v-icon>mdi-plus</v-icon>
                <span>Add</span>
              </div>
            </v-btn>
          </div>
        </div>
      </v-card-text>
    </v-card>
    <v-card>
      <v-card-text>
        <mosaic-snackbar v-model="snackbar.status" :color="snackbar.color" :message="snackbar.message" />
        <v-list>
          <v-list-item v-for="set in judgementSets" :key="set.id">
            <template #prepend>
              <v-avatar>
                <v-icon>mdi-pencil-box-multiple</v-icon>
              </v-avatar>
            </template>

            <h3 class="mb-1">{{ set.name }}</h3>
            <div class="d-flex">
              <div
                v-for="(descriptor, index) in set.judgement_descriptors"
                :key="descriptor.id"
                :class="getBoxClass(set, index) + ' pa-3 d-flex'"
                :style="'color: ' + descriptor.color"
              >
                <div class="d-flex align-center px-2">{{ descriptor.descriptor }}</div>
                <div v-if="set.editable">
                  <ndt-icon-button
                    icon="pencil"
                    tooltip="Edit descriptor"
                    @click.prevent="editDescriptor(descriptor)"
                  />
                </div>
              </div>
              <div v-if="set.editable" class="d-flex align-center ml-2">
                <ndt-icon-button
                  class="ml-2"
                  icon="plus"
                  tooltip="Add Descriptor"
                  @click.prevent="createDescriptor(set)"
                ></ndt-icon-button>
              </div>
            </div>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>

    <ndt-dialog
      v-model:active="editDescriptorDialog.active"
      title="Update Descriptor"
      :error-message="editDescriptorDialog.errorMessage"
      :on-close="() => onDialogClose(editDescriptorDialog)"
    >
      <div class="d-flex">
        <div class="flex-grow-1">
          <v-text-field v-model="editDescriptorDialog.descriptor" label="Descriptor" hide-details=""></v-text-field>
        </div>
        <div class="justify-end">
          <v-select
            v-model="editDescriptorDialog.color"
            label="Color"
            class="pl-4"
            :items="colors"
            item-title="name"
            item-value="hex"
            hide-details
          ></v-select>
        </div>
      </div>
      <template #buttons>
        <v-btn
          variant="text"
          ripple
          :disabled="!editDescriptorDialog.descriptor || editDescriptorDialog.processing"
          @click.prevent="submitEditDescriptor(descriptor)"
        >
          Save
        </v-btn>
      </template>
    </ndt-dialog>
    <ndt-dialog
      v-model:active="createDescriptorDialog.active"
      title="New Descriptor"
      :error-message="createDescriptorDialog.errorMessage"
      :on-close="() => onDialogClose(createDescriptorDialog)"
    >
      <div class="d-flex">
        <div class="flex-grow-1">
          <v-text-field v-model="createDescriptorDialog.descriptor" label="Descriptor" hide-details=""></v-text-field>
        </div>
        <div class="justify-end">
          <v-select
            v-model="createDescriptorDialog.color"
            label="Color"
            class="pl-4"
            :items="colors"
            item-title="name"
            item-value="hex"
            hide-details
          ></v-select>
        </div>
      </div>
      <template #buttons>
        <v-btn
          variant="text"
          ripple
          :disabled="
            !createDescriptorDialog.descriptor || !createDescriptorDialog.color || createDescriptorDialog.processing
          "
          @click.prevent="submitCreateDescriptor()"
        >
          Save
        </v-btn>
      </template>
    </ndt-dialog>
  </div>
</template>

<script>
import NdtIconButton from '../../components/NdtIconButton.vue';
import NdtDialog from '../../components/NdtDialog.vue';

export default {
  name: 'AdminJudgementSetsPage',

  components: { NdtIconButton, NdtDialog },
  data: function () {
    return {
      data: [],
      newJudgementSet: '',
      snackbar: {
        message: '',
        status: false,
        color: '',
      },
      editDescriptorDialog: {
        active: false,
        descriptor: '',
        id: null,
        color: '',
        errorMessage: '',
        processing: false,
      },
      createDescriptorDialog: {
        active: false,
        descriptor: '',
        color: '',
        errorMessage: '',
        setId: null,
        processing: false,
      },
      colors: [
        { name: 'Red', hex: '#FF0000' },
        { name: 'Green', hex: '#008000' },
        { name: 'Blue', hex: '#0000FF' },
        { name: 'Amber', hex: '#FF9800' },
        { name: 'Grey', hex: '#757575' },
      ],
    };
  },
  computed: {
    breadcrumbs() {
      return [{ text: 'Judgement Sets' }];
    },
    judgementSets() {
      return this.data.map(x => ({
        ...x,
        editable: x.institutions.length === 0 && x.professional_institutions.length === 0,
      }));
    },
  },
  async created() {
    this.refreshJudgementSets();
  },
  methods: {
    async refreshJudgementSets() {
      const response = await this.$api.get(`/admin/judgement-sets`);
      this.data = response.data;
    },
    async addJudgementSet() {
      try {
        const r = await this.$api.post(`/admin/judgement-sets`, { name: this.newJudgementSet });
        this.newJudgementSet = '';
        this.snackbar = {
          message: 'Success',
          status: true,
          color: 'success',
        };
        this.data.push(r.data);
      } catch (e) {
        console.log(e);
        this.snackbar = {
          message:
            (e.response && e.response.data && e.response.data.message) || `Sorry, cannot add judgement set right now`,
          status: true,
          color: 'error',
        };
      }
    },
    editDescriptor(descriptor) {
      this.editDescriptorDialog = {
        active: true,
        id: descriptor.id,
        descriptor: descriptor.descriptor,
        color: descriptor.color,
        errorMessage: '',
        processing: false,
      };
    },
    async submitEditDescriptor() {
      try {
        await this.$api.put(`/judgement-descriptors/${this.editDescriptorDialog.id}`, this.editDescriptorDialog);
        this.editDescriptorDialog = {
          active: false,
          descriptor: '',
          color: '',
          errorMessage: '',
          processing: false,
        };
        this.refreshJudgementSets();
      } catch (e) {
        this.editDescriptorDialog.errorMessage =
          (e.response && e.response.data && e.response.data.message) || `Sorry, cannot update descriptor right now`;
      }
    },
    createDescriptor(set) {
      this.createDescriptorDialog = {
        active: true,
        descriptor: '',
        color: '',
        errorMessage: '',
        setId: set.id,
        processing: false,
      };
    },
    async submitCreateDescriptor() {
      try {
        await this.$api.post(`/judgement-descriptors`, this.createDescriptorDialog);
        this.createDescriptorDialog = {
          active: false,
          descriptor: '',
          color: '',
          errorMessage: '',
          setId: null,
          processing: false,
        };
        this.refreshJudgementSets();
      } catch (e) {
        this.editDescriptorDialog.errorMessage =
          (e.response && e.response.data && e.response.data.message) || `Sorry, cannot update descriptor right now`;
      }
    },
    onDialogClose(dialog) {
      dialog.descriptor = '';
      dialog.id = null;
      dialog.color = null;
      dialog.errorMessage = '';
      if ('setId' in dialog) dialog.setId = null;
    },
    getBoxClass(set, index) {
      const borderClass =
        index === 0
          ? 'descriptor-box-left'
          : index === set.judgement_descriptors.length - 1
          ? 'descriptor-box-right'
          : 'descriptor-box-center';
      return borderClass;
    },
  },
};
</script>

<style scoped>
.descriptor-box-left {
  border: 2px solid;
  border-right-color: black;
  border-radius: 8px 0px 0px 8px;
}
.descriptor-box-right {
  border: 2px solid;
  border-left: none;
  border-radius: 0px 8px 8px 0px;
}
.descriptor-box-center {
  border: 2px solid;
  border-right-color: black;
  border-left: none;
}
</style>
