<template>
  <mentor-meetings-list
    :student-id="userStudent.id"
    :can-edit-mentor-meetings="selectedInstitution.config.students_can_edit_mentor_meetings"
    :can-edit-evidence="true"
    :mentor-meeting-click-to="mentorMeetingClickTo"
    @click-create-mentor-meeting="createMentorMeetingClick"
  >
  </mentor-meetings-list>
</template>

<script>
import { mapState } from 'vuex';
import MentorMeetingsList from './MentorMeetingsList.vue';

export default {
  name: 'StudentMentorMeetingsListPage',
  components: { MentorMeetingsList },
  data: () => ({}),
  computed: {
    ...mapState(['selectedInstitution', 'userStudent']),
    breadcrumbs() {
      return [
        {
          text: 'Mentor Meetings',
        },
      ];
    },
  },

  methods: {
    mentorMeetingClickTo(id) {
      return { name: 'MentorMeetingPage', params: { studentId: this.userStudent.id, id: id } };
    },
    createMentorMeetingClick() {
      this.$router.push({ name: 'StudentMentorMeetingCreatePage' });
    },
  },
};
</script>
