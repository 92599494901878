<template>
  <div>
    <mosaic-loading-and-error-cards :object-type="actionNoun" :load="load">
      <mosaic-stepper-card
        :steps="['Details', assigneeNounPluralised]"
        :save="save"
        :can-continue="() => canContinue"
        :can-save="canSave"
        :object-type="actionNoun"
        :is-editing="isEditing"
        :return-to="returnTo"
      >
        <template #title>
          <mosaic-card-title>
            <span>{{ isEditing ? 'Edit' : 'New' }} {{ actionNoun }}</span>
            <template #chip>
              <cohort-action-status-chip
                :object-type="actionNoun"
                :draft="draft"
                :assignee-noun-pluralised="assigneeNounPluralised"
                :set-date="isEditing ? actionSetDate : undefined"
                :scheduled-date="actionScheduleInTheFuture ? actionScheduledAtDate : undefined"
              />
            </template>
          </mosaic-card-title>
        </template>
        <template #item.1>
          <slot name="details-step"></slot>
        </template>

        <template #item.2>
          <slot name="assignment-step"></slot>
        </template>
      </mosaic-stepper-card>
    </mosaic-loading-and-error-cards>

    <unsaved-changes-dialog
      v-model:unsaved-changes-dialog="dialog"
      :object-type="actionNoun"
      :save="isEditing ? save : undefined"
    />
  </div>
</template>

<script setup lang="ts">
import { useUnsavedChanges } from '@/composables/unsaved-changes';
import UnsavedChangesDialog from '@/components/UnsavedChangesDialog.vue';
import { toRef } from 'vue';
import type { RouteLocationNamedRaw } from 'vue-router';
import CohortActionStatusChip from './CohortActionStatusChip.vue';
import type { AsyncOperationResult } from '@/composables/processing-and-errors';

const props = defineProps<{
  actionNoun: string;
  actionSetDate?: string;
  actionScheduledAtDate?: string;
  actionScheduleInTheFuture: boolean;
  assigneeNoun: string;
  assigneeNounPluralised: string;
  load: () => Promise<void>;
  canContinue: boolean;
  canSave: boolean;
  save: () => AsyncOperationResult;
  returnTo: RouteLocationNamedRaw;
  dirty: boolean;
  isEditing: boolean;
  draft?: boolean;
}>();

const { dialog } = useUnsavedChanges(toRef(props, 'dirty'));
</script>
