import type { CurriculumStatementSlim, CurriculumStatementSlimLegacy } from '@/store/map-store';
import { fromSnakeCaseToCamelCase } from './transforms';

interface StudentWithSubjects {
  subject: {
    id: number;
  };
  additional_subject: {
    id: number;
  };
}
export function visibleCurriculumStatements(
  curriculumStatements: CurriculumStatementSlim[] | CurriculumStatementSlimLegacy[],
  selectedStudent: StudentWithSubjects,
  user: {
    student?: StudentWithSubjects;
  }
): CurriculumStatementSlim[] {
  const camelisedStatements = fromSnakeCaseToCamelCase<CurriculumStatementSlim[]>(curriculumStatements);
  return camelisedStatements.filter(cs => {
    // Always show if view is not student scoped
    if (!selectedStudent && !user.student) return true;
    const student = selectedStudent ? selectedStudent : user.student;
    if (!student) throw 'student should always be defined here';
    const subjectId = 'subject_id' in cs ? cs.subject_id : cs.subjectId;
    const studentVisible = !cs.hidden;

    return (
      studentVisible &&
      (!subjectId || subjectId === student.subject?.id || subjectId === student.additional_subject?.id)
    );
  });
}
