<template>
  <div>
    <v-card v-if="error">
      <v-card-text>
        <div class="pa-4">{{ error }}</div>
      </v-card-text>
    </v-card>
    <review-part-2-card
      v-else-if="studentReviewForTrainee"
      :student-review="studentReviewForTrainee"
      :student-viewer="user.student"
      :student="user.student"
      :review-path="reviewPath"
      @update:dirty="dirty = $event"
    />
    <unsaved-changes-dialog
      v-model:unsaved-changes-dialog="unsavedChangesDialog"
      :object-type="reviewNounCapitalised"
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import ReviewPart2Card from '@/components/ReviewPart2Card.vue';
import unsavedChangesMixin from '@/components/unsaved-changes-mixin';
import UnsavedChangesDialog from '@/components/UnsavedChangesDialog.vue';
import clearStudentReviewMixin from '@/mixins/clear-student-review';

export default {
  name: 'StudentReviewPart2Page',
  mixins: [unsavedChangesMixin, clearStudentReviewMixin],
  ...unsavedChangesMixin,
  async created() {
    await this.$store.dispatch('loadStudentReview', { id: this.$route.params.id });
  },
  computed: {
    ...mapState(['user', 'selectedStudent']),
    ...mapState({
      error: state => state.studentReviewPages.error,
    }),
    ...mapGetters(['studentReviewForTrainee']),
    reviewPath() {
      return {
        text: this.studentReviewForTrainee?.name,
        to: {
          name: 'StudentReviewPage',
          params: { id: this.studentReviewForTrainee?.id },
        },
      };
    },
    breadcrumbs() {
      return [
        {
          text: this.reviewNounCapitalisedAndPluralised,
          to: {
            name: 'StudentReviewsListPage',
          },
        },
        this.reviewPath,
        {
          text: 'Part 2',
        },
      ];
    },
  },
  components: { ReviewPart2Card, UnsavedChangesDialog },
};
</script>
