<template>
  <div>
    <mosaic-tab-card-page
      v-model:trigger-background-load="triggerBackgroundLoad"
      object-type="Instructor Announcements"
      :object-type-is-plural="true"
      :headers="tabHeaders"
      :load="load"
      @tab-selected="tab = $event"
    >
      <template #actions>
        <mosaic-btn icon="mdi-plus" :to="{ name: 'CohortAdminStaffAnnouncementCreatePage' }">Announcement</mosaic-btn>
      </template>

      <template #live-tab-item>
        <cohort-staff-announcements-page-list
          v-model:trigger-background-load="triggerBackgroundLoad"
          :announcements="liveAnnouncements"
          type="live"
          :subtitle="liveSubtitle"
          :click-announcement-to="clickAnnouncementTo"
        />
      </template>

      <template #scheduled-tab-item>
        <cohort-staff-announcements-page-list
          v-model:trigger-background-load="triggerBackgroundLoad"
          :announcements="scheduledAnnouncements"
          type="scheduled"
          :subtitle="a => `Scheduled for ${formatDate(a.scheduledAtDate || '')}`"
          :click-announcement-to="clickAnnouncementTo"
        />
      </template>
    </mosaic-tab-card-page>
  </div>
</template>

<script setup lang="ts">
import { setBreadcrumbs } from '@/utils/breadcrumbs';
import { computed, ref } from 'vue';
import type { AdminStaffAnnouncement } from './CohortAdminStaffAnnouncementsPageList.vue';
import CohortStaffAnnouncementsPageList from './CohortAdminStaffAnnouncementsPageList.vue';
import { parseRouteId } from '@/composables/vue-router';
import { formatDate } from '@/mixins/global-mixins';
import { useApi } from '@/composables/api';

const api = useApi();

setBreadcrumbs([{ text: 'Instructor Announcements' }]);

const tabHeaders = [
  {
    text: 'Live',
    key: 'live',
  },
  {
    text: 'Scheduled',
    key: 'scheduled',
  },
];
const tab = ref('live');

const cohortId = parseRouteId('cohortId');
const announcements = ref<AdminStaffAnnouncement[]>([]);

async function load() {
  const r = await api.get<AdminStaffAnnouncement[]>(`/cohorts/${cohortId.value}/admin-staff-announcements`);
  announcements.value = r.data;
}
const triggerBackgroundLoad = ref(false);

const liveAnnouncements = computed(() => announcements.value.filter(x => x.setDate).sortBy('setDate', 'desc'));

const scheduledAnnouncements = computed(() =>
  announcements.value.filter(x => x.scheduledAtDate && !x.setDate).sortBy('scheduledAtDate')
);

function liveSubtitle(a: AdminStaffAnnouncement) {
  if (!a.setDate) return '';
  return `Set ${formatDate(a.setDate)}`;
}

const clickAnnouncementTo = (t: AdminStaffAnnouncement) => ({
  name: 'CohortAdminStaffAnnouncementEditPage',
  params: { cohortId: cohortId.value.toString(), id: t.id.toString() },
  query: { fromTab: tab.value },
});
</script>
