<template>
  <mosaic-tab-item-list
    :items="filteredCertificates"
    :action="action"
    :empty-text="emptyText"
    mosaic-key="certificates"
  >
    <template #filters>
      <mosaic-checkbox
        name="relevant"
        :label="`Show Certificates not relevant to ${selectedInstitution.name}`"
        no-icon
        hide-details
        dense
        v-model="showNotRelevant"
      />
    </template>

    <template #item="{ item }">
      <mosaic-list-item
        :icon="icons.certificate"
        :title="item.certificateType.name"
        :subtitle="item.subtitle"
        :to="item.to"
        :chip="item.chip"
      >
        <template #actions v-if="canDelete">
          <mosaic-delete-icon-btn object-type="Certificate" @click.prevent="deleteCertificate(item)" />
        </template>
      </mosaic-list-item>
    </template>
  </mosaic-tab-item-list>

  <mosaic-delete-dialog
    v-model:active="deleteDialog.active"
    :object-name="deleteDialog.name"
    object-type="Certificate"
    :url="`/staff/${staffId}/staff-training-certificates/${deleteDialog.id}`"
    @delete="certificateDeleted()"
  >
    <div v-if="deleteDialog.approved">
      This Certificate has been approved<span v-if="deleteDialog.status != 'approved'">
        (in a different Institution)</span
      >. If you delete it, then it will need to be re-approved (in all currently approved Institutions) if it is created
      again.
    </div>
  </mosaic-delete-dialog>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { useRouter, type RouteLocationNamedRaw } from 'vue-router';
import { icons } from '@/utils/icons';
import { staffTrainingCertificateStatusChip, useStaffTraining } from './staff-training';
import { useInstitutionStore } from '@/stores/institution';
import { enumerateItems } from '@/utils/text';
import type { StaffTraining } from '@/stores/staff-training';
import { syncQueryParam } from '@/composables/query';

const props = withDefaults(
  defineProps<{
    staffTraining: StaffTraining;
    isMe?: boolean;
    clickCertificateTo: (certificate: StaffTraining['staffTrainingCertificates'][number]) => RouteLocationNamedRaw;
    createCertificateTo: RouteLocationNamedRaw;
    certificateDeleted: () => Promise<unknown>;
  }>(),
  {
    isMe: false,
  }
);

const { staffId } = useStaffTraining();
const { selectedInstitution } = useInstitutionStore();

const assignedStaffTrainingCompetencyIds = computed(() =>
  props.staffTraining.staffTrainingFrameworks.flatMap(f =>
    f.staffTrainingCompetencyThemes.flatMap(t => t.staffTrainingCompetencies.map(c => c.id))
  )
);
const certificates = computed(() =>
  props.staffTraining.staffTrainingCertificates.map(c => ({
    ...c,
    to: props.clickCertificateTo(c),
    subtitle: `Meets ${enumerateItems(
      (
        props.staffTraining.staffTrainingAcceptedCertificates
          .find(ac => ac.certificateType.id == c.certificateType.id)
          ?.staffTrainingAcceptedCertificateCompetencies.map(c => c.staffTrainingCompetencyId) || []
      ).filter(cId => assignedStaffTrainingCompetencyIds.value.includes(cId)).length,
      'Competency'
    )}`,
    chip: staffTrainingCertificateStatusChip(c.status),
  }))
);
const showNotRelevant = ref(false);
syncQueryParam(showNotRelevant, 'certificates-not-relevant', 'boolean');
const filteredCertificates = computed(() =>
  certificates.value.filter(c => showNotRelevant.value || c.relevantForInstitution).sortBy(c => c.certificateType.name)
);

const action = computed(() => (props.isMe ? { icon: 'plus', text: 'Certificate', click: addCertificate } : undefined));

const emptyText = computed(
  () =>
    `${props.isMe ? 'You have no' : 'This Instructor has no'} Certificates${
      props.staffTraining.staffTrainingCertificates.length !== filteredCertificates.value.length
        ? ' for the current filters'
        : ''
    }.${action.value ? ' Add one by clicking the button above.' : ''}`
);

const router = useRouter();
function addCertificate() {
  router.push(props.createCertificateTo);
}

// #region delete
const deleteDialog = ref({
  active: false,
  id: -1,
  name: '',
  approved: false,
  status: '',
});

const canDelete = computed(() => props.isMe);
function deleteCertificate(certificate: StaffTraining['staffTrainingCertificates'][number]) {
  deleteDialog.value = {
    active: true,
    id: certificate.id,
    name: certificate.certificateType.name,
    status: certificate.status,
    approved: certificate.approvedAtLeastOnce,
  };
}
// #endregion
</script>
