<template>
  <div>
    <div
      class="d-flex"
      :style="{
        cursor: studentReviews.length === 0 ? 'default' : 'pointer',
      }"
      :class="{ 'text-red': status !== 'notstarted' && studentReviews.length > 0 }"
      @click.prevent="showStudents = studentReviews.length === 0 ? false : !showStudents"
    >
      <v-icon v-if="!showStudents">mdi-chevron-right</v-icon>
      <v-icon v-else>mdi-chevron-down</v-icon>
      <div v-if="studentReviews.length === 1">
        1 {{ traineeNounCapitalised() }} with a {{ reviewNounCapitalised }} that has {{ displayStatus }}
      </div>
      <div v-else>
        {{ studentReviews.length }} {{ traineeNounCapitalisedAndPluralised }} with
        {{ reviewNounCapitalisedAndPluralised }} that have {{ displayStatus }}
      </div>
    </div>
    <div class="pl-4">
      <ul v-if="showStudents">
        <li v-for="student in studentReviews" :key="student.id">
          {{ student.name || student.email }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';

type ReviewStatus = 'notstarted' | 'started' | 'partiallycompleted' | 'completed' | 'approved';
const props = defineProps<{
  students: { id: number; name: string; email: string; studentReview: { status: ReviewStatus } }[];
  status: ReviewStatus;
}>();

const displayStatus = {
  notstarted: 'not been started',
  started: 'been started',
  partiallycompleted: 'been partially completed',
  completed: 'been completed',
  approved: 'been approved',
}[props.status];

const showStudents = ref(false);

const studentReviews = computed(() => props.students.filter(x => x.studentReview.status === props.status));
</script>
