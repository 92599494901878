<template>
  <div>
    <v-card>
      <v-card-text>
        <mosaic-list :items="templates">
          <template #item="{ item: template }">
            <mosaic-list-item icon="mdi-hexagon-multiple" :title="template.name" :to="templateTo(template.id)">
              <template #actions>
                <ndt-icon-button
                  icon="pencil"
                  tooltip="Edit Curriculum Template name"
                  @click.prevent="editTemplateName(template)"
                />
                <ndt-icon-button
                  icon="delete"
                  tooltip="Delete Curriculum Template"
                  @click.prevent="deleteTemplate(template)"
                />
              </template>
            </mosaic-list-item>
          </template>
        </mosaic-list>
        <v-list-item>
          <span class="text-h6">New Curriculum Template</span>
          <div class="d-flex">
            <div style="width: 100%" class="pr-2">
              <mosaic-text-field
                v-model="newTemplate"
                label="Name"
                name="new-name"
                prepend-icon="mdi-hexagon-multiple"
              ></mosaic-text-field>
            </div>
            <v-list-item-action>
              <v-btn :disabled="!canAddTemplate" @click.prevent="addTemplate">Add</v-btn>
            </v-list-item-action>
          </div>
        </v-list-item>
      </v-card-text>
      <v-alert v-if="errorMessage.length" type="error" closable>{{ errorMessage }}</v-alert>
    </v-card>

    <ndt-dialog
      v-model:active="editTemplateDialog.active"
      title="Update Template Name"
      :error-message="editTemplateDialog.errorMessage"
      :on-close="onDialogClose"
    >
      <v-text-field v-model="editTemplateDialog.name" prepend-icon="mdi-pencil" label="Name" type="text" />
      <template #buttons>
        <v-btn variant="text" ripple :disabled="editTemplateDialog.processing" @click.prevent="submitEditTemplateName()"
          >Save</v-btn
        >
      </template>
    </ndt-dialog>
    <ndt-dialog v-model:active="deleteDialog.active" title="Delete Template" :width="500">
      <span>Are you sure you want to delete "{{ deleteDialog.templateName }}"?</span>
      <template #buttons>
        <v-btn
          variant="text"
          ripple
          color="error"
          :disabled="deleteDialog.processing"
          @click.prevent="submitDeleteTemplate()"
        >
          Delete
        </v-btn>
      </template>
    </ndt-dialog>
  </div>
</template>

<script>
import NdtIconButton from '../../components/NdtIconButton.vue';
import NdtDialog from '../../components/NdtDialog.vue';

export default {
  name: 'AdminCurriculumTemplatesPage',
  components: { NdtIconButton, NdtDialog },
  data: function () {
    return {
      templates: [],
      newTemplate: '',
      errorMessage: '',
      deleteDialog: {
        active: false,
        themeName: '',
        themeId: '',
        processing: '',
      },
      editTemplateDialog: {
        active: false,
        name: '',
        templateId: null,
        errorMessage: '',
        processing: false,
      },
    };
  },
  computed: {
    breadcrumbs() {
      return [{ text: 'Curriculum Templates' }];
    },
    canAddTemplate() {
      return !!this.newTemplate.length;
    },
  },
  created: async function () {
    const r = await this.$api.get('/curriculum-templates');
    this.templates = r.data;
  },
  methods: {
    templateTo(templateId) {
      return { name: 'AdminCurriculumThemeTemplatesPage', params: { templateId: templateId } };
    },
    async addTemplate() {
      this.errorMessage = '';
      try {
        const r = await this.$api.post('/curriculum-templates', { name: this.newTemplate });
        this.templates.push(r.data);
        this.newTemplate = '';
      } catch (e) {
        this.errorMessage = 'Sorry, cannot add a template right now';
        throw e;
      }
    },
    editTemplateName(template) {
      this.editTemplateDialog.active = true;
      (this.editTemplateDialog.name = template.name), (this.editTemplateDialog.templateId = template.id);
    },
    deleteTemplate(template) {
      this.deleteDialog = {
        active: true,
        templateId: template.id,
        templateName: template.name,
        processing: false,
      };
    },
    submitDeleteTemplate() {
      this.deleteDialog.processing = true;
      this.$api.delete(`/curriculum-templates/${this.deleteDialog.templateId}`).then(() => {
        this.deleteDialog.active = false;
        this.templates = this.templates.filter(t => t.id !== this.deleteDialog.templateId);
      });
    },
    async submitEditTemplateName() {
      try {
        this.editTemplateDialog.processing = true;
        const r = await this.$api.put(`/curriculum-templates/${this.editTemplateDialog.templateId}`, {
          name: this.editTemplateDialog.name,
        });
        this.editTemplateDialog.processing = false;
        const template = r.data;
        const i = this.templates.findIndex(t => t.id === template.id);
        this.templates.splice(i, 1, template);
        this.onDialogClose();
      } catch (e) {
        this.editTemplateDialog.errorMessage = 'Sorry, cannot update this template right now';
        throw e;
      }
    },
    onDialogClose() {
      this.editTemplateDialog = {
        active: false,
        name: '',
        templateId: null,
        errorMessage: '',
        processing: false,
      };
    },
  },
};
</script>
