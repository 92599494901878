import type { Api } from '@/composables/api';
import type { ComputedRef } from 'vue';
import type { RouteLocationNamedRaw } from 'vue-router';

export function useMoveListItem(urlRoot: string, api: Api) {
  async function moveListItem(direction: 'up' | 'down', listItem: Item) {
    await api.put(`${urlRoot}/${listItem.id}/move-${direction}`, {});
  }
  return { moveListItem };
}

export function useRetitleListItem(urlRoot: string, body: ComputedRef<Record<string, unknown>>, api: Api) {
  async function retitleListItem(itemId: number) {
    await api.put(`${urlRoot}/${itemId}/update-title`, body.value);
  }

  return { retitleListItem };
}

export function useEditListItem(urlRoot: string, body: ComputedRef<Record<string, unknown>>, api: Api) {
  async function editListItem(itemId: number) {
    await api.put(`${urlRoot}/${itemId}`, body.value);
  }

  return { editListItem };
}

export function useDeleteListItem(urlRoot: string, api: Api) {
  async function deleteListItem<T extends Item>(listItem: T) {
    await api.delete(`${urlRoot}/${listItem.id}`);
  }
  return { deleteListItem };
}

export type Item = {
  id: number;
  icon?: string;
  title: string;
  subtitle?: string;
  order: number;
  hideMove: boolean;
  disableDelete: boolean;
  warningMessage?: string;
  deleteDisabledTooltip?: string;
  disableEdit?: boolean;
  editDisabledTooltip?: string;
  last?: boolean;
  clickTo?: RouteLocationNamedRaw;
};
