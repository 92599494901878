<template>
  <div>
    <mosaic-loading-card v-if="busy" type="card" />
    <mosaic-load-error-card v-else-if="error" :object-type="reviewNounCapitalised" @retry="load" />
    <review-card
      v-else-if="studentReviewForTrainee"
      :student-review="studentReviewForTrainee"
      :student-viewer="user.student"
      :student="user.student"
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import ReviewCard from '@/components/ReviewCard.vue';
import clearStudentReviewMixin from '@/mixins/clear-student-review';

export default {
  name: 'StudentReviewPage',
  mixins: [clearStudentReviewMixin],
  async created() {
    await this.load();
  },
  computed: {
    ...mapState(['user', 'selectedStudent']),
    ...mapState({
      error: state => state.studentReviewPages.error,
      busy: state => state.studentReviewPages.busy,
    }),
    ...mapGetters(['studentReviewForTrainee', 'reviewNounCapitalisedAndPluralised']),
    breadcrumbs() {
      return [
        {
          text: this.reviewNounCapitalisedAndPluralised,
          to: {
            name: 'StudentReviewsListPage',
          },
        },
        {
          text: this.studentReviewForTrainee?.name,
        },
      ];
    },
  },
  methods: {
    async load() {
      await this.$store.dispatch('loadStudentReview', { id: this.$route.params.id });
    },
  },
  components: { ReviewCard },
};
</script>
