<template>
  <div>
    <v-card class="mb-4">
      <v-card-text>
        <mosaic-card-title
          >Judgement Sets<template #actions>
            <v-btn ripple @click.prevent="addJudgementSet()">
              <div class="d-flex align-center">
                <v-icon>mdi-plus</v-icon>
                <span>Judgement Set</span>
              </div>
            </v-btn>
          </template>
        </mosaic-card-title>
      </v-card-text>
    </v-card>
    <v-card v-if="loadError" class="mb-4">
      <v-card-text> Sorry cannot load your Judgement Sets right now. </v-card-text>
    </v-card>
    <v-card v-else-if="!loading || judgementSets.length">
      <v-card-text>
        <template v-if="!judgementSets.length">
          <div>You do not currently have any Judgement Sets associated with your institution.</div>
        </template>
        <template v-else>
          <mosaic-list :items="judgementSets">
            <template #item="{ item: set }">
              <mosaic-list-item icon="mdi-pencil-box-multiple" :title="set.name">
                <template #subtitle>
                  <div class="d-flex">
                    <div
                      v-for="(descriptor, index) in set.institutionJudgementDescriptors"
                      :key="descriptor.id"
                      :class="getBoxClass(set, index) + ' px-2 py-1 d-flex my-2'"
                      :style="`background-color: ${descriptor.color}2E; color: ${descriptor.color}; `"
                    >
                      <div class="d-flex align-center">
                        <div
                          class="d-flex align-center px-2"
                          :style="`color: ${descriptor.color}; text-transform: Uppercase; font-weight: 500; font-size: 0.875rem; letter-spacing: .0892857143em`"
                        >
                          {{ descriptor.descriptor }}
                        </div>
                        <div class="d-flex">
                          <div>
                            <ndt-icon-button
                              icon="pencil"
                              :tooltip="
                                set.isInUse ? 'Descriptors are in use, cannot edit Descriptor' : 'Edit descriptor'
                              "
                              :disabled="set.isInUse"
                              @click.prevent="editDescriptor(descriptor)"
                            />
                          </div>
                          <div>
                            <ndt-icon-button
                              class="ml-2"
                              icon="delete"
                              :tooltip="descriptorTooltip(set)"
                              :disabled="!canDeleteDescriptors(set)"
                              @click.prevent="deleteDescriptor(descriptor)"
                            ></ndt-icon-button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-if="!set.isInUse" class="d-flex align-center ml-2">
                      <ndt-icon-button
                        class="ml-2"
                        icon="plus"
                        tooltip="Add Descriptor"
                        @click.prevent="createDescriptor(set)"
                      ></ndt-icon-button>
                    </div>
                  </div>
                </template>

                <template #actions>
                  <ndt-icon-button icon="pencil" tooltip="Edit Judgement Set name" @click.prevent="editSet(set)" />
                  <ndt-icon-button
                    icon="delete"
                    :tooltip="
                      set.isInUse ? 'Judgement Set is in use, cannot delete Judgement Set' : 'Delete Judgement Set'
                    "
                    :disabled="set.isInUse"
                    @click.prevent="deleteSet(set)"
                  />
                </template>
              </mosaic-list-item>
            </template>
          </mosaic-list>
        </template>
      </v-card-text>
    </v-card>

    <ndt-dialog
      v-model:active="editSetDialog.active"
      title="Update Judgement Set"
      :error-message="editSetDialog.errorMessage"
      :on-close="() => onDialogClose(editSetDialog)"
    >
      <v-text-field v-model="editSetDialog.name" label="Name" hide-details=""></v-text-field>

      <template #buttons>
        <v-btn
          variant="text"
          ripple
          :disabled="!editSetDialog.name || editSetDialog.processing"
          @click.prevent="submitEditSet(set)"
        >
          Save
        </v-btn>
      </template>
    </ndt-dialog>

    <ndt-dialog
      v-model:active="editDescriptorDialog.active"
      title="Update Descriptor"
      :error-message="editDescriptorDialog.errorMessage"
      :on-close="() => onDialogClose(editDescriptorDialog)"
    >
      <div class="d-flex">
        <div class="flex-grow-1">
          <v-text-field v-model="editDescriptorDialog.descriptor" label="Descriptor" hide-details=""></v-text-field>
        </div>
        <div class="justify-end">
          <mosaic-color-select :colors="colors" v-model:selected-color="editDescriptorDialog.color" />
        </div>
      </div>
      <template #buttons>
        <v-btn
          variant="text"
          ripple
          @click="submitEditDescriptor()"
          :disabled="!editDescriptorDialog.descriptor || editDescriptorDialog.processing"
        >
          Save
        </v-btn>
      </template>
    </ndt-dialog>
    <ndt-dialog
      v-model:active="createDescriptorDialog.active"
      title="New Descriptor"
      :error-message="createDescriptorDialog.errorMessage"
      :on-close="() => onDialogClose(createDescriptorDialog)"
    >
      <div class="d-flex">
        <div class="flex-grow-1">
          <v-text-field v-model="createDescriptorDialog.descriptor" label="Descriptor" hide-details=""></v-text-field>
        </div>
        <div class="justify-end">
          <mosaic-color-select :colors="colors" v-model:selected-color="createDescriptorDialog.color" />
        </div>
      </div>
      <template #buttons>
        <v-btn
          variant="text"
          ripple
          :disabled="
            !createDescriptorDialog.descriptor || !createDescriptorDialog.color || createDescriptorDialog.processing
          "
          @click.prevent="submitCreateDescriptor()"
        >
          Save
        </v-btn>
      </template>
    </ndt-dialog>
    <ndt-dialog
      v-model:active="deleteDescriptorDialog.active"
      title="Delete Descriptor"
      :error-message="deleteDescriptorDialog.errorMessage"
    >
      <span>Are you sure you want to delete the Descriptor "{{ deleteDescriptorDialog.descriptor }}"?</span>
      <template #buttons>
        <v-btn
          variant="text"
          ripple
          color="error"
          :disabled="deleteDescriptorDialog.processing"
          @click.prevent="submitDeleteDescriptor()"
          >Delete</v-btn
        >
      </template>
    </ndt-dialog>
    <ndt-dialog
      v-model:active="deleteSetDialog.active"
      title="Delete Judgement Set"
      :error-message="deleteSetDialog.errorMessage"
    >
      <span>Are you sure you want to delete the Judgement Set "{{ deleteSetDialog.name }}"?</span>
      <template #buttons>
        <v-btn
          variant="text"
          ripple
          color="error"
          :disabled="deleteSetDialog.processing"
          @click.prevent="submitDeleteSet()"
          >Delete</v-btn
        >
      </template>
    </ndt-dialog>
    <ndt-dialog
      v-model:active="addSetDialog.active"
      title="Add Judgement Set"
      :error-message="addSetDialog.errorMessage"
    >
      <mosaic-text-field
        v-model="addSetDialog.name"
        label="Judgement Set Name"
        name="judgement-set-name"
        prepend-icon="mdi-pencil-box-multiple"
      />
      <mosaic-card-subheading class="pt-4 pb-2">Descriptors</mosaic-card-subheading>
      <div v-for="(d, index) in addSetDialog.descriptors" :key="index">
        <div class="d-flex">
          <div style="width: 60%">
            <mosaic-text-field
              v-model="d.descriptor"
              :label="`Descriptor ${index + 1}`"
              :name="`descriptor-${index + 1}`"
              prepend-icon="mdi-pencil-box-outline"
            />
          </div>
          <div class="pl-2" style="width: 30%">
            <mosaic-color-select v-model:selected-color="d.color" :colors="colors" />
          </div>
          <div class="mt-4 pl-2" style="width: 10%">
            <ndt-icon-button
              icon="delete"
              :disabled="addSetDialog.descriptors.length < 3"
              :tooltip="
                addSetDialog.descriptors.length < 3
                  ? `Judgement Set must have at least two descriptors`
                  : `Delete Descriptor`
              "
              @click.prevent="removeDescriptorFromNewSet(index)"
            />
          </div>
        </div>
      </div>
      <mosaic-card-subheading class="pt-4">Add Descriptor</mosaic-card-subheading>
      <div class="d-flex">
        <div style="width: 60%">
          <mosaic-text-field
            v-model="addSetDialog.newDescriptorName"
            label="New Descriptor"
            name="new-descriptor"
            prepend-icon="mdi-pencil-box-outline"
          />
        </div>
        <div class="pl-2" style="width: 30%">
          <mosaic-color-select v-model:selected-color="addSetDialog.newDescriptorColor" :colors="colors" />
        </div>
        <div class="mt-4" style="width: 10%">
          <v-btn ripple variant="text" @click.prevent="addDescriptorToNewSet()">
            <span>Add</span>
          </v-btn>
        </div>
      </div>

      <template #buttons>
        <v-btn
          variant="text"
          ripple
          :disabled="addSetDialog.processing || !canAddJudgementSet"
          @click.prevent="submitAddJudgementSet()"
          >Submit</v-btn
        >
      </template>
    </ndt-dialog>
  </div>
</template>

<script>
import NdtIconButton from '../../components/NdtIconButton.vue';
import NdtDialog from '../../components/NdtDialog.vue';
import MosaicColorSelect from '@/components/library/inputs/MosaicColorSelect.vue';
import { mapState } from 'vuex';

export default {
  name: 'TutorAdminJudgementSetsPage',
  data: function () {
    return {
      judgementSets: [],
      loadError: false,
      loading: true,
      newJudgementSet: '',
      error: '',
      editDescriptorDialog: {
        active: false,
        descriptor: '',
        id: null,
        color: '',
        errorMessage: '',
        processing: false,
      },
      createDescriptorDialog: {
        active: false,
        descriptor: '',
        color: '',
        errorMessage: '',
        setId: null,
        processing: false,
      },
      deleteDescriptorDialog: {
        active: false,
        errorMessage: '',
        descriptorId: null,
        descriptor: '',
        processing: false,
      },
      deleteSetDialog: {
        active: false,
        errorMessage: '',
        setId: null,
        name: '',
        processing: false,
      },
      editSetDialog: {
        active: false,
        setId: null,
        name: '',
        errorMessage: '',
        processing: false,
      },
      addSetDialog: {
        active: false,
        errorMessage: '',
        name: '',
        processing: false,
        newDescriptorName: '',
        newDescriptorColor: '#0000FF',
        descriptors: [
          {
            descriptor: 'Not Met',
            color: '#FF0000',
          },
          {
            descriptor: 'Met',
            color: '#008000',
          },
        ],
      },
      colors: [
        { name: 'Red', hex: '#FF0000' },
        { name: 'Green', hex: '#008000' },
        { name: 'Blue', hex: '#0000FF' },
        { name: 'Amber', hex: '#FF9800' },
        { name: 'Grey', hex: '#757575' },
      ],
    };
  },
  async created() {
    this.refreshJudgementSets();
  },
  computed: {
    ...mapState(['selectedInstitution']),
    breadcrumbs() {
      return [{ text: 'Judgement Sets' }];
    },
    canAddJudgementSet() {
      return this.addSetDialog.descriptors.every(d => d.descriptor) && this.addSetDialog.name;
    },
  },
  methods: {
    async refreshJudgementSets() {
      this.loading = true;
      try {
        const r = await this.$api.get(`institutions/${this.selectedInstitution.id}/admin-institution-judgement-sets`);
        this.judgementSets = r.data;
      } catch (e) {
        this.error = true;
      }
      this.loading = false;
      this.$store.dispatch('loadJudgementSets', { force: true });
    },
    addJudgementSet() {
      this.addSetDialog.active = true;
    },
    descriptorTooltip(set) {
      return set.isInUse
        ? 'Descriptors are in use, cannot delete Descriptor'
        : set.institutionJudgementDescriptors.length < 3
        ? 'Judgement Set must have at least two descriptors'
        : 'Delete Descriptor';
    },
    canDeleteDescriptors(set) {
      return !set.isInUse && set.institutionJudgementDescriptors.length > 2;
    },
    addDescriptorToNewSet() {
      this.addSetDialog.descriptors.push({
        descriptor: this.addSetDialog.newDescriptorName,
        color: this.addSetDialog.newDescriptorColor,
      });
    },
    removeDescriptorFromNewSet(index) {
      this.addSetDialog.descriptors.splice(index, 1);
    },
    async submitAddJudgementSet() {
      try {
        await this.$api.post(`/institutions/${this.selectedInstitution.id}/institution-judgement-sets`, {
          name: this.addSetDialog.name,
          descriptors: this.addSetDialog.descriptors,
        });
        this.newJudgementSet = '';
        this.addSetDialog = {
          active: false,
          errorMessage: '',
          name: '',
          processing: false,
          newDescriptorName: '',
          newDescriptorColor: '#0000FF',
          descriptors: [
            {
              descriptor: 'Not Met',
              color: '#FF0000',
            },
            {
              descriptor: 'Met',
              color: '#008000',
            },
          ],
        };
        this.refreshJudgementSets();
      } catch (e) {
        console.log(e);
        this.addSetDialog.errorMessage = `Sorry, cannot add Judgement Set right now`;
      }
    },
    editDescriptor(descriptor) {
      this.editDescriptorDialog = {
        active: true,
        id: descriptor.id,
        descriptor: descriptor.descriptor,
        color: descriptor.color,
        errorMessage: '',
        processing: false,
      };
    },
    async submitEditDescriptor() {
      try {
        await this.$api.put(
          `/institution-judgement-descriptors/${this.editDescriptorDialog.id}`,
          this.editDescriptorDialog
        );
        this.editDescriptorDialog = {
          active: false,
          setId: null,
          name: '',
          errorMessage: '',
          processing: false,
        };
        this.refreshJudgementSets();
      } catch (e) {
        this.editDescriptorDialog.errorMessage =
          (e.response && e.response.data && e.response.data.message) || `Sorry, cannot update Judgement Set right now`;
      }
    },
    async submitEditSet() {
      try {
        await this.$api.put(`/institution-judgement-sets/${this.editSetDialog.setId}`, this.editSetDialog);
        this.editSetDialog = {
          active: false,
          descriptor: '',
          color: '',
          errorMessage: '',
          processing: false,
        };
        this.refreshJudgementSets();
      } catch (e) {
        this.editDescriptorDialog.errorMessage =
          (e.response && e.response.data && e.response.data.message) || `Sorry, cannot update Descriptor right now`;
      }
    },
    createDescriptor(set) {
      this.createDescriptorDialog = {
        active: true,
        descriptor: '',
        color: '',
        errorMessage: '',
        setId: set.id,
        processing: false,
      };
    },
    deleteDescriptor(descriptor) {
      this.deleteDescriptorDialog = {
        active: true,
        descriptorId: descriptor.id,
        errorMessage: '',
        descriptor: descriptor.descriptor,
        processing: false,
      };
    },
    deleteSet(set) {
      this.deleteSetDialog = {
        active: true,
        setId: set.id,
        name: set.name,
        errorMessage: '',
        processing: false,
      };
    },
    editSet(set) {
      this.editSetDialog = {
        active: true,
        setId: set.id,
        name: set.name,
        errorMessage: '',
        processing: false,
      };
    },
    async submitDeleteDescriptor() {
      this.deleteDescriptorDialog.processing = true;
      try {
        await this.$api.delete(`/institution-judgement-descriptors/${this.deleteDescriptorDialog.descriptorId}`);
        this.deleteDescriptorDialog = {
          active: false,
          descriptorId: null,
          errorMessage: '',
          processing: false,
          descriptor: '',
        };
        this.refreshJudgementSets();
      } catch (e) {
        console.log(e);
        this.deleteDescriptorDialog.errorMessage = `Sorry, cannot delete Descriptor right now`;
      }
      this.deleteDescriptorDialog.processing = false;
    },
    async submitDeleteSet() {
      this.deleteSetDialog.processing = true;
      try {
        await this.$api.delete(`/institution-judgement-sets/${this.deleteSetDialog.setId}`);
        this.deleteSetDialog = {
          active: false,
          setId: null,
          errorMessage: '',
          processing: false,
          name: '',
        };
        this.refreshJudgementSets();
      } catch (e) {
        console.log(e);
        this.deleteDescriptorDialog.errorMessage = `Sorry, cannot delete Judgement Set right now`;
      }
      this.deleteDescriptorDialog.processing = false;
    },
    async submitCreateDescriptor() {
      try {
        await this.$api.post(
          `/institutions/${this.selectedInstitution.id}/institution-judgement-descriptors`,
          this.createDescriptorDialog
        );
        this.createDescriptorDialog = {
          active: false,
          descriptor: '',
          color: '',
          errorMessage: '',
          setId: null,
          processing: false,
        };
        this.refreshJudgementSets();
      } catch (e) {
        this.editDescriptorDialog.errorMessage =
          (e.response && e.response.data && e.response.data.message) || `Sorry, cannot update Descriptor right now`;
      }
    },
    onDialogClose(dialog) {
      dialog.descriptor = '';
      dialog.id = null;
      dialog.color = null;
      dialog.errorMessage = '';
      if ('setId' in dialog) dialog.setId = null;
    },
    getBoxClass(set, index) {
      const borderClass =
        index === 0
          ? 'descriptor-box-left'
          : index === set.institutionJudgementDescriptors.length - 1
          ? 'descriptor-box-right'
          : 'descriptor-box-center';
      return borderClass;
    },
  },

  components: { NdtIconButton, NdtDialog, MosaicColorSelect },
};
</script>

<style scoped>
.descriptor-box-left {
  border: 2px solid;
  border-right-color: black;
  border-radius: 8px 0px 0px 8px;
}
.descriptor-box-right {
  border: 2px solid;
  border-left: none;
  border-radius: 0px 8px 8px 0px;
}
.descriptor-box-center {
  border: 2px solid;
  border-right-color: black;
  border-left: none;
}
</style>
