// This is the original version of the syncQueryParamsMixin that is now only used in the FilesList
// This mixin had to be replaced because it didn't compose if multiple are used in the same page, but this doesn't apply to the FilesList
// This mixin supports 'push' mode, which is needed for the FilesList and hasn't been added to the new version (yet)

// config is an object with the following structue
// { componentData1: { query: 'how_this_appears_in_the_url', type: 'boolean' }, componentData2: { query: 'how_this_appears_in_the_url', type: 'integer' } }
// If no type is supplied then it's treated as a string

// keyPrefixProp allows same component to be in the page multiple times and all be synced to the query
// It uses a prop to dynamically retrieve the prefix
export function syncQueryParamsMixin(config, queryKeyPrefxProp = '', routerMode = 'replace') {
  const watch = {};
  const methods = {};

  Object.keys(config).forEach(property => {
    const type = config[property].type;

    watch[property] = function (x) {
      this.updateQuery(x);
    };

    methods[`set${property}`] = function (param) {
      if (this[property] === param) return;

      param = convertQueryToData(type, param);

      if (param !== undefined) {
        this[property] = param;
      }
    };
  });

  return {
    data() {
      return {
        hasDoneFirstPageLoadReplace: false,
      };
    },
    created() {
      Object.keys(config).forEach(property => {
        this.$watch(`$route.query.${this.getQueryParamKey(property)}`, function (param) {
          this[`set${property}`](param);
        });
      });
    },
    mounted() {
      // Runs as mounted so it runs after component.created
      // This means any values set in the component.data/created will be overwritten with what's in the query
      Object.keys(config).forEach(property => {
        const queryParam = this.getQueryParamKey(property);
        this[`set${property}`](this.$route.query[queryParam]);
      });
      // Then ensure any values set in the component.data/created are updated in the query
      this.updateQuery();
    },
    watch,
    methods: {
      ...methods,
      getQueryParamKey(property) {
        let key = '';
        if (queryKeyPrefxProp) {
          key = this[queryKeyPrefxProp];
        }
        return key + config[property].query;
      },
      updateQuery() {
        const queryChanged = Object.keys(config).some(
          property =>
            this[property] !==
            convertQueryToData(config[property].type, this.$route.query[this.getQueryParamKey(property)])
        );
        if (!queryChanged) return;
        // Update all tracked query parameters to the current component values to deal with multiple query params changing at the same time
        const query = { ...this.$route.query };
        Object.keys(config).forEach(property => {
          query[this.getQueryParamKey(property)] = convertDataToQuery(config[property].type, this[property]);
        });
        if (routerMode === 'replace' || !this.hasDoneFirstPageLoadReplace) {
          // Suppress calling this while navigating errors
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          this.$router.replace({ ...this.$route, query }).catch(() => {});
          this.hasDoneFirstPageLoadReplace = true;
        } else if (routerMode === 'push') {
          // Suppress calling this while navigating errors
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          this.$router.push({ ...this.$route, query }).catch(() => {});
        }
      },
    },
  };
}

// Returning undefined means the component's data won't be changed
function convertQueryToData(type, param) {
  if (param === 'null') {
    return null;
  }

  if (type === 'integer' && typeof param === 'string') {
    const value = parseInt(param);
    return isNaN(value) ? undefined : value;
  }

  if (type === 'boolean' && typeof param === 'string') {
    return param === 'false' ? false : param === 'true' ? true : undefined;
  }

  if (type === 'array') {
    return param?.split(',') || undefined;
  }

  return param;
}

function convertDataToQuery(type, param) {
  if (type === 'array') {
    return param.join(',');
  }
  return param === null ? 'null' : param;
}
