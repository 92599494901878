<template>
  <mosaic-tab-item-list
    :items="sortedCertificates"
    empty-text="There are no Certificates you accept from your Instructors to meet Competencies. Add one by clicking the button above."
    :action="{
      icon: 'plus',
      text: 'Certificate',
      click: addCertificate,
    }"
    mosaic-key="certificates"
  >
    <template #item="{ item }">
      <mosaic-list-item
        :title="item.certificateType.name"
        :icon="icons.certificate"
        :to="{
          name: 'InstitutionStaffTrainingAcceptedCertificateEditPage',
          params: { id: item.id },
        }"
        :error-snackbar="{
          active: Boolean(deleteError) && deleteItemId == item.id,
          message: isString(deleteError) ? deleteError : 'Sorry, cannot delete this Certificate at the moment',
        }"
      >
        <template #information>
          <mosaic-warning-icon v-if="!item.hasCompetencies" tooltip="This Certificate has no Competencies" />
        </template>

        <template #actions>
          <mosaic-delete-icon-btn
            object-type="Certificate"
            @click.prevent="deleteCertificate(item)"
            :disabled="deleteProcessing"
            :loading="deleteProcessing && deleteItemId == item.id"
          />
        </template>
      </mosaic-list-item>
    </template>
  </mosaic-tab-item-list>

  <mosaic-delete-dialog
    v-model:active="deleteItemDialog.active"
    :url="deleteItemDialog.url"
    object-type="Certificate"
    :object-name="deleteItemDialog.title"
    @delete="certificateDeleted"
  >
    <mosaic-alert type="warning" v-if="deleteItemDialog.inUse">
      Some of your Instructors have already had this Certificate approved. If you delete it and then re-create it, these
      Instructors will need to have their certificates re-approved.
    </mosaic-alert>
  </mosaic-delete-dialog>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import { icons } from '@/utils/icons';
import { useStaffTrainingStore } from '@/stores/staff-training';
import { withProcessingAndError } from '@/composables/processing-and-errors';
import { useApi } from '@/composables/api';
import { useStaffStore } from '@/stores/staff';
import { isString } from 'lodash';

const props = defineProps<{
  certificates: StaffTrainingAcceptedCertificateSlimResponse[];
  triggerBackgroundLoad?: boolean;
}>();

const emit = defineEmits<{
  'update:triggerBackgroundLoad': [triggerBackgroundLoad: boolean];
}>();

interface StaffTrainingAcceptedCertificateSlimResponse {
  id: number;
  certificateType: { name: string };
  hasCompetencies: boolean;
}

const api = useApi();
const { userStaff } = useStaffStore();
const {
  actions: { clearAllStaffTraining, loadStaffTraining },
} = useStaffTrainingStore();

const sortedCertificates = computed(() => props.certificates.sortBy(c => c.certificateType.name));

const router = useRouter();
function addCertificate() {
  router.push({
    name: 'InstitutionStaffTrainingAcceptedCertificateCreatePage',
  });
}

const deleteItemDialog = ref({ active: false, title: '', url: '', inUse: false });
const deleteItemId = ref(-1);
const {
  action: deleteCertificate,
  processing: deleteProcessing,
  error: deleteError,
} = withProcessingAndError(async (certificate: StaffTrainingAcceptedCertificateSlimResponse) => {
  deleteItemId.value = certificate.id;
  const r = await api.get<{ inUse: boolean }>(`/staff-training/accepted-certificates/${certificate.id}/in-use`);

  deleteItemDialog.value = {
    active: true,
    title: certificate.certificateType.name,
    inUse: r.data.inUse,
    url: `/staff-training/accepted-certificates/${certificate.id}`,
  };
});

function certificateDeleted() {
  clearAllStaffTraining();
  loadStaffTraining(userStaff.value.id);
  emit('update:triggerBackgroundLoad', true);
}
</script>
