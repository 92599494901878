const reviewPages = [
  'TutorReviewPage',
  'TutorReviewOverallPage',
  'TutorReviewStandardPage',
  'TutorReviewPart2Page',
  'StudentReviewPage',
  'StudentReviewOverallPage',
  'StudentReviewStandardPage',
  'StudentReviewPart2Page',
];

export default {
  beforeRouteLeave(to, _from, next) {
    if (!reviewPages.includes(to.name)) {
      this.$store.commit('clearStudentReview');
    }

    next();
  },
};
