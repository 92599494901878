<template>
  <div class="d-flex align-center">
    <div class="pr-2" :style="{ width: labelWidth || 'default' }">{{ label }}:</div>
    <div class="d-flex align-center" style="column-gap: 8px">
      <mosaic-role-completed-chip
        v-for="roleId of selectedRoleIds"
        :completed="false"
        :role-id="roleId"
        :key="roleId"
        :role-scoped-tooltip="(role: RoleWithStudent) => role.name"
      />
      <div v-if="selectedRoleIds.length === 0" class="d-flex align-end">
        <mosaic-icon icon="alert-outline" color="error" />
        <span class="pl-1 pt-1">No Roles selected</span>
      </div>
      <mosaic-icon-btn v-if="isEditing" icon="pencil" tooltip="Edit Roles" @click="openDialog()" />
    </div>
  </div>

  <mosaic-dialog v-model:active="dialogActive" title="Update Roles" :width="500">
    <div>
      <div>{{ label }}:</div>
      <div v-for="role of dialogRoles" :key="role.id">
        <mosaic-disabled-tooltip
          v-slot="{ disabled }"
          :disabled="!role.canView || role.disabled"
          :tooltip="`${role.id === 'student' ? traineeNounCapitalised() : 'Role'} ${disabledTooltip}`"
        >
          <mosaic-checkbox
            :label="role.name"
            :name="role.name"
            :model-value="role.canView && dialogSelectedRoles.includes(role.id)"
            @update:model-value="roleSelected(role.id)"
            no-icon
            hide-details
            dense
            :disabled="disabled"
          />
        </mosaic-disabled-tooltip>
      </div>
    </div>

    <template #buttons>
      <mosaic-btn variant="text" color="primary" @click="update">Update</mosaic-btn>
    </template>
  </mosaic-dialog>
</template>

<script setup lang="ts">
import { mapGetters } from '@/store/map-store';
import { mapActions } from '@/store/map-store';
import { computed, ref } from 'vue';
import type { RoleWithStudent } from '@/store/map-store';

// includes 'student' as the roleId for trainees
type RoleId = number | 'student';
export type ReviewRoleSelectRole = { roleId: RoleId; selected: boolean; canView: boolean; disabled?: boolean };

const props = withDefaults(
  defineProps<{
    roles: ReviewRoleSelectRole[];
    label: string;
    labelWidth?: string;
    disabledTooltip?: string;
    isEditing?: boolean;
  }>(),
  {
    isEditing: true,
    disabledTooltip: 'is not marked as a Viewer for this template',
  }
);

const emit = defineEmits<{
  selectedRolesUpdated: [v: { roleId: RoleId; selected: boolean }[]];
}>();

const selectedRoleIds = computed(() => props.roles.filter(r => r.selected && r.canView).map(r => r.roleId));

const { loadRoles } = mapActions();
loadRoles();
const { traineeNounCapitalised, rolesWithStudent } = mapGetters();

const dialogRoles = computed(() =>
  rolesWithStudent.value
    .map(r => {
      const role = props.roles.find(x => x.roleId == r.id);
      return { ...r, show: !!role, canView: role?.canView || false, disabled: role?.disabled || false };
    })
    .filter(r => r.show)
);
const dialogSelectedRoles = ref<RoleId[]>([]);
const dialogActive = ref(false);

function openDialog() {
  dialogActive.value = true;
  dialogSelectedRoles.value = [...selectedRoleIds.value];
}

function roleSelected(roleId: RoleId) {
  if (dialogSelectedRoles.value.includes(roleId)) {
    dialogSelectedRoles.value = dialogSelectedRoles.value.filter(r => r !== roleId);
  } else {
    dialogSelectedRoles.value = [...dialogSelectedRoles.value, roleId];
  }
}

function update() {
  emit(
    'selectedRolesUpdated',
    dialogRoles.value.map(r => ({
      roleId: r.id,
      selected:
        dialogSelectedRoles.value.includes(r.id) ||
        (!r.canView && props.roles.find(pr => pr.roleId === r.id)!.selected),
    }))
  );
  dialogActive.value = false;
}
</script>
