<template>
  <div>
    <v-card class="mb-4">
      <v-card-text>
        <div class="pa-2 d-flex align-center" :class="{ 'flex-wrap justify-end': smallScreen }" style="row-gap: 8px">
          <div class="text-h6">Part 2 - Personal and Professional Conduct</div>
          <div v-if="dirty" class="mx-2">(unsaved)</div>
          <div v-else style="width: 63px" class="mx-2"></div>
          <div class="flex-grow-1"></div>
          <template v-if="!studentReview.approved">
            <mosaic-role-completed-chip
              class="pl-1"
              v-for="x in studentReview.part2Completed"
              :key="x.name"
              :role-id="x.roleId"
              :completed="x.completed"
            />
          </template>
          <v-btn v-if="previous" class="ml-2" @click.prevent="$router.push(previous)"
            ><v-icon>mdi-chevron-left</v-icon></v-btn
          >
          <v-btn class="ml-2" @click.prevent="backToSummary()">Back to Summary</v-btn>
        </div>
        <div v-if="studentReview.approved" class="pl-1 pt-2">
          <v-chip color="secondary">Approved</v-chip>
          <span class="pl-1">This {{ reviewPointName }} has been approved and can no longer be edited</span>
        </div>
      </v-card-text>
    </v-card>
    <v-card class="mb-4">
      <v-card-text>
        <div class="pl-2 py-2">
          <div v-for="s in editableStatements" :key="s.id" class="mb-4">
            <review-part-title :can-edit="canEdit" :completed="!!s.judgementDescriptorId">{{
              s.statement
            }}</review-part-title>
            <div v-if="canEdit" class="px-2">
              <div class="d-flex align-center">
                <mosaic-judgement-select
                  v-model="s.judgementDescriptorId"
                  :readonly="false"
                  :items="judgementDescriptors"
                />
                <mosaic-checkbox
                  v-if="isNotMet(s.judgementDescriptorId)"
                  name="concerns-raised"
                  v-model="s.concernsRaised"
                  no-icon
                  label="Concerns raised"
                  class="ml-2 mt-0"
                  dense
                  hide-details
                />
              </div>
              <template v-if="s.judgementDescriptorId">
                <div class="text-h6 mt-4">Comments{{ isNotMet(s.judgementDescriptorId) ? '' : ' (optional)' }}</div>
                <div class="pa-2">
                  <v-textarea
                    v-model="s.comment"
                    :placeholder="'Please comment on the ' + traineeNoun() + '\'s progress'"
                    variant="filled"
                    auto-grow
                    type="text"
                    rows="2"
                    hide-details
                  />
                </div>
              </template>
            </div>
            <div v-else class="pa-2">
              <div v-if="judgementDescriptor(s)">
                <div class="d-flex align-center ml-7">
                  <v-chip :color="judgementDescriptor(s).color">{{ judgementDescriptor(s).descriptor }}</v-chip>

                  <div v-if="isNotMet(s.judgementDescriptorId)" class="ml-4">
                    Concerns raised
                    <v-icon v-if="s.concernsRaised">mdi-checkbox-marked-circle-outline</v-icon>
                    <v-icon v-else>mdi-checkbox-blank-circle-outline</v-icon>
                  </div>
                </div>

                <div class="ml-2 mt-2">
                  <div class="text-body-1">{{ s.comment || 'To be completed' }}</div>
                </div>
              </div>
              <div v-else class="ml-1 text-body-1">To be completed</div>
            </div>
          </div>
        </div>
        <v-alert density="compact" class="my-4 mx-4" v-if="error" type="error">{{ error }}</v-alert>
        <div v-if="canEdit" class="d-flex">
          <div class="flex-grow-1">
            <mosaic-snackbar v-model="snackbar" color="success" :message="snackbarMessage" />"
          </div>
          <v-btn v-if="studentReview.approved" class="ml-2" @click.prevent="backToSummary()">Return</v-btn>
          <template v-else>
            <v-btn ripple :disabled="saveDisabled" @click.prevent="save()">Save</v-btn>
            <v-btn class="ml-2" @click.prevent="saveAndContinue()">Save and Return</v-btn>
          </template>
        </div>
      </v-card-text>
    </v-card>
    <mosaic-mark-as-complete-dialog
      v-model:active="markAsCompleteDialog.active"
      :roles-that-can-complete="myRolesThatCanMarkAsComplete"
      :student-viewer="studentViewer"
      :staff-viewer="staffViewer"
      :next="markAsCompleteDialog.next"
      :student-review-id="studentReview.id"
      :review-path="reviewPath.to"
    ></mosaic-mark-as-complete-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { roleHasPermission, rolesThatCanComplete, canStudentMarkAsComplete } from '@/utils/student-review';
import ReviewPartTitle from './ReviewPartTitle.vue';
import MosaicMarkAsCompleteDialog from './library/dialogs/reviews/MosaicMarkAsCompleteDialog.vue';

export default {
  name: 'ReviewPart2Card',
  components: { ReviewPartTitle, MosaicMarkAsCompleteDialog },
  props: {
    studentReview: {
      type: Object,
      required: true,
    },
    staffViewer: {
      type: Object,
      default: null,
    },
    studentViewer: {
      type: Object,
      default: null,
    },
    student: {
      type: Object,
      required: true,
    },
    reviewPath: {
      type: Object,
      required: true,
    },
  },
  emits: ['update:dirty'],
  data: () => ({
    error: '',
    processing: false,
    snackbar: false,
    snackbarMessage: '',
    editableStatements: [],
    markAsCompleteDialog: {
      active: false,
      next: null,
      afterSave: true,
    },
  }),
  async created() {
    if (this.studentReview.review.useStPhilipsLegacyPart2JudgementSet) {
      await this.$store.dispatch('loadStPhilipsLegacyPart2JudgementSet');
    } else {
      await this.$store.dispatch('loadPart2JudgementSet');
    }
    this.mapEditableStatements();
  },
  watch: {
    studentReview() {
      this.mapEditableStatements();
    },
    dirty(x) {
      this.$emit('update:dirty', x);
    },
  },
  computed: {
    ...mapState([
      'selectedInstitution',
      'part2JudgementSet',
      'stPhilipsLegacyPart2JudgementSet',
      'studentReviewPages',
      'roles',
    ]),
    myRolesThatCanMarkAsComplete() {
      return this.staffViewer
        ? rolesThatCanComplete(this.roles, this.studentReview, this.staffViewer, this.student)
        : [];
    },
    canMarkAnyAsComplete() {
      return this.staffViewer ? this.myRolesThatCanMarkAsComplete.length : canStudentMarkAsComplete(this.studentReview);
    },
    canEdit() {
      if (this.studentReview.approved) return false;
      if (this.studentViewer) return false;

      return roleHasPermission(this.studentReview.review.roleIdsThatCanEditPart2, this.staffViewer, this.student);
    },
    judgementDescriptors() {
      return (
        (this.studentReview.review.useStPhilipsLegacyPart2JudgementSet
          ? this.stPhilipsLegacyPart2JudgementSet
          : this.part2JudgementSet
        )?.judgement_descriptors || []
      );
    },
    saveDisabled() {
      if (this.studentViewer) return true;
      if (this.staffViewer && !this.canEdit) return true;
      return this.processing;
    },
    dirty() {
      return this.studentReview.part2Statements.some(x => {
        const statement = this.editableStatements.find(s => s.id === x.id);
        const judgementDirty =
          statement?.judgementDescriptorId !== x.judgementDescriptorId &&
          !(x.judgementDescriptorId === null && statement?.judgementDescriptorId === this.bestDescriptorId);
        return judgementDirty || statement?.concernsRaised !== x.concernsRaised || statement.comment !== x.comment;
      });
    },
    bestDescriptorId() {
      if (!this.judgementDescriptors) return null;
      const maxOrder = Math.max(...this.judgementDescriptors.map(x => x.order));
      return this.judgementDescriptors.find(x => x.order === maxOrder)?.id;
    },
    reviewPointName() {
      return this.selectedInstitution.config.review_noun;
    },
    previous() {
      return this.studentReview.reviewStandards.find(x => x.id === 'part2')?.previous;
    },
  },
  methods: {
    async saveToApi(next) {
      this.error = '';
      this.processing = true;
      try {
        const statements = this.editableStatements.map(x => {
          const descriptor = this.judgementDescriptors.find(jd => jd.id === x.judgementDescriptorId);
          const met = descriptor?.descriptor === 'Met';
          return {
            id: x.id,
            judgement_descriptor_id: x.judgementDescriptorId,
            comment: x.comment,
            concerns_raised: met ? false : x.concernsRaised,
          };
        });
        await this.$api.put(`/student-reviews/${this.studentReview.id}/part-2`, {
          statements,
        });
        await this.$store.commit('updateStudentReviewPart2Statements', statements);
        next();
      } catch (e) {
        console.log(e);
        this.error = `Sorry, cannot save your ${this.reviewPointName} at the moment`;
      }
      this.processing = false;
    },
    async save() {
      await this.saveToApi(() => {
        if (this.canMarkAnyAsComplete && this.studentReviewPages.showCompletedModal) {
          this.markAsCompleteDialog = {
            active: true,
            next: null,
            afterSave: true,
          };
        } else {
          this.snackbarMessage = `${this.reviewNounCapitalised} Part 2 saved`;
          this.snackbar = true;
        }
      });
    },
    async saveAndContinue() {
      await this.saveToApi(() => {
        if (this.canMarkAnyAsComplete && this.studentReviewPages.showCompletedModal) {
          this.markAsCompleteDialog = {
            active: true,
            next: this.reviewPath.to,
            afterSave: true,
          };
        } else this.backToSummary();
      });
    },
    backToSummary() {
      this.$router.push(this.reviewPath.to);
    },
    judgementDescriptor(statement) {
      return this.judgementDescriptors.find(x => x.id === statement.judgementDescriptorId);
    },
    isNotMet(descriptorId) {
      return this.judgementDescriptors.find(x => x.id === descriptorId)?.order === 0;
    },

    mapEditableStatements() {
      this.editableStatements = this.studentReview.part2Statements.map(x => {
        return {
          id: x.id,
          statement: x.statement,
          judgementDescriptorId: x.judgementDescriptorId || null,
          comment: x.comment,
          concernsRaised: x.concernsRaised,
        };
      });
    },
  },
};
</script>
