import { isString } from 'lodash';
import { ref } from 'vue';

type Error = string;
export type AsyncOperationResult = Promise<void | void[] | 'no-op' | Error>;
// eslint-disable-next-line @typescript-eslint/ban-types
export function withProcessingAndError<T extends Array<{}>>(asyncAction: (...args: T) => AsyncOperationResult) {
  const processing = ref(false);
  const error = ref<boolean | string>(false);

  function createAction(doInBackground: boolean) {
    return async (...args: T) => {
      if (!doInBackground) processing.value = true;
      error.value = false;
      try {
        const result = await asyncAction(...args);
        processing.value = false;

        if (result == 'no-op') return false;
        if (isString(result)) {
          error.value = result;
          return false;
        }

        return true;
      } catch (e) {
        console.log(e);
        error.value = true;
        processing.value = false;
        return false;
      }
    };
  }

  return { processing, error, action: createAction(false), actionInBackground: createAction(true) };
}
