<template>
  <v-alert variant="outlined" type="warning"
    >No Judgement Sets have been configured for your Institution.
    <template v-if="hasPermission(userStaff, 'Admin')"
      >You can add/edit Judgement Sets
      <mosaic-router-link :to="{ name: 'TutorAdminJudgementSetsPage' }">here</mosaic-router-link>.</template
    ></v-alert
  >
</template>

<script setup lang="ts">
import { mapState } from '@/store/map-store';

const { userStaff } = mapState();
</script>
