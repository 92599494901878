<template>
  <div>
    <div class="text-h6">Permissions</div>
    <v-expansion-panels class="mt-4" variant="accordion">
      <permission-expansion-panel v-for="(permission, i) in friendlyPermissions" :key="i" :permission="permission" />
    </v-expansion-panels>
  </div>
</template>

<script>
import PermissionExpansionPanel from '../../components/PermissionExpansionPanel.vue';
import { mapGetters } from 'vuex';
export default {
  name: 'AdminPermissionsPage',
  async created() {
    await this.$store.dispatch('loadPermissionsForAdmin');
    await this.$store.dispatch('loadFeatureConfigsForAdmin');
  },
  computed: {
    ...mapGetters(['friendlyPermissions']),
    breadcrumbs() {
      return [{ text: 'Permissions' }];
    },
  },
  components: { PermissionExpansionPanel },
};
</script>

<style scoped></style>
