<template>
  <div class="d-flex mb-4">
    <div class="pr-2 mt-6" v-if="!noIcon && (!smallScreen || readonlySelectedCompetencies.length === 0)">
      <mosaic-icon icon="rhombus-outline" />
    </div>
    <div class="flex-grow-1">
      <div class="pb-1 text-color-default">{{ label }}</div>
      <div>
        <div class="mb-2 d-flex" v-if="!(smallScreen && readonly)">
          <div>
            <span
              :class="`${
                (!showUnassignedFrameworks ? selectedCountForAssignedFrameworks : selectedCount) > 0
                  ? 'text-primary'
                  : ''
              }`"
              >{{ !showUnassignedFrameworks ? selectedCountForAssignedFrameworks : selectedCount }}</span
            >
            / {{ !showUnassignedFrameworks ? competencyCountForAssignedFrameworks : competencies.length }}
            Competencies selected
          </div>
          <div class="px-2">-</div>
          <mosaic-expand-collapse-all @handle-expand-all="expandAll" @handle-collapse-all="collapseAll" />
          <template v-if="assignedFrameworkIds">
            <div class="px-2">-</div>
            <mosaic-disabled-tooltip
              :disabled="frameworksInternal.every(f => f.assigned)"
              tooltip="There are no unassigned Frameworks"
            >
              <template #default="{ disabled }: { disabled: Boolean }">
                <a
                  :class="{ 'text-link': !disabled, 'text-disabled': disabled }"
                  @click="!disabled ? (showUnassignedFrameworks = !showUnassignedFrameworks) : {}"
                >
                  {{ showUnassignedFrameworks ? 'Hide' : 'Show' }} unassigned Frameworks
                </a>
              </template>
            </mosaic-disabled-tooltip>
          </template>
        </div>
        <div v-if="!(smallScreen && readonly)">
          <template v-for="framework of filteredFrameworksInternal" :key="framework.id">
            <div>
              <div class="d-flex align-center">
                <mosaic-checkbox
                  :name="'framework-' + framework.id"
                  no-icon
                  hide-details
                  dense
                  :readonly="readonly"
                  :disabled="readonly"
                  :model-value="framework.selected"
                  @update:model-value="frameworkSelected(framework, $event)"
                >
                  <template #label>
                    <span>
                      {{ framework.name }}
                      <span
                        :class="{
                          'ml-1': true,
                          details: true,
                          'text-black font-weight-bold': framework.selectedCount > 0,
                        }"
                        >({{ framework.selectedCount }}/{{ framework.competencyCount }})</span
                      ></span
                    >
                  </template>
                </mosaic-checkbox>
                <mosaic-warning-icon
                  v-if="!framework.assigned"
                  :tooltip="`This Framework is not assigned to ${isMe ? 'you' : 'this Instructor'}`"
                />
                <mosaic-icon
                  v-if="framework.show"
                  icon="chevron-up"
                  @click="hideFramework(framework.id)"
                  style="cursor: pointer"
                />
                <mosaic-icon v-else icon="chevron-down" @click="showFramework(framework.id)" style="cursor: pointer" />
              </div>
            </div>

            <template v-if="framework.show">
              <template v-for="theme of framework.staffTrainingCompetencyThemes" :key="theme.id">
                <div class="pl-4 section">
                  <div class="d-flex align-center">
                    <mosaic-checkbox
                      :name="'theme-' + theme.id"
                      no-icon
                      hide-details
                      dense
                      :readonly="readonly"
                      :disabled="readonly"
                      :model-value="theme.selected"
                      @update:model-value="themeSelected(theme, $event)"
                    >
                      <template #label>
                        <span>
                          {{ theme.code }} - {{ theme.name }}
                          <span
                            :class="{
                              'ml-1': true,
                              details: true,
                              'text-black font-weight-bold': theme.selectedCount > 0,
                            }"
                            >({{ theme.selectedCount }}/{{ theme.staffTrainingCompetencies.length }})</span
                          ></span
                        >
                      </template>
                    </mosaic-checkbox>
                    <mosaic-icon
                      v-if="theme.show"
                      icon="chevron-up"
                      @click="hideTheme(theme.id)"
                      style="cursor: pointer"
                    />
                    <mosaic-icon v-else icon="chevron-down" @click="showTheme(theme.id)" style="cursor: pointer" />
                  </div>

                  <div v-if="theme.show" class="section">
                    <div
                      v-for="competency of theme.staffTrainingCompetencies"
                      :key="competency.id"
                      style="padding-left: 22px"
                    >
                      <mosaic-checkbox
                        :name="'competency-' + competency.id"
                        :label="`${competency.code} - ${competency.name}`"
                        no-icon
                        hide-details
                        dense
                        :readonly="readonly"
                        :disabled="readonly"
                        :model-value="competency.selected"
                        @update:model-value="competencySelected(competency.id, $event)"
                      />
                    </div>
                  </div>
                </div>
              </template>
            </template>
          </template>
        </div>
        <div v-else style="border: 1px solid #ccc" class="px-2">
          <mosaic-list :items="readonlySelectedCompetencies" v-if="readonlySelectedCompetencies.length > 0">
            <template #item="{ item }">
              <mosaic-list-item
                v-if="staffTrainingCompetencyTo"
                :title="item.title"
                :subtitle="item.subtitle"
                :icon="icons.instructorTrainingCompetency"
                :to="staffTrainingCompetencyTo(item.themeId)"
              />
            </template>
          </mosaic-list>
          <div v-else class="text-body-1 py-2">{{ emptyText }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { StaffTrainingFrameworkResponse } from '@/stores/institution-staff-training';
import { watch } from 'vue';
import { computed, ref } from 'vue';
import { icons } from '@/utils/icons';
import type { RouteLocationNamedRaw } from 'vue-router';
import MosaicExpandCollapseAll from '@/components/library/buttons/MosaicExpandCollapseAll.vue';

const props = withDefaults(
  defineProps<{
    modelValue: number[]; // competency ids
    frameworks: StaffTrainingFrameworkResponse;
    assignedFrameworkIds?: number[]; // Not supplying this prop removes the hiding unassigned functionality
    readonly?: boolean;
    staffTrainingCompetencyTo?: (themeId: number) => RouteLocationNamedRaw; // Only needed if readonly
    label?: string;
    emptyText?: string;
    noIcon?: boolean;
    isMe?: boolean;
  }>(),
  {
    label: 'Competencies Met',
    emptyText: 'No Competencies recorded',
  }
);

const emit = defineEmits<{
  'update:modelValue': [ids: number[]];
}>();

const showFrameworks = ref(
  props.frameworks.reduce((map, current) => {
    map[current.id] = false;
    return map;
  }, {} as Record<string, boolean>)
);

const showThemes = ref(
  props.frameworks
    .flatMap(f => f.staffTrainingCompetencyThemes)
    .reduce((map, current) => {
      map[current.id] = false;
      return map;
    }, {} as Record<string, boolean>)
);

// #region selectedCompetencies
const competencies = computed(() =>
  props.frameworks.flatMap(f => f.staffTrainingCompetencyThemes.flatMap(t => t.staffTrainingCompetencies))
);
const selectedCompetencies = ref(calculateSelectedCompetencies());

watch(
  () => props.modelValue,
  () => {
    selectedCompetencies.value = calculateSelectedCompetencies();
  }
);

function calculateSelectedCompetencies() {
  return competencies.value.reduce((map, current) => {
    map[current.id] = props.modelValue.includes(current.id);
    return map;
  }, {} as Record<string, boolean>);
}

watch(selectedCompetencies, (value, oldValue) => {
  const oldCompetencies = mapSelectedComptenciesToIds(oldValue);
  const newCompetencies = mapSelectedComptenciesToIds(value);

  if (oldCompetencies.length !== newCompetencies.length || oldCompetencies.some(c => !newCompetencies.includes(c))) {
    emit('update:modelValue', newCompetencies);
  }
});

function mapSelectedComptenciesToIds(selectedCompetencies: Record<string, boolean>) {
  return Object.entries(selectedCompetencies)
    .filter(([_, selected]) => selected)
    .map(([id, _]) => parseInt(id));
}

const selectedCount = computed(() => Object.values(selectedCompetencies.value).filter(x => x).length);

const competencyCountForAssignedFrameworks = computed(() =>
  props.frameworks
    .filter(f => !props.assignedFrameworkIds || props.assignedFrameworkIds.includes(f.id))
    .flatMap(f => f.staffTrainingCompetencyThemes.map(t => t.staffTrainingCompetencies.length))
    .sum()
);
const selectedCountForAssignedFrameworks = computed(() =>
  props.frameworks
    .filter(f => !props.assignedFrameworkIds || props.assignedFrameworkIds.includes(f.id))
    .flatMap(f =>
      f.staffTrainingCompetencyThemes.map(
        t => t.staffTrainingCompetencies.filter(c => selectedCompetencies.value[c.id]).length
      )
    )
    .sum()
);

const readonlySelectedCompetencies = computed(() => {
  return props.frameworks
    .flatMap(f =>
      f.staffTrainingCompetencyThemes.flatMap(t =>
        t.staffTrainingCompetencies
          .filter(c => selectedCompetencies.value[c.id])
          .map(c => ({
            ...c,
            title: `${c.code} - ${c.name}`,
            subtitle: `${t.code} - ${t.name} / ${f.name}`,
            themeOrder: t.order,
            themeId: t.id,
            frameworkName: f.name,
          }))
      )
    )
    .sortBy([c => c.frameworkName, c => c.themeOrder, c => c.order]);
});
// #endregion

const frameworksInternal = computed(() =>
  props.frameworks
    .filter(f => f.staffTrainingCompetencyThemes.some(t => t.staffTrainingCompetencies.length > 0))
    .map(f => {
      const staffTrainingCompetencyThemes = f.staffTrainingCompetencyThemes
        .filter(t => t.staffTrainingCompetencies.length > 0)
        .map(t => {
          const show = showThemes.value[t.id];

          const staffTrainingCompetencies = t.staffTrainingCompetencies
            .map(c => {
              return { ...c, selected: selectedCompetencies.value[c.id] };
            })
            .sortBy(c => c.order);

          const selectedCount = staffTrainingCompetencies.filter(c => c.selected).length;
          const selected = selectedCount === staffTrainingCompetencies.length;
          return { ...t, show, selected, selectedCount, staffTrainingCompetencies };
        })
        .sortBy(t => t.order);

      const show = showFrameworks.value[f.id];
      const selected = staffTrainingCompetencyThemes.every(t => t.selected);
      return {
        ...f,
        assigned: !props.assignedFrameworkIds || props.assignedFrameworkIds.includes(f.id),
        show,
        selected,
        selectedCount: staffTrainingCompetencyThemes.map(t => t.selectedCount).sum(),
        competencyCount: staffTrainingCompetencyThemes.map(t => t.staffTrainingCompetencies.length).sum(),
        staffTrainingCompetencyThemes,
      };
    })
    .sortBy([f => !f.assigned, f => f.name])
);

const showUnassignedFrameworks = ref(false);
const filteredFrameworksInternal = computed(() =>
  frameworksInternal.value.filter(f => showUnassignedFrameworks.value || f.assigned)
);

function showFramework(frameworkId: number) {
  showFrameworks.value[frameworkId] = true;
}
function hideFramework(frameworkId: number) {
  showFrameworks.value[frameworkId] = false;
}

function showTheme(themeId: number) {
  showThemes.value[themeId] = true;
}
function hideTheme(themeId: number) {
  showThemes.value[themeId] = false;
}

function expandAll() {
  setAll(true);
}
function collapseAll() {
  setAll(false);
}

function setAll(value: boolean) {
  for (const id of Object.keys(showFrameworks.value)) {
    showFrameworks.value[id] = value;
  }
  for (const id of Object.keys(showThemes.value)) {
    showThemes.value[id] = value;
  }
}

function competencySelected(competencyId: number, selected: boolean) {
  selectedCompetencies.value = { ...selectedCompetencies.value, [competencyId]: selected };
}

function themeSelected(
  theme: (typeof frameworksInternal.value)[number]['staffTrainingCompetencyThemes'][number],
  selected: boolean
) {
  for (const competency of theme.staffTrainingCompetencies) {
    competencySelected(competency.id, selected);
  }
}

function frameworkSelected(framework: (typeof frameworksInternal.value)[number], selected: boolean) {
  for (const theme of framework.staffTrainingCompetencyThemes) {
    themeSelected(theme, selected);
  }
}
</script>

<style scoped>
.details {
  font-size: 14px;
}
.grid {
  display: grid;
  grid-template-columns: minmax(0, max-content) minmax(0, max-content) minmax(0, max-content);
  margin-left: -24px;
}
.section {
  border-left: 0.5px solid rgb(198, 196, 196);
  margin-left: 14px;
}
</style>
