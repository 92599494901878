import type { ComputedRef } from 'vue';
import { computed } from 'vue';
import { useRoute } from 'vue-router';

export function parseRouteId(param: string): ComputedRef<number> {
  const route = useRoute();
  const id = route.params[param];
  if (Array.isArray(id)) throw `Query param '${param}' is an array so can't be parsed an id`;
  return computed(() => parseInt(id));
}
