<template>
  <v-card elevation="4">
    <v-toolbar color="white" elevation="6">
      <v-toolbar-title>Change your password</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <mosaic-error-alert :override-error-message="error" />
      <div v-if="isInsecurePassword" class="mb-2">
        Your password is not secure, either it is too short or a
        <a href="/#/password-security" target="blank">commonly used password</a>, please change it.
      </div>
      <mosaic-password-help class="mb-2" />
      <mosaic-password-text-field
        v-if="showOldPasswordField"
        v-model="oldPassword"
        name="oldPassword"
        label="Current Password"
      />
      <mosaic-password-text-field
        v-model="password"
        :validate-password="true"
        @password-valid="passwordValid = $event"
      />
      <mosaic-password-text-field
        v-model="passwordConfirmation"
        name="passwordConfirmation"
        label="Password Confirmation"
        :rules="passwordConfirmationRules"
        @keyup.enter="submit"
      />
      <div class="d-flex justify-end">
        <mosaic-password-security-link />
      </div>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn class="mx-2" :disabled="!canSubmit || processing" @click.prevent="submit">Submit</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { validationsPass } from '../utils/validations';
import { routeToNextStep } from '../utils/auth';

export default {
  name: 'ForceChangePasswordPage',
  data: function () {
    return {
      password: '',
      passwordConfirmation: '',
      oldPassword: '',
      showOldPasswordField: false,
      processing: false,
      passwordValid: false,
      passwordConfirmationRules: [],
      error: '',
      isInsecurePassword: false,
    };
  },
  computed: {
    canSubmit() {
      return (
        this.password &&
        this.passwordConfirmation &&
        this.oldPassword &&
        validationsPass(this.passwordConfirmationRules, this.passwordConfirmation) &&
        this.passwordValid
      );
    },
  },
  created() {
    this.isInsecurePassword = this.$route.name === 'UserInsecurePasswordPage';
    this.oldPassword = sessionStorage.getItem('password') || '';
    this.showOldPasswordField = !this.oldPassword;
    this.passwordConfirmationRules = [v => (v && v === this.password) || 'Password confirmation should match password'];
  },
  methods: {
    async submit() {
      if (!this.canSubmit) return;
      this.error = '';
      this.processing = true;
      try {
        const r = await this.$api.post('users/current/change-password', {
          old_password: this.oldPassword,
          new_password: this.password,
        });
        if (r.data.success) {
          await this.$store.dispatch('refreshUser');
          await routeToNextStep(r.data.next_step, this.$router, this.$route);
        } else if (!r.data.old_password_correct) {
          this.error = `Sorry, your current password does not match our records.`;
        } else {
          this.error =
            r.data.error_message ||
            `Sorry, cannot change your password at the moment. Please check your internet and, if you're still having issues, send an email to support@penrose.education.`;
        }
      } catch (e) {
        console.log(e);
        this.error = `Sorry, cannot change your password at the moment. Please check your internet and, if you're still having issues, send an email to support@penrose.education.`;
      }
      this.processing = false;
    },
  },
};
</script>
