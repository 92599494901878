<template>
  <div>
    <mosaic-list-filters-card title="Mentor Meetings">
      <curriculum-theme-filter v-model:curriculum-theme-id="selectedCurriculumThemeId" />
      <div
        class="d-flex align-center"
        :class="{
          'vertical-flex-spacing-small': smallScreen,
          'horizontal-flex-spacing-small': !smallScreen,
          'flex-wrap': smallScreen,
        }"
      >
        <span class="pt-4">Show Mentor Meetings between</span>
        <div class="d-flex align-center">
          <mosaic-date-picker
            v-model:date="startDate"
            placeholder="start date"
            density="compact"
            hide-details
            active-icon-colour
          />
          <span class="pt-4">and</span>
        </div>
        <div class="d-flex align-center">
          <mosaic-date-picker
            v-model:date="endDate"
            placeholder="end date"
            density="compact"
            hide-details
            active-icon-colour
          />
          <div style="margin-bottom: -20px">
            <ndt-icon-button icon="close" tooltip="Clear dates" @click.prevent="clearFilters()" />
          </div>
        </div>
      </div>
      <template #actions>
        <mosaic-btn v-if="canEditMentorMeetings" ripple @click.prevent="$emit('clickCreateMentorMeeting')">
          <div class="d-flex align-center">
            <v-icon>mdi-plus</v-icon>
            <span>Mentor Meeting</span>
          </div>
        </mosaic-btn>
      </template>
    </mosaic-list-filters-card>

    <mosaic-loading-card v-if="busy" type="list-no-filters" />
    <mosaic-load-error-card v-else-if="loadError" object-type="Mentor Meetings" @retry="loadMentorMeetings" />
    <!-- This is a v-show (instead of a v-if) so that mosaic-pagination doesn't get remounted on every load-->
    <mosaic-card v-show="!busy && !loadError">
      <div v-if="mentorMeetings.length === 0" class="pa-4 pl-6">
        No Mentor Meetings have been added<span v-if="filtersApplied"> for these filters</span>.
        <span v-if="canEditMentorMeetings"
          >Create a <a @click="$emit('clickCreateMentorMeeting')" class="text-link">new Mentor Meeting</a>.</span
        >
      </div>
      <mosaic-list v-else :items="paginatedMentorMeetings">
        <template #item="{ item: meeting }">
          <mosaic-list-item
            :key="meeting.id"
            icon="mdi-recycle-variant"
            :title="meeting.title"
            subtitle=""
            :to="mentorMeetingClickTo(meeting.id)"
          >
            <template #actions>
              <template v-if="showEvidence && canEditEvidence">
                <mosaic-evidence-icon-btn
                  v-model="meeting.evidence"
                  @click-edit="editEvidence(meeting)"
                  @click-create="createEvidence(meeting)"
                />
              </template>
              <ndt-icon-button
                v-if="canEditMentorMeetings"
                icon="delete"
                tooltip="Delete Mentor Meeting"
                @click.prevent="deleteMentorMeeting(meeting)"
              />
              <!-- Ideally this would be conditional on there being some delete buttons on the page -->
              <div v-else-if="!smallScreen" style="width: 36px"></div>
            </template>
          </mosaic-list-item>
        </template>
      </mosaic-list>
      <mosaic-pagination v-model="currentPage" v-model:page-size="pageSize" :total="mentorMeetings.length" />
    </mosaic-card>

    <ndt-dialog
      v-model:active="deleteDialog.active"
      title="Delete Mentor Meeting"
      :error-message="deleteDialog.errorMessage"
    >
      <span
        >Are you sure you want to delete "{{ deleteDialog.name }}"? Any targets set as part of this Mentor Meeting will
        not be deleted and can be found in the Targets area.</span
      >
      <template #buttons>
        <v-btn
          variant="text"
          ripple
          color="error"
          :disabled="deleteDialog.processing"
          @click.prevent="submitDeleteMentorMeeting()"
          >Delete</v-btn
        >
      </template>
    </ndt-dialog>

    <create-evidence-dialog
      v-model:active="createEvidenceDialog.active"
      :on-close="loadMentorMeetings"
      :mentor-meeting-id="createEvidenceDialog.mentorMeetingId"
      :title-placeholder="createEvidenceDialog.title"
      :suggested-codes="createEvidenceDialog.curriculumThemeCodes"
    />
    <edit-evidence-dialog v-model:active="editEvidenceDialog.active" :evidence="editEvidenceDialog.evidence" />
  </div>
</template>

<script>
import NdtIconButton from '@/components/NdtIconButton.vue';
import NdtDialog from '@/components/NdtDialog.vue';
import { mapGetters, mapState } from 'vuex';
import { syncQueryParamsMixin } from '@/mixins/query-mixins';
import moment from 'moment';
import CreateEvidenceDialog from '@/components/student/CreateEvidenceDialog.vue';
import EditEvidenceDialog from '@/components/student/EditEvidenceDialog.vue';
import CurriculumThemeFilter from '@/components/CurriculumThemeFilter.vue';
import { useQueryStore } from '@/stores/query';
import axios from 'axios';

export default {
  name: 'MentorMeetingsList',
  components: {
    NdtIconButton,
    NdtDialog,
    CreateEvidenceDialog,
    EditEvidenceDialog,
    CurriculumThemeFilter,
  },
  setup() {
    const queryStore = useQueryStore();
    return { queryStore };
  },
  mixins: [syncQueryParamsMixin({ startDate: { query: 'start' }, endDate: { query: 'end' } })],
  props: {
    studentId: {
      type: Number,
      required: true,
    },
    canEditMentorMeetings: {
      type: Boolean,
      default: false,
    },
    canEditEvidence: {
      type: Boolean,
      default: false,
    },
    mentorMeetingClickTo: {
      type: Function,
      required: true,
    },
  },
  emits: ['clickCreateMentorMeeting'],
  data() {
    return {
      busy: false,
      mentorMeetings: [],
      startDate: null,
      loadError: false,
      endDate: null,
      selectedCurriculumThemeId: null,
      deleteDialog: {
        active: false,
        errorMessage: null,
        processing: false,
        name: '',
        id: null,
      },
      createEvidenceDialog: {
        active: false,
        curriculumThemeCodes: [],
      },
      editEvidenceDialog: {
        active: false,
        evidence: {},
      },
      currentPage: 1,
      pageSize: 10,
      cancelTokenSource: null,
      initialLoadDone: false,
    };
  },
  computed: {
    ...mapState(['user']),
    ...mapGetters(['showEvidence']),
    filtersApplied() {
      return this.startDate || this.endDate || this.selectedCurriculumThemeId;
    },
    paginatedMentorMeetings() {
      const index = (this.currentPage - 1) * this.pageSize;
      return this.mentorMeetings.slice(index, index + this.pageSize);
    },
  },
  watch: {
    startDate() {
      if (this.initialLoadDone) this.loadMentorMeetings();
    },
    endDate() {
      if (this.initialLoadDone) this.loadMentorMeetings();
    },
    selectedCurriculumThemeId() {
      if (this.initialLoadDone) this.loadMentorMeetings();
    },
    mentorMeetings() {
      this.currentPage = 1;
    },
  },
  mounted() {
    this.loadMentorMeetings();
  },
  methods: {
    async loadMentorMeetings() {
      if (this.cancelTokenSource) {
        this.cancelTokenSource.cancel();
      }
      this.cancelTokenSource = axios.CancelToken.source();

      this.busy = true;
      this.loadError = false;
      try {
        const params = new URLSearchParams();
        if (this.startDate) params.append('start', this.startDate);
        // Have to add a day to make the filter inclusive
        if (this.endDate) params.append('end', moment(this.endDate).add(1, 'days').format('YYYY-MM-DD'));
        if (this.selectedCurriculumThemeId) params.append('curriculumThemeId', this.selectedCurriculumThemeId);

        const url = `/students/${this.studentId}/mentor-meetings?${params.toString()}`;
        const r = await this.$api.get(url, this.cancelTokenSource.token);
        this.mentorMeetings = r.data;
        this.initialLoadDone = true;
      } catch (e) {
        console.log(e);
        this.loadError = true;
      }
      this.busy = false;
      this.cancelTokenSource = null;
    },
    deleteMentorMeeting(meeting) {
      this.deleteDialog = {
        active: true,
        errorMessage: null,
        processing: false,
        name: meeting.title,
        id: meeting.id,
      };
    },
    async submitDeleteMentorMeeting() {
      this.deleteDialog.processing = true;
      this.deleteDialog.errorMessage = '';
      try {
        await this.$api.delete(`/mentor-meetings/${this.deleteDialog.id}`);
        this.deleteDialog.active = false;
        this.loadMentorMeetings();
      } catch (e) {
        console.log(e);
        this.deleteDialog.errorMessage = `Sorry, cannot delete your mentor meeting at the moment`;
      }
      this.deleteDialog.processing = false;
    },
    clearFilters() {
      this.startDate = null;
      this.endDate = null;
    },
    createEvidence(meeting) {
      this.createEvidenceDialog = {
        active: true,
        title: meeting.title,
        mentorMeetingId: meeting.id,
        curriculumThemeCodes: meeting.curriculum_statements.map(s => s.curriculum_theme_code).unique(),
      };
    },
    editEvidence(meeting) {
      this.$api.get(`/evidence/${meeting.evidence.id}`).then(response => {
        this.editEvidenceDialog = {
          active: true,
          evidence: response.data,
        };
      });
    },
  },
};
</script>
