<template>
  <div>
    <mosaic-tab-card-page
      v-model:trigger-background-load="triggerBackgroundLoad"
      object-type="Course Reflections"
      :object-type-is-plural="true"
      :headers="tabHeaders"
      :load="loadCourseReflections"
      @tab-selected="tab = $event"
    >
      <template #actions>
        <mosaic-btn icon="mdi-plus" :to="{ name: 'CohortCourseReflectionCreatePage', params: { cohortId: cohortId } }"
          >Course Reflection</mosaic-btn
        >
      </template>

      <template #live-tab-item>
        <cohort-course-reflections-page-list
          v-model:trigger-background-load="triggerBackgroundLoad"
          :reflections="liveReflections"
          type="live"
          :subtitle="liveReflectionSubtitle"
          :click-reflection-to="clickReflectionTo"
        />
      </template>

      <template #scheduled-tab-item>
        <cohort-course-reflections-page-list
          v-model:trigger-background-load="triggerBackgroundLoad"
          :reflections="scheduledReflections"
          type="scheduled"
          :subtitle="r => `Scheduled for ${moment(r.scheduled_date).format('DD-MM-YYYY')}`"
          :click-reflection-to="clickReflectionTo"
        />
      </template>
      <template #monitoring-tab-item>
        <mosaic-cohort-monitoring-filters
          class="mb-6"
          :students="selectedCohortStudents"
          @update:filtered-student-ids="filteredStudentIds = $event"
        />
        <mosaic-cohort-completion-table
          :object-type-pluralised="traineeNounCapitalisedAndPluralised"
          :statuses="completionStatuses"
          :status-columns="reflectionStatusColumns"
          column-object-type-pluralised="Course Reflections"
          :rows="monitoringRows"
          :name-click-route="nameClickRoute"
          :click-route="dataClickRoute"
          :selected-group-id="selectedGroupId"
        />
      </template>
    </mosaic-tab-card-page>
  </div>
</template>

<script setup lang="ts">
import MosaicTabCardPage from '@/components/library/pages/MosaicTabCardPage.vue';
import { parseRouteId } from '@/composables/vue-router';
import moment from 'moment';
import { computed } from 'vue';
import { ref } from 'vue';
import CohortCourseReflectionsPageList from './CohortCourseReflectionsPageList.vue';
import type { CourseReflection } from './CohortCourseReflectionsPageList.vue';
import MosaicCohortCompletionTable from '@/components/monitoring/MosaicCohortCompletionTable.vue';
import { setBreadcrumbs } from '@/utils/breadcrumbs';
import type { Row } from '@/components/monitoring/mosaic-table';
import { useApi } from '@/composables/api';
import { useCohortStore } from '@/stores/cohort';

const api = useApi();
const { selectedCohortStudents } = useCohortStore();

setBreadcrumbs([
  {
    text: 'Course Reflections',
  },
]);

const tabHeaders = [
  {
    text: 'Live',
    key: 'live',
  },
  {
    text: 'Scheduled',
    key: 'scheduled',
  },
  {
    text: 'Monitoring',
    key: 'monitoring',
  },
];
const tab = ref('live');

const cohortId = parseRouteId('cohortId');
const reflections = ref<CourseReflection[]>([]);
const triggerBackgroundLoad = ref(false);
async function loadCourseReflections() {
  const r = await api.get<CourseReflection[]>(`/admin-reflections?cohortId=${cohortId.value}`);
  reflections.value = r.data;
}

const liveReflections = computed(() =>
  reflections.value
    .filter(x => !x.scheduled_date || x.schedule_created_date)
    .map(x => ({
      ...x,
      created_date: x.schedule_created_date || x.created_at,
    }))
    .sort((a, b) => (moment(a.set_date || a.created_date) < moment(b.set_date || b.created_date) ? 1 : -1))
);

function liveReflectionSubtitle(reflection: CourseReflection) {
  return `Set ${moment(reflection.set_date || reflection.schedule_created_date || reflection.created_at).format(
    'DD-MM-YYYY'
  )}`;
}

const scheduledReflections = computed(() =>
  reflections.value
    .filter(x => x.scheduled_date && !x.schedule_created_date)
    .map(x => ({
      ...x,
    }))
    .sort((a, b) => (moment(a.scheduled_date) > moment(b.scheduled_date) ? 1 : -1))
);

const clickReflectionTo = (t: CourseReflection) => ({
  name: 'CohortCourseReflectionEditPage',
  params: { cohortId: cohortId.value.toString(), id: t.id.toString() },
  query: { fromTab: tab.value },
});

// Monitoring
const selectedGroupId = ref<number>(-1);
type Student = {
  id: number;
  name: string;
};

function studentReflectionMap(student: Student) {
  return (courseReflection: CourseReflection) => {
    const ars = courseReflection.admin_reflection_students.find(ars => ars.student_id === student.id);
    if (!ars) return {};
    return {
      value: ars.submitted ? 'submitted' : 'pending',
      reflectionId: ars.reflection_id,
    };
  };
}

const filteredStudentIds = ref<number[]>(selectedCohortStudents.value.map(s => s.id));
const monitoringRows = computed(() =>
  selectedCohortStudents.value
    .filter(s => filteredStudentIds.value.includes(s.id))
    .map(s => ({
      id: s.id,
      name: s.name || s.email,
      studentGroups: s.student_groups,
      ...liveReflections.value.reduce((acc, t) => ({ ...acc, [t.id]: studentReflectionMap(s)(t) }), {}),
    }))
);

type StudentCourseReflectionData = {
  id: number;
  name: string;
} & Record<string, { value: string; reflectionId: number }>;

const completionStatuses = {
  pending: {
    text: 'Pending',
    color: 'pending',
    sortOrder: 2,
  },
  submitted: {
    text: 'Submitted',
    color: 'primary',
    sortOrder: 1,
  },
};
function dataClickRoute(row: Row, reflectionId: number) {
  const studentCourseReflectionData = row as StudentCourseReflectionData;
  const data = studentCourseReflectionData[reflectionId];
  if (!data.value) return undefined;
  return {
    name: 'ReflectionPage',
    params: {
      studentId: studentCourseReflectionData.id.toString(),
      reflectionId: data.reflectionId ? data.reflectionId.toString() : '',
    },
  };
}

function nameClickRoute(row: Row) {
  return {
    name: 'ReflectionsListPage',
    params: {
      studentId: (row as Student).id.toString(),
    },
  };
}
const reflectionStatusColumns = computed(() =>
  liveReflections.value.map(r => ({
    name: moment(r.set_date || r.schedule_created_date || r.created_date).format('DD/MM') + ': ' + r.title,
    id: r.id,
  }))
);
</script>
