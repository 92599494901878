<template>
  <student-mentor-meetings-list-page v-if="isStudent" />
  <tutor-mentor-meetings-list-page v-else />
</template>

<script setup lang="ts">
import { useUserStore } from '@/stores/user';
import StudentMentorMeetingsListPage from './StudentMentorMeetingsListPage.vue';
import TutorMentorMeetingsListPage from './TutorMentorMeetingsListPage.vue';

const { isStudent } = useUserStore();
</script>
