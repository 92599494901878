<template>
  <div>
    <mosaic-loading-card v-if="stateProcessing" type="list" />
    <mosaic-load-error-card v-else-if="stateError" object-type="Curriculum" @retry="refreshThemes" />
    <div v-else>
      <v-card v-if="!loadCurriculumProcessing" class="flex-grow-1 mb-4">
        <v-card-text>
          <div class="d-flex align-center">
            <mosaic-card-title v-if="themes">Curriculum</mosaic-card-title>
            <div v-if="isCohortPage">
              <div v-if="canEditCohortCurriculum">
                <v-switch
                  v-if="!hasPublishedCourseWeeks"
                  density="compact"
                  hide-details
                  color="primary"
                  class="mt-0 pl-4"
                  label="Visible to Cohort"
                  :model-value="studentVisible"
                  :disabled="visibilityProcessing"
                  @update:model-value="updateVisibility()"
                />
                <v-tooltip v-else location="top">
                  <template #activator="{ props }">
                    <div v-bind="props">
                      <v-switch
                        density="compact"
                        hide-details
                        class="mt-0 pl-4"
                        label="Visible to Cohort"
                        :model-value="studentVisible"
                        :disabled="true"
                      />
                    </div>
                  </template>
                  <div>
                    Cannot be hidden while there are published Course Weeks linked to Curriculum Statements within this
                    Curriculum
                  </div>
                </v-tooltip>
              </div>
              <mosaic-tooltip-chip v-else class="pl-2" :color="studentVisible ? 'accent' : 'secondary'"
                ><template #tooltip>
                  {{
                    studentVisible
                      ? 'The Curriculum area is currently visible for this Cohort'
                      : 'The whole Curriculum area is currently hidden from this Cohort'
                  }}</template
                >
                <template #text>{{ studentVisible ? 'Visible' : 'Hidden' }}</template></mosaic-tooltip-chip
              >
            </div>
            <div v-if="showTheorySwitch">
              <v-switch
                density="compact"
                hide-details
                color="primary"
                class="mt-0 pl-4"
                label="Curriculum Theory sections visible"
                :model-value="theoryStudentVisible"
                :disabled="theoryVisibilityProcessing"
                @update:model-value="updateTheoryVisibility()"
              />
            </div>
            <div class="flex-grow-1"></div>
            <v-btn v-if="!inheritingFromInstitution && canEditCurriculum" ripple @click.prevent="addTheme()">
              <div class="d-flex align-center">
                <v-icon>mdi-plus</v-icon>
                <span>Theme</span>
              </div>
            </v-btn>
          </div>
          <div class="pt-2 container">
            <div v-if="myUsingCohorts.length > 0">
              This Curriculum is currently {{ isCohortPage ? ' also ' : '' }} being used by the following Cohorts:
              <span v-for="(c, index) of myUsingCohorts" :key="index">
                <mosaic-router-link
                  :to="{
                    name: 'TutorAdminCohortCurriculumPage',
                    params: { cohortId: c.id },
                  }"
                  >{{ c.name }} {{ c.show_curriculum_for_students ? '(Visible)' : ' (Hidden)' }}</mosaic-router-link
                >
                <span v-if="index !== myUsingCohorts.length - 1">, </span></span
              >
            </div>
            <div v-else-if="!isCohortPage">This Curriculum is currently not in use with any Cohorts</div>
          </div>
          <mosaic-info-alert
            class="mt-2"
            v-if="canEditCurriculum && usingCohorts.length > 0 && !inheritingFromInstitution && isCohortPage"
          >
            Edits to this Curriculum will show across all using Cohorts.
          </mosaic-info-alert>
          <mosaic-snackbar
            v-model="visibilitySnackbar.active"
            :color="visibilitySnackbar.color"
            :message="visibilitySnackbar.message"
          />
        </v-card-text>
      </v-card>
      <template v-if="inheritingFromInstitution && selectedInstitution">
        <v-card>
          <v-card-text>
            <div>This Cohort is using the global Curriculum for {{ selectedInstitution.name }}.</div>
            <div v-if="userStaffHasPermission('curriculum.edit')">
              You can edit this Curriculum
              <mosaic-router-link
                :to="{
                  name: 'TutorAdminCurriculumPage',
                  params: { institutionId: selectedInstitution.id },
                }"
                >here</mosaic-router-link
              >. If you want to use a different Curriculum with this Cohort, please contact support.
            </div>
          </v-card-text>
        </v-card>
      </template>
      <template v-else>
        <v-card class="flex-grow-1">
          <v-card-title>Curriculum Themes</v-card-title>
          <v-card-text>
            <mosaic-list :items="Object.values(themes)">
              <template #item="{ item: theme }">
                <mosaic-list-item
                  :to="themeClickTo(theme.id)"
                  icon="mdi-hexagon"
                  :title="`${theme.code} ${theme.name}`"
                >
                  <template #information>
                    <v-tooltip location="top">
                      <template #activator="{ props }">
                        <v-chip :color="renderVisibilityStatusMessage(theme).color" class="mr-2" v-bind="props">{{
                          renderVisibilityStatusMessage(theme).chip
                        }}</v-chip>
                      </template>
                      <span>{{ renderVisibilityStatusMessage(theme).tooltip }}</span>
                    </v-tooltip>
                  </template>

                  <template #actions v-if="canEditCurriculum">
                    <mosaic-icon-btn icon="pencil" tooltip="Edit theme" @click.prevent="editTheme(theme)" />
                    <mosaic-icon-btn
                      icon="delete"
                      :tooltip="deleteThemeToolTip(theme)"
                      :disabled="!canDeleteTheme(theme)"
                      @click.prevent="deleteTheme(theme)"
                    />
                  </template>
                </mosaic-list-item>
              </template>
            </mosaic-list>
          </v-card-text>
          <mosaic-snackbar v-model="snackbar.active" :color="snackbar.color" :message="snackbar.message" />
        </v-card>
      </template>
    </div>
    <ndt-dialog
      v-model:active="addThemeDialog.active"
      title="Add Theme"
      :error-message="addThemeDialog.errorMessage"
      :on-close="() => onDialogClose(editThemeDialog)"
    >
      <div class="d-flex">
        <div style="width: 100px">
          <v-text-field v-model="addThemeDialog.theme.code" label="Code" hide-details></v-text-field>
        </div>
        <div class="flex-grow-1 pl-4">
          <v-text-field v-model="addThemeDialog.theme.name" label="Name" hide-details></v-text-field>
        </div>
      </div>
      <template #buttons>
        <v-btn variant="text" ripple :disabled="!canAddTheme" @click.prevent="submitAddTheme()">Save</v-btn>
      </template>
    </ndt-dialog>
    <ndt-dialog
      v-model:active="editThemeDialog.active"
      title="Update Theme"
      :error-message="editThemeDialog.errorMessage"
      :on-close="() => onDialogClose(editThemeDialog)"
    >
      <div class="d-flex">
        <div style="width: 100px">
          <v-text-field v-model="editThemeDialog.code" label="Code" hide-details></v-text-field>
        </div>
        <div class="flex-grow-1 px-4">
          <v-text-field v-model="editThemeDialog.name" label="Name" hide-details></v-text-field>
        </div>
      </div>
      <template #buttons>
        <v-btn variant="text" ripple :disabled="!canEditTheme" @click.prevent="submitEditTheme()">Save</v-btn>
      </template>
    </ndt-dialog>
    <ndt-dialog
      v-model:active="deleteThemeDialog.active"
      title="Delete Theme"
      :error-message="deleteThemeDialog.errorMessage"
      :on-close="() => onDialogClose(deleteThemeDialog)"
    >
      <div class="d-flex">Are you sure you want to delete this theme?</div>
      <template #buttons>
        <v-btn
          variant="text"
          ripple
          color="error"
          :disabled="deleteThemeDialog.processing"
          @click.prevent="submitDeleteTheme()"
          >Delete</v-btn
        >
      </template>
    </ndt-dialog>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { mapStateError, mapStateProcessing } from '@/store/map-store';
import NdtDialog from '../../components/NdtDialog.vue';

export default {
  name: 'TutorAdminCurriculumPage',
  data: () => ({
    error: '',
    studentVisible: false,
    theoryStudentVisible: false,
    theoryVisibilityProcessing: false,
    visibilityProcessing: false,
    editThemeDialog: {
      active: false,
      processing: false,
      code: '',
      name: '',
      errorMessage: '',
      themeId: null,
    },
    addThemeDialog: {
      active: false,
      processing: false,
      theme: {
        code: '',
        name: '',
      },
      errorMessage: '',
    },
    deleteThemeDialog: {
      active: false,
      processing: false,
      code: '',
      name: '',
      errorMessage: '',
      themeId: null,
    },
    newTheme: {
      code: '',
      name: '',
    },
    snackbar: {
      active: false,
      color: 'success',
      message: '',
    },
    visibilitySnackbar: {
      active: false,
      color: 'success',
      message: '',
    },
    themes: [],
  }),
  async created() {
    this.refreshThemes();
    this.studentVisible = this.selectedCohort?.show_curriculum_for_students;
  },
  computed: {
    ...mapState(['curriculumThemes', 'selectedInstitution', 'selectedCohort', 'selectedCurriculum', 'userStaff']),
    ...mapGetters(['userStaffCohorts']),
    ...mapStateProcessing(['loadCurriculum']),
    ...mapStateError(['loadCurriculum']),
    breadcrumbs() {
      return [
        {
          text: this.curriculumNoun,
        },
      ];
    },
    usingCohorts() {
      return this.selectedCurriculum.using_cohorts.filter(c => !this.isCohortPage || c.id !== this.selectedCohort.id);
    },
    myUsingCohorts() {
      if (this.userStaffHasPermission('Admin')) return this.usingCohorts;
      const myCohortIds = this.userStaffCohorts.map(c => c.id);
      return this.usingCohorts.filter(c => myCohortIds.includes(c.id));
    },
    canEditCurriculum() {
      // this represents having curriculum.edit at the institution level
      return this.userStaffHasPermission('curriculum.edit');
    },
    canEditCohortCurriculum() {
      return this.hasPermissionForCohort(this.userStaff, 'curriculum.edit', this.selectedCohort);
    },
    canAddTheme() {
      return this.addThemeDialog.theme.code.length && this.addThemeDialog.theme.name.length;
    },
    inheritingFromInstitution() {
      return this.isCohortPage && !this.selectedCohort.has_curriculum;
    },
    canEditTheme() {
      return !this.editThemeDialog.processing && this.editThemeDialog.code && this.editThemeDialog.name;
    },
    isCohortPage() {
      return this.$route.name === 'TutorAdminCohortCurriculumPage';
    },
    hasPublishedCourseWeeks() {
      return this.selectedCurriculum.curriculum_themes.some(x =>
        x.curriculum_statements.some(
          x =>
            x.cohort_course_weeks.filter(
              w => w.cohort_course_term.cohort_course.cohort.id === this.selectedCohort?.id && w.status === 'published'
            ).length > 0
        )
      );
    },
    showTheorySwitch() {
      return (
        (this.isCohortPage && this.selectedCohort?.has_curriculum && this.canEditCurriculum) ||
        (!this.isCohortPage && this.selectedInstitution.config.has_curriculum)
      );
    },
  },
  watch: {
    selectedCurriculum(x) {
      if (x) {
        this.theoryStudentVisible = x.show_theory;
      }
    },
    curriculumThemes(x) {
      if (x) {
        this.themes = x;
      }
    },
  },
  methods: {
    refreshThemes() {
      this.$store.dispatch('loadCurriculum', { force: true });
    },
    renderVisibilityStatusMessage(theme) {
      return theme.student_visible
        ? {
            chip: 'Visible',
            color: 'accent',
            tooltip: 'This theme is visible to ' + this.traineeNounPluralised(),
          }
        : {
            chip: 'Hidden',
            color: '',
            tooltip: 'This theme is not visible to ' + this.traineeNounPluralised(),
          };
    },
    themeClickTo(id) {
      if (this.$route.params.cohortId) {
        return { name: 'TutorAdminCohortCurriculumThemePage', params: { themeId: id } };
      } else {
        return {
          name: 'TutorAdminCurriculumThemePage',
          params: { institutionId: this.selectedInstitution.id, themeId: id },
        };
      }
    },
    canDeleteTheme(theme) {
      return theme.curriculum_statements.length === 0;
    },
    deleteThemeToolTip(theme) {
      return this.canDeleteTheme(theme)
        ? 'Delete Theme'
        : 'Cannot delete Curriculum Theme that has statements. Please delete statements first.';
    },
    addTheme() {
      this.addThemeDialog.active = true;
    },
    editTheme(theme) {
      this.editThemeDialog = {
        active: true,
        processing: false,
        code: theme.code,
        name: theme.name,
        themeId: theme.id,
        errorMessage: '',
      };
    },
    async updateVisibility() {
      this.visibilityProcessing = true;
      try {
        const r = await this.$api.put(`cohorts/${this.selectedCohort.id}/curriculum-visibility`, {
          show_curriculum_for_students: !this.studentVisible,
        });
        this.studentVisible = r.data.show_curriculum_for_students;
        this.$store.commit('updateSelectedCohortWithChanges', {
          show_curriculum_for_students: r.data.show_curriculum_for_students,
        });
      } catch (e) {
        console.log(e);
        this.visibilitySnackbar = {
          active: true,
          color: 'error',
          message:
            (e.response && e.response.data && e.response.data.message) ||
            'Sorry, cannot update visibility at the moment',
        };
      }
      this.visibilityProcessing = false;
    },
    async updateTheoryVisibility() {
      this.theoryVisibilityProcessing = true;
      try {
        const r = await this.$api.put(`curriculum/${this.selectedCurriculum.id}/theory-visibility`, {
          show_theory: !this.theoryStudentVisible,
        });
        this.theoryVisibilityProcessing = false;
        this.theoryStudentVisible = r.data.show_theory;
        this.$store.commit('updateSelectedCurriculum', r.data);
      } catch (e) {
        console.log(e);
        this.visibilitySnackbar = {
          active: true,
          color: 'error',
          message:
            (e.response && e.response.data && e.response.data.message) ||
            'Sorry, cannot update theory visibility at the moment',
        };
        this.theoryVisibilityProcessing = false;
      }
    },
    deleteTheme(theme) {
      this.deleteThemeDialog = {
        active: true,
        processing: false,
        code: theme.code,
        name: theme.name,
        themeId: theme.id,
        errorMessage: '',
      };
    },
    async submitAddTheme() {
      this.errorMessage = '';
      const themesArray = Object.values(this.themes);
      const order = themesArray.length
        ? Math.max.apply(
            null,
            themesArray.map(t => t.order)
          ) + 1
        : 1;
      try {
        await this.$api.post('/curriculum-themes', {
          ...this.addThemeDialog.theme,
          curriculum_id: themesArray[0].curriculum.id,
          order: order,
        });
        await this.refreshThemes();
        this.addThemeDialog.theme.name = '';
        this.addThemeDialog.theme.code = '';
        this.addThemeDialog.active = false;
      } catch (e) {
        this.addThemeDialog.errorMessage = 'Sorry, cannot add a theme right now';
        throw e;
      }
    },
    async submitEditTheme() {
      try {
        this.editThemeDialog.processing = true;
        await this.$api.put(`curriculum-themes/${this.editThemeDialog.themeId}`, this.editThemeDialog);
        await this.refreshThemes();
        this.editThemeDialog.processing = false;
        this.editThemeDialog.active = false;
        this.snackbar = {
          active: true,
          color: 'success',
          message: 'Success!',
        };
      } catch (e) {
        console.log(e);
        (this.editThemeDialog.errorMessage =
          (e.response && e.response.data && e.response.data.message) || 'Sorry, cannot update theme at the moment'),
          (this.editThemeDialog.processing = false);
        this.editThemeDialog.active = false;
      }
    },
    async submitDeleteTheme() {
      try {
        this.deleteThemeDialog.processing = true;
        await this.$api.delete(`curriculum-themes/${this.deleteThemeDialog.themeId}`);
        this.deleteThemeDialog.processing = false;
        this.deleteThemeDialog.active = false;
        this.snackbar = {
          active: true,
          color: 'success',
          message: 'Success!',
        };
        this.refreshThemes();
      } catch (e) {
        console.log(e);
        (this.deleteThemeDialog.errorMessage =
          (e.response && e.response.data && e.response.data.message) || 'Sorry, cannot delete theme at the moment'),
          (this.deleteThemeDialog.processing = false);
        this.deleteThemeDialog.active = false;
      }
    },
    onDialogClose(dialog) {
      dialog.active = false;
      (dialog.processing = false),
        (dialog.code = ''),
        (dialog.name = ''),
        (dialog.themeId = ''),
        (dialog.errorMessage = '');
    },
  },
  components: { NdtDialog },
};
</script>
