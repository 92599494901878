<template>
  <v-dialog v-model="activeVDialog" :max-width="width" @click:outside="$emit('clickOutside')">
    <v-sheet>
      <div class="d-flex pa-4 align-center" style="background-color: rgb(176, 190, 197)">
        <div class="text-h6 flex-grow-1">
          {{ title }}
        </div>
        <slot name="header" />
      </div>
      <div class="bg-white">
        <div v-if="!!errorMessage" class="px-4">
          <mosaic-error-alert :override-error-message="errorMessage" />
        </div>
        <div class="bg-white pa-2">
          <div class="pa-2 d-flex flex-column">
            <slot />
          </div>
          <v-divider />
          <div class="d-flex justify-end pt-2">
            <v-btn variant="text" ripple @click.prevent="close()">
              {{ closeButtonText }}
            </v-btn>
            <slot name="buttons" />
          </div>
        </div>
      </div>
    </v-sheet>
  </v-dialog>
</template>

<script>
import $ from 'jquery';

// Deprecated: prefer MosaicDialog
export default {
  props: {
    active: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    onClose: {
      type: Function,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      default: () => {},
    },
    width: {
      type: [String, Number],
      default: 650,
    },
    closeButtonText: {
      type: String,
      default: 'Cancel',
    },
    errorMessage: {
      type: String,
      default: null,
    },
  },
  emits: ['clickOutside', 'update:active'],
  data: function () {
    return {
      activeVDialog: this.active,
    };
  },
  watch: {
    errorMessage: function (newError) {
      if (newError) {
        $('.v-dialog--active').scrollTop(0);
      }
    },
    active: function (val) {
      this.activeVDialog = val ? true : false;
    },
    activeVDialog: function (val) {
      this.$emit('update:active', val);
    },
  },
  methods: {
    close: function () {
      this.activeVDialog = false;
      this.onClose();
    },
  },
};
</script>
