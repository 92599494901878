<template>
  <ndt-dialog v-model:active="activeNdtDialog" :title="title" :error-message="errorMessage">
    <v-text-field v-model="name" :prepend-icon="icon" label="Name" type="text" />
    <template #buttons>
      <v-btn variant="text" ripple :disabled="!canSave" @click.prevent="submitEditItem()">Save</v-btn>
    </template>
  </ndt-dialog>
</template>

<script>
import NdtDialog from '../NdtDialog.vue';
import { invalidCharacters } from '../../utils/files';

export default {
  name: 'EditItemDialog',
  components: { NdtDialog },
  props: {
    active: { type: Boolean, required: true },
    item: { type: Object, required: true },
  },
  emits: ['update:active', 'close'],
  data: function () {
    return {
      activeNdtDialog: this.active,
      processing: false,
      errorMessage: '',
      extension: '',
      name: '',
      title: '',
      icon: '',
    };
  },
  computed: {
    canSave() {
      let nameChanged = false;
      if (this.item.type === 'file') {
        const dotPosition = this.item.name.lastIndexOf('.');
        const name = dotPosition != -1 ? this.item.name.slice(0, dotPosition) : this.item.name;
        nameChanged = name !== this.name;
      } else {
        nameChanged = this.item.name !== this.name;
      }
      return !this.processing && nameChanged;
    },
  },
  watch: {
    activeNdtDialog(x) {
      this.$emit('update:active', x);
    },
    active(x) {
      this.activeNdtDialog = x;
    },
    item(item) {
      if (item.type === 'file') {
        const dotPosition = item.name.lastIndexOf('.');
        this.name = dotPosition != -1 ? item.name.slice(0, dotPosition) : item.name;
        this.extension = dotPosition != -1 ? item.name.slice(dotPosition, item.name.length) : '';
      } else {
        this.name = item.name;
        this.extension = '';
      }
      this.title = item.type === 'file' ? 'Rename File' : 'Rename Folder';
      this.icon = item.type === 'file' ? 'mdi-file-document' : 'mdi-folder';
      this.errorMessage = '';
      this.processing = false;
    },
  },
  methods: {
    async submitEditItem() {
      const invalidChars = invalidCharacters.filter(x => this.name.includes(x));
      if (invalidChars.length > 0) {
        this.errorMessage = `Name contains an invalid character: ${invalidChars.join(' ')}`;
        return;
      }

      this.processing = true;
      this.errorMessage = '';
      const itemName = this.name + this.extension;
      try {
        await this.$api.put(`${this.item.filesApiPath}/${encodeURIComponent(this.item.id)}`, {
          name: itemName,
        });
        this.activeNdtDialog = false;
        this.$emit('close');
      } catch (e) {
        console.log(e);
        this.errorMessage = `Sorry, cannot edit your ${this.item.type} at the moment`;
      }
      this.processing = false;
    },
  },
};
</script>
