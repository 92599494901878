<template>
  <div>
    <mosaic-loading-card v-if="loading || stateProcessing" />
    <mosaic-load-error-card v-else-if="loadError || stateError" object-type="Cohort" @retry="load" />

    <mosaic-card v-else>
      <mosaic-card-title>{{ copying ? 'Copy' : 'New' }} Cohort </mosaic-card-title>
      <mosaic-error-alert :error="cohortConfigLoadError" action="load this Cohort's details" />
      <mosaic-text-field
        v-model="name"
        autofocus
        name="cohort-name"
        label="Name"
        prepend-icon="mdi-google-classroom"
        half-width
      />
      <mosaic-radio-buttons v-model="copying" :radios="createOptions" row></mosaic-radio-buttons>
      <mosaic-select
        v-if="copying"
        v-model="selectedCohort"
        name="select-cohort"
        label="Create copy from"
        :items="sortedCohorts"
        item-value="id"
        item-title="name"
        no-data-text="No Cohorts found"
        prepend-icon="mdi-google-classroom"
        half-width
        dense
        return-object
      >
        <template #item="{ props, item }">
          <v-list-item v-bind="props" title="">
            <div class="d-flex align-center">
              <div>{{ item.raw.name }}</div>
              <div class="flex-grow-1" />
              <div v-if="item.raw.status === 'closed'" class="ml-2">
                <v-chip color="secondary">Closed</v-chip>
              </div>
            </div>
          </v-list-item>
        </template>
        <template #selection="{ item }">
          <div>{{ `${item.raw.name} ${item.raw.status === 'closed' ? ' (Closed)' : ''}` }}</div>
        </template>
      </mosaic-select>
      <template v-if="!(copying && !selectedCohort)">
        <div v-if="!selectedInstitution.config.mentor_training_only">
          <mosaic-card-heading class="pt-6">Monitoring Windows</mosaic-card-heading>
          <div class="pt-2">
            Monitoring Windows determine the start and end dates for week-level monitoring within Mosaic, e.g. weekly
            Target counts
          </div>
          <div class="pt-2">Choose an academic year for suggested Monitoring Windows:</div>
          <mosaic-radio-buttons
            v-model="selectedWindowOption"
            :radios="windowOptionRadios"
            :disabled="loadingCohortConfig"
            inline
          ></mosaic-radio-buttons>

          <mosaic-data-creation-list
            v-model="monitoringWindows"
            :new-object="newWindow"
            object-type="Monitoring Window"
            :can-add-new-object="canAddMonitoringWindow"
            show-clear-all
            @update:add-object="addMonitoringWindow()"
          >
            <template #item="{ item: window }"
              ><mosaic-text-field
                v-model="window.name"
                name="window-name"
                label="Name"
                class="pr-2 flex-grow-1"
                prepend-icon="mdi-monitor" />
              <mosaic-date-picker v-model:date="window.startDate" label="Start date"></mosaic-date-picker>
              <mosaic-date-picker v-model:date="window.endDate" label="End date" />
              <mosaic-error-icon
                v-if="window.startDate && window.endDate && !validDates(window)"
                tooltip="End date must be
          after start date" />
              <mosaic-warning-icon
                v-else-if="overlapping(window)"
                tooltip="This Monitoring Window overlaps with another"
              ></mosaic-warning-icon
            ></template>
            <template #new-item>
              <mosaic-text-field
                v-model="newWindow.name"
                name="new-window-name"
                label="Name"
                class="flex-grow-1 pr-2"
                prepend-icon="mdi-monitor-star" />

              <mosaic-date-picker v-model:date="newWindow.startDate" label="Start date"></mosaic-date-picker>
              <mosaic-date-picker v-model:date="newWindow.endDate" label="End date" />
              <mosaic-error-icon
                v-if="newWindow.startDate && newWindow.endDate && !validDates(newWindow)"
                tooltip="End date must be
          after start date" />
              <mosaic-warning-icon
                v-else-if="overlapping(newWindow)"
                tooltip="This Monitoring Window overlaps with
            another"
            /></template>
          </mosaic-data-creation-list>
          <mosaic-info-alert v-if="copying && selectedCohortConfig && selectedCohortConfig.showCurriculumForStudents"
            >Please note: {{ traineeNounCapitalisedAndPluralised }} in the new Cohort will not be able to view the
            {{ curriculumNoun }} by default. You can update this setting in the {{ curriculumNoun }} area of the
            Cohort</mosaic-info-alert
          >
        </div>
        <template v-if="showCopyOptions">
          <mosaic-card-heading class="pt-4"> Copy Options </mosaic-card-heading>
          <div v-if="selectedCohortConfig.hasCurriculum">
            <div class="d-flex align-center">
              <mosaic-radio-buttons
                class="my-1 mr-1"
                v-model="curriculumOption"
                :radios="curriculumOptions"
                :disabled="loadingCohortConfig"
                prepend-icon="mdi-hexagon-multiple"
                label="Curriculum Options"
                inline
                hide-details="auto"
              ></mosaic-radio-buttons>
              <div class="mt-5">
                <mosaic-help text-align="start">
                  <div><strong>No Curriculum</strong></div>
                  <div>
                    The new Cohort will not have its own Curriculum. It will inherit the Curriculum from the
                    Institution. Any Curriculum Links to Course Targets or Training Plan Weeks will be removed.
                  </div>
                  <div class="pt-2"><strong>Use Shared Cohort Curriculum</strong></div>
                  <div>
                    The new Cohort will use the same Curriculum as the source Cohort. Any edits to this Curriculum will
                    affect both Cohorts. Curriculum Links will be retained.
                  </div>
                  <div class="pt-2"><strong>Copy Curriculum</strong></div>
                  <div>
                    The new Cohort will get a copy of the source Cohort's Curriculum. Any edits to the Curriculum will
                    be independent of the other Cohort. Curriculum links will be copied over.
                  </div>
                </mosaic-help>
              </div>
            </div>
          </div>
          <div v-if="showOtherOptions && selectedCohortConfig.hasCurriculum" class="text-caption">Other</div>
          <div
            v-if="selectedInstitution.config.show_mentor_meetings && !selectedInstitution.config.mentor_training_only"
          >
            <div class="d-flex">
              <mosaic-checkbox
                v-model="copyMentorMeeting"
                class="mr-2"
                name="copy-mentor-meeting"
                prepend-icon="mdi-recycle-variant"
                label="Copy Mentor Meeting Template"
                help-text="If not selected, the default template will be used"
              />
            </div>
          </div>
          <div>
            <div
              class="d-flex align-center"
              v-if="selectedCohortConfig.hasTargets && !selectedInstitution.config.mentor_training_only"
            >
              <mosaic-checkbox
                v-model="copyTargets"
                class="mr-2"
                name="copy-targets"
                prepend-icon="mdi-near-me"
                :label="`Copy Course Targets with no ${traineeNounCapitalisedAndPluralised}`"
                :help-text="`The source Cohort uses its own Course Targets. If selected, the Course Targets will be copied in a draft state with no ${traineeNounCapitalisedAndPluralised}.`"
              />
              <mosaic-warning-icon
                v-if="
                  copyTargets &&
                  selectedCohortConfig.hasTargetsWithCurriculumLinks &&
                  selectedCohortConfig.hasCurriculum &&
                  curriculumOption === 'none'
                "
                tooltip="You have chosen not to copy or share the Curriculum. Any Curriculum Links within Course Targets will be removed as
                a result."
              />
            </div>
          </div>

          <div v-if="selectedCohortConfig.hasStandardSet && !selectedInstitution.config.mentor_training_only">
            <div class="d-flex">
              <mosaic-checkbox
                v-model="copyStandardSet"
                class="mr-2"
                name="copy-standard-set"
                prepend-icon="mdi-fingerprint"
                label="Copy Assessment Framework"
                help-text="The source Cohort uses it's own Assessment Framework for Evidence/Portfolio functionality. If not selected, the Cohort will use the Institution Assessment Framework."
              />
            </div>
          </div>
          <div v-if="selectedCohortConfig.hasGroups && !selectedInstitution.config.mentor_training_only">
            <div class="d-flex">
              <mosaic-checkbox
                v-model="copyGroups"
                class="mr-2"
                name="copy-groups"
                prepend-icon="mdi-account-group"
                label="Copy Groups"
                help-text="If selected, empty Groups will be created with the same name as Groups in the source Cohort."
              />
            </div>
          </div>
          <div v-if="selectedCohortConfig.hasStaffRoles">
            <div class="d-flex">
              <mosaic-checkbox
                v-model="copyRoleAssignments"
                class="mr-2"
                name="copy-role-assignments"
                prepend-icon="mdi-human-male-board"
                label="Copy Instructor Roles"
                help-text="If selected, any Instructors with admin permissions for the source Cohort will be granted these for the
                new Cohort."
              />
            </div>
          </div>
          <div v-if="selectedCohortConfig.hasFolderStructure && !selectedInstitution.config.mentor_training_only">
            <mosaic-checkbox
              v-model="copyFolderStructure"
              class="mr-2"
              name="copy-folder-structure"
              prepend-icon="mdi-file-tree"
              label="Copy Folder Structure"
            />
          </div>
          <div v-if="selectedCohortConfig.hasStaffTraining">
            <div class="d-flex">
              <mosaic-checkbox
                v-model="copyStaffTraining"
                class="mr-2"
                name="copy-staff-training"
                prepend-icon="mdi-sitemap-outline"
                label="Copy Instructor Training Framework publications"
                help-text="If selected, any Instructor Training Frameworks published to the source Cohort will be published to the new
                Cohort."
              />
            </div>
          </div>
          <div
            v-if="
              selectedCohortConfig.cohortCourseTerms.length > 0 || selectedCohortConfig.hasCourseWeekTemplateContent
            "
          >
            <div class="d-flex align-center">
              <mosaic-checkbox
                v-if="!selectedInstitution.config.mentor_training_only"
                v-model="copyCourse"
                name="copy-course"
                prepend-icon="mdi-hexagon-slice-4"
                label="Copy Training Plan Structure"
                help-text="If selected, Training Plan Terms, Weeks (including content) and the Week Template will be copied to the new Cohort."
              />
              <mosaic-warning-icon
                v-if="
                  selectedCohortConfig.hasCurriculum &&
                  curriculumOption === 'none' &&
                  selectedCohortConfig.hasCourseWithCurriculumStatements
                "
                tooltip="You have chosen not to copy or share the Curriculum. Any Curriculum Links within Training Plan Weeks will be removed as
                a result."
              />
            </div>
            <div v-if="copyCourse" class="pl-8">
              Configure Term start dates
              <div v-for="term in selectedCohortConfig.cohortCourseTerms" :key="term.id">
                <mosaic-date-picker
                  v-model:date="term.startDate"
                  :label="`New ${term.name} start date`"
                  :exact-width="false"
                ></mosaic-date-picker>
              </div>
              <mosaic-info-alert class="mx-4"
                ><div>
                  Mosaic will create new Terms using the start dates chosen and the length of the existing Term. Weeks
                  will then be copied across.
                </div>
                <div class="pt-2">
                  Once created, please review the Week start and end dates as these may no longer perfectly line up.
                </div>
                <div class="pt-2">
                  If you require a completely new structure, but want to keep Week content, please deselect "Copy
                  Training Plan Structure", and instead copy over individual Weeks once the new Cohort has been created.
                </div>
              </mosaic-info-alert>
            </div>
          </div>
        </template>
      </template>
      <mosaic-error-alert :error="saveError" action="create this Cohort" />
      <div class="d-flex flex-wrap justify-end align-center mt-2" style="row-gap: 4px">
        <mosaic-btn class="mr-2" @click.prevent="cancel()">Cancel</mosaic-btn>
        <mosaic-btn :disabled="!canSave" color="primary" :loading="processing" @click.prevent="save()"
          >Create Cohort</mosaic-btn
        >
      </div>
    </mosaic-card>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { mapStateProcessingAndError } from '@/store/map-store';
import { syncQueryParamsMixin } from '../../mixins/query-mixins';
import { useQueryStore } from '@/stores/query';
import moment from 'moment';

export default {
  name: 'TutorAdminCohortCreatePage',
  setup() {
    const queryStore = useQueryStore();
    return { queryStore };
  },
  mixins: [
    syncQueryParamsMixin({
      copying: { query: 'copying', type: 'boolean' },
      selectedCohortId: { query: 'copyCohortId', type: 'integer' },
    }),
  ],
  data: () => ({
    processing: false,
    loading: true,
    loadAcademicYearsError: false,
    saveError: false,
    academicYears: [],
    name: '',
    selectedWindowOption: 'custom',
    monitoringWindows: [],
    newWindow: {
      name: '',
      startDate: '',
      endDate: '',
    },
    selectedCohortConfig: null,
    copying: false,
    createOptions: [
      { label: 'Start from scratch', value: false },
      { label: 'Copy from an existing Cohort', value: true },
    ],
    selectedCohort: null,
    cohortConfigLoadError: false,
    loadingCohortConfig: false,
    copyStandardSet: false,
    copyTargets: false,
    copyMentorMeeting: false,
    copyRoleAssignments: false,
    copyCourse: false,
    copyGroups: false,
    copyFolderStructure: false,
    copyStaffTraining: false,
    curriculumOption: '',
    curriculumOptions: [
      { label: 'No Curriculum', value: 'none' },
      { label: 'Use Shared Cohort Curriculum', value: 'share' },
      { label: 'Copy Curriculum', value: 'copy' },
    ],
  }),
  computed: {
    ...mapState(['selectedInstitution', 'cohorts', 'userStaff']),
    ...mapStateProcessingAndError(['loadCohorts']),
    breadcrumbs() {
      return [
        {
          text: 'Instructors',
          to: {
            name: 'InstitutionStaffListPage',
          },
        },
        {
          text: 'Cohorts',
          to: {
            name: 'TutorCohortListPage',
          },
        },
        {
          text: `${this.copying ? 'Copy' : 'New'} Cohort`,
        },
      ];
    },

    showCopyOptions() {
      if (!this.selectedCohortConfig || !this.copying) return false;
      if (
        this.selectedInstitution.config.mentor_training_only &&
        !this.selectedCohortConfig.hasStaffTraining &&
        !this.selectedCohortConfig.hasStaffRoles
      )
        return false;
      return this.selectedCohortConfig.hasCurriculum || this.showOtherOptions;
    },
    showOtherOptions() {
      if (!this.selectedCohortConfig || !this.copying) return false;
      return (
        this.selectedInstitution.config.show_mentor_meetings ||
        this.selectedCohortConfig.hasCurriculum ||
        this.selectedCohortConfig.hasGroups ||
        this.selectedCohortConfig.hasCourseWeekTemplateContent ||
        this.selectedCohortConfig.cohortCourseTerms.length ||
        this.selectedCohortConfig.hasCourseWeekTemplateContent ||
        this.selectedCohortConfig.hasFolderStructure ||
        this.selectedCohortConfig.hasStaffTraining ||
        this.selectedCohortConfig.hasStaffRoles ||
        this.selectedCohortConfig.hasStandardSet
      );
    },
    loadError() {
      return this.loadAcademicYearsError;
    },
    canAddMonitoringWindow() {
      return !!(
        this.newWindow.name &&
        this.newWindow.startDate &&
        this.newWindow.endDate &&
        this.validDates(this.newWindow)
      );
    },
    selectedCohortId: {
      get() {
        return this.selectedCohort?.id;
      },
      async set(value) {
        if (!this.cohorts.length) await this.loadStoreData();
        this.selectedCohort = this.cohorts.find(c => c.id == value) || null;
      },
    },
    sortedCohorts() {
      return this.cohorts.sortBy(['status', 'name']);
    },
    windowOptionRadios() {
      const customRadios = [{ label: 'Custom', value: 'custom' }];
      if (this.copying) {
        customRadios.push({ label: 'Copy from selected Cohort', value: 'copy' });
      }
      return customRadios.concat(
        this.academicYears.map(w => {
          return {
            label: w.name,
            value: w.name,
          };
        })
      );
    },

    selectedWindowOptionRadio() {
      return this.windowOptionRadios.find(y => y.value == this.selectedWindowOption);
    },
    dirty() {
      return this.monitoringWindows.length > 0 || this.name;
    },
    canSave() {
      return (
        this.dirty &&
        this.name &&
        !this.processing &&
        !this.loadingCohortConfig &&
        this.monitoringWindows.length > 0 &&
        (!this.copying || (this.selectedCohort && this.selectedCohortConfig)) &&
        (!this.copyCourse || this.selectedCohortConfig.cohortCourseTerms.every(t => t.startDate)) &&
        (!this.copying || !this.selectedCohortConfig.hasCurriculum || !!this.curriculumOption) &&
        this.monitoringWindows.every(w => this.validDates(w))
      );
    },
  },

  watch: {
    selectedWindowOption(x) {
      if (this.copying && x === 'copy') {
        this.monitoringWindows = this.selectedCohort.placements.map(p => {
          return {
            name: p.name,
            startDate: p.start_date,
            endDate: p.end_date,
          };
        });
      } else if (x != 'copy' && x != 'custom') {
        this.monitoringWindows = this.academicYears.find(y => y.name === this.selectedWindowOptionRadio.label).terms;
      }
    },
    async selectedCohort(x) {
      if (this.selectedWindowOption === 'copy') this.selectedWindowOption = 'custom';
      if (x) {
        await this.loadCohortConfig(x);
      }
    },
    copying(x) {
      if (this.selectedWindowOption == 'copy') {
        this.selectedWindowOption = 'custom';
      }
      if (this.selectedCohortId && !x) {
        this.selectedCohort = null;
      }
    },
  },
  async created() {
    await this.load();
  },
  methods: {
    validDates(window) {
      return window.startDate && window.endDate && moment(window.startDate).isBefore(window.endDate);
    },
    overlapping(window) {
      return this.monitoringWindows.some(
        w =>
          moment(w.startDate).isBetween(window.startDate, window.endDate) ||
          moment(w.endDate).isBetween(window.startDate, window.endDate) ||
          moment(window.startDate).isBetween(w.startDate, w.endDate) ||
          moment(window.endDate).isBetween(w.startDate, w.endDate)
      );
    },
    async loadAcademicYears() {
      this.loading = true;
      try {
        const r = await this.$api.get(`institutions/${this.selectedInstitution.id}/academic-years`);
        this.academicYears = r.data;
        if (this.selectedInstitution.config.mentor_training_only && this.academicYears.length > 0)
          this.selectedWindowOption = this.academicYears.find(y => y.label === 'Current').name;
      } catch (e) {
        console.log(e);
        this.loadAcademicYearsError = true;
      }
      this.loading = false;
    },
    async loadStoreData() {
      if (this.stateProcessing) return;
      await this.$store.dispatch('loadCohorts');
    },
    async load() {
      await this.loadAcademicYears();
      await this.loadStoreData();
    },
    async loadCohortConfig(x) {
      this.cohortConfigLoadError = false;
      this.loadingCohortConfig = true;
      try {
        const r = await this.$api.get(`cohorts/${x.id}/config`);
        this.selectedCohortConfig = r.data;
      } catch (e) {
        console.log(e);
        this.cohortConfigLoadError = true;
      }
      this.loadingCohortConfig = false;
    },
    addMonitoringWindow() {
      this.monitoringWindows.push({
        name: this.newWindow.name,
        startDate: this.newWindow.startDate,
        endDate: this.newWindow.endDate,
      });
      this.newWindow = {
        name: '',
        startDate: '',
        endDate: '',
      };
      this.selectedWindowOption = 'custom';
    },
    async save() {
      this.processing = true;
      this.saveError = false;
      const copyBody = !this.copying
        ? {}
        : {
            sourceCohortId: this.selectedCohort.id,
            curriculumOption: this.selectedCohortConfig.hasCurriculum ? this.curriculumOption : 'none',
            copyStandardSet: this.selectedCohortConfig.hasStandardSet && this.copyStandardSet,
            copyGroups: this.selectedCohortConfig.hasGroups && this.copyGroups,
            copyMentorMeeting: this.copyMentorMeeting,
            copyTargets: this.copyTargets,
            copyRoleAssignments: this.selectedCohortConfig.hasStaffRoles && this.copyRoleAssignments,
            copyCourse: this.selectedCohortConfig.cohortCourseTerms.length && this.copyCourse,
            copyFolderStructure: this.selectedCohortConfig.hasFolderStructure && this.copyFolderStructure,
            copyStaffTraining: this.selectedCohortConfig.hasStaffTraining && this.copyStaffTraining,
            termStartDates: this.selectedCohortConfig.cohortCourseTerms.map(x => {
              return {
                termId: x.id,
                startDate: x.startDate,
              };
            }),
          };

      try {
        await this.$api.post(`institutions/${this.selectedInstitution.id}/cohorts`, {
          ...copyBody,
          placements: this.monitoringWindows,
          name: this.name,
        });
        if (
          this.copying &&
          this.copyRoleAssignments &&
          this.selectedCohortConfig.cohortStaffIds.includes(this.userStaff.id)
        ) {
          this.$store.dispatch('refreshUser');
        }
        this.$router.go(-1);
      } catch (e) {
        console.log(e);
        this.saveError = true;
      }
      this.processing = false;
    },
    cancel() {
      this.$router.go(-1);
    },
  },
};
</script>
<style scoped>
.left-column {
  flex-basis: 50%;
  padding-right: 16px;
}
.right-column {
  flex-basis: 50%;
  padding-left: 8px;
  padding-right: 8px;
}
.full-width {
  flex-basis: 100%;
  padding-right: 8px;
}
</style>
