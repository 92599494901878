<template>
  <cohort-course-weeks-list
    cohort-course-url="/cohort-course"
    :student-viewer="true"
    student-cohort-course-weeks-url="/student-cohort-course-weeks"
    :week-click-to="weekClickTo"
  />
</template>

<script>
import CohortCourseWeeksList from './CohortCourseWeeksList.vue';

export default {
  name: 'StudentCohortCoursePage',
  components: { CohortCourseWeeksList },
  computed: {
    breadcrumbs() {
      return [
        {
          text: 'Training Plan',
        },
      ];
    },
  },
  methods: {
    weekClickTo(id) {
      return { name: 'StudentCohortCourseWeekPage', params: { studentWeekId: id } };
    },
  },
};
</script>
