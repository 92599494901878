<template>
  <mosaic-tooltip-chip :color="chip.color">
    <template #text>
      {{ chip.text }}
    </template>

    <template #tooltip>
      {{ chip.tooltip }}
    </template>
  </mosaic-tooltip-chip>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { mapGetters } from '@/store/map-store';
import type { TargetStatus } from './targets';

const props = defineProps<{
  status?: TargetStatus;
}>();

const { traineeNounCapitalised } = mapGetters();

const chip = computed(() => createTargetStatusChip(traineeNounCapitalised.value(), props.status));

function createTargetStatusChip(traineeNounCapitalised: string, status?: TargetStatus) {
  if (status === 'active') {
    return {
      text: 'Active',
      tooltip: `The Target is active`,
      color: 'accent',
    };
  } else if (status === 'completed') {
    return {
      text: 'Completed',
      tooltip: `This ${traineeNounCapitalised} has marked the Target as complete`,
      color: 'primary',
    };
  } else if (status === 'approved') {
    return {
      text: 'Approved',
      tooltip: `Completion of the Target has been approved for this ${traineeNounCapitalised}`,
      color: 'secondary',
    };
  } else {
    return {
      text: 'Not Assigned',
      tooltip: `The Target is not assigned to this ${traineeNounCapitalised}`,
      color: 'secondary',
    };
  }
}
</script>
