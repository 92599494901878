<template>
  <mosaic-loading-card v-if="busy" type="list" />
  <mosaic-load-error-card v-else-if="loadError" object-type="Targets" :viewing-student="true" @retry="loadTargets" />
  <div v-else>
    <targets-list
      :targets="targets"
      :target-click-to="targetClickTo"
      :delete-target="deleteTarget"
      :can-create-targets="
        userStaffHasPermissionForSelectedStudent('student.targets.create') ||
        userStaffHasPermissionForSelectedStudent('student.targets.editAll')
      "
      @click-create-target="createTarget"
    >
      <div>{{ traineeNounCapitalised() }} has set no targets.</div>
    </targets-list>

    <ndt-dialog
      v-model:active="deleteTargetDialog.active"
      title="Delete Target"
      :error-message="deleteTargetDialog.errorMessage"
    >
      <div>Are you sure you want to delete target:</div>
      <br />
      <div>
        <span class="font-weight-bold">{{ `"` + deleteTargetDialog.target.goal + `"` }}</span>
        <span>?</span>
      </div>
      <template v-if="deleteTargetDialog.evidence">
        <br />
        <div>
          <span>
            This target is linked to:
            {{
              deleteTargetDialog.evidence.substandards
                .map(s => {
                  return s.code;
                })
                .join(', ')
            }}
            as evidence
          </span>
        </div>
      </template>
      <template #buttons>
        <v-btn
          variant="text"
          ripple
          color="error"
          :disabled="deleteTargetDialog.processing"
          @click.prevent="submitDeleteTarget()"
          >Delete</v-btn
        >
      </template>
    </ndt-dialog>
  </div>
</template>

<script>
import TargetsList from './TargetsList.vue';
import NdtDialog from '@/components/NdtDialog.vue';
import { mapState } from 'vuex';
import { useActiveTargetsStore } from '@/stores/active-targets';

export default {
  name: 'TutorTargetsListPage',
  data() {
    return {
      targets: [],
      studentId: null,
      busy: true,
      loadError: false,
      deleteTargetDialog: {
        active: false,
        target: {},
        evidence: null,
        disabled: true,
        errorMessage: '',
      },
    };
  },
  setup() {
    const {
      actions: { removeTarget },
    } = useActiveTargetsStore();
    return {
      removeTarget,
    };
  },
  async created() {
    await this.loadTargets();
  },
  computed: {
    ...mapState(['selectedStudent', 'user']),
    breadcrumbs() {
      return [
        {
          text: 'Targets',
        },
      ];
    },
  },
  methods: {
    async loadTargets() {
      this.busy = true;
      this.loadError = false;
      try {
        const r = await this.$api.get(`/students/${this.selectedStudent.id}/targets`);
        this.targets = r.data;
      } catch (e) {
        console.log(e);
        this.loadError = true;
      }
      this.busy = false;
    },
    targetClickTo(targetId) {
      return {
        name: 'TargetPage',
        params: { studentId: this.selectedStudent.id, targetId: targetId },
      };
    },
    createTarget() {
      this.$router.push({ name: 'TargetCreatePage' });
    },
    async deleteTarget(target) {
      this.deleteTargetDialog.target = target;
      if (target.evidence_id) {
        this.deleteTargetDialog.disabled = true;
        try {
          const response = await this.$api.get(`/evidence/${target.evidence_id}`);
          this.deleteTargetDialog.evidence = response.data;
          this.deleteTargetDialog.disabled = false;
        } catch (e) {
          console.log(e);
        }
      }
      this.deleteTargetDialog.active = true;
    },
    async submitDeleteTarget() {
      try {
        await this.$api.delete(`/targets/${this.deleteTargetDialog.target.id}`);
        //remove active target
        this.removeTarget(this.deleteTargetDialog.target.id);
        this.deleteTargetDialog.active = false;
        const i = this.targets.findIndex(t => t.id === this.deleteTargetDialog.target.id);
        // updates the array such that Vue knows it has changed
        this.targets.splice(i, 1);
        if (this.deleteTargetDialog.target.target_type === 'review') {
          // This ensures that if the target is stored on the cached student review then it's cleared
          // We could do a more nuanced check than this, but I think this is good enough
          this.$store.commit('clearStudentReview');
        }
        this.deleteTargetDialog.processing = false;
      } catch (e) {
        this.deleteTargetDialog.errorMessage =
          (e.response && e.response.data.message) || `Sorry, cannot delete your target at the moment`;
        this.deleteTargetDialog.processing = false;
      }
    },
  },
  components: { TargetsList, NdtDialog },
};
</script>
