<template>
  <div>
    <v-card>
      <v-card-title>
        <div class="pl-6">
          {{ themeName }}
        </div>
      </v-card-title>
      <v-card-text>
        <div v-if="!statements.length" class="pl-6">No statements for this curriculum Theme</div>
        <mosaic-list :items="statements">
          <template #item="{ item: statement, index }">
            <mosaic-list-item icon="mdi-hexagon" :title="statement.code + ': ' + statement.statement">
              <template #actions>
                <ndt-icon-button
                  icon="chevron-up"
                  tooltip="Change order: move up"
                  @click.prevent="moveUp(statement)"
                  v-if="index != 0"
                />
                <ndt-icon-button
                  icon="chevron-down"
                  tooltip="Change order: move down"
                  @click.prevent="moveDown(statement)"
                  v-if="index != statements.length - 1"
                />
                <ndt-icon-button
                  icon="pencil"
                  tooltip="Edit Curriculum Statement"
                  @click.prevent="editStatement(statement)"
                />
                <ndt-icon-button
                  icon="delete"
                  tooltip="Delete Curriculum Statement"
                  @click.prevent="deleteStatement(statement)"
                />
              </template>
            </mosaic-list-item>
          </template>
        </mosaic-list>
        <v-list-item>
          <span class="text-h6">New Curriculum Statement</span>
          <div class="d-flex">
            <div style="width: 100px" class="pr-2">
              <mosaic-text-field v-model="newStatement.code" label="Code" prepend-icon="mdi-hexagon" />
            </div>
            <div class="flex-grow-1 pr-2">
              <mosaic-text-field v-model="newStatement.statement" label="Statement" />
            </div>
            <v-list-item-action>
              <v-btn :disabled="!canAddStatement" @click.prevent="addStatement">Add</v-btn>
            </v-list-item-action>
          </div>
        </v-list-item>
      </v-card-text>
    </v-card>

    <ndt-dialog
      v-model:active="editStatementDialog.active"
      title="Update Statement Name"
      :error-message="editStatementDialog.errorMessage"
      :on-close="onDialogClose"
    >
      <div class="d-flex">
        <div style="width: 100px">
          <v-text-field v-model="editStatementDialog.code" label="Code" hide-details=""></v-text-field>
        </div>
        <div class="flex-grow-1 px-4">
          <v-text-field v-model="editStatementDialog.statement" label="Name" hide-details></v-text-field>
        </div>
      </div>
      <template #buttons>
        <v-btn variant="text" ripple :disabled="editStatementDialog.processing" @click.prevent="submitEditStatement()"
          >Save</v-btn
        >
      </template>
    </ndt-dialog>
    <ndt-dialog v-model:active="deleteDialog.active" title="Delete Statement" :width="500">
      <span>Are you sure you want to delete "{{ deleteDialog.statement }}"?</span>
      <template #buttons>
        <v-btn
          variant="text"
          ripple
          color="error"
          :disabled="deleteDialog.processing"
          @click.prevent="submitDeleteStatement()"
        >
          Delete
        </v-btn>
      </template>
    </ndt-dialog>
  </div>
</template>

<script>
import NdtIconButton from '../../components/NdtIconButton.vue';
import NdtDialog from '../../components/NdtDialog.vue';

export default {
  name: 'AdminCurriculumTemplateStatementsPage',
  components: { NdtIconButton, NdtDialog },
  data: function () {
    return {
      themeId: null,
      themeName: '',
      statements: [],
      templateName: '',
      templateId: '',
      newStatement: {
        code: '',
        statement: '',
      },
      errorMessage: '',
      deleteDialog: {
        active: false,
        statementName: '',
        statementId: '',
        processing: '',
      },
      editStatementDialog: {
        active: false,
        statement: '',
        code: '',
        StatementId: null,
        errorMessage: '',
        processing: false,
      },
    };
  },
  computed: {
    canAddStatement() {
      return this.newStatement.code.length && this.newStatement.statement.length;
    },
    breadcrumbs() {
      return [
        {
          text: 'Curriculum Templates',
          to: {
            name: 'AdminCurriculumTemplatesPage',
          },
        },
        ...(this.templateId
          ? [
              {
                text: this.templateName,
                to: {
                  name: 'AdminCurriculumThemeTemplatesPage',
                  params: {
                    templateId: this.templateId,
                  },
                },
              },
              {
                text: this.themeName,
              },
            ]
          : []),
      ];
    },
  },
  created: async function () {
    await this.getStatements();
  },
  methods: {
    async getStatements() {
      this.themeId = this.$route.params.themeId.toString();
      const r = await this.$api.get(`/curriculum-theme-templates/${this.themeId}`);
      this.statements = r.data.curriculum_statement_templates;
      this.themeName = r.data.name;
      this.templateName = r.data.curriculum_template.name;
      this.templateId = r.data.curriculum_template.id;
    },
    async addStatement() {
      this.errorMessage = '';
      const theme_order = this.statements.length
        ? Math.max.apply(
            null,
            this.statements.map(t => t.theme_order)
          ) + 1
        : 1;
      try {
        const r = await this.$api.post('/curriculum-statement-templates', {
          code: this.newStatement.code,
          statement: this.newStatement.statement,
          curriculum_theme_template_id: this.themeId,
          theme_order: theme_order,
        });
        this.statements.push(r.data);
        this.newStatement.statement = '';
        this.newStatement.code = '';
      } catch (e) {
        this.errorMessage = 'Sorry, cannot add a statement right now';
        throw e;
      }
    },
    editStatement(statement) {
      this.editStatementDialog = {
        active: true,
        statementId: statement.id,
        ...statement,
      };
    },
    deleteStatement(statement) {
      this.deleteDialog = {
        active: true,
        statementId: statement.id,
        statement: statement.statement,
        processing: false,
      };
    },
    submitDeleteStatement() {
      this.deleteDialog.processing = true;
      this.$api.delete(`/curriculum-statement-templates/${this.deleteDialog.statementId}`).then(() => {
        this.deleteDialog.active = false;
        this.statements = this.statements.filter(s => s.id !== this.deleteDialog.statementId);
      });
    },
    async submitEditStatement() {
      try {
        this.editStatementDialog.processing = true;
        const r = await this.$api.put(
          `/curriculum-statement-templates/${this.editStatementDialog.statementId}`,
          this.editStatementDialog
        );
        this.editStatementDialog.processing = false;
        const statement = r.data;
        const i = this.statements.findIndex(s => s.id === statement.id);
        this.statements.splice(i, 1, statement);
        this.onDialogClose();
      } catch (e) {
        this.editStatementDialog.errorMessage = 'Sorry, cannot update this template right now';
        throw e;
      }
    },
    onDialogClose() {
      this.editStatementDialog = {
        active: false,
        name: '',
        StatementId: null,
        errorMessage: '',
        processing: false,
      };
    },
    async moveUp(statement) {
      const StatementIndex = this.statements.findIndex(t => t.id === statement.id);
      if (StatementIndex === 0) return;
      const higherStatement = this.statements[StatementIndex - 1];
      const statementsToUpdate = [
        {
          id: statement.id,
          theme_order: statement.theme_order - 1,
        },
        {
          id: higherStatement.id,
          theme_order: higherStatement.theme_order + 1,
        },
      ];
      await this.$api.post(`curriculum-statement-templates/update-ordering`, {
        statementsToUpdate: statementsToUpdate,
      });
      await this.getStatements();
    },
    async moveDown(statement) {
      const StatementIndex = this.statements.findIndex(t => t.id === statement.id);
      if (StatementIndex === this.statements.length - 1) return; // SORT THIS FOR MOVING DOWN SHOULD BE FINDING LAST NOT 0
      const lowerStatement = this.statements[StatementIndex + 1];
      const statementsToUpdate = [
        {
          id: statement.id,
          theme_order: statement.theme_order + 1,
        },
        {
          id: lowerStatement.id,
          theme_order: lowerStatement.theme_order - 1,
        },
      ];
      await this.$api.post(`curriculum-statement-templates/update-ordering`, {
        statementsToUpdate: statementsToUpdate,
      });
      await this.getStatements();
    },
  },
};
</script>
