<template>
  <div class="pt-2">
    <div v-if="!singleSubstandardPerStandard" class="text-h6 pl-6">
      {{ standard.code ? standardNounCapitalised + ': ' + standard.code + ' - ' + standard.name : 'Loading...' }}
    </div>

    <v-list @update:opened="listGroupsOpened($event)">
      <template v-for="substandard in standard.substandards" :key="substandard.id">
        <v-list-group v-if="substandard.evidence && substandard.evidence.length" :value="substandard.id">
          <template #activator="{ props }">
            <v-list-item
              :title="renderTitle(substandard)"
              :subtitle="renderEvidenceCount(substandard)"
              v-bind="props"
              class="py-2"
              :class="{ 'px-6': smallScreen }"
            >
              <template #prepend v-if="!smallScreen">
                <v-avatar>
                  <ndt-icon-button icon="link" tooltip="Evidence linked" color="accent" active />
                </v-avatar>
              </template>
            </v-list-item>
          </template>

          <v-expand-transition>
            <mosaic-list
              :three-line="smallScreen"
              :items="substandard.evidence"
              class="px-2"
              v-if="showEvidence[substandard.id]"
            >
              <template #item="{ item: evidence }">
                <evidence-list-item
                  :key="evidence.id"
                  :evidence="evidence"
                  :user="user"
                  :edit-evidence="editEvidence"
                  :delete-evidence="deleteEvidence"
                  :can-view-reflections="canViewReflections"
                  @click-favourite="favouriteEvidenceClick"
                  @click-target="x => $emit('clickTarget', x)"
                  @click-reflection="x => $emit('clickReflection', x)"
                  @click-lesson-observation="x => $emit('clickLessonObservation', x)"
                  @click-mentor-meeting="x => $emit('clickMentorMeeting', x)"
                  @click-student-assignment="x => $emit('clickStudentAssignment', x)"
                />
              </template>
            </mosaic-list>
          </v-expand-transition>
        </v-list-group>

        <v-list-item
          v-else
          :title="renderTitle(substandard)"
          :subtitle="renderEvidenceCount(substandard)"
          :class="{ 'px-6': smallScreen }"
        >
          <template #prepend v-if="!smallScreen">
            <v-avatar>
              <ndt-icon-button icon="link-off" tooltip="No Evidence linked" />
            </v-avatar>
          </template>
        </v-list-item>
      </template>
    </v-list>
  </div>
</template>

<script>
import NdtIconButton from './NdtIconButton.vue';
import { mapState, mapGetters } from 'vuex';
import EvidenceListItem from './EvidenceListItem.vue';
import config from '../utils/config';

export default {
  props: {
    standard: {
      type: Object,
      required: true,
    },
    editEvidence: {
      type: Function,
      default: null,
    },
    deleteEvidence: {
      type: Function,
      default: null,
    },
    canViewReflections: {
      type: Boolean,
      required: true,
    },
  },
  emits: [
    'clickTarget',
    'clickReflection',
    'clickLessonObservation',
    'clickMentorMeeting',
    'clickStudentAssignment',
    'clickFavourite',
  ],
  data: () => ({
    config: config,
    showEvidence: {},
  }),
  computed: {
    ...mapState(['user']),
    ...mapGetters(['singleSubstandardPerStandard']),
  },
  methods: {
    renderTitle: function (substandard) {
      const title = `${substandard.code} - ${substandard.name}`;
      return title;
    },
    favouriteEvidenceClick(evidence) {
      this.$emit('clickFavourite', evidence);
    },
    renderEvidenceCount(substandard) {
      if (substandard.evidence && substandard.evidence.length) {
        return `${substandard.evidence.length} piece${substandard.evidence.length === 1 ? '' : 's'} of Evidence linked`;
      } else {
        return 'No Evidence linked for these filters';
      }
    },
    listGroupsOpened(openSubstandardIds) {
      this.showEvidence = openSubstandardIds.reduce((a, v) => ({ ...a, [v]: true }), {});
    },
  },
  components: { NdtIconButton, EvidenceListItem },
};
</script>
