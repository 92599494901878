export function createStaffAnnouncementStatusChip(read?: boolean) {
  if (read) {
    return {
      text: 'Read',
      tooltip: `The Announcement has been read by the Instructor`,
      color: 'primary',
    };
  } else if (read === false) {
    return {
      text: 'Unread',
      tooltip: `The Announcement has not yet been read by the Instructor`,
      color: 'accent',
    };
  } else {
    return {
      text: 'Not Assigned',
      tooltip: `The Announcement is not assigned to this Instructor`,
      color: 'secondary',
    };
  }
}
