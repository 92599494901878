export function storageConstraintMessage(storageConstraint) {
  if (!storageConstraint) return '';
  let message = `When you click the button below, you must link a${
    storageConstraint.account_type == 'personal' ? ' personal' : 'n organisational'
  } One Drive account. This is enforced by your institution. If you try and link a${
    storageConstraint.account_type == 'organisational' ? ' personal' : 'n organisational'
  } One Drive account, you will be redirected here to try again.`;
  if (storageConstraint.domain) {
    message =
      message + ` You must also ensure you use an email address with "${storageConstraint.domain}" after the @ symbol.`;
  }
  return message;
}
export function storageEmailError(storageQueryParams, storageConstraint) {
  const storageEmail = storageQueryParams.storage_email;
  switch (storageQueryParams.storage_email_error) {
    case 'expected_personal':
      return `You are only allowed to link a personal One Drive account with your Mosaic account for this institution. You have tried to link ${storageEmail}, which is an organisational account.`;
    case 'incorrect_domain':
      return `You are only allowed to link a One Drive account with "${storageConstraint.domain}" after the @ symbol for this institution. You have tried to link ${storageEmail}, which does not meet this criteria.`;
    case 'expected_organisational':
      return `You are only allowed to link an organisational One Drive account with your Mosaic account for this institution. You have tried to link ${storageEmail}, which is a personal account.`;
    default:
      return false;
  }
}
