<template>
  <mosaic-loading-card v-if="busy" type="list" />
  <mosaic-load-error-card
    v-else-if="loadError"
    object-type="Reflections"
    :viewing-student="true"
    @retry="loadReflections"
  />
  <div v-else>
    <!-- TODO: move this into ReflectionsList -->
    <mosaic-list-filters-card
      v-if="curriculumVisible || selectedInstitution.config.students_can_add_reflections"
      title="Reflections"
    >
      <curriculum-theme-filter v-model:curriculum-theme-id="selectedCurriculumThemeId" />
      <template #actions>
        <v-btn
          v-if="selectedInstitution.config.students_can_add_reflections"
          ripple
          @click.prevent="createReflection()"
        >
          <div class="d-flex align-center">
            <v-icon>mdi-plus</v-icon>
            <span>Reflection</span>
          </div>
        </v-btn>
      </template>
    </mosaic-list-filters-card>

    <reflections-list
      :reflections="curriculumFilteredReflections"
      :edit-evidence="editEvidence"
      :create-evidence="createEvidence"
      :reflection-click-to="reflectionClickTo"
      :delete-reflection="deleteReflection"
      :show-title="false"
    >
      <template v-if="!busy">
        <div v-if="selectedInstitution.config.students_can_add_reflections">
          <span v-if="selectedCurriculumThemeId">You have no Reflections for these filters.</span>
          <span v-else>You have no Reflections.</span>
          Create a
          <a @click.prevent="createReflection()" class="text-link">new Reflection</a>.
        </div>
        <div v-else>
          You have no Reflections to complete. Your course leads may set reflective tasks, which will appear here.
        </div>
      </template>
    </reflections-list>

    <CreateEvidenceDialog
      v-model:active="createEvidenceDialog.active"
      :on-close="updateReflectionsList"
      :reflection-id="createEvidenceDialog.reflectionId"
      :title-placeholder="createEvidenceDialog.title"
      :suggested-codes="createEvidenceDialog.curriculumThemeCodes"
    />
    <EditEvidenceDialog v-model:active="editEvidenceDialog.active" :evidence="editEvidenceDialog.evidence" />
    <ndt-dialog
      v-model:active="deleteReflectionDialog.active"
      title="Delete Reflection"
      :error-message="deleteReflectionDialog.errorMessage"
    >
      <div>Are you sure you want to delete reflection:</div>
      <br />
      <div>
        <span class="font-weight-bold">{{ `"` + deleteReflectionDialog.reflection.title + `"` }}</span>
        <span>?</span>
      </div>
      <template v-if="deleteReflectionDialog.reflection.evidence">
        <br />
        <div>
          <span>
            This reflection is linked to:
            {{
              deleteReflectionDialog.reflection.evidence.substandards
                .map(s => {
                  return s.code;
                })
                .join(', ')
            }}
            as evidence
          </span>
        </div>
      </template>
      <template #buttons>
        <v-btn
          variant="text"
          ripple
          color="error"
          :disabled="deleteReflectionDialog.processing"
          @click.prevent="submitDeleteReflection()"
        >
          Delete
        </v-btn>
      </template>
    </ndt-dialog>
  </div>
</template>

<script>
import ReflectionsList from './ReflectionsList.vue';
import CreateEvidenceDialog from '@/components/student/CreateEvidenceDialog.vue';
import EditEvidenceDialog from '@/components/student/EditEvidenceDialog.vue';
import NdtDialog from '@/components/NdtDialog.vue';
import CurriculumThemeFilter from '@/components/CurriculumThemeFilter.vue';
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'StudentReflectionsListPage',
  components: {
    ReflectionsList,
    CreateEvidenceDialog,
    EditEvidenceDialog,
    NdtDialog,
    CurriculumThemeFilter,
  },
  data: function () {
    return {
      reflections: [],
      selectedCurriculumThemeId: null,
      busy: true,
      loadError: false,
      createEvidenceDialog: {
        active: false,
        curriculumThemeCodes: [],
      },
      editEvidenceDialog: {
        active: false,
        evidence: {},
      },
      deleteReflectionDialog: {
        active: false,
        reflection: {},
        disabled: true,
        errorMessage: '',
        processing: false,
      },
      reflectionEvidence: [],
    };
  },
  computed: {
    ...mapState(['selectedInstitution', 'curriculumThemes', 'userStudent']),
    ...mapGetters(['curriculumVisible']),
    curriculumFilteredReflections() {
      return this.reflections.filter(reflection => {
        return this.selectedCurriculumThemeId && this.curriculumThemes
          ? this.curriculumThemes[this.selectedCurriculumThemeId].curriculum_statements
              .map(s => s.id)
              .some(x => reflection.curriculum_statements.map(s => s.id).includes(x))
          : true;
      });
    },
    breadcrumbs() {
      return [
        {
          text: 'Reflections',
        },
      ];
    },
  },
  async created() {
    await this.loadReflections();
  },
  methods: {
    async loadReflections() {
      this.busy = true;
      this.loadError = false;
      try {
        const response = await this.$api.get(`/students/${this.userStudent.id}/reflections`);
        this.reflections = response.data;
      } catch (e) {
        console.log(e);
        this.loadError = true;
      }
      this.busy = false;
    },
    createReflection() {
      this.$router.push({ name: 'StudentReflectionCreatePage' });
    },
    reflectionClickTo(id) {
      return { name: 'ReflectionPage', params: { studentId: this.userStudent.id, reflectionId: id } };
    },
    updateReflectionsList() {
      this.$api.get(`/students/${this.userStudent.id}/reflections`).then(response => {
        this.reflections = response.data;
        this.busy = false;
      });
    },
    deleteReflection(reflection) {
      this.deleteReflectionDialog.reflection = reflection;
      this.deleteReflectionDialog.active = true;
    },
    submitDeleteReflection() {
      this.$api
        .delete(`/reflections/${this.deleteReflectionDialog.reflection.id}`)
        .then(() => {
          this.deleteReflectionDialog.active = false;
          this.deleteReflectionDialog.errorMessage = '';
          const i = this.reflections.findIndex(t => t.id === this.deleteReflectionDialog.reflection.id);
          // updates the array such that Vue knows it has changed
          this.reflections.splice(i, 1);
          this.deleteReflectionDialog.processing = false;
        })
        .catch(e => {
          this.deleteReflectionDialog.errorMessage =
            (e.response && e.response.data.message) || `Sorry, cannot delete your reflection at the moment`;
          this.deleteReflectionDialog.processing = false;
        });
    },
    createEvidence(reflection) {
      this.createEvidenceDialog = {
        active: true,
        title: reflection.title,
        reflectionId: reflection.id,
        curriculumThemeCodes: reflection.curriculum_statements.map(s => s.curriculum_theme_code).unique(),
      };
    },
    editEvidence(reflection) {
      this.$api.get(`/evidence/${reflection.evidence.id}`).then(response => {
        this.editEvidenceDialog = {
          active: true,
          evidence: response.data,
        };
      });
    },
  },
};
</script>
