<template>
  <div>
    <mosaic-tab-card-page
      v-model:trigger-background-load="triggerBackgroundLoad"
      object-type="Course Targets"
      :object-type-is-plural="true"
      :headers="tabHeaders"
      :load="loadCourseTargets"
      @tab-selected="tab = $event"
    >
      <template #actions>
        <mosaic-btn
          icon="mdi-plus"
          :to="{ name: 'CohortCourseTargetCreatePage', params: { cohortId: cohortId }, query: { fromTab: tab } }"
          >Course Target</mosaic-btn
        >
      </template>
      <template #live-tab-item>
        <cohort-course-targets-page-list
          :cohorts="cohorts"
          v-model:trigger-background-load="triggerBackgroundLoad"
          :targets="liveTargets"
          type="live"
          :subtitle="liveTargetSubtitle"
          :click-target-to="clickTargetTo"
        />
      </template>
      <template #scheduled-tab-item>
        <cohort-course-targets-page-list
          :cohorts="cohorts"
          v-model:trigger-background-load="triggerBackgroundLoad"
          :targets="scheduledTargets"
          type="scheduled"
          :subtitle="t => `Scheduled for ${moment(t.scheduled_at_date || t.scheduled_date).format('DD-MM-YYYY')}`"
          :click-target-to="clickTargetTo"
        />
      </template>
      <template #draft-tab-item>
        <cohort-course-targets-page-list
          :cohorts="cohorts"
          v-model:trigger-background-load="triggerBackgroundLoad"
          :targets="draftTargets"
          type="draft"
          subtitle="Draft"
          :click-target-to="clickTargetTo"
        />
      </template>
      <template #monitoring-tab-item>
        <mosaic-cohort-monitoring-filters
          class="mb-6"
          :students="selectedCohortStudents"
          @update:filtered-student-ids="filteredStudentIds = $event"
        />
        <mosaic-cohort-completion-table
          :object-type-pluralised="traineeNounCapitalisedAndPluralised"
          :statuses="completionStatuses"
          :status-columns="targetStatusColumns"
          column-object-type-pluralised="Course Targets"
          :rows="monitoringRows"
          :name-click-route="nameClickRoute"
          :click-route="dataClickRoute"
          :selected-group-id="selectedGroupId"
        />
      </template>
    </mosaic-tab-card-page>
  </div>
</template>

<script setup lang="ts">
import MosaicTabCardPage from '@/components/library/pages/MosaicTabCardPage.vue';
import MosaicCohortCompletionTable from '@/components/monitoring/MosaicCohortCompletionTable.vue';
import { parseRouteId } from '@/composables/vue-router';
import moment from 'moment';
import { computed } from 'vue';
import { ref } from 'vue';
import CohortCourseTargetsPageList from './CohortCourseTargetsPageList.vue';
import type { CourseTarget } from './CohortCourseTargetEditPage.vue';
import type { Row } from '@/components/monitoring/mosaic-table';
import { setBreadcrumbs } from '@/utils/breadcrumbs';
import { useApi } from '@/composables/api';
import { useCohortStore } from '@/stores/cohort';
import { mapActions } from '@/store/map-store';
import { mapState } from '@/store/map-store';
import { hasPermissionForSelectedInstitution } from '@/composables/permission';

const api = useApi();
const { selectedCohortStudents } = useCohortStore();
const { loadCohorts } = mapActions();
const { userStaff, cohorts: allCohorts } = mapState();

const staffCohorts = userStaff.value.staff_roles
  .filter(x => x.cohort_id)
  .map(x => ({
    ...x.cohort,
  }));

if (hasPermissionForSelectedInstitution('Admin').value) loadCohorts();

const cohorts = computed(() => {
  const arrayToUse = allCohorts.value.length ? allCohorts.value : staffCohorts;
  return arrayToUse.map((item: { name: string; id: number; curriculum_id: number; status: string }) => ({
    name: item.name,
    id: item.id,
    curriculum_id: item.curriculum_id,
    status: item.status,
  }));
});

setBreadcrumbs([
  {
    text: 'Course Targets',
  },
]);

const tabHeaders = [
  {
    text: 'Live',
    key: 'live',
  },
  {
    text: 'Scheduled',
    key: 'scheduled',
  },
  {
    text: 'Draft',
    key: 'draft',
  },
  {
    text: 'Monitoring',
    key: 'monitoring',
  },
];
const tab = ref('live');

const cohortId = parseRouteId('cohortId');
const targets = ref<CourseTarget[]>([]);
const triggerBackgroundLoad = ref(false);
async function loadCourseTargets() {
  const r = await api.get<CourseTarget[]>(`/admin-targets?cohortId=${cohortId.value}`);
  targets.value = r.data;
}

const liveTargets = computed(() =>
  targets.value
    .filter(x => x.set_date)
    .map(x => ({
      ...x,
      created_date: x.schedule_created_date || x.created_at,
    }))
    .sort((a, b) => (moment(a.set_date || a.created_date) < moment(b.set_date || b.created_date) ? 1 : -1))
);

function liveTargetSubtitle(target: CourseTarget) {
  return `Set ${moment(target.set_date || target.schedule_created_date || target.created_at).format('DD-MM-YYYY')}`;
}

const scheduledTargets = computed(() => {
  return targets.value
    .filter(x => (x.scheduled_date || x.scheduled_at_date) && !x.schedule_created_date)
    .map(x => ({
      ...x,
    }))
    .sort((a, b) => {
      const date = a.scheduled_at_date ? 'scheduled_at_date' : 'scheduled_date';
      return moment(a[date]) > moment(b[date]) ? 1 : -1;
    });
});

const draftTargets = computed(() =>
  targets.value
    .filter(x => !x.scheduled_date && !x.scheduled_at_date && !x.set_date && x.draft)
    .map(x => ({
      ...x,
    }))
    .sort((a, b) => (moment(a.created_at) > moment(b.created_at) ? 1 : -1))
);

const clickTargetTo = (t: CourseTarget) => ({
  name: 'CohortCourseTargetEditPage',
  params: { cohortId: cohortId.value.toString(), id: t.id.toString() },
  query: { fromTab: tab.value },
});

// Monitoring
const selectedGroupId = ref<number>(-1);
type Student = {
  id: number;
  name: string;
};

function studentTargetMap(student: Student) {
  return (courseTarget: CourseTarget) => {
    const ats = courseTarget.admin_target_students.find(ats => ats.student_id === student.id);
    if (!ats) return {};
    return {
      value: courseTarget.admin_target_students.find(ats => ats.student_id === student.id)?.status,
      targetId: ats.target_id,
    };
  };
}

const filteredStudentIds = ref<number[]>(
  selectedCohortStudents && selectedCohortStudents.value && selectedCohortStudents.value.length > 0
    ? selectedCohortStudents.value.map(s => s.id)
    : []
);
const monitoringRows = computed(() =>
  selectedCohortStudents.value
    .filter(s => filteredStudentIds.value.includes(s.id))
    .map(s => ({
      id: s.id,
      name: s.name || s.email,
      studentGroups: s.student_groups,
      ...liveTargets.value.reduce((acc, t) => ({ ...acc, [t.id]: studentTargetMap(s)(t) }), {}),
    }))
);

type StudentCourseTargetData = {
  id: number;
  name: string;
} & Record<string, { value: string; targetId: number }>;

const completionStatuses = {
  active: {
    text: 'Active',
    color: 'accent',
    sortOrder: 3,
  },
  completed: {
    text: 'Completed',
    color: 'primary',
    sortOrder: 2,
  },
  approved: {
    text: 'Approved',
    color: 'secondary',
    sortOrder: 1,
  },
};
function dataClickRoute(row: Row, targetId: number) {
  const studentCourseTargetData = row as StudentCourseTargetData;
  const data = studentCourseTargetData[targetId];
  if (!data.value) return undefined;
  return {
    name: 'TargetPage',
    params: {
      studentId: studentCourseTargetData.id.toString(),
      targetId: data.targetId ? data.targetId.toString() : '',
    },
  };
}

function nameClickRoute(row: Row) {
  return {
    name: 'TargetsListPage',
    params: {
      studentId: (row as Student).id.toString(),
    },
  };
}
const targetStatusColumns = computed(() =>
  liveTargets.value.map(t => ({
    name: moment(t.set_date || t.schedule_created_date || t.created_date).format('DD/MM') + ': ' + t.goal,
    id: t.id,
  }))
);
</script>
