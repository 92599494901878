import type { Ref } from 'vue';
import { computed, ref, watch } from 'vue';

export function paginateList<T>(list: Ref<T[]>, pageSizeProp?: number) {
  const currentPage = ref(1);
  watch(list, (list, oldList) => {
    if (list.length !== oldList.length || JSON.stringify(list) !== JSON.stringify(oldList)) {
      currentPage.value = 1;
    }
  });

  const pageSize = ref(pageSizeProp || 10);

  const paginatedList = computed(() => {
    const index = (currentPage.value - 1) * pageSize.value;
    return list.value.slice(index, index + pageSize.value);
  });

  function setPageToShowItemAtIndex(index: number) {
    if (index < 0 || index >= list.value.length) return;
    const pageWithItem = Math.ceil((index + 1) / pageSize.value);
    currentPage.value = pageWithItem;
  }

  const paginationTotal = computed(() => list.value.length);

  return { paginatedList, currentPage, pageSize, paginationTotal, setPageToShowItemAtIndex };
}
