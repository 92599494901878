<template>
  <v-expansion-panel>
    <v-expansion-panel-title class="text-h7">
      <template #default="{ open }">
        <div class="d-flex align-center">
          <div>
            <v-icon class="mr-4">mdi-key</v-icon>
          </div>
          <div :style="open ? 'color: rgb(var(--v-theme-primary));' : ''">{{ reallyFriendlyName }}</div>
        </div>
      </template>
    </v-expansion-panel-title>
    <v-expansion-panel-text class="px-10">
      <div class="d-flex align-center justify-space-between">
        <div>
          <span class="font-weight-bold">Database name:</span>
          {{ permission.name }}
        </div>
        <div>
          <span class="pr-2 font-weight-bold">Expose to Customer?</span>
          <v-btn-toggle v-model="internalPermission.expose_to_customer" mandatory variant="outlined" divided>
            <v-btn class="px-3" :value="true" color="green">Yes</v-btn>
            <v-btn class="px-3" :value="false" color="red">No</v-btn>
          </v-btn-toggle>
        </div>
      </div>
      <div>
        <v-text-field
          v-model="internalPermission.friendly_name"
          label="Friendly Name"
          style="width: 60%"
        ></v-text-field>
        <v-textarea
          v-model="internalPermission.friendly_description"
          auto-grow
          rows="1"
          label="Friendly Description"
        ></v-textarea>
        <v-select
          v-model="internalPermission.feature_config_dependency_id"
          :items="featureConfigsWithNull"
          item-value="id"
          item-title="name"
          label="Feature dependency"
        />
      </div>
      <div class="d-flex justify-end">
        <v-btn
          class="mr-2"
          variant="text"
          :disabled="!permissionIsChanged"
          @click.prevent="savePermission(internalPermission)"
          >Save</v-btn
        >
        <v-btn variant="text" :disabled="!permissionIsChanged" @click.prevent="cancelChange()">Cancel</v-btn>
      </div>
      <mosaic-snackbar v-model="snackbar.active" :color="snackbar.color" contained :message="snackbar.message" />
    </v-expansion-panel-text>
  </v-expansion-panel>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { renderFriendlyText } from '@/utils/text';
export default {
  props: {
    permission: {
      required: true,
      type: Object,
    },
  },
  data: function () {
    return {
      internalPermission: { ...this.permission },
      snackbar: {
        active: false,
        color: 'success',
        message: '',
      },
    };
  },
  computed: {
    ...mapGetters(['friendlyTextMap']),
    ...mapState(['featureConfigs']),

    permissionIsChanged() {
      return (
        this.internalPermission.friendly_name != this.permission.friendly_name ||
        this.internalPermission.friendly_description != this.permission.friendly_description ||
        this.internalPermission.expose_to_customer != this.permission.expose_to_customer ||
        this.internalPermission.feature_config_dependency_id != this.permission.feature_config_dependency_id
      );
    },
    featureConfigsWithNull() {
      return [
        {
          id: null,
          name: 'None',
        },
      ].concat(this.featureConfigs);
    },
    reallyFriendlyName() {
      return renderFriendlyText(this.internalPermission.friendly_name, this.friendlyTextMap);
    },
  },
  methods: {
    cancelChange() {
      this.resetChanges();
    },
    resetChanges() {
      this.internalPermission = { ...this.permission };
    },
    async savePermission() {
      try {
        const r = await this.$api.put(`/admin/permissions/${this.internalPermission.id}`, this.internalPermission);
        this.snackbar = {
          active: true,
          color: 'success',
          message: 'Success!',
        };
        this.$store.commit('updatePermission', r.data);
      } catch (e) {
        console.log(e);
        this.snackbar = {
          active: true,
          color: 'error',
          message: 'Sorry, could not update permission at the moment',
        };
      }
    },
  },
};
</script>
