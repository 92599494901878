<template>
  <mosaic-tooltip-chip :color="chip.color">
    <template #text>
      {{ chip.text }}
    </template>

    <template #tooltip>
      {{ chip.tooltip }}
    </template>
  </mosaic-tooltip-chip>
</template>

<script setup lang="ts">
import { formatDate } from '@/mixins/global-mixins';
import { computed } from 'vue';

const props = defineProps<{
  setDate?: string;
  scheduledDate?: string;
  draft?: boolean;
  objectType: string;
  assigneeNounPluralised: string;
}>();

const chip = computed(() => {
  if (props.setDate) {
    return {
      text: 'Live',
      tooltip: `The ${props.objectType} is live with ${props.assigneeNounPluralised}`,
      color: 'accent',
    };
  } else if (props.scheduledDate) {
    return {
      text: 'Scheduled',
      tooltip: `The ${props.objectType} will be assigned to ${props.assigneeNounPluralised} on ${formatDate(
        props.scheduledDate
      )}.`,
      color: 'purple',
    };
  } else if (props.draft) {
    return {
      text: 'Draft',
      tooltip: `The ${props.objectType} can be assigned to trainees at a later date.`,
      color: 'grey',
    };
  } else {
    return {
      text: 'Pending Assignment',
      tooltip: `The ${props.objectType} has not been assigned yet`,
      color: 'green',
    };
  }
});
</script>
