<template>
  <div>
    <v-card v-if="error">
      <v-card-text>
        <div class="pa-4">{{ error }}</div>
      </v-card-text>
    </v-card>
    <v-card v-else>
      <v-card-text>
        <mosaic-card-title class="pb-1">Course Reflection Template</mosaic-card-title>
        <div>
          The Course Reflection template will be the default template for Course Reflections, but can be modified before
          setting the Course Reflection. This template applies across all cohorts.
        </div>
        <div class="mt-4">
          <mosaic-quill v-model:contents="reflectionTemplate" :read-only="false" :has-min-height="true"></mosaic-quill>
        </div>
        <div class="mt-2 text-right">
          <v-btn color="primary" ripple :disabled="processing" :loading="processing" @click.prevent="updateReflection()"
            >Save</v-btn
          >
        </div>
      </v-card-text>
      <mosaic-snackbar v-model="snackbar.active" :color="snackbar.color" :message="snackbar.message" />
    </v-card>
  </div>
</template>

<script>
import MosaicQuill from '@/components/quill/MosaicQuill.vue';
import { mapState } from 'vuex';

export default {
  name: 'TutorAdminReflectionTemplatePage',
  data: () => ({
    error: '',
    processing: false,
    busy: false,
    snackbar: {
      active: false,
      color: 'success',
      message: 'Successfully updated',
    },
    reflectionTemplate: null,
  }),
  created() {
    this.reflectionTemplate = this.selectedInstitution.config.course_reflection_template;
  },
  computed: {
    ...mapState(['selectedInstitution']),
    breadcrumbs() {
      return [
        {
          text: 'Reflection Template',
        },
      ];
    },
  },
  methods: {
    async updateReflection() {
      this.processing = true;
      try {
        await this.$api.put(`/institutions/${this.selectedInstitution.id}/course-reflection-template`, {
          courseReflectionTemplate: this.reflectionTemplate,
        });
        this.$store.commit('updateSelectedInstitutionReflectionTemplate', this.reflectionTemplate);
        this.snackbar = {
          active: true,
          color: 'success',
          message: 'Successfully updated',
        };
      } catch (e) {
        console.log(e);
        this.snackbar = {
          active: true,
          color: 'error',
          message: 'Sorry, could not update your template right now',
        };
      }
      this.processing = false;
    },
  },
  components: { MosaicQuill },
};
</script>
