<template>
  <div>
    <mosaic-loading-card v-if="loading || stateProcessing" type="card" />
    <mosaic-load-error-card
      v-else-if="error || stateError"
      object-type="Lesson Observation"
      @retry="loadLessonObservation"
    />
    <lesson-observation-card
      v-else
      ref="lessonObservationCard"
      :id="id"
      v-model:lesson-observation="lessonObservation"
      :template-content-layout="
        fromSnakeCaseToCamelCase(userStudent.cohort.lesson_observation_templates[0].content_layout)
      "
      :is-creating="isCreating"
      :has-create-permissions="selectedInstitution.config.students_can_add_lesson_observations"
      :student-id="userStudent.id"
      :curriculum-statement-id="
        $route.query.curriculumStatementId ? $route.query.curriculumStatementId.toString() : undefined
      "
      :return-to="{ name: 'LessonObservationsListPage', params: { studentId: userStudent.id } }"
      @update:dirty="$emit('update:dirty', $event)"
    />
  </div>
</template>

<script>
import LessonObservationCard from './LessonObservationCard.vue';
import { mapStateProcessing, mapStateError } from '@/store/map-store';
import { mapState } from 'vuex';
import { fromSnakeCaseToCamelCase } from '@/utils/transforms';

export default {
  name: 'StudentLessonObservationEditPage',
  components: { LessonObservationCard },
  emits: ['update:dirty'],
  expose: ['save'],
  data() {
    return {
      isCreating: true,
      id: null,
      lessonObservation: null,
      error: false,
      loading: true,
    };
  },
  computed: {
    ...mapState(['selectedInstitution', 'userStudent']),
    ...mapStateProcessing(['loadStudentStaffRoles']),
    ...mapStateError(['loadStudentStaffRoles']),
    breadcrumbs() {
      return [
        {
          text: 'Lesson Observations',
          to: {
            name: 'LessonObservationsListPage',
            params: { studentId: this.userStudent.id },
          },
        },
        {
          text: this.isCreating ? 'New Lesson Observation' : this.lessonObservation?.title,
        },
      ];
    },
  },
  async created() {
    if (this.$route.name === 'LessonObservationCreatePage') {
      this.isCreating = true;
      this.loading = false;
    } else {
      this.id = this.$route.params.id.toString();
      this.isCreating = false;
      await this.loadLessonObservation();
    }
    await this.$store.dispatch('loadStudentStaffRoles');
  },
  methods: {
    async loadLessonObservation() {
      this.loading = true;
      this.error = false;
      try {
        const response = await this.$api.get(`lesson-observations/${this.id}`);
        this.lessonObservation = response.data;
      } catch (e) {
        console.log(e);
        this.error = true;
      }
      this.loading = false;
    },
    save() {
      return this.$refs.lessonObservationCard.save();
    },
    fromSnakeCaseToCamelCase,
  },
};
</script>
