<template>
  <component
    :is="level"
    ref="headingRef"
    class="heading"
    :style="{
      fontSize: fontSize,
      color: color,
      fontWeight: fontWeight,
    }"
    :aria-level="level.charAt(1)"
  >
    <slot></slot>
  </component>
</template>
<script setup lang="ts">
import { computed, ref } from 'vue';

interface Props {
  level: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  color?: string;
}

const props = withDefaults(defineProps<Props>(), {
  level: 'h2',
});

const headingRef = ref<HTMLElement | null>(null);

const fontSize = computed(() => {
  const sizes = {
    h1: '1.5rem',
    h2: '1.4rem',
    h3: '1.3rem',
    h4: '1.2rem',
    h5: '1.1rem',
    h6: '1rem',
  };
  return sizes[props.level];
});

const fontWeight = computed(() => {
  const weights = {
    h1: '700',
    h2: '700',
    h3: '600',
    h4: '600',
    h5: '500',
    h6: '500',
  };
  return weights[props.level];
});
</script>

<style scoped>
.heading {
  overflow: hidden;
  hyphens: none;
  letter-spacing: -0.01em;
}

@media (max-width: 768px) {
  .heading {
    font-size: calc(var(--font-size) * 0.9);
  }
}

@media (max-width: 480px) {
  .heading {
    white-space: normal;
    overflow-wrap: break-word;
  }
}
</style>
