<template>
  <div class="d-flex align-center">
    <mosaic-completion-label :can-edit="canEdit" :completed="completed">
      <div class="flex-grow-1 ml-2">
        <slot></slot>
      </div>
    </mosaic-completion-label>
  </div>
</template>

<script>
import MosaicCompletionLabel from './MosaicCompletionLabel.vue';

export default {
  name: 'ReviewOverallStatement',
  components: { MosaicCompletionLabel },
  props: {
    canEdit: {
      type: Boolean,
      required: true,
    },
    completed: {
      type: Boolean,
      required: true,
    },
  },
};
</script>
