<template>
  <div>
    <mosaic-list-filters-card title="Lesson Observations">
      <curriculum-theme-filter v-model:curriculum-theme-id="selectedCurriculumThemeId" class="mr-6" />
      <div>
        <div
          class="d-flex align-center"
          :class="{
            'vertical-flex-spacing-small': smallScreen,
            'horizontal-flex-spacing-small': !smallScreen,
            'flex-wrap': smallScreen,
          }"
        >
          <span class="pt-4">Show Lesson Observations between</span>
          <div class="d-flex align-center">
            <mosaic-date-picker
              v-model:date="startDate"
              placeholder="start date"
              density="compact"
              hide-details
              active-icon-colour
            />
            <span class="pt-4">and</span>
          </div>
          <div class="d-flex align-center">
            <mosaic-date-picker
              v-model:date="endDate"
              placeholder="end date"
              density="compact"
              hide-details
              active-icon-colour
            />
            <div style="margin-bottom: -20px">
              <mosaic-icon-btn name="clear-dates" icon="close" tooltip="Clear dates" @click.prevent="clearFilters()" />
            </div>
          </div>
        </div>
      </div>
      <template #actions>
        <mosaic-btn v-if="canCreateLessonObservations" ripple @click.prevent="$emit('clickCreateLessonObservation')">
          <div class="d-flex align-center">
            <v-icon>mdi-plus</v-icon>
            <span>Lesson Observation</span>
          </div>
        </mosaic-btn>
      </template>
    </mosaic-list-filters-card>

    <mosaic-loading-card v-if="busy" type="list-no-filters" />
    <mosaic-load-error-card v-else-if="loadError" object-type="Lesson Observations" @retry="loadLessonObservations" />
    <!-- This is a v-show (instead of a v-if) so that mosaic-pagination doesn't get remounted on every load-->
    <mosaic-card v-show="!busy && !loadError">
      <div v-if="lessonObservations.length === 0" class="pa-4 pl-6">
        No lesson observations have been added<span v-if="filtersApplied"> for these filters</span>.
        <span v-if="canCreateLessonObservations"
          >Create a
          <a @click.prevent="$emit('clickCreateLessonObservation')" class="text-link">new Lesson Observation</a>.</span
        >
      </div>
      <mosaic-list v-else :two-line="!smallScreen" :three-line="smallScreen" :items="paginatedLessonObservations">
        <template #item="{ item: lo }">
          <mosaic-list-item
            :key="lo.id"
            icon="mdi-file-eye"
            :title="lo.title"
            :subtitle="renderSubTitle(lo)"
            :to="lessonObservationClickTo(lo.id)"
          >
            <template #information>
              <v-tooltip location="top">
                <template #activator="{ props }">
                  <span v-bind="props">
                    <v-icon v-if="lo.created_by" color="primary">mdi-account-tie-voice</v-icon>
                    <v-icon v-else color="primary">mdi-account-tie</v-icon>
                  </span>
                </template>
                <span>Created by {{ createdBy(lo) }}</span>
              </v-tooltip>
            </template>

            <template #actions>
              <template v-if="showEvidence && canEditEvidence">
                <mosaic-evidence-icon-btn
                  v-model="lo.evidence"
                  @click-edit="editEvidence(lo)"
                  @click-create="createEvidence(lo)"
                />
              </template>
              <ndt-icon-button
                v-if="createdByMe(lo)"
                icon="delete"
                tooltip="Delete Lesson Observation"
                @click.prevent="deleteLessonObservation(lo)"
              />
              <!-- Ideally this would be conditional on there being some delete buttons on the page -->
              <div v-else-if="!smallScreen" style="width: 48px"></div>
            </template>
          </mosaic-list-item>
        </template>
      </mosaic-list>
      <mosaic-pagination v-model="currentPage" v-model:page-size="pageSize" :total="lessonObservations.length" />
    </mosaic-card>

    <ndt-dialog
      v-model:active="deleteDialog.active"
      title="Delete Lesson Observation"
      :error-message="deleteDialog.errorMessage"
    >
      <span>Are you sure you want to delete "{{ deleteDialog.name }}"?</span>
      <template #buttons>
        <v-btn
          variant="text"
          ripple
          color="error"
          :disabled="deleteDialog.processing"
          @click.prevent="submitDeleteLessonObservation()"
          >Delete</v-btn
        >
      </template>
    </ndt-dialog>

    <create-evidence-dialog
      v-model:active="createEvidenceDialog.active"
      :on-close="loadLessonObservations"
      :lesson-observation-id="createEvidenceDialog.lessonObservationId"
      :title-placeholder="createEvidenceDialog.title"
      :suggested-codes="createEvidenceDialog.curriculumThemeCodes"
    />
    <edit-evidence-dialog v-model:active="editEvidenceDialog.active" :evidence="editEvidenceDialog.evidence" />
  </div>
</template>

<script>
import NdtIconButton from '@/components/NdtIconButton.vue';
import NdtDialog from '@/components/NdtDialog.vue';
import { mapGetters, mapState } from 'vuex';
import { syncQueryParamsMixin } from '@/mixins/query-mixins';
import moment from 'moment';
import CreateEvidenceDialog from '@/components/student/CreateEvidenceDialog.vue';
import EditEvidenceDialog from '@/components/student/EditEvidenceDialog.vue';
import CurriculumThemeFilter from '@/components/CurriculumThemeFilter.vue';
import { useQueryStore } from '@/stores/query';
import axios from 'axios';

export default {
  name: 'LessonObservationsList',
  components: {
    NdtIconButton,
    NdtDialog,
    CreateEvidenceDialog,
    EditEvidenceDialog,
    CurriculumThemeFilter,
  },
  setup() {
    const queryStore = useQueryStore();
    return { queryStore };
  },
  mixins: [syncQueryParamsMixin({ startDate: { query: 'start' }, endDate: { query: 'end' } })],
  props: {
    studentId: {
      type: Number,
      required: true,
    },
    canCreateLessonObservations: {
      type: Boolean,
      default: false,
    },
    canEditEvidence: {
      type: Boolean,
      default: false,
    },
    lessonObservationClickTo: {
      type: Function,
      required: true,
    },
  },
  emits: ['clickCreateLessonObservation'],
  data() {
    return {
      busy: false,
      loadError: false,
      lessonObservations: [],
      startDate: null,
      endDate: null,
      selectedCurriculumThemeId: null,
      deleteDialog: {
        active: false,
        errorMessage: null,
        processing: false,
        name: '',
        id: null,
      },
      createEvidenceDialog: {
        active: false,
        curriculumThemeCodes: [],
      },
      editEvidenceDialog: {
        active: false,
        evidence: {},
      },
      currentPage: 1,
      pageSize: 10,
      cancelTokenSource: null,
      initialLoadDone: false,
    };
  },
  computed: {
    ...mapState(['user', 'userStaff']),
    ...mapGetters(['showEvidence']),
    filtersApplied() {
      return this.startDate || this.endDate || this.selectedCurriculumThemeId;
    },
    paginatedLessonObservations() {
      const index = (this.currentPage - 1) * this.pageSize;
      return this.lessonObservations.slice(index, index + this.pageSize);
    },
  },
  watch: {
    startDate() {
      if (this.initialLoadDone) this.loadLessonObservations();
    },
    endDate() {
      if (this.initialLoadDone) this.loadLessonObservations();
    },
    selectedCurriculumThemeId() {
      if (this.initialLoadDone) this.loadLessonObservations();
    },
    lessonObservations() {
      this.currentPage = 1;
    },
  },
  mounted() {
    this.loadLessonObservations();
  },
  methods: {
    async loadLessonObservations() {
      if (this.cancelTokenSource) {
        this.cancelTokenSource.cancel();
      }
      this.cancelTokenSource = axios.CancelToken.source();

      this.busy = true;
      this.loadError = false;
      try {
        const params = new URLSearchParams();
        if (this.startDate) params.append('start', this.startDate);
        // Have to add a day to make the filter inclusive
        if (this.endDate) params.append('end', moment(this.endDate).add(1, 'days').format('YYYY-MM-DD'));
        if (this.selectedCurriculumThemeId) params.append('curriculumThemeId', this.selectedCurriculumThemeId);

        const url = `/students/${this.studentId}/lesson-observations?${params.toString()}`;
        const r = await this.$api.get(url, this.cancelTokenSource.token);
        this.lessonObservations = r.data;
        this.initialLoadDone = true;
      } catch (e) {
        if (!axios.isCancel(e)) {
          console.log(e);
          this.loadError = true;
        }
      }
      this.busy = false;
      this.cancelTokenSource = null;
    },
    deleteLessonObservation(lo) {
      this.deleteDialog = {
        active: true,
        errorMessage: null,
        processing: false,
        name: lo.title,
        id: lo.id,
      };
    },
    async submitDeleteLessonObservation() {
      this.deleteDialog.processing = true;
      this.deleteDialog.errorMessage = '';
      try {
        await this.$api.delete(`/lesson-observations/${this.deleteDialog.id}`);
        this.deleteDialog.active = false;
        this.loadLessonObservations();
      } catch (e) {
        console.log(e);
        this.deleteDialog.errorMessage = `Sorry, cannot delete your lesson observation at the moment`;
      }
      this.deleteDialog.processing = false;
    },
    createdBy(lo) {
      if (!lo.created_by) return this.user.student ? 'Me' : this.traineeNounCapitalised();
      if (this.userStaff && lo.created_by.id == this.userStaff.id) return 'Me';
      return lo.created_by.name;
    },
    createdByMe(lo) {
      return this.createdBy(lo) === 'Me';
    },
    renderSubTitle(lo) {
      return `${this.formatDate(lo.observation_date)} - ${lo.class_name} - ${lo.year_group} - ${
        lo.observed_by?.name || lo.observer_name
      } (${lo.observed_by_role})`;
    },
    clearFilters() {
      this.startDate = null;
      this.endDate = null;
    },
    createEvidence(lo) {
      this.createEvidenceDialog = {
        active: true,
        title: lo.title,
        lessonObservationId: lo.id,
        curriculumThemeCodes: lo.curriculum_statements.map(s => s.curriculum_theme_code).unique(),
      };
    },
    editEvidence(lo) {
      this.$api.get(`/evidence/${lo.evidence.id}`).then(response => {
        this.editEvidenceDialog = {
          active: true,
          evidence: response.data,
        };
      });
    },
  },
};
</script>
