<template>
  <div>
    <v-card class="mb-4">
      <v-card-text>
        <div class="d-flex align-center">
          <div>
            <v-text-field
              v-model="filterTerm"
              density="compact"
              hide-details
              class="pl-6"
              label="Filter instructors by name or email"
              prepend-icon="mdi-magnify"
              style="width: 400px"
            />
          </div>
          <div class="flex-shrink-0 flex-grow-1">
            <v-select
              v-model="selectedRoleId"
              density="compact"
              hide-details
              :items="roleItems"
              class="px-6"
              label="Filter by Role"
            />
          </div>
          <div class="flex-grow-1"></div>
          <v-btn @click.prevent="bulkUpload()">Bulk Upload</v-btn>
        </div>
      </v-card-text>
    </v-card>
    <mosaic-loading-and-error-cards
      :load="loadStaff"
      object-type="Instructor"
      v-model:trigger-background-load="triggerStaffBackgroundLoad"
    >
      <mosaic-card>
        <mosaic-list :items="slicedStaff" empty-text="There are no Instructors for these filters">
          <template #item="{ item: s }">
            <mosaic-list-item
              prepend-icon="mdi-human-male-board"
              :title="renderStaffInfo(s)"
              :subtitle="
                s.roles
                  .map(x => x.name)
                  .unique()
                  .join(', ')
              "
            >
              <template #information>
                <demo-account-badge v-if="s.is_demo" class="pr-2" />
              </template>
              <template #actions>
                <ndt-icon-button
                  icon="incognito"
                  tooltip="Impersonate"
                  class="mr-2"
                  @click.prevent="impersonate(s.user_id)"
                />
                <ndt-icon-button
                  icon="lock-reset"
                  tooltip="Reset password"
                  class="mr-2"
                  @click.prevent="resetPassword(s)"
                />
                <ndt-icon-button
                  v-if="s.status === 'active'"
                  icon="package-down"
                  tooltip="Archive staff"
                  @click.prevent="updateStaffStatus(s, 'archived')"
                />
                <ndt-icon-button
                  v-else
                  icon="package-up"
                  icon-color="green"
                  tooltip="Activate staff"
                  @click.prevent="updateStaffStatus(s, 'active')"
                />
              </template>
            </mosaic-list-item>
          </template>
        </mosaic-list>
        <v-list>
          <div class="px-6 mt-4">
            Adding instructors here is mainly intended for the initial institution admins. Generally prefer
            impersonating and using the instructors tab unless you know what you're doing.
          </div>
          <v-list-item v-if="institution.cohorts.length > 0">
            <template #prepend>
              <v-avatar>
                <v-icon>mdi-human-male-board</v-icon>
              </v-avatar>
            </template>
            <div class="d-flex align-center">
              <div class="flex-grow-1 pr-4">
                <div class="d-flex align-center">
                  <v-text-field v-model="newStaffEmail" label="Staff email" type="email" />
                  <div class="flex-grow-1">
                    <mosaic-select
                      v-model="newStaffRole"
                      name="newStaffRole"
                      class="pl-2"
                      :items="institutionScopedRoles"
                      item-title="name"
                      item-value="id"
                      label="Role"
                    />
                  </div>
                </div>
                <div class="d-flex align-center">
                  <mosaic-checkbox v-model="newStaffIsDemo" class="pr-2" label="Demo account" no-icon />
                  <mosaic-help>
                    <div>Demo accounts are intended for testing or demonstrating functionality</div>
                    <div>and will not trigger any automated emails from Mosaic.</div>
                  </mosaic-help>

                  <mosaic-checkbox
                    v-if="adminInstitution.config.allow_forcing_microsoft_sso"
                    label="Force sign in with Microsoft"
                    v-model="newStaffForceMicrosoftSso"
                  />
                </div>
              </div>
              <v-btn variant="text" ripple :disabled="!canAddStaff" @click.prevent="addStaff()">Add</v-btn>
            </div>
          </v-list-item>
          <div v-else class="px-6 mt-4">Cannot add instructors without first creating a cohort</div>
        </v-list>
        <div>
          <v-alert v-model="instructorCreationErrorAlert" class="pa-4" closable type="error">{{
            instructorCreationErrorMessage
          }}</v-alert>
        </div>
      </mosaic-card>
    </mosaic-loading-and-error-cards>

    <ndt-dialog
      v-model:active="resetPasswordDialog.active"
      title="Reset Password"
      :on-close="resetPasswordDialogClose"
      :error-message="resetPasswordDialog.errorMessage"
    >
      <div class="pt-2">
        <v-text-field v-model="resetPasswordDialog.newPassword" name="password" label="Password" type="text" />
        <v-text-field
          v-model="resetPasswordDialog.newPasswordConfirmation"
          name="password"
          label="Confirm Password"
          type="text"
        />
      </div>
      <template #buttons>
        <v-btn variant="text" ripple :disabled="!canSubmitResetPassword" @click.prevent="submitResetPassword()"
          >Submit</v-btn
        >
      </template>
    </ndt-dialog>

    <ndt-dialog
      v-model:active="updateUserStatusDialog.active"
      title="Update User Status"
      :error-message="updateUserStatusDialog.errorMessage"
      :width="500"
    >
      <span class="pb-2">
        Are you sure you want to set {{ updateUserStatusDialog.userName }}'s account status to
        <b>{{ updateUserStatusDialog.newStatus }}</b
        >?
      </span>
      <v-alert v-if="updateUserStatusDialog.newStatus == 'archived'" type="warning"
        >This will mean the user can no longer log in and will be removed from lists in the instructors area</v-alert
      >
      <v-alert v-if="updateUserStatusDialog.newStatus == 'active'" type="info"
        >This will all the user to log in again and be viewable across the instructors area</v-alert
      >
      <template #buttons>
        <v-btn
          variant="text"
          ripple
          color="error"
          :disabled="updateUserStatusDialog.processing"
          @click.prevent="submitUpdateUserStatus()"
          >Update Status</v-btn
        >
      </template>
    </ndt-dialog>

    <ndt-dialog
      v-model:active="bulkUploadDialog.active"
      title="Bulk Upload Instructors With Trainee Links"
      :error-message="bulkUploadDialog.error"
    >
      <div>
        <p>
          This bulk upload allows creating (or adding existing accounts to a different Institution) of instructor Mosaic
          accounts and/or linking an instructor account with a trainee account.
        </p>
        <div class="d-flex align-center">
          <div class="pr-4">
            The only required column value is instructor email (though you will need to use all the column headers and
            select a role even if you're not linking any trainee accounts). Download the CSV template to see the
            required structure.
          </div>
          <v-btn @click.prevent="downloadUploadTemplate()">Download CSV Template</v-btn>
        </div>
        <div class="d-flex mt-4">
          <div class="w-50">
            <v-file-input
              v-model="bulkUploadDialog.file"
              label="Select CSV of instructors"
              class="mr-2"
              accept="text/csv"
            />
          </div>
          <div class="w-50">
            <v-select
              v-model="bulkUploadDialog.role"
              class="ml-2"
              label="Select role"
              :items="studentScopedRoles"
              item-title="name"
              item-value="id"
            />
          </div>
        </div>

        <mosaic-checkbox
          v-if="adminInstitution.config.allow_forcing_microsoft_sso"
          label="Force sign in with Microsoft"
          v-model="bulkUploadDialog.forceMicrosoftSso"
        />
      </div>
      <template #buttons>
        <v-btn variant="text" ripple color="error" :disabled="!canBulkUpload" @click.prevent="submitBulkUpload()"
          >Bulk Upload</v-btn
        >
      </template>
    </ndt-dialog>
  </div>
</template>

<script>
import { getFullAdminInstitution } from '../../models/institutions';
import NdtIconButton from '../../components/NdtIconButton.vue';
import NdtDialog from '../../components/NdtDialog.vue';
import DemoAccountBadge from '../../components/DemoAccountBadge.vue';
import { impersonate } from '../../utils/auth';
import { generateStongPassword } from '../../utils/passwords';
import { downloadAsCsv } from '../../utils/download-as-file';
import { mapState } from 'vuex';
import Papa from 'papaparse';
import moment from 'moment';

const defaultBulkUploadDialog = {
  active: false,
  processing: false,
  error: '',
  role: null,
  file: null,
  forceMicrosoftSso: false,
};

const bulkUploadHeader = ['Instructor Email', 'Instructor Name', 'Student Email', 'Cohort Name'];

export default {
  name: 'AdminInstitutionStaffPage',
  data: function () {
    return {
      staff: [],
      institutionId: null,
      institution: {
        cohorts: [],
      },
      instructorCreationErrorAlert: false,
      instructorCreationErrorMessage: '',
      newStaffEmail: '',
      newStaffRole: null,
      newStaffIsDemo: false,
      newStaffForceMicrosoftSso: false,
      updateUserStatusDialog: {
        active: false,
        newStatus: '',
        userName: '',
        userId: null,
        errorMessage: '',
        staffOrStudent: '',
      },
      resetPasswordDialog: {
        active: false,
        name: '',
        userId: null,
        processing: false,
        newPassword: '',
        newPasswordConfirmation: '',
        errorMessage: '',
      },
      bulkUploadDialog: {
        ...defaultBulkUploadDialog,
      },
      filterTerm: '',
      selectedRoleId: null,
      triggerStaffBackgroundLoad: false,
    };
  },
  computed: {
    ...mapState(['user', 'adminInstitution']),
    breadcrumbs() {
      return [
        {
          text: this.adminInstitution?.name,
          to: {
            name: 'AdminInstitutionPage',
          },
        },
        {
          text: 'Instructors',
        },
      ];
    },
    roleItems() {
      return [{ title: 'All', value: null }, ...this.adminInstitution.roles.map(x => ({ title: x.name, value: x.id }))];
    },
    canAddStaff() {
      return this.newStaffEmail && this.newStaffRole;
    },
    sortedStaff() {
      const staff = this.staff.filter(
        x =>
          (x.name.toLowerCase().includes(this.filterTerm.toLowerCase()) ||
            x.email.toLowerCase().includes(this.filterTerm.toLowerCase())) &&
          (!this.selectedRoleId || x.roles.map(r => r.id).includes(this.selectedRoleId))
      );
      return staff.sort((a, b) => {
        const aValue = a.status == 'active' ? 2 : 1;
        const bValue = b.status == 'active' ? 2 : 1;
        return bValue - aValue;
      });
    },
    slicedStaff() {
      return this.sortedStaff.slice(0, 20);
    },
    canSubmitResetPassword() {
      return (
        !this.resetPasswordDialog.processing &&
        !!this.resetPasswordDialog.newPassword &&
        !!this.resetPasswordDialog.newPasswordConfirmation
      );
    },
    institutionScopedRoles() {
      return this.institution?.roles.filter(x => x.permissions.every(y => y.institution_scope)) || [];
    },
    studentScopedRoles() {
      return this.institution?.roles.filter(x => x.permissions.every(y => y.student_scope)) || [];
    },
    canBulkUpload() {
      return !this.bulkUploadDialog.processing && this.bulkUploadDialog.role && this.bulkUploadDialog.file;
    },
  },
  watch: {
    adminInstitution(x) {
      this.institution = x;
      this.defaultPassword = x.default_password_stem || '';
    },
  },
  async created() {
    this.institutionId = this.$route.params.institutionId;
    this.institution = this.adminInstitution;
    this.newStaffForceMicrosoftSso = this.adminInstitution.config.allow_forcing_microsoft_sso;
  },
  methods: {
    async loadStaff() {
      let staff = [];
      let offset = 0;
      let firstId = -1;

      while (true) {
        const r = await this.$api.get(`/admin/institutions/${this.adminInstitution.id}/staff?offset=${offset}`);
        if (r.data.length === 0) break;
        // Hopefully redundant check to avoid infinite loops
        if (firstId === r.data[0].id) break;
        firstId = r.data[0].id;

        offset += r.data.length;
        staff = [...staff, ...r.data];
      }

      this.staff = staff;
    },
    async addStaff() {
      try {
        const r = await this.$api.post(`/staff/admin`, {
          email: this.newStaffEmail,
          roleId: this.newStaffRole,
          isDemo: this.newStaffIsDemo,
          forceMicrosoftSso: this.newStaffForceMicrosoftSso,
          institutionId: this.institutionId,
        });
        this.instructorCreationErrorMessage = '';
        this.staff.push({
          id: r.data.id,
          name: r.data.user.name,
          email: r.data.user.email,
          user_id: r.data.user.id,
          is_demo: r.data.user.is_demo,
          status: r.data.user.status,
          roles: [this.institution?.roles.find(x => x.id == this.newStaffRole)],
        });
        this.newStaffEmail = '';
        this.newStaffIsDemo = false;
      } catch (e) {
        console.log(e);
        this.instructorCreationErrorMessage = 'Sorry, cannot create instructor at the moment';
        this.instructorCreationErrorAlert = true;
      }
    },
    updateStaffStatus(staff, status) {
      this.updateUserStatusDialog = {
        active: true,
        userName: staff.name || staff.email,
        userId: staff.user_id,
        errorMessage: '',
        staffOrStudent: 'Instructor',
        newStatus: status,
      };
    },
    async submitUpdateUserStatus() {
      try {
        await this.$api.put(`users/${this.updateUserStatusDialog.userId}/update-user-status`, {
          status: this.updateUserStatusDialog.newStatus,
        });
        this.triggerStaffBackgroundLoad = true;
        this.updateUserStatusDialog.active = false;
      } catch (e) {
        this.updateUserStatusDialog.errorMessage = 'Sorry, cannot update user status right now';
      }
    },
    renderStaffInfo(staff) {
      const status = staff.status === 'archived' ? 'ARCHIVED - ' : '';
      return status + staff.name + (staff.name ? ' - ' : '') + staff.email;
    },
    async impersonate(userId) {
      const response = await this.$api.post(`/impersonate`, { target_id: userId });
      impersonate(response.data.auth_token);
    },
    async resetPassword(staff) {
      const password = await generateStongPassword(this.$api, this.user);
      this.resetPasswordDialog = {
        active: true,
        name: staff.name,
        userId: staff.user_id,
        processing: false,
        newPassword: password,
        newPasswordConfirmation: password,
      };
    },
    submitResetPassword: async function () {
      this.resetPasswordDialog.processing = true;
      try {
        await this.$api.put(`/users/${this.resetPasswordDialog.userId}/admin-reset-password`, {
          password: this.resetPasswordDialog.newPassword,
          password_confirmation: this.resetPasswordDialog.newPasswordConfirmation,
        });
        this.resetPasswordDialog.processing = false;
        this.resetPasswordDialog.active = false;
      } catch (e) {
        console.log(e);
        this.resetPasswordDialog.errorMessage = `Sorry, cannot reset password at the moment`;
      }
    },
    resetPasswordDialogClose() {
      this.resetPasswordDialog = {
        active: false,
        name: '',
        userId: null,
        processing: false,
        newPassword: '',
        newPasswordConfirmation: '',
        errorMessage: '',
      };
    },
    downloadUploadTemplate() {
      downloadAsCsv([bulkUploadHeader], 'instructor-bulk-upload-template');
    },
    bulkUpload() {
      this.bulkUploadDialog.active = true;
      this.bulkUploadDialog.forceMicrosoftSso = this.adminInstitution.config.allow_forcing_microsoft_sso;
      this.bulkUploadDialog.error = '';
    },
    submitBulkUpload() {
      this.bulkUploadDialog.processing = true;
      this.bulkUploadDialog.error = '';
      Papa.parse(this.bulkUploadDialog.file, {
        complete: this.postBulkUpload,
        error: e => {
          this.bulkUploadDialog.error = e;
        },
      });
    },
    async postBulkUpload(parseResult) {
      try {
        if (parseResult.errors.length > 0) {
          this.bulkUploadDialog.error = 'CSV parsing failed - check the js console for more information';
          console.log('Parsing failed', parseResult);
          this.bulkUploadDialog.processing = false;
          return;
        }

        const header = parseResult.data[0];
        if (header.some((h, i) => h !== bulkUploadHeader[i])) {
          this.bulkUploadDialog.error = `CSV header row doesn't match the template - are you sure this file is correct?`;
          this.bulkUploadDialog.processing = false;
          return;
        }

        const data = parseResult.data.slice(1).filter(row => row.some(r => r));
        const r = await this.$api.post(`/institutions/${this.adminInstitution.id}/bulk-upload-staff`, {
          staff: data,
          roleId: this.bulkUploadDialog.role,
          forceMicrosoftSso: this.bulkUploadDialog.forceMicrosoftSso,
        });
        downloadAsCsv(r.data.results, `instructor-bulk-upload-result-${moment().format('DD-MM-YYYY')}`);
        await getFullAdminInstitution(this.adminInstitution.id, this.$api);
        this.bulkUploadDialog = {
          ...defaultBulkUploadDialog,
          active: r.data.errorCount !== 0,
          error:
            r.data.errorCount > 0
              ? `${r.data.errorCount} out of ${
                  r.data.results.length - 1
                } have failed. Check the results CSV that has just been downloaded.`
              : '',
        };
      } catch (e) {
        console.log(e);
        this.bulkUploadDialog.error = 'Sorry, cannot bulk upload instructors at the moment';
      }

      this.bulkUploadDialog.processing = false;
    },
  },
  components: { NdtIconButton, NdtDialog, DemoAccountBadge },
};
</script>
