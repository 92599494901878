<template>
  <mentor-meetings-list
    :student-id="selectedStudent.id"
    :can-edit-mentor-meetings="canEditMentorMeetings"
    :mentor-meeting-click-to="mentorMeetingClickTo"
    @click-create-mentor-meeting="createMentorMeetingClick"
  >
  </mentor-meetings-list>
</template>

<script>
import { mapState } from 'vuex';
import MentorMeetingsList from './MentorMeetingsList.vue';

export default {
  name: 'TutorMentorMeetingsListPage',
  components: { MentorMeetingsList },
  data: () => ({}),
  computed: {
    ...mapState(['user', 'selectedInstitution', 'selectedStudent']),
    breadcrumbs() {
      return [
        {
          text: 'Mentor Meetings',
        },
      ];
    },
    canEditMentorMeetings() {
      return this.userStaffHasPermissionForSelectedStudent('student.mentorMeetings.edit');
    },
  },
  methods: {
    mentorMeetingClickTo(id) {
      return { name: 'MentorMeetingPage', params: { id: id } };
    },
    createMentorMeetingClick() {
      this.$router.push({ name: 'MentorMeetingCreatePage' });
    },
  },
};
</script>
