<template>
  <div>
    <mosaic-cohort-filters-sync-query v-model:group-id="selectedGroupId" v-model:subject-id="selectedSubjectId" />
    <div class="d-flex">
      <mosaic-select
        v-if="groups.length > 0"
        v-model="selectedGroupId"
        name="group-filter"
        :items="filterGroups"
        item-value="id"
        item-title="name"
        label="Filter by Group"
        hide-details
        single-line
        prepend-icon="mdi-account-group"
        class="pl-2"
      />
      <mosaic-select
        v-if="subjects.length > 0"
        label="Filter by Subject"
        v-model="selectedSubjectId"
        name="subject-filter"
        :items="filterSubjects"
        item-value="id"
        item-title="name"
        hide-details
        single-line
        prepend-icon="mdi-pencil-ruler"
        class="pl-2"
      />

      <div class="flex-grow-1"></div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch } from 'vue';
import { mapState } from '@/store/map-store';
import type { CohortStudent } from '@/store/map-store';
import MosaicCohortFiltersSyncQuery from './MosaicCohortFiltersSyncQuery.vue';
import { watchEffect } from 'vue';
import { useCohortStore } from '@/stores/cohort';

const props = defineProps<{
  students: CohortStudent[];
}>();

const emit = defineEmits<{
  'update:filteredStudentIds': [filteredStudentIds: number[]];
  filterStateUpdated: [filterState: { visibleFilters: string[]; values: string[] }];
}>();

defineExpose({ clearCohortFilters });

const { inUseCohortSubjects: subjects } = useCohortStore();

// Monitoring
const { groups } = mapState();
const filterSubjects = computed(() => [{ name: 'All Subjects', id: -1 }, ...subjects.value]);
const filterGroups = computed(() => [{ id: -1, name: 'All Groups' }, ...groups.value]);
const selectedGroupId = ref<number>(-1);
const selectedSubjectId = ref<number>(-1);

function clearCohortFilters() {
  selectedGroupId.value = -1;
  selectedSubjectId.value = -1;
}

const filteredStudentIds = computed(() => {
  const students = props.students;
  const groupId = selectedGroupId.value;
  const subjectId = selectedSubjectId.value;
  return students
    .filter(student => {
      if (groupId === -1) return true;
      return student.student_groups.map(sg => sg.group_id).includes(groupId);
    })
    .filter(student => {
      if (subjectId === -1) return true;
      return student.subject?.id === subjectId || student.additional_subject?.id === subjectId;
    })
    .map(student => student.id);
});

watch(filteredStudentIds, x => {
  emit('update:filteredStudentIds', x);
});

emit('update:filteredStudentIds', filteredStudentIds.value);

const filterState = computed(() => {
  const visibleFilters = [];
  const values = [];
  if (groups.value.length > 0) {
    visibleFilters.push('Group');
    values.push(filterGroups.value.find(g => g.id == selectedGroupId.value)?.name || 'None');
  }
  if (subjects.value.length > 0) {
    visibleFilters.push('Subject');
    values.push(filterSubjects.value.find(s => s.id == selectedSubjectId.value)?.name || 'None');
  }
  return {
    visibleFilters,
    values,
  };
});
watchEffect(() => emit('filterStateUpdated', filterState.value));
</script>
