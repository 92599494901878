export const budActivityStatuses = [
  {
    statusId: 0,
    statusName: 'Not Set',
    statusDescription: `NotSet = Activity has just been created and there is no 'DueDate' specified.`,
    initials: 'NS',
    friendlyDescription: `Not Set - Bud Activity has been created but no due date specified.`,
    sortOrder: 4,
  },
  {
    statusId: 1,
    statusName: 'In Progress',
    statusDescription: `InProgress = Activity has been updated or created with 'DueDate'. `,
    initials: 'IP',
    friendlyDescription: `In Progress - Bud Activity has been set with due date.`,
    sortOrder: 0,
  },
  {
    statusId: 2,
    statusName: 'Submitted',
    statusDescription: `Submitted = when Learner or Trainer has submitted this Activity.`,
    initials: 'S',
    friendlyDescription: `Submitted - Bud Activity has been submitted by the Learner or Trainer.`,
    sortOrder: 3,
  },
  {
    statusId: 3,
    statusName: 'Assessed as Complete',
    statusDescription: `AssessmentComplete = when Activity is fully approved by the Trainer and awaits confirmation from the Learner.`,
    initials: 'AC',
    friendlyDescription: `Assessed as Complete - Bud Activity has been fully approved by the Trainer and awaits confirmation from the Learner.`,
    sortOrder: 2,
  },
  {
    statusId: 4,
    statusName: 'Completed',
    statusDescription: `Complete = when the Learner has verified and confirmed the grade from the Trainer.`,
    initials: 'C',
    friendlyDescription: `Complete - Bud Activity is fully completed.`,
    sortOrder: 1,
  },
] as const;

type BudActivityStatusTuple = typeof budActivityStatuses;
export type BudActivityStatus = BudActivityStatusTuple[number];

export type BudActivity = {
  id: string;
  name: string;
  isExempt: boolean;
} & BudActivityStatus;

export const budReferenceNumberValidation = (x: string) => {
  new RegExp(/^\d{7}$/).test(x);
  return !!x && new RegExp(/^(BUD[0-9]{9}$)/).test(x);
};
export const budReferenceNumberRules = [
  (v: string | number) => {
    if (typeof v === 'number') {
      return 'Bud Reference Number should be BUD followed by 9 digits';
    }
    return budReferenceNumberValidation(v) || !v || 'Bud Reference Number should be BUD followed by 9 digits';
  },
];
