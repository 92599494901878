// Has to be added to component like so:
// {
//   mixins: [unsavedChangesMixin],
//   ...unsavedChangesMixin,
// }
// This is because router component guards can no longer (since vue 3) be defined in mixins https://github.com/vuejs/router/issues/454
// So they need to be added separately via the spread (...) operator. The non-router properties in here (e.g. data) will
// be overwritten by the page component definitions (but still incorporated via the normal mixin syntax).

export default {
  data: () => ({
    dirty: false,
    unsavedChangesDialog: {
      active: false,
      to: null,
      leaving: false,
      navigatingToLogin: false,
    },
  }),
  beforeRouteUpdate(to, from, next) {
    this.onRouteChange(to, from, next);
  },
  beforeRouteLeave(to, from, next) {
    this.onRouteChange(to, from, next);
  },
  watch: {
    dirty(x) {
      if (x) {
        addEventListener('beforeunload', this.beforeUnloadListener, { capture: true });
      } else {
        removeEventListener('beforeunload', this.beforeUnloadListener, { capture: true });
      }
    },
  },
  methods: {
    onRouteChange(to, from, next) {
      if (to.path === from.path || to.name?.toString().endsWith('ErrorPage')) {
        next();
        return;
      }
      if (this.dirty && !this.unsavedChangesDialog.leaving) {
        this.unsavedChangesDialog = {
          active: true,
          to,
          leaving: false,
          navigatingToLogin: to.name == 'LoginPage' || to.name == 'LogoutPage',
        };
        next(false);
      } else {
        removeEventListener('beforeunload', this.beforeUnloadListener, { capture: true });
        next();
        this.unsavedChangesDialog = {
          active: false,
          to: null,
          leaving: false,
          navigatingToLogin: false,
        };
      }
    },
    beforeUnloadListener(event) {
      event.preventDefault();
      event.returnValue = '';
    },
  },
};
