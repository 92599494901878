<template>
  <div>
    <v-card v-if="error">
      <v-card-text>
        <div class="pa-4">{{ error }}</div>
      </v-card-text>
    </v-card>

    <div v-if="!error">
      <v-card class="flex-grow-1 mb-4">
        <v-card-text>
          <div class="d-flex align-center">
            <div v-if="curriculumTheme" class="text-h6">{{ curriculumTheme.code }} - {{ curriculumTheme.name }}</div>
            <div v-if="curriculumTheme" class="d-flex px-2 align-center">
              <template v-if="canEditCurriculum">
                <template v-if="canUpdateVisibility">
                  <v-switch
                    v-if="
                      !curriculumTheme.curriculum_statements.some(x =>
                        x.cohort_course_weeks.some(w => w.status === 'published')
                      )
                    "
                    density="compact"
                    color="primary"
                    hide-details
                    class="mt-0 pl-4"
                    :label="'Visible to ' + traineeNounPluralised()"
                    :model-value="curriculumTheme.student_visible"
                    :disabled="visibilityProcessing"
                    @update:model-value="updateVisibility()"
                  />
                  <v-tooltip v-else location="top">
                    <template #activator="{ props }">
                      <div v-bind="props">
                        <v-switch
                          density="compact"
                          hide-details
                          class="mt-0 pl-4"
                          :label="'Visible to ' + traineeNounPluralised()"
                          :model-value="curriculumTheme.student_visible"
                          :disabled="true"
                        />
                      </div>
                    </template>
                    <div>
                      Cannot be hidden while there are published Course Weeks linked to Curriculum Statements in this
                      Curriculum Theme
                    </div>
                  </v-tooltip>
                </template>

                <div v-else class="pl-4">
                  (This theme will not be visible to {{ traineeNounPluralised() }} until visible Curriculum Statements
                  are added)
                </div>
              </template>
            </div>
            <div class="flex-grow-1"></div>
            <v-btn v-if="canEditCurriculum" ripple @click.prevent="addStatement()">
              <div class="d-flex align-center">
                <v-icon>mdi-plus</v-icon>
                <span>Statement</span>
              </div>
            </v-btn>
          </div>
          <mosaic-info-alert v-if="selectedCohort && !selectedCohort.show_curriculum_for_students" class="mt-2">
            The entire {{ curriculumNoun }} is not currently visible to {{ traineeNounCapitalisedAndPluralised }} in
            this Cohort.
          </mosaic-info-alert>
          <mosaic-info-alert class="mt-2" v-if="canEditCurriculum && usingCohorts.length > 0">
            Edits to this Curriculum will show across all using Cohorts.
          </mosaic-info-alert>
        </v-card-text>
      </v-card>
      <mosaic-snackbar
        v-model="visibilitySnackbar.active"
        :color="visibilitySnackbar.color"
        :message="visibilitySnackbar.message"
      />
      <v-card class="flex-grow-1">
        <v-card-title>Curriculum Statements</v-card-title>
        <v-card-text>
          <mosaic-snackbar v-model="snackbar.active" :color="snackbar.color" :message="snackbar.message" />
          <mosaic-list :items="themeStatements">
            <template #item="{ item: statement }">
              <mosaic-list-item
                :to="statementClickTo(statement.id)"
                icon="mdi-hexagon"
                :title="`${statement.code}: ${statement.statement}`"
                :subtitle="
                  visibilitySubtitle(
                    selectedCohort,
                    fromSnakeCaseToCamelCase(curriculumTheme),
                    fromSnakeCaseToCamelCase(statement),
                    subjectsWithNullOptionAndPrimarySeparation.find(s => s.id === statement.subject_id),
                    traineeNounCapitalisedAndPluralised
                  )
                "
              >
                <template #information>
                  <div class="ml-2">
                    <v-tooltip location="top">
                      <template #activator="{ props }">
                        <v-chip :color="renderVisibilityStatusMessage(statement).color" class="mr-2" v-bind="props">{{
                          renderVisibilityStatusMessage(statement).chip
                        }}</v-chip>
                      </template>
                      <span>{{ renderVisibilityStatusMessage(statement).tooltip }}</span>
                    </v-tooltip>
                  </div>
                </template>

                <template #actions v-if="canEditCurriculum">
                  <ndt-icon-button
                    icon="pencil"
                    tooltip="Edit Curriculum Statement"
                    @click.prevent="editStatement(statement)"
                  />
                  <mosaic-icon-btn
                    icon="delete"
                    :tooltip="linkedStatementMessage(statement) || 'Delete Curriculum Statement'"
                    :disabled="
                      statement.cohort_course_weeks.length > 0 ||
                      statement.cohort_course_week_template_section_curriculum_statements.length > 0 ||
                      statement.has_linked_artefacts
                    "
                    @click.prevent="deleteStatement(statement)"
                  />
                </template>
              </mosaic-list-item>
            </template>
          </mosaic-list>
        </v-card-text>
      </v-card>
    </div>

    <ndt-dialog
      v-model:active="editStatementDialog.active"
      title="Update Statement"
      :error-message="editStatementDialog.errorMessage"
      :on-close="() => onDialogClose(editStatementDialog)"
    >
      <template v-if="editStatementDialog.statement">
        <div style="max-width: 120px">
          <v-text-field
            v-model="editStatementDialog.statement.code"
            prepend-icon="mdi-code-tags"
            label="Code"
            hide-details
          ></v-text-field>
        </div>
        <div class="flex-grow-1 pt-2">
          <v-text-field
            v-model="editStatementDialog.statement.statement"
            prepend-icon="mdi-hexagon"
            label="Statement"
            hide-details
          ></v-text-field>
        </div>
        <div class="pt-2 d-flex align-center">
          <v-select
            v-model="editStatementDialog.statement.subject_id"
            prepend-icon="mdi-pencil-ruler"
            name="edit-student-subject"
            :items="subjectsWithNullOptionAndPrimarySeparation"
            placeholder="Subject"
            label="Subject (or Primary)"
            item-title="name"
            item-value="id"
            max-width="200px"
            class="mr-4"
          >
            <template #item="{ item, props }">
              <v-divider v-if="item.raw.divider" />
              <v-list-item v-else v-bind="props" :title="item.raw.name" />
            </template>
          </v-select>
          <v-tooltip location="bottom">
            <template #activator="{ props }">
              <v-icon color="primary" dark v-bind="props">mdi-information</v-icon>
            </template>
            <div class="text-center">
              Only {{ traineeNounPluralised() }} associated with this subject will see this part of your Curriculum.
              <br />
              If "All Subjects" is selected, all {{ traineeNounPluralised() }} will see this area
            </div>
          </v-tooltip>
        </div>
        <mosaic-radio-buttons
          v-model="editStatementDialog.statement.student_visible"
          class="mt-0 ml-4"
          :radios="statementVisibleRadios"
          inline
        />
      </template>
      <template #buttons>
        <v-btn variant="text" ripple :disabled="!canEditStatement" @click.prevent="submitEditStatement()">Save</v-btn>
      </template>
    </ndt-dialog>
    <ndt-dialog
      v-model:active="addStatementDialog.active"
      title="Add Statement"
      :error-message="addStatementDialog.errorMessage"
      :on-close="() => onDialogClose(addStatementDialog)"
    >
      <template v-if="addStatementDialog.statement">
        <div style="max-width: 120px">
          <v-text-field
            v-model="addStatementDialog.statement.code"
            prepend-icon="mdi-code-tags"
            label="Code"
            hide-details
          ></v-text-field>
        </div>
        <div class="flex-grow-1 pt-2">
          <v-text-field
            v-model="addStatementDialog.statement.statement"
            prepend-icon="mdi-hexagon"
            label="Statement"
            hide-details
          ></v-text-field>
        </div>
        <div class="d-flex pt-4">
          <v-select
            v-model="addStatementDialog.statement.subject_id"
            prepend-icon="mdi-pencil-ruler"
            name="edit-student-subject"
            :items="subjectsWithNullOptionAndPrimarySeparation"
            placeholder="Subject"
            label="Subject (or Primary)"
            item-title="name"
            item-value="id"
            max-width="200px"
            class="mr-4"
          />
          <v-tooltip location="bottom">
            <template #activator="{ props }">
              <v-icon color="primary" dark v-bind="props">mdi-information</v-icon>
            </template>
            <div class="text-center">
              Only {{ traineeNounPluralised() }} associated with this subject will see this part of your Curriculum.
              <br />
              If "All Subjects" is selected, all {{ traineeNounPluralised() }} will see this area
            </div>
          </v-tooltip>
        </div>
      </template>
      <template #buttons>
        <v-btn variant="text" ripple :disabled="!canAddStatement" @click.prevent="submitAddStatement()">Save</v-btn>
      </template>
    </ndt-dialog>

    <ndt-dialog
      v-model:active="deleteStatementDialog.active"
      title="Delete Statement"
      :error-message="deleteStatementDialog.errorMessage"
      :on-close="() => onDialogClose(deleteStatementDialog)"
    >
      <template v-if="!deleteStatementDialog.deleteStatusProcessing">
        <div class="d-flex">Are you sure you want to delete this statement?</div>
        <div
          v-if="
            deleteStatementDialog.statement &&
            (deleteStatementDialog.statement.theory_content_layout.sections.length ||
              deleteStatementDialog.statement.practice_content_layout.sections.length ||
              deleteStatementDialog.statement.curriculum_teaching_strategies.length)
          "
        >
          <br />
          <div>It currently contains:</div>
          <ul class="pt-2">
            <li v-if="deleteStatementDialog.statement.theory_content_layout.sections.length">Theory content</li>
            <li v-if="deleteStatementDialog.statement.practice_content_layout.sections.length">Practice content</li>
            <li v-if="deleteStatementDialog.statement.curriculum_teaching_strategies.length">
              {{
                enumerateItems(
                  deleteStatementDialog.statement.curriculum_teaching_strategies.length,
                  'Teaching Strategy',
                  'Teaching Strategies'
                )
              }}
            </li>
          </ul>
        </div>
      </template>
      <template #buttons>
        <v-btn
          variant="text"
          ripple
          color="error"
          :disabled="deleteStatementDialog.processing"
          @click.prevent="submitDeleteStatement()"
          >Delete</v-btn
        >
      </template>
    </ndt-dialog>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import NdtIconButton from '../../components/NdtIconButton.vue';
import NdtDialog from '../../components/NdtDialog.vue';
import { visibilitySubtitle } from './admin-curriculum-statements';
import { fromSnakeCaseToCamelCase } from '@/utils/transforms';

export default {
  name: 'TutorAdminCurriculumThemePage',
  data: () => ({
    error: '',
    themeId: null,
    editStatementDialog: {
      active: false,
      processing: false,
      statement: null,
      errorMessage: '',
    },
    addStatementDialog: {
      active: false,
      processing: false,
      statement: {
        code: '',
        statement: '',
        subject_id: null,
      },
      errorMessage: '',
    },
    deleteStatementDialog: {
      active: false,
      processing: false,
      statement: null,
      errorMessage: '',
      deleteStatusProcessing: true,
    },
    snackbar: {
      active: false,
      color: 'success',
      message: '',
    },
    visibilitySnackbar: {
      active: false,
      color: 'success',
      message: '',
    },
    visibilityProcessing: false,
    statementVisibleRadios: [
      { label: 'Visible', value: true },
      { label: 'Hidden', value: false },
    ],
  }),
  created() {
    this.themeId = this.$route.params.themeId;
    this.$store.dispatch('loadCurriculum');
    this.$store.dispatch('loadSubjects');
  },
  computed: {
    ...mapState(['curriculumThemes', 'selectedInstitution', 'selectedCurriculum', 'selectedCohort']),
    ...mapGetters(['subjectsWithNullOptionAndPrimarySeparation']),
    canAddStatement() {
      return (
        this.addStatementDialog.statement.code.length &&
        this.addStatementDialog.statement.statement.length &&
        !this.addStatementDialog.processing
      );
    },
    canUpdateVisibility() {
      return (
        this.curriculumTheme.curriculum_statements.length &&
        this.curriculumTheme.curriculum_statements.some(s => s.student_visible)
      );
    },
    usingCohorts() {
      return (
        this.selectedCurriculum?.using_cohorts.filter(c => !this.isCohortPage || c.id !== this.selectedCohort.id) || []
      );
    },
    curriculumTheme() {
      if (!this.curriculumThemes) return null;
      return this.curriculumThemes[this.themeId];
    },
    themeStatements() {
      return this.curriculumTheme?.curriculum_statements || [];
    },
    canEditCurriculum() {
      return this.userStaffHasPermission('curriculum.edit');
    },
    canEditStatement() {
      return (
        !this.editStatementDialog.processing &&
        this.editStatementDialog.statement?.code &&
        this.editStatementDialog.statement?.statement
      );
    },
    isCohortPage() {
      return this.$route.name === 'TutorAdminCohortCurriculumThemePage';
    },
    urlRoot() {
      if (this.isCohortPage) {
        return `/cohorts/${this.selectedCohort.id}`;
      } else {
        return `/institutions/${this.selectedInstitution.id}`;
      }
    },
    breadcrumbs() {
      return [
        {
          text: this.curriculumNoun,
          to: {
            name: this.isCohortPage ? 'TutorAdminCohortCurriculumPage' : 'TutorAdminCurriculumPage',
          },
        },
        {
          text: this.curriculumTheme?.code,
        },
      ];
    },
  },
  methods: {
    visibilitySubtitle,
    fromSnakeCaseToCamelCase,
    statementClickTo(id) {
      const component = this.$route.params.cohortId
        ? 'CohortAdminCurriculumStatementPage'
        : 'InstAdminCurriculumStatementPage';
      return { name: component, params: { themeId: this.curriculumTheme.id, statementId: id } };
    },
    linkedStatementMessage(statement) {
      if (statement.cohort_course_weeks.length > 0) {
        return 'Cannot delete this Curriculum Statement as it is linked to Training Plan Weeks';
      } else if (statement.cohort_course_week_template_section_curriculum_statements.length > 0) {
        return `Cannot delete this Curriculum Statement as it is linked to a Training Plan Week Template`;
      } else if (statement.has_linked_artefacts) {
        return `Cannot delete this Curriculum Statement as it is linked to at least one ${this.traineeNounCapitalised()} artefact (e.g. Target, Reflection, Course Activity)`;
      }
    },
    async updateVisibility() {
      this.visibilityProcessing = true;
      try {
        await this.$api.put(`curriculum-themes/${this.curriculumTheme.id}`, {
          student_visible: !this.curriculumTheme.student_visible,
        });
        this.$store.dispatch('loadCurriculum', { force: true });
      } catch (e) {
        console.log(e);
        this.visibilitySnackbar = {
          active: true,
          color: 'error',
          message:
            (e.response && e.response.data && e.response.data.message) ||
            'Sorry, cannot update visibility at the moment',
        };
      }
      this.visibilityProcessing = false;
    },
    editStatement(statement) {
      this.editStatementDialog = {
        active: true,
        processing: false,
        statement: { ...statement },
        errorMessage: '',
      };
    },
    async deleteStatement(statement) {
      try {
        this.deleteStatementDialog.deleteStatusProcessing = false;
        this.deleteStatementDialog = {
          active: true,
          processing: false,
          statement: { ...statement },
          errorMessage: '',
          deleteStatusProcessing: false,
        };
      } catch (e) {
        this.snackbar = {
          active: true,
          color: 'error',
          message:
            (e.response && e.response.data && e.response.data.message) ||
            'Sorry, cannot delete statement at the moment',
        };
        this.deleteStatementDialog.active = false;
      }
    },
    addStatement() {
      this.addStatementDialog.active = true;
    },
    async submitAddStatement() {
      try {
        this.addStatementDialog.errorMessage = '';
        this.addStatementDialog.processing = true;
        const order = this.themeStatements.length
          ? Math.max.apply(
              null,
              this.themeStatements.map(t => t.theme_order)
            ) + 1
          : 1;
        const r = await this.$api.post('/curriculum-statements', {
          ...this.addStatementDialog.statement,
          curriculum_theme_id: this.curriculumTheme.id,
          curriculum_id: this.curriculumTheme.curriculum.id,
          theme_order: order,
        });
        this.curriculumTheme.curriculum_statements.push(r.data);
        this.addStatementDialog.statement.statement = '';
        this.addStatementDialog.statement.code = '';
        this.addStatementDialog.active = false;
      } catch (e) {
        console.log(e);
        this.addStatementDialog.errorMessage = 'Sorry, cannot add a statement right now';
      }
      this.addStatementDialog.processing = false;
    },
    async submitEditStatement() {
      try {
        this.editStatementDialog.processing = true;
        await this.$api.put(`curriculum-statements/${this.editStatementDialog.statement.id}`, {
          code: this.editStatementDialog.statement.code,
          statement: this.editStatementDialog.statement.statement,
          subject_id: this.editStatementDialog.statement.subject_id,
          student_visible: this.editStatementDialog.statement.student_visible,
        });
        this.$store.dispatch('loadCurriculum', { force: true });
        this.editStatementDialog.processing = false;
        this.editStatementDialog.active = false;
      } catch (e) {
        console.log(e);
        this.editStatementDialog.errorMessage = 'Sorry, cannot update statement at the moment';
        this.editStatementDialog.processing = false;
      }
    },
    async submitDeleteStatement() {
      this.deleteStatementDialog.processing = true;
      try {
        await this.$api.delete(`curriculum-statements/${this.deleteStatementDialog.statement.id}`);
        this.snackbar = {
          active: true,
          color: 'success',
          message: 'Success!',
        };
        await this.$store.dispatch('loadCurriculum', { force: true });

        this.deleteStatementDialog.active = false;
        //if statements for this theme becomes empty of statements due to deletion, need to reset visibility
        if (this.themeStatements.length === 0) {
          await this.$api.put(`curriculum-themes/${this.curriculumTheme.id}`, { student_visible: false });
        }
      } catch (e) {
        console.log(e);
        this.deleteStatementDialog.errorMessage = 'Sorry, cannot delete statement at the moment';
      }

      this.deleteStatementDialog.processing = false;
    },
    renderVisibilityStatusMessage(statement) {
      return statement.student_visible && this.curriculumTheme.student_visible
        ? {
            chip: 'Visible',
            color: 'accent',
            tooltip: 'This statement is visible to ' + this.traineeNounPluralised(),
          }
        : statement.student_visible && !this.curriculumTheme.student_visible
        ? {
            chip: 'Visible',
            color: '',
            tooltip:
              'This statement will be visible to ' + this.traineeNounPluralised() + ' when the theme is made visible',
          }
        : {
            chip: 'Hidden',
            color: '',
            tooltip: 'This statement is not visible to ' + this.traineeNounPluralised(),
          };
    },
    onDialogClose(dialog) {
      dialog.active = false;
      (dialog.processing = false),
        (dialog.statement.code = ''),
        (dialog.statement.name = ''),
        (dialog.statement.id = ''),
        (dialog.errorMessage = '');
    },
  },
  components: { NdtDialog, NdtIconButton },
};
</script>
