<template>
  <div v-if="!disabled" class="d-flex flex-grow-1">
    <slot :disabled="false" />
  </div>
  <v-tooltip v-else :[tooltip]="true" :offset="offset">
    <template #activator="{ props }">
      <div class="flex-grow-1" v-bind="props">
        <slot :disabled="true" />
      </div>
    </template>
    <div v-if="reviewTemplateInUse">Cannot {{ verb }} as template is in use</div>
    <div v-if="reviewTemplateStatementsInUse">Cannot {{ verb }} as statements are in use</div>
  </v-tooltip>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ReviewTemplateReadOnly',
  props: {
    // Should supply one of these
    reviewTemplateInUse: {
      type: Boolean,
      default: false,
    },
    // Should supply one of these
    reviewTemplateStatementsInUse: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: String,
      default: 'bottom',
    },
    offset: {
      type: String,
      default: '0',
    },
    verb: {
      type: String,
      default: 'edit',
    },
  },
  computed: {
    ...mapGetters({
      stateReviewTemplateInUse: 'reviewTemplateInUse',
      stateReviewTemplateStatementsInUse: 'reviewTemplateStatementsInUse',
    }),
    disabled() {
      if (this.reviewTemplateStatementsInUse) return this.stateReviewTemplateStatementsInUse;
      return this.stateReviewTemplateInUse;
    },
  },
};
</script>
