<template>
  <div>
    <v-tabs v-model="internalSelectedTabKey" color="primary">
      <v-tab v-for="h in headers" :key="h.key" class="pr-0" :value="h.key">
        <v-icon v-if="h.icon" class="pr-4">{{ h.icon }} </v-icon>
        <span>{{ h.text }}</span>
        <v-icon v-if="h.dirty" class="mb-2">mdi-circle-small</v-icon>
        <span v-else style="width: 18px"></span>
      </v-tab>
    </v-tabs>
    <slot name="shared-filters"></slot>
    <v-window v-model="internalSelectedTabKey">
      <v-window-item v-for="h in headers" :key="h.key" :value="h.key" class="pt-2">
        <slot :name="h.key"></slot>
      </v-window-item>
    </v-window>
  </div>
</template>

<script setup lang="ts">
import { syncQueryParam } from '@/composables/query';
import { ref, watchEffect } from 'vue';

const props = defineProps<{
  headers: {
    key: string;
    text: string;
    dirty?: boolean;
    icon?: string;
  }[];
}>();
const emit = defineEmits<{
  (e: 'tabSelected', selectedTab: string): void;
}>();

const internalSelectedTabKey = ref(props.headers[0].key);
watchEffect(() => emit('tabSelected', internalSelectedTabKey.value));

syncQueryParam(internalSelectedTabKey, 'tab', 'string');
</script>
