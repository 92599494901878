<template>
  <div>
    <mosaic-list-item
      :key="evidence.id"
      icon="mdi-fingerprint"
      :title="evidence.title"
      :subtitle="renderSubTitle(evidence)"
      :actions="actions"
    >
      <template #information>
        <div class="mr-4">
          {{ standardNounCapitalised + ': ' + standardsInformation(evidence) }}
        </div>
        <template v-if="readOnly">
          <v-tooltip location="bottom">
            <template #activator="{ props }">
              <v-icon class="mx-2" :color="evidence.favourite ? 'accent' : 'secondary'" v-bind="props">
                mdi-star
              </v-icon>
            </template>
            <span>{{ evidence.favourite ? 'Best Evidence' : 'Not marked as Best Evidence' }}</span>
          </v-tooltip>
        </template>
      </template>
    </mosaic-list-item>
    <mosaic-snackbar
      v-model="errorSnackbar.active"
      :color="errorSnackbar.color"
      location="bottom"
      :message="errorSnackbar.message"
    />
    <ndt-dialog v-model:active="descriptionDialog.active" :title="descriptionDialog.title" :width="500">
      <h3 class="h3">Description</h3>
      <p>{{ descriptionDialog.description || 'No description set' }}</p>
    </ndt-dialog>
  </div>
</template>

<script>
import evidenceMethods from '../utils/evidence-methods';
import NdtDialog from './NdtDialog.vue';
import { mapState, mapGetters } from 'vuex';
import config from '../utils/config';
import moment from 'moment';

export default {
  props: {
    evidence: {
      type: Object,
      required: true,
    },
    editEvidence: {
      type: Function,
      default: null,
    },
    deleteEvidence: {
      type: Function,
      default: null,
    },
    canViewReflections: {
      type: Boolean,
      required: true,
    },
  },
  emits: [
    'clickReflection',
    'clickTarget',
    'clickLessonObservation',
    'clickMentorMeeting',
    'clickStudentAssignment',
    'clickFavourite',
  ],
  data: () => ({
    config: config,
    descriptionDialog: {
      active: false,
      description: '',
      title: '',
    },
    errorSnackbar: {
      active: false,
      message: '',
      color: '',
    },
  }),
  computed: {
    ...mapState(['user', 'userStaff', 'selectedInstitution', 'selectedStudent']),
    ...mapGetters(['storageSetUp', 'useOnDemandSharing']),
    readOnly() {
      return !this.user.student;
    },
    canLaunchFiles() {
      return (
        (this.user.student && this.storageSetUp) || (this.user.staff && (this.storageSetUp || this.useOnDemandSharing))
      );
    },
    actions() {
      return [
        {
          icon: this.evidence.favourite ? 'star' : 'star-outline',
          iconColor: this.evidence.favourite ? 'accent' : 'secondary',
          tooltip: this.evidence.favourite ? 'Best Evidence' : 'Mark as best Evidence',
          hide: this.readOnly,
          click: () => this.favourite(this.evidence),
        },
        {
          icon: 'file-document',
          tooltip: this.canLaunchFiles ? 'View file' : 'You must link a storage account to view files',
          disabled: !this.canLaunchFiles,
          hide: !this.evidence.evidence_file,
          click: () => this.showEvidence(this.evidence),
        },
        {
          icon: 'download',
          tooltip: 'Download file',
          disabled: !this.canLaunchFiles,
          hide: !this.evidence.evidence_file || this.selectedInstitution.storage_type !== 'Mosaic',
          click: () => this.downloadEvidenceFile(this.evidence),
        },
        {
          icon: 'mirror',
          tooltip: 'View Reflection',
          hide: !this.evidence.reflection || !this.canViewReflections,
          click: () => this.clickReflection(this.evidence),
        },
        {
          icon: 'near-me',
          tooltip: 'View Target',
          hide: !this.evidence.target,
          click: () => this.clickTarget(this.evidence),
        },
        {
          icon: 'file-eye',
          tooltip: 'View Lesson Observation',
          hide: !this.evidence.lesson_observation,
          click: () => this.clickLessonObservation(this.evidence),
        },
        {
          icon: 'recycle-variant',
          tooltip: 'View Mentor Meeting',
          hide: !this.evidence.mentor_meeting,
          click: () => this.clickMentorMeeting(this.evidence),
        },
        {
          icon: 'clipboard-text',
          tooltip: 'View Course Activity',
          hide: !this.evidence.student_assignment,
          click: () => this.clickStudentAssignment(this.evidence),
        },
        {
          icon: 'pencil',
          tooltip: 'Edit Evidence',
          hide: this.readOnly,
          click: () => this.editEvidence(this.evidence),
        },
        {
          icon: 'delete',
          tooltip: 'Delete Evidence',
          hide: this.readOnly,
          click: () => this.deleteEvidence(this.evidence),
        },
        {
          icon: 'text',
          tooltip: 'Description',
          hide: !this.readOnly,
          click: () => this.showDescription(this.evidence),
        },
      ];
    },
  },
  methods: {
    standardsInformation(evidence) {
      const substandards = evidence.substandards.sortBy(['standard.order', 'order']).map(x => x.code);
      if (substandards.length < 7) {
        return substandards.join(', ');
      } else {
        return `${substandards.slice(0, 4).join(', ')} and ${substandards.length - 4} more`;
      }
    },
    async showEvidence(evidence) {
      await this.openEvidence(evidence, false);
    },
    async downloadEvidenceFile(evidence) {
      await this.openEvidence(evidence, true);
    },
    async openEvidence(evidence, download) {
      try {
        await evidenceMethods.show(evidence, download, this.$api);
      } catch (e) {
        console.log(e);
        this.errorSnackbar = {
          active: true,
          message: 'Sorry, cannot open this file right now',
          color: 'error',
        };
      }
    },

    showDescription(evidence) {
      this.descriptionDialog = {
        active: true,
        title: evidence.title,
        description: evidence.evidence_description,
      };
    },
    favourite(evidence) {
      this.$emit('clickFavourite', evidence);
    },
    isFavourite(evidence) {
      return evidence.favourite;
    },
    renderSubTitle: function (evidence) {
      return evidence.evidence_type + ' - Added ' + moment(evidence.created_at).fromNow();
    },
    clickTarget(evidence) {
      this.$emit('clickTarget', evidence.target);
    },
    clickLessonObservation(evidence) {
      this.$emit('clickLessonObservation', evidence.lesson_observation);
    },
    clickMentorMeeting(evidence) {
      this.$emit('clickMentorMeeting', evidence.mentor_meeting);
    },
    clickStudentAssignment(evidence) {
      this.$emit('clickStudentAssignment', evidence.student_assignment);
    },
    clickReflection(evidence) {
      this.$emit('clickReflection', evidence.reflection);
    },
  },
  components: { NdtDialog },
};
</script>
