<template>
  <ndt-dialog v-model:active="activeNdtDialog" :title="'Link to ' + curriculumNoun" :width="950">
    <v-autocomplete
      v-model="internalCurriculumStatements"
      :label="curriculumNoun"
      :items="curriculumStatementsArray"
      :custom-filter="autocompleteFilter"
      prepend-inner-icon="mdi-magnify"
      :multiple="multiple"
      item-value="id"
      item-title="code"
      color="black"
      return-object
    >
      <template #item="{ item, props }">
        <v-divider v-if="item.raw.divider" />
        <v-list-subheader v-else-if="item.raw.header">
          {{ item.raw.header }}
        </v-list-subheader>
        <v-list-item
          v-else
          v-bind="props"
          :title="item.raw.code + ' - ' + item.raw.statement"
          width="900px"
          :disabled="item.raw.disabled"
        >
          <template #append>
            <v-chip v-if="item.raw.subject_id"
              >{{ subjectName(item.raw.subject_id) }} Only{{
                item.raw.disabled ? ' - incompatible subject' : ''
              }}</v-chip
            >
          </template>
        </v-list-item>
      </template>
      <template #selection="{ item }">
        <v-chip>{{ item.raw.code }}</v-chip>
      </template>
    </v-autocomplete>
    <template #buttons>
      <v-btn variant="text" ripple :disabled="processing" @click.prevent="confirmClicked()"> Confirm </v-btn>
    </template>
  </ndt-dialog>
</template>

<script>
import NdtDialog from './NdtDialog.vue';
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'LinkToCurriculumMultipleAdminDialog',
  props: {
    active: {
      type: Boolean,
      required: true,
    },
    curriculumStatements: {
      type: Array,
      default: null,
    },
    multiple: {
      type: Boolean,
      default: true,
    },
    subjectIds: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['curriculumStatementsSelected', 'update:active'],
  data() {
    return {
      activeNdtDialog: this.active,
      processing: false,
      internalCurriculumStatements: [],
    };
  },
  computed: {
    ...mapState({ curriclumThemes: 'curriculumThemes', storeCurriculumStatements: 'curriculumStatements' }),
    ...mapState(['curriculumThemes', 'selectedInstitution', 'selectedCohort', 'subjects']),
    ...mapGetters([
      'singleStatementPerCurriculumTheme',
      'curriculumStatementsOrderedArray',
      'curriculumThemesOrderedArray',
    ]),
    curriculumStatementsArray() {
      if (!this.storeCurriculumStatements) return [];
      let curriculumStatementsArray = [];
      if (this.singleStatementPerCurriculumTheme) {
        curriculumStatementsArray = this.curriculumStatementsOrderedArray;
      } else {
        this.curriculumThemesOrderedArray.forEach(ct => {
          const filterCode = ct.curriculum_statements.map(x => x.code).join(' ');
          const filterStatement = ct.curriculum_statements.map(x => x.statement).join(' ');
          curriculumStatementsArray.push({ header: ct.name, code: filterCode, statement: filterStatement });
          curriculumStatementsArray.push({ divider: true, code: filterCode, statement: filterStatement });
          ct.curriculum_statements.forEach(cs => {
            curriculumStatementsArray.push({
              ...cs,
              disabled: !(cs.subject_id === null || this.subjectIds.includes(cs.subject_id)),
              hidden: cs.hidden || !this.selectedCohort.show_curriculum_for_students,
            });
          });
        });
      }
      return curriculumStatementsArray;
    },
  },
  watch: {
    curriculumStatements() {
      this.updateInternalCurriculumStatements();
    },
    storeCurriculumStatements() {
      this.updateInternalCurriculumStatements();
    },
    activeNdtDialog(x) {
      this.$emit('update:active', x);
    },
    active(x) {
      this.activeNdtDialog = x;
    },
  },
  created() {
    this.$store.dispatch('loadCurriculum');
    this.$store.dispatch('loadSubjects');
    this.updateInternalCurriculumStatements();
  },
  methods: {
    updateInternalCurriculumStatements() {
      this.internalCurriculumStatements =
        this.curriculumStatements && this.storeCurriculumStatements
          ? this.curriculumStatementsArray.filter(x => this.curriculumStatements.map(x => x.id).includes(x.id))
          : [];
    },
    autocompleteFilter: (x, queryText, item) => {
      return queryText
        .split(' ')
        .every(q => `${item.raw.code} - ${item.raw.statement}`.toLocaleLowerCase().indexOf(q.toLocaleLowerCase()) > -1);
    },
    confirmClicked() {
      this.$emit(
        'curriculumStatementsSelected',
        this.multiple ? this.internalCurriculumStatements : [this.internalCurriculumStatements]
      );
      this.activeNdtDialog = false;
    },
    subjectName(id) {
      return this.subjects.find(x => x.id == id).name;
    },
  },
  components: { NdtDialog },
};
</script>

<style scoped>
.curriculum-statement {
  width: 850px;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  text-align: left;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.curriculum-statement:hover {
  text-overflow: clip;
  white-space: normal;
  -webkit-line-clamp: 4;
}
</style>
