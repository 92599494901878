<template>
  <student-reflections-list-page v-if="isStudent" />
  <tutor-reflections-list-page v-else />
</template>

<script setup lang="ts">
import { useUserStore } from '@/stores/user';
import StudentReflectionsListPage from './StudentReflectionsListPage.vue';
import TutorReflectionsListPage from './TutorReflectionsListPage.vue';

const { isStudent } = useUserStore();
</script>
