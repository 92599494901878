<template>
  <div>
    <v-card class="mb-4">
      <v-card-text>
        <div class="d-flex align-center justify-end pl-2">
          <v-btn ripple @click.prevent="addStandardSet()">
            <div class="d-flex align-center">
              <v-icon>mdi-plus</v-icon>
              <span>Standard Set</span>
            </div>
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
    <v-card>
      <v-card-text>
        <mosaic-list :items="standardSets">
          <template #item="{ item: standardSet }">
            <mosaic-list-item
              icon="mdi-hexagon-multiple"
              :title="standardSet.name"
              :subtitle="`Institutions count: ${standardSet.institutions ? standardSet.institutions.length : ''}`"
              :to="standardSetTo(standardSet.id)"
            >
              <template #actions>
                <ndt-icon-button
                  icon="pencil"
                  tooltip="Edit standard set name"
                  @click.prevent="editStandardSet(standardSet)"
                />
                <ndt-icon-button
                  v-if="standardSet.institutions"
                  icon="delete"
                  tooltip="Delete standard set"
                  :disabled="standardSet.institutions.length > 0"
                  @click.prevent="deleteStandardSet(standardSet)"
                />
              </template> </mosaic-list-item
          ></template>
          <v-list-item>
            <template #prepend>
              <v-avatar>
                <v-icon>mdi-plus-circle-outline</v-icon>
              </v-avatar>
            </template>

            <div class="d-flex align-center">
              <div class="flex-grow-1 mr-2">
                <mosaic-text-field
                  v-model="newStandardSet"
                  no-icon
                  label="Name"
                  name="new-standard-set"
                ></mosaic-text-field>
              </div>

              <v-list-item-action>
                <v-btn :disabled="!newStandardSet.length" @click.prevent="addStandardSet">Add</v-btn>
              </v-list-item-action>
            </div>
          </v-list-item>
        </mosaic-list>
      </v-card-text>
      <v-alert v-if="errorMessage.length" type="error" closable>{{ errorMessage }}</v-alert>
    </v-card>

    <ndt-dialog
      v-model:active="editStandardSetDialog.active"
      title="Update StandardSet Name"
      :error-message="editStandardSetDialog.errorMessage"
      :on-close="onDialogClose"
    >
      <v-text-field v-model="editStandardSetDialog.name" prepend-icon="mdi-pencil" label="Name" type="text" />
      <v-text-field
        v-model="editStandardSetDialog.standardNoun"
        prepend-icon="mdi-pencil"
        label="Standard Noun"
        type="text"
      />
      <v-text-field
        v-model="editStandardSetDialog.standardNounPlural"
        prepend-icon="mdi-pencil"
        label="Standard Noun Plural"
        type="text"
      />
      <template #buttons>
        <v-btn
          variant="text"
          ripple
          :disabled="editStandardSetDialog.processing"
          @click.prevent="submitEditStandardSetName()"
        >
          Save
        </v-btn>
      </template>
    </ndt-dialog>
    <ndt-dialog v-model:active="deleteDialog.active" title="Delete Standard Set" :width="500">
      <span>Are you sure you want to delete "{{ deleteDialog.standardSetName }}"?</span>
      <template #buttons>
        <v-btn
          variant="text"
          ripple
          color="error"
          :disabled="deleteDialog.processing"
          @click.prevent="submitDeleteStandardSet()"
        >
          Delete
        </v-btn>
      </template>
    </ndt-dialog>
  </div>
</template>

<script>
import NdtIconButton from '../../components/NdtIconButton.vue';
import NdtDialog from '../../components/NdtDialog.vue';

export default {
  name: 'AdminStandardSetsPage',
  components: { NdtIconButton, NdtDialog },
  data: function () {
    return {
      standardSets: [],
      newStandardSet: '',
      errorMessage: '',
      deleteDialog: {
        active: false,
        standardName: '',
        standardId: '',
        processing: '',
      },
      editStandardSetDialog: {
        active: false,
        name: '',
        standardNoun: '',
        standardNounPlural: '',
        isCreating: false,
        standardSetId: null,
        errorMessage: '',
        processing: false,
      },
    };
  },
  computed: {
    breadcrumbs() {
      return [{ text: 'Standard Sets' }];
    },
  },

  created: async function () {
    const r = await this.$api.get('/standard-sets');
    this.standardSets = r.data;
  },
  methods: {
    standardSetTo(standardSetId) {
      return { name: 'AdminStandardSetStandardsPage', params: { standardSetId: standardSetId } };
    },

    async addStandardSet() {
      this.editStandardSetDialog = {
        active: true,
        name: '',
        standardNoun: '',
        standardNounPlural: '',
        isCreating: true,
        standardSetId: null,
        errorMessage: '',
        processing: false,
      };
    },
    editStandardSet(standardSet) {
      this.editStandardSetDialog = {
        active: true,
        name: standardSet.name,
        standardNoun: standardSet.standard_noun,
        standardNounPlural: standardSet.standard_noun_plural,
        isCreating: false,
        standardSetId: standardSet.id,
        errorMessage: '',
        processing: false,
      };
    },
    deleteStandardSet(standardSet) {
      this.deleteDialog = {
        active: true,
        standardSetId: standardSet.id,
        standardSetName: standardSet.name,
        processing: false,
      };
    },
    submitDeleteStandardSet() {
      this.deleteDialog.processing = true;
      this.$api.delete(`/standard-sets/${this.deleteDialog.standardSetId}`).then(() => {
        this.deleteDialog.active = false;
        this.standardSets = this.standardSets.filter(t => t.id !== this.deleteDialog.standardSetId);
      });
    },
    async submitEditStandardSetName() {
      this.editStandardSetDialog.processing = true;
      let apiCall;
      if (this.editStandardSetDialog.isCreating) {
        apiCall = x => this.$api.post(`/standard-sets`, x);
      } else {
        apiCall = x => this.$api.put(`/standard-sets/${this.editStandardSetDialog.standardSetId}`, x);
      }
      try {
        const r = await apiCall({
          name: this.editStandardSetDialog.name,
          standard_noun: this.editStandardSetDialog.standardNoun,
          standard_noun_plural: this.editStandardSetDialog.standardNounPlural,
        });
        this.editStandardSetDialog.processing = false;
        const standardSet = r.data;
        const i = this.standardSets.findIndex(t => t.id === standardSet.id);
        this.standardSets.splice(i, 1, standardSet);
        this.onDialogClose();
      } catch (e) {
        this.editStandardSetDialog.errorMessage = `Sorry, cannot ${
          this.editStandardSetDialog.isCreating ? 'add' : 'update'
        } this standard set right now`;
        throw e;
      }
    },
    onDialogClose() {
      this.editStandardSetDialog = {
        active: false,
        name: '',
        standardNoun: '',
        standardNounPlural: '',
        isCreating: false,
        standardSetId: null,
        errorMessage: '',
        processing: false,
      };
    },
  },
};
</script>
