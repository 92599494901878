<template>
  <proficiencies-list v-if="!busy" :topics="topics" :ratings="ratings" :read-only="readOnly" />
</template>

<script>
import ProficienciesList from '../../components/ProficienciesList.vue';
import moment from 'moment';
export default {
  name: 'StudentProficienciesPage',

  components: { ProficienciesList },
  data: function () {
    return {
      busy: true,
      readOnly: true,
      topics: [],
      ratings: [
        {
          int: 0,
          label: 'R',
          color: 'red',
        },
        {
          int: 1,
          label: 'A',
          color: 'yellow',
        },
        {
          int: 2,
          label: 'G',
          color: 'green',
        },
      ],
    };
  },
  computed: {
    breadcrumbs() {
      return [
        {
          text: 'Proficiencies',
        },
      ];
    },
  },
  created: function () {
    this.$api.get(`/proficiencies`).then(r => {
      this.topics = r.data.map(t => ({
        ...t,
        proficiencies: t.proficiencies.map(p => ({
          ...p,
          proficiency_assessments: p.proficiency_assessments.map(a => ({
            ...a,
            last_updated: a.updated_at ? moment(a.updated_at).format('l') : null,
            previous_rating_int: a.rating_int,
          })),
        })),
      }));
      this.busy = false;
    });
    //options: revert to computed and force computed update OR use previous value on the original topics data object
  },
};
</script>
