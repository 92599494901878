<template>
  <mosaic-tooltip-chip :color="chipInfo.color">
    <template #text>
      {{ chipInfo.text }}
    </template>

    <template #tooltip>
      {{ chipInfo.tooltip }}
    </template>
  </mosaic-tooltip-chip>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { mapAssignmentStatusToChip, type AssignmentStatus } from '@/components/library/has-status/has-status';

const props = defineProps<{
  status: AssignmentStatus;
}>();

const chipInfo = computed(() => mapAssignmentStatusToChip(props.status));
</script>
