<template>
  <div style="width: 100%">
    <div data-vjs-player>
      <video ref="playerElement" class="video-js"></video>
    </div>

    <div class="text-right">
      Video not playing? Try <a class="text-primary" style="cursor: pointer" @click="openVideo()">here</a> instead.
    </div>
  </div>
</template>

<script setup lang="ts">
import videojs from 'video-js';
import 'videojs-youtube';
import type Player from 'video-js/dist/types/player';
import { onMounted, onUnmounted } from 'vue';
import 'video-js/dist/video-js.css';
import { ref } from 'vue';
import { watch } from 'vue';

const props = defineProps<{
  url: string;
}>();

const player = ref<Player | null>(null);
const playerElement = ref<HTMLElement | null>(null);

onMounted(() => {
  if (!playerElement.value) {
    throw "Can't find playerElement";
  }

  player.value = videojs(playerElement.value, {
    controls: true,
    autoplay: false,
    preload: 'metadata',
    fluid: true,
    techOrder: ['youtube'],
    sources: [
      {
        src: props.url,
        type: 'video/youtube',
      },
    ],
  });
});

watch(() => props.url, setPlayerSrc);
function setPlayerSrc(src: string) {
  if (src) {
    player.value?.src({
      src: src,
      type: 'video/youtube',
    });
  }
}

async function openVideo() {
  window.open(props.url, '_blank');
}

onUnmounted(() => {
  player.value?.dispose();
});
</script>
