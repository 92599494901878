import { mapGetters } from '@/store/map-store';
import { capitaliseFirstLetters } from '@/utils/text';

export function editTargetTooltip(permitted: PermittedWithReason) {
  return targetActionTooltip('edit', 'edited', permitted);
}

export function deleteTargetTooltip(permitted: PermittedWithReason) {
  return targetActionTooltip('delete', 'deleted', permitted);
}

function targetActionTooltip(action: string, actionPastTense: string, { permitted, reason }: PermittedWithReason) {
  const { reviewNounCapitalisedAndPluralised } = mapGetters();
  if (permitted) return `${capitaliseFirstLetters(action)} Target`;
  switch (reason) {
    case 'cant_edit_targets':
      return `You don't have permissions to ${action} Targets`;
    case 'cant_edit_mentor_meeting_targets':
      return `You don't have permissions to ${action} Mentor Meeting Targets`;
    case 'cant_edit_course_targets':
      return `Course Targets can't be ${actionPastTense}`;
    case 'not_set_by_me':
      return `You can't ${action} Targets set by someone else`;
    case 'review_is_approved':
      return `Targets from approved ${reviewNounCapitalisedAndPluralised.value} can't be ${actionPastTense}`;
    default:
      return `You don't have permissions to ${action} this Target`;
  }
}

interface PermittedWithReason {
  permitted: boolean;
  reason: string;
}

export const targetStatuses = ['active', 'completed', 'approved'] as const;
export type TargetStatus = (typeof targetStatuses)[number];
export const targetStatusItems = targetStatuses.map(s => ({ value: s, title: capitaliseFirstLetters(s) }));
