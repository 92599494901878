<template>
  <div>
    <mosaic-tab-card-page object-type="Instructor" :title="title" :headers="tabHeaders" :load="load">
      <template
        #subtitle
        v-if="
          hasPermissionForSelectedInstitution('staff.training.edit') &&
          staffTraining?.totalDuration &&
          !selectedInstitution.config.early_careers
        "
      >
        <staff-training-subtitle :total-duration="staffTraining.totalDuration" />
      </template>

      <template #details-tab-item>
        <div v-if="staff" class="px-2">
          <div class="d-flex align-center">
            <v-text-field
              v-model="email"
              prepend-icon="mdi-at"
              label="Email"
              required
              :rules="[isValidEmail]"
              type="text"
              :disabled="staff.user.activated"
            />
            <account-locked-chip v-if="staff.user.account_locked" class="pl-2" />
            <email-status-chip
              v-if="isEmailVerificationOnForSelectedInstitution && !staff.user.is_demo"
              class="ml-2"
              :email-verified="staff.user.email_verified"
              :email-bounced="staff.user.email_bounced"
              :opted-out-of-emails="staff.user.opted_out_of_emails"
            />
            <div v-if="staff.user.activated" class="ml-2">
              <mosaic-icon-button icon="pencil" tooltip="Edit email" @click.prevent="editEmail"></mosaic-icon-button>
            </div>
          </div>
          <v-text-field v-model="name" prepend-icon="mdi-account" label="Name" required type="text" />
          <div class="d-flex align-center">
            <div>
              <mosaic-disabled-tooltip
                :disabled="!staff.user.is_demo && staff.user.in_multiple_institutions"
                tooltip="This Instructor shares their account with another Institution and demo Instructors cannot be in multiple Institutions"
              >
                <template #default="{ disabled }">
                  <mosaic-checkbox
                    v-model="isDemo"
                    no-icon
                    class="pr-2"
                    label="Demo account"
                    name="is-demo"
                    hide-details
                    :disabled="disabled"
                  />
                </template>
              </mosaic-disabled-tooltip>
            </div>
            <mosaic-help>
              <div>Demo accounts are intended for testing or demonstrating functionality</div>
              <div>and will not trigger any automated emails from Mosaic.</div>
            </mosaic-help>
          </div>
          <force-microsoft-sso-checkbox
            v-model="forceMicrosoftSso"
            :disabled="staff.user.force_microsoft_sso && staff.user.in_multiple_institutions"
          />
          <mosaic-error-alert :override-error-message="updateError" />
          <div class="text-right">
            <v-btn ripple :disabled="processing" :loading="processing" color="primary" @click.prevent="updateStaff()"
              >Save</v-btn
            >
          </div>
        </div>
        <mosaic-snackbar v-model="updateSuccessSnackbar" color="success" :message="updateSuccess" />
      </template>

      <template #roles-tab-item>
        <mosaic-list v-if="staff" :items="sortedStaffRoles" class="pt-0">
          <template #item="{ item: sr }">
            <mosaic-list-item icon="mdi-account-multiple-check" :title="sr.role.name">
              <template #subtitle>
                <mosaic-router-link
                  v-if="sr.student && hasPermission(userStaff, 'Admin')"
                  :to="{
                    name: 'TutorStudentDetailsPage',
                    params: { studentId: sr.student.id },
                  }"
                >
                  {{ sr.student.name || sr.student.email }}
                </mosaic-router-link>
                <mosaic-router-link
                  v-else-if="
                    sr.school &&
                    (hasPermissionForSchool(userStaff, 'schools.edit', sr.school) ||
                      hasPermissionForSchool(userStaff, 'schools.view', sr.school))
                  "
                  :to="{
                    name: 'TutorSchoolEditPage',
                    params: { schoolId: sr.school.id },
                  }"
                >
                  {{ sr.school.name }}
                </mosaic-router-link>
                <mosaic-router-link
                  v-else-if="sr.cohort && hasPermissionForCohort(userStaff, 'Admin', sr.cohort)"
                  :to="{
                    name: 'TutorStudentListPage',
                    params: { cohortId: sr.cohort.id },
                  }"
                >
                  {{ sr.cohort.name }}
                </mosaic-router-link>
                <span v-else>{{ staffRoleSubtitle(sr) }}</span>
              </template>

              <template #information v-if="sr.cohort && sr.cohort.status === 'closed'">
                <v-chip color="secondary">Cohort Closed</v-chip>
              </template>

              <template #actions>
                <mosaic-icon-button
                  v-if="
                    staff.user.id !== user.id || !(sr.role.permissions.map((x: StaffRole['role']['permissions'][number]) => x.name).includes('Admin') && !sr.cohort)
                  "
                  icon="delete"
                  tooltip="Remove Role"
                  @click.prevent="
                    deleteRoleDialog.active = true;
                    deleteRoleDialog.staffRole = sr;
                  "
                />
              </template>
            </mosaic-list-item>
          </template>
        </mosaic-list>
        <div class="d-flex align-top justify-end">
          <v-btn ripple color="primary" @click.prevent="addRole()">
            <div class="d-flex align-center">
              <v-icon>mdi-plus</v-icon>
              <span>Role</span>
            </div>
          </v-btn>
        </div>
      </template>

      <template #training-requirements-tab-item>
        <staff-training-requirements-tab
          :frameworks="frameworks"
          :click-theme-to="theme => clickThemeTo(theme)"
          :load-error="staffTrainingLoadError"
        />
      </template>

      <template #training-modules-tab-item>
        <staff-training-modules-tab
          :module-completions="moduleCompletions"
          :click-module-to="module => clickModuleTo(module)"
          :load-error="staffTrainingLoadError"
          :reload="() => loadTraining(true, false)"
        />
      </template>

      <template #manual-training-records-tab-item>
        <staff-training-manual-records-tab
          :training-records="trainingRecords"
          :click-record-to="record => clickRecordTo(record)"
          :create-training-record-to="{
            name: 'StaffTrainingRecordCreatePage',
            params: { staffId },
          }"
          :load-error="staffTrainingLoadError"
          :reload="() => loadTraining(true, false)"
        />
      </template>

      <template #certificates-tab-item>
        <div v-if="staffTrainingLoadError" class="mt-2">
          Sorry, cannot load this Instructor's Certificates right now. If this problem persists, please contact support.
        </div>
        <staff-training-certificates-tab
          v-else-if="staffTraining"
          :staff-training="staffTraining"
          :click-certificate-to="
            c => ({
              name: 'StaffTrainingCertificateEditPage',
              params: { certificateId: c.id, staffId },
            })
          "
          :create-certificate-to="{
            name: 'StaffTrainingCertificateCreatePage',
          }"
          :certificate-deleted="() => loadTraining(true, true)"
        />
      </template>

      <template #events-tab-item>
        <div v-if="staffTrainingLoadError" class="mt-2">
          Sorry, cannot load this Instructor's Events right now. If this problem persists, please contact support.
        </div>
        <staff-training-events-tab
          v-else-if="staffTraining"
          :staff-training="staffTraining"
          :click-event-to="
            e => ({
              name: 'StaffTrainingEventPage',
              params: { eventId: e.id, staffId },
            })
          "
        />
      </template>
    </mosaic-tab-card-page>

    <mosaic-dialog v-model:active="addRoleDialog.active" title="Add Role" :error-message="addRoleDialog.error">
      <role-select
        v-model:role="addRoleDialog.selectedRole"
        @update:scope="addRoleDialog.selectedScope = $event"
        @update:no-student-yet-cohort-id="addRoleDialog.selectedNoStudentYetCohortId = $event"
        @update:valid="addRoleDialog.roleAndScopeValid = $event"
      ></role-select>
      <template #buttons>
        <v-btn
          variant="text"
          ripple
          :disabled="!canSubmitAddRole"
          :loading="addRoleDialog.processing"
          @click.prevent="submitAddRole()"
          >Save</v-btn
        >
      </template>
    </mosaic-dialog>

    <mosaic-dialog v-model:active="deleteRoleDialog.active" title="Remove Role" :error-message="deleteRoleDialog.error">
      <div>
        Are you sure you want to remove the "{{ deleteRoleDialog.staffRole.role.name }}" role from this instructor?
      </div>
      <template #buttons>
        <v-btn
          variant="text"
          ripple
          color="error"
          :disabled="deleteRoleDialog.processing"
          @click.prevent="submitDeleteRole()"
        >
          Delete
        </v-btn>
      </template>
    </mosaic-dialog>

    <mosaic-dialog
      v-model:active="editEmailDialog.active"
      :error-message="editEmailDialog.errorMessage"
      title="Edit Email"
    >
      <v-alert type="warning"
        >The Instructor's email is used to login. Please let them know if you change it. They will need to verify this
        email when they next login.</v-alert
      >
      <v-text-field v-model="editEmailDialog.email" label="Email" />
      <template #buttons>
        <v-btn
          variant="text"
          ripple
          :disabled="editEmailDialog.processing || !editEmailDialog.email"
          @click.prevent="submitEditEmail()"
          >Save</v-btn
        >
      </template>
    </mosaic-dialog>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { mapState, mapActions, mapGetters } from '@/store/map-store';
import EmailStatusChip from '@/components/user/EmailStatusChip.vue';
import MosaicDialog from '@/components/library/dialogs/MosaicDialog.vue';
import RoleSelect from '@/components/RoleSelect.vue';
import MosaicIconButton from '@/components/library/buttons/MosaicIconButton.vue';
import { isValidEmail } from '@/utils/validations';
import AccountLockedChip from '@/components/user/AccountLockedChip.vue';
import { useRoute } from 'vue-router';
import axios from 'axios';
import { hasPermissionForCohort, hasPermissionForSchool } from '@/mixins/global-mixins';
import { setBreadcrumbs } from '@/utils/breadcrumbs';
import StaffTrainingRequirementsTab from '@/pages/staff-training/StaffTrainingRequirementsTab.vue';
import StaffTrainingModulesTab from '@/pages/staff-training/StaffTrainingModulesTab.vue';
import StaffTrainingManualRecordsTab from '@/pages/staff-training/StaffTrainingManualRecordsTab.vue';
import StaffTrainingCertificatesTab from '@/pages/staff-training/StaffTrainingCertificatesTab.vue';
import StaffTrainingEventsTab from '@/pages/staff-training/StaffTrainingEventsTab.vue';
import StaffTrainingSubtitle from '@/pages/staff-training/StaffTrainingSubtitle.vue';
import { validateEmail } from '@/utils/email';
import { useApi } from '@/composables/api';
import { useStaffTrainingStore, type StaffTraining } from '@/stores/staff-training';
import { parseRouteId } from '@/composables/vue-router';
import { withProcessingAndError } from '@/composables/processing-and-errors';
import { hasPermissionForSelectedInstitution } from '@/composables/permission';
import { staffTrainingTabHeaders } from '@/pages/staff-training/staff-training';
import { useInstitutionStaffStore } from '@/stores/institution-staff';
import ForceMicrosoftSsoCheckbox from '@/components/ForceMicrosoftSsoCheckbox.vue';
import type { SelectedStaff, StaffRole } from '@/stores/staff';

const { selectedInstitution, userStaff, user } = mapState();
const { loadRoles, refreshUser } = mapActions();
const {
  actions: { clearInstitutionStaff },
} = useInstitutionStaffStore();
const route = useRoute();
const staffId = parseRouteId('id');
const api = useApi();

// Staff setup

async function load() {
  await loadStaff();
  await loadTraining(false, false);
}

const title = computed(() => (staff.value ? staff.value.user.name : ''));
const staff = ref<SelectedStaff | null>(null);
const email = ref('');
const name = ref('');
const isDemo = ref(false);
const forceMicrosoftSso = ref(false);
const staffRoles = ref<StaffRole[] | []>([]);
const detailsDirty = computed(() => {
  return (
    email.value !== staff.value?.user.email ||
    name.value !== staff.value?.user.name ||
    isDemo.value !== staff.value?.user.is_demo
  );
});

function updateStaffData(data: SelectedStaff) {
  staff.value = data;
  email.value = data.user.email;
  name.value = data.user.name;
  isDemo.value = data.user.is_demo;
  forceMicrosoftSso.value = data.user.force_microsoft_sso;
  staffRoles.value = data.staff_roles.sortBy(['created_at'], ['desc']);
}
async function loadStaff() {
  const r = await api.get<SelectedStaff>(`/staff/${staffId.value}`);
  updateStaffData(r.data);
}

// Staff Training
const {
  assignedStaffTrainingFrameworks: frameworks,
  staffTrainingModuleCompletions: moduleCompletions,
  staffTrainingRecords: trainingRecords,
  staffTraining,
  actions: { loadStaffTraining },
} = useStaffTrainingStore();

const { action: loadTraining, error: staffTrainingLoadError } = withProcessingAndError(
  (force: boolean, forUser: boolean) =>
    forUser
      ? Promise.all(user.value.staff?.map(staff => loadStaffTraining(staff.id, true)) || [])
      : loadStaffTraining(staffId.value, force || false)
);

function clickThemeTo(
  theme: StaffTraining['staffTrainingFrameworks'][number]['staffTrainingCompetencyThemes'][number]
) {
  return {
    name: 'TutorStaffTrainingCompetencyThemePage',
    params: { themeId: theme.id.toString(), staffId: staff.value!.id.toString() },
    query: { fromTab: 'training-requirements' },
  };
}

function clickModuleTo(module: StaffTraining['staffTrainingModuleCompletions'][number]['staffTrainingModule']) {
  return {
    name: 'TutorStaffTrainingModulePage',
    params: { moduleId: module.id.toString(), staffId: staff.value!.id.toString() },
    query: { fromTab: 'training-modules' },
  };
}

function clickRecordTo(record: StaffTraining['staffTrainingRecords'][number]) {
  return {
    name: 'StaffTrainingRecordEditPage',
    params: { recordId: record.id.toString(), staffId: staff.value!.id.toString() },
  };
}

// Tab setup
const tabHeaders = computed(() => {
  let headers = [
    { key: 'details', text: `Details`, dirty: detailsDirty.value },
    { key: 'roles', text: 'Roles' },
  ];
  if (!selectedInstitution.value.config.early_careers && hasPermissionForSelectedInstitution('staff.training.edit')) {
    headers = headers.concat(staffTrainingTabHeaders);
  }
  return headers;
});

setBreadcrumbs(
  computed(() => [
    {
      text: 'Instructors',
      to: {
        name: 'InstitutionStaffListPage',
      },
    },
    {
      text: staff?.value?.user.name || staff?.value?.user.email || '',
    },
  ])
);

// Roles

loadRoles();

const sortedStaffRoles = computed(() => staffRoles.value.sortBy('role.name'));

type AddRoleDialog = {
  active: boolean;
  error: string;
  processing: boolean;
  selectedRole?: StaffRole['role'];
  selectedScope: {
    type: string;
    id: number;
  } | null;
  selectedNoStudentYetCohortId: number | null;
  roleAndScopeValid: boolean;
};
type DeleteRoleDialog = {
  active: boolean;
  error: string;
  processing: boolean;
  staffRole: StaffRole;
};

const addRoleDialogDefaults: AddRoleDialog = {
  active: false,
  error: '',
  processing: false,
  selectedRole: undefined,
  selectedScope: null,
  selectedNoStudentYetCohortId: null,
  roleAndScopeValid: false,
};
const canSubmitAddRole = computed(() => !addRoleDialog.value.processing && addRoleDialog.value.roleAndScopeValid);

const { traineeNounCapitalised } = mapGetters();

function staffRoleSubtitle(staffRole: StaffRole) {
  if (staffRole.cohort) return staffRole.cohort.name;
  if (staffRole.no_student_yet_cohort)
    return `${staffRole.no_student_yet_cohort.name} (no ${traineeNounCapitalised.value()} yet)`;
  if (staffRole.school) return staffRole.school.name;
  return selectedInstitution.value.name;
}
const addRoleDialog = ref<AddRoleDialog>({ ...addRoleDialogDefaults });
const deleteRoleDialog = ref<DeleteRoleDialog>({
  active: false,
  error: '',
  processing: false,
  staffRole: {
    created_at: '',
    id: -1,
    no_student_yet_cohort: null,
    role: {
      name: '',
      id: null,
      permissions: [],
    },
    student: null,
    school: null,
    cohort: null,
  },
});

function addRole() {
  addRoleDialog.value.active = true;
}

async function submitAddRole() {
  addRoleDialog.value.processing = true;
  addRoleDialog.value.error = '';
  try {
    const scope = addRoleDialog.value.selectedScope;
    await api.post(`/staff/${route.params.id}/roles`, {
      roleId: addRoleDialog.value.selectedRole?.id,
      institutionId: scope?.type === 'institution' ? scope.id : null,
      studentId: scope?.type === 'student' ? scope.id : null,
      noStudentYetCohortId:
        scope?.type === 'student' && scope?.id === -1 ? addRoleDialog.value.selectedNoStudentYetCohortId : null,
      cohortId: scope?.type === 'cohort' ? scope.id : null,
      schoolId: scope?.type === 'school' ? scope.id : null,
    });
    loadStaff();
    clearInstitutionStaff();
    if (route.params.id === userStaff.value.id.toString()) {
      refreshUser();
    }
    addRoleDialog.value = {
      ...addRoleDialogDefaults,
    };
  } catch (e) {
    if (axios.isAxiosError(e) && e.response?.status === 409) {
      addRoleDialog.value.error = 'This person already has this role';
    } else {
      console.log(e);
      addRoleDialog.value.error = 'Sorry, cannot add this role at the moment';
    }
    addRoleDialog.value.processing = false;
  }
}
async function submitDeleteRole() {
  deleteRoleDialog.value.processing = true;
  deleteRoleDialog.value.error = '';
  try {
    await api.delete(`/staff/${route.params.id}/roles/${deleteRoleDialog.value.staffRole.id}`);
    loadStaff();
    clearInstitutionStaff();
    if (route.params.id == userStaff.value.id.toString()) {
      refreshUser();
    }
    deleteRoleDialog.value.active = false;
  } catch (e) {
    console.log(e);
    deleteRoleDialog.value.error = 'Sorry, cannot delete this role at the moment';
  }
  deleteRoleDialog.value.processing = false;
}

// Email verification flags

const { isEmailVerificationOnForSelectedInstitution } = mapGetters();

// Update Staff
const updateError = ref('');
const processing = ref(false);
const updateSuccessSnackbar = ref(false);
const updateSuccess = ref('');

async function updateStaff() {
  updateError.value = '';
  processing.value = true;

  try {
    const staffId = route.params.id;
    const r = await api.put<unknown, SelectedStaff>(`/staff/${staffId}`, {
      name: name.value,
      email: email.value,
      is_demo: isDemo.value,
      force_microsoft_sso: forceMicrosoftSso.value,
    });
    updateStaffData(r.data);
    clearInstitutionStaff();
    updateSuccessSnackbar.value = true;
    updateSuccess.value = 'Instructor updated';
  } catch (e: unknown) {
    if (axios.isAxiosError(e) && e.response?.status === 409) {
      updateError.value = 'This email address is already in use';
    } else {
      console.log(e);
      updateError.value = 'Sorry, cannot update this Instructor at the moment';
    }
  }
  processing.value = false;
}

// Edit email

const editEmailDialog = ref({
  active: false,
  email: '',
  errorMessage: '',
  processing: false,
});

function editEmail() {
  editEmailDialog.value = {
    active: true,
    email: email.value,
    errorMessage: '',
    processing: false,
  };
}

async function submitEditEmail() {
  if (!validateEmail(editEmailDialog.value.email)) {
    editEmailDialog.value.errorMessage = 'Please supply a valid email';
    editEmailDialog.value.processing = false;
    return;
  }
  editEmailDialog.value.processing = true;
  try {
    const r = (await api.put(`staff/${route.params.id}`, { email: editEmailDialog.value.email })) as {
      data: SelectedStaff;
    };
    updateStaffData(r.data);
    clearInstitutionStaff();
    if (route.params.id === userStaff.value.id.toString()) {
      refreshUser();
    }
    editEmailDialog.value.active = false;
  } catch (e) {
    if (axios.isAxiosError(e) && e.response?.status === 409) {
      editEmailDialog.value.errorMessage = 'An instructor with that email already exists';
    } else {
      editEmailDialog.value.errorMessage = `Sorry, cannot edit instructor's email at the moment`;
    }
  }
  editEmailDialog.value.processing = false;
}
</script>
