<template>
  <mosaic-inline-creation-list-page
    :object-type="`${cohortCourseWeekNounCapitalised} Template Page`"
    title="Week Template"
    list-title="Pages"
    :load="load"
    :items="weekSections"
    :icon="icons.trainingPlanWeekSection"
    :hide-add="false"
    :can-add-item="canAdd"
    :move-item="moveSectionListItem"
    :add-item="() => addWeekSection()"
    :edit-item-clicked="editSection"
    edit-action="Edit"
    :delete-url-stem="`cohort-course/week-template-sections`"
    v-model:trigger-background-load="triggerBackgroundLoad"
  >
    <template #subtitle
      ><div>
        This {{ cohortCourseWeekNounCapitalised }} Template will be copied to newly created
        {{ cohortCourseWeekNounCapitalisedAndPluralised }} to allow you to set up common content for your Training Plan
      </div></template
    >
    <template #information="{ item }">
      <v-chip v-if="item.subjectId">{{ item.subjectName }} Only</v-chip>
    </template>
    <template #add-item-fields>
      <mosaic-text-field
        prepend-icon="pencil"
        name="new-certificate-type"
        label="Name"
        class="flex-grow-1"
        v-model="newSectionName"
      />
      <mosaic-select
        class="pl-4 pr-1"
        v-model="newSectionSubject"
        name="add-section-subject"
        label="Subject"
        :items="subjects"
        item-title="name"
        item-value="id"
        prepend-icon="mdi-pencil-ruler"
        return-object
        ><template #item="{ item, props }">
          <v-divider v-if="item.raw.divider" />
          <v-list-item v-else v-bind="props" :title="item.raw.name" /> </template></mosaic-select
      ><mosaic-help
        >Selecting a Subject will ensure the Page is only visible to {{ traineeNounPluralised }} linked to the same
        Subject</mosaic-help
      >
    </template>
  </mosaic-inline-creation-list-page>
  <cohort-course-week-section-edit-dialog
    v-model:active="editSectionDialog.active"
    :section="editSectionDialog.section"
    is-template-section
    @save="triggerBackgroundLoad = true"
  />
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { useCohortCourseStore } from '@/stores/cohort-course';
import { useEditWeekSectionDetails } from '@/stores/cohort-course';
import { mapGetters, mapActions } from '@/store/map-store';
import { icons } from '@/utils/icons';
import { useRoute } from 'vue-router';
import { setBreadcrumbs } from '@/utils/breadcrumbs';
import { useApi } from '@/composables/api';
import { useStudentStore } from '@/stores/student';
import CohortCourseWeekSectionEditDialog from './CohortCourseWeekSectionEditDialog.vue';
import { useMoveListItem } from '@/components/library/mosaic-list/list-item-helpers';

const { loadSubjects } = mapActions();
loadSubjects();

const api = useApi();
const {
  cohortCourseWeekNounCapitalisedAndPluralised,
  cohortCourseWeekNounCapitalised,
  selectedCohortCourse,
  actions: { loadCohortCourse },
} = useCohortCourseStore();

const route = useRoute();

const load = async (force?: boolean) => {
  await loadCohortCourse(force);
};

const weekSections = computed(() => {
  if (!selectedCohortCourse.value) return [];
  return selectedCohortCourse.value.cohortCourseWeekTemplateSections.map(s => ({
    ...s,
    hideMove: false,
    disableDelete: false,
    clickTo: {
      name: 'TutorAdminCohortCourseWeekTemplateSectionPage',
      params: {
        weekId: route.params.weekId,
        sectionId: s.id,
      },
    },
    warningMessage: s.contentLayout.sections.every(s => s.items.length === 0)
      ? `This ${cohortCourseWeekNounCapitalised.value} Template Page has no content`
      : s.contentLayout.sections.some(s => s.items.length === 0 && !s.heading)
      ? `This ${cohortCourseWeekNounCapitalised.value} Template Page has empty Sections with no Heading`
      : '',
  }));
});

const { traineeNounPluralised } = useStudentStore();
// Might want to enable this for the general section

const newSectionName = ref('');
interface Subject {
  id: number;
  name: string;
}
const newSectionSubject = ref<Subject | null>(null);

const { subjectsWithNullOptionAndPrimarySeparation: subjects } = mapGetters();
const canAdd = computed(() => !!newSectionName.value);

async function addWeekSection() {
  await api.post(`cohort-course/${selectedCohortCourse.value?.id}/week-template-sections/`, {
    title: newSectionName.value,
    subjectId: newSectionSubject.value?.id,
  });
  await loadCohortCourse(true);
}

const triggerBackgroundLoad = ref(false);
const { editSectionDialog, editSection } = useEditWeekSectionDetails();

// Move Section

const { moveListItem: moveSectionListItem } = useMoveListItem('/cohort-course/week-template-sections', api);

setBreadcrumbs(
  computed(() => [
    {
      text: 'Training Plan',
      to: {
        name: 'TutorAdminCohortCoursePage',
      },
    },
    {
      text: `${cohortCourseWeekNounCapitalised.value} Template`,
    },
  ])
);
</script>
