<template>
  <div>
    <v-card>
      <v-card-title>
        <div class="pl-6">
          {{ templateName }}
        </div>
      </v-card-title>
      <v-card-text>
        <div v-if="!themes.length" class="pl-6">No Themes for this Template</div>
        <div class="pl-6">
          {{ 'Institutions using this template: ' + institutions.map(i => i.name).join(', ') }}
        </div>
        <mosaic-list :items="themes">
          <template #item="{ item: theme, index }">
            <mosaic-list-item icon="mdi-hexagon" :title="theme.code + ': ' + theme.name" :to="themeTo(theme.id)">
              <template #actions>
                <ndt-icon-button
                  icon="chevron-up"
                  tooltip="Change order: move up"
                  @click.prevent="moveUp(theme)"
                  v-if="index != 0"
                />
                <ndt-icon-button
                  icon="chevron-down"
                  tooltip="Change order: move down"
                  @click.prevent="moveDown(theme)"
                  v-if="index != themes.length - 1"
                />
                <ndt-icon-button icon="pencil" tooltip="Edit Curriculum Theme" @click.prevent="editThemeName(theme)" />
                <ndt-icon-button icon="delete" tooltip="Delete Curriculum Theme" @click.prevent="deleteTheme(theme)" />
              </template>
            </mosaic-list-item>
          </template>
        </mosaic-list>
        <v-list-item>
          <span class="text-h6">New Curriculum Theme</span>
          <div class="d-flex">
            <div style="width: 100px">
              <mosaic-text-field v-model="newTheme.code" name="new-code" label="Code" prepend-icon="mdi-hexagon" />
            </div>
            <div class="flex-grow-1 pr-2">
              <mosaic-text-field v-model="newTheme.name" name="new-label" label="Name"></mosaic-text-field>
            </div>
            <v-list-item-action>
              <v-btn :disabled="!canAddTheme" @click.prevent="addTheme">Add</v-btn>
            </v-list-item-action>
          </div>
        </v-list-item>
      </v-card-text>
      <v-alert v-if="errorMessage.length" type="error" closable>{{ errorMessage }}</v-alert>
    </v-card>

    <ndt-dialog
      v-model:active="editThemeDialog.active"
      title="Update Theme Name"
      :error-message="editThemeDialog.errorMessage"
      :on-close="onDialogClose"
    >
      <div class="d-flex">
        <div style="max-width: 100px">
          <v-text-field v-model="editThemeDialog.code" label="Code" hide-details=""></v-text-field>
        </div>
        <div class="flex-grow-1 px-4">
          <v-text-field v-model="editThemeDialog.name" label="Name" hide-details></v-text-field>
        </div>
      </div>
      <template #buttons>
        <v-btn variant="text" ripple :disabled="editThemeDialog.processing" @click.prevent="submitEditThemeName()"
          >Save</v-btn
        >
      </template>
    </ndt-dialog>
    <ndt-dialog v-model:active="deleteDialog.active" title="Delete Theme" :width="500">
      <span>Are you sure you want to delete "{{ deleteDialog.themeName }}"?</span>
      <template #buttons>
        <v-btn
          variant="text"
          ripple
          color="error"
          :disabled="deleteDialog.processing"
          @click.prevent="submitDeleteTheme()"
        >
          Delete
        </v-btn>
      </template>
    </ndt-dialog>
  </div>
</template>

<script>
import NdtIconButton from '../../components/NdtIconButton.vue';
import NdtDialog from '../../components/NdtDialog.vue';

export default {
  name: 'AdminCurriculumThemeTemplatesPage',
  components: { NdtDialog, NdtIconButton },
  data: function () {
    return {
      templateId: null,
      templateName: '',
      themes: [],
      newTheme: {
        code: '',
        name: '',
      },
      errorMessage: '',
      institutions: [],
      deleteDialog: {
        active: false,
        themeName: '',
        themeId: '',
        processing: '',
      },
      editThemeDialog: {
        active: false,
        name: '',
        code: '',
        themeId: null,
        errorMessage: '',
        processing: false,
      },
    };
  },
  computed: {
    canAddTheme() {
      return this.newTheme.code.length && this.newTheme.name.length;
    },
    breadcrumbs() {
      return [
        {
          text: 'Curriculum Templates',
          to: {
            name: 'AdminCurriculumTemplatesPage',
          },
        },
        {
          text: this.templateName,
        },
      ];
    },
  },
  created: async function () {
    await this.getThemes();
    const instutitons_response = await this.$api.get(`/curriculum-template-institutions/${this.templateId}`);
    this.institutions = instutitons_response.data;
  },
  methods: {
    async getThemes() {
      this.templateId = this.$route.params.templateId.toString();
      const r = await this.$api.get(`/curriculum-templates/${this.templateId}`);
      this.templateName = r.data.name;
      this.themes = r.data.curriculum_theme_templates;
    },
    themeTo(themeId) {
      return { name: 'AdminCurriculumStatementTemplatesPage', params: { themeId: themeId } };
    },
    async addTheme() {
      this.errorMessage = '';
      const order = this.themes.length
        ? Math.max.apply(
            null,
            this.themes.map(t => t.order)
          ) + 1
        : 1;
      try {
        const r = await this.$api.post('/curriculum-theme-templates', {
          code: this.newTheme.code,
          name: this.newTheme.name,
          curriculum_template_id: this.templateId,
          order: order,
        });
        this.themes.push(r.data);
        this.newTheme.name = '';
        this.newTheme.code = '';
      } catch (e) {
        this.errorMessage = 'Sorry, cannot add a theme right now';
        throw e;
      }
    },
    editThemeName(theme) {
      this.editThemeDialog = {
        active: true,
        themeId: theme.id,
        ...theme,
      };
    },
    async submitEditThemeName() {
      try {
        this.editThemeDialog.processing = true;
        const r = await this.$api.put(
          `/curriculum-theme-templates/${this.editThemeDialog.themeId}`,
          this.editThemeDialog
        );
        this.editThemeDialog.processing = false;
        const theme = r.data;
        const i = this.themes.findIndex(t => t.id === theme.id);
        this.themes.splice(i, 1, theme);
        this.onDialogClose();
      } catch (e) {
        this.editThemeDialog.errorMessage = 'Sorry, cannot update this template right now';
        throw e;
      }
    },
    deleteTheme(theme) {
      this.deleteDialog = {
        active: true,
        themeId: theme.id,
        themeName: theme.name,
        processing: false,
      };
    },
    submitDeleteTheme() {
      this.deleteDialog.processing = true;
      this.$api.delete(`/curriculum-theme-templates/${this.deleteDialog.themeId}`).then(() => {
        this.deleteDialog.active = false;
        this.themes = this.themes.filter(t => t.id !== this.deleteDialog.themeId);
      });
    },
    onDialogClose() {
      this.editThemeDialog = {
        active: false,
        name: '',
        ThemeId: null,
        errorMessage: '',
        processing: false,
      };
    },
    async moveUp(theme) {
      const ThemeIndex = this.themes.findIndex(t => t.id === theme.id);
      if (ThemeIndex === 0) return;
      const higherTheme = this.themes[ThemeIndex - 1];
      const themesToUpdate = [
        {
          id: theme.id,
          order: theme.order - 1,
        },
        {
          id: higherTheme.id,
          order: higherTheme.order + 1,
        },
      ];
      await this.$api.post(`curriculum-theme-templates/update-ordering`, { themesToUpdate: themesToUpdate });
      await this.getThemes();
    },
    async moveDown(theme) {
      const ThemeIndex = this.themes.findIndex(t => t.id === theme.id);
      if (ThemeIndex === this.themes.length - 1) return;
      const lowerTheme = this.themes[ThemeIndex + 1];
      const themesToUpdate = [
        {
          id: theme.id,
          order: theme.order + 1,
        },
        {
          id: lowerTheme.id,
          order: lowerTheme.order - 1,
        },
      ];
      await this.$api.post(`curriculum-theme-templates/update-ordering`, { themesToUpdate: themesToUpdate });
      await this.getThemes();
    },
  },
};
</script>
