import { mapMutations } from '@/store/map-store';
import type { Ref } from 'vue';
import { ref, watchEffect } from 'vue';
import type { RouteLocationNormalized } from 'vue-router';
import { onBeforeRouteLeave, onBeforeRouteUpdate } from 'vue-router';

export function useUnsavedChanges(dirty: Ref<boolean>) {
  const dialog = ref<{
    active: boolean;
    to: RouteLocationNormalized | null;
    leaving: boolean;
    navigatingToLogin: boolean;
  }>({
    active: false,
    to: null,
    leaving: false,
    navigatingToLogin: false,
  });

  const { stopNavigating } = mapMutations();

  function onRouteChange(to: RouteLocationNormalized, from: RouteLocationNormalized) {
    if (to.path === from.path || to.name?.toString().endsWith('ErrorPage')) {
      return;
    }
    if (dirty.value && !dialog.value.leaving) {
      dialog.value = {
        active: true,
        to,
        leaving: false,
        navigatingToLogin: to.name == 'LoginPage' || to.name == 'LogoutPage',
      };
      // Vue router docs says navigation guard order is: beforeRouteLeave -> beforeEnter -> beforeRouteUpdate
      // Observed behaviour is that beforeRouteLeave happens after beforeEnter (using the composables, but not via the options API - may be different in vue router 4/vue 3)
      // Navigating state is set in beforeEach (earliest global hook to show a spinner to cover work done in other hooks)
      // Therefore need to cancel store navigating state
      stopNavigating();

      return false;
    } else {
      try {
        removeEventListener('beforeunload', beforeUnloadListener, { capture: true });
      } catch (error) {
        // This has caused an error, but unsure what causes it
        // https://penrose-education.sentry.io/issues/4452595002/?project=4505238477275136&query=is%3Aunresolved&referrer=issue-stream&stream_index=1
        // Don't know what the state of this eventListener is when the error is raised
        console.log(error);
      }
      dialog.value = {
        active: false,
        to: null,
        leaving: false,
        navigatingToLogin: false,
      };
    }
  }

  onBeforeRouteUpdate((to: RouteLocationNormalized, from: RouteLocationNormalized) => {
    return onRouteChange(to, from);
  });
  onBeforeRouteLeave((to: RouteLocationNormalized, from: RouteLocationNormalized) => {
    return onRouteChange(to, from);
  });

  function beforeUnloadListener(event: BeforeUnloadEvent) {
    event.preventDefault();
    event.returnValue = '';
  }
  function addListenerToStopBrowserNavigation() {
    addEventListener('beforeunload', beforeUnloadListener, { capture: true });
  }
  function removeListenerToStopBrowserNavigation() {
    removeEventListener('beforeunload', beforeUnloadListener, { capture: true });
  }
  watchEffect(() => {
    if (dirty.value) {
      addListenerToStopBrowserNavigation();
    } else {
      removeListenerToStopBrowserNavigation();
    }
  });

  return { dialog };
}
