import api from '@/entrypoints/application/api';
import { isAxiosError } from 'axios';

export const mosaicUnfinishedJobStatuses = ['queued', 'started'] as const;
type MosaicUnfinishedJobStatusTuple = typeof mosaicUnfinishedJobStatuses;
export type MosaicUnfinishedJobStatus = MosaicUnfinishedJobStatusTuple[number];

export const mosaicFinishedJobStatuses = ['finished', 'errored'] as const;
type MosaicFinishedJobStatusTuple = typeof mosaicFinishedJobStatuses;
export type MosaicFinishedJobStatus = MosaicFinishedJobStatusTuple[number];

export type MosaicJobStatus = MosaicUnfinishedJobStatus | MosaicFinishedJobStatus | 'not_found';

export function mosaicJobFinished(status: MosaicJobStatus): boolean {
  return status === 'finished' || status === 'errored';
}

type UnfinishedResponse =
  | {
      status: 'errored';
      errorCode: string;
      errorMessage: string;
    }
  | {
      status: MosaicUnfinishedJobStatus;
    };

export async function executeMosaicJob<T>(
  action: string,
  url: string,
  body: Record<string, unknown>,
  onSuccess: (pollResult: T) => void,
  onError: (e: { errorCode: string; errorMessage: string }) => void,
  maxAttempts = 30
) {
  try {
    const r = await api.post<unknown, { poll_url: string }>(url, body);
    const pollResult = await api.pollGet<(T & { status: 'finished' }) | UnfinishedResponse>(`${r.data.poll_url}`, {
      retryOnSuccess: r => !mosaicJobFinished(r.data.status),
      maxAttempts,
    });
    if (pollResult.data.status == 'errored') {
      onError(pollResult.data);
    }
    if (pollResult.data.status == 'finished') {
      onSuccess(pollResult.data);
    }
  } catch (e: unknown) {
    if (!isAxiosError(e)) {
      console.log(e);
    }
    onError({ errorCode: 'network_error', errorMessage: `Sorry, cannot ${action} at the moment` });
  }
}
