<template>
  <mosaic-save-dialog
    :active="active"
    title="Edit Course Activity Template"
    object-type="Course Activity Template"
    :save="submitEditTemplate"
    :can-save="canEditTemplate"
    @update:active="emit('update:active', $event)"
    @save="emit('save')"
  >
    <template #default="{ onKeyupEnter }">
      <mosaic-text-field
        v-model="name"
        name="rename-name"
        label="Name"
        prepend-icon="mdi-pencil"
        @keyup.enter="onKeyupEnter"
      />
      <mosaic-select
        v-model="selectedRoleId"
        :items="rolesWithStudent"
        name="role-select"
        item-value="id"
        prepend-icon="mdi-account-multiple-check"
        item-title="pluralisedName"
        label="Who should complete Course Activities created from this Template?"
      />
      <template v-if="template && selectedRoleIdDirty && template.assignmentCount > 0">
        <mosaic-checkbox
          name="update-assignments"
          v-model="updateAssignments"
          label="Update all Course Activities using this Template?"
        />
        <mosaic-alert type="warning" class="mt-2" v-if="updateAssignments">
          <span>
            You are about to change who can complete the live Course Activities using this Template. This will mean that
            {{ originalRole }} will no longer be able to complete these Course Activities.
          </span>
        </mosaic-alert>
      </template>
    </template>
  </mosaic-save-dialog>
</template>

<script setup lang="ts">
import { ref, watchEffect, computed } from 'vue';
import type { AssignmentTemplate } from './assignment-templates';
import { useApi } from '@/composables/api';
import { mapGetters } from '@/store/map-store';

const { rolesWithStudent } = mapGetters();

const selectedRoleId = ref<string | number>('student');

const api = useApi();

const props = defineProps<{
  active: boolean;
  template: AssignmentTemplate | null;
}>();

const originalRole = computed(() => {
  if (!props.template) return '';
  const normalisedRoleId = props.template.roleId ?? 'student';
  return rolesWithStudent.value.find(r => r.id === normalisedRoleId)?.pluralisedName ?? '';
});

const emit = defineEmits<{
  'update:active': [active: boolean];
  save: [void];
}>();

const name = ref('');

watchEffect(() => {
  if (!props.template) return;
  name.value = props.template.name;
  selectedRoleId.value = props.template.isTraineeContributor ? 'student' : props.template.roleId ?? 'student';
});

const updateAssignments = ref(false);

const selectedRoleIdDirty = computed(
  () =>
    (props.template?.isTraineeContributor && selectedRoleId.value != 'student') ||
    (!props.template?.isTraineeContributor && selectedRoleId.value !== props.template?.roleId)
);

const canEditTemplate = computed(
  () => (!!name.value && name.value !== props.template?.name) || selectedRoleIdDirty.value
);

const submitEditTemplate = async () => {
  if (!props.template) return;
  await api.put(`/assignment-templates/${props.template.id}`, {
    name: name.value,
    roleId: selectedRoleId.value === 'student' ? null : selectedRoleId.value,
    isTraineeContributor: selectedRoleId.value === 'student',
    updateAssignments: updateAssignments.value,
  });
  emit('update:active', false);
};
</script>
