<template>
  <div>
    <mosaic-save-card
      :save="save"
      :can-save="canSave"
      object-type="Lesson Observation"
      :is-creating="isCreating"
      :return-to="returnTo"
      :readonly="!editable"
      :preview="preview"
    >
      <mosaic-create-edit-title
        object-type="Lesson Observation"
        :is-creating="isCreating"
        :readonly="!editable"
        :subtitle="
          createdBy && lessonObservation
            ? { action: 'Created', name: createdBy, actionAt: lessonObservation.created_at }
            : undefined
        "
      />
      <div class="py-2">
        <mosaic-text-field
          name="lesson-observation-title"
          :readonly="!editable"
          v-model="title"
          prepend-icon="mdi-pencil"
          label="Title"
          autofocus
          hide-details
        />
        <div
          class="mt-4"
          :class="{
            'grid-container-sm': smallScreen,
            'grid-container': !smallScreen,
          }"
        >
          <div>
            <mosaic-text-field
              name="lesson-observation-class-name"
              :readonly="!editable"
              v-model="className"
              prepend-icon="mdi-google-classroom"
              label="Class Name"
            />
          </div>
          <div>
            <div>
              <mosaic-select
                name="lesson-observation-year-group"
                :readonly="!editable"
                v-model="yearGroup"
                prepend-icon="mdi-account-multiple-check"
                :items="institutionYearGroups"
                label="Year Group"
              ></mosaic-select>
            </div>
          </div>
          <div>
            <div>
              <mosaic-select
                :readonly="!editable"
                name="lesson-observation-observed-by"
                v-model="observedById"
                prepend-icon="mdi-account-multiple-check"
                :items="staff"
                label="Observed By"
                item-title="name"
                item-value="id"
              />
              <mosaic-text-field
                name="lesson-observation-observed-by-other"
                v-if="observedById === 'other'"
                v-model="otherName"
                prepend-icon="mdi-alphabetical-variant"
                label="Observer Name"
                :readonly="!editable"
              />
            </div>
          </div>
          <div>
            <div>
              <mosaic-select
                name="lesson-observation-observed-by-role"
                v-if="availableRoles.length > 0"
                v-model="observedByRole"
                prepend-icon="mdi-account-multiple-check"
                :items="availableRoles"
                label="Role of Observer"
                :readonly="!editable"
              />
            </div>
          </div>
          <div>
            <mosaic-date-picker
              name="lesson-observation-date"
              :readonly="!editable"
              v-model:date="observationDate"
              label="Observation Date"
              :exact-width="false"
              :end-margin="false"
            ></mosaic-date-picker>
          </div>
        </div>
        <mosaic-snackbar
          v-model="errorSnackbar.active"
          location="bottom"
          color="error"
          :message="errorSnackbar.message"
        />
        <div :class="{ 'mt-4': !editable }">
          <div v-if="contentLayout">
            <mosaic-content-layout
              :content-layout="contentLayout"
              get-resource-url-prefix="/lesson-observation-templates/resources"
              get-file-url-prefix="/lesson-observations/files"
              :upload-file-presign-url="`/presign/students/${studentId}/lesson-observation-files`"
              v-model:comments="comments"
              v-model:tasks="tasks"
              v-model:files="contentLayoutFiles"
              :preview="preview"
              :readonly="!editable"
            />
          </div>
          <div v-else class="mb-4">
            <div class="text-h6">Files</div>
            <mosaic-list :items="files">
              <template #item="{ item: file }">
                <mosaic-list-item @click="fileClicked(file)" :title="file.name" icon="mdi-file">
                  <template #actions>
                    <ndt-icon-button icon="download" tooltip="Download file" @click.prevent="downloadFile(file)" />
                    <ndt-icon-button
                      v-if="editable"
                      icon="delete"
                      tooltip="Delete file"
                      @click.prevent="deleteFile(file)"
                    />
                  </template>
                </mosaic-list-item>
              </template>
            </mosaic-list>
            <div v-if="files.length < maxFileCount && editable">
              <v-btn ripple class="mt-2" @click.prevent="addFile()">
                <v-icon>mdi-plus</v-icon>
                <span>File</span>
              </v-btn>
            </div>
          </div>
        </div>
      </div>
      <curriculum-links
        artefact-type="Lesson Observation"
        :selected-curriculum-statements="curriculumStatements"
        :curriculum-statement-id="curriculumStatementId"
        :can-edit="editable"
        @update:link-added="addCurriculumLink"
        @update:link-removed="removeCurriculumLink"
      >
      </curriculum-links>
    </mosaic-save-card>

    <ndt-dialog v-model:active="addFilesDialog.active" title="Add a file" :on-close="addFilesOnClose">
      <mosaic-file-pond-external-storage
        ref="filepond"
        :get-upload-url-and-headers="getUploadUrlAndHeaders"
        :max-files="maxFiles"
        @file-uploaded="fileUploaded"
      ></mosaic-file-pond-external-storage>
      <template #buttons>
        <v-btn variant="text" ripple :disabled="!addFilesDialog.files.length > 0" @click.prevent="confirmAddFile()"
          >Confirm</v-btn
        >
      </template>
    </ndt-dialog>
  </div>
</template>

<script>
import moment from 'moment';
import NdtDialog from '@/components/NdtDialog.vue';
import NdtIconButton from '@/components/NdtIconButton.vue';
import MosaicFilePondExternalStorage from '@/components/file-pond/MosaicFilePondExternalStorage.vue';
import CurriculumLinks from '@/components/CurriculumLinks.vue';
import { mapGetters, mapState } from 'vuex';
import MosaicContentLayout from '@/components/mosaic-content-layout/MosaicContentLayout.vue';
import { fromSnakeCaseToCamelCase } from '@/utils/transforms';
import { mapContentLayoutToCompletionComments, mapContentLayoutToCompletionTasks } from '@/utils/content-layout';

const defaultObservationDate = moment().toISOString();
export default {
  name: 'LessonObservationCard',
  components: { NdtDialog, MosaicFilePondExternalStorage, NdtIconButton, CurriculumLinks, MosaicContentLayout },
  props: {
    isCreating: {
      type: Boolean,
      required: true,
    },
    returnTo: {
      type: Object,
      required: true,
    },
    // create permissions imply you can edit the lesson observations you have created, but no-one elses
    hasCreatePermissions: {
      type: Boolean,
      required: true,
    },
    id: {
      type: String,
      default: null,
    },
    lessonObservation: {
      required: true,
      validator: prop => typeof prop === 'object' || prop === null,
    },
    templateContentLayout: {
      type: Object,
      required: true,
    },
    studentId: {
      type: Number,
      required: true,
    },
    curriculumStatementId: {
      type: String,
      default: null,
    },
    preview: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:dirty', 'update:lessonObservation'],
  expose: ['save'],
  data() {
    return {
      title: '',
      observationDate: defaultObservationDate,
      otherName: '',
      files: [],
      observedById: null,
      observedByRole: '',
      observedByName: '',
      className: '',
      yearGroup: '',
      addFilesDialog: {
        active: false,
        files: [],
      },
      saveSnackbar: false,
      errorSnackbar: {
        active: false,
        message: '',
      },
      curriculumStatements: [],
      maxFileCount: 5,
      contentLayout: null,
      comments: [],
      tasks: [],
      contentLayoutFiles: [],
    };
  },
  watch: {
    lessonObservation(lo) {
      this.setFields(lo);
    },
    observedById() {
      if (!this.observedById) return;
      if (this.editable) {
        if (this.observedById !== (this.lessonObservation?.observed_by?.id || 'other')) {
          this.observedByRole =
            this.availableRoles && this.roles.length && this.observedById != 'other' ? this.availableRoles[0] : null;
          // update to make unselected when other
        } else if (this.lessonObservation) {
          this.observedByRole = this.lessonObservation.observed_by_role;
        }
      }
    },
    dirty(x) {
      this.$emit('update:dirty', x);
    },
  },
  created() {
    this.$store.dispatch('loadRoles');
    this.setFields(this.lessonObservation);
    this.$emit('update:dirty', this.dirty);
  },
  computed: {
    ...mapState(['user', 'roles', 'userStaff']),
    ...mapGetters(['institutionYearGroups', 'studentStaff']),
    canSave() {
      return Boolean(
        this.title &&
          this.observationDate &&
          (this.contentLayout || this.files.length > 0) &&
          this.yearGroup &&
          this.className &&
          this.observedById &&
          (this.otherName || this.observedById != 'other') &&
          this.observedByRole &&
          this.dirty
      );
    },
    dirty() {
      if (this.isCreating) {
        // If lessonObservation has been created then the card is not dirty
        if (this.lessonObservation) return false;
        const curriculumStatementsDirty =
          this.curriculumStatements.length > 1 ||
          (this.curriculumStatements.length === 1 && this.curriculumStatements[0].id != this.curriculumStatementId);

        return Boolean(
          this.title ||
            this.className ||
            this.yearGroup ||
            this.observedById !== this.userStaff?.id ||
            this.observationDate !== defaultObservationDate ||
            this.files.length > 0 ||
            curriculumStatementsDirty ||
            this.contentLayoutFiles.length > 0 ||
            this.tasks.some(t => t.completed) ||
            // TODO: I don't think this takes into account placeholders. Also content-layout.ts doesn't deal with artefacts like Reflections, LOs
            // and MMs where the comments and tasks haven't been pre-created
            this.comments.some(c => c.comment)
        );
      } else {
        const fileIds = this.lessonObservation.lesson_observation_files.map(f => f.file_id);
        const contentLayoutFileIds = this.lessonObservation.lesson_observation_content_layout_files.map(f => f.file_id);
        const contentLayoutFilesDirty =
          this.contentLayoutFiles.length != this.lessonObservation.lesson_observation_content_layout_files.length ||
          this.contentLayoutFiles.some(f => !contentLayoutFileIds.includes(f.fileId));
        const commentsDirty = this.lessonObservation.lesson_observation_comments.some(
          c => this.comments.find(x => x.id === c.id)?.comment !== c.comment
        );
        const tasksDirty = this.lessonObservation.lesson_observation_tasks.some(
          t => this.tasks.find(x => x.id === t.id)?.completed !== t.completed
        );

        const curriculumStatementIds = this.lessonObservation.curriculum_statements.map(cs => cs.id);
        return (
          this.title !== this.lessonObservation.title ||
          this.className !== this.lessonObservation.class_name ||
          this.yearGroup !== this.lessonObservation.year_group ||
          (this.observedById === 'other'
            ? this.lessonObservation.observed_by !== null
            : this.observedById !== this.lessonObservation.observed_by?.id) ||
          (this.observedById === 'other'
            ? this.observedByName !== this.lessonObservation.observer_name
            : this.observedByName !== this.lessonObservation.observed_by?.name) ||
          this.observedByRole !== this.lessonObservation.observed_by_role ||
          this.observationDate !== this.lessonObservation.observation_date ||
          this.files.length !== fileIds.length ||
          this.files.some(f => !fileIds.includes(f.id)) ||
          this.curriculumStatements.length !== curriculumStatementIds.length ||
          this.curriculumStatements.some(cs => !curriculumStatementIds.includes(cs.id)) ||
          contentLayoutFilesDirty ||
          commentsDirty ||
          tasksDirty
        );
      }
    },
    editable() {
      if (this.isCreating) return true;
      return this.hasCreatePermissions && this.createdByMe;
    },
    createdBy() {
      if (!this.lessonObservation?.created_by) return this.user.student ? 'Me' : this.traineeNounCapitalised();
      if (this.userStaff && this.lessonObservation.created_by.id == this.userStaff.id) return 'Me';
      return this.lessonObservation.created_by.name;
    },
    createdByMe() {
      return this.createdBy === 'Me';
    },
    staff() {
      if (this.preview) return [{ id: 1, name: 'Dummy Mentor', roles: [{ id: 1, name: 'Mentor' }] }];
      // this.studentStaff is null when using the student picker
      if (!this.studentStaff) return [];
      const savedObservedById = this.lessonObservation?.observed_by?.id || 'other';
      const savedRole = this.lessonObservation?.observed_by_role;
      const other = {
        id: 'other',
        name: 'Other',
        role: null,
      };
      if (
        !savedObservedById ||
        savedObservedById === 'other' ||
        this.studentStaff.some(x => x.id === savedObservedById && x.roles.some(r => r.name === savedRole))
      ) {
        return this.studentStaff.map(x => ({ ...x, name: x.name || x.email })).concat(other);
      }
      // We only show staff that have access to the student, but if the current observer no longer
      // has access to the student (or no longer has the saved role) then they need adding to the list
      return [
        {
          id: savedObservedById,
          name: this.lessonObservation.observed_by.name,
          roles: [{ name: savedRole }],
        },
      ]
        .concat(this.studentStaff)
        .concat(other);
    },
    observedBy() {
      return this.staff.find(x => x.id === this.observedById);
    },
    availableRoles() {
      if (!this.observedById) return [];
      if (this.observedById === 'other') return this.roles.map(x => x.name);
      return this.staff.filter(x => x.id === this.observedById).flatMap(x => x.roles.map(r => r.name));
    },
    maxFiles() {
      return this.maxFileCount - this.files.length;
    },
  },
  methods: {
    setFields(lo) {
      if (!lo) {
        this.observedById = this.preview ? 1 : this.userStaff?.id;
        this.contentLayout = this.templateContentLayout;
        this.comments = mapContentLayoutToCompletionComments(this.contentLayout, id => ({
          lessonObservationCommentId: id,
        }));
        this.tasks = mapContentLayoutToCompletionTasks(this.contentLayout, id => ({
          lessonObservationTaskId: id,
        }));
        this.contentLayoutFiles = [];
      } else {
        this.title = lo.title;
        this.observationDate = lo.observation_date;
        this.files = lo.lesson_observation_files.map(x => ({ id: x.file_id, name: x.file_name }));
        this.className = lo.class_name;
        this.observedByRole = lo.observed_by_role;
        this.observedById = lo.observed_by?.id || 'other';
        this.otherName = lo.observer_name;
        this.observedByName = lo.observed_by?.name || lo.observer_name;
        this.yearGroup = lo.year_group;
        this.curriculumStatements = [...lo.curriculum_statements];
        this.contentLayout = fromSnakeCaseToCamelCase(lo.content_layout);
        this.comments = fromSnakeCaseToCamelCase(lo.lesson_observation_comments).map(c => ({
          ...c,
          templateId: c.lessonObservationCommentId,
        }));
        this.tasks = fromSnakeCaseToCamelCase(lo.lesson_observation_tasks).map(t => ({
          ...t,
          templateId: t.lessonObservationTaskId,
        }));
        this.contentLayoutFiles = fromSnakeCaseToCamelCase(lo.lesson_observation_content_layout_files).map(f => ({
          ...f,
          templateId: f.lessonObservationFileUploadId,
        }));
      }
    },
    addCurriculumLink(x) {
      this.curriculumStatements.push(x);
    },
    removeCurriculumLink(x) {
      const index = this.curriculumStatements.findIndex(s => s.id === x.id);
      this.curriculumStatements.splice(index, 1);
    },

    async fileClicked(file) {
      await this.openFile(file, false);
    },
    async downloadFile(file) {
      await this.openFile(file, true);
    },
    async openFile(file, download) {
      try {
        const r = await this.$api.get(
          `/students/${this.studentId}/lesson-observations/file/${file.id}?download=${download}`
        );
        window.open(r.data.web_url, '_blank');
      } catch (e) {
        console.log(e);
        let message = '';
        if (e.response?.status === 404) {
          message = 'This file no longer exists';
        } else {
          message = 'Sorry. cannot open this file at the moment';
        }
        this.errorSnackbar = {
          active: true,
          color: 'error',
          message,
        };
      }
    },
    async save() {
      let apiCall;
      let body = {};
      if (this.isCreating) {
        body = {
          contentLayout: this.contentLayout,
        };
        apiCall = x => this.$api.post(`/students/${this.studentId}/lesson-observations`, x);
      } else {
        apiCall = x => this.$api.put(`/lesson-observations/${this.id}`, x);
      }
      const r = await apiCall({
        ...body,
        title: this.title,
        observationDate: this.observationDate,
        yearGroup: this.yearGroup,
        className: this.className,
        observedById: this.observedById === 'other' ? null : this.observedById,
        observedByRole: this.observedByRole,
        observerName: this.observedById === 'other' ? this.otherName : null,
        curriculumStatementIds: this.curriculumStatements.map(s => s.id),
        comments: this.comments.map(c => ({ comment: c.comment, lessonObservationCommentId: c.templateId })),
        tasks: this.tasks.map(t => ({ completed: t.completed, lessonObservationTaskId: t.templateId })),
        files: this.contentLayout
          ? this.contentLayoutFiles.map(f => ({
              fileId: f.fileId,
              title: f.title,
              lessonObservationFileUploadId: f.templateId,
            }))
          : this.files,
      });
      this.saveSnackbar = true;
      this.$emit('update:lessonObservation', r.data);
    },
    addFile() {
      this.addFilesDialog.active = true;
      this.addFilesDialog.files = [];
    },
    confirmAddFile() {
      this.addFilesDialog.active = false;
      this.files = [...this.files, ...this.addFilesDialog.files];
      this.$refs.filepond.onClose();
    },
    async getUploadUrlAndHeaders(cancelToken, contentType, filename) {
      const r = await this.$api.post(
        `/presign/students/${this.studentId}/lesson-observations`,
        { contentType, filename },
        cancelToken
      );
      return r.data;
    },
    fileUploaded(x) {
      this.addFilesDialog.files.push({ id: x.fileId, name: x.title });
    },
    addFilesOnClose() {
      this.$refs.filepond.onClose();
    },
    deleteFile(file) {
      this.files = this.files.filter(x => x.id !== file.id);
    },
  },
};
</script>

<style scoped>
.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 8px;
  column-gap: 16px;
}
.grid-container-sm {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 8px;
}
</style>
