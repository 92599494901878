<template>
  <mosaic-tooltip-chip :color="chipInfo.color">
    <template #text>
      {{ chipInfo.text }}
    </template>

    <template #tooltip>
      {{ chipInfo.tooltip }}
    </template>
  </mosaic-tooltip-chip>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { mapWeekStatusToChip, type WeekStatus } from '@/components/library/has-status/has-status';
import { useStudentStore } from '@/stores/student';

const { traineeNoun } = useStudentStore();

const props = defineProps<{
  status: WeekStatus;
  studentViewer: boolean;
  weekNoun: string;
}>();

const chipInfo = computed(() =>
  mapWeekStatusToChip(props.status, props.studentViewer, traineeNoun.value, props.weekNoun)
);
</script>
