<template>
  <template v-if="selectedInstitution.config.use_bud_integration">
    <mosaic-text-field
      :model-value="budReferenceNumber"
      :readonly="readonly"
      prepend-icon="mdi-account"
      name="bud-reference-number"
      label="Bud Reference Number"
      :rules="referenceRules"
      @update:model-value="emit('update:bud-reference-number', $event)"
    />
    <mosaic-date-picker
      :date="budDob"
      :readonly="readonly"
      name="bud-dob"
      label="Bud Date of Birth"
      view-mode="year"
      :exact-width="false"
      :rules="dobRules"
      @update:date="emit('update:bud-dob', $event)"
    />
  </template>
</template>

<script setup lang="ts">
import { useInstitutionStore } from '@/stores/institution';
import { budReferenceNumberRules } from '@/utils/bud';
import { watchEffect, computed } from 'vue';
import { validationsPass } from '@/utils/validations';

const props = defineProps<{
  readonly?: boolean;
  budDob: string | null;
  budReferenceNumber: string;
}>();

const emit = defineEmits<{
  'update:bud-reference-number': [v: string];
  'update:bud-dob': [v: string];
  'update:valid': [v: boolean];
}>();

const { selectedInstitution } = useInstitutionStore();

const bothPresentOrNeither = computed(
  () => (props.budReferenceNumber && props.budDob) || (!props.budReferenceNumber && !props.budDob)
);

const referenceRules = computed(() => [
  ...budReferenceNumberRules,
  (x: string | number) => !!x || !props.budDob || 'Bud Reference Number is required if Bud DoB is provided',
]);

const dobRules = computed(() => [
  (x: string | number) => !!x || !props.budReferenceNumber || 'Bud DoB is required if Bud Reference Number is provided',
]);

const valid = computed(
  () => !!(bothPresentOrNeither.value && validationsPass(budReferenceNumberRules, props.budReferenceNumber))
);

watchEffect(() => {
  emit('update:valid', valid.value);
});
</script>
