<template>
  <div>
    <div>
      <v-tooltip location="bottom">
        <template #activator="{ props: downloadProps }">
          <mosaic-btn @click.prevent="downloadInstructors()" v-bind="downloadProps" :disabled="props.disable">
            <mosaic-icon icon="mdi-table-arrow-down" color="black" />
          </mosaic-btn>
        </template>
        <span>Download instructors</span>
      </v-tooltip>
      <mosaic-error-snackbar v-model="downloadError" action="download your Instructors" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { mapState, mapGetters } from '@/store/map-store';
import XLSX from 'xlsx';
import moment from 'moment';
import { getEmailStatusChipParams } from '@/utils/email-verification';
import { useQueryStore } from '@/stores/query';
import { computed, ref } from 'vue';
import { formatDate } from '@/mixins/global-mixins';

type Staff = {
  id: number;
  created_at: string;
  storage_email: null | boolean;
  user: {
    id: number;
    name: string;
    email: string;
    activated: boolean;
    staff_ids: number[];
    is_demo: boolean;
    wont_receive_emails: boolean;
    email_verified: boolean;
    opted_out_of_emails: boolean;
    email_bounced: boolean;
    last_active_at: null;
    force_microsoft_sso: boolean;
    in_multiple_institutions: boolean;
    account_locked: boolean;
  };
  roles: [
    {
      id: number;
      name: string;
      initialisedText?: string;
    }
  ];
};

const props = withDefaults(
  defineProps<{
    staff: Staff[];
    disable: boolean;
  }>(),
  {
    staff: () => [] as Staff[],
  }
);

const { queryParams } = useQueryStore();

const { selectedInstitution, selectedCohort } = mapState();
const level = computed(() => (selectedCohort.value ? 'Cohort' : 'Institution'));

export type Role = {
  id: number;
  name: string;
  pluralisedName: string;
  student_scope: boolean;
  permissions: {
    name: string;
  }[];
};
const filterTerm = ref(queryParams.value?.filterTerm);
const selectedRoleId = ref(Number(queryParams.value?.selectedRoleId));
const notLoggedInOnly = ref(queryParams.value?.notLoggedInOnly);

const downloadError = ref(false);

const { roles } = mapState();
const { isEmailVerificationOnForSelectedInstitution } = mapGetters();

const institutionRoles = computed(() => {
  return [{ name: 'All Roles', id: null }, ...roles.value, { name: 'No Role Assigned', id: -1 }];
});

const downloadInstructors = () => {
  try {
    const xlxs = XLSX.utils.book_new();
    const selectedRoleName = selectedRoleId?.value
      ? institutionRoles?.value.find(x => selectedRoleId.value && x.id === selectedRoleId.value)?.name
      : null;

    const filters = [
      ['Search term', 'Role', 'Show logged in users?'],
      [filterTerm.value || 'None', selectedRoleName, !notLoggedInOnly.value],
    ];
    const headers = [
      'Email',
      'Name',
      'Created Date',
      `Roles for this ${level.value}`,
      'Last active on',
      'Storage email',
    ];
    if (isEmailVerificationOnForSelectedInstitution.value) headers.push('Email verification status');

    const data = [
      headers,
      ...props.staff.map(x => [
        x.user.email,
        x.user.name,
        x.created_at,
        [...new Set(x.roles.map(r => r.name))].join(', '),
        !x.user.last_active_at ? 'Has never logged in' : formatDate(x.user.last_active_at),
        !x.storage_email ? 'Storage not set up' : x.storage_email,
        x.user.is_demo
          ? 'Demo user'
          : isEmailVerificationOnForSelectedInstitution.value
          ? getEmailStatusChipParams(x.user.email_verified, x.user.email_bounced, x.user.opted_out_of_emails)
              .friendlyValue
          : null,
      ]),
    ];
    XLSX.utils.book_append_sheet(xlxs, XLSX.utils.aoa_to_sheet(data), 'Instructors');
    XLSX.utils.book_append_sheet(xlxs, XLSX.utils.aoa_to_sheet(filters), `Applied Filters`);
    XLSX.writeFile(
      xlxs,
      `${level.value === 'Cohort' ? selectedCohort.value.name : selectedInstitution.value.name} ${
        level.value
      } Instructors (${moment().format('DD-MM-YYYY')}).xlsx`
    );
  } catch (e) {
    console.log(e);
    downloadError.value = true;
  }
};
</script>
