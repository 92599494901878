<template>
  <ndt-dialog v-model:active="activeNdtDialog" title="Add Evidence" :error-message="errorMessage" :width="950">
    <EvidenceAttributes
      :selected-substandards="inputSelectedSubstandards"
      :title="titlePlaceholder"
      :selected-type="evidenceType"
      :evidence-types="evidenceTypes"
      :given-description="evidenceDescription"
      :can-edit-type="!(targetId || reflectionId || lessonObservationId || mentorMeetingId || studentAssignmentId)"
      @attributes-updated="attributesUpdated"
    />
    <template #buttons>
      <v-btn variant="text" ripple :disabled="processing || !canSubmit" @click.prevent="submitCreateEvidence()"
        >Create</v-btn
      >
    </template>
  </ndt-dialog>
</template>

<script>
import NdtDialog from '../NdtDialog.vue';
import { mapGetters } from 'vuex';
import EvidenceAttributes from './EvidenceAttributes.vue';
import { evidenceTypes, fileEvidenceTypes } from '../../models/evidence-types';

export default {
  name: 'CreateEvidenceDialog',
  props: {
    active: {
      type: Boolean,
      required: true,
    },
    onClose: {
      type: Function,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      default: () => {},
    },
    titlePlaceholder: {
      type: String,
      default: null,
    },
    fileId: {
      type: String,
      default: null,
    },
    reflectionId: {
      type: Number,
      default: null,
    },
    targetId: {
      type: Number,
      default: null,
    },
    lessonObservationId: {
      type: Number,
      default: null,
    },
    mentorMeetingId: {
      type: Number,
      default: null,
    },
    studentAssignmentId: {
      type: Number,
      default: null,
    },
    suggestedCodes: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['update:active'],
  data() {
    return {
      activeNdtDialog: this.active,
      title: '',
      evidenceType: '',
      evidenceDescription: '',
      processing: false,
      errorMessage: '',
      inputSelectedSubstandards: [],
      selectedSubstandards: [],
    };
  },
  created() {
    this.suggestSelectedSubstandards();
  },

  computed: {
    ...mapGetters(['substandards']),
    canSubmit() {
      return !!(this.title && this.selectedSubstandards.length && this.evidenceType);
    },
    evidenceTypes() {
      return this.fileId ? fileEvidenceTypes : evidenceTypes;
    },
  },
  watch: {
    activeNdtDialog(val) {
      this.$emit('update:active', val);
    },
    active(val) {
      this.activeNdtDialog = val ? true : false;
    },
    reflectionId() {
      this.evidenceType = 'Reflection';
    },
    targetId() {
      this.evidenceType = 'Target';
    },
    lessonObservationId() {
      this.evidenceType = 'Lesson Observation';
    },
    mentorMeetingId() {
      this.evidenceType = 'Mentor Meeting';
    },
    studentAssignmentId() {
      this.evidenceType = 'Course Activity';
    },
    suggestedCodes: {
      handler() {
        this.suggestSelectedSubstandards();
      },
      deep: true,
    },
  },
  methods: {
    submitCreateEvidence() {
      this.errorMessage = '';
      this.processing = true;
      this.$api
        .post('/evidence', {
          title: this.title,
          substandards: this.selectedSubstandards.map(s => s.code),
          file_id: this.fileId,
          journal_id: this.reflectionId,
          target_id: this.targetId,
          lesson_observation_id: this.lessonObservationId,
          mentor_meeting_id: this.mentorMeetingId,
          student_assignment_id: this.studentAssignmentId,
          evidence_type: this.evidenceType,
          evidence_description: this.evidenceDescription,
        })
        .then(() => {
          this.inputSelectedSubstandards = [];
          this.activeNdtDialog = false;
          this.title = '';
          this.evidenceType = '';
          this.evidenceDescription = '';
          this.processing = false;
          this.onClose();
          this.errorMessage = '';
        })
        .catch(e => {
          this.errorMessage =
            (e.response && e.response.data && e.response.data.message) ||
            'Sorry, cannot create your Evidence at the moment';
          this.processing = false;
        });
    },
    suggestSelectedSubstandards() {
      this.inputSelectedSubstandards = this.suggestedCodes
        ? this.substandards.filter(s => this.suggestedCodes.includes(s.code))
        : [];
    },
    attributesUpdated(attributes) {
      this.evidenceDescription = attributes.description;
      this.title = attributes.title;
      this.evidenceType = attributes.type;
      this.selectedSubstandards = attributes.selectedSubstandards;
    },
  },
  components: { NdtDialog, EvidenceAttributes },
};
</script>
