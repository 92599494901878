<template>
  <div>
    <mosaic-filter-list-page
      object-type="Subject"
      :load="fetchSubjectsWithMappings"
      :items="filteredSubjects"
      :hide-add="false"
      @add="addSubjectDialog.active = true"
      v-model:trigger-background-load="triggerBackgroundLoad"
    >
      <template #filters>
        <mosaic-text-field
          v-model="searchTerm"
          name="name-filter"
          label="Filter by name"
          style="width: 250px"
          prepend-icon="magnify"
          hide-details
      /></template>
      <template #list-item="{ item: s }">
        <mosaic-list-item :key="s.id" :title="s.name" :subtitle="renderMappedSubjects(s)" :icon="icons.subject">
          <template #actions>
            <mosaic-icon-btn @click.prevent="editSubject(s)" icon="mdi-pencil"></mosaic-icon-btn>
          </template>
        </mosaic-list-item>
      </template>
    </mosaic-filter-list-page>
    <mosaic-dialog v-model:active="addSubjectDialog.active" title="Add Subject">
      <mosaic-text-field
        v-model="addSubjectDialog.subject"
        name="new-subject"
        label="Subject Name"
        prepend-icon="mdi-pencil-ruler"
        hide-details
      />
      <template v-for="mapping in addSubjectDialog.indexedMappings" :key="mapping.id">
        <mosaic-text-field
          v-model="mapping.name"
          name="new-subject-mapping"
          :label="`Common Mapping ${mapping.id + 1}`"
          prepend-icon="mdi-pencil-ruler"
          hide-details
        />
      </template>
      <div class="text-caption mt-8">Common Mappings</div>
      <div class="d-flex align-end mb-4">
        <div class="flex-grow-1">
          <mosaic-text-field
            v-model="addSubjectDialog.newMapping"
            name="new-subject-new-mapping"
            label="New Mapping"
            prepend-icon="mdi-pencil-ruler"
            hide-details
          />
        </div>
        <mosaic-btn
          @click="
            addSubjectDialog.indexedMappings.push({
              id: addSubjectDialog.indexedMappings.length,
              name: addSubjectDialog.newMapping,
            });
            addSubjectDialog.newMapping = '';
          "
          color="primary"
          variant="text"
          ripple
          :disabled="!addSubjectDialog.newMapping"
          >Add</mosaic-btn
        >
      </div>
      <template #buttons>
        <mosaic-btn @click="submitAddSubject" color="primary" :disabled="!addSubjectDialog.subject">Add</mosaic-btn>
      </template>
    </mosaic-dialog>
    <mosaic-dialog
      v-model:active="editSubjectDialog.active"
      title="Edit Subject"
      :error-message="editSubjectDialog.error"
    >
      <mosaic-text-field
        v-model="editSubjectDialog.subject"
        name="edit-subject"
        label="Subject Name"
        prepend-icon="mdi-pencil-ruler"
        hide-details
        class="mb-2"
      />
      <template v-for="mapping in editSubjectDialog.indexedMappings" :key="mapping.id">
        <mosaic-text-field
          v-model="mapping.name"
          name="edit-subject-mapping"
          :label="`Common Mapping ${mapping.id + 1}`"
          prepend-icon="mdi-pencil-ruler"
          hide-details
          class="mb-2"
      /></template>
      <div class="text-caption mt-8">Common Mappings</div>
      <div class="d-flex align-end mb-4">
        <div class="flex-grow-1">
          <mosaic-text-field
            v-model="editSubjectDialog.newMapping"
            name="edit-subject-new-mapping"
            label="New Mapping"
            prepend-icon="mdi-pencil-ruler"
            hide-details
          ></mosaic-text-field>
        </div>
        <mosaic-btn
          @click="
            editSubjectDialog.indexedMappings.push({
              id: editSubjectDialog.indexedMappings.length,
              name: editSubjectDialog.newMapping,
            });
            editSubjectDialog.newMapping = '';
          "
          color="primary"
          variant="text"
          ripple
          :disabled="!editSubjectDialog.newMapping"
          >Add</mosaic-btn
        >
      </div>
      <template #buttons>
        <mosaic-btn @click="submitEditSubject" color="primary" variant="text" ripple :disabled="!canUpdateSubject"
          >Save</mosaic-btn
        >
      </template>
    </mosaic-dialog>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import { setBreadcrumbs } from '@/utils/breadcrumbs';
import { useApi } from '@/composables/api';
import { icons } from '@/utils/icons';

const api = useApi();
const triggerBackgroundLoad = ref(false);
const subjects = ref<Subject[]>([]);

const breadcrumbs = ref([{ text: 'Subjects' }]);
setBreadcrumbs(breadcrumbs.value);

const searchTerm = ref('');
const filteredSubjects = computed(() => {
  return subjects.value.filter(s => s.name.toLowerCase().includes(searchTerm.value.toLowerCase()));
});

function renderMappedSubjects(subject: ApiSubject) {
  if (!subject.commonMappings.length) {
    return 'No common mappings';
  }
  return 'Common Mappings: ' + subject.commonMappings.join(', ');
}

interface Subject {
  id: number;
  name: string;
  indexedMappings: { id: number; name: string }[];
  commonMappings: string[];
}

interface ApiSubject {
  id: number;
  name: string;
  commonMappings: string[];
}

async function fetchSubjectsWithMappings() {
  const response = await api.get<ApiSubject[]>(`/admin-subjects`);
  subjects.value = response.data.map(s => ({
    ...s,
    indexedMappings: s.commonMappings.map((m, i) => ({ id: i, name: m })),
  }));
}

const editSubjectDialog = ref<{
  active: boolean;
  subject: string;
  error: string;
  id: null | number;
  indexedMappings: { id: number; name: string }[];
  newMapping: string;
}>({
  active: false,
  id: null,
  subject: '',
  error: '',
  indexedMappings: [],
  newMapping: '',
});

const canUpdateSubject = computed(() => {
  const originalSubject = subjects.value.find(s => s.id === editSubjectDialog.value.id);
  return (
    !!editSubjectDialog.value.subject &&
    !!originalSubject &&
    (originalSubject.name !== editSubjectDialog.value.subject ||
      mappingsDirty(originalSubject.commonMappings, editSubjectDialog.value.indexedMappings))
  );
});
const mappingsDirty = (mappings: string[], indexedMappings: { name: string; id: number }[]) => {
  return mappings.length !== indexedMappings.length || mappings.some((m, i) => m !== indexedMappings[i].name);
};
function editSubject(subject: Subject) {
  editSubjectDialog.value = {
    active: true,
    id: subject.id,
    subject: subject.name,
    error: '',
    indexedMappings: subject.indexedMappings,
    newMapping: '',
  };
}

async function submitEditSubject() {
  try {
    await api.put(`subjects/${editSubjectDialog.value.id}`, {
      name: editSubjectDialog.value.subject,
      commonMappings: editSubjectDialog.value.indexedMappings.map(m => m.name),
    });
    triggerBackgroundLoad.value = true;
    editSubjectDialog.value.active = false;
  } catch (e: unknown) {
    console.log(e);
    editSubjectDialog.value.error = `Sorry, cannot edit subject right now`;
  }
}

const addSubjectDialog = ref<{
  active: boolean;
  subject: string;
  error: string;
  indexedMappings: { id: number; name: string }[];
  newMapping: string;
}>({
  active: false,
  subject: '',
  error: '',
  indexedMappings: [],
  newMapping: '',
});

async function submitAddSubject() {
  try {
    await api.post(`subjects`, {
      name: addSubjectDialog.value.subject,
      commonMappings: addSubjectDialog.value.indexedMappings.map(m => m.name),
    });
    triggerBackgroundLoad.value = true;
    addSubjectDialog.value.active = false;
  } catch (e) {
    console.log(e);
  }
}
</script>
