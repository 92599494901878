<template>
  <div>
    <v-card v-if="error">
      <v-card-text>
        <div class="pa-4">{{ error }}</div>
      </v-card-text>
    </v-card>
    <v-card v-if="!busy && !error" class="mb-4">
      <v-card-text>
        <div class="text-h6">{{ isEditing ? 'Edit' : 'New' }} {{ reviewNounCapitalised }} Template</div>
        <div class="pa-2">
          <v-text-field
            v-model="name"
            name="template-name"
            prepend-icon="mdi-pencil"
            label="Template Name"
          ></v-text-field>

          <v-select
            id="standard-set"
            v-model="standardSetId"
            prepend-icon="mdi-clipboard-list"
            :items="standardSets"
            item-title="name"
            item-value="id"
            placeholder="Select Assessment Framework"
          ></v-select>

          <mosaic-select
            name="review-type"
            v-if="selectedInstitution.config.early_careers"
            v-model="reviewType"
            prepend-icon="mdi-palette-swatch-variant"
            item-title="title"
            :items="reviewTypeItems"
            :label="`${reviewNounCapitalised} Type`"
          />
          <mosaic-error-alert :override-error-message="saveError" />
          <div class="mt-2 text-right">
            <v-btn variant="text" ripple :disabled="processing || !canSubmit" @click.prevent="next()">Next</v-btn>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'TutorAdminReviewTemplateCreatePage',
  data: () => ({
    isEditing: false,
    reviewTemplate: null,
    templateId: null,
    busy: true,
    processing: false,
    error: '',
    saveError: '',
    name: '',
    standardSetId: null,
    reviewType: null,
  }),
  async created() {
    this.$store.dispatch('loadStandardSets');
    if (this.$route.name === 'TutorAdminReviewTemplateCreatePage') {
      this.isEditing = false;
      this.busy = false;
    } else {
      this.templateId = this.$route.params.templateId.toString();
      this.loadReviewTemplate();
      this.isEditing = true;
    }
  },
  computed: {
    ...mapState(['selectedInstitution', 'standardSets', 'reviewTypeItems']),
    ...mapGetters(['reviewNounCapitalised']),
    breadcrumbs() {
      return [
        {
          text: `${this.reviewNounCapitalised} Templates`,
          to: {
            name: 'TutorAdminReviewTemplatesListPage',
          },
        },
        {
          text: `New ${this.reviewNounCapitalised} Template`,
        },
      ];
    },
    canSubmit() {
      return this.standardSetId && this.name && (!this.selectedInstitution.config.early_careers || this.reviewType);
    },
  },
  methods: {
    async loadReviewTemplate() {
      this.error = '';
      try {
        const response = await this.$api.get(`review-templates/${this.$route.params.templateId}`);
        this.reviewTemplate = response.data;
      } catch (e) {
        console.log(e);
        this.error = 'Sorry, cannot load your review template at the moment.';
      }
      this.busy = false;
    },
    async next() {
      this.processing = true;
      this.saveError = '';
      try {
        let apiCall;
        if (this.isEditing) {
          apiCall = x => this.$api.put(`/review-templates/${this.templateId}`, x);
        } else {
          apiCall = x => this.$api.post(`/institutions/${this.selectedInstitution.id}/review-templates`, x);
        }
        const template = await apiCall({
          name: this.name,
          standardSetId: this.standardSetId,
          reviewType: this.reviewType,
        });
        if (!this.isEditing) {
          this.$store.commit('updateReviewTemplatePage', { creating: true });
        }
        this.$router.push({
          name: 'TutorAdminReviewTemplatePage',
          params: { templateId: template.data.id },
        });
      } catch (e) {
        console.log(e);
        this.saveError = `Sorry, cannot ${this.isEditing ? 'save' : 'create'} your ${this.reviewNoun} template`;
      }
      this.processing = false;
    },
  },
};
</script>
