<template>
  <mosaic-filter-list-page object-type="Announcement" :load="load" :items="announcements" :hide-add="true">
    <template #list-item="{ item: a }">
      <mosaic-list-item
        :key="a.id"
        :title="a.title"
        :subtitle="subtitle(a)"
        :icon="icons.announcement"
        :to="{
          name: 'StudentAnnouncementPage',
          params: { id: a.id },
        }"
      >
        <template #information>
          <announcement-status-chip :read="a.has_read" />
        </template>

        <template #actions>
          <mosaic-icon-btn
            v-if="a.has_read"
            icon="email-mark-as-unread"
            tooltip="Mark as unread"
            :disabled="unreadProcessing"
            @click.prevent="markAsUnread(a)"
          />
          <div v-else-if="anyAnnouncementsAreRead" style="width: 36px"></div>
        </template>
      </mosaic-list-item>
    </template>

    <template #list-snackbar>
      <mosaic-error-snackbar v-model="unreadError" action="mark this Announcement as unread" />
    </template>
  </mosaic-filter-list-page>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { icons } from '@/utils/icons';
import { setBreadcrumbs } from '@/utils/breadcrumbs';
import AnnouncementStatusChip from './AnnouncementStatusChip.vue';
import { computed } from 'vue';
import { withProcessingAndError } from '@/composables/processing-and-errors';
import { formatDateFromNow } from '@/mixins/global-mixins';
import { useApi } from '@/composables/api';

const api = useApi();

setBreadcrumbs([
  {
    text: 'Announcements',
  },
]);

interface Announcement {
  id: number;
  title: string;
  announced_at: string;
  has_read: boolean;
  created_by?: { name: string };
}

const announcements = ref<Announcement[]>([]);
const anyAnnouncementsAreRead = computed(() => announcements.value.some(a => a.has_read));

async function load() {
  const r = await api.get<Announcement[]>(`/announcements`);
  announcements.value = r.data.sortBy('announcedAt', 'desc');
}

function subtitle(a: Announcement) {
  const createdByName = a.created_by?.name || 'your course leads';
  return `${formatDateFromNow(a.announced_at)} - from ${createdByName}`;
}

const {
  action: markAsUnread,
  processing: unreadProcessing,
  error: unreadError,
} = withProcessingAndError(async (a: Announcement) => {
  await api.post(`announcements/${a.id}/unread`, {});
  announcements.value = announcements.value.map(announcement => {
    if (announcement.id === a.id) return { ...announcement, has_read: false };
    return announcement;
  });
});
</script>
