<template>
  <div style="width: 100%">
    <div data-vjs-player>
      <video ref="playerElement" class="video-js"></video>
    </div>
    <div class="text-right">
      Video not playing? Try <a class="text-primary" style="cursor: pointer" @click="openVideo()">here</a> instead.
    </div>
  </div>
</template>

<script setup lang="ts">
import videojs from 'video-js';
import type Player from 'video-js/dist/types/player';
import type { default as VideoJsMediaError } from 'video-js/dist/types/media-error';
import { onMounted, onUnmounted } from 'vue';
import 'video-js/dist/video-js.css';
import { ref } from 'vue';
import { watch } from 'vue';
import { isString } from 'lodash';

const props = defineProps<{
  src: string;
  getVideoSrc: () => Promise<string | undefined>;
}>();

const emit = defineEmits<{
  (e: 'resetSrc'): void;
}>();

const resetting = ref(false);

const player = ref<Player | null>(null);
const playerElement = ref<HTMLElement | null>(null);

const beforeError = function (
  triggeringPlayer: Player,
  err: VideoJsMediaError | string | null
): VideoJsMediaError | string | null {
  console.log('video.js.beforeError', err, triggeringPlayer);

  const error = triggeringPlayer.error();
  console.log('video.js.beforeError.error', error);
  if (triggeringPlayer.id() !== player.value?.id()) {
    return err || error || null;
  }

  if (err && !isString(err) && err.code === MediaError.MEDIA_ERR_NETWORK) {
    // If already resetting, we've tried once and will propagate the error to the user
    if (!resetting.value) {
      emit('resetSrc');
      triggeringPlayer.error(undefined);
      resetting.value = true;
      return null;
    }
  }

  // prevent current error from being cleared out
  return err || error || null;
};

function error(_player: Player | null, err: VideoJsMediaError | string | null | undefined) {
  if (!err) {
    console.log(`video.js.error player has errored out`);
  } else if (isString(err)) {
    console.log(`video.js.error player has errored out ${err}`);
  } else {
    console.log(`video.js.error player has errored out with code ${err.code} ${err.message}`);
  }
}

onMounted(() => {
  if (!playerElement.value) {
    throw "Can't find playerElement";
  }

  player.value = videojs(playerElement.value, {
    controls: true,
    autoplay: false,
    preload: 'metadata',
    fluid: true,
  });
  player.value.on('error', () => error(null, player.value?.error()));
  videojs.hook('beforeerror', beforeError);
  videojs.hook('error', error);
});

watch(() => props.src, setPlayerSrc);
setPlayerSrc(props.src);
function setPlayerSrc(src: string) {
  console.log('video.js.setPlayerSrc', src);
  if (src) {
    player.value?.src({
      src: src,
      type: 'video/mp4',
    });
    if (resetting.value) {
      player.value?.play();
      resetting.value = false;
    }
  }
}

async function openVideo() {
  let src = await props.getVideoSrc();
  src = src || props.src;
  window.open(src, '_blank');
}

onUnmounted(() => {
  player.value?.dispose();
  videojs.removeHook('beforeerror', beforeError);
  videojs.removeHook('error', error);
});
</script>
