<template>
  <div>
    <mosaic-loading-and-error-cards object-type="Subjects for Mapping" :load="fetchSubjectsWithMappings">
      <mosaic-card>
        <mosaic-card-heading>Bulk Upload {{ traineeNounPluralised }}</mosaic-card-heading>
        <div>
          <p>This bulk upload allows creation of {{ traineeNoun }} accounts from a <strong>CSV file</strong>.</p>
          <mosaic-card-subheading>Template</mosaic-card-subheading>
          <div class="mt-2">
            <div class="mb-2">
              You can provide the following fields in the upload:
              <ul class="ml-2">
                <li>Email (required)</li>
                <li>Name</li>
                <li>Subject (Must match a Mosaic Subject - see below)</li>
              </ul>
            </div>
            <div class="pt-2" @click.prevent="downloadUploadTemplate()">
              <v-icon color="primary">mdi-table-arrow-down</v-icon>
              <span class="export-text pl-1">Download Template</span>
            </div>
          </div>
          <v-divider class="my-4" />
          <mosaic-card-subheading>Matching Subjects</mosaic-card-subheading>
          <div class="mt-2 mb-2">
            Subject names provided <strong>must</strong> match one of the Subjects available in Mosaic.
          </div>
          <div class="mb-2">
            If a non-matching Subject name is provided, we will attempt to map it to a similar Subject name and check
            you are happy for this mapping to be used.
          </div>
          <div class="mb-4">
            Please contact support if you need a Subject added to Mosaic. For more information on Mosaic Subjects, see
            our help documentation
            <a href="http://help.mosaic.penrose.education/books/implementing-mosaic/page/mosaic-subjects">here</a>.
          </div>

          <div @click.prevent="downloadSubjects()">
            <v-icon color="primary">mdi-table-arrow-down</v-icon>
            <span class="export-text pl-1">Download Subjects</span>
          </div>
          <v-divider class="my-4" />
          <mosaic-card-subheading>Upload CSV</mosaic-card-subheading>
          <div class="d-flex align-center mt-2">
            <div class="flex-grow-1">
              <v-file-input
                v-model="file"
                :label="`Select CSV of ${traineeNounPluralised}`"
                class="mr-2"
                accept="text/csv"
              />
            </div>
            <force-microsoft-sso-checkbox class="mb-4" v-model="forceMicrosoftSso" is-bulk />
            <mosaic-btn
              variant="text"
              class="mb-4 ml-2"
              ripple
              color="primary"
              :disabled="!file || uploadStudentsProcessing || parseProcessing || subjectMappingsToConfirm.length > 0"
              @click.prevent="submitParseUpload()"
              >Upload</mosaic-btn
            >
          </div>
        </div>
        <mosaic-error-alert v-if="parseError" :error="parseError" />

        <template v-if="subjectMappingsToConfirm.length > 0">
          <mosaic-info-alert class="my-2" ref="mappingsToConfirmAlert"
            >We have found some Subjects in your Spreadsheet that we can't match to Subjects on
            Mosaic</mosaic-info-alert
          >
          <div>
            <mosaic-card-subheading>Subjects to Confirm</mosaic-card-subheading>
            <div>
              The following subjects could not be matched to an existing subject on Mosaic via our common mappings.
              Where possible, we have suggested a close match. Please confirm the mapping or select a different subject.
            </div>
            <div>
              <div class="d-flex mt-4">
                <div class="flex-grow-1"><strong>CSV Subject</strong></div>
                <div style="width: 250px"><strong>Suggested Mosaic Subject</strong></div>
              </div>
              <div v-for="s in subjectMappingsToConfirm" :key="s.subject" class="d-flex mt-4">
                <div class="flex-grow-1">{{ s.subject }}</div>
                <div style="width: 250px">
                  <mosaic-select
                    v-model="subjectMappings[s.subject].mapping"
                    :items="subjectsWithCommonMappings"
                    item-title="name"
                    item-value="name"
                    label="Select Subject"
                    prepend-icon="mdi-pencil-ruler"
                    dense
                    outlined
                  ></mosaic-select>
                </div>
              </div>
            </div>
          </div>
          <div>If a Subject you wish to use is not in the Mosaic Subject list, please contact support.</div>
          <div class="d-flex">
            <div class="flex-grow-1"></div>
            <mosaic-btn v-if="uploadHasErrors" variant="text" ripple color="error" @click.prevent="restartUpload()"
              >Restart upload</mosaic-btn
            >
            <mosaic-btn
              v-else
              variant="text"
              class="mb-4 mt-2"
              ripple
              color="primary"
              :disabled="!allSubjectsMapped || uploadStudentsProcessing"
              :loading="uploadStudentsProcessing"
              @click.prevent="submitUpload(true)"
              >Confirm and Upload</mosaic-btn
            >
          </div>
        </template>
        <mosaic-error-alert
          v-if="uploadHasErrors"
          :error="uploadHasErrors"
          :override-error-message="`Some ${traineeNounPluralised} could not be uploaded. Please check the Results export for information on errors `"
        />
        <mosaic-alert v-if="uploadErrorCount && uploadErrorCount > 0" type="warning">
          Your upload has been successfully processed.
          {{ enumerateItems(uploadErrorCount, traineeNoun, traineeNounPluralised) }} has failed to upload, check the
          downloaded results CSV for details.
        </mosaic-alert>
        <mosaic-error-alert v-if="error" :error="error" />
      </mosaic-card>
    </mosaic-loading-and-error-cards>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch, type Ref } from 'vue';
import { downloadAsCsv } from '../../utils/download-as-file';
import { useApi } from '@/composables/api';
import type { ParseResult } from 'papaparse';
import Papa from 'papaparse';
import Fuse from 'fuse.js';
import { mapState } from '@/store/map-store';
import { useStudentStore } from '@/stores/student';
import ForceMicrosoftSsoCheckbox from '@/components/ForceMicrosoftSsoCheckbox.vue';
import { setBreadcrumbs } from '@/utils/breadcrumbs';
import { enumerateItems } from '@/utils/text';
import { useRouter } from 'vue-router';

const { selectedCohort } = mapState();

const file = ref<File | null>(null);

const api = useApi();
const router = useRouter();

interface SubjectWithCommonMapping {
  name: string;
  commonMappings: string[];
}
const subjectsWithCommonMappings = ref<SubjectWithCommonMapping[]>([]);
async function fetchSubjectsWithMappings() {
  const r = await api.get<SubjectWithCommonMapping[]>('/subjects-with-mappings');
  subjectsWithCommonMappings.value = r.data;
}

const { traineeNounPluralised, traineeNoun } = useStudentStore();

const error = ref('');

const bulkUploadHeader = ['Email', 'Name', 'Subject'];
function downloadUploadTemplate() {
  downloadAsCsv([bulkUploadHeader], `${traineeNoun.value}-bulk-upload-template`);
}
function downloadSubjects() {
  downloadAsCsv([['Subject Name'], ...subjectsWithCommonMappings.value.map(s => [s.name])], 'mosaic-subjects');
}

const uploadStudentsProcessing = ref(false);
const parseProcessing = ref(false);

const parseError = ref('');

function submitParseUpload() {
  uploadHasErrors.value = false;
  parseProcessing.value = true;
  subjectMappings.value = {};
  rows = [];
  error.value = '';
  parseError.value = '';
  const f = file.value;
  if (!f) return;
  Papa.parse(f, {
    complete: (results: ParseResult<string[]>) => {
      postUploadParse(results);
    },
    error: (e: Error) => {
      error.value = e.message;
      parseError.value =
        'Sorry, could not read the file. Please check the file and try again. If this problem persists, please contact support';
    },
  });
  parseProcessing.value = false;
}

// Generate mapping for all subjects
// If match, or out due to casing/spacing/ampersand usage then just map and don't show suggestion (set unknown to false)
// If no match, try and find mapping, but set unknown to true
// If no mapping, set unknown to true and suggested to null - this will then be used by UI to prompt users to select a subject
const subjectMappings = ref<Record<string, { mapping: string | null; unknown: boolean }>>({});
const subjectMappingsToConfirm = ref<{ subject: string; suggestion: string | null }[]>([]);

const mappingsToConfirmAlert = ref<ScrollableHTMLElement | null>(null);

type ScrollableHTMLElement = HTMLElement & {
  $el: HTMLElement;
};

function scrollTo(view: Ref<ScrollableHTMLElement | null>) {
  view.value?.$el.scrollIntoView({ behavior: 'smooth', inline: 'nearest' });
}
watch(
  () => mappingsToConfirmAlert.value,
  () => {
    if (mappingsToConfirmAlert.value) scrollTo(mappingsToConfirmAlert);
  }
);

const allSubjectsMapped = computed(() =>
  Object.keys(subjectMappings.value).every(s => subjectMappings.value[s].mapping)
);

type Row = string[];
let rows: Row[] = [];

async function postUploadParse(parseResult: ParseResult<string[]>) {
  uploadStudentsProcessing.value = true;
  if (parseResult.errors.length > 0) {
    error.value =
      'CSV parsing failed. Please check your Spreadsheet conforms to the template and contact support if you cannot resolve the issue';
    console.log('Parsing failed', parseResult);
    parseProcessing.value = false;
    return;
  }

  const header: Row = parseResult.data[0].slice(0, 3);
  if (header.some((h, i) => h.toLowerCase() !== bulkUploadHeader[i].toLowerCase())) {
    parseError.value = `CSV header row doesn't match the template - are you sure this file is correct?`;
    parseProcessing.value = false;
    return;
  }
  rows = parseResult.data.slice(1).filter(row => row.some(r => r));
  if (rows.length == 0) {
    parseError.value = 'No rows found in the CSV file';
    return;
  }
  if (rows.some(r => r[2])) {
    const uniqueSubjects = rows
      .map(r => r[2])
      .unique()
      .filter(s => s);
    const subjectNames = subjectsWithCommonMappings.value.map(s => s.name);

    // Produces Object with common mappings as keys and subject names as values e.g.:
    // { 'Maths': 'Mathematics', 'Math': 'Mathematics' }
    const commonMappingsMap = subjectsWithCommonMappings.value.reduce((acc, s) => {
      s.commonMappings.forEach(m => {
        acc[m] = s.name;
      });
      return acc;
    }, {} as Record<string, string>);

    // Only really using fuse to deal with very small changes.
    const fuse = new Fuse(subjectNames, { threshold: 0.1 });
    const mappingsFuse = new Fuse(Object.keys(commonMappingsMap), { threshold: 0.1 });
    uniqueSubjects.forEach(s => {
      const existingSubjectsMatch = matchToExistingSubjects(s);
      if (existingSubjectsMatch) {
        subjectMappings.value[s] = { mapping: existingSubjectsMatch, unknown: false };
      } else {
        // Fuzzy check against existing subject names
        const match = fuse.search(s);
        if (match.length > 0) {
          subjectMappings.value[s] = { mapping: match[0].item, unknown: true };
        } else {
          // Fuzzy check against common mappings
          const commonMappingsMatch = mappingsFuse.search(s);
          if (commonMappingsMatch.length > 0) {
            subjectMappings.value[s] = { mapping: commonMappingsMap[commonMappingsMatch[0].item], unknown: true };
          } else {
            subjectMappings.value[s] = { mapping: null, unknown: true };
          }
        }
      }
    });
    subjectMappingsToConfirm.value = Object.keys(subjectMappings.value)
      .filter(s => subjectMappings.value[s].unknown)
      .map(s => {
        return { subject: s, suggestion: subjectMappings.value[s].mapping };
      });
    if (subjectMappingsToConfirm.value.length === 0) {
      parseProcessing.value = false;
      await submitUpload(true);
    }
  } else await submitUpload();
  uploadStudentsProcessing.value = false;
}

const forceMicrosoftSso = ref(false);
const uploadHasErrors = ref(false);
const uploadErrorCount = ref<number>();
async function submitUpload(mapSubjects = false) {
  uploadStudentsProcessing.value = true;
  uploadErrorCount.value = undefined;
  error.value = '';
  let r;
  let mappedRows = rows;
  try {
    if (mapSubjects) {
      mappedRows = rows.map((r: Row) => {
        const subject = r[2];
        if (subject && !subjectMappings.value[subject]?.mapping) {
          throw 'Not all subjects have mappings';
        }
        return [r[0], r[1], subjectMappings.value[subject]?.mapping || ''];
      });
    }
    r = await api.post<unknown, { poll_url: string }>(`cohorts/${selectedCohort.value.id}/bulk-upload-students`, {
      students: mappedRows,
      forceMicrosoftSso: forceMicrosoftSso.value,
    });
    const pollResult = await api.pollGet<{ status: string; downloadUrl: string; errorCount: number }>(r.data.poll_url, {
      retryOnSuccess: r => !['finished', 'errored'].includes(r.data.status),
    });

    if (pollResult.data.status == 'errored') {
      throw 'Background job has errored';
    }

    window.open(pollResult.data.downloadUrl);
    uploadErrorCount.value = pollResult.data.errorCount;

    if (pollResult.data.errorCount == 0) {
      router.push({ name: 'TutorStudentListPage' });
    }
  } catch (e) {
    console.log(e);
    error.value = `Sorry, there was an error uploading the ${traineeNounPluralised.value}. Please try again or contact support.`;
  }
  uploadStudentsProcessing.value = false;
}

function restartUpload() {
  subjectMappings.value = {};
  subjectMappingsToConfirm.value = [];
  parseProcessing.value = false;
  uploadHasErrors.value = false;
  file.value = null;
}

function matchToExistingSubjects(s: string) {
  return subjectsWithCommonMappings.value.find(
    sub =>
      ampersandCaseAndSpacingAgnosticMatch(s, sub.name) ||
      sub.commonMappings.some(m => ampersandCaseAndSpacingAgnosticMatch(s, m))
  )?.name;
}

function ampersandCaseAndSpacingAgnosticMatch(s: string, match: string) {
  // Often when collating data, people use dagger symbols to reference some footnotes
  // These are then not removed before upload
  const modifiedS = s.replace(/&/g, 'and').replace(/†/g, '').replace(/‡/, '').replace(/;/g, '').trim().toLowerCase();
  const modifiedMatch = match.replace(/&/g, 'and').trim().toLowerCase();
  return modifiedS === modifiedMatch;
}

const breadcrumbs = computed(() => {
  return [
    {
      text: traineeNounPluralised.value,
      to: { name: 'TutorStudentListPage', params: { cohortId: selectedCohort.value.id } },
    },
    {
      text: 'Bulk Upload',
    },
  ];
});

setBreadcrumbs(breadcrumbs);
</script>

<style scoped>
.export-text {
  cursor: pointer;
  color: rgb(var(--v-theme-primary));
}
</style>
