<template>
  <ul>
    <li v-for="s in counts" :key="s.status">
      {{ getStatus(s.status) }}:
      {{ enumerateItems(s.count, traineeNounCapitalised(), traineeNounCapitalisedAndPluralised) }}
    </li>
  </ul>
</template>

<script setup lang="ts">
import { enumerateItems } from '@/utils/text';
import type { StudentAssignmentStatus } from './student-assignments';
import { studentAssignmentStatusItems } from './student-assignments';
import { computed } from 'vue';

const props = defineProps<{
  statusCounts: { status: StudentAssignmentStatus; count: number }[];
}>();

const counts = computed(() => {
  return studentAssignmentStatusItems
    .sortBy('order', 'desc')
    .map(i => props.statusCounts.find(c => c.status === i.value)) as {
    status: StudentAssignmentStatus;
    count: number;
  }[];
});

function getStatus(status: StudentAssignmentStatus) {
  return studentAssignmentStatusItems.find(x => x.value === status)?.title;
}
</script>
