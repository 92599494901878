<template>
  <div style="visibility: hidden"></div>
</template>

<script>
import { syncQueryParamsMixin } from '@/mixins/query-mixins';
import { useQueryStore } from '@/stores/query';

export default {
  props: {
    start: { type: String, required: true },
    end: { type: String, required: true },
  },
  emits: ['update:start', 'update:end'],
  setup() {
    const queryStore = useQueryStore();
    return { queryStore };
  },
  mixins: [
    syncQueryParamsMixin({
      internalStart: { query: 'start', type: 'string' },
      internalEnd: { query: 'end', type: 'string' },
    }),
  ],
  data() {
    return {
      internalStart: this.start,
      internalEnd: this.end,
    };
  },
  created() {
    this.$emit('update:start', this.internalStart);
    this.$emit('update:end', this.internalEnd);
  },
  watch: {
    start(x) {
      this.internalStart = x;
    },
    internalStart(x) {
      this.$emit('update:start', x);
    },
    end(x) {
      this.internalEnd = x;
    },
    internalEnd(x) {
      this.$emit('update:end', x);
    },
  },
};
</script>
