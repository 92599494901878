<template>
  <mosaic-loading-card v-if="busy || storageConstraintBusy" type="list" />
  <mosaic-load-error-card
    v-else-if="loadError || loadStorageConstraintError"
    object-type="Evidence"
    @retry="loadEvidenceAndStorageConstraint"
  />
  <evidence-list-views
    v-else
    :initial-evidence="evidenceItems"
    :can-view-reflections="true"
    :storage-constraint="storageConstraint"
    @click-target="clickTarget"
    @click-reflection="clickReflection"
    @click-lesson-observation="clickLessonObservation"
    @click-mentor-meeting="clickMentorMeeting"
    @click-student-assignment="clickStudentAssignment"
  />
</template>

<script>
import { evidenceTypes } from '../../models/evidence-types';
import EvidenceListViews from '../../components/EvidenceListViews.vue';
import { mapState } from 'vuex';

export default {
  name: 'StudentEvidenceListPage',
  components: {
    EvidenceListViews,
  },
  data() {
    return {
      evidenceItems: [],
      substandards: [],
      busy: true,
      storageConstraintBusy: true,
      loadError: false,
      loadStorageConstraintError: false,
      standards: [],
      types: evidenceTypes,
      storageConstraint: null,
    };
  },
  async created() {
    await this.loadEvidenceAndStorageConstraint();
  },
  computed: {
    ...mapState(['user', 'selectedInstitution', 'userStudent']),
    breadcrumbs() {
      return [
        {
          text: 'Evidence',
        },
      ];
    },
  },
  methods: {
    async loadEvidenceAndStorageConstraint() {
      await Promise.all([this.loadEvidence(), this.loadStorageConstraint()]);
    },
    async loadEvidence() {
      this.busy = true;
      this.loadError = false;
      try {
        const response = await this.$api.get('/evidence');
        this.evidenceItems = response.data;
      } catch (e) {
        console.log(e);
        this.loadError = true;
      }
      this.busy = false;
    },
    async loadStorageConstraint() {
      this.loadStorageConstraintError = false;
      this.storageConstraintBusy = true;
      if (this.selectedInstitution.storage_type === 'One Drive' && !this.user.student.storage_email) {
        try {
          const r = await this.$api.get(`/storage-constraint`);
          this.storageConstraint = r.data;
        } catch (e) {
          console.log(e);
          this.loadStorageConstraintError = true;
        }
      }
      this.storageConstraintBusy = false;
    },
    clickReflection(reflection) {
      this.$router.push({
        name: 'ReflectionPage',
        params: { studentId: this.userStudent.id, reflectionId: reflection.id },
      });
    },
    clickTarget(target) {
      this.$router.push({
        name: 'TargetPage',
        params: { studentId: this.userStudent.id, targetId: target.id },
      });
    },
    clickLessonObservation(lo) {
      this.$router.push({
        name: 'LessonObservationPage',
        params: { studentId: this.userStudent.id, id: lo.id },
      });
    },
    clickMentorMeeting(meeting) {
      console.log('meeting');
      this.$router.push({
        name: 'MentorMeetingPage',
        params: { studentId: this.userStudent.id, id: meeting.id },
      });
    },
    clickStudentAssignment(sa) {
      this.$router.push({
        name: 'StudentAssignmentLatestVersionPage',
        params: { id: sa.assignment_id },
      });
    },
  },
};
</script>
