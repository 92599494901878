<template>
  <!-- eslint-disable vue/no-mutating-props -->
  <ndt-dialog v-model:active="activeNdtDialog" title="Edit Evidence" :error-message="errorMessage" :width="950">
    <EvidenceAttributes
      :id="evidence.id"
      :title="evidence.title"
      :given-description="evidence.evidence_description"
      :selected-substandards="evidence.substandards"
      :selected-type="evidence.evidence_type"
      :evidence-types="evidenceTypes"
      :can-edit-type="
        !(
          evidence.target ||
          evidence.reflection ||
          evidence.lesson_observation ||
          evidence.mentor_meeting ||
          evidence.student_assignment
        )
      "
      @attributes-updated="attributesUpdated"
    />
    <div v-if="evidence.evidence_file">
      <v-text-field
        v-model="evidence.evidence_file.name"
        prepend-inner-icon="mdi-paperclip"
        label="Linked File"
        variant="outlined"
        readonly
      />
    </div>
    <div v-else-if="evidence.reflection">
      <v-text-field
        v-model="evidence.reflection.title"
        prepend-inner-icon="mdi-paperclip"
        label="Linked Reflection"
        variant="outlined"
        readonly
      />
    </div>
    <div v-else-if="evidence.target">
      <v-text-field
        v-model="evidence.target.goal"
        prepend-inner-icon="mdi-paperclip"
        label="Linked Target"
        variant="outlined"
        readonly
      />
    </div>
    <div v-else-if="evidence.lesson_observation">
      <v-text-field
        v-model="evidence.lesson_observation.title"
        prepend-inner-icon="mdi-paperclip"
        label="Linked Lesson Observation"
        variant="outlined"
        readonly
      />
    </div>
    <div v-else-if="evidence.mentor_meeting">
      <v-text-field
        v-model="evidence.mentor_meeting.title"
        prepend-inner-icon="mdi-paperclip"
        label="Linked Mentor Meeting"
        variant="outlined"
        readonly
      />
    </div>
    <div v-else-if="evidence.student_assignment">
      <v-text-field
        v-model="evidence.student_assignment.name"
        prepend-inner-icon="mdi-paperclip"
        label="Linked Course Activity"
        variant="outlined"
        readonly
      />
    </div>
    <div v-else>
      <mosaic-alert type="warning"> Oops! The associated file can't be found - has it been deleted? </mosaic-alert>
    </div>
    <template #buttons>
      <v-btn variant="text" ripple :disabled="processing || !canSubmit" @click.prevent="submit()">Save</v-btn>
    </template>
  </ndt-dialog>
</template>

<script>
import NdtDialog from '../NdtDialog.vue';
import EvidenceAttributes from './EvidenceAttributes.vue';
import { evidenceTypes, fileEvidenceTypes } from '../../models/evidence-types';

export default {
  name: 'EditEvidenceDialog',
  components: { NdtDialog, EvidenceAttributes },
  props: {
    active: {
      type: Boolean,
      required: true,
    },
    onClose: {
      type: Function,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      default: () => {},
    },
    evidence: {
      type: Object,
      required: true,
    },
  },
  emits: ['update:active'],
  data: function () {
    return {
      activeNdtDialog: this.active,
      title: '',
      selectedSubstandards: [],
      evidenceType: '',
      description: '',
      processing: false,
      errorMessage: '',
    };
  },

  computed: {
    canSubmit: function () {
      return !!(this.title && this.selectedSubstandards.length);
    },
    evidenceTypes: function () {
      return this.evidence.evidence_file ? fileEvidenceTypes : evidenceTypes;
    },
  },
  watch: {
    activeNdtDialog: function (val) {
      this.$emit('update:active', val);
    },
    active: function (val) {
      this.activeNdtDialog = val ? true : false;
    },
    evidence: function (e) {
      this.title = e.title;
      this.selectedSubstandards = e.substandards;
      this.evidenceType = e.evidence_type;
      this.description = e.evidence_description;
      this.processing = false;
      this.errorMessage = '';
    },
  },
  methods: {
    submit: function () {
      this.processing = true;
      this.errorMessage = '';
      this.$api
        .put(`/evidence/${this.evidence.id}`, {
          title: this.title,
          evidence_type: this.evidenceType,
          substandards: this.selectedSubstandards.map(s => s.code),
          evidence_description: this.description,
        })
        .then(response => {
          this.processing = false;
          this.onClose(response.data);
          this.activeNdtDialog = false;
        })
        .catch(e => {
          this.errorMessage =
            (e.response && e.response.data && e.response.data.message) || 'Sorry, cannot edit Evidence at the moment';
          this.processing = false;
        });
    },
    attributesUpdated: function (attributes) {
      this.description = attributes.description;
      this.title = attributes.title;
      this.evidenceType = attributes.type;
      this.selectedSubstandards = attributes.selectedSubstandards;
    },
  },
};
</script>
