import type { Breadcrumb } from '@/store/map-store';
import { mapMutations } from '@/store/map-store';
import type { ComputedRef } from 'vue';
import { watchEffect } from 'vue';
import { useRoute } from 'vue-router';

export function setBreadcrumbs(breadcrumbs: ComputedRef<Breadcrumb[]> | Breadcrumb[]) {
  const { setPageInformation } = mapMutations();
  // A fix for a "Missing required param" error - details https://github.com/vuetifyjs/vuetify/issues/17176#issuecomment-1517843304
  // If that issue is fixed then this can probably be removed
  const originalRouteParams = { ...useRoute().params };

  if (isComputedRef(breadcrumbs)) {
    watchEffect(() => {
      setPageInformation({
        breadcrumbs: breadcrumbs.value.map(b => ({
          ...b,
          to: b.to ? { ...b.to, params: { ...originalRouteParams, ...b.to.params } } : undefined,
        })),
      });
    });
  } else {
    setPageInformation({ breadcrumbs });
  }
}

export function setNoBreadcrumbs(title: string) {
  const { setPageInformation } = mapMutations();
  setPageInformation({ breadcrumbs: [] });
  document.title = `Mosaic - ${title}`;
}

export function getReturnTo(breadcrumbs: ComputedRef<Breadcrumb[]> | Breadcrumb[]) {
  const b = isComputedRef(breadcrumbs) ? breadcrumbs.value : breadcrumbs;
  if (b.length < 2) throw `breadcrumbs must be of at least length 2 to getReturnTo`;
  const breadcrumb = b[b.length - 2];
  if (!breadcrumb.to) throw `penultimate breadcrumb must have a 'to' property to getReturnTo`;
  return breadcrumb.to;
}

// Might actually be better to not make this generic and have isBreacrumbs instead?
function isComputedRef<T>(ref: ComputedRef<T> | T): ref is ComputedRef<T> {
  if ((ref as ComputedRef<T>).value) {
    return true;
  }
  return false;
}
