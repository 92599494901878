<template>
  <div>
    <mosaic-loading-card v-if="busy || loadingSchools" type="card" />
    <mosaic-load-error-card v-else-if="error || loadSchoolsError" :object-type="reviewNounCapitalised" @retry="load" />
    <review-card
      v-else-if="studentReviewForStaff"
      :student-review="studentReviewForStaff"
      :staff-viewer="userStaff"
      :student="selectedStudent"
      :allowed-to-edit-school="allowedToEditSchool"
      @edit-school-clicked="editSchool()"
      @edit-due-date-clicked="editDueDate()"
    />
    <ndt-dialog
      v-model:active="editSchoolDialog.active"
      :error-message="editSchoolDialog.errorMessage"
      title="Edit School"
    >
      <mosaic-autocomplete
        v-model="editSchoolDialog.school"
        name="school"
        :items="schools"
        item-value="id"
        item-title="displayName"
        label="School"
        no-data-text="No schools found"
        prepend-icon="mdi-domain"
        return-object
      />
      <template #buttons>
        <v-btn
          variant="text"
          ripple
          :disabled="editSchoolDialog.processing || !editSchoolDialog.school"
          @click.prevent="submitEditSchool()"
          >Save</v-btn
        >
      </template>
    </ndt-dialog>
    <ndt-dialog
      v-model:active="editDueDateDialog.active"
      :error-message="editDueDateDialog.errorMessage"
      :title="`Edit due date for this ${traineeNounCapitalised()}`"
    >
      <mosaic-date-picker
        v-model:date="editDueDateDialog.date"
        label="Due date"
        :exact-width="false"
      ></mosaic-date-picker>
      <template #buttons>
        <v-btn
          variant="text"
          ripple
          :disabled="editDueDateDialog.processing || !editDueDateDialog.date"
          @click.prevent="submitEditDueDate()"
          >Save</v-btn
        >
      </template>
    </ndt-dialog>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import ReviewCard from '@/components/ReviewCard.vue';
import NdtDialog from '@/components/NdtDialog.vue';
import moment from 'moment';
import clearStudentReviewMixin from '@/mixins/clear-student-review';

export default {
  name: 'TutorReviewPage',
  mixins: [clearStudentReviewMixin],
  data: () => ({
    loadingSchools: true,
    loadSchoolsError: false,
    editSchoolDialog: {
      active: false,
      school: null,
      processing: false,
      errorMessage: '',
    },
    editDueDateDialog: {
      active: false,
      date: moment().toISOString(),
      processing: false,
      errorMessage: '',
    },
    schools: [],
  }),
  async created() {
    await this.load();
    // Will be falsey if there's an error
    if (this.studentReviewForStaff) {
      this.editDueDateDialog = {
        active: false,
        date: moment(this.studentReviewForStaff.dueDate).toISOString(),
        processing: false,
        errorMessage: '',
      };
    }
  },
  computed: {
    ...mapState(['user', 'selectedStudent', 'userStaff', 'selectedInstitution']),
    ...mapState({
      error: state => state.studentReviewPages.error,
      busy: state => state.studentReviewPages.busy,
    }),
    allowedToEditSchool() {
      return (
        // Need schools.edit to be able to view the schools in an institution
        this.userStaffHasPermissionForSelectedStudent('students.reviews.school.edit') &&
        this.userStaffHasPermission('schools.edit')
      );
    },
    ...mapGetters(['studentReviewForStaff']),
    breadcrumbs() {
      return [
        {
          text: this.reviewNounCapitalisedAndPluralised,
          to: {
            name: 'TutorReviewsListPage',
          },
        },
        {
          text: this.studentReviewForStaff?.name,
        },
      ];
    },
  },
  methods: {
    async load() {
      await Promise.all([this.loadReview(), this.loadSchools()]);
    },
    async loadReview() {
      await this.$store.dispatch('loadStudentReview', { id: this.$route.params.id });
    },
    editSchool() {
      this.editSchoolDialog = {
        active: true,
        school: this.studentReviewForStaff?.ectSchool,
        processing: false,
        errorMessage: '',
      };
    },
    editDueDate() {
      this.editDueDateDialog = {
        active: true,
        date: this.studentReviewForStaff.dueDate,
        processing: false,
        errorMessage: '',
      };
    },
    async submitEditDueDate() {
      this.editDueDateDialog.processing = true;
      try {
        await this.$api.put(`/student-reviews/${this.studentReviewForStaff.id}/due-date`, {
          due_date: this.editDueDateDialog.date,
        });
        await this.$store.dispatch('loadStudentReview', { id: this.$route.params.id, force: true });
        this.editDueDateDialog.active = false;
      } catch (e) {
        console.log(e);
        this.editDueDateDialog.errorMessage = 'Sorry, cannot update due date right now.';
      }
      this.editDueDateDialog.processing = false;
    },
    async submitEditSchool() {
      this.editSchoolDialog.processing = true;
      try {
        await this.$api.put(`/student-reviews/${this.studentReviewForStaff.id}/school`, {
          school_id: this.editSchoolDialog.school.id,
        });
        await this.$store.dispatch('loadStudentReview', { id: this.$route.params.id, force: true });
        this.editSchoolDialog.active = false;
      } catch (e) {
        console.log(e);
        this.editSchoolDialog.errorMessage = 'Sorry, cannot update school right now.';
      }
      this.editSchoolDialog.processing = false;
    },
    async loadSchools() {
      if (this.allowedToEditSchool) {
        this.loadingSchools = true;
        this.loadSchoolsError = false;
        try {
          const r = await this.$api.get(`/institutions/${this.selectedInstitution.id}/schools`);
          this.schools = r.data;
        } catch (e) {
          console.log(e);
          this.loadSchoolsError = true;
        }
      }
      this.loadingSchools = false;
    },
  },
  components: { ReviewCard, NdtDialog },
};
</script>
