<template>
  <v-expansion-panel>
    <v-expansion-panel-title class="text-h7">
      <template #default="{ open }">
        <div class="d-flex align-center">
          <div>
            <v-icon class="mr-4">mdi-cog-outline</v-icon>
          </div>
          <div :style="open ? 'color: rgb(var(--v-theme-primary));' : ''">{{ reallyFriendlyName }}</div>
        </div>
      </template>
    </v-expansion-panel-title>
    <v-expansion-panel-text class="px-10">
      <div class="d-flex align-center justify-space-between">
        <div>
          <span class="font-weight-bold">Database name:</span>
          {{ feature.name }}
        </div>
        <div class="py-2">
          <span class="pr-2 font-weight-bold">Expose to Customer?</span>
          <v-btn-toggle v-model="internalFeature.expose_to_customer" mandatory divided variant="outlined">
            <v-btn class="px-3" :value="true" color="green">Yes</v-btn>
            <v-btn class="px-3" :value="false" color="red">No</v-btn>
          </v-btn-toggle>
        </div>
      </div>
      <div>
        <v-text-field v-model="internalFeature.friendly_name" label="Friendly Name" style="width: 60%"></v-text-field>
        <v-textarea
          v-model="internalFeature.friendly_description"
          auto-grow
          rows="1"
          label="Friendly Description"
        ></v-textarea>
        <v-text-field v-model="internalFeature.support_hyperlink" label="Support Link"></v-text-field>
      </div>
      <div class="d-flex justify-end">
        <v-btn class="mr-2" variant="text" :disabled="!featureIsChanged" @click.prevent="saveConfig(internalFeature)"
          >Save</v-btn
        >
        <v-btn variant="text" :disabled="!featureIsChanged" @click.prevent="cancelChange()">Cancel</v-btn>
      </div>
      <mosaic-snackbar v-model="snackbar.active" :color="snackbar.color" contained :message="snackbar.message" />
    </v-expansion-panel-text>
  </v-expansion-panel>
</template>

<script>
import { mapGetters } from 'vuex';
import { renderFriendlyText } from '@/utils/text';
export default {
  props: {
    feature: {
      required: true,
      type: Object,
    },
  },
  data: function () {
    return {
      internalFeature: { ...this.feature },
      snackbar: {
        active: false,
        color: 'success',
        message: '',
      },
    };
  },
  computed: {
    ...mapGetters(['friendlyTextMap']),
    featureIsChanged() {
      return (
        this.internalFeature.friendly_name != this.feature.friendly_name ||
        this.internalFeature.friendly_description != this.feature.friendly_description ||
        this.internalFeature.expose_to_customer != this.feature.expose_to_customer
      );
    },
    reallyFriendlyName() {
      return renderFriendlyText(this.internalFeature.friendly_name, this.friendlyTextMap);
    },
  },
  methods: {
    cancelChange() {
      this.resetChanges();
    },
    resetChanges() {
      this.internalFeature = { ...this.feature };
    },
    async saveConfig() {
      try {
        const r = await this.$api.put(`admin/feature-configs/${this.internalFeature.id}`, this.internalFeature);
        this.snackbar = {
          active: true,
          color: 'success',
          message: 'Success!',
        };
        this.$store.commit('updateFeatureConfig', r.data);
      } catch (e) {
        console.log(e);
        this.snackbar = {
          active: true,
          color: 'error',
          message: 'Sorry, could not update config at the moment',
        };
      }
    },
  },
};
</script>
