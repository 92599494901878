<template>
  <v-card class="flex-grow-1">
    <v-card-text>
      <mosaic-card-title v-if="showTitle" class="pb-1">Reflections</mosaic-card-title>
      <div v-if="reflections.length === 0" class="pa-4 pl-6">
        <slot />
      </div>
      <mosaic-list v-else two-line :items="reflections">
        <template #item="{ item: reflection }">
          <mosaic-list-item
            :key="reflection.id"
            icon="mdi-mirror"
            :to="reflectionClickTo(reflection.id)"
            :title="reflection.title"
            :subtitle="renderSubTitle(reflection.created_at)"
          >
            <template #information>
              <comments-badge
                v-if="reflection.comment_count"
                :comment-count="reflection.comment_count"
                :seen="!reflection.unseen_comments"
                :user="user"
              />
              <div v-if="reflection.admin_reflection_student_id" class="d-flex align-center">
                <v-tooltip location="top">
                  <template #activator="{ props }">
                    <span class="pr-4" v-bind="props">
                      <v-icon color="primary">mdi-cast-education</v-icon>
                    </span>
                  </template>
                  <span>Course reflection</span>
                </v-tooltip>
                <div :style="{ width: !smallScreen ? '100px' : 'unset' }" class="text-center">
                  <v-chip v-if="!reflection.submitted" color="accent">
                    <span>Active</span>
                  </v-chip>
                  <v-chip v-else color="primary">
                    <span>Completed</span>
                  </v-chip>
                </div>
              </div>
            </template>

            <template #actions>
              <template v-if="showEvidence && editEvidence">
                <mosaic-evidence-icon-btn
                  v-model="reflection.evidence"
                  @click-edit="editEvidence(reflection)"
                  @click-create="createEvidence(reflection)"
                />
              </template>
              <ndt-icon-button
                v-if="deleteReflection && !reflection.admin_reflection_student_id"
                icon="delete"
                tooltip="Delete Reflection"
                @click.prevent="deleteReflection(reflection)"
              />
              <!-- Ideally this would be conditional on there being some delete buttons on the page -->
              <div v-else-if="!smallScreen" style="width: 48px"></div>
            </template>
          </mosaic-list-item>
        </template>
      </mosaic-list>
    </v-card-text>
  </v-card>
</template>

<script>
import NdtIconButton from '@/components/NdtIconButton.vue';
import CommentsBadge from '@/components/CommentsBadge.vue';
import moment from 'moment';
import { mapGetters, mapState } from 'vuex';

export default {
  components: { NdtIconButton, CommentsBadge },
  props: {
    reflections: {
      type: Array,
      required: true,
    },
    editEvidence: {
      type: Function,
      default: null,
    },
    createEvidence: {
      type: Function,
      default: null,
    },
    deleteReflection: {
      type: Function,
      default: null,
    },
    reflectionClickTo: {
      type: Function,
      required: true,
    },
    showTitle: {
      type: Boolean,
      required: true,
    },
  },
  data: function () {
    return {
      seenClass: 'seen',
      unseenClass: 'unseen',
    };
  },
  computed: {
    ...mapState(['user']),
    ...mapGetters(['showEvidence']),
  },
  methods: {
    createReflection: function () {
      this.$router.push({ name: 'StudentReflectionCreatePage' });
    },
    renderSubTitle: function (created_date) {
      return 'Added ' + moment(created_date).fromNow();
    },
    seenStatusColor(reflection) {
      return reflection.unseen_comments ? 'orange' : 'grey';
    },
  },
};
</script>
