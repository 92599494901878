import Papa from 'papaparse';

export function downloadAsCsv(array, filename) {
  const csv = Papa.unparse(array);
  downloadAsFile(csv, `${filename}.csv`, 'text/csv;charset=utf-8;');
}

function downloadAsFile(content, filename, contentType) {
  const blob = new Blob([content], { type: contentType });
  const url = URL.createObjectURL(blob);

  const a = document.createElement('a');
  a.href = url;
  a.setAttribute('download', filename);
  a.click();
  a.remove();
}
