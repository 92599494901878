<template>
  <v-card class="mt-4">
    <v-card-text class="pa-5">
      <div class="text-h5">
        {{ 'Developmental Targets for this ' + (isOverallReview ? reviewNounCapitalised : standardNoun) }}
      </div>
      <div class="text-h12 pb-4">Any Targets created here will also appear in the Targets area</div>
      <mosaic-list :items="targetsInternal">
        <template #item="{ item: t }">
          <mosaic-list-item :to="targetClickedTo(t.id)" icon="mdi-near-me" :title="t.goal" :subtitle="t.impact_goal">
            <template #actions>
              <ndt-icon-button
                v-if="!studentReview.approved"
                icon="pencil"
                :tooltip="editTargetTooltip(t.can_edit_goal_and_description)"
                :disabled="!t.can_delete.permitted"
                @click.prevent="editTarget(t)"
              />
              <ndt-icon-button
                v-if="!studentReview.approved"
                icon="delete"
                :tooltip="deleteTargetTooltip(t.can_delete)"
                :disabled="!t.can_delete.permitted"
                @click.prevent="deleteTarget(t)"
              />
            </template>
          </mosaic-list-item>
        </template>
      </mosaic-list>
      <div v-if="targetsInternal.length === 0" class="py-2 text-body-1">
        Targets set as part of this {{ reviewNounCapitalised }} {{ isOverallReview ? '' : `and ${standardNoun}` }} will
        appear here
      </div>

      <template v-if="userStaffHasPermissionForSelectedStudent('student.targets.editAll') || canCreateTargets">
        <v-divider class="mt-4" />
        <div class="py-2 mt-4">
          <div class="text-h6 mb-2">Set new Target</div>
          <v-textarea
            v-model="target.goal"
            variant="filled"
            class="mb-4"
            label="New Target"
            auto-grow
            type="text"
            rows="1"
            hide-details
          />
          <v-textarea
            v-model="target.impactGoal"
            variant="filled"
            label="Description - please add any additional detail relevant to this Target"
            auto-grow
            type="text"
            rows="2"
            hide-details
          />
          <v-alert density="compact" class="mt-4 mb-4" v-if="target.error" type="error">{{ target.error }}</v-alert>
          <div class="mt-4 text-right">
            <v-btn :disabled="!target.goal" @click.prevent="addTarget()">Save Target</v-btn>
          </div>
        </div>
      </template>
    </v-card-text>
    <ndt-dialog v-model:active="editTargetDialog.active" title="Edit Target" :error-message="editTargetDialog.error">
      <v-textarea
        v-model="editTargetDialog.goal"
        class="mb-4"
        label="Target"
        autofocus
        auto-grow
        type="text"
        rows="1"
        hide-details
      />
      <v-textarea
        v-model="editTargetDialog.impactGoal"
        variant="filled"
        label="Description - please add any additional detail relevant to this Target"
        auto-grow
        type="text"
        rows="2"
        hide-details
      />
      <template #buttons>
        <v-btn
          variant="text"
          ripple
          :disabled="editTargetDialog.processing || !editTargetDialog.goal"
          @click.prevent="submitEditTarget()"
          >Save</v-btn
        >
      </template>
    </ndt-dialog>
    <ndt-dialog
      v-model:active="deleteTargetDialog.active"
      title="Delete Target"
      :error-message="deleteTargetDialog.error"
    >
      <span>Are you sure you want to delete "{{ deleteTargetDialog.title }}"?</span>
      <template #buttons>
        <v-btn
          variant="text"
          ripple
          color="error"
          :disabled="deleteTargetDialog.processing"
          @click.prevent="submitDeleteTarget()"
          >Delete</v-btn
        >
      </template>
    </ndt-dialog>
  </v-card>
</template>

<script>
import NdtIconButton from './NdtIconButton.vue';
import NdtDialog from './NdtDialog.vue';
import config from '../utils/config';
import { roleHasPermission } from '@/utils/student-review';
import { mapState } from 'vuex';
import { capitaliseFirstLetters } from '@/utils/text';
import { deleteTargetTooltip, editTargetTooltip } from '@/pages/targets/targets';
import { useActiveTargetsStore } from '../stores/active-targets';
export default {
  name: 'ReviewTargetsList',
  props: {
    studentReviewId: {
      type: [Number, String],
      default: null,
    },
    studentReviewStandardId: {
      type: [Number, String],
      default: null,
    },
    studentReview: {
      type: Object,
      required: true,
    },
    isOverallReview: {
      type: Boolean,
      default: false,
    },
    staffViewer: {
      type: Object,
      default: null,
    },
    studentViewer: {
      type: Object,
      default: null,
    },
    student: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    config: config,
    target: {
      goal: '',
      impactGoal: '',
      error: '',
      processing: false,
    },
    // Ideally we can now refactor away from this, by just using the store updates
    targetsInternal: [],
    editTargetDialog: {
      targetId: null,
      active: false,
      error: '',
      processing: false,
      goal: '',
      impactGoal: '',
    },
    deleteTargetDialog: {
      targetId: null,
      title: null,
      active: false,
      error: '',
      processing: false,
    },
  }),
  setup() {
    const {
      actions: { addTarget: addActiveTarget, removeTarget: removeActiveTarget },
    } = useActiveTargetsStore();
    return { addActiveTarget, removeActiveTarget };
  },
  created() {
    this.targetsInternal = this.isOverallReview
      ? [...this.studentReview.targets]
      : [...this.studentReview.reviewStandards.find(x => x.id === this.studentReviewStandardId).targets];
  },
  computed: {
    ...mapState(['selectedInstitution']),
    standardNoun() {
      return capitaliseFirstLetters(this.studentReview.review.standardNoun);
    },
    canCreateTargets() {
      if (this.studentReview.approved) return false;

      if (this.studentViewer) {
        return this.isOverallReview
          ? this.studentReview.review.canTraineeAddTargetsOverall
          : this.studentReview.review.canTraineeAddTargetsPerStandard;
      }
      const roleIds = this.isOverallReview
        ? this.studentReview.review.roleIdsThatCanAddTargetsOverall
        : this.studentReview.review.roleIdsThatCanAddTargetsPerStandard;

      return roleHasPermission(roleIds, this.staffViewer, this.student);
    },
    canEditTargets() {
      return this.canCreateTargets && this.setByMe(this.target);
    },
  },

  methods: {
    targetClickedTo(targetId) {
      return {
        name: 'TargetPage',
        params: { studentId: this.student.id, targetId },
      };
    },
    setByMe(target) {
      if (this.staffViewer) {
        return target.set_by_id === this.staffViewer.id;
      } else return !target.set_by_id;
    },
    async addTarget() {
      this.target.error = '';
      this.target.processing = true;
      try {
        const r = await this.$api.post(`/students/${this.student.id}/review-targets`, {
          goal: this.target.goal,
          impact_goal: this.target.impactGoal,
          student_review_standard_id: this.studentReviewStandardId,
          student_review_id: this.studentReviewId,
        });
        this.targetsInternal.push(r.data);
        //add target to the active targets list
        if (r.data.status === 'active') this.addActiveTarget(r.data);
        if (this.isOverallReview) {
          this.$store.commit('addStudentReviewOverallTarget', r.data);
        } else {
          this.$store.commit('addStudentReviewStandardTarget', {
            studentReviewStandardId: this.studentReviewStandardId,
            target: r.data,
          });
        }
        this.target.goal = '';
        this.target.impactGoal = '';
      } catch (e) {
        console.log(e);
        this.target.error = 'Sorry, cannot add your target at the moment';
      }
      this.target.processing = false;
    },
    editTarget(target) {
      this.editTargetDialog.targetId = target.id;
      this.editTargetDialog.goal = target.goal;
      this.editTargetDialog.impactGoal = target.impact_goal;
      this.editTargetDialog.active = true;
    },
    async submitEditTarget() {
      this.editTargetDialog.error = '';
      this.editTargetDialog.processing = true;
      try {
        await this.$api.put(`/review-targets/${this.editTargetDialog.targetId}`, {
          goal: this.editTargetDialog.goal,
          impact_goal: this.editTargetDialog.impactGoal,
        });
        const target = this.targetsInternal.find(x => x.id === this.editTargetDialog.targetId);
        const index = this.targetsInternal.findIndex(x => x.id === this.editTargetDialog.targetId);
        target.goal = this.editTargetDialog.goal;
        target.impact_goal = this.editTargetDialog.impactGoal;
        this.targetsInternal.splice(index, 1, target);
        if (this.isOverallReview) {
          this.$store.commit('updateStudentReviewOverallTarget', target);
        } else {
          this.$store.commit('updateStudentReviewStandardTarget', {
            studentReviewStandardId: this.studentReviewStandardId,
            target,
          });
        }
        this.editTargetDialog.active = false;
      } catch (e) {
        console.log(e);
        this.editTargetDialog.error = 'Sorry, cannot save your target at the moment';
      }
      this.editTargetDialog.processing = false;
    },
    deleteTarget(target) {
      this.deleteTargetDialog.targetId = target.id;
      this.deleteTargetDialog.title = target.goal;
      this.deleteTargetDialog.active = true;
    },
    async submitDeleteTarget() {
      this.deleteTargetDialog.error = '';
      this.deleteTargetDialog.processing = true;
      try {
        await this.$api.delete(`/targets/${this.deleteTargetDialog.targetId}`);
        this.targetsInternal = this.targetsInternal.filter(x => x.id !== this.deleteTargetDialog.targetId);
        //remove from active targets
        this.removeActiveTarget(this.deleteTargetDialog.targetId);
        if (this.isOverallReview) {
          this.$store.commit('deleteStudentReviewOverallTarget', this.deleteTargetDialog.targetId);
        } else {
          this.$store.commit('deleteStudentReviewStandardTarget', {
            studentReviewStandardId: this.studentReviewStandardId,
            targetId: this.deleteTargetDialog.targetId,
          });
        }
        this.deleteTargetDialog.active = false;
      } catch (e) {
        console.log(e);
        this.deleteTargetDialog.error = 'Sorry, cannot delete your target at the moment';
      }
      this.deleteTargetDialog.processing = false;
    },
    editTargetTooltip,
    deleteTargetTooltip,
  },
  components: { NdtIconButton, NdtDialog },
};
</script>
