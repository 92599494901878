<template>
  <div>
    <mosaic-loading-card v-if="loading" type="curriculum" />
    <mosaic-load-error-card v-else-if="loadError" :object-type="curriculumNoun" @retry="load" />
    <curriculum-list
      v-else
      :curriculum-themes="curriculumThemes"
      :can-see-reflections="userStaffHasPermissionForSelectedStudent('student.reflections.view')"
      :curriculum-statement-click-to="curriculumStatementClickTo"
      @update:selected-curriculum-theme="updateSelectedCurriculumTheme"
    />
  </div>
</template>

<script>
import CurriculumList from './CurriculumList.vue';
import { mapState } from 'vuex';

export default {
  name: 'TutorCurriculumPage',
  data: function () {
    return {
      curriculumThemes: [],
      selectedCurriculumTheme: null,
      studentId: null,
      loading: true,
    };
  },
  async created() {
    await this.load();
  },
  computed: {
    ...mapState(['selectedStudent']),
    breadcrumbs() {
      return [
        {
          text: this.curriculumNoun,
        },
      ];
    },
    title: function () {
      let title = 'Curriculum';
      if (!this.selectedCurriculumTheme) return title;
      title += ' - ';
      title += this.curriculumThemes.find(x => x.id === this.selectedCurriculumTheme).name;
      return title;
    },
  },
  methods: {
    async load() {
      this.loading = true;
      this.loadError = false;
      try {
        const r = await this.$api.get(`students/${this.selectedStudent.id}/student-curriculum-themes`);
        this.curriculumThemes = r.data;
      } catch (e) {
        this.loadError = true;
        console.log(e);
      }
      this.loading = false;
    },
    updateSelectedCurriculumTheme(theme) {
      this.selectedCurriculumTheme = theme;
    },
    curriculumStatementClickTo(statement) {
      return { name: 'TutorCurriculumStatementPage', params: { id: statement.id } };
    },
  },
  components: { CurriculumList },
};
</script>
