<template>
  <mosaic-dialog
    :active="active"
    title="Mark as complete"
    :error-message="error"
    close-button-text="I'll do this later"
    @update:active="emit('update:active', $event)"
    @close="close()"
  >
    <div v-if="rolesThatCanComplete.length" class="mb-4">
      <template v-if="rolesThatCanComplete.length > 1">
        <div>It looks like you can mark this {{ reviewNounCapitalised }} as complete for the following Roles:</div>
        <mosaic-checkbox-multiple v-model="selectedRoleIds" :items="rolesThatCanComplete" item-title="name" />
      </template>
      <div v-else>
        It looks like you can mark this {{ reviewNounCapitalised }} as complete
        {{ staffViewer ? 'in your role as ' + rolesThatCanComplete[0].name : '' }}
      </div>
    </div>
    <div class="mb-4">
      Marking a {{ reviewNounCapitalised }} as complete lets those responsible for approving the
      {{ reviewNounCapitalised }} know that your sections are ready for review.
    </div>
    <div v-if="reviewPath">Marking as complete will take you back to the {{ reviewNounCapitalised }} summary page</div>
    <template #buttons>
      <mosaic-btn
        variant="text"
        color="primary"
        :disabled="!canMarkAsComplete || processing"
        :loading="processing"
        @click="markAsComplete()"
        >Mark as complete {{ rolesThatCanComplete.length > 1 ? 'for selected Roles' : '' }}</mosaic-btn
      >
    </template>
  </mosaic-dialog>
</template>

<script setup lang="ts">
import { ref, computed, watchEffect } from 'vue';
import { useRouter } from 'vue-router';
import type { Role } from '@/store/map-store';
import { mapActions, mapGetters, mapMutations } from '@/store/map-store';
import MosaicCheckboxMultiple from '@/components/library/inputs/MosaicCheckboxMultiple.vue';
import type { RouteLocationNamedRaw } from 'vue-router';
import moment from 'moment';
import { useApi } from '@/composables/api';

const router = useRouter();
const api = useApi();

interface Staff {
  id: number;
}
interface Student {
  id: number;
}

const props = defineProps<{
  active: boolean;
  afterSave?: boolean;
  staffViewer?: Staff;
  studentViewer?: Student;
  studentReviewId: number;
  rolesThatCanComplete: Role[];
  next?: RouteLocationNamedRaw | null;
  reviewPath?: RouteLocationNamedRaw | null;
}>();

const emit = defineEmits<{
  (e: 'update:active', active: boolean): void;
  (e: 'close', toPath: RouteLocationNamedRaw | null): void;
}>();

const { reviewNounCapitalised } = mapGetters();

const selectedRoleIds = ref<number[]>([]);
watchEffect(() => (selectedRoleIds.value = props.rolesThatCanComplete.map(role => role.id)));

const canMarkAsComplete = computed(() => {
  return selectedRoleIds.value.length > 0 || !!props.studentViewer;
});
const processing = ref(false);
const error = ref('');
const { submitStudentReview, updateStudentReview } = mapMutations();
const { dontShowStudentReviewCompletedModal } = mapActions();

async function apiCall() {
  if (props.staffViewer) {
    const role_ids = selectedRoleIds.value;

    await api.post(`student-reviews/${props.studentReviewId}/mark-roles-as-complete`, {
      role_ids: role_ids,
    });
    submitStudentReview({ roleIds: role_ids });
  } else {
    await api.post(`student-reviews/${props.studentReviewId}/submit`, {});
    updateStudentReview({ student_submitted_at: moment().toISOString() });
  }
}
async function markAsComplete() {
  processing.value = true;
  error.value = '';

  try {
    await apiCall();
    if (props.reviewPath) {
      dontShowStudentReviewCompletedModal();
      router.push(props.reviewPath);
    } else emit('update:active', false);
  } catch (e) {
    console.log(e);
    error.value = `Sorry, cannot mark the ${reviewNounCapitalised} as complete at the moment`;
  }
  processing.value = false;
}

function close() {
  dontShowStudentReviewCompletedModal();
  if (props.next) router.push(props.next);
}
</script>
