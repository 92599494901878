<template>
  <mosaic-loading-and-error-cards object-type="Competency Statements" :load="load">
    <mosaic-card v-if="theme">
      <mosaic-title level="h4" class="mb-3">
        <div class="d-flex justify-space-between">
          <span>
            {{ theme.code }} - {{ theme.name }}
            <span class="framework-text"
              >(<v-icon :size="16" class="mr-1">mdi-{{ icons.instructorTrainingFramework }}</v-icon>
              {{ framework?.name }})
              <mosaic-expand-collapse-all
                v-if="
                  competencies.some(
                    c =>
                      c.requiredModules.length > 0 ||
                      c.staffTrainingEvents.length > 0 ||
                      c.staffTrainingRecords.length > 0 ||
                      c.staffTrainingCertificates.length > 0
                  )
                "
                class="details font-weight-regular mb-2 mt-3 ml-5"
                @handle-expand-all="expandAll"
                @handle-collapse-all="collapseAll"
              />
            </span>
          </span>
          <mosaic-tooltip-chip :color="theme.status == 'not_met' ? 'accent' : 'primary'">
            <template #text>{{
              theme.status === 'not_met' ? 'Not Met' : theme.status === 'up_to_date' ? 'Up to date' : 'Met'
            }}</template>
            <template #tooltip>
              <div v-if="theme.status === 'not_met'">This Competency Theme has not yet been met.</div>
              <div v-else-if="theme.status === 'up_to_date'">
                This Competency Theme is up to date as all of its Competencies have been met or are up to date.
              </div>
              <div v-else>This Competency Theme has been met as all of its Competencies have been met</div>
            </template>
          </mosaic-tooltip-chip>
        </div>
      </mosaic-title>
      <div class="pl-2 competency-theme">
        <template v-for="competency in competencies" :key="competency.id">
          <div>
            <mosaic-title level="h5" class="ml-2">
              <div
                class="d-flex justify-space-between cursor-pointer"
                @click="collapseSection(competency.id + competency.code)"
              >
                <span
                  ><v-icon :size="14" class="mr-2" color="secondary"
                    >mdi-{{ icons.instructorTrainingCompetency }}</v-icon
                  >
                  {{ competency.code }} - {{ competency.name }}
                  <span>
                    <v-tooltip v-if="competency.requiredModules.length > 0 && anyPublishedModule(competency)">
                      <template #activator="{ props }">
                        <span class="details font-weight-regular mr-2 ml-1" v-bind="props"
                          >{{ getTotalDetails(competency, 'Requirement', 'completed') }}
                        </span>
                      </template>
                      <div v-if="competency.status === 'not_met' || competency.status === 'up_to_date'">
                        <div v-if="competency.requiredModules.filter(m => m.status === 'not_started').length > 0">
                          Requirements to complete:
                          <ul>
                            <li
                              v-for="(m, mi) in competency.requiredModules.filter(m => m.status === 'not_started')"
                              :key="mi"
                            >
                              {{ m.name }}
                            </li>
                          </ul>
                        </div>
                        <div v-if="completedModules(competency).length > 0">
                          Requirements completed:
                          <ul>
                            <li v-for="(m, mi) in completedModules(competency)" :key="mi">
                              {{ m.name }}
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div v-else>
                        Required Modules
                        {{
                          competency.status === 'met_by_requirements'
                            ? 'Met by fulfilling all Module Requirements'
                            : competency.status === 'met_by_training_record'
                            ? 'Met by a manual Training Record'
                            : competency.status === 'met_by_certificate'
                            ? 'Met by an approved Certificate'
                            : competency.status === 'met_by_event'
                            ? 'Met by attending an Event'
                            : ''
                        }}:
                        <ul>
                          <li v-for="(m, mi) in publishedModules(competency)" :key="mi">
                            {{ m.name }}
                          </li>
                        </ul>
                      </div>
                    </v-tooltip>
                    <span class="details font-weight-regular mr-2 ml-1" v-else>
                      {{
                        getNoModulesSentence(
                          competency.staffTrainingEvents,
                          competency.staffTrainingRecords,
                          competency.staffTrainingCertificates,
                          competency.status
                        )
                      }}
                    </span>
                  </span>
                  <v-icon
                    :size="16"
                    class="mr-2"
                    v-if="
                      competency.requiredModules.length > 0 ||
                      competency.staffTrainingEvents.length > 0 ||
                      competency.staffTrainingRecords.length > 0 ||
                      competency.staffTrainingCertificates.length > 0
                    "
                    >mdi-chevron-{{ closedSections[competency.id + competency.code] ? 'down' : 'up' }}</v-icon
                  >
                </span>

                <mosaic-tooltip-chip :color="competency.status == 'not_met' ? 'accent' : 'primary'">
                  <template #text>{{
                    competency.status === 'not_met'
                      ? 'Not Met'
                      : competency.status === 'up_to_date'
                      ? 'Up to date'
                      : 'Met'
                  }}</template>
                  <template #tooltip>
                    <div v-if="competency.status === 'not_met'">This Competency has not yet been met.</div>
                    <div v-else-if="competency.status === 'up_to_date'">
                      This Competency is up to date.
                      {{
                        publishedModules(competency).length > 0
                          ? 'All current Module Requirements have been met. New content is coming soon.'
                          : 'The Module Requirements are coming soon.'
                      }}
                    </div>
                    <div v-else-if="competency.status === 'met_by_requirements'">
                      This Competency has been met by fulfilling all of its Module Requirements
                    </div>
                    <div v-else-if="competency.status === 'met_by_training_record'">
                      This Competency has been met by a manual Training Record
                    </div>
                    <div v-else-if="competency.status === 'met_by_certificate'">
                      This Competency has been met by an approved Certificate
                    </div>
                    <div v-else-if="competency.status === 'met_by_event'">
                      This Competency has been met by attending an Event
                    </div>
                  </template>
                </mosaic-tooltip-chip>
              </div>
            </mosaic-title>
            <div :class="`ml-4 pl-2 section ${closedSections[competency.id + competency.code] ? 'hidden' : ''}`">
              <mosaic-quill
                v-if="competency.description"
                :key="competency.id"
                v-model:contents="competency.description"
                :read-only="true"
              />
              <div class="competency-training">
                <div
                  v-if="
                    competency.requiredModules.length > 0 &&
                    ['not_met', 'met_by_requirements', 'up_to_date'].includes(competency.status)
                  "
                >
                  <div class="ml-2">
                    <mosaic-title level="h6">
                      <span class="cursor-pointer" @click="collapseSection(competency.id + 'module')">
                        <v-icon :size="14" class="mr-2 text-secondary">mdi-{{ icons.instructorTrainingModule }}</v-icon>
                        Training Modules
                        <span class="details font-weight-regular mr-2 ml-1">
                          {{ getDetails(publishedModules(competency), 'Required Module') }}
                        </span>
                        <v-icon :size="16" class="mr-4"
                          >mdi-chevron-{{ closedSections[competency.id + 'module'] ? 'down' : 'up' }}</v-icon
                        ></span
                      >
                    </mosaic-title>
                    <div :class="`ml-2 pl-2  section ${closedSections[competency.id + 'module'] ? 'hidden' : ''} `">
                      <div :class="`text-secondary ${publishedModules(competency).length === 0 ? '' : 'mt-1'}`">
                        {{
                          competency.status !== 'up_to_date'
                            ? `This Competency
                        ${competency.status == 'not_met' ? 'can be' : 'has been'}
                        met by completing all of these Training Modules`
                            : anyPublishedModule(competency)
                            ? 'This Competency can be met by completing all Training Modules, both those listed below and the upcoming ones.'
                            : 'This Competency can be met by completing all upcoming Training Modules.'
                        }}
                      </div>
                      <mosaic-list :items="competencyModules(competency)" v-if="anyPublishedModule(competency)">
                        <template #item="{ item: module }">
                          <mosaic-list-item
                            :class="{ unpublished: module.status === 'unpublished' }"
                            :key="module.id"
                            :icon-color="
                              module.status === 'unpublished'
                                ? competency.status === 'up_to_date'
                                  ? 'primary'
                                  : 'accent'
                                : undefined
                            "
                            :icon="module.status !== 'unpublished' ? icons.instructorTrainingModule : icons.more"
                            :subtitle="getModuleSubtitle(module  as StaffTrainingCompetencyModule )"
                            :to="module.status !== 'unpublished' ? clickModuleTo(module  as StaffTrainingCompetencyModule ) : undefined"
                            :chip="module.status !== 'unpublished' ?mapStatus(module  as StaffTrainingCompetencyModule ):undefined"
                            :title="module.name"
                          >
                          </mosaic-list-item>
                        </template>
                      </mosaic-list>
                    </div>
                  </div>
                </div>

                <div
                  v-if="
                    ['not_met', 'met_by_event', 'up_to_date'].includes(competency.status) &&
                    competencyEvents(competency).length > 0
                  "
                >
                  <div class="ml-2">
                    <mosaic-title level="h6">
                      <span class="cursor-pointer" @click="collapseSection(competency.id + 'event')">
                        <v-icon :size="14" class="mr-2 text-secondary">mdi-{{ icons.instructorTrainingEvent }}</v-icon>
                        Events
                        <span class="details font-weight-regular mr-2 ml-1"
                          >{{ getDetails(competencyEvents(competency), 'Event') }}
                        </span>
                        <v-icon :size="16" class="mr-4"
                          >mdi-chevron-{{ closedSections[competency.id + 'event'] ? 'down' : 'up' }}</v-icon
                        ></span
                      >
                    </mosaic-title>
                    <div :class="`ml-2 pl-2  section ${closedSections[competency.id + 'event'] ? 'hidden' : ''} `">
                      <div class="text-secondary mt-1">
                        This Competency
                        {{
                          competency.status == 'not_met' || competency.status == 'up_to_date' ? 'can be' : 'has been'
                        }}
                        met by attending one of these Events
                      </div>
                      <mosaic-list :items="competencyEvents(competency)">
                        <template #item="{ item: event }">
                          <mosaic-list-item
                            :key="event.id"
                            :icon="icons.instructorTrainingEvent"
                            :title="event.name"
                            :subtitle="`Event - ${formatDateTime(event.startsAt)} - Duration ${formatDuration(
                              event.durationHours,
                              event.durationMinutes
                            )}`"
                            :to="clickEventTo(event)"
                          >
                          </mosaic-list-item>
                        </template>
                      </mosaic-list>
                    </div>
                  </div>
                </div>

                <div v-if="['notMet', 'met_by_training_record'].includes(competency.status)">
                  <div class="ml-2">
                    <mosaic-title level="h6">
                      <span class="cursor-pointer" @click="collapseSection(competency.id + 'record')">
                        <v-icon :size="14" class="mr-1 text-secondary"
                          >mdi-{{ icons.instructorTrainingRecordAudit }}</v-icon
                        ><span class="text-secondary">/</span>
                        <v-icon :size="14" class="ml-1 mr-2 text-secondary"
                          >mdi-{{ icons.instructorTrainingRecordSession }}</v-icon
                        >
                        Manual Training Records
                        <span class="details font-weight-regular mr-2 ml-1">
                          {{ getDetails(competency.staffTrainingRecords, 'Manual Training Record') }}
                        </span>
                        <v-icon :size="16" class="mr-4"
                          >mdi-chevron-{{ closedSections[competency.id + 'record'] ? 'down' : 'up' }}</v-icon
                        ></span
                      >
                    </mosaic-title>
                    <div :class="`ml-2 pl-2  section ${closedSections[competency.id + 'record'] ? 'hidden' : ''} `">
                      <div class="text-secondary mt-1">
                        This Competency
                        {{
                          competency.status == 'not_met' || competency.status == 'up_to_date' ? 'can be' : 'has been'
                        }}
                        met by your course leads creating a Manual Training Record
                      </div>
                      <mosaic-list
                        v-if="competency.staffTrainingRecords.length > 0"
                        :items="competency.staffTrainingRecords"
                      >
                        <template #item="{ item: record }">
                          <mosaic-list-item
                            :key="record.id"
                            :icon="
                              record.recordType === 'prior_experience_audit'
                                ? icons.instructorTrainingRecordAudit
                                : icons.instructorTrainingRecordSession
                            "
                            :title="record.name"
                            :subtitle="staffTrainingRecordSubtitle(record)"
                            :to="clickRecordTo(record)"
                          >
                          </mosaic-list-item>
                        </template>
                      </mosaic-list>
                    </div>
                  </div>
                </div>

                <div
                  v-if="
                    competency.staffTrainingCertificates.length > 0 &&
                    ['not_met', 'met_by_certificate', 'up_to_date'].includes(competency.status)
                  "
                >
                  <div class="ml-2">
                    <mosaic-title level="h6">
                      <span class="cursor-pointer" @click="collapseSection(competency.id + 'certificate')">
                        <v-icon :size="14" class="mr-2 text-secondary">mdi-{{ icons.certificate }}</v-icon>
                        Certificates
                        <span class="details font-weight-regular mr-2 ml-1">
                          {{ getDetails(competency.staffTrainingCertificates, 'Certificate') }}</span
                        >
                        <v-icon :size="16" class="mr-4"
                          >mdi-chevron-{{ closedSections[competency.id + 'certificate'] ? 'down' : 'up' }}</v-icon
                        ></span
                      >
                    </mosaic-title>
                    <div
                      :class="`ml-2 pl-2  section ${closedSections[competency.id + 'certificate'] ? 'hidden' : ''} `"
                    >
                      <div class="text-secondary mt-1">
                        This Competency
                        {{
                          competency.status == 'not_met' || competency.status == 'up_to_date' ? 'can be' : 'has been'
                        }}
                        met by having one of these Certificates approved by your course leads
                      </div>
                      <mosaic-list :items="competency.staffTrainingCertificates">
                        <template #item="{ item: certificate }">
                          <mosaic-list-item
                            :key="certificate.id"
                            :icon="icons.certificate"
                            :title="certificate.certificateType.name"
                            subtitle="Certificate"
                            :to="clickCertificateTo(certificate)"
                            :chip="staffTrainingCertificateStatusChip(certificate.status)"
                          >
                          </mosaic-list-item>
                        </template>
                      </mosaic-list>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </mosaic-card>
  </mosaic-loading-and-error-cards>
</template>

<script setup lang="ts">
import { mapState } from '@/store/map-store';
import { setBreadcrumbs } from '@/utils/breadcrumbs';
import { useRoute } from 'vue-router';
import { computed, ref, watchEffect } from 'vue';
import MosaicQuill from '@/components/quill/MosaicQuill.vue';
import { useStaffTrainingStore, type StaffTrainingCompetencyModule, type StaffTraining } from '@/stores/staff-training';
import { parseRouteId } from '@/composables/vue-router';
import { icons } from '@/utils/icons';
import {
  staffTrainingRecordSubtitle,
  staffTrainingCertificateStatusChip,
  useStaffTraining,
  staffTrainingModuleExpectedDuration,
} from './staff-training';
import { formatDateTime } from '@/utils/date';
import { formatDuration } from '@/utils/time';
import MosaicTitle from '@/components/library/headers/MosaicTitle.vue';
import MosaicExpandCollapseAll from '@/components/library/buttons/MosaicExpandCollapseAll.vue';

const moduleStatusMap = {
  not_started: {
    text: 'Not Started',
    color: 'accent',
  },
  started: {
    text: 'Started',
    color: 'accent',
  },
  completed: {
    text: 'Completed',
    color: 'primary',
  },
  unpublished: {
    text: 'Upcoming',
    color: 'secondary',
  },
};

const mapStatus = (module: StaffTrainingCompetencyModule) => moduleStatusMap[module.status];

// Load Staff Training
const { userStaff, selectedInstitution } = mapState();

const {
  staffTraining,
  actions: { loadStaffTraining },
} = useStaffTrainingStore();

const route = useRoute();
const { isMyStaffTraining, staffId, staff, loadStaff } = useStaffTraining();
const themeId = parseRouteId('themeId');

const theme = computed(() => {
  if (staffTraining.value) {
    for (const f of staffTraining.value.staffTrainingFrameworks) {
      const theme = f.staffTrainingCompetencyThemes.find(t => t.id == themeId.value);
      if (theme) return theme;
    }
  }
  return undefined;
});

const framework = computed(() => {
  return staffTraining.value?.staffTrainingFrameworks.find(f =>
    f.staffTrainingCompetencyThemes.some(t => t.id == themeId.value)
  );
});

const competencies = computed(() => {
  return (
    theme.value?.staffTrainingCompetencies.map(c => ({
      ...c,
      staffTrainingCertificates:
        c.status == 'met_by_certificate'
          ? c.staffTrainingCertificates.filter(c => c.status == 'approved')
          : c.staffTrainingCertificates,
    })) || []
  );
});

type Competency =
  StaffTraining['staffTrainingFrameworks'][number]['staffTrainingCompetencyThemes'][number]['staffTrainingCompetencies'][number];
function competencyEvents(competency: Competency) {
  if (competency.status === 'not_met' || competency.status === 'up_to_date') {
    return competency.staffTrainingEvents.filter(e => e.status == 'upcoming');
  }
  return competency.staffTrainingEvents.filter(e => e.status == 'attended');
}

function competencyModules(competency: Competency) {
  if (competency.requiredModules.length === 0) return [];
  const publishedModules = competency.requiredModules.filter(m => m.status !== 'unpublished');

  const completeModulesList =
    publishedModules.length !== competency.requiredModules.length
      ? [
          ...publishedModules,
          {
            id: -1,
            name: '',
            status: 'unpublished',
          },
        ]
      : [...competency.requiredModules];

  return completeModulesList;
}

async function load() {
  await Promise.all([loadStaff(), loadStaffTraining(staffId.value)]);
}

setBreadcrumbs(
  computed(() => {
    if (isMyStaffTraining.value) {
      return [
        {
          text: 'My Training',
          to: { name: 'TutorStaffTrainingPage' },
          query: { tab: 'training-requirements' },
        },
        {
          text: theme.value?.name || '',
          to: {
            name: 'TutorStaffTrainingCompetencyThemePage',
            params: {
              themeId: themeId.value.toString(),
            },
          },
        },
      ];
    }
    return [
      {
        text: 'Instructors',
        to: {
          name: 'InstitutionStaffListPage',
        },
      },
      {
        text: staff.value?.user.name || '',
        to: {
          name: 'TutorStaffPage',
          params: { institutionId: selectedInstitution.value.id.toString(), id: staffId.value.toString() },
          query: { tab: route.query.fromTab?.toString() || 'training-requirements' },
        },
      },
      { text: theme.value?.name || '' },
    ];
  })
);

// Click
const clickModuleTo = (module: StaffTrainingCompetencyModule) => {
  if (isMyStaffTraining.value) {
    return {
      name: 'MyStaffTrainingModulePage',
      params: { moduleId: module.id },
    };
  }
  return {
    name: 'TutorStaffTrainingModulePage',
    params: { moduleId: module.id, staffId: staffId.value },
  };
};

const clickRecordTo = (record: { id: number }) => {
  if (isMyStaffTraining.value) {
    return {
      name: 'MyStaffTrainingRecordEditPage',
      params: { recordId: record.id },
    };
  }
  return {
    name: 'StaffTrainingRecordEditPage',
    params: { recordId: record.id, staffId: userStaff.value.id },
  };
};

const clickCertificateTo = (
  certificate: (typeof competencies)['value'][number]['staffTrainingCertificates'][number]
) => {
  if (isMyStaffTraining.value) {
    return certificate.status == 'not_created'
      ? {
          name: 'MyStaffTrainingCertificateCreatePage',
          query: { certificateTypeId: certificate.certificateType.id },
        }
      : {
          name: 'MyStaffTrainingCertificateEditPage',
          params: { certificateId: certificate.id },
        };
  }

  if (certificate.status == 'not_created') return undefined;
  return {
    name: 'StaffTrainingCertificateEditPage',
    params: { staffId: staffId.value, certificateId: certificate.id },
  };
};

const clickEventTo = (event: { id: number }) => {
  if (isMyStaffTraining.value) {
    return {
      name: 'MyStaffTrainingEventPage',
      params: { eventId: event.id },
    };
  }
  return {
    name: 'StaffTrainingEventPage',
    params: { eventId: event.id, staffId: staffId.value },
  };
};

const getModuleSubtitle = (module: StaffTrainingCompetencyModule) => {
  if (module.status === 'unpublished')
    return 'There is more content coming. This competency cannot be met through Module completion until the additional content has been completed.';
  return (
    'Training Module - ' +
    (module.status === 'not_started'
      ? 'Not Started'
      : `${mapStatus(module).text} on ${formatDateTime(
          module.status === 'completed' && module.completedAt ? module.completedAt : module.completionCreatedAt
        )}`) +
    (staffTrainingModuleExpectedDuration(module, '') ? ` - ${staffTrainingModuleExpectedDuration(module, '')}` : '')
  );
};

const getNoModulesSentence = (
  e: Competency['staffTrainingEvents'],
  r: Competency['staffTrainingRecords'],
  c: Competency['staffTrainingCertificates'],
  status: Competency['status']
) => {
  const training = [];
  if (e.length > 0) training.push(`Events`);
  if (r.length > 0) training.push(`Records`);
  if (c.length > 0) training.push(`Certificates`);
  const otherTraining = training.length > 0 ? '- contains ' + training.join(', ').replace(/, ([^,]*)$/, ' and $1') : '';

  const metByLabel = {
    met_by_event: 'Events',
    met_by_training_record: 'Records',
    met_by_certificate: 'Certificates',
  };

  if (metByLabel[status as keyof typeof metByLabel]) {
    return 'No Module Requirements - contains ' + metByLabel[status as keyof typeof metByLabel];
  } else if (status === 'up_to_date') {
    if (e.length === 0 && r.length === 0 && c.length === 0) {
      return 'No Module Requirements - nothing to do for now';
    } else {
      return `Module Requirements are coming soon`;
    }
  } else {
    return `No Module Requirements ${otherTraining}`;
  }
};

const anyPublishedModule = (competency: Competency) => {
  return competency.requiredModules.length > 0 && competency.requiredModules.some(m => m.status !== 'unpublished');
};

const completedModules = (competency: Competency) => {
  return competency.requiredModules.filter(m => m.status !== 'not_started' && m.status !== 'unpublished');
};

const publishedModules = (competency: Competency) => competency.requiredModules.filter(m => m.status !== 'unpublished');

const getDetails = (trainingItems: unknown[], type: string) =>
  `(${trainingItems.length} ${type}${trainingItems.length !== 1 ? 's' : ''})`;

const getTotalDetails = (competency: Competency, type: string, sentence: string) => {
  if (competency.status !== 'up_to_date' && competency.status !== 'not_met') return '(All Requirements completed)';
  const allCompletedModules = completedModules(competency);
  const allPublishedModules = publishedModules(competency);
  if (allCompletedModules.length === 0 && allPublishedModules.length === 0 && competency.status === 'up_to_date')
    return 'Module Requirements coming soon';
  return `(${allCompletedModules.length}/${allPublishedModules.length} ${type}${
    allPublishedModules.length > 1 ? 's' : ''
  } ${sentence})`;
};

const closedSections = ref<{ [key: string]: boolean }>({});

const expandOrCollapseSections = (mainSection: boolean, subSections: boolean) => {
  const sections: { [key: string]: boolean } = {};
  competencies.value.forEach((c: Competency) => {
    sections[c.id + c.code] = mainSection;
    if (c.requiredModules.length > 0 && ['not_met', 'met_by_requirements', 'up_to_date'].includes(c.status))
      sections[c.id + 'module'] = subSections;
    if (['not_met', 'met_by_event', 'up_to_date'].includes(c.status) && competencyEvents(c).length > 0)
      sections[c.id + 'event'] = subSections;
    if (['notMet', 'met_by_training_record', 'up_to_date'].includes(c.status)) sections[c.id + 'record'] = subSections;
    if (c.staffTrainingCertificates.length > 0 && ['not_met', 'met_by_certificate', 'up_to_date'].includes(c.status))
      sections[c.id + 'certificate'] = subSections;
  });
  closedSections.value = sections;
};

watchEffect(() => expandOrCollapseSections(true, false));

const collapseSection = (section: string) => {
  const filteredClosedSections = { ...closedSections.value, [section]: !closedSections.value[section] };
  closedSections.value = filteredClosedSections;
};

const collapseAll = () => expandOrCollapseSections(true, true);
const expandAll = () => (closedSections.value = {});
</script>
<style scoped>
.competency-theme {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.competency-training .section {
  margin: 5px 0;
}
.details {
  font-size: 14px;
}
.framework-text {
  font-size: 18px;
  font-weight: 400;
  margin-left: 5px;
}
.section {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-in-out;
  border-left: 0.5px solid rgb(198, 196, 196);
  position: relative;
}
.section:not(.hidden) {
  max-height: 1000px;
}
.unpublished {
  cursor: default;
  pointer-events: none;
}
</style>
