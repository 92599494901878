<template>
  <div>
    <div v-if="curriculumThemes.length > 0" class>
      <v-btn-toggle
        v-if="!smallScreen"
        v-model="selectedCurriculumTheme"
        mandatory
        class="flex-wrap"
        color="orange"
        divided
        variant="outlined"
      >
        <v-btn v-for="theme in curriculumThemes" :key="theme.id" class="px-6" :value="theme.id">{{ theme.code }}</v-btn>
      </v-btn-toggle>
      <mosaic-select
        v-else
        v-model="selectedCurriculumTheme"
        item-value="id"
        item-title="code"
        :items="curriculumThemes"
        no-icon
        prepend-inner-icon="mdi-hexagon"
        hide-details
        label=""
        variant="solo"
      />
      <div class="flex-grow-1 d-flex my-2">
        <span v-if="curriculumTheme" class="text-h6 d-flex align-center">{{ curriculumTheme.name }}</span>
      </div>
    </div>
    <v-card>
      <v-card-text v-if="curriculumThemes.length === 0">
        <v-icon class="mr-2">mdi-alert-outline</v-icon>It looks like the Curriculum area has not been fully built yet.
        {{ contactProviderMessage }}
      </v-card-text>
      <v-card-text v-else>
        <mosaic-list :items="curriculumStatements">
          <template #item="{ item: statement }">
            <mosaic-list-item
              :to="curriculumStatementClickTo(statement)"
              icon="mdi-hexagon"
              :title="`${statement.code} - ${statement.statement}`"
            >
              <template #subtitle>
                <div class="d-flex mt-2 ml-2">
                  <v-tooltip location="bottom">
                    <template #activator="{ props }">
                      <div v-bind="props">
                        <span>
                          <v-icon>mdi-near-me</v-icon>
                        </span>
                        <span class="mr-4">{{ statement.target_count }}</span>
                      </div>
                    </template>
                    {{
                      `${statement.target_count === 0 ? 'No' : statement.target_count} target${
                        statement.target_count === 1 ? '' : 's'
                      } recorded`
                    }}
                  </v-tooltip>
                  <v-tooltip location="bottom">
                    <template #activator="{ props }">
                      <div v-bind="props">
                        <span>
                          <v-icon class="icon-flipped">mdi-reflect-horizontal</v-icon>
                        </span>
                        <span class="mr-4">{{ statement.target_reflections_count }}</span>
                      </div>
                    </template>
                    {{
                      `${
                        statement.target_reflections_count === 0 ? 'No' : statement.target_reflections_count
                      } target reflection${statement.target_reflections_count === 1 ? '' : 's'} recorded`
                    }}
                  </v-tooltip>
                  <v-tooltip v-if="canSeeReflections" location="bottom">
                    <template #activator="{ props }">
                      <div v-bind="props">
                        <span>
                          <v-icon>mdi-mirror</v-icon>
                        </span>
                        <span class="mr-4">{{ statement.reflection_count }}</span>
                      </div>
                    </template>
                    {{
                      `${statement.reflection_count === 0 ? 'No' : statement.reflection_count} reflection${
                        statement.reflection_count === 1 ? '' : 's'
                      } recorded`
                    }}
                  </v-tooltip>
                  <v-tooltip v-if="selectedInstitution.config.show_lesson_observations" location="bottom">
                    <template #activator="{ props }">
                      <div v-bind="props">
                        <span>
                          <v-icon>mdi-file-eye</v-icon>
                        </span>
                        <span class="mr-4">{{ statement.lesson_observation_count }}</span>
                      </div>
                    </template>
                    {{
                      `${
                        statement.lesson_observation_count === 0 ? 'No' : statement.lesson_observation_count
                      } lesson observation${statement.lesson_observation_count === 1 ? '' : 's'} recorded`
                    }}
                  </v-tooltip>
                  <v-tooltip v-if="selectedInstitution.config.show_mentor_meetings" location="bottom">
                    <template #activator="{ props }">
                      <div v-bind="props">
                        <span>
                          <v-icon>mdi-recycle-variant</v-icon>
                        </span>
                        <span class="mr-4">{{ statement.mentor_meeting_count }}</span>
                      </div>
                    </template>
                    {{
                      `${statement.mentor_meeting_count === 0 ? 'No' : statement.mentor_meeting_count} mentor meeting${
                        statement.mentor_meeting_count === 1 ? '' : 's'
                      } recorded`
                    }}
                  </v-tooltip>
                </div>
              </template>

              <template #actions v-if="statement.curriculum_teaching_strategies.length > 0">
                <v-tooltip location="bottom">
                  <template #activator="{ props }">
                    <v-btn
                      :to="{ ...curriculumStatementClickTo(statement), query: { tab: 'teaching-strategies' } }"
                      class="ma-0"
                      icon
                      variant="flat"
                      v-bind="props"
                    >
                      <v-icon>
                        {{ mdiIcons.teachingStrategy }}
                      </v-icon>
                    </v-btn>
                  </template>

                  <div>
                    <div class="font-weight-bold">Teaching Strategies</div>
                    <ul class="pl-4">
                      <li v-for="ts in statement.curriculum_teaching_strategies.sortBy('order')" :key="ts.id">
                        {{ ts.name }}
                      </li>
                    </ul>
                  </div>
                </v-tooltip>
              </template>
            </mosaic-list-item>
          </template>
        </mosaic-list>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mdiIcons } from '@/utils/icons';
import { mapGetters, mapState } from 'vuex';
export default {
  name: 'CurriculumList',
  props: {
    curriculumThemes: {
      type: Array,
      required: true,
    },
    canSeeReflections: {
      type: Boolean,
      default: true,
    },
    curriculumStatementClickTo: {
      type: Function,
      required: true,
    },
  },
  emits: ['update:selectedCurriculumTheme'],
  data: function () {
    return {
      selectedCurriculumTheme: null,
      mdiIcons,
    };
  },
  created() {
    this.selectedCurriculumTheme = this.curriculumThemes[0]?.id;
  },
  watch: {
    selectedCurriculumTheme: function (theme) {
      this.$emit('update:selectedCurriculumTheme', theme);
    },
  },
  computed: {
    ...mapState(['user', 'selectedStudent', 'selectedInstitution']),
    ...mapGetters(['contactProviderMessage']),
    curriculumStatements() {
      if (!this.selectedCurriculumTheme) return [];
      const student = this.user.student || this.selectedStudent;
      const selectedThemeCurriculumStatements =
        this.curriculumThemes.find(x => x.id === this.selectedCurriculumTheme)?.curriculum_statements || [];
      return selectedThemeCurriculumStatements.filter(
        s =>
          s.subject_id === student.subject?.id ||
          s.subject_id === student.additional_subject?.id ||
          s.subject_id === null
      );
    },
    curriculumTheme() {
      return this.curriculumThemes.find(x => x.id === this.selectedCurriculumTheme);
    },
  },
};
</script>

<style>
.border-left {
  border-left: 1px solid !important;
}
.icon-flipped {
  transform: scaleX(-1);
  -moz-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  -ms-transform: scaleX(-1);
}
</style>
