export const evidenceTypes = [
  'Lesson Observation',
  'Lesson Plan',
  'Reflection',
  'Target',
  'Resource',
  'Mentor Meeting',
  'Course Activity',
  'Other',
];
export const fileEvidenceTypes = evidenceTypes.filter(x => !['Reflection', 'Target', 'Course Activity'].includes(x));
