<template>
  <div class="d-flex">
    <mosaic-completion-label :can-edit="canEdit" :completed="completed">
      <div class="text-h6 flex-grow-1 ml-2 d-flex flex-wrap align-center" style="row-gap: 8px; column-gap: 16px">
        <slot></slot>
      </div>
    </mosaic-completion-label>
  </div>
</template>

<script>
import MosaicCompletionLabel from './MosaicCompletionLabel.vue';

export default {
  name: 'ReviewPartTitle',
  components: { MosaicCompletionLabel },
  props: {
    canEdit: {
      type: Boolean,
      required: true,
    },
    completed: {
      type: Boolean,
      required: true,
    },
  },
};
</script>
