<template>
  <div>
    <mosaic-error-snackbar v-model="deleteError" action="delete this Course Target" />
    <mosaic-list
      :items="paginatedList"
      :empty-text="`There are no ${type} Course Targets. Add one by clicking the button above.`"
      class="py-0"
    >
      <template #item="{ item }">
        <mosaic-list-item
          :icon="icons.target"
          :title="item.goal"
          :subtitle="typeof subtitle === 'function' ? subtitle(item) : subtitle"
          :to="clickTargetTo(item)"
        >
          <template #information>
            <cohort-action-curriculum-links-badge :curriculum-statements="item.curriculum_statements" />
            <mosaic-trainee-count-chip :count="item.admin_target_students.length" object-type="Course Target" />
          </template>

          <template #actions>
            <mosaic-icon-btn icon="content-copy" tooltip="Copy Course Target" @click.prevent="copy(item)" />
            <mosaic-delete-icon-btn
              object-type="Course Target"
              :disabled="deleteProcessing"
              @click.prevent="deleteCourseTarget(item)"
            />
          </template>
        </mosaic-list-item>
      </template>
    </mosaic-list>
    <mosaic-pagination v-model="currentPage" v-model:page-size="pageSize" :total="paginationTotal" />
    <mosaic-delete-dialog
      v-model:active="deleteDialog.active"
      :url="deleteDialog.url"
      object-type="Course Target"
      :object-name="deleteDialog.name"
      :deleting-student-data="deleteDialog.deletingStudentData"
      @delete="emit('update:triggerBackgroundLoad', true)"
    >
      <cohort-action-delete-dialog-information
        v-if="deleteDialog.deletingStudentData"
        action-noun="Course Target"
        :status-items="targetStatusItems"
        :student-ids-to-be-removed="deleteDialog.adminTargetStudents.map(s => s.student_id)"
        :get-action-status="getTargetStatus"
      />
    </mosaic-delete-dialog>

    <mosaic-save-dialog
      v-model:active="copyDialog.active"
      title="Copy Course Target"
      object-type="Course Target"
      action="Copy"
      action-redirect="Copy and Edit"
      :save="submitCopy"
      :can-save="!!copyDialog.goal"
      @save="triggerBackgroundLoad = true"
      :redirect-to="clickNewDraftTargetTo"
    >
      <template #default="{ onKeyupEnter }">
        <mosaic-text-field
          v-model="copyDialog.goal"
          name="copy-name"
          action="copy"
          label="Name"
          prepend-icon="mdi-pencil"
          @keyup.enter="onKeyupEnter"
        />

        <mosaic-select
          v-if="cohorts.length > 1"
          v-model="selectedCohortToCopy"
          name="select-cohort"
          label="New Cohort"
          :items="cohorts"
          item-value="id"
          item-title="name"
          no-data-text="No cohort found"
          prepend-icon="mdi-domain"
          return-object
        >
          <template #item="{ item, props: sProps }">
            <v-list-item v-bind="sProps">
              <template #append>
                <mosaic-tooltip-chip v-if="item.raw.status === 'closed'" color="secondary"
                  ><template #text>Closed</template
                  ><template #tooltip> This cohort is closed</template></mosaic-tooltip-chip
                >
              </template>
            </v-list-item>
          </template>
        </mosaic-select>
        <mosaic-info-alert>
          <div>
            {{
              selectedCohort.id !== selectedCohortToCopy.id
                ? `The Course Target will be copied in draft state without any ${traineeNounCapitalisedAndPluralised}.`
                : `The Course Target will be copied in draft state with the same ${traineeNounCapitalisedAndPluralised}.`
            }}
          </div>
          <div class="pt-2" v-if="copyDialog.curriculum_statements.length > 0 && !shareCurriculum">
            The Curriculum Links will be removed from the copied Course Target because the Curriculums do not match.
          </div>
        </mosaic-info-alert>
      </template>
    </mosaic-save-dialog>
  </div>
</template>

<script setup lang="ts">
import { paginateList } from '@/components/library/pagination/pagination';
import { withProcessingAndError } from '@/composables/processing-and-errors';
import { icons } from '@/utils/icons';
import { computed, ref } from 'vue';
import type { RouteLocationNamedRaw } from 'vue-router';
import CohortActionDeleteDialogInformation from '@/components/cohort-actions/CohortActionDeleteDialogInformation.vue';
import { targetStatusItems } from './targets';
import type { CourseTarget as CourseTargetFull } from './CohortCourseTargetEditPage.vue';
import { useApi } from '@/composables/api';
import { mapState } from '@/store/map-store';
import type { TargetResponse } from '@/types/responses';
import CohortActionCurriculumLinksBadge from '@/components/cohort-actions/CohortActionCurriculumLinksBadge.vue';

interface CopyDialog {
  active: boolean;
  id: number | null;
  goal: string;
  admin_target_students: CourseTargetFull['admin_target_students'];
  curriculum_statements: CourseTargetFull['curriculum_statements'];
  impactGoal: string | null;
  selected_all: boolean | null;
  target_actions: [];
}

const api = useApi();

const props = defineProps<{
  targets: CourseTargetFull[];
  cohorts: { name: string; id: number; curriculum_id: number | null; status: string }[];
  type: 'live' | 'scheduled' | 'draft';
  subtitle: string | ((target: CourseTargetFull) => string);
  clickTargetTo: (target: CourseTargetFull) => RouteLocationNamedRaw;
}>();

const emit = defineEmits<{
  (e: 'update:triggerBackgroundLoad', x: boolean): void;
}>();

const { paginatedList, currentPage, pageSize, paginationTotal } = paginateList(computed(() => props.targets));

const deleteDialog = ref({
  active: false,
  url: '',
  name: '',
  deletingStudentData: false,
  adminTargetStudents: [] as CourseTargetFull['admin_target_students'],
});

const {
  action: deleteCourseTarget,
  processing: deleteProcessing,
  error: deleteError,
} = withProcessingAndError(async (target: CourseTargetFull) => {
  let deletingStudentData = false;
  let adminTargetStudents: CourseTargetFull['admin_target_students'] = [];
  if (target.set_date) {
    // This should check whether any targets have been started, but we don't have that concept yet
    const r = await api.get<CourseTargetFull>(`admin-targets/${target.id}`);
    adminTargetStudents = r.data.admin_target_students;
    deletingStudentData = true;
  }
  deleteDialog.value = {
    active: true,
    url: `admin-targets/${target.id}`,
    name: target.goal,
    adminTargetStudents,
    deletingStudentData,
  };
});

function getTargetStatus(studentId: number) {
  return deleteDialog.value.adminTargetStudents.find(s => s.student_id === studentId)?.status;
}
const triggerBackgroundLoad = ref(false);

const { selectedCohort } = mapState();

const selectedCohortToCopy = ref({
  name: selectedCohort.value.name,
  id: selectedCohort.value.id,
  curriculum_id: selectedCohort.value.curriculum_id,
});

// Copy
const copyDialog = ref<CopyDialog>({
  active: false,
  id: 0,
  goal: '',
  admin_target_students: [] as CourseTargetFull['admin_target_students'],
  curriculum_statements: [] as CourseTargetFull['curriculum_statements'],
  impactGoal: '',
  selected_all: null,
  target_actions: [],
});

const shareCurriculum = computed(() => {
  return selectedCohort.value.curriculum_id === selectedCohortToCopy.value.curriculum_id;
});

async function copy(target: CourseTargetFull) {
  selectedCohortToCopy.value = {
    name: selectedCohort.value.name,
    id: selectedCohort.value.id,
    curriculum_id: selectedCohort.value.curriculum_id,
  };
  copyDialog.value.active = true;
  copyDialog.value.goal = `${target.goal} (copy)`;
  copyDialog.value.id = target.id;
  copyDialog.value.admin_target_students = [...target.admin_target_students];
  copyDialog.value.curriculum_statements = [...target.curriculum_statements];
  copyDialog.value.impactGoal = target.impact_goal;
  copyDialog.value.selected_all = target.selected_all;
  copyDialog.value.target_actions = [];
}

const redirectedTargetId = ref(-1);

const clickNewDraftTargetTo = computed(() => ({
  name: 'CohortCourseTargetEditPage',
  params: { cohortId: selectedCohortToCopy.value?.id, id: redirectedTargetId.value },
  query: { fromTab: 'draft' },
}));

async function submitCopy() {
  const copyToSameCohort = selectedCohort.value.id === selectedCohortToCopy?.value?.id;

  const requestBody = {
    goal: copyDialog.value.goal || null,
    sourceAdminTargetId: copyDialog.value.id,
    copyTargetFromId: selectedCohort.value.id,
    cohortId: selectedCohortToCopy.value.id,
    studentIds:
      copyToSameCohort && copyDialog.value?.admin_target_students && copyDialog.value?.admin_target_students?.length > 0
        ? copyDialog.value.admin_target_students.map(s => s.student_id)
        : [],
    curriculumStatementIds:
      shareCurriculum.value &&
      copyDialog.value?.curriculum_statements &&
      copyDialog.value?.curriculum_statements.length &&
      copyDialog.value?.curriculum_statements.length > 0
        ? copyDialog.value.curriculum_statements.map(s => s.id)
        : [],
    selectedAll: copyToSameCohort && copyDialog.value?.selected_all ? copyDialog.value.selected_all : null,
  };
  const r = (await api.post(`/cohorts/${selectedCohortToCopy.value.id}/admin-targets-copy`, requestBody)) as {
    data: TargetResponse;
  };
  redirectedTargetId.value = r.data.id;
  emit('update:triggerBackgroundLoad', true);
}
</script>
