import store from '../../store';
import { ectStatusItems } from './ect';
import { firstName, lastName } from '../text';
import type { EctRegistrationApiResponse } from './ect-registrations';
import { ecfTermsCompletedItems } from './ect';
import moment from 'moment';

const fullHeaders = [
  'Package',
  'School Name',
  'Setting Type',
  'Multi-Academy Trust',
  'URN',
  'Address Line 1',
  'Address Line 2',
  'City',
  'Post Code',
  'Local Authority',
  'Country',
  'Phone number',
  'Finance Lead First Name',
  'Finance Lead Last Name',
  'Finance Office Email Address',
  'School Details Confirmed',
  'School Detail Changes',
  'ECT First Name',
  'ECT Last Name',
  'ECT Email Address',
  'ECT DOB',
  'TRN',
  'Date Awarded QTS',
  'Induction Start Date at School',
  'Registration Status',
  'Previous Induction Terms',
  'Previous Induction Details',
  'Prior Reviews/Assessments Obtained?',
  'Full Time',
  'Part Time',
  'Contract Type',
  'Temp/Fixed Term/Supply Contract Start Date',
  'Temp/Fixed Term/Supply Contract End Date',
  'Other Contract Details',
  'Year groups being taught',
  'Subjects being taught',
  'ECT has been DBS checked',
  'ECTs contract satisfies the statutory requirements',
  'Teacher Training Institution',
  'Qualifications awarded',
  'Qualified subjects',
  'Qualified age ranges',
  'ECF Programme',
  'ECF Provider',
  'ECF Cohort',
  'Headteacher/Principal First Name',
  'Headteacher/Principal Last Name',
  'Headteacher/Principal Email Address',
  'Induction Tutor First Name',
  'Induction Tutor Last Name',
  'Induction Tutor Email Address',
  'ECF Mentor First Name',
  'ECF Mentor Last Name',
  'ECF Mentor TRN',
  'ECF Mentor Email Address',
  'ECF Mentor Job Title',
  'If this mentor has ever undertaken ECF training in the past, please give details below of date period(s) and provider(s) so that we can allocate the correct seminar programme for them. If none, please write N/A',
  'Induction Coordinator/Lead First Name',
  'Induction Coordinator/Lead Last Name',
  'Induction Coordinator/Lead Email Address',
  'Induction Coordinator/Lead Job Title',
  'First name of person completing this form',
  'Last name of person completing this form',
  'Email address of person completing this form',
  'I confirm the details in this form are correct',
  'I agree to the terms of the SLA',
  'I agree to receive marketing communications from Bright Futures',
  'Invoice Reference 1',
  'Invoice Reference 2',
  'Internal AB Cohort',
  'Date and time registration submitted',
] as const;

type FullHeadersTuple = typeof fullHeaders;
type Header = FullHeadersTuple[number];

type Institution = {
  ab_sla_link: string;
  ab_only_ect_package_name: string;
  ab_and_ecf_ect_package_name: string;
};

function getPackageName(ectPackage: string): string {
  const selectedInstitution = store.state.selectedInstitution as unknown as Institution;
  if (ectPackage === 'ab_only') {
    return selectedInstitution.ab_only_ect_package_name;
  } else {
    return selectedInstitution.ab_and_ecf_ect_package_name;
  }
}

const notApplicable = 'N/A';

function getEctStatus(statusValue: string): string {
  const status = ectStatusItems.find(x => x.value === statusValue);
  return status ? status.title : notApplicable;
}

export type Column =
  | {
      header: Header;
      type: 'string';
      dataTransform: (data: string) => string;
      excludeFromEcfExport: boolean;
      data: (r: EctRegistrationApiResponse) => string;
    }
  | {
      header: Header;
      type: 'nullOrString';
      dataTransform: (data: string | null | undefined) => string;
      excludeFromEcfExport: boolean;
      data: (r: EctRegistrationApiResponse) => string | undefined | null;
    }
  | {
      header: Header;
      type: 'nullOrNumber';
      dataTransform: (data: number | null | undefined) => string;
      excludeFromEcfExport: boolean;
      data: (r: EctRegistrationApiResponse) => number | undefined | null;
    }
  | {
      header: Header;
      type: 'number';
      dataTransform: (data: number) => string;
      excludeFromEcfExport: boolean;
      data: (r: EctRegistrationApiResponse) => number;
    }
  | {
      header: Header;
      type: 'string[]';
      dataTransform: (data: string[]) => string;
      excludeFromEcfExport: boolean;
      data: (r: EctRegistrationApiResponse) => string[];
    }
  | {
      header: Header;
      type: 'alwaysYes';
      excludeFromEcfExport: boolean;
    }
  | {
      header: Header;
      type: 'boolean';
      dataTransform: (data: boolean) => 'Yes' | 'No';
      excludeFromEcfExport: boolean;
      data: (r: EctRegistrationApiResponse) => boolean;
    }
  | {
      header: Header;
      type: 'nullOrBoolean';
      dataTransform: (data: boolean | null | undefined) => 'Yes' | 'No' | 'N/A';
      excludeFromEcfExport: boolean;
      data: (r: EctRegistrationApiResponse) => boolean | undefined | null;
    };

export function getHeaders() {
  const selectedInstitution = store.state.selectedInstitution as unknown as Institution;
  const headersArray: Column[] = [
    {
      header: 'Package',
      type: 'string',
      dataTransform: (data: string) => getPackageName(data),
      excludeFromEcfExport: false,
      data: (r: EctRegistrationApiResponse) => r.institutionEctPackage,
    },
    {
      header: 'School Name',
      type: 'string',
      dataTransform: (data: string) => data,
      data: (r: EctRegistrationApiResponse) => r.registrationSchool.name,
      excludeFromEcfExport: false,
    },
    {
      header: 'Setting Type',
      type: 'string',
      dataTransform: (data: string) => data,
      data: (r: EctRegistrationApiResponse) => r.registrationSchool.settingType,
      excludeFromEcfExport: false,
    },
    {
      header: 'Multi-Academy Trust',
      type: 'string',
      dataTransform: (data: string) => data,
      data: (r: EctRegistrationApiResponse) => r.registrationSchool.mat,
      excludeFromEcfExport: false,
    },
    {
      header: 'URN',
      type: 'string',
      dataTransform: (data: string) => data,
      data: (r: EctRegistrationApiResponse) => r.registrationSchool.urn,
      excludeFromEcfExport: false,
    },
    {
      header: 'Address Line 1',
      type: 'string',
      dataTransform: (data: string) => data,
      data: (r: EctRegistrationApiResponse) => r.registrationSchool.addressFirstLine,
      excludeFromEcfExport: false,
    },
    {
      header: 'Address Line 2',
      type: 'string',
      dataTransform: (data: string) => data,
      data: (r: EctRegistrationApiResponse) => r.registrationSchool.addressSecondLine,
      excludeFromEcfExport: false,
    },
    {
      header: 'City',
      type: 'string',
      dataTransform: (data: string) => data,
      data: (r: EctRegistrationApiResponse) => r.registrationSchool.addressCity,
      excludeFromEcfExport: false,
    },
    {
      header: 'Post Code',
      type: 'string',
      dataTransform: (data: string) => data,
      data: (r: EctRegistrationApiResponse) => r.registrationSchool.addressPostcode,
      excludeFromEcfExport: false,
    },
    {
      header: 'Local Authority',
      type: 'string',
      dataTransform: (data: string) => data,
      data: (r: EctRegistrationApiResponse) => r.registrationSchool.localAuthority,
      excludeFromEcfExport: false,
    },
    {
      header: 'Country',
      type: 'string',
      dataTransform: (data: string) => data,
      data: (r: EctRegistrationApiResponse) => r.registrationSchool.addressCountry,
      excludeFromEcfExport: true,
    },
    {
      header: 'Phone number',
      type: 'string',
      dataTransform: (data: string) => data,
      data: (r: EctRegistrationApiResponse) => r.registrationSchool.phoneNumber,
      excludeFromEcfExport: false,
    },
    {
      header: 'Finance Lead First Name',
      type: 'string',
      dataTransform: (data: string) => firstName(data),
      data: (r: EctRegistrationApiResponse) => r.registrationSchool.financeLeadName,
      excludeFromEcfExport: true,
    },
    {
      header: 'Finance Lead Last Name',
      type: 'string',
      dataTransform: (data: string) => lastName(data),
      data: (r: EctRegistrationApiResponse) => r.registrationSchool.financeLeadName,
      excludeFromEcfExport: true,
    },
    {
      header: 'Finance Office Email Address',
      type: 'string',
      dataTransform: (data: string) => data,
      data: (r: EctRegistrationApiResponse) => r.registrationSchool.financeEmail,
      excludeFromEcfExport: true,
    },
    {
      header: 'School Details Confirmed',
      type: 'boolean',
      dataTransform: (data: boolean) => (data ? 'Yes' : 'No'),
      data: (r: EctRegistrationApiResponse) => r.schoolDetailsConfirmed,
      excludeFromEcfExport: true,
    },
    {
      header: 'School Detail Changes',
      type: 'string',
      dataTransform: (data: string) => data || notApplicable,
      data: (r: EctRegistrationApiResponse) => r.schoolDetailChanges,
      excludeFromEcfExport: true,
    },
    {
      header: 'ECT First Name',
      type: 'string',
      dataTransform: (data: string) => firstName(data),
      data: (r: EctRegistrationApiResponse) => r.name,
      excludeFromEcfExport: false,
    },
    {
      header: 'ECT Last Name',
      type: 'string',
      dataTransform: (data: string) => lastName(data),
      data: (r: EctRegistrationApiResponse) => r.name,
      excludeFromEcfExport: false,
    },
    {
      header: 'ECT Email Address',
      type: 'string',
      dataTransform: (data: string) => data,
      data: (r: EctRegistrationApiResponse) => r.email,
      excludeFromEcfExport: false,
    },
    {
      header: 'ECT DOB',
      type: 'string',
      dataTransform: (data: string) => (data ? moment(data).format('DD/MM/YYYY') : 'Not Recorded'),
      data: (r: EctRegistrationApiResponse) => r.dateOfBirth,
      excludeFromEcfExport: false,
    },
    {
      header: 'TRN',
      type: 'string',
      dataTransform: (data: string) => data,
      data: (r: EctRegistrationApiResponse) => r.trn || notApplicable,
      excludeFromEcfExport: false,
    },
    {
      header: 'Date Awarded QTS',
      type: 'string',
      dataTransform: (data: string) => (data ? moment(data).format('DD/MM/YYYY') : 'Not Recorded'),
      data: (r: EctRegistrationApiResponse) => r.dateAwardedQts,
      excludeFromEcfExport: false,
    },
    {
      header: 'Induction Start Date at School',
      type: 'string',
      dataTransform: (data: string) => moment(data).format('DD/MM/YYYY'),
      data: (r: EctRegistrationApiResponse) => r.startDate,
      excludeFromEcfExport: false,
    },
    {
      header: 'Registration Status',
      type: 'string',
      dataTransform: (data: string) => getEctStatus(data),
      data: (r: EctRegistrationApiResponse) => r.status,
      excludeFromEcfExport: false,
    },
    {
      header: 'Previous Induction Terms',
      type: 'number',
      dataTransform: (data: number) => data.toString(),
      data: (r: EctRegistrationApiResponse) => r.startTermsAlreadyCompleted,
      excludeFromEcfExport: false,
    },
    {
      header: 'Previous Induction Details',
      type: 'string',
      dataTransform: (data: string) => data || notApplicable,
      data: (r: EctRegistrationApiResponse) => r.previousInductionDetails,
      excludeFromEcfExport: false,
    },
    {
      header: 'Prior Reviews/Assessments Obtained?',
      type: 'nullOrBoolean',
      dataTransform: (data: boolean | undefined | null) =>
        data === null || data === undefined ? notApplicable : data ? 'Yes' : 'No',
      data: (r: EctRegistrationApiResponse) => r.priorReviewsObtained,
      excludeFromEcfExport: true,
    },
    {
      header: 'Full Time',
      type: 'number',
      dataTransform: (data: number) => (data === 1 ? 'Full Time' : notApplicable),
      data: (r: EctRegistrationApiResponse) => r.startFteRate,
      excludeFromEcfExport: false,
    },
    {
      header: 'Part Time',
      type: 'number',
      dataTransform: (data: number) => (data < 1 ? data.toString() : notApplicable),
      data: (r: EctRegistrationApiResponse) => r.startFteRate,
      excludeFromEcfExport: false,
    },
    {
      header: 'Contract Type',
      type: 'string',
      dataTransform: (data: string) => data,
      data: (r: EctRegistrationApiResponse) => r.contractType,
      excludeFromEcfExport: false,
    },
    {
      header: 'Temp/Fixed Term/Supply Contract Start Date',
      type: 'nullOrString',
      dataTransform: (data: string | null | undefined) => (data ? moment(data).format('DD/MM/YYYY') : notApplicable),
      data: (r: EctRegistrationApiResponse) => r.contractStartDate,
      excludeFromEcfExport: false,
    },
    {
      header: 'Temp/Fixed Term/Supply Contract End Date',
      type: 'nullOrString',
      dataTransform: (data?: string | null | undefined) => (data ? moment(data).format('DD/MM/YYYY') : notApplicable),
      data: (r: EctRegistrationApiResponse) => r.contractEndDate,
      excludeFromEcfExport: false,
    },
    {
      header: 'Other Contract Details',
      type: 'nullOrString',
      dataTransform: (data: string | null | undefined) => data || notApplicable,
      data: (r: EctRegistrationApiResponse) => r.contractDetails,
      excludeFromEcfExport: false,
    },
    {
      header: 'Year groups being taught',
      type: 'string[]',
      dataTransform: (data: string[]) => data.join(', '),
      data: (r: EctRegistrationApiResponse) => r.yearGroups,
      excludeFromEcfExport: false,
    },
    {
      header: 'Subjects being taught',
      type: 'string[]',
      dataTransform: (data: string[]) => data.join(', '),
      data: (r: EctRegistrationApiResponse) => r.teachingSubjects,
      excludeFromEcfExport: false,
    },
    {
      header: 'ECF Provider',
      type: 'string',
      dataTransform: (data: string) => data || notApplicable,
      data: (r: EctRegistrationApiResponse) => r.ecfProvider,
      excludeFromEcfExport: true,
    },
    {
      header: 'ECT has been DBS checked',
      type: 'alwaysYes',
      excludeFromEcfExport: true,
    },
    {
      header: 'ECTs contract satisfies the statutory requirements',
      type: 'alwaysYes',
      excludeFromEcfExport: true,
    },
    {
      header: 'Teacher Training Institution',
      type: 'string',
      dataTransform: (data: string) => data,
      data: (r: EctRegistrationApiResponse) => r.teacherTrainingInstitution,
      excludeFromEcfExport: true,
    },
    {
      header: 'Qualifications awarded',
      type: 'string[]',
      dataTransform: (data: string[]) => data.join(', '),
      data: (r: EctRegistrationApiResponse) => r.qualificationsAwarded,
      excludeFromEcfExport: true,
    },
    {
      header: 'Qualified subjects',
      type: 'string[]',
      dataTransform: (data: string[]) => data.join(', '),
      data: (r: EctRegistrationApiResponse) => r.qualifiedSubjects,
      excludeFromEcfExport: true,
    },
    {
      header: 'Qualified age ranges',
      type: 'string[]',
      dataTransform: (data: string[]) => data.join(', '),
      data: (r: EctRegistrationApiResponse) => r.qualifiedAgeRanges,
      excludeFromEcfExport: true,
    },
    {
      header: 'ECF Programme',
      type: 'string',
      dataTransform: (data: string) => data,
      data: (r: EctRegistrationApiResponse) => r.ecfProgramme,
      excludeFromEcfExport: true,
    },
    {
      header: 'ECF Cohort',
      type: 'nullOrNumber',
      dataTransform: (data: number | null | undefined) =>
        ecfTermsCompletedItems.find(x => data === x.termsCompleted)?.text || notApplicable,
      data: (r: EctRegistrationApiResponse) => r.ecfTermsCompleted,
      excludeFromEcfExport: false,
    },
    {
      header: 'Headteacher/Principal First Name',
      type: 'nullOrString',
      dataTransform: (data: string | null | undefined) => (data ? firstName(data) : 'Not assigned'),
      data: (r: EctRegistrationApiResponse) => r.headteacherName,
      excludeFromEcfExport: false,
    },
    {
      header: 'Headteacher/Principal Last Name',
      type: 'nullOrString',
      dataTransform: (data: string | null | undefined) => (data ? lastName(data) : 'Not assigned'),
      data: (r: EctRegistrationApiResponse) => r.headteacherName,
      excludeFromEcfExport: false,
    },
    {
      header: 'Headteacher/Principal Email Address',
      type: 'nullOrString',
      dataTransform: (data: string | null | undefined) => (data ? data : 'Not assigned'),
      data: (r: EctRegistrationApiResponse) => r.headteacherEmail,
      excludeFromEcfExport: false,
    },
    {
      header: 'Induction Tutor First Name',
      type: 'nullOrString',
      dataTransform: (data: string | null | undefined) => (data ? firstName(data) : 'Not assigned'),
      data: (r: EctRegistrationApiResponse) => r.inductionTutorName,
      excludeFromEcfExport: false,
    },
    {
      header: 'Induction Tutor Last Name',
      type: 'nullOrString',
      dataTransform: (data: string | null | undefined) => (data ? lastName(data) : 'Not assigned'),
      data: (r: EctRegistrationApiResponse) => r.inductionTutorName,
      excludeFromEcfExport: false,
    },
    {
      header: 'Induction Tutor Email Address',
      type: 'nullOrString',
      dataTransform: (data: string | null | undefined) => (data ? data : 'Not assigned'),
      data: (r: EctRegistrationApiResponse) => r.inductionTutorEmail,
      excludeFromEcfExport: false,
    },
    {
      header: 'ECF Mentor First Name',
      type: 'string',
      dataTransform: (data: string) => firstName(data),
      data: (r: EctRegistrationApiResponse) => r.mentorName,
      excludeFromEcfExport: false,
    },
    {
      header: 'ECF Mentor Last Name',
      type: 'string',
      dataTransform: (data: string) => lastName(data),
      data: (r: EctRegistrationApiResponse) => r.mentorName,
      excludeFromEcfExport: false,
    },
    {
      header: 'ECF Mentor TRN',
      type: 'string',
      dataTransform: (data: string) => data || notApplicable,
      data: (r: EctRegistrationApiResponse) => r.mentorTrn,
      excludeFromEcfExport: false,
    },
    {
      header: 'ECF Mentor Email Address',
      type: 'string',
      dataTransform: (data: string) => data,
      data: (r: EctRegistrationApiResponse) => r.mentorEmail,
      excludeFromEcfExport: false,
    },
    {
      header: 'ECF Mentor Job Title',
      type: 'string',
      dataTransform: (data: string) => data,
      data: (r: EctRegistrationApiResponse) => r.mentorJobTitle,
      excludeFromEcfExport: true,
    },
    {
      header:
        'If this mentor has ever undertaken ECF training in the past, please give details below of date period(s) and provider(s) so that we can allocate the correct seminar programme for them. If none, please write N/A',
      type: 'string',
      dataTransform: (data: string) => data || notApplicable,
      data: (r: EctRegistrationApiResponse) => r.mentorTrainingDetails,
      excludeFromEcfExport: false,
    },
    {
      header: 'Induction Coordinator/Lead First Name',
      type: 'string',
      dataTransform: (data: string) => firstName(data),
      data: (r: EctRegistrationApiResponse) => r.coordinatorName,
      excludeFromEcfExport: false,
    },
    {
      header: 'Induction Coordinator/Lead Last Name',
      type: 'string',
      dataTransform: (data: string) => lastName(data),
      data: (r: EctRegistrationApiResponse) => r.coordinatorName,
      excludeFromEcfExport: false,
    },
    {
      header: 'Induction Coordinator/Lead Email Address',
      type: 'string',
      dataTransform: (data: string) => data,
      data: (r: EctRegistrationApiResponse) => r.coordinatorEmail,
      excludeFromEcfExport: false,
    },
    {
      header: 'Induction Coordinator/Lead Job Title',
      type: 'string',
      dataTransform: (data: string) => data,
      data: (r: EctRegistrationApiResponse) => r.coordinatorJobTitle,
      excludeFromEcfExport: true,
    },
    {
      header: 'First name of person completing this form',
      type: 'string',
      dataTransform: (data: string) => firstName(data),
      data: (r: EctRegistrationApiResponse) => r.registeringName,
      excludeFromEcfExport: false,
    },
    {
      header: 'Last name of person completing this form',
      type: 'string',
      dataTransform: (data: string) => lastName(data),
      data: (r: EctRegistrationApiResponse) => r.registeringName,
      excludeFromEcfExport: false,
    },
    {
      header: 'Email address of person completing this form',
      type: 'string',
      dataTransform: (data: string) => data,
      data: (r: EctRegistrationApiResponse) => r.registeringEmail,
      excludeFromEcfExport: false,
    },
    {
      header: 'I confirm the details in this form are correct',
      type: 'alwaysYes',
      excludeFromEcfExport: true,
    },
  ];
  if (selectedInstitution.ab_sla_link) {
    headersArray.push({
      header: 'I agree to the terms of the SLA',
      type: 'alwaysYes',
      excludeFromEcfExport: true,
    });
  }
  if (store.getters.isBrightFuturesAB) {
    headersArray.push(
      {
        header: 'I agree to receive marketing communications from Bright Futures',
        type: 'boolean',
        dataTransform: (data: boolean) => (data ? 'Yes' : 'No'),
        data: (r: EctRegistrationApiResponse) => r.marketingAgreed,
        excludeFromEcfExport: true,
      },
      {
        header: 'Invoice Reference 1',
        type: 'string',
        dataTransform: (data: string) => data,
        data: (r: EctRegistrationApiResponse) => r.invoiceRef1,
        excludeFromEcfExport: true,
      },
      {
        header: 'Invoice Reference 2',
        type: 'string',
        dataTransform: (data: string) => data,
        data: (r: EctRegistrationApiResponse) => r.invoiceRef2,
        excludeFromEcfExport: true,
      },
      {
        header: 'Internal AB Cohort',
        type: 'string',
        dataTransform: (data: string) => data,
        data: (r: EctRegistrationApiResponse) => r.ecfCohort,
        excludeFromEcfExport: true,
      }
    );
  }
  headersArray.push({
    header: 'Date and time registration submitted',
    type: 'string',
    dataTransform: (data: string) => moment(data).format('DD/MM/YYYY [ - ] HH:mm'),
    data: (r: EctRegistrationApiResponse) => r.registrationDateTime,
    excludeFromEcfExport: false,
  });
  return headersArray;
}
