<template>
  <div>
    <v-card class="mb-4">
      <v-card-text>
        <div class="text-h6">
          {{ standard ? standardNounCapitalised + ': ' + standard.code + ' - ' + standard.name : 'Loading...' }}
        </div>
        <v-switch v-model="onlyFavourites" label="Only show best evidence" color="accent" hide-details></v-switch>
      </v-card-text>
    </v-card>

    <div v-if="showStorageMessage" class="mx-6 pt-2">
      <v-alert type="info">
        <div>
          In order to open {{ user.student ? 'your' : traineeNoun() }} files linked as Evidence, you must link a storage
          account.
        </div>
        <div v-if="storageConstraint" class="pt-2">{{ storageConstraintMessage }}</div>
      </v-alert>
      <v-alert v-if="storageEmailError" type="error"
        ><div>{{ storageEmailError }}</div>
        <div class="mt-2">
          You may need to <a href="https://login.microsoftonline.com/logout.srf" target="_blank">logout</a> of your One
          Drive account first.
        </div>
      </v-alert>
      <div class="d-flex">
        <div class="flex-grow-1" />
        <div class="mb-4 align-right">
          <v-btn class="ml-6" @click.prevent="navigateToStorageLogin()"
            >Confirm {{ selectedInstitution.storage_type }} Email</v-btn
          >
        </div>
      </div>
    </div>

    <template v-if="standardEvidence">
      <v-row>
        <v-col cols="12">
          <portfolio-evidence-card
            icon="file-document"
            :evidence="standardEvidence.evidenceFile"
            title="Files"
            :edit-evidence="editEvidence"
            :delete-evidence="deleteEvidence"
            :only-favourites="onlyFavourites"
            @click-favourite="favouriteEvidenceClick"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <portfolio-evidence-card
            icon="near-me"
            :evidence="standardEvidence.target"
            title="Targets"
            :edit-evidence="editEvidence"
            :delete-evidence="deleteEvidence"
            :only-favourites="onlyFavourites"
            @click-favourite="favouriteEvidenceClick"
            @click-target="x => $emit('clickTarget', x)"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <portfolio-evidence-card
            v-if="!selectedInstitution.config.early_careers"
            icon="mirror"
            :evidence="standardEvidence.reflection"
            title="Reflections"
            :can-view-reflections="canViewReflections"
            :edit-evidence="editEvidence"
            :delete-evidence="deleteEvidence"
            :only-favourites="onlyFavourites"
            @click-favourite="favouriteEvidenceClick"
            @click-reflection="x => $emit('clickReflection', x)"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <portfolio-evidence-card
            v-if="!selectedInstitution.config.early_careers && selectedInstitution.config.show_lesson_observations"
            icon="file-eye"
            :evidence="standardEvidence.lessonObservation"
            title="Lesson Observations"
            :edit-evidence="editEvidence"
            :delete-evidence="deleteEvidence"
            :only-favourites="onlyFavourites"
            @click-favourite="favouriteEvidenceClick"
            @click-lesson-observation="x => $emit('clickLessonObservation', x)"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <portfolio-evidence-card
            v-if="!selectedInstitution.config.early_careers && selectedInstitution.config.show_mentor_meetings"
            icon="recycle-variant"
            :evidence="standardEvidence.mentorMeeting"
            title="Mentor Meetings"
            :edit-evidence="editEvidence"
            :delete-evidence="deleteEvidence"
            :only-favourites="onlyFavourites"
            @click-favourite="favouriteEvidenceClick"
            @click-mentor-meeting="x => $emit('clickMentorMeeting', x)"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <portfolio-evidence-card
            v-if="!selectedInstitution.config.early_careers && showAssignments"
            icon="clipboard-text"
            :evidence="standardEvidence.courseActivity"
            title="Course Activities"
            :edit-evidence="editEvidence"
            :delete-evidence="deleteEvidence"
            :only-favourites="onlyFavourites"
            @click-favourite="favouriteEvidenceClick"
            @click-student-assignment="x => $emit('clickStudentAssignment', x)"
          />
        </v-col>
      </v-row>
    </template>
    <EditEvidenceDialog
      v-model:active="editEvidenceDialog.active"
      :on-close="evidenceEdited"
      :evidence-types="types"
      :evidence="editEvidenceDialog.evidence"
    />
    <ndt-dialog v-model:active="deleteDialog.active" title="Delete Evidence" :width="500">
      <span>Are you sure you want to delete "{{ deleteDialog.title }}"?</span>
      <template #buttons>
        <v-btn
          variant="text"
          ripple
          color="error"
          :disabled="deleteDialog.processing"
          @click.prevent="submitDeleteEvidence()"
        >
          Delete
        </v-btn>
      </template>
    </ndt-dialog>
  </div>
</template>

<script>
import PortfolioEvidenceCard from './PortfolioEvidenceCard.vue';
import { evidenceTypes } from '../models/evidence-types';
import NdtDialog from './NdtDialog.vue';
import EditEvidenceDialog from './student/EditEvidenceDialog.vue';
import { mapState, mapGetters } from 'vuex';
import config from '../utils/config';
import { storageConstraintMessage, storageEmailError } from '../utils/storage-constraints';
import {
  startStudentLinkOneDriveFlow,
  startStaffLinkOneDriveEmailMicrosoftFlow,
  startStudentLinkGoogleDriveFlow,
  startStaffLinkGoogleDriveEmailFlow,
} from '../utils/external-auth';

export default {
  props: {
    standard: {
      type: Object,
      required: true,
    },
    standardEvidence: {
      type: Object,
      required: true,
    },
    canViewReflections: {
      type: Boolean,
      required: true,
    },
    storageConstraint: {
      type: Object,
      default: undefined,
    },
  },
  emits: [
    'clickTarget',
    'clickReflection',
    'clickLessonObservation',
    'clickMentorMeeting',
    'clickStudentAssignment',
    'evidenceEdited',
    'clickFavourite',
    'evidenceDeleted',
  ],
  data() {
    return {
      editEvidenceDialog: {
        active: false,
        evidence: {},
      },
      deleteDialog: {
        active: false,
        id: null,
        title: '',
        processing: false,
      },
      standardId: null,
      substandards: [],
      types: evidenceTypes,
      config: config,
      onlyFavourites: false,
    };
  },
  computed: {
    ...mapState(['user', 'selectedInstitution']),
    ...mapGetters(['storageSetUp', 'useOnDemandSharing', 'showAssignments']),
    storageConstraintMessage() {
      if (!this.storageConstraint) return false;
      return storageConstraintMessage(this.storageConstraint);
    },
    storageEmailError() {
      return storageEmailError(this.$route.query, this.storageConstraint);
    },
    showStorageMessage() {
      return (
        this.selectedInstitution.storage_type != 'S3' &&
        !this.useOnDemandSharing &&
        !this.storageSetUp &&
        this.standardEvidence.evidenceFile.length > 0
      );
    },
  },
  created() {
    this.standardId = this.standard.id;
  },

  components: {
    PortfolioEvidenceCard,
    EditEvidenceDialog,
    NdtDialog,
  },
  beforeRouteUpdate(to, from, next) {
    this.standardId = to.params.standardId;
    next();
  },

  methods: {
    evidenceEdited(evidence) {
      this.$emit('evidenceEdited', evidence);
    },
    editEvidence(evidence) {
      this.editEvidenceDialog = {
        active: true,
        evidence,
      };
    },
    deleteEvidence(evidence) {
      this.deleteDialog = {
        active: true,
        evidence,
        title: evidence.title,
        processing: false,
      };
    },
    favouriteEvidenceClick(evidence) {
      this.$emit('clickFavourite', evidence);
    },
    submitDeleteEvidence() {
      this.deleteDialog.processing = true;
      this.$api.delete(`/evidence/${this.deleteDialog.evidence.id}`).then(() => {
        this.deleteDialog.active = false;
        this.$emit('evidenceDeleted', this.deleteDialog.evidence);
      });
    },
    navigateToStorageLogin() {
      if (this.selectedInstitution.storage_type === 'One Drive') {
        if (this.user.student) startStudentLinkOneDriveFlow(this.$route.path);
        else startStaffLinkOneDriveEmailMicrosoftFlow(this.$route.path, this.selectedInstitution.id);
      } else {
        if (this.user.student) startStudentLinkGoogleDriveFlow(this.$route.path, this.$api);
        else startStaffLinkGoogleDriveEmailFlow(this.$route.path, this.$api);
      }
    },
  },
};
</script>
