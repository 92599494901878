import { hasPermissionForStudent } from '@/mixins/global-mixins';
import type { SelectedStudent, UserStaff } from '@/store/map-store';

export function findStudentLevelHomePage(staff: UserStaff, student: SelectedStudent) {
  const name =
    // use hasPermissionForStudent for 'Admin' as it checks inst and cohort
    hasPermissionForStudent(staff, 'Admin', student) || hasPermissionForStudent(staff, 'student.details.view', student)
      ? 'TutorStudentDetailsPage'
      : staff.institution.config.early_careers
      ? 'TutorReviewsListPage'
      : 'TargetsListPage';
  return {
    name,
    params: { studentId: student.id },
  };
}
