<template>
  <div v-if="curriculumVisible">
    <mosaic-autocomplete
      name="curriculumThemeFilter"
      v-model="internalCurriculumThemeId"
      label="Filter by Curriculum"
      :items="curriculumThemesArray"
      item-title="name"
      item-value="id"
      auto-select-first
      hide-details
      min-width="200px"
      no-icon
      :chips="false"
    />
  </div>
</template>

<script>
import { syncQueryParamsMixin } from '../mixins/query-mixins';
import { mapState, mapGetters } from 'vuex';
import { useQueryStore } from '@/stores/query';

export default {
  name: 'CurriculumThemeFilter',
  setup() {
    const queryStore = useQueryStore();
    return { queryStore };
  },
  mixins: [
    syncQueryParamsMixin({
      internalCurriculumThemeId: { query: 'curriculumThemeId', type: 'integer' },
    }),
  ],
  props: {
    curriculumThemeId: {
      type: Number,
      default: null,
    },
  },
  emits: ['update:curriculumThemeId'],
  data() {
    return {
      internalCurriculumThemeId: this.curriculumThemeId,
    };
  },
  watch: {
    internalCurriculumThemeId(x) {
      this.$emit('update:curriculumThemeId', x);
    },
  },
  created() {
    this.$store.dispatch('loadCurriculum');
  },
  computed: {
    ...mapState(['curriculumThemes']),
    ...mapGetters(['curriculumVisible']),
    curriculumThemesArray() {
      let themesArray = this.curriculumThemes ? Object.values(this.curriculumThemes) : [];
      themesArray = themesArray
        .filter(t => t.student_visible)
        .map(t => ({
          ...t,
          name: t.code + ' - ' + t.name,
        }));
      themesArray.unshift({ name: 'All', id: null });

      return themesArray;
    },
  },
};
</script>
