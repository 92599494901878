<template>
  <div class="ma-2" style="cursor: pointer" @click.prevent="launch()">
    <v-icon size="x-large">mdi-{{ icon }}</v-icon
    ><span class="ml-2"
      ><a :href="link" target="_blank">{{ label }}</a></span
    >
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  link: string;
  label: string;
  icon: string;
}>();

function launch() {
  window.open(props.link, '_blank');
}
</script>
