<template>
  <div>
    <mosaic-checkbox
      v-model="storageNotSetUpOnly"
      class="mt-0"
      density="compact"
      no-icon
      hide-details
      label="Storage not set up"
      name="storage-not-set-up"
    />
  </div>
</template>

<script>
import { syncQueryParamsMixin } from '@/mixins/query-mixins';
import { useQueryStore } from '@/stores/query';

export default {
  name: 'StaffListStorageNotSetUpFilter',
  setup() {
    const queryStore = useQueryStore();
    return { queryStore };
  },
  mixins: [
    syncQueryParamsMixin({
      storageNotSetUpOnly: { query: 'storageNotSetUp', type: 'boolean' },
    }),
  ],
  emits: ['update:storage-not-set-up'],
  data() {
    return { storageNotSetUpOnly: false };
  },
  watch: {
    storageNotSetUpOnly(x) {
      this.$emit('update:storage-not-set-up', x);
    },
  },
};
</script>
