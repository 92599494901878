<template>
  <div>
    <v-card class="mb-4">
      <v-card-text>
        <div class="d-flex align-center">
          <v-text-field
            v-model="searchTerm"
            density="compact"
            hide-details
            variant="outlined"
            :label="`Search for ${traineeNounCapitalisedAndPluralised} by email or name`"
            prepend-inner-icon="mdi-magnify"
          />
          <div class="flex-grow-1"></div>
        </div>
      </v-card-text>
    </v-card>
    <v-card v-if="searchTerm && (students.length || error || !busy)">
      <v-card-text>
        <mosaic-list :items="students" v-if="students.length > 0">
          <template #item="{ item: t }">
            <mosaic-list-item
              :to="studentTo(t)"
              :title="`${t.name || t.email} - ${t.name ? t.email : ''}`"
              :subtitle="`${t.email} ${renderSubtitle(t)}`"
              icon="account"
            >
              <template #information>
                <demo-account-badge v-if="t.is_demo" />
              </template>
            </mosaic-list-item>
          </template>
        </mosaic-list>
        <div v-else-if="error">{{ error }}</div>
        <div v-else-if="searchTerm && !busy">No {{ traineeNounCapitalisedAndPluralised }} found</div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import DemoAccountBadge from '../../components/DemoAccountBadge.vue';
import axios from 'axios';
import { mapState } from 'vuex';

export default {
  name: 'TutorAdminGlobalSearchPage',
  data: () => ({
    searchTerm: null,
    students: [],
    cancelSource: null,
    error: '',
    busy: false,
    institution: {
      cohorts: [],
    },
  }),
  created() {
    this.institution = this.selectedInstitution;
  },
  computed: {
    ...mapState([`selectedInstitution`]),
    breadcrumbs() {
      return [{ text: this.traineeNounCapitalisedAndPluralised }];
    },
  },
  watch: {
    async searchTerm(x) {
      this.error = '';
      this.busy = true;
      if (this.cancelSource) this.cancelSource.cancel();
      if (!x) {
        this.students = [];
        return;
      }
      this.cancelSource = axios.CancelToken.source();
      try {
        const r = await this.$api.get(
          `institutions/${this.selectedInstitution.id}/students/get-active-students-for-search?q=${encodeURIComponent(
            x
          )}`,
          this.cancelSource.token
        );
        this.students = r.data;
      } catch (e) {
        if (!axios.isCancel(e)) {
          console.log(e);
          this.students = [];
          this.error = `Sorry, cannot load ${this.traineeNounCapitalisedAndPluralised} at the moment`;
        }
      }
      this.busy = false;
    },
  },
  methods: {
    studentTo(student) {
      return {
        name: 'TutorStudentDetailsPage',
        params: { studentId: student.id },
      };
    },

    renderSubtitle(t) {
      return !this.selectedInstitution.config.early_years ? ` - ${t.cohort_name}` : '';
    },
  },
  components: { DemoAccountBadge },
};
</script>
