export default {
  async show(evidence, download, api) {
    const response = await api.get(`/evidence-files/${evidence.evidence_file.id}?download=${download}`);
    window.open(response.data.location, '_blank');
  },
  simpleCrissCross: function (evidence, standards) {
    const standardGroupedEvidence = standards.map(s => {
      const substandards = s.substandards.map(ss => {
        const evidenceItems = evidence.filter(e => {
          return e.substandards.some(ess => ess.id === ss.id);
        });
        return { ...ss, evidence: evidenceItems };
      });
      return { ...s, substandards };
    });
    return standardGroupedEvidence;
  },
};
