<template>
  <file-upload
    :files="files"
    :readonly="readonly"
    :min-files="minFiles"
    :max-files="maxFiles"
    :presign-url="presignUrl"
    :get-file-url-prefix="getFileUrlPrefix"
    @file-added="fileAdded"
    @file-removed="fileRemoved"
    @uploading="emit('uploading', $event)"
  />
</template>

<script setup lang="ts">
import type { ContentLayoutCompletionFile } from '@/utils/content-layout';
import FileUpload, { type FileDetails } from '@/components/mosaic-content-layout/FileUpload.vue';

const props = defineProps<{
  files: ContentLayoutCompletionFile[];
  readonly?: boolean;
  minFiles: number;
  maxFiles: number;
  presignUrl: string;
  templateId: string;
  getFileUrlPrefix: string;
}>();

const emit = defineEmits<{
  (e: 'fileAdded', file: ContentLayoutCompletionFile): void;
  (e: 'fileRemoved', file: ContentLayoutCompletionFile): void;
  (e: 'uploading', uploading: boolean): void;
}>();

function fileAdded(file: FileDetails) {
  emit('fileAdded', { ...file, templateId: props.templateId });
}

function fileRemoved(file: FileDetails) {
  emit('fileRemoved', { ...file, templateId: props.templateId });
}
</script>
