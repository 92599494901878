<template>
  <mosaic-save-dialog
    :active="active"
    :title="`Edit ${cohortCourseWeekNounCapitalised}`"
    @update:active="emit('update:active', $event)"
    :object-type="`${cohortCourseWeekNounCapitalised}`"
    :save="submitEditWeek"
    :can-save="canEditWeek"
    @save="emit('save')"
  >
    <mosaic-text-field v-model="name" name="week-name" label="Name" prepend-icon="mdi-pencil" />
    <div class="d-flex align-center">
      <div class="pr-2 flex-grow-1">
        <mosaic-date-picker
          v-model:date="startDate"
          name="week-start-date"
          label="Start Date"
          :exact-width="false"
          :end-margin="false"
        />
      </div>
      <div class="pl-2 flex-grow-1">
        <mosaic-date-picker
          v-model:date="endDate"
          name="week-end-date"
          label="End Date"
          :exact-width="false"
          :end-margin="false"
        />
      </div>
      <mosaic-error-icon
        v-if="endBeforeStart || conflictingDates"
        :tooltip="
          conflictingDates
            ? `These dates overlap with an existing ${cohortCourseWeekNounCapitalised}`
            : 'The selected end date is before the start date'
        "
      />
    </div>
  </mosaic-save-dialog>
</template>

<script setup lang="ts">
import { useApi } from '@/composables/api';
import { watchEffect } from 'vue';
import { ref, computed } from 'vue';
import { mapState } from '@/store/map-store';
import { type CohortCourseTerm, type Week, useCohortCourseStore, isConflictingWeek } from '@/stores/cohort-course';

const { selectedCohort } = mapState();

const {
  cohortCourseWeekNounCapitalised,
  actions: { loadCohortCourse },
} = useCohortCourseStore();

const props = defineProps<{
  active: boolean;
  week: Week | null;
  term: CohortCourseTerm | null;
  isTemplateSection?: boolean;
}>();

const emit = defineEmits<{
  'update:active': [active: boolean];
  save: [void];
}>();

const api = useApi();

const name = ref('');
const startDate = ref('');
const endDate = ref('');
watchEffect(() => {
  if (!props.week) return;
  name.value = props.week.name;
  startDate.value = props.week.startDate;
  endDate.value = props.week.endDate;
});

const endBeforeStart = computed(() => endDate.value < startDate.value);
const conflictingDates = computed(() => {
  if (!props.week || !props.term) return false;
  return isConflictingWeek(props.week, props.term, startDate.value, endDate.value);
});

const canEditWeek = computed(() => {
  const w = props.week;
  if (!w) return false;
  return (
    !!name.value &&
    (name.value !== w.name || startDate.value !== w.startDate || endDate.value !== w.endDate) &&
    !conflictingDates.value &&
    !endBeforeStart.value
  );
});

async function submitEditWeek() {
  if (!props.week) return;
  const url = `cohorts/${selectedCohort.value.id}/course/weeks/${props.week.id}`;
  await api.put(url, {
    name: name.value,
    startDate: startDate.value,
    endDate: endDate.value,
  });
  loadCohortCourse(true);
  emit('update:active', false);
}
</script>
