<template>
  <div>
    <div class="text-h6">Feature Switches</div>
    <v-expansion-panels class="mt-4" variant="accordion">
      <config-expansion-panel v-for="(feature, i) in friendlyFeatureConfigs" :key="i" :feature="feature" />
    </v-expansion-panels>
  </div>
</template>

<script>
import ConfigExpansionPanel from '../../components/ConfigExpansionPanel.vue';
import { mapGetters } from 'vuex';
export default {
  name: 'AdminFeatureSwitchesPage',
  async created() {
    await this.$store.dispatch('loadFeatureConfigsForAdmin');
    this.configs = this.friendlyFeatureConfigs.map(c => {
      return { ...c };
    });
  },
  computed: {
    ...mapGetters(['friendlyFeatureConfigs']),
    breadcrumbs() {
      return [{ text: 'Feature Switches' }];
    },
  },
  components: { ConfigExpansionPanel },
};
</script>

<style scoped></style>
