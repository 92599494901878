import { hasPermission, hasPermissionForCohort, hasPermissionForStudent } from '@/mixins/global-mixins';
import type { Permission } from '@/store/map-store';
import { useCohortStore } from '@/stores/cohort';
import { useStaffStore } from '@/stores/staff';
import { useStudentStore } from '@/stores/student';
import { computed } from 'vue';

export function hasPermissionForSelectedInstitution(permission: Permission) {
  const userStaffStore = useStaffStore();
  return computed(() => hasPermission(userStaffStore.userStaff.value, permission));
}

export function hasPermissionForSelectedCohort(permission: Permission) {
  const { selectedCohort } = useCohortStore();
  const userStaffStore = useStaffStore();
  return computed(() => hasPermissionForCohort(userStaffStore.userStaff.value, permission, selectedCohort.value));
}

export function hasPermissionForSelectedStudent(permission: Permission) {
  const { selectedStudent } = useStudentStore();
  const userStaffStore = useStaffStore();
  return computed(() => hasPermissionForStudent(userStaffStore.userStaff.value, permission, selectedStudent.value));
}
