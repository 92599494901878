<template>
  <v-card elevation="4">
    <v-toolbar color="white" elevation="6">
      <v-toolbar-title>Reset your password</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <mosaic-error-alert :override-error-message="errorMessage" />
      <mosaic-alert v-if="!!successMessage" type="success" class="mb-4">
        {{ successMessage }}
      </mosaic-alert>
      <mosaic-password-help />
      <mosaic-password-text-field
        v-model="password"
        :validate-password="true"
        @password-valid="passwordValid = $event"
      />
      <mosaic-password-text-field
        v-model="passwordConfirmation"
        name="passwordConfirmation"
        label="Password Confirmation"
        :rules="passwordConfirmationRules"
        @keyup.enter="resetPassword()"
      />
      <div class="d-flex justify-end">
        <mosaic-password-security-link />
      </div>
    </v-card-text>
    <v-card-actions>
      <mosaic-router-link to="login" class="pl-4">Return to login</mosaic-router-link>
      <v-spacer />
      <v-btn :disabled="!canSubmit" @click.prevent="resetPassword()">Reset</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { validationsPass } from '../utils/validations';

export default {
  name: 'ResetPasswordPage',
  data() {
    return {
      password: '',
      passwordConfirmation: '',
      errorMessage: '',
      successMessage: '',
      busy: false,
      passwordValid: false,
      passwordConfirmationRules: [v => (v && v === this.password) || 'Password confirmation should match password'],
    };
  },
  computed: {
    canSubmit() {
      return (
        !this.busy &&
        this.password &&
        this.passwordConfirmation &&
        validationsPass(this.passwordConfirmationRules, this.passwordConfirmation) &&
        this.passwordValid
      );
    },
  },
  methods: {
    async resetPassword() {
      if (!this.canSubmit) return;
      this.busy = true;
      this.errorMessage = '';
      this.successMessage = '';

      try {
        await this.$api.post('password/reset', { password: this.password, token: this.$route.query.token });
        this.successMessage = 'Your password has been succesfully changed.';
      } catch (e) {
        if (e.response?.status === 404) {
          this.errorMessage = 'This link has expired, please request a new one.';
        } else if (e.response?.status === 422 && e.response.data.error_code === 'invalid_password') {
          this.errorMessage = e.response.data.message;
        } else {
          this.errorMessage = 'Sorry, cannot connect to the server. Please try again later.';
        }
      }
      this.busy = false;
    },
  },
};
</script>

<style scoped>
.signup-message {
  padding: 8px 0;
  width: 100%;
  text-align: center;
}
</style>
