<template>
  <mosaic-save-dialog
    :active="active"
    :title="title"
    object-type="Course Activity"
    action="mark as complete"
    :save="markAsCompleteApiCall"
    close-text="Not now"
    @update:active="emit('update:active', $event)"
  >
    <p>You have filled in all the required elements of this {{ objectType }}, would you like to mark it as complete?</p>
    <div>This will indicate to your course leads that this {{ objectType }} is ready for review.</div>
  </mosaic-save-dialog>
</template>

<script setup lang="ts">
defineProps<{
  active: boolean;
  title: string;
  objectType: string;
  markAsCompleteApiCall: () => Promise<void>;
}>();

const emit = defineEmits<{
  (e: 'update:active', active: boolean): void;
}>();
</script>
