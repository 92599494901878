<template>
  <div v-if="isEmailVerificationOnForSelectedInstitution">
    <mosaic-checkbox
      v-model="wontReceiveEmails"
      no-icon
      class="mt-0"
      density="compact"
      hide-details
      label="Won't receive emails"
      name="wont-recieve-emails"
    />
  </div>
</template>

<script>
import { syncQueryParamsMixin } from '@/mixins/query-mixins';
import { useQueryStore } from '@/stores/query';

import { mapGetters } from 'vuex';
export default {
  name: 'StaffListWontReceiveEmailsFilter',
  setup() {
    const queryStore = useQueryStore();
    return { queryStore };
  },
  mixins: [
    syncQueryParamsMixin({
      wontReceiveEmails: { query: 'wontReceiveEmails', type: 'boolean' },
    }),
  ],
  emits: ['update:wont-receive-emails'],
  data() {
    return { wontReceiveEmails: '' };
  },
  computed: {
    ...mapGetters(['isEmailVerificationOnForSelectedInstitution']),
  },
  watch: {
    wontReceiveEmails(x) {
      this.$emit('update:wont-receive-emails', x);
    },
  },
};
</script>
