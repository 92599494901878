import type { Ref } from 'vue';
import { ref } from 'vue';
import type { RouteLocationNamedRaw } from 'vue-router';
import { useRoute, useRouter } from 'vue-router';

export function withSaveAndReturn(save: () => Promise<boolean>, returnTo: Ref<RouteLocationNamedRaw | undefined>) {
  const saveSuccess = ref(false);
  const justSaving = ref(false);
  const savingAndReturning = ref(false);

  async function justSave() {
    justSaving.value = true;
    saveSuccess.value = await save();
    justSaving.value = false;
  }

  function justReturn() {
    if (!returnTo.value) throw `justReturn() called, but no returnTo supplied`;
    if (route.query.returnUrl) {
      let url = route.query.returnUrl as string;
      if (url.startsWith('#')) {
        url = url.slice(1);
      }
      router.push(url);
    } else if (returnTo) {
      router.push(returnTo.value);
    } else {
      router.go(-1);
    }
  }

  const route = useRoute();
  const router = useRouter();
  async function saveAndReturn() {
    savingAndReturning.value = true;
    const success = await save();
    if (success) {
      justReturn();
    }
    savingAndReturning.value = false;
  }

  return { saveSuccess, justSaving, savingAndReturning, justSave, saveAndReturn, justReturn };
}
