<template>
  <div class="d-flex align-top">
    <v-select
      v-model="selectedLastActiveInternal"
      class="mr-4"
      density="compact"
      hide-details
      prepend-icon="mdi-clock-time-four-outline"
      :items="lastActiveItems"
      label="Filter by last log in"
      placeholder="Filter by last log in"
    ></v-select>
    <mosaic-date-picker
      v-if="selectedLastActiveInternal === 'customDate'"
      v-model:date="lastActiveCustomDate"
      density="compact"
      label="Last log in before"
    ></mosaic-date-picker>
  </div>
</template>

<script>
import moment from 'moment';
import { syncQueryParamsMixin } from '@/mixins/query-mixins';
import { useQueryStore } from '@/stores/query';

export default {
  name: 'StaffListLastActiveFilter',
  setup() {
    const queryStore = useQueryStore();
    return { queryStore };
  },
  mixins: [
    syncQueryParamsMixin({
      selectedLastActiveInternal: { query: 'selectedLastActiveValue', type: 'string' },
    }),
  ],
  emits: ['update:lastActive'],
  data() {
    return {
      lastActiveItems: [
        { title: 'Show all users', value: 'all', filter: () => true },
        { title: 'Never', value: 'never', filter: user => !user.last_active_at },
        {
          title: 'Not in last week',
          value: 'lastWeek',
          filter: user => moment(user.last_active_at) < moment().subtract(1, 'weeks') || !user.last_active_at,
        },
        {
          title: 'Not in last 30 days',
          value: 'last30Days',
          filter: user => moment(user.last_active_at) < moment().subtract(30, 'days') || !user.last_active_at,
        },
        {
          title: 'Not in last 2 months',
          value: 'last2Months',
          filter: user => moment(user.last_active_at) < moment().subtract(2, 'months') || !user.last_active_at,
        },
        {
          title: 'Not since custom date',
          value: 'customDate',
          filter: user => moment(user.last_active_at) < moment(this.lastActiveCustomDate) || !user.last_active_at,
        },
      ],
      selectedLastActiveInternal: 'all',
      lastActiveCustomDate: moment().toISOString(),
    };
  },
  watch: {
    selectedLastActiveInternal(selectedLastActiveValue) {
      this.$emit(
        'update:lastActive',
        this.lastActiveItems.find(x => x.value === selectedLastActiveValue)
      );
    },
  },
};
</script>
