<template>
  <v-container fluid>
    <v-container>
      <proficiencies-list
        v-if="!busy"
        :topics="topics"
        :ratings="ratings"
        :read-only="readOnly"
        @rating-changed="changeRating"
      />
    </v-container>
  </v-container>
</template>

<script>
import ProficienciesList from '../../components/ProficienciesList.vue';
import moment from 'moment';
import { mapState } from 'vuex';

export default {
  name: 'TutorStudentProficienciesPage',
  data: function () {
    return {
      busy: true,
      readOnly: true,
      topics: [],
      ratings: [
        {
          int: 0,
          label: 'R',
          color: 'red',
        },
        {
          int: 1,
          label: 'A',
          color: 'yellow',
        },
        {
          int: 2,
          label: 'G',
          color: 'green',
        },
      ],
    };
  },
  computed: {
    ...mapState(['selectedStudent']),
    breadcrumbs() {
      return [
        {
          text: 'Proficiencies',
        },
      ];
    },
  },
  created: function () {
    this.readOnly = !this.userStaffHasPermissionForSelectedStudent('student.proficiencies.edit');
    this.$api.get(`/students/${this.selectedStudent.id}/proficiencies`).then(r => {
      this.topics = r.data.map(t => ({
        ...t,
        proficiencies: t.proficiencies.map(p => ({
          ...p,
          proficiency_assessments: p.proficiency_assessments.map(a => ({
            ...a,
            last_updated: a.updated_at ? moment(a.updated_at).format('l') : null,
            previous_rating_int: a.rating_int,
          })),
        })),
      }));
      this.busy = false;
    });
    //options: revert to computed and force computed update OR use previous value on the original topics data object
  },
  methods: {
    async changeRating(assessment_id, rating_int, topic_id, proficiency_id) {
      const assessmentToUpdate = this.topics
        .find(t => t.id === topic_id)
        .proficiencies.find(p => p.id == proficiency_id)
        .proficiency_assessments.find(a => a.id === assessment_id);
      let apiCall;
      if (assessmentToUpdate.previous_rating_int === null) {
        apiCall = x => this.$api.post(`/students/${this.selectedStudent.id}/proficiency-assessments`, x);
      } else {
        apiCall = x =>
          this.$api.put(
            `/students/${this.selectedStudent.id}/proficiency-assessments/${assessmentToUpdate.student_proficiency_assessment_id}`,
            x
          );
      }
      if (rating_int !== assessmentToUpdate.previous_rating_int) {
        try {
          const response = await apiCall({
            rating: rating_int,
            proficiency_assessment_id: assessmentToUpdate.id,
          });
          this.topics
            .find(t => t.id == topic_id)
            .proficiencies.find(p => p.id == proficiency_id)
            .proficiency_assessments.forEach(a => {
              if (a.id == assessment_id) {
                a.rating_label = response.data.rating_label;
                a.rating_int = response.data.rating_int;
                a.student_proficiency_assessment_id = response.data.id;
                a.last_updated = moment(response.data.last_updated).format('l');
                a.previous_rating_int = response.data.rating_int;
              }
            });
        } catch (e) {
          assessmentToUpdate.rating_int = assessmentToUpdate.previous_rating_int;
          assessmentToUpdate.rating_label = this.ratings.find(
            r => r.int == assessmentToUpdate.previous_rating_int
          ).label;
        }
      } else {
        assessmentToUpdate.rating_int = assessmentToUpdate.previous_rating_int;
        assessmentToUpdate.rating_label = this.ratings.find(r => r.int == assessmentToUpdate.previous_rating_int).label;
      }
    },
  },
  components: { ProficienciesList },
};
</script>
