<template>
  <mosaic-loading-and-error-cards object-type="Announcement" :load="load">
    <mosaic-card v-if="announcement">
      <mosaic-card-title>{{ announcement.title }}</mosaic-card-title>
      <mosaic-card-subtitle
        >{{ formatDate(announcement.announced_at) }} - from
        {{ announcement.created_by ? announcement.created_by.name : 'your course leads' }}</mosaic-card-subtitle
      >
      <div class="pt-4"><mosaic-quill :contents="announcement.body" :read-only="true" /></div>
    </mosaic-card>
  </mosaic-loading-and-error-cards>
</template>

<script setup lang="ts">
import { parseRouteId } from '@/composables/vue-router';
import { computed, ref } from 'vue';
import MosaicQuill from '@/components/quill/MosaicQuill.vue';
import { setBreadcrumbs } from '@/utils/breadcrumbs';
import { useApi } from '@/composables/api';

const api = useApi();

const announcement = ref<Announcement>();
setBreadcrumbs(
  computed(() => [
    {
      text: 'Announcements',
      to: { name: 'StudentAnnouncementsPage' },
    },
    {
      text: announcement.value?.title || '',
    },
  ])
);

const id = parseRouteId('id');

interface Announcement {
  id: number;
  title: string;
  body: string;
  announced_at: string;
  created_by?: { name: string };
}

async function load() {
  const r = await api.get<Announcement>(`/announcements/${id.value}`);
  announcement.value = r.data;

  // Deliberately unawaited
  markAsRead();
}

async function markAsRead() {
  try {
    await api.post(`announcements/${id.value}/read`, {});
  } catch (e) {
    console.log('Cannot mark as read', e);
  }
}
</script>
