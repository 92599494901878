<template>
  <div>
    <mosaic-loading-card v-if="busy" type="card" />
    <mosaic-load-error-card v-else-if="error" :object-type="cohortCourseWeekNounCapitalised" @retry="loadWeek" />
    <mosaic-card v-else-if="studentWeek?.studentCohortCourseWeekSections.length === 0">
      There don't appear to be any Pages of content relevant to your Subject(s) for this
      {{ cohortCourseWeekNounCapitalised }}. Please
      <span class="text-primary" style="cursor: pointer" @click.prevent="contactSupport()">contact support</span> to
      resolve this issue.
    </mosaic-card>
    <template v-else>
      <cohort-course-week-card
        v-model:student-week="studentWeek"
        :student-viewer="user.student"
        :next="next"
        :previous="previous"
        v-model:section-id="sectionId"
        @click-curriculum-statement="clickCurriculumStatement"
        @update:dirty="dirty = $event"
        @update:student-week="studentWeek = mapStudentWeek($event)"
        @click-section="onClickSection($event)"
      />
    </template>
    <unsaved-changes-dialog
      v-model:unsaved-changes-dialog="unsavedChangesDialog"
      object-type="Week"
      :custom-leave-action="changeSection"
    />

    <mosaic-support-dialog v-model:active="supportDialog.active" />
  </div>
</template>

<script>
import CohortCourseWeekCard from './CohortCourseWeekCard.vue';
import unsavedChangesMixin from '@/components/unsaved-changes-mixin';
import UnsavedChangesDialog from '@/components/UnsavedChangesDialog.vue';
import MosaicSupportDialog from '@/components/library/dialogs/MosaicSupportDialog.vue';
import { mapState } from 'vuex';

export default {
  name: 'StudentCohortCourseWeekPage',
  components: { CohortCourseWeekCard, UnsavedChangesDialog, MosaicSupportDialog },
  mixins: [unsavedChangesMixin],
  ...unsavedChangesMixin,
  data: () => ({
    studentWeek: null,
    busy: true,
    error: false,
    next: null,
    previous: null,
    sectionId: null,
    supportDialog: { active: false },
    dirty: false,
    onClickSectionId: 0,
  }),
  computed: {
    ...mapState(['user']),
    breadcrumbs() {
      return [
        {
          text: 'Training Plan',
          to: {
            name: 'StudentCohortCoursePage',
          },
        },
        {
          text: this.studentWeek?.cohortCourseWeek.name,
        },
      ];
    },
  },
  watch: {
    $route() {
      this.loadWeek();
      this.loadNextAndPreviousWeek();
    },
  },
  created() {
    this.loadWeek();
    this.loadNextAndPreviousWeek();
  },
  methods: {
    onClickSection(id) {
      this.onClickSectionId = id;
      if (this.dirty) {
        this.unsavedChangesDialog = {
          active: true,
          to: null,
          leaving: false,
          navigatingToLogin: false,
        };
      } else {
        this.changeSection();
      }
    },
    contactSupport() {
      this.supportDialog.active = true;
      this.$store.dispatch('loadVersionsAndAppMessages');
    },
    changeSection() {
      this.sectionId = this.onClickSectionId;
      this.onClickSectionId = 0;
      this.unsavedChangesDialog = {
        active: false,
        to: null,
        leaving: false,
        navigatingToLogin: false,
      };
    },
    mapStudentWeek(studentWeek) {
      const cohortCourseWeek = studentWeek.cohortCourseWeek;
      const studentFilteredCohortCourseWeekIds = studentWeek.studentCohortCourseWeekSections.map(
        x => x.cohortCourseWeekSectionId
      );
      return {
        ...studentWeek,
        cohortCourseWeek: {
          ...cohortCourseWeek,
          // Would be nicer if the subject filtering was done server side, but quite complicated to do via ActiveRecord
          cohortCourseWeekSections: [
            ...studentWeek.cohortCourseWeek.cohortCourseWeekSections.filter(s =>
              studentFilteredCohortCourseWeekIds.includes(s.id)
            ),
          ],
        },
      };
    },
    async loadWeek() {
      this.busy = true;
      this.error = false;
      try {
        const r = await this.$api.get(`/student-cohort-course-weeks/${this.$route.params.studentWeekId}`);

        this.studentWeek = this.mapStudentWeek(r.data);
        this.sectionId = this.studentWeek.cohortCourseWeek.cohortCourseWeekSections[0]?.id;
      } catch (e) {
        this.error = true;
        console.log(e);
      }
      this.busy = false;
    },
    async loadNextAndPreviousWeek() {
      try {
        const r = await this.$api.get(`/student-cohort-course-weeks`);
        const index = r.data.findIndex(x => x.id == this.$route.params.studentWeekId);
        if (index > 0) {
          this.next = {
            name: 'StudentCohortCourseWeekPage',
            params: { studentWeekId: r.data[index - 1].id },
          };
        } else {
          this.next = null;
        }

        if (index !== r.data.length - 1) {
          this.previous = {
            name: 'StudentCohortCourseWeekPage',
            params: { studentWeekId: r.data[index + 1].id },
          };
        } else {
          this.previous = null;
        }
      } catch (e) {
        console.log(e);
        // Deliberately ignore errors as all that happens is that next and previous don't work
      }
    },
    clickCurriculumStatement(statement) {
      this.$router.push({ name: 'StudentCurriculumStatementPage', params: { id: statement.id } });
    },
  },
};
</script>
