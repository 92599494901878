<template>
  <mosaic-save-card
    v-model:trigger-save="triggerSave"
    :save="save"
    object-type="Course Activity Template"
    :can-save="canSave"
    :hide-return="true"
    :return-to="{ name: 'TutorAdminAssignmentTemplatesListPage' }"
  >
    <mosaic-card-title>New Course Activity Template</mosaic-card-title>
    <mosaic-text-field
      v-model="name"
      autofocus
      name="name"
      label="Name"
      prepend-icon="mdi-google-classroom"
      @keyup.enter="triggerSave = true"
    />
    <mosaic-select
      v-model="selectedRoleId"
      :items="rolesWithStudent"
      name="role-select"
      item-value="id"
      prepend-icon="mdi-account-multiple-check"
      item-title="pluralisedName"
      label="Who should complete Course Activities created from this Template?"
    />
  </mosaic-save-card>
</template>

<script setup lang="ts">
import { useRouter } from 'vue-router';
import { mapState, mapGetters } from '@/store/map-store';
import { setBreadcrumbs } from '@/utils/breadcrumbs';
import { computed } from 'vue';
import { ref } from 'vue';
import { useApi } from '@/composables/api';

const api = useApi();

const { selectedInstitution } = mapState();

const { rolesWithStudent } = mapGetters();

setBreadcrumbs([
  {
    text: `Course Activity Templates`,
    to: {
      name: 'TutorAdminAssignmentTemplatesListPage',
    },
  },
  {
    text: `New Course Activity Template`,
  },
]);

const name = ref('');
const selectedRoleId = ref<number | 'student'>('student');
const canSave = computed(() => !!name.value);

const triggerSave = ref(false);

const router = useRouter();
async function save() {
  const r = await api.post<{ name: string; roleId: number | null; isTraineeContributor: boolean }, { id: number }>(
    `institutions/${selectedInstitution.value.id}/assignment-templates`,
    {
      name: name.value,
      roleId: selectedRoleId.value === 'student' ? null : selectedRoleId.value,
      isTraineeContributor: selectedRoleId.value === 'student',
    }
  );
  router.push({
    name: 'TutorAdminAssignmentTemplateEditPage',
    params: {
      id: r.data.id.toString(),
    },
  });
}
</script>
