<template>
  <v-skeleton-loader v-if="processing" type="image"> </v-skeleton-loader>
  <div v-else-if="error">Sorry, cannot load this image at the moment</div>
  <div v-else style="width: 100%" :style="{ textAlign: item.alignment, backgroundColor: item.backgroundColor }">
    <img
      :src="url"
      :style="{ width: `${widthPercentage}%` }"
      style="max-width: 100%; min-width: 10%"
      :alt="item.description"
    />
  </div>
</template>

<script setup lang="ts">
import { withProcessingAndError } from '@/composables/processing-and-errors';
import type { ContentLayoutImage } from '@/utils/content-layout';
import { computed, ref } from 'vue';
import { useApi } from '@/composables/api';

const api = useApi();

const props = defineProps<{
  item: ContentLayoutImage;
  getResourceUrlPrefix: string;
}>();

const url = ref<string>('');
const {
  processing,
  error,
  action: loadUrl,
} = withProcessingAndError(async () => {
  const r = await api.get<{ web_url: string }>(`${props.getResourceUrlPrefix}/${props.item.resourceId}?download=false`);
  url.value = r.data.web_url;
});
loadUrl();

const widthPercentage = computed(() => (props.item.displayType === 'fullWidth' ? 100 : props.item.widthPercentage));
</script>
