import type { ComputedRef, Ref } from 'vue';
import { computed } from 'vue';

export function filteredByNameOrEmail<T extends { name: string; email: string }>(
  list: Ref<T[]>,
  filter: Ref<string>
): ComputedRef<T[]> {
  return computed(() => list.value.filter(item => filterByNameOrEmail(item, filter.value)));
}

export function filterByNameOrEmail<T extends { name: string; email: string }>(item: T, filter: string) {
  return (
    item.name.toLowerCase().includes(filter.toLowerCase()) || item.email.toLowerCase().includes(filter.toLowerCase())
  );
}

export function filterByString(string: string, filter: string) {
  return string.toLowerCase().includes(filter.toLowerCase());
}

export function filterByGroupId<T extends { groupIds: number[] }>(list: Ref<T[]>, groupId: Ref<number | null>) {
  return computed(() =>
    list.value.filter(item => {
      return !groupId.value || item.groupIds.includes(groupId.value);
    })
  );
}

export function filterByDateRange(date: string, startDate: string | null, endDate: string | null) {
  return (!startDate || date >= startDate) && (!endDate || date < endDate);
}
