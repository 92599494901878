// ABOUT TYPES:
// 1) GraphColum: does not contain filters
// 2) There are 4 possible combinations within EnumColumn:
//    2.1) hasSingleChipAndMultipleSelection - the user can select multiple options and it only contains a value
//    2.2) hasSingleChipAndSingleSelection - the user can select one option and it only contains a value
//    2.3) hasMultipleChipsAndSingleSelection - the user can select one option and it checks it against arrays of values
//        - in which case if needed to pass option "All selected" it should contain value/id -1
//    2.4) hasMultipleChipsAndMultipleSelection - the user can select sereral options and it tries to find the arrays of values in which at least one is matched
//        e.g. instructor training time - roles

import type { RouteLocationNamedRaw } from 'vue-router';

// - To type this component properly requires component level generics https://vuejs.org/api/sfc-script-setup.html#generics, which are supported in Vue 3.3, but not backport to 2.x
export type Row = {
  id: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
} & Record<string, any>;

export type Column = TextColumn | EnumColumn | NumberColumn | GraphColumn;
export interface TextColumn extends BaseColumn {
  text: (row: Row) => string;
  textColor?: (row: Row) => string;
  secondaryText?: (row: Row) => string;
  prependHelpText?: (row: Row) => string | undefined;
  filter?: TextFilter;
}
export interface TextFilter {
  type: 'text';
  value: (row: Row, filters?: SelectFilterValue) => string;
}

export type SelectFilterValue = {
  [key: string]: number[];
};

export interface EnumColumn extends BaseColumn {
  multipleChips?: (row: Row) => Chip[];
  chip?: (row: Row) => Chip;
  filter: SelectRowFilter;
  isRoles?: boolean;
}

export interface SelectDataFilter {
  label?: string;
  initial: number[];
  key: string;
  items: { value: number; name: string; chipIcon?: string | null; chipTooltip?: string | null }[];
  name: string;
  multiple?: boolean | undefined;
  affectedColumns?: string[];
  summarizeSelected?: boolean;
  itemDetails?: boolean;
  value?: (row: Row) => string | number;
}

export interface SelectRowFilter {
  type: 'select';
  items: { value: number | string | null; title: string }[];
  multiple?: boolean | undefined;
  value?: (row: Row) => string | number | string[] | number[] | undefined;
  minWidth?: string;
}

export interface NumberColumn extends BaseColumn {
  value: (row: Row, filters?: SelectFilterValue) => number;
  friendlyAlternativeValue?: (row: Row, filters?: SelectFilterValue) => string | number;
  valueColor?: (row: Row) => string;
  filter?: NumberFilter;
}

export interface NumberFilter {
  type: 'range';
  value: (row: Row, filters?: SelectFilterValue) => number;
}

export interface GraphColumn extends BaseColumn {
  items: (
    row: Row,
    filters?: SelectFilterValue
  ) => [
    {
      label: string;
      background: string;
      key: string;
      width: number;
      tooltip: { title: string; lists: string[] };
    }
  ];
  filter: null;
}

export interface GraphFilter {
  type: 'graph';
  value: (row: Row, filters?: SelectFilterValue) => number;
}

// Ideally would do this as a union, but there are problems with the export type checking
// I think this can be solved when we have component level generics and using a computed, rather than executing the column function in the template
export type Chip = {
  color: string;
  text: string;
  value: string;
  icon?: string;
  iconColor?: string;
  tooltip?: string;
  hide: boolean;
};
// | { hide: true };

export interface BaseColumn {
  name: string;
  key: string;
  clickRoute?: (row: Row) => RouteLocationNamedRaw | undefined;
  sort?: (row: Row, filters?: SelectFilterValue) => string | number;
  helpText?: string;
  helpTextHtml?: string;
  filter?: unknown;
  sticky?: boolean;
  width?: string;
}

export function isTextColumn(c: Column): c is TextColumn {
  return !!(c as TextColumn).text;
}

export function isEnumColumn(c: Column): c is EnumColumn {
  return !!(c as EnumColumn).chip || !!(c as EnumColumn).multipleChips;
}

export function hasSingleChipAndSingleSelection(c: Column): c is EnumColumn {
  return !!(c as EnumColumn).chip && !(c as EnumColumn).filter.multiple;
}

export function hasSingleChipAndMultipleSelection(c: Column): c is EnumColumn {
  return !!(c as EnumColumn).chip && !!(c as EnumColumn).filter.multiple;
}

export function hasMultipleChipsAndSingleSelection(c: Column): c is EnumColumn {
  return !!(c as EnumColumn).multipleChips && !(c as EnumColumn).filter.multiple;
}

export function hasMultipleChipsAndMultipleSelection(c: Column): c is EnumColumn {
  return !!(c as EnumColumn).multipleChips && !!(c as EnumColumn).filter.multiple;
}

export function isNumberColumn(c: Column): c is NumberColumn {
  return !!(c as NumberColumn).value;
}

export function isGraphColumn(c: Column): c is GraphColumn {
  return !!(c as GraphColumn).items;
}
