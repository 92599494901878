<template>
  <div class="monitoring-v-select">
    <v-select
      v-model="selectedReviewIdInternal"
      :items="reviews"
      item-value="id"
      item-title="name"
      :label="reviewNounCapitalised"
      hide-details
      prepend-icon="mdi-check-circle-outline"
      class="py-0 mt-0"
      style="min-width: 200px"
    >
    </v-select>
  </div>
</template>

<script>
import { syncQueryParamsMixin } from '../../mixins/query-mixins';
import { useQueryStore } from '@/stores/query';

export default {
  name: 'MonitoringReviewSelect',
  setup() {
    const queryStore = useQueryStore();
    return { queryStore };
  },
  mixins: [syncQueryParamsMixin({ selectedReviewIdInternal: { query: 'review' } })],
  props: {
    reviews: {
      type: Array, // [{ id: number, name: string }]
      required: true,
    },
    selectedReviewId: {
      type: [String, Number],
      required: true,
    },
  },
  emits: ['update:selectedReviewId'],
  data() {
    return {
      selectedReviewIdInternal: this.selectedReviewId,
    };
  },
  watch: {
    selectedReviewIdInternal(x) {
      this.$emit('update:selectedReviewId', x);
    },
  },
};
</script>

<style>
.monitoring-v-select .v-select__selections input {
  display: none;
}
</style>
