function getStandardEvidenceSummary(standardCode, studentId = null, api) {
  const portfolioEvidenceTypes = [
    'Reflection',
    'Target',
    'EvidenceFile',
    'Mentor Meeting',
    'Lesson Observation',
    'Course Activity',
  ];

  const groupBy = key => array =>
    array.reduce(
      (objectsByKeyValue, obj) => {
        const value = obj[key];
        objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
        return objectsByKeyValue;
      },
      { reflection: [], target: [], evidenceFile: [], mentorMeeting: [], lessonObservation: [], courseActivity: [] }
    );

  const evidenceUri = !studentId ? `/evidence` : `students/${studentId}/evidence`;

  return api.get(evidenceUri).then(response => {
    const nonFileEvidenceTypes = portfolioEvidenceTypes.filter(t => t != 'EvidenceFile');

    const typedEvidence = response.data.map(e => {
      const groupByType =
        !nonFileEvidenceTypes.includes(e.evidence_type) || e.evidence_file ? 'EvidenceFile' : e.evidence_type;
      e.groupByType = (groupByType[0].toLowerCase() + groupByType.substring(1)).replace(' ', '');
      return e;
    });
    const isThisStandard = x => x.standard.code === standardCode;
    const filteredEvidence = typedEvidence.filter(e => e.substandards.some(isThisStandard));
    const groupByEvidenceType = groupBy('groupByType');
    const groupedEvidence = groupByEvidenceType(filteredEvidence);
    return groupedEvidence;
  });
}

export default {
  getStandardEvidenceSummary,
};
