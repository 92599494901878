<template>
  <div>
    <mosaic-icon-btn
      v-if="showPublish(module)"
      icon="mdi-publish"
      :tooltip="publishTooltip(module)"
      :disabled="disablePublish(module)"
      @click.prevent="publishModule(module)"
    />
    <mosaic-icon-btn
      v-else
      icon="mdi-publish-off"
      :disabled="disableUnpublish(module)"
      :tooltip="unpublishTooltip(module)"
      @click.prevent="unpublishModule(module)"
    />
  </div>

  <mosaic-dialog
    v-model:active="publishModuleDialog.active"
    :error-message="publishModuleDialog.errorMessage"
    title="Publish Module"
    :width="950"
  >
    <div>
      Are you sure you want to publish
      <span class="font-weight-medium">"{{ publishModuleDialog?.selectedModule?.name }}</span
      >"?
      <span v-if="publishModuleDialog.frameworks.length > 0">
        This Module is a requirement to meet Competencies of the following Frameworks and it will become visible to
        Instructors:</span
      >
    </div>

    <v-list class="py-0" v-if="publishModuleDialog.frameworks.length > 0" density="compact">
      <v-list-item :key="fName" class="py-0" v-for="fName in publishModuleDialog.frameworks">
        <v-icon size="18" class="text-secondary" :icon="`mdi-${icons.instructorTrainingFramework}`"></v-icon>
        <span class="ml-2">{{ fName }}</span>
      </v-list-item>
    </v-list>

    <template #buttons>
      <v-btn
        variant="text"
        ripple
        color="primary"
        :disabled="publishModuleDialog.processing"
        @click.prevent="savePublishModule(module)"
      >
        Confirm
      </v-btn>
    </template>
  </mosaic-dialog>

  <mosaic-dialog
    v-model:active="unpublishModuleDialog.active"
    :error-message="unpublishModuleDialog.errorMessage"
    title="Unpublish Module"
    :width="950"
  >
    <div>
      Are you sure you want to unpublish
      <span class="font-weight-medium">"{{ unpublishModuleDialog?.selectedModule?.name }}</span
      >"?
      <span v-if="unpublishModuleDialog.frameworks.length > 0">
        This Module is a requirement to meet Competencies of the following Frameworks and it will no longer be visible
        to Instructors:</span
      >
    </div>

    <v-list class="py-0" v-if="unpublishModuleDialog.frameworks.length > 0" density="compact">
      <v-list-item :key="fName" class="py-0" v-for="fName in unpublishModuleDialog.frameworks">
        <v-icon size="18" class="text-secondary" :icon="`mdi-${icons.instructorTrainingFramework}`"></v-icon>
        <span class="ml-2">{{ fName }}</span>
      </v-list-item>
    </v-list>
    <template #buttons>
      <v-btn
        variant="text"
        ripple
        color="primary"
        :disabled="unpublishModuleDialog.processing"
        @click.prevent="saveUnpublishModule(module)"
      >
        Confirm
      </v-btn>
    </template>
  </mosaic-dialog>
</template>
<script setup lang="ts">
import { useInstitutionStaffTrainingStore } from '@/stores/institution-staff-training';
import type { StaffTrainingModule } from '@/store/map-store';
import { icons } from '@/utils/icons';
import { useApi } from '@/composables/api';
import { ref } from 'vue';

interface Props {
  module: StaffTrainingModule;
}
defineProps<Props>();

const api = useApi();

const {
  institutionStaffTrainingFrameworks,
  actions: { loadInstitutionStaffTrainingFrameworks },
} = useInstitutionStaffTrainingStore();

loadInstitutionStaffTrainingFrameworks();

const publishModuleDialog = ref({
  active: false,
  selectedModule: null as StaffTrainingModule | null,
  processing: false,
  errorMessage: '',
  frameworks: [] as string[],
});
const unpublishModuleDialog = ref({
  active: false,
  selectedModule: null as StaffTrainingModule | null,
  processing: false,
  errorMessage: '',
  frameworks: [] as string[],
});

const emit = defineEmits<{
  (e: 'handleSavePublishModule', payload: StaffTrainingModule): void;
  (e: 'handleSaveUnpublishModule', payload: StaffTrainingModule): void;
}>();

const showPublish = (module: StaffTrainingModule) => module.status !== 'published';

function publishTooltip(module: StaffTrainingModule) {
  if (!module.hasSections) {
    return 'Cannot publish Module without any Sections';
  }
  if (!module.allSectionsHaveContent) {
    return 'Cannot publish Module as some Sections do not have any content';
  }
  return 'Publish Training Module. Published Modules cannot be edited.';
}

function unpublishTooltip(m: StaffTrainingModule) {
  return m.hasStaffCompletions
    ? 'Cannot unpublish Training Module as at least one Instructor has started it.'
    : 'Unpublish Training Module';
}

const disableUnpublish = (module: StaffTrainingModule) => module.hasStaffCompletions;
const disablePublish = (module: StaffTrainingModule) => !(module.hasSections && module.allSectionsHaveContent);

async function publishModule(m: StaffTrainingModule) {
  const frameworkIds = m.staffTrainingCompetencies.map(c => c.frameworkId);
  publishModuleDialog.value.active = true;
  publishModuleDialog.value.errorMessage = '';
  publishModuleDialog.value.selectedModule = m;
  if (institutionStaffTrainingFrameworks.value)
    publishModuleDialog.value.frameworks = institutionStaffTrainingFrameworks.value
      .filter(f => frameworkIds.includes(f.id))
      .map(f => f.name);
}

async function unpublishModule(m: StaffTrainingModule) {
  const frameworkIds = m.staffTrainingCompetencies.map(c => c.frameworkId);
  unpublishModuleDialog.value.active = true;
  unpublishModuleDialog.value.errorMessage = '';
  unpublishModuleDialog.value.selectedModule = m;
  if (institutionStaffTrainingFrameworks.value)
    unpublishModuleDialog.value.frameworks = institutionStaffTrainingFrameworks.value
      .filter(f => frameworkIds.includes(f.id))
      .map(f => f.name);
}

async function savePublishModule(m: StaffTrainingModule) {
  publishModuleDialog.value.processing = true;
  try {
    await api.put(`/staff-training/modules/${m.id}/publish`, {});
    emit('handleSavePublishModule', m);
    publishModuleDialog.value.active = false;
    publishModuleDialog.value.processing = false;
  } catch (e) {
    console.log(e);
    publishModuleDialog.value.errorMessage = `Sorry, cannot publish the Module "${publishModuleDialog.value?.selectedModule?.name}" at the moment`;
  }
}

async function saveUnpublishModule(m: StaffTrainingModule) {
  unpublishModuleDialog.value.processing = true;
  try {
    await api.put(`/staff-training/modules/${m.id}/unpublish`, {});
    emit('handleSaveUnpublishModule', m);
    unpublishModuleDialog.value.active = false;
    unpublishModuleDialog.value.processing = false;
  } catch (e) {
    console.log(e);
    unpublishModuleDialog.value.errorMessage = `Sorry, cannot unpublish the Module "${unpublishModuleDialog.value?.selectedModule?.name}" at the moment`;
  }
}
</script>
