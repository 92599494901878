<template>
  <div>
    <mosaic-save-card
      :save="save"
      object-type="settings"
      :object-type-is-plural="true"
      :can-save="canSave"
      :return-to="findInstitutionLevelHomePage(userStaff)"
    >
      <mosaic-card-title> Home Page Settings </mosaic-card-title>
      <div v-if="!selectedInstitution.config.mentor_training_only">
        <mosaic-card-heading class="mt-2"> {{ traineeNounCapitalised() }} Home Page </mosaic-card-heading>
        <div class="mt-2">
          <mosaic-quill-field
            v-model="studentRubric"
            :read-only="false"
            name="student-welcome-message"
            label="Welcome message"
          />
        </div>
      </div>
      <div>
        <mosaic-card-heading> Instructor Home Page </mosaic-card-heading>
        <div class="mt-2">
          <mosaic-quill-field
            v-model="staffRubric"
            :read-only="false"
            name="staff-welcome-message"
            label="Welcome message"
          />
        </div>
      </div>
    </mosaic-save-card>
    <unsaved-changes-dialog
      v-model:unsaved-changes-dialog="dialog"
      object-type="settings"
      :object-type-is-plural="true"
      :save="save"
    />
  </div>
</template>

<script setup lang="ts">
import { setBreadcrumbs } from '@/utils/breadcrumbs';
import { mapMutations, mapState } from '@/store/map-store';
import { computed, ref } from 'vue';
import { useUnsavedChanges } from '@/composables/unsaved-changes';
import UnsavedChangesDialog from '@/components/UnsavedChangesDialog.vue';
import { useApi } from '@/composables/api';
import { findInstitutionLevelHomePage } from '@/router/guard-methods';

const api = useApi();

setBreadcrumbs([
  {
    text: `Home Page Settings`,
  },
]);

const { selectedInstitution, userStaff } = mapState();
const { updateSelectedInstitutionHomePageRubrics } = mapMutations();

const studentRubric = ref(selectedInstitution.value.config.home_page_rubric);
const staffRubric = ref(selectedInstitution.value.config.staff_home_page_rubric);

const dirty = computed(
  () =>
    studentRubric.value !== selectedInstitution.value.config.home_page_rubric ||
    staffRubric.value !== selectedInstitution.value.config.staff_home_page_rubric
);
const canSave = computed(() => dirty.value);

const { dialog } = useUnsavedChanges(dirty);

async function save() {
  const body = {
    homePageRubric: studentRubric.value,
    staffHomePageRubric: staffRubric.value,
  };
  await api.put(`institutions/${selectedInstitution.value.id}/home-page-settings`, body);
  updateSelectedInstitutionHomePageRubrics(body);
}
</script>
