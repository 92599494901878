<template>
  <mosaic-loading-and-error-cards object-type="Teaching Strategy" :load="load">
    <curriculum-teaching-strategy-card v-if="teachingStrategy" :teaching-strategy="teachingStrategy" />
  </mosaic-loading-and-error-cards>
</template>

<script setup lang="ts">
import { parseRouteId } from '@/composables/vue-router';
import CurriculumTeachingStrategyCard from './CurriculumTeachingStrategyCard.vue';
import { setupCurriculumTeachingStrategyPage } from './student-teaching-strategies';
import { useApi } from '@/composables/api';

const api = useApi();

const teachingStrategyId = parseRouteId('id');

const { load, teachingStrategy } = setupCurriculumTeachingStrategyPage(
  `curriculum-statements/teaching-strategies/${teachingStrategyId.value}`,
  true,
  api
);
</script>
