<template>
  <mosaic-loading-card v-if="loading" type="three-cards" />
  <mosaic-load-error-card v-else-if="loadError" object-type="Target" @retry="loadTarget" />
  <div v-else>
    <v-card v-if="target" class="mb-4">
      <v-card-text class="pa-5">
        <div class="d-flex align-center mb-2">
          <span class="text-h6">Approval</span>
          <span v-if="approvedBy" class="pl-4">
            <v-chip color="secondary">Approved by {{ approvedBy }}</v-chip>
          </span>
        </div>
        <div v-if="!target.approved_at && canApprove" class="text-body-1 mb-2">
          Mark this target as approved when you are satisfied the {{ traineeNoun() }} has completed it
          successfully.<span v-if="target.mentor_comment">
            The {{ traineeNoun() }} will only see your comment once the target has been approved.</span
          >
        </div>
        <div v-if="!target.approved_at && !canApprove" class="text-body-1 mb-2">
          This target has not yet been approved. You do not have permission to approve targets for this
          {{ traineeNoun() }}.
        </div>
        <div v-if="canApprove || target.approved_at" class="text-subtitle-2 mb-2">Approval Comment</div>
        <v-textarea
          v-if="canApprove && !target.approved_at"
          v-model="mentorComment"
          variant="filled"
          auto-grow
          type="text"
          rows="2"
          hide-details
        />
        <div v-else-if="target.approved_at" class="ml-1">
          {{ mentorComment || 'No comment recorded' }}
        </div>
        <mosaic-snackbar
          v-model="approveSnackbar.active"
          :color="approveSnackbar.type"
          :message="approveSnackbar.message"
        />
        <v-alert density="compact" class="mt-4 mb-4" v-if="approveError" type="error">{{ approveError }}</v-alert>
        <div v-if="canApprove" class="mt-2 text-right">
          <v-btn
            v-if="target.approved_at"
            ripple
            :loading="processing"
            :disabled="processing"
            @click.prevent="unapprove()"
            >Unapprove</v-btn
          >
          <v-btn v-else ripple :loading="processing" :disabled="processing" @click.prevent="approve()">Approve</v-btn>
        </div>
      </v-card-text>
    </v-card>

    <target-card
      ref="targetCard"
      v-model:target="target"
      :student-id="selectedStudent.id"
      :curriculum-statement-id="$route.query.curriculumStatementId"
      :mentor-meeting-id="$route.params.mentorMeetingId"
      :mentor-meeting-date="$route.query.mentorMeetingDate"
      :return-url="$route.query.returnUrl"
      :show-approval-comment="false"
      :return-to="{ name: 'TargetsListPage', params: { studentId: selectedStudent.id } }"
      @update:dirty="targetDirty = $event"
    />

    <comments-card
      v-if="target"
      ref="commentsCard"
      :key="target.id"
      class="mt-4"
      :comments="target.target_comments"
      edit-permission="student.targets.comments"
      api-path="/targets/comments"
      :create-api-path="createApiPath"
      @comments-updated="target.target_comments = $event"
      @update:dirty="feedbackCommentDirty = $event"
    />
  </div>
</template>

<script>
import TargetCard from './TargetCard.vue';
import CommentsCard from '@/components/CommentsCard.vue';
import { mapGetters, mapState } from 'vuex';
import { useActiveTargetsStore } from '@/stores/active-targets';

export default {
  name: 'TutorTargetPage',
  components: { TargetCard, CommentsCard },
  emits: ['update:dirty'],
  expose: ['save'],
  data() {
    return {
      loading: true,
      loadError: false,
      targetId: null,
      mentorComment: '',
      target: null,
      processing: false,
      approveSnackbar: {
        message: '',
        type: 'success',
        active: false,
      },
      approveError: null,
      targetDirty: false,
      feedbackCommentDirty: false,
    };
  },
  setup() {
    const {
      actions: { removeTarget },
    } = useActiveTargetsStore();
    return {
      removeTarget,
    };
  },
  watch: {
    $route: {
      handler(to) {
        this.targetId = to.params.targetId;
      },
      deep: true,
    },
    targetId() {
      if (this.targetId) {
        this.loadTarget();
      }
    },
    dirtyComputed(x) {
      this.$emit('update:dirty', x);
    },
  },
  created() {
    if (!this.isCreating) {
      this.targetId = this.$route.params.targetId;
    } else {
      this.loading = false;
    }
  },
  computed: {
    ...mapState(['selectedStudent', 'userStaff']),
    ...mapGetters(['isSubjectMentor']),
    mentorCommentDirty() {
      if (this.isCreating || !this.target) return false;
      return this.mentorComment !== this.target.mentor_comment;
    },
    dirtyComputed() {
      return this.targetDirty || this.mentorCommentDirty || this.feedbackCommentDirty;
    },
    breadcrumbs() {
      return [
        {
          text: 'Targets',
          to: {
            name: 'TargetsListPage',
          },
        },
        {
          text: this.isCreating ? 'New Target' : this.target?.goal,
        },
      ];
    },
    isCreating() {
      return ['TargetCreatePage', 'MentorMeetingTargetCreatePage'].includes(this.$route.name);
    },
    canApprove() {
      return this.userStaffHasPermissionForSelectedStudent('student.targets.approve');
    },
    createApiPath() {
      return `/targets/${this.targetId}/comments`;
    },
    approvedBy() {
      if (!this.target.approved_by) return;
      if (this.userStaff.id === this.target.approved_by.id) return 'Me';
      return this.target.approved_by.name;
    },
  },
  methods: {
    async approve() {
      await this.approveAction(this.approveNoErrorHandling, 'Target approved', 'approve this target');
      this.removeTarget(this.targetId);
    },
    approveNoErrorHandling() {
      return this.$api.post(`/targets/${this.targetId}/approve`, {
        mentor_comment: this.mentorComment,
      });
    },
    async unapprove() {
      await this.approveAction(
        () => this.$api.post(`/targets/${this.targetId}/unapprove`, {}),
        'Target unapproved',
        'unapprove this target'
      );
    },
    async approveAction(action, successMessage, errorMessageDetails) {
      this.processing = true;
      this.approveError = '';
      try {
        const response = await action();
        this.target = response.data;
        this.approveSnackbar = {
          message: successMessage,
          type: 'success',
          active: true,
        };
      } catch (e) {
        this.approveError = `Cannot ${errorMessageDetails} at the moment. Please try again later.`;
      }
      this.processing = false;
    },
    async loadTarget() {
      this.loading = true;
      this.loadError = false;
      try {
        const response = await this.$api.get(`/targets/${this.targetId}`);
        this.target = response.data;
        this.mentorComment = response.data.mentor_comment;
      } catch (e) {
        console.log(e);
        this.loadError = true;
      }
      this.loading = false;
    },
    async save() {
      let error = null;
      if (this.targetDirty) {
        error = await this.$refs.targetCard.save();
      }

      if (!error && this.mentorCommentDirty) {
        await this.approveNoErrorHandling();
      }

      if (!error && this.feedbackCommentDirty) {
        await this.$refs.commentsCard.saveIfDirty();
      }

      return error;
    },
  },
};
</script>
