<template>
  <div>
    <mosaic-loading-and-error-cards object-type="Pending ECT" :load="loadEcts" loading-type="list">
      <v-card class="mb-4">
        <v-card-text>
          <mosaic-card-title class="pb-1"
            >Pending ECTs

            <template #actions>
              <mosaic-btn @click.prevent="exportRegistrations()"><v-icon>mdi-table-arrow-down</v-icon></mosaic-btn>
            </template>
          </mosaic-card-title>
          <div>
            Pending {{ traineeNounPluralised() }} are {{ traineeNounPluralised() }} that have been registered by schools
            and are either awaiting having their QTS checked with the TRA or awaiting being approved by an admin. The
            QTS check happens automatically in the background and should happen within an hour of the ECT being
            registered.
          </div>
          <div class="d-flex align-center flex-wrap" style="row-gap: 16px">
            <div class="d-flex align-center flex-wrap" style="row-gap: 16px">
              <v-text-field
                v-model="nameEmailFilter"
                hide-details
                style="width: 300px"
                :label="`Filter ${traineeNounPluralised()} by name or email`"
                prepend-icon="mdi-magnify"
              />
              <v-select
                v-model="statusFilter"
                class="ml-4"
                style="width: 200px"
                hide-details
                :items="sortedEctStatusItems"
                :label="`Filter by ${traineeNoun()} status`"
              ></v-select>
            </div>
          </div>
        </v-card-text>
      </v-card>
      <v-card>
        <v-card-text>
          <mosaic-list
            :empty-text="`There are no pending ECTs${ects.length > 0 ? ' for these filters' : ''}`"
            :items="paginatedEcts"
          >
            <template #item="{ item: ect }">
              <mosaic-list-item
                :to="ectClickTo(ect.id)"
                icon="mdi-account-question"
                :title="ect.name"
                :subtitle="`Registered ${formatDate(ect.registrationDateTime)}. Starting ${formatDate(
                  ect.startDate
                )} at ${ect.startSchool.displayName}`"
              >
                <template #information>
                  <div v-if="ect.trainedOutsideEngland" class="mr-4">
                    <v-tooltip location="top">
                      <template #activator="{ props }">
                        <span class="pr-4" v-bind="props">
                          <v-icon color="primary">mdi-earth</v-icon>
                        </span>
                      </template>
                      <span>Trained outside of England</span>
                    </v-tooltip>
                  </div>
                  <div>
                    <v-chip v-if="ect.status === 'awaiting_approval'" color="accent">Awaiting Approval</v-chip>
                    <v-chip v-else-if="ect.status === 'awaiting_qts'" color="secondary">Awaiting QTS Check</v-chip>
                    <v-chip v-else-if="ect.status === 'does_not_have_qts'" color="red">Does Not Have QTS</v-chip>
                    <v-chip v-else-if="ect.status === 'teacher_not_found'" color="red"
                      >{{ traineeNoun() }} Not Found On The TRA Portal</v-chip
                    >
                    <v-chip v-else-if="ect.status === 'rejected'" color="red">Rejected</v-chip>
                  </div>
                </template>
                <template #actions>
                  <mosaic-icon-btn
                    icon="mdi-package-down"
                    :tooltip="'Archive ' + traineeNounCapitalised()"
                    @click.prevent="archiveEct(ect)"
                  />
                </template>
              </mosaic-list-item>
            </template>
          </mosaic-list>
        </v-card-text>
      </v-card>
      <mosaic-pagination
        v-model="currentPage"
        v-model:page-size="pageSize"
        class="mt-4"
        :total="filteredAndSortedEcts.length"
      />
      <mosaic-dialog
        v-model:active="exportRegistrationsDialog.active"
        title="Export ECT registrations"
        :error-message="exportRegistrationsDialog.error"
      >
        <div class="mb-2">
          This will export a CSV file containing the pending ECTs for this institution. This may take a few minutes.
        </div>
        <mosaic-card-subheading>Export Type</mosaic-card-subheading>
        <mosaic-radio-buttons
          v-if="!selectedInstitution.early_careers"
          v-model="exportRegistrationsDialog.exportType"
          :radios="exportTypes"
          inline
        ></mosaic-radio-buttons>
        <mosaic-card-subheading>Filters</mosaic-card-subheading>
        <mosaic-checkbox
          v-model="exportRegistrationsDialog.includeAbOnly"
          name="include-ab-only"
          :label="`Include ${selectedInstitution.ab_only_ect_package_name} package registrations`"
          no-icon
        />
        <mosaic-checkbox
          v-model="exportRegistrationsDialog.includeAbAndEcf"
          name="include-ab-and_ecf"
          :label="`Include ${selectedInstitution.ab_and_ecf_ect_package_name} package registrations`"
          no-icon
        />
        <mosaic-alert v-if="!canSubmitExport" type="error">At least one package type must be selected.</mosaic-alert>
        <mosaic-checkbox
          v-model="exportRegistrationsDialog.includeApproved"
          name="include-approved"
          label="Include already approved ECTs?"
          no-icon
        />
        <div>Include registrations from</div>
        <div class="d-flex">
          <div style="flex-basis: 48%" class="mr-2">
            <mosaic-date-picker
              v-model:date="exportRegistrationsDialog.fromDate"
              label="Date"
              :exact-width="false"
            ></mosaic-date-picker>
          </div>
          <div style="flex-basis: 48%">
            <mosaic-time-picker v-model="exportRegistrationsDialog.fromTime" label="Time" />
          </div>
        </div>
        <div>Include registrations up to</div>
        <div class="d-flex">
          <div style="flex-basis: 48%" class="mr-2">
            <mosaic-date-picker
              v-model:date="exportRegistrationsDialog.toDate"
              label="Date"
              :exact-width="false"
            ></mosaic-date-picker>
          </div>
          <div style="flex-basis: 48%">
            <mosaic-time-picker v-model="exportRegistrationsDialog.toTime" label="Time" />
          </div>
        </div>

        <template #buttons>
          <mosaic-btn
            text
            color="primary"
            :disabled="!canSubmitExport"
            :loading="exportRegistrationsDialog.loading"
            @click.prevent="submitExportRegistrations()"
            >Export</mosaic-btn
          >
        </template>
      </mosaic-dialog>
      <mosaic-dialog
        v-model:active="archiveDialog.active"
        :title="`Archive ${traineeNounCapitalised()}`"
        :error-message="archiveDialog.error"
      >
        <div>Are you sure you want to archive {{ archiveDialog.title }}'s account?</div>
        <div class="pt-2">You will be able to retrieve this account later by contacting support.</div>
        <template #buttons>
          <mosaic-btn
            variant="text"
            ripple
            color="error"
            :disabled="archiveDialog.processing"
            @click.prevent="submitArchiveEct()"
            >Archive
          </mosaic-btn>
        </template>
      </mosaic-dialog>
    </mosaic-loading-and-error-cards>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { syncQueryParamsMixin } from '@/mixins/query-mixins';
import { useQueryStore } from '@/stores/query';
import { exportEctRegistrations } from '@/utils/ect/ect-registrations';
import { ectStatusItems } from '@/utils/ect/ect';
import { toISOString } from '@/utils/date';

const sortedStatuses = [
  'all',
  'does_not_have_qts',
  'awaiting_approval',
  'awaiting_qts',
  'teacher_not_found',
  'rejected',
];

export default {
  name: 'TutorPendingEctsPage',
  setup() {
    const queryStore = useQueryStore();
    return { queryStore };
  },
  mixins: [syncQueryParamsMixin({ nameEmailFilter: { query: 'name' }, statusFilter: { query: 'status' } })],
  data: () => ({
    nameEmailFilter: '',
    statusFilter: 'all',
    ectStatusItems: ectStatusItems,
    exportTypes: [
      {
        label: 'Full Export',
        value: 'full',
      },
      {
        label: 'ECF-specific export with restricted fields',
        value: 'ecf',
      },
    ],
    currentPage: 1,
    pageSize: 10,
    exportRegistrationsDialog: {
      active: false,
      loading: false,
      includeAbOnly: true,
      includeAbAndEcf: true,
      includeApproved: true,
      fromDate: null,
      fromTime: null,
      fromTimeMenu: false,
      toDate: null,
      toTime: null,
      toTimeMenu: false,
      exportType: 'full', // 'Full Export' | 'ECF export with restricted fields
      error: '',
    },
    ects: [],
    archiveDialog: {
      active: false,
      id: null,
      title: '',
      processing: false,
      error: '',
    },
  }),
  watch: {
    ects() {
      this.currentPage = 1;
    },
  },

  computed: {
    ...mapState(['selectedInstitution']),
    breadcrumbs() {
      return [{ text: 'Pending ECTs' }];
    },
    filteredAndSortedEcts() {
      return this.ects
        .filter(x => {
          const nameEmailFilter =
            x.name?.toLowerCase().includes(this.nameEmailFilter) ||
            x.email.toLowerCase().includes(this.nameEmailFilter);
          const statusFilter = this.statusFilter == 'all' || x.status === this.statusFilter;
          return nameEmailFilter && statusFilter;
        })
        .sort(
          (a, b) =>
            sortedStatuses.indexOf(a.status) - sortedStatuses.indexOf(b.status) ||
            new Date(a.registrationDateTime) - new Date(b.registrationDateTime) ||
            a.name.localeCompare(b.name)
        );
    },
    paginatedEcts() {
      const index = (this.currentPage - 1) * this.pageSize;
      return this.filteredAndSortedEcts.slice(index, index + this.pageSize);
    },
    canSubmitExport() {
      return this.exportRegistrationsDialog.includeAbAndEcf || this.exportRegistrationsDialog.includeAbOnly;
    },
    sortedEctStatusItems() {
      return [...this.ectStatusItems].sort((a, b) => sortedStatuses.indexOf(a.value) - sortedStatuses.indexOf(b.value));
    },
  },
  methods: {
    async loadEcts() {
      const r = await this.$api.get(`/institutions/${this.selectedInstitution.id}/pending-ects`);
      this.ects = r.data;
    },
    ectClickTo(id) {
      return { name: 'TutorEctApproveRegistrationPage', params: { ectId: id } };
    },
    exportRegistrations() {
      this.exportRegistrationsDialog.active = true;
    },
    archiveEct(ect) {
      this.archiveDialog = {
        active: true,
        studentId: ect.studentId,
        title: ect.name || ect.email,
        processing: false,
        error: '',
      };
    },
    async submitArchiveEct() {
      this.archiveDialog.processing = true;
      try {
        await this.$api.put(`students/${this.archiveDialog.studentId}/archive`);
        this.archiveDialog = {
          active: false,
          processing: false,
        };
        this.loadEcts();
      } catch (e) {
        this.archiveDialog.error = `Sorry, cannot archive this ${this.traineeNounCapitalised()} at the moment`;
        this.archiveDialog.processing = false;
      }
    },
    async submitExportRegistrations() {
      this.exportRegistrationsDialog.loading = true;
      this.exportRegistrationsDialog.error = '';
      try {
        const r = await this.$api.get(`/institutions/${this.selectedInstitution.id}/pending-ects/export`);
        const includedPackageTypes = [
          this.exportRegistrationsDialog.includeAbOnly ? 'ab_only' : null,
          this.exportRegistrationsDialog.includeAbAndEcf ? 'ab_and_ecf' : null,
        ].filter(x => x);
        exportEctRegistrations(
          r.data,
          this.exportRegistrationsDialog.exportType,
          includedPackageTypes,
          this.exportRegistrationsDialog.includeApproved,
          this.stringifyDateTime(this.exportRegistrationsDialog.fromDate, this.exportRegistrationsDialog.fromTime),
          this.stringifyDateTime(this.exportRegistrationsDialog.toDate, this.exportRegistrationsDialog.toTime)
        );
        this.exportRegistrationsDialog.active = false;
      } catch (e) {
        this.exportRegistrationsDialog.error = 'Sorry, cannot export your ECT registrations at the moment';
        console.log(e);
      }
      this.exportRegistrationsDialog.loading = false;
    },
    stringifyDateTime(date, time) {
      if (!date) return null;
      if (!time) time = '00:00';
      return toISOString(date, time);
    },
  },
};
</script>
