<template>
  <div>
    <mosaic-card class="mb-4">
      <mosaic-card-title>Configuration/Feature Switches</mosaic-card-title>
      <v-list lines="one">
        <v-list-item v-for="(flag, index) in booleanFlags" :key="index" density="compact">
          <template #prepend>
            <v-avatar>
              <v-icon>mdi-cog-outline</v-icon>
            </v-avatar>
          </template>
          <div class="pl-2">
            <mosaic-switch
              v-model="flag.value"
              :label="flag.key"
              :name="flag.key"
              :update-value="v => updateFlag({ key: flag.key, value: v })"
              object-type="configuration"
            />
          </div>
        </v-list-item>
      </v-list>
      <mosaic-snackbar
        v-model="flagsSnackbar.active"
        contained
        :color="flagsSnackbar.color"
        :message="flagsSnackbar.message"
      />
    </mosaic-card>

    <mosaic-card class="mb-4">
      <mosaic-card-title>Reviews</mosaic-card-title>
      <v-list>
        <v-list-item class="pt-4">
          <template #prepend>
            <v-avatar>
              <v-icon>mdi-format-text</v-icon>
            </v-avatar>
          </template>
          <v-list-item-title>
            <div class="d-flex align-center">
              <v-text-field v-model="reviewNounEditable" class="shrink mr-4" label="Review noun"></v-text-field>
              <v-btn ripple @click.prevent="updateNouns()">Update</v-btn>
            </div>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </mosaic-card>

    <mosaic-card class="mb-4">
      <mosaic-card-title>Storage</mosaic-card-title>
      <v-list>
        <v-list-item>
          <template #prepend>
            <v-avatar>
              <v-icon>mdi-folder-outline</v-icon>
            </v-avatar>
          </template>
          <v-list-item-title>{{ 'Storage Type: ' + adminInstitution.storage_type }}</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="adminInstitution.storage_type === 'One Drive'">
          <template #prepend>
            <v-avatar>
              <v-icon>mdi-cog-outline</v-icon>
            </v-avatar>
          </template>
          <div class="d-flex align-center">
            <mosaic-switch
              v-model="adminInstitution.config.one_drive_write_access"
              class="mr-2 pl-2"
              label="one_drive_write_access"
              :disabled="processing || adminInstitution.config.one_drive_write_access"
              @update:model-value="
                updateFlag({
                  key: 'one_drive_write_access',
                  value: adminInstitution.config.one_drive_write_access,
                })
              "
            />
            <mosaic-help>
              This determines whether files are shared with read access or write access. Once it has been set to write
              then it cannot be reversed as we currently do not support revoking permissions.
            </mosaic-help>
          </div>
        </v-list-item>
      </v-list>
      <div v-if="adminInstitution.storage_type === 'One Drive'" class="ml-6">
        <mosaic-card-subheading>One Drive Storage Constraints</mosaic-card-subheading>
        <div class="grid-container my-4">
          <div></div>
          <div class="font-weight-bold">User Type</div>
          <div class="font-weight-bold">Constraint Type</div>
          <div class="font-weight-bold">Domain</div>
          <div class="font-weight-bold">Role</div>
          <div class="font-weight-bold"></div>
          <div class="font-weight-bold"></div>

          <template v-for="c in adminInstitution.institution_storage_account_constraints" :key="'group-col-' + c.id">
            <div><v-icon>mdi-folder-cog-outline</v-icon></div>
            <div>
              {{ c.is_student_constraint ? traineeNounCapitalised() : 'Instructor' }}
            </div>
            <div>
              {{ c.account_type === 'organisational' ? 'Organisational' : 'Personal' }}
            </div>
            <div>{{ renderConstraintDomain(c) }}</div>
            <div>{{ renderConstraintRole(c) }}</div>
            <div>
              <ndt-icon-button icon="pencil" tooltip="Update Constraint" @click.prevent="updateStorageConstraint(c)" />
            </div>
            <div>
              <ndt-icon-button icon="delete" tooltip="Remove Constraint" @click.prevent="removeStorageConstraint(c)" />
            </div>
          </template>
        </div>
        <div v-if="adminInstitution.institution_storage_account_constraints.length === 0">
          There are no storage constraints
        </div>
        <div class="d-flex justify-end">
          <v-btn @click.prevent="addStorageConstraint()">Add Storage Constraint</v-btn>
        </div>
      </div>
    </mosaic-card>

    <mosaic-card v-if="!adminInstitution.config.early_careers">
      <mosaic-card-title>Nasbtt Modules</mosaic-card-title>
      <mosaic-error-alert :override-error-message="nasbttModuleOrganisationsLoadError" />
      <mosaic-autocomplete
        name="nasbtt-modules-orgs"
        label="Nasbtt Module Organisation"
        v-model="nasbttModuleOrganisationId"
        :items="nasbttModuleOrganisations"
        item-title="organisation_name"
        item-value="organisation_id"
        clearable
      />
      <mosaic-error-alert :error="nasbttModuleOrganisationsSaveError" />
      <mosaic-success-snackbar
        v-model="updateNasbttModuleOrganisationSuccess"
        object-type="NASBTT Module Organisation"
        action="updated"
      />
      <div class="mt-2 text-right">
        <v-btn
          ripple
          :disabled="
            processing ||
            !!nasbttModuleOrganisationsLoadError ||
            adminInstitution.nasbtt_modules_organisation_id === nasbttModuleOrganisationId
          "
          @click.prevent="updateNasbttModuleOrganisation()"
          >Save</v-btn
        >
      </div>
    </mosaic-card>

    <ndt-dialog
      v-model:active="storageConstraintDialog.active"
      title="Add/update storage constraint"
      :on-close="() => (storageConstraintDialog.active = false)"
      :error-message="storageConstraintDialog.errorMessage"
    >
      <div class="d-flex align-center">
        <div style="width: 30%">
          <mosaic-select
            v-model="storageConstraintDialog.accountType.id"
            :items="accountTypes"
            label="Account Type"
            name="AccountType"
            item-title="name"
            item-value="id"
            no-icon
          ></mosaic-select>
        </div>
        <div v-if="storageConstraintDialog.accountType.id == 'organisational'" style="width: 40%">
          <v-text-field v-model="storageConstraintDialog.domain" class="ml-4" label="Domain"></v-text-field>
        </div>
      </div>
      <div>Applies to:</div>
      <div class="d-flex align-center">
        <div>
          <v-radio-group v-model="storageConstraintDialog.isStudentConstraint" inline>
            <v-radio label="Staff" :value="false"></v-radio>
            <v-radio label="Student" :value="true"></v-radio>
          </v-radio-group>
        </div>
        <div v-if="!storageConstraintDialog.isStudentConstraint" style="width: 30%" class="ml-4 mb-4">
          <mosaic-select
            v-model="storageConstraintDialog.roleId"
            :items="adminInstitution.roles"
            label="Role"
            name="Role"
            item-title="name"
            item-value="id"
            no-icon
          ></mosaic-select>
        </div>
      </div>
      <template #buttons>
        <v-btn
          variant="text"
          ripple
          :disabled="storageConstraintDialog.processing"
          @click.prevent="submitUpdateStorageConstraint()"
          >{{ storageConstraintDialog.constraintId ? 'Update' : 'Add' }}</v-btn
        >
      </template>
    </ndt-dialog>
    <ndt-dialog
      v-model:active="removeStorageConstraintDialog.active"
      title="Delete Storage constraint"
      :error-message="removeStorageConstraintDialog.errorMessage"
    >
      <span>Are you sure you want to delete this storage Constraint?</span>
      <template #buttons>
        <v-btn
          variant="text"
          ripple
          color="error"
          :disabled="removeStorageConstraintDialog.processing"
          @click.prevent="submitRemoveStorageConstraint()"
          >Delete</v-btn
        >
      </template>
    </ndt-dialog>
  </div>
</template>

<script>
import NdtDialog from '../../components/NdtDialog.vue';
import NdtIconButton from '../../components/NdtIconButton.vue';
import { getFullAdminInstitution } from '../../models/institutions';
import { mapState } from 'vuex';

export default {
  name: 'AdminInstitutionConfigPage',
  data: function () {
    return {
      configArray: [],
      reviewNounEditable: '',
      editor: null,
      processing: false,
      adminStandardNoun: '',
      adminStandardNounPlural: '',
      flagsSnackbar: {
        active: false,
        color: 'success',
        message: '',
      },
      storageConstraintDialog: {
        constraintId: null,
        isStudentConstraint: false,
        domain: '',
        accountType: {
          name: 'Organisational',
          id: 'organisational',
        },
        roleId: null,
        active: false,
        processing: false,
        errorMessage: '',
      },
      removeStorageConstraintDialog: {
        active: false,
        processing: false,
        errorMessage: '',
        constraintId: null,
      },
      accountTypes: [
        {
          name: 'Personal',
          id: 'personal',
        },
        {
          name: 'Organisational',
          id: 'organisational',
        },
      ],
      nasbttModuleOrganisationId: null,
      nasbttModuleOrganisations: [],
      nasbttModuleOrganisationsLoadError: '',
      nasbttModuleOrganisationsSaveError: false,
      updateNasbttModuleOrganisationSuccess: false,
    };
  },
  watch: {
    adminInstitution() {
      this.deconstructConfigObject();
      this.nasbttModuleOrganisationId = this.adminInstitution.nasbtt_modules_organisation_id;
    },
  },
  created() {
    this.deconstructConfigObject();
    this.nasbttModuleOrganisationId = this.adminInstitution.nasbtt_modules_organisation_id;
    this.reviewNounEditable = this.reviewNoun;
    this.loadNasbttModuleOrganisations();
  },
  computed: {
    ...mapState(['adminInstitution']),
    breadcrumbs() {
      return [
        {
          text: this.adminInstitution?.name,
          to: {
            name: 'AdminInstitutionPage',
          },
        },
        {
          text: 'Config',
        },
      ];
    },
    booleanFlags() {
      return this.configArray.filter(
        c =>
          typeof c.value === 'boolean' &&
          !(
            c.key == 'has_curriculum' ||
            c.key == 'has_proficiencies' ||
            c.key == 'one_drive_write_access' ||
            c.key == 'early_careers'
          )
      );
    },
    reflectionTemplate: {
      get() {
        return this.adminInstitution.config.course_reflection_template;
      },
      set(x) {
        this.$store.commit('updateAdminInstitutionWithChanges', { course_reflection_template: x });
      },
    },
  },
  methods: {
    async loadNasbttModuleOrganisations() {
      try {
        const r = await this.$api.get('/nasbtt-modules/organisations');
        this.nasbttModuleOrganisations = r.data;
      } catch (e) {
        console.log(e);
        this.nasbttModuleOrganisationsLoadError = 'Nasbtt Module Organisations failed to load';
      }
    },
    async updateNasbttModuleOrganisation() {
      this.nasbttModuleOrganisationsError = '';
      this.processing = true;
      try {
        const r = await this.$api.put(`institutions/${this.adminInstitution.id}`, {
          nasbtt_modules_organisation_id: this.nasbttModuleOrganisationId,
        });
        this.$store.commit('updateAdminInstitution', r.data);
        this.updateNasbttModuleOrganisationSuccess = true;
      } catch (e) {
        console.log(e);
        this.nasbttModuleOrganisationsSaveError = true;
      }
      this.processing = false;
    },
    deconstructConfigObject() {
      const objectArray = Object.entries(this.adminInstitution.config);
      this.configArray = [];
      objectArray.forEach(([key, value]) => {
        this.configArray.push({
          key: key,
          value: value,
        });
      });
    },

    addStorageConstraint() {
      this.storageConstraintDialog = {
        constraintId: null,
        isStudentConstraint: false,
        domain: '',
        accountType: {
          name: 'Organisational',
          id: 'organisational',
        },
        roleId: null,
        active: true,
        processing: false,
        errorMessage: '',
      };
    },
    updateStorageConstraint(constraint) {
      this.storageConstraintDialog = {
        constraintId: constraint.id,
        isStudentConstraint: constraint.is_student_constraint,
        domain: constraint.domain,
        accountType: {
          id: constraint.account_type,
          name: this.accountTypes.find(x => x.id == constraint.account_type).name,
        },
        roleId: constraint.role_id,
        active: true,
        processing: false,
        errorMessage: '',
      };
    },
    renderConstraintRole(c) {
      if (c.is_student_constraint) return 'n/a';
      return this.adminInstitution.roles.find(x => x.id === c.role_id)?.name || 'All Roles';
    },
    renderConstraintDomain(c) {
      if (c.account_type === 'personal') return 'n/a';
      return c.domain || 'No domain restriction';
    },
    async submitUpdateStorageConstraint() {
      this.storageConstraintDialog.processing = true;
      this.storageConstraintDialog.errorMessage = '';
      const body = {
        role_id: this.storageConstraintDialog.isStudentConstraint ? null : this.storageConstraintDialog.roleId,
        domain:
          this.storageConstraintDialog.accountType.id === 'organisational' ? this.storageConstraintDialog.domain : null,
        is_student_constraint: this.storageConstraintDialog.isStudentConstraint,
        account_type: this.storageConstraintDialog.accountType.id,
      };
      let apiCall;
      const storageConstraintUrl = `/institutions/${this.adminInstitution.id}/storage-constraints`;
      if (this.storageConstraintDialog.constraintId) {
        apiCall = x => this.$api.put(`${storageConstraintUrl}/${this.storageConstraintDialog.constraintId}`, x);
      } else {
        apiCall = x => this.$api.post(storageConstraintUrl, x);
      }
      try {
        await apiCall(body);
        this.storageConstraintDialog.processing = false;
        this.storageConstraintDialog.active = false;
        await getFullAdminInstitution(this.adminInstitution.id, this.$api);
      } catch (e) {
        console.log(e);
        this.storageConstraintDialog.errorMessage = "Sorry, can't add/update this storage constraint right now";
        this.storageConstraintDialog.processing = false;
      }
    },
    removeStorageConstraint(c) {
      this.removeStorageConstraintDialog = {
        active: true,
        processing: false,
        errorMessage: '',
        constraintId: c.id,
      };
    },
    async submitRemoveStorageConstraint() {
      this.removeStorageConstraintDialog.processing = true;
      try {
        await this.$api.delete(
          `institutions/${this.adminInstitution.id}/storage-constraints/${this.removeStorageConstraintDialog.constraintId}`
        );
        this.removeStorageConstraintDialog.active = false;
        await getFullAdminInstitution(this.adminInstitution.id, this.$api);
      } catch (e) {
        console.log(e);
        this.removeStorageConstraintDialog.errorMessage = 'Sorry, cannot remove storage constraint right now';
      }
      this.storageConstraintDialog.processing = false;
    },
    async updateFlag(flag) {
      await this.$api.put(`/institutions/${this.adminInstitution.id}/feature-switches`, {
        name: flag.key,
        on: flag.value,
      });
    },
    async updateNouns() {
      this.processing = true;
      try {
        const r = await this.$api.put(`institutions/${this.adminInstitution.id}`, {
          review_noun: this.reviewNounEditable,
        });
        this.flagsSnackbar = {
          active: true,
          color: 'success',
          message: 'Updated',
        };
        this.$store.commit('updateAdminInstitution', r.data);
      } catch (e) {
        console.log(e);
        this.flagsSnackbar = {
          active: true,
          color: 'error',
          message: 'Error whilst updating',
        };
      }
      this.processing = false;
    },
  },
  components: { NdtDialog, NdtIconButton },
};
</script>

<style scoped>
.grid-container {
  display: grid;
  grid-template-columns: 40px 1fr 1fr 1fr 1fr 30px 30px;
  column-gap: 12px;
  align-items: center;
}
</style>
