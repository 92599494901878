<template>
  <div>
    <mosaic-loading-card v-if="loading || stateProcessing" type="card" />
    <mosaic-load-error-card v-else-if="loadError || stateError" :object-type="curriculumNoun" @retry="load" />
    <curriculum-statement-card
      v-else
      :curriculum-statement="curriculumStatement"
      :show-theory="selectedCurriculum.show_theory"
      :edit-evidence="editEvidence"
      :create-evidence="createEvidence"
      :click-reflection-to="clickReflectionTo"
      :click-target-to="clickTargetTo"
      :click-lesson-observation-to="clickLessonObservationTo"
      :click-mentor-meeting-to="clickMentorMeetingTo"
      :click-cohort-course-week-to="clickCohortCourseWeekTo"
      :click-curriculum-teaching-strategy-to="clickCurriculumTeachingStrategyTo"
      :click-student-assignment-to="clickStudentAssignmentTo"
      @click-add-target="addTarget()"
      @click-add-reflection="addReflection()"
      @click-add-lesson-observation="addLessonObservation()"
      @click-add-mentor-meeting="addMentorMeeting()"
    />
    <CreateEvidenceDialog
      v-model:active="createEvidenceDialog.active"
      :on-close="loadCurriculumStatement"
      :target-id="createEvidenceDialog.targetId"
      :reflection-id="createEvidenceDialog.reflectionId"
      :lesson-observation-id="createEvidenceDialog.lessonObservationId"
      :mentor-meeting-id="createEvidenceDialog.mentorMeetingId"
      :student-assignment-id="createEvidenceDialog.studentAssignmentId"
      :evidence-types="types"
      :title-placeholder="createEvidenceDialog.title"
      :suggested-code="curriculumThemeCode"
    />
    <EditEvidenceDialog
      v-model:active="editEvidenceDialog.active"
      :evidence-types="types"
      :evidence="editEvidenceDialog.evidence"
    />
  </div>
</template>

<script>
import CurriculumStatementCard from './CurriculumStatementCard.vue';
import CreateEvidenceDialog from '../../components/student/CreateEvidenceDialog.vue';
import EditEvidenceDialog from '../../components/student/EditEvidenceDialog.vue';
import { mapState } from 'vuex';
import { evidenceTypes } from '../../models/evidence-types';
import { mapStateProcessingAndError } from '@/store/map-store';
import { fromSnakeCaseToCamelCase } from '@/utils/transforms';

export default {
  name: 'StudentCurriculumStatementPage',
  data: function () {
    return {
      loading: true,
      loadError: false,
      curriculumStatementId: null,
      curriculumStatement: null,
      createEvidenceDialog: {
        active: false,
      },
      editEvidenceDialog: {
        active: false,
        evidence: {},
      },

      types: evidenceTypes,
    };
  },
  async created() {
    this.curriculumStatementId = this.$route.params.id.toString();
    await this.load();
  },
  computed: {
    ...mapState(['selectedCurriculum', 'userStudent']),
    ...mapStateProcessingAndError(['loadCurriculum']),
    breadcrumbs() {
      return [
        {
          text: this.curriculumNoun,
          to: {
            name: 'StudentCurriculumPage',
          },
        },
        {
          text: this.curriculumStatement?.code,
        },
      ];
    },
    curriculumThemeCode() {
      return this.curriculumStatement?.curriculum_theme?.code;
    },
  },
  methods: {
    async load() {
      await Promise.all([this.loadCurriculumStatement(), this.$store.dispatch('loadCurriculum')]);
    },
    async loadCurriculumStatement() {
      this.loading = true;
      this.loadError = false;
      try {
        const r = await this.$api.get(`/curriculum-statements/${this.curriculumStatementId}`);
        this.curriculumStatement = r.data;
        this.curriculumStatement.theory_content_layout = fromSnakeCaseToCamelCase(r.data.theory_content_layout);
        this.curriculumStatement.practice_content_layout = fromSnakeCaseToCamelCase(r.data.practice_content_layout);
      } catch (e) {
        console.log(e);
        this.loadError = true;
      }
      this.loading = false;
    },
    addTarget() {
      this.$router.push({
        name: 'TargetCreatePage',
        params: { studentId: this.userStudent.id },
        query: {
          curriculumStatementId: this.curriculumStatement.id,
          returnUrl: this.$router.resolve({
            name: 'StudentCurriculumStatementPage',
          }).href,
        },
      });
    },
    addReflection() {
      this.$router.push({
        name: 'StudentReflectionCreatePage',
        query: {
          curriculumStatementId: this.curriculumStatement.id,
          returnUrl: this.$router.resolve({ name: 'StudentCurriculumStatementPage' }).href,
        },
      });
    },
    addLessonObservation() {
      this.$router.push({
        name: 'StudentLessonObservationCreatePage',
        query: {
          curriculumStatementId: this.curriculumStatement.id,
          returnUrl: this.$router.resolve({ name: 'StudentCurriculumStatementPage' }).href,
        },
      });
    },
    addMentorMeeting() {
      this.$router.push({
        name: 'StudentMentorMeetingCreatePage',
        query: {
          curriculumStatementId: this.curriculumStatement.id,
          returnUrl: this.$router.resolve({ name: 'StudentCurriculumStatementPage' }).href,
        },
      });
    },
    clickTargetTo(target) {
      return {
        name: 'TargetPage',
        params: { studentId: this.userStudent.id, targetId: target.id },
        query: {
          returnUrl: this.$router.resolve({ name: 'StudentCurriculumStatementPage' }).href,
        },
      };
    },
    clickReflectionTo(reflection) {
      return {
        name: 'ReflectionPage',
        params: { studentId: this.userStudent.id, reflectionId: reflection.id },
        query: {
          returnUrl: this.$router.resolve({ name: 'StudentCurriculumStatementPage' }).href,
        },
      };
    },
    clickLessonObservationTo(lo) {
      return {
        name: 'LessonObservationPage',
        params: { studentId: this.userStudent.id, id: lo.id },
        query: {
          returnUrl: this.$router.resolve({ name: 'StudentCurriculumStatementPage' }).href,
        },
      };
    },
    clickMentorMeetingTo(meeting) {
      return {
        name: 'MentorMeetingPage',
        params: { studentId: this.userStudent.id, id: meeting.id },
        query: {
          returnUrl: this.$router.resolve({ name: 'StudentCurriculumStatementPage' }).href,
        },
      };
    },
    clickCohortCourseWeekTo(week) {
      return {
        name: 'StudentCohortCourseWeekPage',
        params: { studentWeekId: week.id },
        query: {
          returnUrl: this.$router.resolve({ name: 'StudentCurriculumStatementPage' }).href,
        },
      };
    },
    clickStudentAssignmentTo(sa) {
      return {
        name: 'StudentAssignmentLatestVersionPage',
        params: { id: sa.assignment_id },
        query: {
          returnUrl: this.$router.resolve({ name: 'StudentCurriculumStatementPage' }).href,
        },
      };
    },
    clickCurriculumTeachingStrategyTo(ts) {
      return {
        name: 'StudentCurriculumTeachingStrategyPage',
        params: { id: ts.id },
      };
    },
    createEvidence(item) {
      this.createEvidenceDialog = {
        active: true,
        title: item.type == 'target' ? item.goal : item.title,
        targetId: item.type == 'target' ? item.id : null,
        reflectionId: item.type == 'reflection' ? item.id : null,
        lessonObservationId: item.type == 'lesson-observation' ? item.id : null,
        mentorMeetingId: item.type == 'mentor-meeting' ? item.id : null,
        studentAssignmentId: item.type == 'student-assignment' ? item.id : null,
      };
    },
    editEvidence(item) {
      this.$api.get(`/evidence/${item.evidence_id}`).then(response => {
        this.editEvidenceDialog = {
          active: true,
          evidence: response.data,
        };
      });
    },
  },
  components: { CurriculumStatementCard, CreateEvidenceDialog, EditEvidenceDialog },
};
</script>
