import type { Ref } from 'vue';
import { ref } from 'vue';
import type { Api } from '@/composables/api';

export const invalidCharacters = ['/', '\\', '*', ':', '?', '|', '"', '<', '>'];

async function openS3File(
  urlPrefix: string,
  fileId: string | number,
  error: Ref<boolean>,
  download: boolean,
  api: Api
) {
  try {
    const r = await api.get<{ web_url: string }>(`${urlPrefix}/${fileId}?download=${download}`);
    window.open(r.data.web_url, '_blank');
  } catch (e) {
    console.log(e);
    error.value = true;
  }
}

export function useOpenS3Files(urlPrefix: string, api: Api) {
  const error = ref(false);
  return {
    open: (fileId: string | number) => openS3File(urlPrefix, fileId, error, false, api),
    download: (fileId: string | number) => openS3File(urlPrefix, fileId, error, true, api),
    error,
  };
}
