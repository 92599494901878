<template>
  <ndt-dialog v-model:active="activeNdtDialog" :title="title" :error-message="errorMessage">
    <span>Are you sure you want to delete "{{ item.name }}"?</span>
    <template #buttons>
      <v-btn variant="text" ripple color="error" :disabled="processing" @click.prevent="submitDeleteItem()"
        >Delete</v-btn
      >
    </template>
  </ndt-dialog>
</template>

<script>
import NdtDialog from '../NdtDialog.vue';

export default {
  name: 'DeleteItemDialog',
  components: { NdtDialog },
  props: {
    active: { type: Boolean, required: true },
    item: { type: Object, required: true },
  },
  emits: ['update:active', 'close'],
  data: function () {
    return {
      activeNdtDialog: this.active,
      processing: false,
      errorMessage: '',
      title: '',
    };
  },
  watch: {
    activeNdtDialog(x) {
      this.$emit('update:active', x);
    },
    active(x) {
      this.activeNdtDialog = x;
    },
    item(item) {
      this.title = item.type === 'file' ? 'Delete File' : 'Delete Folder';
    },
  },
  methods: {
    async submitDeleteItem() {
      this.processing = true;
      this.errorMessage = '';
      try {
        await this.$api.delete(`${this.item.filesApiPath}/${encodeURIComponent(this.item.id)}`);
        this.activeNdtDialog = false;
        this.$emit('close');
      } catch (e) {
        console.log(e);
        this.errorMessage = `Sorry, cannot delete your ${this.item.type} at the moment`;
      }
      this.processing = false;
    },
  },
};
</script>
