<template>
  <mosaic-tooltip-chip :color="chip.color">
    <template #text>
      {{ chip.text }}
    </template>

    <template #tooltip>
      {{ chip.tooltip }}
    </template>
  </mosaic-tooltip-chip>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { mapGetters } from '@/store/map-store';

type Status = 'submitted' | 'started' | 'not_started';

const props = defineProps<{
  status?: Status;
}>();

const { traineeNounCapitalised } = mapGetters();

const chip = computed(() => createChip(traineeNounCapitalised.value(), props.status));

function createChip(traineeNounCapitalised: string, status?: Status) {
  if (status === 'not_started') {
    return {
      text: 'Not Started',
      tooltip: `This ${traineeNounCapitalised} has not yet started the Reflection`,
      color: 'accent',
    };
  } else if (status === 'started') {
    return {
      text: 'Started',
      tooltip: `This ${traineeNounCapitalised} has started the Reflection`,
      color: 'accent',
    };
  } else if (status === 'submitted') {
    return {
      text: 'Submitted',
      tooltip: `This ${traineeNounCapitalised} has submitted the Reflection`,
      color: 'secondary',
    };
  } else {
    return {
      text: 'Not Assigned',
      tooltip: `The Reflection is not assigned to this ${traineeNounCapitalised}`,
      color: 'secondary',
    };
  }
}
</script>
