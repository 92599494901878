<template>
  <lesson-observations-list
    :student-id="selectedStudent.id"
    :can-create-lesson-observations="canCreateLessonObservations"
    :lesson-observation-click-to="lessonObservationClickTo"
    @click-create-lesson-observation="createLessonObservationClick"
  >
  </lesson-observations-list>
</template>

<script>
import { mapState } from 'vuex';
import LessonObservationsList from './LessonObservationsList.vue';

export default {
  name: 'TutorLessonObservationsListPage',
  components: { LessonObservationsList },
  data: () => ({}),
  computed: {
    ...mapState(['selectedInstitution', 'selectedStudent', 'user']),
    breadcrumbs() {
      return [
        {
          text: 'Lesson Observations',
        },
      ];
    },
    canCreateLessonObservations() {
      return this.userStaffHasPermissionForSelectedStudent('student.lessonObservations.create');
    },
  },
  methods: {
    lessonObservationClickTo(id) {
      return { name: 'LessonObservationPage', params: { id: id } };
    },
    createLessonObservationClick() {
      this.$router.push({ name: 'LessonObservationCreatePage' });
    },
  },
};
</script>
