<template>
  <div>
    <v-card class="mb-4">
      <v-card-text>
        <div class="pa-2">
          <div class="d-flex align-center" :class="{ 'flex-wrap justify-end': smallScreen }" style="row-gap: 8px">
            <div class="text-h6">General</div>
            <div v-if="dirty" class="mx-2">(unsaved)</div>
            <div v-else style="width: 63px" class="mx-2"></div>
            <div class="flex-grow-1"></div>
            <template v-if="!studentReview.approved">
              <mosaic-role-completed-chip
                class="pl-1"
                v-for="x in studentReview.overallReviewCompleted"
                :key="x.name"
                :role-id="x.roleId"
                :completed="x.completed"
              />
            </template>
            <v-btn v-if="next" class="ml-2" @click.prevent="$router.push(next)"
              ><v-icon>mdi-chevron-right</v-icon></v-btn
            >
            <v-btn v-else class="ml-2" @click.prevent="backToSummary()">Back to Summary</v-btn>
          </div>
          <div v-if="studentReview.approved" class="pl-1 pt-2">
            <v-chip color="secondary">Approved</v-chip>
            <span class="pl-1">This {{ reviewPointName }} has been approved and can no longer be edited</span>
          </div>
          <mosaic-quill
            v-if="studentReview.review.overallNote"
            :contents="studentReview.review.overallNote"
            :read-only="true"
          />
        </div>
      </v-card-text>
    </v-card>
    <v-card v-if="showCard" class="mb-4">
      <v-card-text>
        <div class="text-h5 pb-2">Review of Progress</div>
        <div class="pl-2 py-2">
          <div v-if="studentReview.review.overallJudgementSet && canViewOverallJudgement">
            <review-part-title
              :can-edit="!studentReview.approved && canEditJudgement"
              :completed="!!judgementDescriptorId"
            >
              Assessment of {{ traineeNounCapitalised() }} Progress</review-part-title
            >
            <div
              v-if="!studentReview.approved && studentReview.review.overallAssessmentGuidance"
              class="d-flex my-4 mx-10 guidance-box"
            >
              <div class="mb-2 mx-2 pt-2 pl-2">
                <div class="text-subtitle-1">Assessment Guidance</div>
                <div>
                  <mosaic-quill :contents="studentReview.review.overallAssessmentGuidance" :read-only="true" />
                </div>
              </div>
            </div>
            <div
              v-if="!studentReview.approved && canEditJudgement"
              class="px-2 pt-0 pb-2 ml-8 d-flex align-center"
              :class="{ 'justify-end': studentReview.review.overallAssessmentGuidance }"
            >
              <mosaic-judgement-select
                v-model="judgementDescriptorId"
                :readonly="false"
                :items="judgementDescriptors"
              />
            </div>
            <div
              v-else
              class="pa-2 pb-4 d-flex"
              :class="{ 'justify-end': studentReview.review.overallAssessmentGuidance }"
            >
              <div v-if="judgementDescriptor" class="d-flex align-center">
                <v-chip :color="judgementDescriptor.color">{{ judgementDescriptor.descriptor }}</v-chip>
              </div>
              <div v-else class="ml-6 text-body-1">To be completed</div>
            </div>
          </div>
          <div v-if="studentReview.review.recordAbsences">
            <review-part-title
              :can-edit="!studentReview.approved && canRecordAbsences"
              :completed="absenceCount !== null"
              >Absences</review-part-title
            >
            <div v-if="!studentReview.approved && canRecordAbsences" class="d-flex">
              <div class="mt-2 mr-2 ml-8">How many absences have there been since the last {{ reviewNoun }}?</div>
              <div style="width: 120px">
                <v-text-field
                  v-model="absenceCount"
                  type="number"
                  density="compact"
                  :rules="absencesRules"
                  class="text-center"
                ></v-text-field>
              </div>
            </div>
            <div v-else class="pa-2 pb-4">
              <div v-if="!isNaN(parseFloat(absenceCount))">
                How many absences have there been since the last {{ reviewNoun }}: {{ absenceCount }}
              </div>
              <div v-else class="ml-6 text-body-1">To be completed</div>
            </div>
          </div>
          <review-comment-box
            v-for="c in comments"
            :key="c.id"
            :comment="c"
            :review-approved="studentReview.approved"
            :can-edit="canEdit(c)"
            :completed="commentCompleted(c)"
            @update:comment-text="c.comment = $event"
          >
          </review-comment-box>

          <div v-if="statementGroups.length > 0">
            <div class="text-h5 flex-grow-1">Statements</div>
            <div
              :class="{
                'grid-container mr-2': !smallScreen,
                'grid-container-mobile': smallScreen,
              }"
            >
              <template v-for="g in statementGroups" :key="'group-col-' + g.id">
                <div class="font-weight-bold py-2">{{ g.name }}</div>
                <div v-if="!smallScreen"></div>
                <template v-for="s in g.statements" :key="'statement-col1-' + s.id">
                  <div>
                    <review-overall-statement
                      :can-edit="!studentReview.approved && canEditStatementJudgement(s)"
                      :completed="!!s.judgementDescriptorId"
                      >{{ s.statement }}</review-overall-statement
                    >
                  </div>
                  <div
                    v-if="!studentReview.approved && canEditStatementJudgement(s)"
                    :key="'statement-col2-' + s.id"
                    class="d-flex"
                    :class="{
                      'justify-end': smallScreen,
                    }"
                  >
                    <mosaic-judgement-select
                      v-model="s.judgementDescriptorId"
                      :readonly="false"
                      :items="statementJudgementDescriptors"
                    />
                  </div>
                  <div
                    v-else
                    :key="'uneditable-statement-col2-' + s.id"
                    class="pa-2"
                    :class="{
                      'text-right': smallScreen,
                    }"
                  >
                    <div v-if="statementJudgementDescriptor(s)" class="d-flex align-center">
                      <v-chip :color="statementJudgementDescriptor(s).color">
                        {{ statementJudgementDescriptor(s).descriptor }}
                      </v-chip>
                    </div>
                    <div v-else class="ml-1 text-body-1">To be completed</div>
                  </div>
                </template>
              </template>
            </div>
          </div>
        </div>
        <v-alert density="compact" class="my-4 mx-4" v-if="error" type="error">{{ error }}</v-alert>
        <div class="pt-2 d-flex">
          <div class="flex-grow-1">
            <mosaic-snackbar v-model="snackbar" color="success" :message="snackbarMessage" />
          </div>
          <v-btn v-if="studentReview.approved" class="ml-2" @click.prevent="nextPage()">{{
            next ? 'Continue' : 'Return'
          }}</v-btn>
          <template v-else>
            <v-btn v-if="showSave" ripple :disabled="saveDisabled" @click.prevent="save()">Save</v-btn>
            <v-btn :disabled="saveDisabled" class="ml-2" @click.prevent="saveAndContinue()"
              >Save and {{ next ? 'Continue' : 'Return' }}</v-btn
            >
          </template>
        </div>
      </v-card-text>
    </v-card>
    <mosaic-mark-as-complete-dialog
      v-model:active="markAsCompleteDialog.active"
      :roles-that-can-complete="myRolesThatCanMarkAsComplete"
      :student-viewer="studentViewer"
      :staff-viewer="staffViewer"
      :next="markAsCompleteDialog.next"
      :student-review-id="studentReview.id"
      :review-path="reviewPath.to"
    ></mosaic-mark-as-complete-dialog>
    <review-targets-list
      v-if="studentReview.review.canAddTargetsOverall"
      :student-review="studentReview"
      :student-review-id="studentReview.id"
      :is-overall-review="true"
      :staff-viewer="staffViewer"
      :student-viewer="studentViewer"
      :student="student"
    />
  </div>
</template>

<script>
import ReviewTargetsList from './ReviewTargetsList.vue';
import MosaicQuill from './quill/MosaicQuill.vue';
import { roleHasPermission, rolesThatCanComplete, canStudentMarkAsComplete } from '@/utils/student-review';
import { mapState } from 'vuex';
import { absencesRules } from '../utils/validations';
import ReviewPartTitle from './ReviewPartTitle.vue';
import ReviewOverallStatement from './ReviewOverallStatement.vue';
import ReviewCommentBox from './ReviewCommentBox.vue';
import MosaicMarkAsCompleteDialog from './library/dialogs/reviews/MosaicMarkAsCompleteDialog.vue';

export default {
  name: 'ReviewOverallCard',
  components: {
    ReviewTargetsList,
    MosaicQuill,
    ReviewPartTitle,
    ReviewOverallStatement,
    ReviewCommentBox,
    MosaicMarkAsCompleteDialog,
  },
  props: {
    studentReview: {
      type: Object,
      required: true,
    },
    staffViewer: {
      type: Object,
      default: null,
    },
    studentViewer: {
      type: Object,
      default: null,
    },
    student: {
      type: Object,
      required: true,
    },
    reviewPath: {
      type: Object,
      required: true,
    },
  },
  emits: ['update:dirty'],
  data: () => ({
    error: '',
    processing: false,
    snackbar: false,
    snackbarMessage: '',
    comments: [],
    statementGroups: [],
    judgementDescriptorId: null,
    absenceCount: null,
    absencesRules,
    markAsCompleteDialog: {
      active: false,
      next: null,
      afterSave: true,
    },
  }),
  computed: {
    ...mapState(['selectedInstitution', 'studentReviewPages', 'roles']),
    myRolesThatCanMarkAsComplete() {
      return this.staffViewer
        ? rolesThatCanComplete(this.roles, this.studentReview, this.staffViewer, this.student)
        : [];
    },
    canMarkAnyAsComplete() {
      return this.staffViewer ? this.myRolesThatCanMarkAsComplete.length : canStudentMarkAsComplete(this.studentReview);
    },
    saveDisabled() {
      return this.processing || this.absencesRules.some(r => !r(this.absenceCount)) || this.studentReview.approved;
    },
    dirty() {
      const commentsDirty = this.studentReview.overallComments.some(x => {
        const comment = this.comments.find(c => c.id === x.id);
        return comment?.comment !== x.comment && comment?.comment !== x.placeholder;
      });

      const statements = this.studentReview.overallStatementGroups.map(x => x.statements).flat();
      const statementsDirty = this.statementGroups.some(x => {
        return x.statements.some(s => {
          const statement = statements.find(x => x.id === s.id);
          // Deliberately != so that null == undefined
          return statement?.judgementDescriptorId != s.judgementDescriptorId;
        });
      });

      // Deliberately != so that null == undefined
      const judgementDirty = this.studentReview.judgementDescriptorId != this.judgementDescriptorId;

      // Deliberately != so can compare strings and numbers
      const absencesDirty =
        this.studentReview.review.recordAbsences && this.studentReview.absenceCount != this.absenceCount;

      return commentsDirty || judgementDirty || statementsDirty || absencesDirty;
    },
    showSave() {
      return (
        this.studentReview.overallComments.some(x => this.canEdit(x)) ||
        this.statementGroups.some(g => g.statements.some(this.canEditStatementJudgement)) ||
        this.canEditJudgement
      );
    },
    reviewPointName() {
      return this.selectedInstitution.config.review_noun;
    },
    statementJudgementDescriptors() {
      return this.studentReview.review.overallStatementJudgementSet.institutionJudgementDescriptors;
    },
    canViewOverallJudgement() {
      if (!this.studentViewer) return true;
      return this.studentReview.review.canTraineesViewOverallJudgement;
    },
    judgementDescriptors() {
      return this.studentReview.review.overallJudgementSet?.institutionJudgementDescriptors;
    },
    judgementDescriptor() {
      if (!this.judgementDescriptorId) return;
      return this.judgementDescriptors.find(x => x.id === this.judgementDescriptorId);
    },
    canEditJudgement() {
      if (this.studentViewer) return false;
      return roleHasPermission(
        this.studentReview.review.roleIdsThatCanEditOverallJudgement,
        this.staffViewer,
        this.student
      );
    },
    canRecordAbsences() {
      if (this.studentViewer) {
        return this.studentReview.review.recordAbsences && this.studentReview.review.canTraineeRecordAbsences;
      }
      return (
        this.studentReview.review.recordAbsences &&
        roleHasPermission(this.studentReview.review.rolesIdsThatCanRecordAbsences, this.staffViewer, this.student)
      );
    },
    showCard() {
      return (
        this.comments.length > 0 ||
        this.statementGroups.length > 0 ||
        (this.studentReview.review.overallJudgementSet && this.canViewOverallJudgement) ||
        this.studentReview.review.recordAbsences
      );
    },
    next() {
      return this.studentReview.reviewStandards.find(x => x.id === 'overall')?.next;
    },
  },
  watch: {
    studentReview() {
      this.mapData();
    },
    staffViewer() {
      this.mapData();
    },
    studentViewer() {
      this.mapData();
    },
    dirty(x) {
      this.$emit('update:dirty', x);
    },
  },
  created() {
    this.mapData();
    if (this.dirty) {
      this.$emit('update:dirty', true);
    }
  },
  methods: {
    commentCompleted(c) {
      return !!(c.comment && c.comment !== c.placeholder);
    },
    backToSummary() {
      this.$router.push(this.reviewPath.to);
    },
    mapData() {
      this.comments = this.studentReview.overallComments.map(x => {
        const comment = this.comments.find(c => c.id === x.id);
        return { ...x, comment: comment?.comment || x.comment || x.placeholder };
      });

      const statements = this.statementGroups.map(x => x.statements).flat();
      this.statementGroups = this.studentReview.overallStatementGroups.filter(this.canViewStatementGroup).map(sg => {
        return {
          ...sg,
          statements: sg.statements.filter(this.canViewStatement).map(s => {
            const statement = statements.find(x => x.id === s.id);
            return { ...s, judgementDescriptorId: statement?.judgementDescriptorId || s.judgementDescriptorId };
          }),
        };
      });

      this.judgementDescriptorId = this.judgementDescriptorId || this.studentReview.judgementDescriptorId;

      this.absenceCount = this.absenceCount || this.studentReview.absenceCount;
    },
    canEdit(comment) {
      if (this.studentViewer) {
        return comment.canTraineeEdit;
      }
      return roleHasPermission(comment.roleIdsThatCanEdit, this.staffViewer, this.student);
    },
    canViewStatementGroup(group) {
      return group.statements.some(this.canViewStatement);
    },
    canViewStatement(statement) {
      if (this.studentViewer) return statement.canTraineeView;
      return roleHasPermission(statement.roleIdsThatCanView, this.staffViewer, this.student);
    },
    canEditStatementJudgement(statement) {
      if (this.studentViewer) return statement.canTraineeEdit;
      return roleHasPermission(statement.roleIdsThatCanEdit, this.staffViewer, this.student);
    },
    statementJudgementDescriptor(statement) {
      if (!statement.judgementDescriptorId) return;
      return this.statementJudgementDescriptors.find(x => x.id === statement.judgementDescriptorId);
    },
    async save() {
      await this.saveToApi(() => {
        if (this.canMarkAnyAsComplete && this.studentReviewPages.showCompletedModal) {
          this.markAsCompleteDialog = {
            active: true,
            next: null,
            afterSave: true,
          };
        } else {
          this.snackbarMessage = `General section saved`;
          this.snackbar = true;
        }
      });
    },
    async saveAndContinue() {
      await this.saveToApi(() => {
        const next = this.next || this.reviewPath.to;
        if (this.canMarkAnyAsComplete && this.studentReviewPages.showCompletedModal) {
          this.markAsCompleteDialog = {
            active: true,
            next: next,
            afterSave: true,
          };
        } else this.nextPage();
      });
    },
    nextPage() {
      if (this.next) this.$router.push(this.next);
      else this.backToSummary();
    },
    async saveToApi(next) {
      this.error = '';

      if (this.canRecordAbsences && this.absenceCount === null) {
        this.error = `Absences needs to be completed before saving`;
        return;
      }

      this.processing = true;
      try {
        const comments = this.comments.filter(this.canEdit).map(x => ({
          id: x.id,
          comment: x.comment === x.placeholder ? null : x.comment,
        }));
        const statements = this.statementGroups
          .map(x => x.statements)
          .flat()
          .filter(this.canEdit)
          .map(x => ({
            id: x.id,
            institution_judgement_descriptor_id: x.judgementDescriptorId,
          }));
        const body = {
          comments,
          statements,
        };

        if (this.canEditJudgement && this.studentReview.review.overallJudgementSet) {
          body.institution_judgement_descriptor_id = this.judgementDescriptorId;
        }

        if (this.canRecordAbsences) {
          body.absence_count = parseFloat(this.absenceCount);
        }

        await this.$api.put(`/student-reviews/${this.studentReview.id}/overall`, body);
        await this.$store.commit('updateStudentReviewOverall', body);
        next();
      } catch (e) {
        console.log(e);
        this.error = `Sorry, cannot save your ${this.reviewPointName} at the moment`;
      }
      this.processing = false;
    },
  },
};
</script>

<style scoped>
.grid-container {
  display: grid;
  grid-template-columns: 1fr auto;
  column-gap: 12px;
  row-gap: 8px;
}
.grid-container-mobile {
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 12px;
  row-gap: 8px;
}
.guidance-box {
  border: 2px solid rgb(var(--v-theme-primary));
  border-radius: 10px;
  width: 95%;
}
</style>

<style>
.text-center input {
  text-align: center;
}
</style>
