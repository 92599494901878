import { computed } from 'vue';
import { useStore } from 'vuex';

const mapState = () => {
  const store = useStore();
  return Object.fromEntries(Object.keys(store.state).map(key => [key, computed(() => store.state[key])]));
};
const mapGetters = () => {
  const store = useStore();
  return Object.fromEntries(Object.keys(store.getters).map(getter => [getter, computed(() => store.getters[getter])]));
};
const mapMutations = () => {
  const store = useStore();
  return Object.fromEntries(
    Object.keys(store._mutations).map(mutation => [mutation, value => store.commit(mutation, value)])
  );
};
const mapActions = () => {
  const store = useStore();
  return Object.fromEntries(
    Object.keys(store._actions).map(action => [action, value => store.dispatch(action, value)])
  );
};

export { mapState, mapGetters, mapMutations, mapActions };

// These are used for the options API and are therefore not included in map-store.d.ts because we only use
// typescript with the composition API, which should use the version in map-state-error-and-processing.ts
export function mapStateError(keys) {
  const individualErrors = {};
  keys.forEach(k => {
    individualErrors[`${k}Error`] = function () {
      return this.$store.getters.stateError(k);
    };
  });
  return {
    ...individualErrors,
    stateError() {
      return this.$store.getters.stateErrors(keys);
    },
  };
}
export function mapStateProcessing(keys) {
  const individualProcessing = {};
  keys.forEach(k => {
    individualProcessing[`${k}Processing`] = function () {
      return this.$store.getters.stateProcessing(k);
    };
  });
  return {
    ...individualProcessing,
    stateProcessing() {
      return this.$store.getters.stateProcessings(keys);
    },
  };
}

export function mapStateProcessingAndError(keys) {
  return {
    ...mapStateProcessing(keys),
    ...mapStateError(keys),
  };
}
