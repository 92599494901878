<template>
  <div>
    <mosaic-list
      :items="paginatedList"
      :empty-text="`There are no ${type} ${traineeNounCapitalised()} Announcements. Add one by clicking the button above.`"
      class="py-0"
      two-line
    >
      <template #item="{ item }">
        <mosaic-list-item
          :icon="icons.announcement"
          :title="item.title"
          :subtitle="subtitle(item)"
          :to="clickAnnouncementTo(item)"
        >
          <template #information>
            <mosaic-count-chip
              :count="item.adminAnnouncementStudents.length"
              :tooltip="
                countHasBeenAssignedTooltip('Announcement', item.adminAnnouncementStudents.length, 'Instructor')
              "
            />
          </template>

          <template #actions>
            <mosaic-delete-icon-btn object-type="Instructor Announcement" @click.prevent="deleteAnnouncement(item)" />
          </template>
        </mosaic-list-item>
      </template>
    </mosaic-list>
    <mosaic-pagination v-model="currentPage" v-model:page-size="pageSize" :total="paginationTotal" />

    <mosaic-delete-dialog
      v-model:active="deleteDialog.active"
      :url="deleteDialog.url"
      object-type="Instructor Announcement"
      :object-name="deleteDialog.name"
      :deleting-student-data="deleteDialog.deletingStudentData"
      @delete="emit('update:triggerBackgroundLoad', true)"
    />
  </div>
</template>

<script setup lang="ts">
import { countHasBeenAssignedTooltip } from '@/components/library/display/mosaic-count-chip';
import { paginateList } from '@/components/library/pagination/pagination';
import { mapGetters } from '@/store/map-store';
import { icons } from '@/utils/icons';
import { computed, ref } from 'vue';
import type { RouteLocationNamedRaw } from 'vue-router';

export interface AdminAnnouncement {
  id: number;
  title: string;
  scheduledAtDate: string | null;
  setDate: string | null;
  adminAnnouncementStudents: { id: number }[];
}

const props = defineProps<{
  announcements: AdminAnnouncement[];
  type: 'live' | 'scheduled';
  subtitle: (target: AdminAnnouncement) => string;
  clickAnnouncementTo: (target: AdminAnnouncement) => RouteLocationNamedRaw;
}>();

const emit = defineEmits<{
  (e: 'update:triggerBackgroundLoad', x: boolean): void;
}>();

const { traineeNounCapitalised } = mapGetters();

const { paginatedList, currentPage, pageSize, paginationTotal } = paginateList(computed(() => props.announcements));

const deleteDialog = ref({
  active: false,
  url: '',
  name: '',
  deletingStudentData: false,
});

async function deleteAnnouncement(announcement: AdminAnnouncement) {
  deleteDialog.value = {
    active: true,
    url: `/admin-announcements/${announcement.id}`,
    name: announcement.title,
    deletingStudentData: false,
  };
}
</script>
