<template>
  <v-row>
    <v-col v-if="selectedInstitution.config.home_page_rubric" cols="12">
      <v-card>
        <v-card-text>
          <div class="text-h6 d-flex align-center">Welcome to Mosaic</div>
          <mosaic-quill :read-only="true" :contents="selectedInstitution.config.home_page_rubric"></mosaic-quill>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col v-if="busy" cols="12">
      <mosaic-loading-card type="student-home-page" />
    </v-col>
    <v-col v-else-if="loadError" cols="12">
      <mosaic-load-error-card object-type="home page" @retry="loadData" />
    </v-col>
    <template v-else>
      <v-col v-for="(c, i) in cards" :key="c.title" :cols="i < cards.length - 1 ? cols : lastCardCols">
        <home-page-simple-list-card v-bind="c" />
      </v-col>

      <mosaic-dialog v-model:active="eventDialog.active" :title="eventDialog.title" close-button-text="Close">
        <template>
          <h4>When</h4>
          <div class="pl-2">{{ eventDialog.start }}</div>
          <div v-if="eventDialog.subtitle" class="mt-4">
            <h4>Details</h4>
            <mosaic-quill :contents="eventDialog.subtitle" :read-only="true" />
          </div>
        </template>
      </mosaic-dialog>
    </template>
  </v-row>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import MosaicQuill from '@/components/quill/MosaicQuill.vue';
import moment from 'moment';
import HomePageSimpleListCard from '@/components/home-pages/HomePageSimpleListCard.vue';
import { icons } from '@/utils/icons';
import { mapAssignmentStatusToChip } from '@/components/library/has-status/has-status';
import { createAnnouncementStatusChip } from '@/pages/announcements/announcements';

export default {
  name: 'StudentHomePage',
  components: { MosaicQuill, HomePageSimpleListCard },
  data: () => ({
    busy: true,
    loadError: null,
    targets: [],
    courseTargets: [],
    reviews: [],
    studentAssignments: [],
    courseReflections: [],
    announcements: [],
    events: [],
    calendarEvents: [],
    eventDialog: {
      active: false,
      title: '',
      subtitle: '',
    },
    cohortCourseWeeks: [],
  }),
  async created() {
    await this.loadData();
  },
  computed: {
    ...mapState(['selectedInstitution', 'userStudent']),
    ...mapGetters(['reviewNounPluralised', 'reviewNounCapitalised']),
    cols() {
      return this.smallScreen ? 12 : 6;
    },
    cards() {
      const cards = [];

      if (this.announcements.length > 0) {
        cards.push({
          title: 'Unread Announcements',
          titleLink: {
            text: `See all (${this.unreadAnnouncementsCount} unread)`,
            to: { name: 'StudentAnnouncementsPage' },
          },
          itemGroups: [
            {
              subtitle: 'Announcements from your course leads',
              icon: icons.announcement,
              emptyText: 'You have no unread announcements',
              items: this.activeAnnouncements.map(a => ({
                key: a.id,
                to: { name: 'StudentAnnouncementPage', params: { id: a.id } },
                title: a.title,
                subtitle: `${this.formatDateFromNow(a.created_at)} - from ${a.createdBy?.name || 'your course leads'}`,
                chip: createAnnouncementStatusChip(this.traineeNounCapitalised(), a.has_read),
              })),
            },
          ],
        });
      }

      if (this.showCoursePanel) {
        const itemGroups = [];

        if (this.currentCourseWeeks.length > 0) {
          itemGroups.push({
            subtitle: `Current ${
              this.currentCourseWeeks.length > 1
                ? this.cohortCourseWeekNounCapitalisedAndPluralised
                : this.cohortCourseWeekNounCapitalised
            }`,
            weeks: this.currentCourseWeeks,
          });
        }

        if (this.pastCohortCourseWeeks.length > 0) {
          itemGroups.push({
            subtitle: `Training Plan ${this.cohortCourseWeekNounCapitalisedAndPluralised} you've not completed`,
            weeks: this.pastCohortCourseWeeks,
          });
        }

        cards.push({
          title: 'Training Plan',
          titleLink: {
            text: `See all`,
            to: { name: 'StudentCohortCoursePage' },
          },
          itemGroups: itemGroups.map(g => ({
            subtitle: g.subtitle,
            icon: icons.trainingPlanWeek,
            items: g.weeks.map(week => ({
              key: week.id,
              to: { name: 'StudentCohortCourseWeekPage', params: { studentWeekId: week.id } },
              title: week.cohortCourseWeek.name,
              subtitle: `${this.formatDate(week.cohortCourseWeek.startDate)} - ${this.formatDate(
                week.cohortCourseWeek.endDate
              )}`,
              chip: !week.completedAt
                ? {
                    text: 'Active',
                    color: 'accent',
                  }
                : {
                    text: 'Completed',
                    color: 'primary',
                  },
            })),
          })),
        });
      }

      if (this.activeReviews.length > 0) {
        cards.push({
          title: `Incomplete ${this.reviewNounCapitalisedAndPluralised}`,
          itemGroups: [
            {
              subtitle: `Your incomplete ${this.reviewNounCapitalisedAndPluralised}`,
              icon: icons.reviewPoint,
              items: this.activeReviews.map(review => ({
                key: review.id,
                to: { name: review.page, params: { id: review.id } },
                title: review.name,
                subtitle: this.formatDueDate(review.due_date),
                subtitleClass: this.isOverdue(review.due_date) ? 'text-red' : '',
                chip: !review.student_submitted_at
                  ? {
                      text: 'Active',
                      color: 'accent',
                    }
                  : {
                      text: 'Completed',
                      color: 'primary',
                    },
              })),
            },
          ],
        });
      }

      if (this.incompleteStudentAssignments.length > 0 && this.userStudent.cohort.show_assignments) {
        cards.push({
          title: `Incomplete Course Activities`,
          itemGroups: [
            {
              subtitle: `Your incomplete Course Activities`,
              icon: icons.courseActivity,
              items: this.incompleteStudentAssignments.map(studentAssignment => ({
                key: studentAssignment.id,
                to: { name: 'StudentAssignmentLatestVersionPage', params: { id: studentAssignment.assignment.id } },
                title: studentAssignment.assignment.name,
                subtitle: studentAssignment.assignment.dueDate
                  ? this.formatDueDate(studentAssignment.assignment.dueDate)
                  : undefined,
                subtitleClass: this.isOverdue(studentAssignment.assignment.dueDate) ? 'text-red' : '',
                chip: mapAssignmentStatusToChip(studentAssignment.status),
              })),
            },
          ],
        });
      }

      if (this.courseTargets.length > 0) {
        cards.push({
          title: `Course Targets`,
          itemGroups: [
            {
              subtitle: `Targets set by your course leads`,
              icon: icons.target,
              items: this.courseTargets.map(target => ({
                key: target.id,
                to: { name: 'TargetPage', params: { studentId: this.userStudent.id, targetId: target.id } },
                title: target.goal,
              })),
            },
          ],
        });
      }

      if (this.courseReflections.length > 0) {
        cards.push({
          title: `Course Reflections`,
          itemGroups: [
            {
              subtitle: `Reflections prompted by your course leads`,
              icon: icons.reflection,
              items: this.courseReflections.map(reflection => ({
                key: reflection.id,
                to: { name: 'ReflectionPage', params: { studentId: this.userStudent.id, reflectionId: reflection.id } },
                title: reflection.title,
              })),
            },
          ],
        });
      }

      if (this.selectedInstitution.config.show_calendar_for_students) {
        cards.push({
          title: `Events this week`,
          titleLink: {
            text: 'See all',
            to: { name: 'CalendarPage', params: { studentId: this.userStudent.id } },
          },
          itemGroups: [
            {
              icon: icons.calendar,
              emptyText: 'You have no events this week',
              items: this.calendarEvents.map(event => ({
                key: event.id,
                title: event.name,
                subtitle: this.subtitleDate(event.start),
                click: () => this.showEventTitle(event),
              })),
            },
          ],
        });
      }

      cards.push({
        title: `Active Targets`,
        titleLink: {
          text: 'See all',
          to: { name: 'TargetsListPage', params: { studentId: this.userStudent.id } },
        },
        titleButton: !this.selectedInstitution.config.early_careers
          ? {
              icon: 'plus',
              text: 'Target',
              to: { name: 'TargetCreatePage', params: { studentId: this.userStudent.id } },
            }
          : undefined,
        itemGroups: [
          {
            subtitle: `Your active developmental Targets`,
            icon: icons.target,
            emptyText: 'You have no active Targets',
            items: this.targets.map(target => ({
              key: target.id,
              to: { name: 'TargetPage', params: { studentId: this.userStudent.id, targetId: target.id } },
              title: target.goal,
            })),
          },
        ],
      });

      return cards;
    },
    activeAnnouncements() {
      // Debug logging for https://penrose-education.sentry.io/issues/5248673106
      if (!this.announcements.filter) {
        console.log(
          'this.announcements.filter falsey',
          this.announcements.filter,
          typeof this.announcements,
          this.announcements
        );
      }
      return this.announcements.filter(x => !x.has_read).slice(0, 3);
    },
    unreadAnnouncementsCount() {
      // Debug logging for https://penrose-education.sentry.io/issues/5248673106
      if (!this.announcements.filter) {
        console.log(
          'this.announcements.filter falsey',
          this.announcements.filter,
          typeof this.announcements,
          this.announcements
        );
      }
      return this.announcements.filter(x => !x.has_read).length;
    },
    incompleteStudentAssignments() {
      return this.studentAssignments
        .filter(sa => ['not_started', 'started'].includes(sa.status))
        .map(sa => ({ ...sa, statusOrder: sa.status === 'started' ? 1 : 0 }))
        .sortBy('assignment.dueDate', 'statusOrder');
    },
    activeNewReviews() {
      return this.reviews.map(x => ({ ...x, page: 'StudentReviewPage' }));
    },
    activeReviews() {
      if (!this.selectedInstitution.config.show_reviews) return [];
      return [...this.activeNewReviews].sort((a, b) => new Date(a.due_date) - new Date(b.due_date));
    },
    currentCourseWeeks() {
      if (!this.cohortCourseWeeks || this.cohortCourseWeeks.length === 0) return [];
      // Debug logging for https://penrose-education.sentry.io/issues/5247904188
      if (!this.cohortCourseWeeks.filter) {
        console.log(
          'this.cohortCourseWeeks.filter falsey',
          this.cohortCourseWeeks.filter,
          typeof this.cohortCourseWeeks,
          this.cohortCourseWeeks
        );
      }
      return this.cohortCourseWeeks
        .filter(week => this.todayFallsWithin(week.cohortCourseWeek.startDate, week.cohortCourseWeek.endDate))
        .sort((a, b) => (a.cohortCourseWeek.startDate < b.cohortCourseWeek.startDate ? 1 : -1));
    },
    earliestCurrentCourseWeek() {
      if (this.currentCourseWeeks.length === 0) return null;
      return this.currentCourseWeeks[0];
    },
    pastCohortCourseWeeks() {
      if (this.cohortCourseWeeks.length === 0) return [];
      return this.cohortCourseWeeks
        .filter(
          x =>
            !x.completedAt &&
            !this.currentCourseWeeks.some(y => y.id == x.id) &&
            moment(x.cohortCourseWeek.endDate).isBefore(this.earliestCurrentCourseWeek?.startDate || moment())
        )
        .sort((a, b) => (a.cohortCourseWeek.startDate < b.cohortCourseWeek.startDate ? 1 : -1));
    },
    showCoursePanel() {
      return this.cohortCourseWeeks && (this.currentCourseWeeks.length > 0 || this.pastCohortCourseWeeks.length > 0);
    },
    lastCardCols() {
      if (this.smallScreen) return 12;
      let panelCount = 0;
      if (this.courseTargets.length > 0) panelCount += 1;
      if (this.courseReflections.length > 0) panelCount += 1;
      if (this.activeReviews.length > 0) panelCount += 1;
      if (this.incompleteStudentAssignments.length > 0) panelCount += 1;
      if (this.announcements.length > 0) panelCount += 1;
      if (this.showCoursePanel) panelCount += 1;
      if (this.selectedInstitution.config.show_calendar_for_students) panelCount += 1;

      return panelCount % 2 === 0 ? 12 : 6;
    },
  },
  methods: {
    async loadData() {
      this.busy = true;
      this.loadError = false;
      const api = this.$api;
      try {
        const [
          targetsResponse,
          courseTargetResponse,
          reviewsResponse,
          reflectionsResponse,
          announcementsResponse,
          calendarEventsResponse,
          studentAssignmentsResponse,
        ] = await Promise.all([
          api.get(`/students/${this.userStudent.id}/targets-home`),
          api.get(`/students/${this.userStudent.id}/targets-home/course`),
          api.get(`/students/${this.userStudent.id}/student-reviews-home`),
          api.get(`/students/${this.userStudent.id}/reflections-home/course`),
          api.get(`/students/${this.userStudent.id}/announcements`),
          api.get(`/students/${this.userStudent.id}/calendar/week`),
          api.get(`/students/${this.userStudent.id}/student-assignments`),
          this.loadCohortCourseWeeks(),
        ]);
        this.targets = targetsResponse.data;
        this.courseTargets = courseTargetResponse.data;
        this.reviews = reviewsResponse.data;
        this.courseReflections = reflectionsResponse.data;
        this.announcements = announcementsResponse.data;
        this.calendarEvents = calendarEventsResponse.data;
        this.studentAssignments = studentAssignmentsResponse.data;
      } catch (e) {
        this.loadError = true;
      }
      this.busy = false;
    },
    async loadCohortCourseWeeks() {
      if (this.userStudent.cohort.has_course) {
        const r = await this.$api.get(`/students/${this.userStudent.id}/student-cohort-course-weeks-home`);
        this.cohortCourseWeeks = r.data;
      }
    },
    showEventTitle(x) {
      this.eventDialog = {
        ...this.eventDialog,
        active: true,
        title: x.name,
        start: moment(x.start).calendar(),
        end: x.end,
        subtitle: x.description,
      };
    },
    formatDueDate(dueDate) {
      return moment(dueDate).format('DD/MM/YYYY');
    },
    subtitleDate(dueDate) {
      return moment(dueDate).calendar();
    },
  },
};
</script>
