<template>
  <login-page-layout>
    <template #loginContent>
      <v-card-text>
        <v-card-title class="text-primary mb-2 pl-0 font-weight-regular">Login to Mosaic</v-card-title>
        <mosaic-alert density="compact" v-if="warningMessage" type="warning">
          {{ warningMessage }}
        </mosaic-alert>
        <mosaic-error-alert density="compact" :override-error-message="errorMessage" />
        <v-text-field
          class="mt-3"
          v-model="email"
          prepend-icon="mdi-account"
          name="email"
          label="Email"
          type="text"
          @keyup.enter="login"
        />
        <div class="d-flex">
          <mosaic-password-text-field
            v-model="password"
            class="flex-grow-1"
            @keyup="detectCapsLock"
            @keyup.enter="login"
          />
          <v-tooltip v-if="capsLockOn" location="right">
            <template #activator="{ props }">
              <v-icon color="warning" class="ml-2" v-bind="props">mdi-alert-circle-outline</v-icon>
            </template>
            <span>Caps Lock is on</span>
          </v-tooltip>
        </div>
      </v-card-text>
      <v-card-actions>
        <mosaic-router-link to="forgot-password" class="pl-4 text-body-1 text-no-wrap text-secondary"
          >Forgot your password?</mosaic-router-link
        >
        <v-spacer />
        <v-btn :disabled="!canSubmit" class="px-4 mx-2" @click.prevent="login">Login</v-btn>
      </v-card-actions>
      <div class="mt-2 mx-4">
        <div class="d-flex">
          <v-divider class="mt-3" />
          <div class="px-4">or</div>
          <v-divider class="mt-3" />
        </div>
        <div class="d-flex justify-center align-center py-4">
          <div style="width: 24px" />
          <router-link :to="{ name: 'SSOMicrosoft' }">
            <img src="@/assets/ms_signin.svg" class="mx-2" />
          </router-link>
          <mosaic-help>
            You can "Sign in with Microsoft" if your Microsoft account email matches your Mosaic account email
          </mosaic-help>
        </div>
      </div>
    </template>
  </login-page-layout>
</template>

<script>
import { login, routeToNextStep } from '@/utils/auth';
import { isPasswordStrongEnough } from '@/utils/passwords';
import { rateLimitErrorMessage } from '@/utils/errors';
import { mapState } from 'vuex';
import { startMicrosoftSsoFlow } from '@/utils/external-auth';
import LoginPageLayout from '@/components/layout-container/LoginPageLayout.vue';

export default {
  name: 'LoginPage',
  components: { LoginPageLayout },
  data: function () {
    return {
      email: '',
      password: '',
      errorMessage: '',
      warningMessage: '',
      busy: false,
      capsLockOn: false,
    };
  },
  computed: {
    ...mapState(['user']),
    canSubmit() {
      return !this.busy && this.email && this.password;
    },
  },
  created: function () {
    const email = localStorage.getItem('lastLoginEmail');
    if (email) {
      this.email = email;
    }
    this.warningMessage = this.$route.query.error || '';

    if (this.$route.query.error_code) {
      this.processErrorCode(this.$route.query.error_code);
      this.email = '';
    }
  },
  methods: {
    detectCapsLock(event) {
      if (event.getModifierState) {
        this.capsLockOn = event.getModifierState('CapsLock');
      }
    },
    async login() {
      if (!this.canSubmit) return;
      this.busy = true;
      this.errorMessage = '';
      const email = this.email.trim();
      try {
        const response = await login(email, this.password, this.$store);
        localStorage.setItem('lastLoginEmail', email);
        await this.$store.dispatch('refreshUser');

        let nextStep = response.data.next_step;

        const passwordStrong = await isPasswordStrongEnough(this.$api, this.user, this.password);
        if (nextStep !== 'UserSetupPage' && nextStep !== 'ForceChangePasswordPage' && !passwordStrong) {
          nextStep = 'UserInsecurePasswordPage';
        }

        // This is set in case the next_step down the line is one of UserSetupPage, ForceChangePasswordPage, UserInsecurePasswordPage
        // It is cleared by routeToNextStep
        sessionStorage.setItem('password', this.password);

        await routeToNextStep(nextStep, this.$router, this.$route);
      } catch (e) {
        this.busy = false;
        if (e.response?.status === 429) {
          this.errorMessage = rateLimitErrorMessage(e.response.data.wait_time_in_seconds, 'login');
        } else if (e.response?.data?.error_code) {
          this.processErrorCode(e.response.data.error_code);
        } else if (e.response?.data?.message === 'Invalid credentials') {
          this.errorMessage = 'Sorry, either your email or password is incorrect';
        } else {
          console.log(e);
          this.errorMessage = 'Sorry, cannot connect to the server. Please try again later.';
          if (!e.response) {
            throw e;
          }
        }
      }
    },
    processErrorCode(errorCode) {
      if (errorCode.startsWith('user_not_active')) {
        let institution = 'Training Provider or Appropriate Body';
        if (errorCode.endsWith('itt')) {
          institution = 'Training Provider';
        } else if (errorCode.endsWith('early_careers')) {
          institution = 'Appropriate Body';
        }
        this.errorMessage = `Your account is not active, please get in touch with your ${institution} if you think this is an error`;
      } else if (errorCode.startsWith('account_locked')) {
        this.errorMessage = 'Sorry, this account has been locked. Please reset your password to unlock your account.';
      } else if (errorCode === 'no_password') {
        startMicrosoftSsoFlow();
      }
    },
  },
};
</script>

<style scoped>
.signup-message {
  padding: 8px 0;
  text-align: center;
}
</style>
