<template>
  <mosaic-loading-and-error-cards object-type="Mentor Meetings" :load="loadMentorMeetings" loading-type="table">
    <div v-if="monitoringRows.length" class="d-flex align-center mb-6">
      <mosaic-cohort-monitoring-filters
        :students="selectedCohortStudents"
        @update:filtered-student-ids="filteredStudentIds = $event"
      />
      <monitoring-placement-select
        v-if="hasPlacements"
        v-model:selected-placement-id="selectedPlacementId"
        class="ml-4"
      />
    </div>
    <mosaic-cohort-weekly-monitoring-filter-table
      v-if="selectedPlacement"
      :placement-id="selectedPlacementId"
      object-type-pluralised="Mentor Meetings"
      :data-text-transform="dataTextTransform"
      :rows="monitoringRows"
      :headers="selectedPlacement.headers"
      :name-click-route="nameClickRoute"
      list-page-route-name="MentorMeetingsListPage"
    />

    <div v-else class="py-4">
      This Cohort does not have any Monitoring Windows configured. These can be added/updated in Cohort Settings.
    </div>
  </mosaic-loading-and-error-cards>
</template>

<script setup lang="ts">
import { useCohortStore } from '@/stores/cohort';
import { setBreadcrumbs } from '@/utils/breadcrumbs';
import { ref } from 'vue';
import type { Row } from '@/components/monitoring/mosaic-table';
import MosaicCohortWeeklyMonitoringFilterTable from '@/components/monitoring/MosaicCohortWeeklyMonitoringFilterTable.vue';
import type { BaseStudentRow, MonitoringPlacement, WeeklyMonitoringHeader } from '../tutor/utils/monitoring';
import { useWeeklyMonitoring } from '../tutor/utils/monitoring';
import MonitoringPlacementSelect from '@/components/monitoring/MonitoringPlacementSelect.vue';
import { useApi } from '@/composables/api';

const api = useApi();

//Page Setup
setBreadcrumbs([
  {
    text: 'Mentor Meetings',
  },
]);

type ObservationCount = Record<string, number>;
type StudentRow = BaseStudentRow & ObservationCount;

// Load Mentor Meetings

type MonitoringApiResponse = MonitoringPlacement<StudentRow>[];

const { hasPlacements, selectedCohort, selectedCohortStudents } = useCohortStore();
const placements = ref<MonitoringPlacement<StudentRow>[]>([]);

async function loadMentorMeetings() {
  const response = await api.get<MonitoringApiResponse>(
    `/cohorts/${selectedCohort.value.id}/mentor-meeting-monitoring`
  );
  placements.value = response.data;
}

// Table construction
const selectedPlacementId = ref<number>(-1);
const filteredStudentIds = ref<number[]>(selectedCohortStudents.value.map(s => s.id));

const { selectedPlacement, monitoringRows } = useWeeklyMonitoring<StudentRow>(
  placements,
  selectedPlacementId,
  filteredStudentIds
);

//Data Transforms

type Student = {
  id: number;
  name: string;
};

const dataTextTransform = (row: Row, h: WeeklyMonitoringHeader) => {
  const studentRow = row as StudentRow;
  return studentRow[h.key];
};

function nameClickRoute(row: Row) {
  return {
    name: 'MentorMeetingsListPage',
    params: {
      studentId: (row as Student).id.toString(),
    },
  };
}
</script>
