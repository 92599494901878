import { useCurriculumStore } from '@/stores/curriculum';
import { setBreadcrumbs } from '@/utils/breadcrumbs';
import type { ContentLayout } from '@/utils/content-layout';
import { computed, ref } from 'vue';
import type { Api } from '@/composables/api';

interface CurriculumTeachingStrategy {
  id: number;
  name: string;
  order: number;
  status: 'published' | 'draft';
  strategyType: 'content_layout' | 'external_link';
  contentLayout: ContentLayout;
  externalLink: string;
  curriculumStatement: {
    id: number;
    code: string;
  };
}

export function setupCurriculumTeachingStrategyPage(url: string, isStudent: boolean, api: Api) {
  const teachingStrategy = ref<CurriculumTeachingStrategy>();
  async function load() {
    const r = await api.get<CurriculumTeachingStrategy>(url);
    teachingStrategy.value = r.data;
  }

  const { curriculumNoun } = useCurriculumStore();

  setBreadcrumbs(
    computed(() => [
      {
        text: curriculumNoun.value,
        to: {
          name: isStudent ? 'StudentCurriculumPage' : 'TutorCurriculumPage',
        },
      },
      {
        text: teachingStrategy.value?.curriculumStatement.code || '',
        to: {
          name: isStudent ? 'StudentCurriculumStatementPage' : 'TutorCurriculumStatementPage',
          params: {
            id: teachingStrategy.value?.curriculumStatement.id.toString() || '-1',
          },
          query: {
            tab: 'teaching-strategies',
          },
        },
      },
      {
        text: teachingStrategy.value?.name || '',
      },
    ])
  );

  return { load, teachingStrategy };
}
