import { formatDate } from '@/mixins/global-mixins';

export const yearGroupItems = [
  'Babies',
  'Toddlers',
  'Pre-School',
  'Reception',
  'Year 1',
  'Year 2',
  'Year 3',
  'Year 4',
  'Year 5',
  'Year 6',
  'Year 7',
  'Year 8',
  'Year 9',
  'Year 10',
  'Year 11',
  'Year 12',
  'Year 13',
  'Post 16',
  'Other',
];

export const ectRegistrationSubjects = [
  'Primary (General)',
  'Primary (EYFS)',
  'Primary (SEND)',
  'Secondary/P16 - Art',
  'Secondary/P16 - Business Studies',
  'Secondary/P16 - Citizenship',
  'Secondary/P16 - Computing',
  'Secondary/P16 - Dance',
  'Secondary/P16 - Design & Technology',
  'Secondary/P16 - Drama',
  'Secondary/P16 - Economics',
  'Secondary/P16 -  English',
  'Secondary/P16 - Geography',
  'Secondary/P16 - History',
  'Secondary/P16 - Mathematics',
  'Secondary/P16 - Media Studies',
  'Secondary/P16 - MFL',
  'Secondary/P16 - Music',
  'Secondary/P16 - PE',
  'Secondary/P16 - Philosophy',
  'Secondary/P16 - Politics',
  'Secondary/P16 - Psychology',
  'Secondary/P16 - RE',
  'Secondary/P16 - Science',
  'Secondary/P16 - Sociology',
  'Secondary/P16 - SEND',
  'SEND Specialist Provision',
];

interface EctStatusItem {
  title: string;
  value: string;
}
export const ectStatusItems: EctStatusItem[] = [
  { title: 'All', value: 'all' },
  { title: 'Rejected', value: 'rejected' },
  { title: 'Awaiting approval', value: 'awaiting_approval' },
  { title: 'Awaiting QTS check', value: 'awaiting_qts' },
  { title: `ECT not found`, value: 'teacher_not_found' },
  { title: `Does not have QTS`, value: 'does_not_have_qts' },
  { title: 'Approved', value: 'active' },
];

export const qualifiedAgeRangesItems = ['Early Years', 'Primary 3-7', 'Primary 5-11', 'Secondary', 'Post-16'];

export const contractTypeItems = ['Permanent', 'Temporary/Fixed-Term', 'Supply'];
export type ContractType = 'Permanent' | 'Temporary/Fixed-Term' | 'Supply';

export const qualificationsAwardedItems = ['QTS', 'PGCE', 'PGDE'];

export const ecfProgrammeItems = [
  'Full Induction Programme',
  'Core Induction Programme',
  'School-based Induction Programme',
] as const;

type EcfProgrammeTuple = typeof ecfProgrammeItems;
export type EcfProgramme = EcfProgrammeTuple[number];

export const ecfProviderItems = [
  'Ambition Institute',
  'Best Practice Network',
  'Capita - University of Birmingham',
  'Education Development Trust',
  'Teach First',
  'UCL',
  'National Institute of Teaching',
];
export const brightFuturesEcfProviderItems = [
  'Ambition Institute',
  'Best Practice Network',
  'Capita - University of Birmingham',
  'Education Development Trust',
  'Teach First Direct (Not Bright Futures)',
  'UCL',
  'National Institute of Teaching',
];

export const partTimeOptions = [
  {
    rate: 0.9,
    descriptor: '0.9 - (4.5 days a week)',
  },
  {
    rate: 0.8,
    descriptor: '0.8 - (4 days a week)',
  },
  {
    rate: 0.7,
    descriptor: '0.7 - (3.5 days a week)',
  },
  {
    rate: 0.6,
    descriptor: '0.6 - (3 days a week)',
  },
  {
    rate: 0.5,
    descriptor: '0.5 - (2.5 days a week)',
  },
  {
    rate: 0.4,
    descriptor: '0.4 - (2 days a week)',
  },
  {
    rate: 0.3,
    descriptor: '0.3 - (1.5 days a week)',
  },
  {
    rate: 0.2,
    descriptor: '0.2 - (1 days a week)',
  },
  {
    rate: 0.1,
    descriptor: '0.1 - (0.5 days a week)',
  },
];

export const ecfTermsCompletedItems = [
  {
    termsCompleted: 0,
    text: 'This ECT is new to the Early Career Framework',
  },
  {
    termsCompleted: 1,
    text: 'This ECT has completed 1-term of the ECF programme previously',
  },
  {
    termsCompleted: 2,
    text: 'This ECT has completed 2-terms of the ECF programme previously',
  },
  {
    termsCompleted: 3,
    text: 'This ECT has completed 3-terms of the ECF programme previously',
  },
  {
    termsCompleted: 4,
    text: 'This ECT has completed 4-terms of the ECF programme previously',
  },
  {
    termsCompleted: 5,
    text: 'This ECT has completed 5-terms of the ECF programme previously',
  },
  {
    termsCompleted: 6,
    text: 'This ECT needs a bespoke ECF programme',
  },
] as const;

const ecfTermsCompletedLabels = ecfTermsCompletedItems.map(x => x.text);
type EcfTermsCompletedTuple = typeof ecfTermsCompletedLabels;
export type EcfTermsCompletedLabel = EcfTermsCompletedTuple[number];

interface Ect {
  progress?: {
    year_2_end: EctProgressEntry;
  };
  ect_progress_events: EctProgressEvent[];
}
interface EctProgressEntry {
  date: string;
  percent: number;
}
interface EctProgressEvent {
  id: number;
  event_date: string;
  event_type: EctProgressEventType;
}

interface EctStartedEvent extends EctProgressEvent {
  event_type: 'started';
  school_id: number;
}

type EctProgressEventType =
  | 'started'
  | 'fte_rate_changed'
  | 'paused'
  | 'resumed'
  | 'note'
  | 'absences'
  | 'leaving'
  | 'returned'
  | 'extension'
  | 'joined_mosaic'
  | 'induction_finished_early'
  | 'review'
  | 'projected_review';

const isStartedEvent = (e: EctProgressEvent): e is EctStartedEvent => {
  return e.event_type === 'started';
};

function printEventType(type: EctProgressEventType): string {
  return type
    .split('_')
    .map(x => (x === 'fte' ? 'FTE' : x[0].toLocaleUpperCase() + x.slice(1)))
    .join(' ');
}

type Error = string;
// Returns an error message if the supplied event (given the ect) is problematic
type EctProgressEventRule = (event: EctProgressEvent, ect: Ect) => false | Error;
const leaveEventAfterEndOfYear2: EctProgressEventRule = (e, ect) => {
  if (!ect.progress || e.event_type !== 'leaving') return false;
  return e.event_date >= ect.progress.year_2_end.date
    ? `${printEventType('leaving')} event should be before the End of Year 2 (${formatDate(
        ect.progress.year_2_end.date
      )})`
    : false;
};
const disallowedEventsWhileLeft: EctProgressEventRule = (e, ect) => {
  const allowedTypes: EctProgressEventType[] = [
    'note',
    'leaving',
    'review',
    'projected_review',
    'absences',
    'returned',
  ];
  if (allowedTypes.includes(e.event_type)) return false;

  const timePeriodsBetweenLeaveAndReturn = [];
  let leaveDate = null;
  for (const event of ect.ect_progress_events) {
    if (leaveDate == null && event.event_type === 'leaving') {
      leaveDate = event.event_date;
    }

    if (leaveDate && event.event_type === 'returned') {
      timePeriodsBetweenLeaveAndReturn.push({
        leaveDate,
        returnDate: event.event_date,
      });
      leaveDate = null;
    }
  }

  if (leaveDate) {
    timePeriodsBetweenLeaveAndReturn.push({
      leaveDate,
      returnDate: '3000-01-01',
    });
  }

  return timePeriodsBetweenLeaveAndReturn.some(t => e.event_date > t.leaveDate && e.event_date < t.returnDate)
    ? `${printEventType(e.event_type)} event  should not happen while the ECT has left`
    : false;
};

const leavingAndReturnedMustPairUp: EctProgressEventRule = (e, ect) => {
  if (e.event_type !== 'leaving' && e.event_type !== 'returned') return false;

  let isLeaving = false;
  for (const event of ect.ect_progress_events) {
    if (event.event_type === 'leaving') {
      if (isLeaving && event.id === e.id) {
        return `${printEventType('leaving')} event cannot be after another ${printEventType(
          'leaving'
        )} event without a ${printEventType('returned')} event`;
      } else {
        isLeaving = true;
      }
    }

    if (event.event_type === 'returned') {
      if (!isLeaving && event.id === e.id) {
        return `${printEventType('returned')} event can only be after a ${printEventType('leaving')} event`;
      } else {
        isLeaving = false;
      }
    }
  }

  return false;
};

const disallowedEventsWhilePaused: EctProgressEventRule = (e, ect) => {
  const allowedTypes: EctProgressEventType[] = ['note', 'resumed', 'review', 'projected_review', 'absences'];
  if (allowedTypes.includes(e.event_type)) return false;

  const timePeriodsBetweenPauseAndResume = [];
  let pauseDate = null;
  for (const event of ect.ect_progress_events) {
    if (pauseDate == null && event.event_type === 'paused') {
      pauseDate = event.event_date;
    }

    if (pauseDate && event.event_type === 'resumed') {
      timePeriodsBetweenPauseAndResume.push({
        pauseDate,
        resumeDate: event.event_date,
      });
      pauseDate = null;
    }
  }

  if (pauseDate) {
    timePeriodsBetweenPauseAndResume.push({
      pauseDate,
      resumeDate: '3000-01-01',
    });
  }

  return timePeriodsBetweenPauseAndResume.some(t => e.event_date > t.pauseDate && e.event_date < t.resumeDate)
    ? `${printEventType(e.event_type)} event should not happen while the ECT is paused`
    : false;
};

const startedEventShouldHaveASchool: EctProgressEventRule = e => {
  if (!isStartedEvent(e)) return false;
  return !e.school_id ? 'Started event should have a School' : false;
};

export const ectProgressRules: EctProgressEventRule[] = [
  leaveEventAfterEndOfYear2,
  disallowedEventsWhileLeft,
  leavingAndReturnedMustPairUp,
  disallowedEventsWhilePaused,
  startedEventShouldHaveASchool,
];
