import { DateTime } from 'luxon';

// The CacheMap is designed to store entities with an id for the length of time specified (default of one hour)
export class CacheMap<T> {
  private cache: { [id: string]: { value: T; createdAt: string } | undefined };
  constructor(private cacheLengthInMinutes = 60) {
    this.cache = {};
  }

  set(id: number | string, value: T) {
    this.cache[id] = { value, createdAt: DateTime.now().toISO() };
  }

  remove(id: number | string) {
    this.cache[id] = undefined;
  }

  get(id: number | string): T | undefined {
    const cacheEntry = this.cache[id];
    if (!cacheEntry) return undefined;
    if (-1 * DateTime.fromISO(cacheEntry.createdAt).diffNow('minutes').minutes > this.cacheLengthInMinutes) {
      this.remove(id);
      return undefined;
    }
    return cacheEntry.value;
  }

  getEvenIfExpired(id: number | string) {
    const cacheEntry = this.cache[id];
    if (!cacheEntry) return undefined;
    return cacheEntry.value;
  }

  clear() {
    this.cache = {};
  }
}
