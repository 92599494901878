<template>
  <mosaic-loading-card v-if="busy" type="list-no-filters" />
  <mosaic-load-error-card v-else-if="loadError" object-type="portfolio" @retry="load" />
  <standards-list
    v-else
    :title="standardNoun"
    :standards="standards"
    :standard-click-to="standardClickTo"
    :readonly="true"
  />
</template>

<script>
import StandardsList from '../../components/StandardsList.vue';
import { mapState } from 'vuex';

export default {
  name: 'TutorPorfolioOverviewPage',
  data: function () {
    return {
      standards: [],
      busy: true,
      loadError: false,
    };
  },
  async created() {
    await this.load();
  },
  computed: {
    ...mapState(['selectedStudent']),
    breadcrumbs() {
      return [
        {
          text: 'Portfolio',
        },
      ];
    },
  },
  methods: {
    async load() {
      this.loadError = false;
      this.busy = true;
      try {
        const r = await this.$api.get(`/students/${this.selectedStudent.id}/portfolio`);
        this.standards = r.data;
      } catch (e) {
        this.loadError = true;
      }
      this.busy = false;
    },
    standardClickTo(standardId) {
      return {
        name: 'TutorPortfolioStandardPage',
        params: { studentId: this.selectedStudent.id, standardId: standardId },
      };
    },
  },
  components: { StandardsList },
};
</script>
