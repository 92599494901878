<template>
  <div v-if="curriculumEnabled || forceDisplay">
    <mosaic-card-heading>{{ curriculumNoun + ' Links' }}</mosaic-card-heading>
    <div v-if="studentVisibleCurriculumStatements.length > 0 || canEdit" class="py-2">
      <mosaic-alert
        v-for="statement in studentVisibleCurriculumStatements"
        :key="statement.id"
        border="start"
        border-color="primary"
        variant="elevated"
        class="mb-4"
      >
        <div
          class="d-flex py-1 align-center"
          :class="{
            'flex-column': smallScreen,
            'vertical-flex-spacing': smallScreen,
          }"
        >
          <div class="flex-grow-1 d-flex align-center">
            <div class="px-2">{{ statement.code }} - {{ statement.statement }}</div>
            <div class="flex-grow-1" />

            <mosaic-tooltip-chip v-if="statement.hidden" color="secondary"
              ><template #text>Hidden</template>
              <template #tooltip
                >This Curriculum Link will not be displayed to any {{ traineeNounCapitalisedAndPluralised }} because the
                Curriculum Statement is hidden</template
              >
            </mosaic-tooltip-chip>
            <ndt-icon-button
              v-if="canEdit"
              icon="delete"
              :tooltip="'Remove ' + curriculumNoun + ' link'"
              @click.prevent="submitRemoveCurriculumStatement(statement)"
            />
            <div v-else class="space-missing-icon"></div>
          </div>

          <div
            v-if="(curriculumEnabled || forceDisplay) && (selectedStudent || user.student)"
            class="flex-shrink-1 text-right pl-4"
          >
            <v-btn :to="learningJourneyTo(statement.id)">Go to Learning Journey</v-btn>
          </div>
        </div>
      </mosaic-alert>

      <div v-if="canEdit">
        <v-btn ripple @click.prevent="linkCurriculumStatement()">
          <div class="d-flex align-center">
            <v-icon>mdi-plus</v-icon>
            <span>{{ curriculumNoun }} Link</span>
          </div>
        </v-btn>
      </div>
    </div>
    <div v-else>This {{ artefactType }} is not linked to any {{ curriculumNoun }} areas</div>
    <link-to-curriculum-dialog
      v-model:active="linkToCurriculumDialogActive"
      :selected-curriculum-statements="selectedCurriculumStatements"
      @curriculum-statement-selected="submitLinkToCurriculumDialog"
    />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { visibleCurriculumStatements } from '@/utils/curriculum';
import LinkToCurriculumDialog from './LinkToCurriculumDialog.vue';
import NdtIconButton from './NdtIconButton.vue';

export default {
  name: 'CurriculumLinks',
  components: { LinkToCurriculumDialog, NdtIconButton },
  props: {
    selectedCurriculumStatements: {
      type: Array,
    },
    curriculumStatementId: {
      type: String,
      default: null,
    },
    originalCurriculumStatementId: {
      type: Number,
    },
    canEdit: {
      type: Boolean,
      default: true,
    },
    artefactType: {
      type: String,
      required: true,
    },
    forceDisplay: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:link-added', 'update:link-removed'],
  data: () => ({
    linkToCurriculumDialogActive: false,
    removeCurriculumDialogActive: false,
  }),
  computed: {
    ...mapState(['curriculumStatements', 'selectedInstitution', 'user', 'selectedStudent']),
    ...mapGetters(['curriculumEnabled']),
    studentVisibleCurriculumStatements() {
      return visibleCurriculumStatements(this.selectedCurriculumStatements, this.selectedStudent, this.user);
    },
  },
  watch: {
    curriculumStatements(x) {
      if (x && this.curriculumStatementId && x[this.curriculumStatementId]) {
        this.$emit('update:link-added', x[this.curriculumStatementId]);
      }
    },
  },
  async created() {
    this.$store.dispatch('loadCurriculum');
    if (this.curriculumStatements && this.curriculumStatementId) {
      this.$emit('update:link-added', this.curriculumStatements[this.curriculumStatementId]);
    }
  },
  methods: {
    linkCurriculumStatement() {
      this.linkToCurriculumDialogActive = true;
    },
    submitRemoveCurriculumStatement(statement) {
      this.$emit('update:link-removed', statement);
    },
    submitLinkToCurriculumDialog(curriculumStatement) {
      this.$emit('update:link-added', curriculumStatement);
      this.linkToCurriculumDialogActive = false;
    },
    learningJourneyTo(statementId) {
      return {
        name: this.user.student ? 'StudentCurriculumStatementPage' : 'TutorCurriculumStatementPage',
        params: { id: statementId },
        query: { tab: 'practice' },
      };
    },
  },
};
</script>
<style scoped>
.space-missing-icon {
  width: 46px;
}
</style>
