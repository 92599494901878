<template>
  <mosaic-card>
    <mosaic-card-heading id="comments-card-heading">Feedback Comments</mosaic-card-heading>
    <mosaic-error-snackbar v-model="commentDoesNotExist" message="This Comment appears to have been deleted" />
    <div class="pb-4">
      <template v-if="comments.length">
        <template v-for="comment in editableComments" :key="comment.id">
          <div :id="'comment-' + comment.id">
            <div class="d-flex align-center" style="height: 25px">
              <v-icon>mdi-chat-outline</v-icon>
              <div class="pl-2 font-weight-bold">{{ renderCommenterName(comment) }}</div>
              <div class="px-2">{{ renderCommentDate(comment) }}</div>
              <template v-if="canEdit(comment)">
                <template v-if="!comment.editing">
                  <ndt-icon-button
                    icon="pencil"
                    small
                    tooltip="Edit Comment"
                    @click.prevent="toggleEditability(comment)"
                  />
                  <ndt-icon-button
                    icon="delete"
                    small
                    tooltip="Delete Comment"
                    @click.prevent="deleteComment(comment)"
                  />
                </template>
                <template v-else>
                  <ndt-icon-button
                    icon-color="green"
                    small
                    icon="check"
                    tooltip="Save Changes"
                    @click.prevent="updateComment(comment)"
                  />
                  <ndt-icon-button
                    icon-color="red"
                    small
                    icon="close"
                    tooltip="Discard Changes"
                    @click.prevent="cancelEdit(comment)"
                  />
                </template>
              </template>
            </div>
            <div class="d-flex pl-4">
              <div class="flex-grow-1">
                <mosaic-quill
                  v-if="comment.editing"
                  :key="comment.id + '-editing'"
                  v-model:contents="comment.body"
                  class="pt-2 pb-4 pl-2"
                  :read-only="false"
                ></mosaic-quill>
                <mosaic-quill
                  v-if="!comment.editing"
                  :key="comment.id + '-notediting'"
                  v-model:contents="comment.body"
                  :read-only="true"
                ></mosaic-quill>
              </div>
            </div>
          </div>
        </template>
      </template>
      <template v-else>
        <div class="pt-2">No comments recorded</div>
      </template>
    </div>
    <template v-if="userStaffHasPermissionForSelectedStudent(editPermission)">
      <div class="font-weight-bold pb-1">New Comment</div>
      <mosaic-quill v-model:contents="newCommentBody" :read-only="false"></mosaic-quill>
      <div class="d-flex justify-end pt-2">
        <v-btn ripple :disabled="!newCommentBody" @click.prevent="addComment()">Add</v-btn>
      </div>
    </template>
    <mosaic-snackbar v-model="errorSnackbar.active" location="bottom" color="error" :message="errorSnackbar.message" />
  </mosaic-card>
</template>

<script>
import NdtIconButton from './NdtIconButton.vue';
import MosaicQuill from './quill/MosaicQuill.vue';
import moment from 'moment';
import { mapState } from 'vuex';

export default {
  props: {
    comments: {
      required: true,
      type: Array,
    },
    apiPath: {
      default: '',
      type: String,
    },
    createApiPath: {
      default: '',
      type: String,
    },
    editPermission: {
      required: false,
      default: '',
      type: String,
    },
    scrollToCommentId: {
      required: false,
      type: String,
    },
  },
  emits: ['update:dirty', 'commentsUpdated'],
  expose: ['saveIfDirty'],
  data() {
    return {
      studentId: null,
      newCommentBody: null,
      editableComments: [],
      errorSnackbar: {
        message: '',
        active: false,
      },
      commentDoesNotExist: false,
    };
  },
  computed: {
    ...mapState(['user', 'selectedStudent', 'userStaff']),
    dirty() {
      if (this.newCommentBody) return true;
      return this.editableComments.some(x => {
        if (x.editing) {
          const comment = this.comments.find(c => c.id === x.id);
          return x.body !== comment.body;
        }
        return false;
      });
    },
  },
  watch: {
    comments(x) {
      this.editableComments = x.map(c => ({
        ...c,
        editing: false,
      }));
    },
    dirty(x) {
      this.$emit('update:dirty', x);
    },
    scrollToCommentId(id) {
      this.scrollCommentIntoView(id);
    },
  },
  created() {
    this.studentId = this.$route.params.studentId;
    this.editableComments = this.comments.map(c => ({
      ...c,
      editing: false,
    }));
    this.$emit('update:dirty', this.dirty);
  },
  mounted() {
    this.scrollCommentIntoView(this.scrollToCommentId);
  },
  methods: {
    scrollCommentIntoView(commentId) {
      if (commentId) {
        const element = document.getElementById(`comment-${commentId}`);
        // I believe we need center, otherwise small comments can be hidden under the AppToolbar
        if (element) {
          element.scrollIntoView({ block: 'center' });
        } else {
          const heading = document.getElementById(`comments-card-heading`);
          heading.scrollIntoView({ block: 'center' });
          this.commentDoesNotExist = true;
        }
      }
    },
    async addComment() {
      try {
        await this.addCommentWithoutErrorHandling();
      } catch (e) {
        console.log(e);
        this.errorSnackbar = {
          active: true,
          message: 'Sorry, cannot add your comment at the moment',
        };
      }
    },
    async addCommentWithoutErrorHandling() {
      const r = await this.$api.post(this.createApiPath, {
        body: this.newCommentBody,
      });
      this.$emit('commentsUpdated', [...this.comments, r.data]);
      this.newCommentBody = null;
    },
    async updateComment(comment) {
      try {
        await this.updateCommentWithoutErrorHandling(comment);
      } catch (e) {
        console.log(e);
        this.errorSnackbar = {
          active: true,
          message: 'Sorry, cannot update your comment at the moment',
        };
      }
    },
    async updateCommentWithoutErrorHandling(comment) {
      const updateApiPath = this.apiPath + `/${comment.id}`;
      const r = await this.$api.put(updateApiPath, { body: comment.body });
      this.$emit(
        'commentsUpdated',
        this.comments.map(c => {
          if (c.id === comment.id) return r.data;
          return c;
        })
      );
    },
    // Used by parent pages to save the CommentsCard if leaving via UnsavedChangesDialog
    async saveIfDirty() {
      if (this.newCommentBody) {
        await this.addCommentWithoutErrorHandling();
      }

      for (const x of this.editableComments) {
        if (x.editing) {
          const comment = this.comments.find(c => c.id === x.id);
          if (x.body !== comment.body) {
            await this.updateCommentWithoutErrorHandling(x);
          }
        }
      }
    },
    async deleteComment(comment) {
      const deleteApiPath = this.apiPath + `/${comment.id}`;
      try {
        await this.$api.delete(deleteApiPath);
        this.$emit(
          'commentsUpdated',
          this.comments.filter(c => c.id !== comment.id)
        );
      } catch (e) {
        console.log(e);
        this.errorSnackbar = {
          active: true,
          message: 'Sorry, cannot delete your comment at the moment',
        };
      }
    },
    renderCommenterName(comment) {
      const name = this.isMyComment(comment) ? 'You' : comment.staff.user.name;
      return name;
    },
    renderCommentDate(comment) {
      const label = moment(comment.created_at).fromNow();
      return label;
    },
    canEdit(comment) {
      return (
        this.userStaffHasPermissionForSelectedStudent(this.editPermission) &&
        this.isMyComment(comment) &&
        !this.otherCommentBeingEdited(comment)
      );
    },
    isMyComment(comment) {
      return comment.staff.id === this.userStaff?.id;
    },
    toggleEditability(comment) {
      this.editableComments = this.editableComments.map(c => {
        return {
          ...c,
          editing: c.id === comment.id ? !c.editing : c.editing,
        };
      });
    },

    otherCommentBeingEdited(comment) {
      const otherCommentBeingEdited = this.editableComments.some(c => {
        return c.editing && c.id != comment.id;
      });
      return otherCommentBeingEdited;
    },
    cancelEdit() {
      this.editableComments = this.comments.map(c => {
        return {
          ...c,
          body: c.body,
        };
      });
    },
  },
  components: { MosaicQuill, NdtIconButton },
};
</script>
