<template>
  <ndt-dialog v-model:active="activeNdtDialog" title="Create Folder" :error-message="errorMessage">
    <v-text-field
      id="folder-name"
      v-model="name"
      prepend-icon="mdi-folder"
      name="create-folder-name"
      label="Name"
      type="text"
    />
    <template #buttons>
      <v-btn variant="text" ripple :disabled="processing || !name" @click.prevent="submitCreateFolder()">Create</v-btn>
    </template>
  </ndt-dialog>
</template>

<script>
import NdtDialog from '../NdtDialog.vue';
import { invalidCharacters } from '../../utils/files';

export default {
  name: 'CreateFolderDialog',
  components: { NdtDialog },
  props: {
    active: {
      type: Boolean,
      required: true,
    },
    folder: {
      type: Object,
      required: true,
    },
  },
  emits: ['update:active', 'close'],
  data: function () {
    return {
      activeNdtDialog: this.active,
      name: '',
      processing: false,
      errorMessage: '',
    };
  },
  watch: {
    activeNdtDialog: function (x) {
      this.$emit('update:active', x);
    },
    active: function (x) {
      this.activeNdtDialog = x;
    },
  },
  methods: {
    async submitCreateFolder() {
      this.errorMessage = null;
      const invalidChars = invalidCharacters.filter(x => this.name.includes(x));
      if (invalidChars.length > 0) {
        this.errorMessage = `Name contains an invalid character: ${invalidChars.join(' ')}`;
        return;
      }

      this.processing = true;
      try {
        await this.$api.post(this.folder.folderApiPath, {
          parentId: this.folder.id,
          name: this.name.trim(),
        });
        this.name = '';
        this.activeNdtDialog = false;
        this.$emit('close');
      } catch (e) {
        console.log(e);
        this.errorMessage = 'Sorry, cannot create a folder at the moment.';
      }
      this.processing = false;
    },
  },
};
</script>
