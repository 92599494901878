<template>
  <div>
    <mosaic-loading-card v-if="loading" type="curriculum" />
    <mosaic-load-error-card v-else-if="loadError" :object-type="curriculumNoun" @retry="load" />
    <curriculum-list
      v-else
      :curriculum-themes="curriculumThemes"
      :curriculum-statement-click-to="curriculumStatementClickTo"
      @update:selected-curriculum-theme="updateSelectedCurriculumTheme"
    />
  </div>
</template>

<script>
import CurriculumList from './CurriculumList.vue';

export default {
  name: 'StudentCurriculumPage',
  components: { CurriculumList },
  data: function () {
    return {
      curriculumThemes: [],
      selectedCurriculumTheme: null,
      loading: true,
      loadError: false,
    };
  },
  computed: {
    breadcrumbs() {
      return [
        {
          text: this.curriculumNoun,
        },
      ];
    },
  },
  async created() {
    await this.load();
  },
  methods: {
    async load() {
      this.loading = true;
      this.loadError = false;
      try {
        const r = await this.$api.get('/student-curriculum-themes');
        this.curriculumThemes = r.data;
      } catch (e) {
        this.loadError = true;
        console.log(e);
      }
      this.loading = false;
    },
    updateSelectedCurriculumTheme(theme) {
      this.selectedCurriculumTheme = theme;
    },
    curriculumStatementClickTo(statement) {
      return { name: 'StudentCurriculumStatementPage', params: { id: statement.id } };
    },
  },
};
</script>
