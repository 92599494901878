<template>
  <div style="min-width: max-content">
    <v-text-field v-model="filter" hide-details label="Filter by name or email" prepend-icon="mdi-magnify" />
  </div>
</template>

<script>
import { syncQueryParamsMixin } from '@/mixins/query-mixins';
import { useQueryStore } from '@/stores/query';

export default {
  name: 'StaffListNameFilter',
  setup() {
    const queryStore = useQueryStore();
    return { queryStore };
  },
  mixins: [
    syncQueryParamsMixin({
      filter: { query: 'name', type: 'string' },
    }),
  ],
  emits: ['update:filter'],
  data() {
    return { filter: '' };
  },
  watch: {
    filter(x) {
      this.$emit('update:filter', x);
    },
  },
};
</script>
