<template>
  <div>
    <v-card>
      <v-card-text class="pa-5">
        <div class="text-h6">{{ title }}</div>
        <div>
          <div class="d-flex flex-row flex-wrap">
            <div style="flex-basis: 50%" class="mt-2 pr-2">
              <mosaic-date-picker v-if="editable" v-model:date="meetingDate" label="Meeting Date"></mosaic-date-picker>
              <div v-else class="d-flex align-center">
                <div class="text-subtitle-1" style="width: 150px">Meeting Date</div>
                <div class="text-body">{{ formatDate(meetingDate) }}</div>
              </div>
            </div>
          </div>
          <mosaic-content-layout
            v-if="contentLayout.sections.length > 0"
            v-model:comments="comments"
            v-model:tasks="tasks"
            class="mb-4"
            :content-layout="contentLayout"
            :get-resource-url-prefix="`/mentor-meeting-template/resources`"
            :readonly="!editable"
          />
          <template v-if="allowFileUpload">
            <div class="text-h6">{{ editable ? 'Add a File' : 'File' }}</div>

            <mosaic-list-item
              v-if="fileId"
              @click="fileClicked()"
              :title="fileName"
              icon="mdi-file"
              :error-snackbar="errorSnackbar"
            >
              <template #prepend>
                <v-avatar>
                  <v-icon>mdi-file</v-icon>
                </v-avatar>
              </template>

              <template #actions>
                <ndt-icon-button icon="download" tooltip="Download file" @click.prevent="downloadFile()" />
                <ndt-icon-button v-if="editable" icon="delete" tooltip="Delete file" @click.prevent="deleteFile()" />
              </template>
            </mosaic-list-item>

            <div v-else-if="editable">
              <v-btn ripple class="mt-2" @click.prevent="addFile()">
                <v-icon>mdi-plus</v-icon>
                <span>File</span>
              </v-btn>
            </div>
            <div v-else class="pb-2">No file added</div>
          </template>
          <curriculum-links
            v-if="selectedInstitution.config.mentor_meetings_link_to_curriculum"
            class="mt-4"
            artefact-type="Mentor Meeting"
            :selected-curriculum-statements="curriculumStatements"
            :curriculum-statement-id="curriculumStatementId"
            :can-edit="editable"
            @update:link-added="addCurriculumLink"
            @update:link-removed="removeCurriculumLink"
          >
          </curriculum-links>
        </div>
        <v-alert v-if="error" density="compact" class="mt-4 mb-4" type="error">{{ error }}</v-alert>
        <mosaic-snackbar
          v-model="saveSnackbar.active"
          location="bottom"
          color="success"
          :message="saveSnackbar.message"
        />
        <div v-if="editable" class="mt-2 pr-2 d-flex justify-end">
          <v-btn ripple class="mr-2" @click.prevent="cancel()">Cancel</v-btn>
          <v-btn
            color="primary"
            ripple
            :disabled="processing || !canSave || !dirty"
            @click.prevent="saveWithProcessingAndError()"
            >Save</v-btn
          >
        </div>
      </v-card-text>
    </v-card>

    <v-card class="mt-4">
      <v-card-text class="pa-5">
        <mosaic-card-title>Targets</mosaic-card-title>
        <mosaic-list v-if="targets.length > 0" :items="targets">
          <template #item="{ item: t }">
            <mosaic-list-item
              ripple
              :to="targetClickedTo(t)"
              icon="mdi-near-me"
              :title="t.goal"
              :subtitle="t.imapact_goal"
            >
              <template #information>
                <div class="mr-4">
                  <v-chip v-if="t.status === 'active'" color="accent">Active</v-chip>
                  <v-chip v-else-if="t.status === 'approved'" color="secondary">Approved</v-chip>
                  <v-chip v-else color="primary">Completed</v-chip>
                </div>
              </template>

              <template #actions>
                <ndt-icon-button
                  icon="delete"
                  :disabled="!t.can_delete.permitted"
                  :tooltip="deleteTargetTooltip(t.can_delete)"
                  @click.prevent="deleteTarget(t)"
                />
              </template>
            </mosaic-list-item>
          </template>
        </mosaic-list>
        <div v-else-if="canAddTargets && !mentorMeeting" class="pb-2">
          Save this Mentor Meeting to be able to set targets
        </div>
        <div v-else-if="!canAddTargets" class="pb-2">Targets set as part of this Mentor Meeting will appear here</div>

        <v-btn v-if="canAddTargets && mentorMeeting" ripple class="mt-4" @click.prevent="$emit('clickCreateTarget')">
          <v-icon>mdi-plus</v-icon>
          <span>Target</span>
        </v-btn>
      </v-card-text>
    </v-card>

    <ndt-dialog v-model:active="addFileDialog.active" title="Add a file" :on-close="addFilesOnClose">
      <mosaic-file-pond-external-storage
        ref="filepond"
        :get-upload-url-and-headers="getUploadUrlAndHeaders"
        :max-files="1"
        @file-uploaded="fileUploaded"
      ></mosaic-file-pond-external-storage>
      <template #buttons>
        <v-btn variant="text" ripple :disabled="processing || !addFileDialog.fileId" @click.prevent="confirmAddFile()">
          Confirm
        </v-btn>
      </template>
    </ndt-dialog>

    <ndt-dialog
      v-model:active="deleteTargetDialog.active"
      title="Delete Target"
      :error-message="deleteTargetDialog.errorMessage"
    >
      <div>Are you sure you want to delete this target?</div>
      <template #buttons>
        <v-btn
          variant="text"
          ripple
          color="error"
          :disabled="deleteTargetDialog.processing"
          @click.prevent="submitDeleteTarget()"
          >Delete</v-btn
        >
      </template>
    </ndt-dialog>
  </div>
</template>

<script>
import NdtDialog from '@/components/NdtDialog.vue';
import NdtIconButton from '@/components/NdtIconButton.vue';
import MosaicContentLayout from '@/components/mosaic-content-layout/MosaicContentLayout.vue';
import { useActiveTargetsStore } from '@/stores/active-targets';
import MosaicFilePondExternalStorage from '@/components/file-pond/MosaicFilePondExternalStorage.vue';
import { mapState } from 'vuex';
import moment from 'moment';
import CurriculumLinks from '@/components/CurriculumLinks.vue';
import { fromSnakeCaseToCamelCase } from '@/utils/transforms';
import { deleteTargetTooltip } from '@/pages/targets/targets';
import { getContentLayoutItemsOfType, itemIsComment, itemIsTaskList } from '@/utils/content-layout';

export default {
  name: 'MentorMeetingCard',
  components: {
    NdtDialog,
    MosaicFilePondExternalStorage,
    NdtIconButton,
    CurriculumLinks,
    MosaicContentLayout,
  },
  props: {
    editable: {
      type: Boolean,
      required: true,
    },
    canAddTargets: {
      type: Boolean,
      required: true,
    },
    mentorMeeting: {
      required: true,
      validator: prop => typeof prop === 'object' || prop === null,
    },
    mentorMeetingTemplate: {
      type: Object,
      required: true,
    },

    studentId: {
      type: Number,
      required: true,
    },
    targetsUrl: {
      type: String,
      required: true,
    },
    curriculumStatementId: {
      type: String,
      default: null,
    },
    targetClickedTo: {
      type: Function,
      required: true,
    },
  },
  emits: ['update:dirty', 'clickCreateTarget', 'update:mentorMeeting'],
  expose: ['save'],
  data() {
    return {
      processing: false,
      error: '',
      meetingDate: null,
      fileId: null,
      fileName: null,
      curriculumStatements: [],
      contentLayout: null,
      allowFileUpload: false,
      comments: [],
      tasks: [],
      addFileDialog: {
        active: false,
        fileId: null,
        fileName: null,
      },
      saveSnackbar: {
        active: false,
        message: '',
      },
      errorSnackbar: {
        active: false,
        message: '',
      },
      deleteTargetDialog: {
        active: false,
        id: null,
        disabled: true,
        errorMessage: '',
      },
    };
  },
  setup() {
    const {
      actions: { removeTarget },
    } = useActiveTargetsStore();
    return { removeTarget };
  },
  watch: {
    mentorMeeting(meeting) {
      this.setFields(meeting);
    },
    dirty(x) {
      this.$emit('update:dirty', x);
    },
  },
  created() {
    this.setFields(this.mentorMeeting);
    this.$emit('update:dirty', this.dirty);
  },
  computed: {
    ...mapState(['user', 'userStaff', 'selectedInstitution']),
    title() {
      return `Mentor Meeting - ${this.formatDate(this.meetingDate)}`;
    },
    canSave() {
      return this.meetingDate && (this.fileId || this.contentLayout.sections.length > 0);
    },
    createdByMe() {
      if (this.user.student) return !this.mentorMeeting?.created_by_id;
      return this.mentorMeeting?.created_by_id === this.userStaff.id;
    },
    targets() {
      return this.mentorMeeting?.targets || [];
    },
    isCreating() {
      return !this.mentorMeeting;
    },
    curriculumStatementsDirty() {
      const originalIds = !this.isCreating ? this.mentorMeeting.curriculum_statements.map(s => s.id) : [];
      const currentIds = this.curriculumStatements.map(s => s.id);
      return originalIds.sort().join(',') !== currentIds.sort().join(',');
    },
    dirty() {
      if (this.isCreating) {
        return Boolean(this.fileId) || this.comments.some(c => c.comment) || this.tasks.some(t => t.completed);
      }

      const commentsDirty = this.mentorMeeting.mentor_meeting_comments.some(
        c => this.comments.find(x => x.id === c.id)?.comment !== c.comment
      );
      const tasksDirty = this.mentorMeeting.mentor_meeting_tasks.some(
        t => this.tasks.find(x => x.id === t.id)?.completed !== t.completed
      );

      return (
        moment.utc(this.mentorMeeting.meeting_date).format('YYYY-MM-DD') !== this.meetingDate ||
        this.fileId !== this.mentorMeeting.file_id ||
        commentsDirty ||
        tasksDirty ||
        this.curriculumStatementsDirty
      );
    },
  },
  methods: {
    setFields(meeting) {
      if (!meeting) {
        this.contentLayout = this.mentorMeetingTemplate.contentLayout;
        this.allowFileUpload = this.mentorMeetingTemplate.allowFileUpload;
        this.meetingDate = moment().format('YYYY-MM-DD');
        this.comments = getContentLayoutItemsOfType(this.contentLayout, itemIsComment).map(i => ({
          templateId: i.id,
          mentorMeetingCommentId: i.id,
          comment: i.comment,
        }));
        this.tasks = getContentLayoutItemsOfType(this.contentLayout, itemIsTaskList)
          .flatMap(i => i.tasks)
          .map(t => ({ templateId: t.id, mentorMeetingTaskId: t.id, completed: false }));
      } else {
        this.contentLayout = fromSnakeCaseToCamelCase(meeting.content_layout);
        this.allowFileUpload = meeting.allow_file_upload;
        this.meetingDate = moment.utc(meeting.meeting_date).format('YYYY-MM-DD');
        this.fileId = meeting.file_id;
        this.fileName = meeting.file_name;
        this.curriculumStatements = meeting.curriculum_statements.map(s => {
          return { ...s };
        });
        this.comments = this.mentorMeeting.mentor_meeting_comments.map(c => ({
          ...c,
          templateId: c.mentor_meeting_comment_id,
        }));
        this.tasks = this.mentorMeeting.mentor_meeting_tasks.map(t => ({ ...t, templateId: t.mentor_meeting_task_id }));
      }
    },
    addCurriculumLink(x) {
      this.curriculumStatements.push(x);
    },
    removeCurriculumLink(x) {
      const index = this.curriculumStatements.findIndex(s => s.id === x.id);
      this.curriculumStatements.splice(index, 1);
    },
    setByMe(target) {
      if (this.userStaff) {
        return target.set_by_id === this.userStaff.id;
      } else return !target.set_by_id;
    },
    async fileClicked() {
      await this.openFile(false);
    },
    async downloadFile() {
      await this.openFile(true);
    },
    async openFile(download) {
      try {
        const r = await this.$api.get(
          `students/${this.studentId}/mentor-meetings/file/${this.fileId}?download=${download}`
        );
        window.open(r.data.web_url, '_blank');
      } catch (e) {
        console.log(e);
        let message = '';
        if (e.response?.status === 404) {
          message = 'This file no longer exists';
        } else {
          message = 'Sorry. cannot open this file at the moment';
        }
        this.errorSnackbar = {
          active: true,
          color: 'error',
          message,
        };
      }
    },
    async saveWithProcessingAndError() {
      this.processing = true;
      this.error = '';

      try {
        await this.save();
      } catch (e) {
        console.log(e);
        this.error = 'Sorry, cannot save your Mentor Meeting at the moment';
      }
      this.processing = false;
    },
    async save() {
      let apiCall;
      let body = {};
      if (this.isCreating) {
        apiCall = x => this.$api.post(`/students/${this.studentId}/mentor-meetings`, x);
        body = {
          contentLayout: this.contentLayout,
          allowFileUpload: this.allowFileUpload,
        };
      } else {
        apiCall = x => this.$api.put(`mentor-meetings/${this.mentorMeeting.id}`, x);
      }
      const r = await apiCall({
        ...body,
        meetingDate: this.meetingDate,
        fileId: this.fileId,
        fileName: this.fileName,
        curriculumStatementIds: this.curriculumStatements.map(s => s.id),
        comments: this.comments,
        tasks: this.tasks,
      });
      this.saveSnackbar = {
        active: true,
        message: 'Mentor Meeting saved',
      };
      this.$emit('update:mentorMeeting', r.data);
    },
    addFile() {
      this.addFileDialog.active = true;
      this.addFileDialog.fileId = null;
      this.addFileDialog.fileName = null;
    },
    confirmAddFile() {
      this.addFileDialog.active = false;
      this.fileId = this.addFileDialog.fileId;
      this.fileName = this.addFileDialog.fileName;
    },
    async getUploadUrlAndHeaders(cancelToken, contentType, filename) {
      const r = await this.$api.post(
        `/presign/students/${this.studentId}/mentor-meetings`,
        { contentType, filename },
        cancelToken
      );
      return r.data;
    },
    fileUploaded(x) {
      this.addFileDialog.fileId = x.fileId;
      this.addFileDialog.fileName = x.title;
    },
    addFilesOnClose() {
      this.$refs.filepond.onClose();
    },
    deleteFile() {
      this.fileId = null;
      this.fileName = null;
    },
    deleteTarget(t) {
      this.deleteTargetDialog.id = t.id;
      this.deleteTargetDialog.active = true;
    },
    async submitDeleteTarget() {
      this.deleteTargetDialog.processing = true;
      this.deleteTargetDialog.errorMessage = '';
      try {
        await this.$api.delete(`${this.targetsUrl}/${this.deleteTargetDialog.id}`);
        this.$emit('update:mentorMeeting', {
          ...this.mentorMeeting,
          targets: this.mentorMeeting.targets.filter(x => x.id !== this.deleteTargetDialog.id),
        });
        this.removeTarget(this.deleteTargetDialog.id);
        this.deleteTargetDialog.active = false;
      } catch (e) {
        console.log(e);
        this.deleteTargetDialog.errorMessage = 'Sorry, cannot delete your Target at the moment';
      }
      this.deleteTargetDialog.processing = false;
    },
    cancel() {
      this.$router.go(-1);
    },
    deleteTargetTooltip,
  },
};
</script>
