<template>
  <div>
    <mosaic-loading-card v-if="busy" type="two-cards" />
    <mosaic-load-error-card v-else-if="error" object-type="Mentor Meeting" @retry="loadMentorMeeting" />
    <mentor-meeting-card
      v-else
      ref="mentorMeetingCard"
      v-model:mentor-meeting="mentorMeeting"
      :mentor-meeting-template="mentorMeetingTemplate"
      :editable="canEditMentorMeetings"
      :student-id="selectedStudent.id"
      :curriculum-statement-id="$route.query.curriculumStatementId"
      :can-add-targets="canAddTargets"
      :targets-url="targetsUrl"
      :target-clicked-to="targetClickedTo"
      @click-create-target="createTarget"
      @update:dirty="$emit('update:dirty', $event)"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import MentorMeetingCard from './MentorMeetingCard.vue';
import { fromSnakeCaseToCamelCase } from '@/utils/transforms';

export default {
  name: 'TutorMentorMeetingEditPage',
  components: { MentorMeetingCard },
  emits: ['update:dirty'],
  expose: ['save'],
  data() {
    return {
      id: null,
      mentorMeeting: null,
      error: false,
      busy: true,
      isCreating: false,
    };
  },
  computed: {
    ...mapState(['user', 'selectedInstitution', 'selectedStudent']),
    breadcrumbs() {
      return [
        {
          text: 'Mentor Meetings',
          to: {
            name: 'MentorMeetingsListPage',
          },
        },
        {
          text: this.isCreating ? 'New Mentor Meeting' : this.mentorMeeting?.title,
        },
      ];
    },
    canEditMentorMeetings() {
      return this.userStaffHasPermissionForSelectedStudent('student.mentorMeetings.edit');
    },
    canAddTargets() {
      return (
        this.userStaffHasPermissionForSelectedStudent('student.mentorMeetings.targets.create') ||
        this.userStaffHasPermissionForSelectedStudent('student.targets.editAll')
      );
    },
    targetsUrl() {
      return `/students/${this.selectedStudent.id}/targets`;
    },
    mentorMeetingTemplate() {
      return fromSnakeCaseToCamelCase(this.selectedStudent.cohort.mentor_meeting_template);
    },
  },
  async created() {
    if (this.$route.name === 'MentorMeetingPage') {
      this.isCreating = false;
      this.id = this.$route.params.id.toString();
      await this.loadMentorMeeting();
    } else {
      this.isCreating = true;
      this.busy = false;
    }
  },
  methods: {
    save() {
      return this.$refs.mentorMeetingCard.save();
    },
    async loadMentorMeeting() {
      this.busy = true;
      this.error = false;
      try {
        const response = await this.$api.get(`/mentor-meetings/${this.id}`);
        this.mentorMeeting = response.data;
      } catch (e) {
        console.log(e);
        this.error = true;
      }
      this.busy = false;
    },
    // We want to always return to the EditPage (especially if on the CreatePage)
    returnUrl() {
      let url = this.$router.resolve({
        name: 'MentorMeetingPage',
        params: { id: this.mentorMeeting.id.toString() },
      }).href;
      // It appears that when the URL is used with this.$router.push(this.returnUrl) it needs the starting #/ removing
      if (url.startsWith('#')) {
        url = url.slice(1);
      }
      return url;
    },
    createTarget() {
      this.$router.push({
        name: 'MentorMeetingTargetCreatePage',
        params: { mentorMeetingId: this.mentorMeeting.id.toString(), studentId: this.selectedStudent.id },
        query: { returnUrl: this.returnUrl(), mentorMeetingDate: this.mentorMeeting.meeting_date },
      });
    },
    targetClickedTo(t) {
      return {
        name: 'MentorMeetingTargetPage',
        params: { mentorMeetingId: this.mentorMeeting.id.toString(), targetId: t.id.toString() },
        query: { returnUrl: this.returnUrl() },
      };
    },
  },
};
</script>
