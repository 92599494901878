<template>
  <div>
    <review-template-header>
      <template #configure>
        <v-card class="mb-4">
          <v-card-text>
            <div class="pa-2">
              <div class="text-h5 mb-1">Configuring "{{ reviewTemplate.name }}" Per {{ standardNounCapitalised }}</div>
              <div>
                Here you can set whether you'd like assessments of progress to be made against the different areas of
                the assessment framework and, if so, what descriptors to use. You can also set what comments you'd like
                on the performance of the {{ traineeNoun() }} and who should be writing these comments, as well as
                whether the {{ traineeNoun() }} should be able to set related targets. To configure individual
                statements to be assessed against and any notes to be displayed alongside the {{ standardNoun }} (e.g.
                termly expectations), use the individual {{ standardNoun }} configuration areas.
              </div>
              <div class="text-h6 pt-3">Targets</div>
              <v-switch
                :model-value="reviewTemplate.can_add_targets_per_standard"
                class="ml-2"
                color="primary"
                density="compact"
                hide-details
                :label="'Targets can be set as part of this ' + selectedInstitution.config.review_noun + '?'"
                @update:model-value="updateCanAddTargets"
              />
              <review-role-select
                v-if="reviewTemplate.can_add_targets_per_standard"
                :roles="targetAddPermissions"
                label="Who can set targets"
                @selected-roles-updated="rolesAddTargetsChanged($event)"
              />
            </div>
          </v-card-text>
        </v-card>

        <v-card class="mb-4">
          <v-card-text>
            <div class="pa-2">
              <div class="text-h6">Assessments of Progress</div>
              <div>
                <review-template-read-only review-template-in-use tooltip="left">
                  <template #default="readOnlySlotProps">
                    <mosaic-disabled-tooltip
                      v-slot="slotProps"
                      :disabled="judgementSets.length === 0 && !reviewTemplateInUse"
                      :tooltip="`No Judgement Sets have been configured. ${
                        userStaffHasPermission('judgementSets.edit') ? 'These can be configured under settings.' : ''
                      }`"
                    >
                      <v-switch
                        :model-value="!!reviewTemplate.review_standard_institution_judgement_set_id"
                        class="ml-2"
                        density="compact"
                        color="primary"
                        hide-details
                        :label="'Overall assessment of progress recorded against each ' + standardNoun + '?'"
                        :disabled="readOnlySlotProps.disabled || slotProps.disabled"
                        @update:model-value="useStandardJudgementsChanged"
                      />
                    </mosaic-disabled-tooltip>
                  </template>
                </review-template-read-only>
                <div v-if="judgementSets.length === 0"><no-judgement-sets-alert class="ma-2" /></div>
                <div v-else-if="!!reviewTemplate.review_standard_institution_judgement_set_id" class="mt-2">
                  <div class="d-flex align-center">
                    <div>
                      <review-template-read-only review-template-in-use tooltip="left">
                        <template #default="{ disabled }">
                          <mosaic-judgement-set-select
                            v-model="reviewTemplate.review_standard_institution_judgement_set_id"
                            :items="judgementSets"
                            :disabled="disabled"
                            @update:model-value="standardJudgementSetChanged"
                          />
                        </template>
                      </review-template-read-only>
                    </div>
                  </div>
                  <review-role-select
                    :roles="standardJudgementPermissions"
                    label="Who can make assessments of progress"
                    @selected-roles-updated="rolesStandardJudgementEditChanged($event)"
                  />
                  <div style="width: 400px">
                    <mosaic-disabled-tooltip
                      v-slot="{ disabled }"
                      :disabled="!reviewTemplate.can_trainee_view_review"
                      :tooltip="`${traineeNounCapitalised()} is not marked as a viewer for this template`"
                    >
                      <v-switch
                        class="ml-2"
                        density="compact"
                        hide-details
                        color="primary"
                        :label="traineeNounCapitalisedAndPluralised + ' can see assessments of progress?'"
                        :model-value="
                          reviewTemplate.can_trainee_view_review && reviewTemplate.can_trainees_view_judgements
                        "
                        :disabled="disabled"
                        @update:model-value="canTraineesViewJudgementsChanged"
                      />
                    </mosaic-disabled-tooltip>
                  </div>
                </div>
              </div>

              <v-divider class="my-2" />
              <div>
                <review-template-read-only review-template-statements-in-use tooltip="left" offset="8">
                  <template #default="readOnlySlotProps">
                    <mosaic-disabled-tooltip
                      v-slot="slotProps"
                      :disabled="judgementSets.length === 0 && !reviewTemplateInUse"
                      :tooltip="`No Judgement Sets have been configured. ${
                        userStaffHasPermission('judgementSets.edit') ? 'These can be configured under settings.' : ''
                      }`"
                    >
                      <v-switch
                        :model-value="!!reviewTemplate.review_standard_statement_institution_judgement_set_id"
                        class="ml-2"
                        density="compact"
                        hide-details
                        color="primary"
                        :label="
                          'Assess ' +
                          traineeNoun() +
                          ' progress against a set of statements for each ' +
                          standardNoun +
                          '?'
                        "
                        :disabled="slotProps.disabled || readOnlySlotProps.disabled"
                        @update:model-value="useStandardStatementsChanged"
                      />
                    </mosaic-disabled-tooltip>
                  </template>
                </review-template-read-only>

                <div v-if="judgementSets.length === 0"><no-judgement-sets-alert class="ma-2" /></div>
                <div v-else-if="!!reviewTemplate.review_standard_statement_institution_judgement_set_id">
                  <div class="d-flex align-center">
                    <div>
                      <review-template-read-only review-template-statements-in-use tooltip="left">
                        <template #default="{ disabled }">
                          <mosaic-judgement-set-select
                            v-model="reviewTemplate.review_standard_statement_institution_judgement_set_id"
                            :items="judgementSets"
                            :disabled="disabled"
                            @update:model-value="standardStatementJudgementSetChanged"
                          />
                        </template>
                      </review-template-read-only>
                    </div>
                  </div>
                  <review-role-select
                    :roles="standardStatementJudgementPermissions"
                    label="Who can make assessments of progress"
                    @selected-roles-updated="rolesStandardStatementJudgementEditChanged($event)"
                  />
                  <div class="my-2">
                    To configure the statements to be assessed against use the individual
                    {{ standardNoun }}
                    areas.
                  </div>
                </div>
              </div>
            </div>
          </v-card-text>
        </v-card>
        <v-card v-if="!reviewTemplateInUse || sortedComments.length > 0">
          <v-card-text>
            <div class="pa-2">
              <div class="text-h6">Comments</div>
              <review-template-comment-box
                v-for="c of sortedComments"
                :key="c.id"
                class="mb-2"
                :review-template-in-use="reviewTemplateInUse"
                :comment="c"
                :can-trainee-view-review="reviewTemplate.can_trainee_view_review"
                :roles="reviewTemplateRoles"
                @update:label="updateLabel(c, $event)"
                @delete="deleteComment(c)"
                @update:placeholder="updatePlaceholder(c, $event)"
                @update:optionality="optionalityChanged(c, $event)"
                @selected-roles-updated="rolesCommentEditChanged(c, $event)"
              />
              <v-btn v-if="!reviewTemplateInUse" class="mt-4" @click.prevent="addComment()">
                <div class="d-flex align-center">
                  <v-icon>mdi-plus</v-icon>
                  <span>Comment</span>
                </div>
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </template>
      <template #preview>
        <review-standard-card
          :student-review-standard="studentReviewStandard"
          :student-review="reviewTemplateAsStudentReview"
          :staff-viewer="previewUser.staff"
          :student-viewer="previewUser.student"
          :student="previewStudent"
          :review-path="{
            text: '',
            to: {
              name: 'TutorAdminReviewTemplatePage',
            },
          }"
        />
      </template>
    </review-template-header>
  </div>
</template>

<script>
import ReviewStandardCard from '@/components/ReviewStandardCard.vue';
import { mapState, mapGetters } from 'vuex';
import ReviewTemplateHeader from '@/components/ReviewTemplateHeader.vue';
import ReviewTemplateReadOnly from '@/components/ReviewTemplateReadOnly.vue';
import ReviewTemplateCommentBox from '@/components/ReviewTemplateCommentBox.vue';
import NoJudgementSetsAlert from '@/components/templates/NoJudgementSetsAlert.vue';
import ReviewRoleSelect from './ReviewRoleSelect.vue';

export default {
  name: 'TutorAdminReviewTemplatePerStandardPage',
  async created() {
    await this.$store.dispatch('loadReviewTemplate', { id: this.$route.params.templateId });
  },
  computed: {
    ...mapState(['user', 'reviewTemplate', 'judgementSets', 'selectedInstitution']),
    ...mapGetters([
      'reviewTemplateRoles',
      'reviewTemplateAsStudentReview',
      'previewUser',
      'previewStudent',
      'reviewNounCapitalised',
      'reviewTemplateInUse',
      'reviewTemplateStatementsInUse',
      'createPermissionsArray',
    ]),
    breadcrumbs() {
      return [
        {
          text: `${this.reviewNounCapitalised} Templates`,
          to: {
            name: 'TutorAdminReviewTemplatesListPage',
          },
        },
        {
          text: this.reviewTemplate?.name,
          to: {
            name: 'TutorAdminReviewTemplatePage',
          },
        },
        {
          text: `Per ${this.standardNounCapitalised}`,
        },
      ];
    },
    standardJudgementPermissions() {
      return this.createPermissionsArray('StandardJudgement');
    },
    standardStatementJudgementPermissions() {
      return [
        {
          roleId: 'student',
          selected: this.reviewTemplate.can_trainees_edit_standard_statement_judgements,
          canView: this.reviewTemplate.can_trainee_view_review,
        },
        ...this.createPermissionsArray('StandardStatementJudgement'),
      ];
    },

    sortedComments() {
      return this.reviewTemplate.review_template_standard_comments
        .map(x => {
          const c = {
            ...x,
          };
          for (const r of this.reviewTemplateRoles) {
            c[`${r.id}_edit`] = c.review_template_permissions.find(x => x.role_id === r.id)?.edit || false;
          }
          return c;
        })
        .sort((a, b) => (a.order > b.order ? 1 : -1));
    },
    targetAddPermissions() {
      return [
        {
          roleId: 'student',
          selected: this.reviewTemplate.can_trainee_add_targets_per_standard,
          canView: this.reviewTemplate.can_trainee_view_review,
        },
        ...this.createPermissionsArray('AddTargetsPerStandard'),
      ];
    },
    studentReviewStandard() {
      // 0th index is 'General', so Per Standard starts at 1
      return this.reviewTemplateAsStudentReview.reviewStandards[1];
    },
  },
  methods: {
    useStandardJudgementsChanged(x) {
      if (!x || this.judgementSets.length === 0) {
        this.$store.dispatch('resetReviewTemplateStandardJudgements');
      } else {
        this.$store.dispatch('updateReviewTemplate', {
          review_standard_institution_judgement_set_id: this.judgementSets[0].id,
        });
      }
    },
    useStandardStatementsChanged(x) {
      if (!x || this.judgementSets.length === 0) {
        this.$store.dispatch('resetReviewTemplateStandardStatements');
      } else {
        this.$store.dispatch('updateReviewTemplate', {
          review_standard_statement_institution_judgement_set_id: this.judgementSets[0].id,
        });
      }
    },
    updateLabel(c, x) {
      this.$store.dispatch('updateReviewTemplateStandardComment', { id: c.id, updates: { label: x } });
    },
    updatePlaceholder(c, x) {
      this.$store.dispatch('updateReviewTemplateStandardComment', { id: c.id, updates: { placeholder: x } });
    },
    rolesCommentEditChanged(c, roles) {
      const review_template_permissions = roles
        .filter(r => r.roleId !== 'student')
        .map(r => ({ role_id: r.roleId, edit: r.selected }));
      this.$store.dispatch('updateReviewTemplateStandardComment', {
        id: c.id,
        updates: { review_template_permissions, can_trainee_edit: roles.find(r => r.roleId === 'student').selected },
      });
    },
    optionalityChanged(c, x) {
      this.$store.dispatch('updateReviewTemplateStandardComment', { id: c.id, updates: { optional: x } });
    },
    addComment() {
      this.$store.dispatch('addReviewTemplateStandardComment');
    },
    deleteComment(c) {
      this.$store.dispatch('deleteReviewTemplateStandardComment', c.id);
    },
    updateCanAddTargets(x) {
      this.$store.dispatch('updateReviewTemplate', { can_add_targets_per_standard: x });
    },
    standardJudgementSetChanged(x) {
      this.$store.dispatch('updateReviewTemplate', { review_standard_institution_judgement_set_id: x });
    },
    canTraineesViewJudgementsChanged(x) {
      this.$store.dispatch('updateReviewTemplate', { can_trainees_view_judgements: x });
    },
    rolesStandardJudgementEditChanged(roles) {
      this.$store.dispatch('updateReviewTemplatePermissions', {
        roles: roles.map(role => ({
          roleId: role.roleId,
          edit: role.selected,
          permission: 'StandardJudgement',
        })),
      });
    },
    standardStatementJudgementSetChanged(x) {
      this.$store.dispatch('updateReviewTemplate', { review_standard_statement_institution_judgement_set_id: x });
    },
    rolesStandardStatementJudgementEditChanged(roles) {
      this.$store.dispatch('updateReviewTemplatePermissions', {
        roles: roles
          .filter(r => r.roleId !== 'student')
          .map(role => ({
            roleId: role.roleId,
            edit: role.selected,
            permission: 'StandardStatementJudgement',
          })),
        traineePermissionUpdates: {
          can_trainees_edit_standard_statement_judgements: roles.find(r => r.roleId === 'student').selected,
        },
      });
    },
    rolesAddTargetsChanged(roles) {
      this.$store.dispatch('updateReviewTemplatePermissions', {
        roles: roles
          .filter(r => r.roleId !== 'student')
          .map(role => ({
            roleId: role.roleId,
            edit: role.selected,
            permission: 'AddTargetsPerStandard',
          })),
        traineePermissionUpdates: {
          can_trainee_add_targets_per_standard: roles.find(r => r.roleId === 'student').selected,
        },
      });
    },
  },
  components: {
    ReviewStandardCard,
    ReviewTemplateHeader,
    ReviewTemplateReadOnly,
    ReviewTemplateCommentBox,
    NoJudgementSetsAlert,
    ReviewRoleSelect,
  },
};
</script>
<style>
.v-select-fit .v-select__selections input {
  display: none;
}
</style>
