<template>
  <v-tooltip location="top">
    <template #activator="{ props }">
      <span v-bind="props">
        <v-icon class="mr-2" style="position: relative" :color="seenStatusColor">mdi-chat-outline</v-icon>
        <span
          class="font-weight-bold"
          style="position: relative; top: -8px; left: -8px"
          :class="[seen || !user.student ? seenClass : unseenClass]"
        >
          {{ commentCount }}
        </span>
      </span>
    </template>
    <span
      >{{ commentCount }} feedback comment{{ commentCount > 1 ? 's' : ''
      }}{{ !seen && user.student ? ' - new comments to view' : '' }}</span
    >
  </v-tooltip>
</template>

<script>
export default {
  props: {
    commentCount: {
      type: Number,
      required: true,
    },
    seen: {
      type: Boolean,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    return {
      seenClass: 'seen',
      unseenClass: 'unseen',
    };
  },

  computed: {
    seenStatusColor() {
      return this.seen || this.user.staff ? 'grey' : 'orange';
    },
  },
};
</script>
<style scoped>
.unseen {
  color: orange;
}
.seen {
  color: grey;
}
</style>
