<template>
  <div>
    <mosaic-loading-card v-if="busy" type="two-cards" />
    <mosaic-load-error-card v-else-if="loadError" object-type="Target" @retry="loadTargetFromApi" />
    <template v-else>
      <target-card
        ref="targetCard"
        v-model:target="target"
        :student-id="userStudent.id"
        :curriculum-statement-id="$route.query.curriculumStatementId"
        :mentor-meeting-id="$route.params.mentorMeetingId"
        :mentor-meeting-date="$route.query.mentorMeetingDate"
        :return-url="$route.query.returnUrl"
        :show-approval-comment="true"
        :return-to="{ name: 'TargetsListPage' }"
        @update:dirty="dirty = $event"
      />
      <comments-card
        v-if="target && target.target_comments"
        class="mt-4"
        :comments="target.target_comments"
        :scroll-to-comment-id="$route.query.commentId"
      />
    </template>
  </div>
</template>

<script>
import TargetCard from './TargetCard.vue';
import CommentsCard from '@/components/CommentsCard.vue';
import { mapState } from 'vuex';
import axios from 'axios';

export default {
  name: 'StudentTargetPage',
  components: { TargetCard, CommentsCard },
  emits: ['update:dirty'],
  expose: ['save'],
  data() {
    return {
      targetId: null,
      target: null,
      busy: true,
      loadError: false,
      dirty: false,
    };
  },
  async created() {
    this.$emit('update:dirty', this.dirty);
    if (!this.isCreating) {
      this.targetId = this.$route.params.targetId.toString();
      await this.loadTargetFromApi();
    }
    this.busy = false;
  },
  computed: {
    ...mapState(['userStudent']),
    isCreating() {
      return !['TargetPage', 'MentorMeetingTargetPage'].includes(this.$route.name);
    },
    breadcrumbs() {
      return [
        {
          text: 'Targets',
          to: {
            name: 'TargetsListPage',
          },
        },
        {
          text: this.isCreating ? 'New Target' : this.target?.goal,
        },
      ];
    },
  },
  watch: {
    dirty(x) {
      this.$emit('update:dirty', x);
    },
  },
  methods: {
    async loadTargetFromApi() {
      this.busy = true;
      this.loadError = false;
      try {
        const response = await this.$api.get(`targets/${this.targetId}`);
        this.target = response.data;
      } catch (e) {
        if (axios.isAxiosError(e) && (e.response?.status === 404 || e.response?.status === 403)) {
          this.$router.replace({
            name: 'DoNotHaveAccessErrorPage',
            query: { to: this.$route.fullPath, objectType: 'Target' },
          });
        } else {
          console.log(e);
          this.loadError = true;
        }
      }
      this.busy = false;
    },
    save() {
      return this.$refs.targetCard.save();
    },
  },
};
</script>
