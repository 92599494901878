import type { ComputedRef } from 'vue';
import { computed } from 'vue';
import type { Actions } from './map-store';
import { mapGetters } from './map-store';

type ActionKey = keyof Actions;

type ActionProcessingKey = `${ActionKey}Processing`;
function mapStateProcessing(
  ...keys: ActionKey[]
): Record<ActionProcessingKey | 'stateProcessing', ComputedRef<boolean>> {
  const getters = mapGetters();
  const individualProcessing: Partial<Record<ActionProcessingKey, ComputedRef<boolean>>> = {};
  keys.forEach(k => {
    individualProcessing[`${k}Processing`] = computed(() => getters.stateProcessing.value(k));
  });
  return {
    ...(individualProcessing as Record<ActionProcessingKey, ComputedRef<boolean>>),
    stateProcessing: computed(() => getters.stateProcessings.value(keys)),
  };
}

type ActionErrorKey = `${ActionKey}Error`;
function mapStateError(...keys: ActionKey[]): Record<ActionErrorKey | 'stateError', ComputedRef<boolean>> {
  const getters = mapGetters();
  const individualErrors: Partial<Record<ActionErrorKey, ComputedRef<boolean>>> = {};
  keys.forEach(k => {
    individualErrors[`${k}Error`] = computed(() => getters.stateError.value(k));
  });
  return {
    ...(individualErrors as Record<ActionErrorKey, ComputedRef<boolean>>),
    stateError: computed(() => getters.stateErrors.value(keys)),
  };
}

// Returns a properly typed object based on the keys you pass in
// e.g. const { loadCurriculumProcessing, loadCurriculumError, stateProcessing, stateError } = mapStateProcessingAndError('loadCurriculum')
// Currently properly supports up to 4 keys - add another overload if you need more
export function mapStateProcessingAndError<K1 extends ActionKey>(
  key: K1
): Record<`${K1}Processing` | `${K1}Error` | 'stateProcessing' | 'stateError', ComputedRef<boolean>>;
export function mapStateProcessingAndError<K1 extends ActionKey, K2 extends ActionKey>(
  key1: K1,
  key2: K2
): Record<
  `${K1}Processing` | `${K2}Processing` | `${K1}Error` | `${K2}Error` | 'stateProcessing' | 'stateError',
  ComputedRef<boolean>
>;
export function mapStateProcessingAndError<K1 extends ActionKey, K2 extends ActionKey, K3 extends ActionKey>(
  key1: K1,
  key2: K2,
  key3: K3
): Record<
  | `${K1}Processing`
  | `${K2}Processing`
  | `${K3}Processing`
  | `${K1}Error`
  | `${K2}Error`
  | `${K3}Error`
  | 'stateProcessing'
  | 'stateError',
  ComputedRef<boolean>
>;
export function mapStateProcessingAndError<
  K1 extends ActionKey,
  K2 extends ActionKey,
  K3 extends ActionKey,
  K4 extends ActionKey
>(
  key1: K1,
  key2: K2,
  key3: K3,
  key4: K4
): Record<
  | `${K1}Processing`
  | `${K2}Processing`
  | `${K3}Processing`
  | `${K4}Processing`
  | `${K1}Error`
  | `${K2}Error`
  | `${K3}Error`
  | `${K4}Error`
  | 'stateProcessing'
  | 'stateError',
  ComputedRef<boolean>
>;
export function mapStateProcessingAndError(...keys: ActionKey[]) {
  return {
    ...mapStateProcessing(...keys),
    ...mapStateError(...keys),
  };
}
