import config from './config';
import type { Rule } from './validations';
import type { User } from '@/store/map-store';
import type { Api } from '@/composables/api';

// The methods here should move to a composable (so that the methods don't neeed to accept an Api or current User)
// That relies on the consumers using the composition API

export async function isPasswordStrongEnough(api: Api, user: User | null, value: string) {
  if (!isPasswordStrengthEnabled(user)) return true;
  return isPasswordLongEnough(value) && !(await isPasswordCommonlyUsed(api, value));
}

export async function isPasswordStemCommonlyUsed(api: Api, stem: string) {
  const [instructor, student] = await Promise.all([
    isPasswordCommonlyUsed(api, `${stem}.instructor`),
    isPasswordCommonlyUsed(api, `${stem}.student`),
  ]);
  return instructor || student;
}

export async function generateStongPassword(api: Api, user: User | null): Promise<string> {
  let password = '';
  do {
    password = readableRandomStringMaker(12);
  } while (!(await isPasswordStrongEnough(api, user, password)));
  return password;
}

function readableRandomStringMaker(length: number) {
  let s = '';
  for (
    ;
    s.length < length;
    s += 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'.charAt((Math.random() * 62) | 0)
  );
  return s;
}

export function isPasswordStrengthEnabled(user: User | null) {
  return config.enforcePasswordStrength && config.isPasswordStrengthTurnedOnForCurrentUser(user);
}

export function isPasswordLongEnough(value: string) {
  return value.length >= 12;
}

export async function isPasswordCommonlyUsed(api: Api, value: string) {
  const hash = await calculateSha1Hash(value);
  let r = null;
  try {
    r = await api.getWithoutAuth<string>(`https://api.pwnedpasswords.com/range/${hash.slice(0, 5)}`);
  } catch (e) {
    console.log('Calling pwned passwords API failed', e);
    // Allow setting of passwords if pwned passwords is down
    // The 'authorise' guard method will automatically heal this later
    return false;
  }
  const data = r.data.split('\r\n');
  // data is of the format ['HASH_SUFFIX:COUNT', 'HASH_SUFFIX:COUNT']
  const hashSuffix = hash.toUpperCase().slice(5);
  const hashWithCount = data.find((x: string) => x.startsWith(hashSuffix));
  return !!hashWithCount;
}

async function calculateSha1Hash(value: string) {
  const encoder = new TextEncoder();
  const data = encoder.encode(value);
  const hashBuffer = await crypto.subtle.digest('SHA-1', data);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
  return hashHex;
}

export function passwordRules(user: User | null): Rule[] {
  return isPasswordStrengthEnabled(user)
    ? [
        (v: string | number): string | boolean => {
          if (typeof v === 'number') return false;
          return (v && v.length >= 12) || 'Password should be at least 12 characters';
        },
      ]
    : [];
}
