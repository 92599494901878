import XLSX from 'xlsx';
import type { Column } from './ect-registration-headers';
import { getHeaders } from './ect-registration-headers';
import moment from 'moment';
export interface EctRegistrationApiResponse {
  institutionEctPackage: string;
  registrationSchool: {
    name: string;
    settingType: string;
    mat: string;
    urn: string;
    addressFirstLine: string;
    addressSecondLine: string;
    addressCity: string;
    addressPostcode: string;
    addressCountry: string;
    localAuthority: string;
    phoneNumber: string;
    financeEmail: string;
    financeLeadName: string;
  };
  schoolDetailsConfirmed: boolean;
  schoolDetailChanges: string;
  name: string;
  email: string;
  dateOfBirth: string;
  trn: string;
  dateAwardedQts: string;
  startDate: string;
  status: string;
  startTermsAlreadyCompleted: number;
  priorReviewsObtained: boolean;
  previousInductionDetails: string;
  startFteRate: number;
  contractType: string;
  contractStartDate?: string;
  contractEndDate?: string;
  contractDetails?: string;
  yearGroups: string[];
  teachingSubjects: string[];
  ecfProvider: string;
  teacherTrainingInstitution: string;
  qualificationsAwarded: string[];
  qualifiedSubjects: string[];
  qualifiedAgeRanges: string[];
  ecfProgramme: string;
  ecfTermsCompleted?: number;
  headteacherName: string | null | undefined;
  headteacherEmail: string | null | undefined;
  inductionTutorName: string | null | undefined;
  inductionTutorEmail: string | null | undefined;
  mentorName: string;
  mentorEmail: string;
  mentorJobTitle: string;
  mentorTrn: string;
  mentorTrainingDetails: string;
  coordinatorName: string;
  coordinatorEmail: string;
  coordinatorJobTitle: string;
  registeringEmail: string;
  slaAgreed: boolean;
  marketingAgreed: boolean;
  registeringName: string;
  registeringRole: string;
  registrationDateTime: string;
  invoiceRef1: string;
  invoiceRef2: string;
  ecfCohort: string;
}

function registrationData(
  registrations: EctRegistrationApiResponse[],
  exportType: 'full' | 'ecf',
  includedPackageTypes: string[],
  includeApproved: boolean,
  registrationsFromDate: string,
  registrationsToDate: string
): string[][] {
  const excludeNonEcfFields = exportType === 'ecf';
  const filteredHeaders: Column[] = getHeaders().filter(h => !(h.excludeFromEcfExport && excludeNonEcfFields));
  const registrationData: string[][] = [];
  registrationData.push(filteredHeaders.map(h => h.header));
  registrations
    .filter(r => {
      return (
        includedPackageTypes.includes(r.institutionEctPackage) &&
        (includeApproved || r.status !== 'active') &&
        (!registrationsFromDate || moment(r.registrationDateTime).isSameOrAfter(registrationsFromDate)) &&
        (!registrationsToDate || moment(r.registrationDateTime).isSameOrBefore(registrationsToDate))
      );
    })
    .forEach(r => {
      registrationData.push(
        filteredHeaders.map(h => {
          // What I really want is this:
          // const d = h.data(data);
          // h.dataTransform(d);

          if (h.type === 'string') {
            const d = h.data(r);
            return h.dataTransform(d);
          } else if (h.type === 'string[]') {
            const d = h.data(r);
            return h.dataTransform(d);
          } else if (h.type === 'boolean') {
            const d = h.data(r);
            return h.dataTransform(d);
          } else if (h.type === 'alwaysYes') {
            return 'Yes';
          } else if (h.type === 'nullOrString') {
            const d = h.data(r);
            return h.dataTransform(d);
          } else if (h.type === 'nullOrNumber') {
            const d = h.data(r);
            return h.dataTransform(d);
          } else if (h.type === 'nullOrBoolean') {
            const d = h.data(r);
            return h.dataTransform(d);
          } else {
            const d = h.data(r);
            return h.dataTransform(d);
          }
        })
      );
    });
  return registrationData;
}

export function exportEctRegistrations(
  registrations: EctRegistrationApiResponse[],
  type: 'full' | 'ecf',
  includedPackageTypes: string[],
  includeApproved: boolean,
  registrationsFromDate: string,
  registrationsToDate: string
) {
  const formattedData: string[][] = registrationData(
    registrations,
    type,
    includedPackageTypes,
    includeApproved,
    registrationsFromDate,
    registrationsToDate
  );
  const xlxs = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(xlxs, XLSX.utils.aoa_to_sheet(formattedData), `ECT registrations`);
  XLSX.writeFile(xlxs, `ECT Registrations (${moment().format('DD-MM-YYYY')}).xlsx`);
}
