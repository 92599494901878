<template>
  <mosaic-loading-and-error-cards
    v-model:trigger-background-load="internalTriggerBackgroundLoad"
    :object-type="objectType"
    :object-type-is-plural="objectTypeIsPlural"
    :load="load"
    :loading-type="type"
  >
    <mosaic-card>
      <slot name="title" v-if="$slots.title"></slot>
      <mosaic-card-title v-else :edit="edit">
        <span>{{ titleInternal }}</span>
        <template #chip><slot name="chip"></slot></template>
        <template #subtitle v-if="$slots.subtitle"><slot name="subtitle"></slot></template>
        <template #actions><slot name="actions"></slot></template>
        <template #info><slot name="info"></slot></template>
      </mosaic-card-title>
      <v-divider class="mt-4" />

      <mosaic-tabs :headers="headers" @tab-selected="emit('tab-selected', $event)">
        <template #shared-filters><slot name="shared-filters"></slot></template>
        <template v-for="h in headers" #[h.key]>
          <slot :name="`${h.key}-tab-item`"></slot>
        </template>
      </mosaic-tabs>
    </mosaic-card>
  </mosaic-loading-and-error-cards>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import MosaicTabs from '../mosaic-card/MosaicTabs.vue';
import type { LoadingType } from '../loading/MosaicLoadingCard.vue';

const props = withDefaults(
  defineProps<{
    objectType: string;
    objectTypeIsPlural?: boolean;
    load?: () => Promise<void>;
    title?: string;
    type?: LoadingType;
    triggerBackgroundLoad?: boolean;
    edit?: () => void;
    headers: {
      key: string;
      text: string;
      dirty?: boolean;
    }[];
  }>(),
  {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    load: async () => {},
  }
);

const emit = defineEmits<{
  (e: 'update:triggerBackgroundLoad', triggerBackgroundLoad: boolean): void;
  (e: 'tab-selected', selectedTab: string): void;
}>();

const internalTriggerBackgroundLoad = ref(false);
watch(
  () => props.triggerBackgroundLoad,
  x => (internalTriggerBackgroundLoad.value = !!x)
);
watch(internalTriggerBackgroundLoad, x => emit('update:triggerBackgroundLoad', x));

const titleInternal = computed(() => props.title || props.objectType);
</script>
