<template>
  <v-tooltip location="bottom">
    <template #activator="{ props }">
      <div v-bind="props">
        <v-btn
          :color="color"
          class="ma-0"
          icon
          variant="text"
          :disabled="disabled"
          @click.stop="$emit('click', $event)"
          :active="active"
        >
          <mosaic-icon :color="iconColor" :size="size" :icon="icon" />
        </v-btn>
      </div>
    </template>
    <div style="max-width: 300px" class="text-center">{{ tooltip }}</div>
  </v-tooltip>
</template>

<script>
// Deprecated: prefer MosaicIconButton / mosaic-icon-btn
export default {
  name: 'NdtIconButton',
  props: {
    icon: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      default: '',
    },
    iconColor: {
      type: String,
    },
    small: Boolean,
    medium: Boolean,
    large: Boolean,
    xlarge: Boolean,
  },
  emits: ['click'],
  computed: {
    size() {
      return this.small ? 'small' : this.large ? 'large' : this.xlarge ? 'xlarge' : 'default';
    },
  },
};
</script>
