<template>
  <v-card elevation="4">
    <v-toolbar color="white" elevation="6">
      <v-toolbar-title v-if="!verificationEmailSent">Verify your Email</v-toolbar-title>
      <v-toolbar-title v-else>Check your Email</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <mosaic-error-alert :override-error-message="error" />
      <mosaic-alert v-if="success" type="success"> Verification email sent </mosaic-alert>
      <template v-if="!verificationEmailSent">
        <div class="my-2">
          Mosaic has not yet verified your email (or your email has been changed since it was last verified).
        </div>
        <div class="mb-2">
          To verify your email:
          <ul class="pl-6">
            <li>press "Send Email" below</li>
            <li>click the link in the email Mosaic sends you</li>
            <li>this page will automatically detect the verification and redirect you</li>
          </ul>
        </div>
        <div>
          Your email is
          <b>{{ user.email }}</b
          >. If you don't have access to this email then get in touch with your {{ institutionNoun
          }}<span v-if="user.singleInstitution && user.singleInstitution.contact_email">
            on

            <a :href="`mailto:${user.singleInstitution.contact_email}?subject=Unable to verify ${user.email}`">{{
              user.singleInstitution.contact_email
            }}</a
            >.</span
          >
          <span v-else>.</span>
        </div>
      </template>
      <template v-else>
        <div class="mb-2">
          Mosaic has just sent a verification email to
          <b>{{ user.email }}</b
          >, please click the link in the email.
        </div>
        <div class="mb-2">If you did not receive the verification email, then please check your junk/spam folder.</div>
        <div class="mb-2">
          Email not in your junk/spam folder? Get in touch with
          <a :href="`mailto:support@penrose.education?subject=Unable to verify ${user.email}`"
            >support@penrose.education</a
          >.
        </div>
        <div>
          Don't have access to this email account? Get in touch with your
          {{ institutionNoun
          }}<span v-if="user.singleInstitution && user.singleInstitution.contact_email">
            on

            <a :href="`mailto:${user.singleInstitution.contact_email}?subject=Unable to verify ${user.email}`">{{
              user.singleInstitution.contact_email
            }}</a
            >.</span
          >
          <span v-else>.</span>
        </div>
      </template>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn class="mx-2" :disabled="processing" @click.prevent="submit"
        >{{ !verificationEmailSent ? 'Send' : 'Resend' }} Verification Email</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { routeToNextStep } from '../utils/auth';

export default {
  name: 'VerifyEmailPage',
  data: function () {
    return {
      processing: false,
      error: '',
      nextStep: '',
      verifyEmailPollInterval: null,
      verificationEmailSent: false,
      success: false,
    };
  },
  computed: {
    ...mapState(['user']),
    ...mapGetters(['institutionNoun']),
  },
  // Think this will change with vue 3
  beforeUnmount() {
    clearInterval(this.verifyEmailPollInterval);
  },
  methods: {
    async submit() {
      this.error = '';
      this.processing = true;
      this.success = false;
      try {
        const r = await this.$api.post('users/current/verify-email', {});
        this.nextStep = r.data.next_step;
        this.verificationEmailSent = true;
        this.success = true;
        this.pollForEmailVerified();
      } catch (e) {
        if (e.response?.status === 429) {
          const waitTime = Math.ceil(e.response.data.wait_time_in_seconds);
          if (waitTime === 0) {
            this.error = 'Please try requesting another email';
          } else if (waitTime) {
            this.error = ` Please wait ${waitTime} second${waitTime === 1 ? '' : 's'} before requesting another email`;
          }
        } else if (e.response?.status === 422 && e.response.data.error_code === 'email_bounced') {
          this.error = 'Cannot deliver to your email address. Please get in touch with support@penrose.education.';

          if (this.verifyEmailPollInterval) {
            clearInterval(this.verifyEmailPollInterval);
          }
        } else {
          console.log(e);
          this.error = `Sorry, cannot verify your email at the moment. Please check your internet and, if you're still having issues, send an email to support@penrose.education.`;
        }
      }
      this.processing = false;
    },
    pollForEmailVerified() {
      this.verifyEmailPollInterval = setInterval(async () => {
        const r = await this.$api.get('users/current/slim');
        if (r.data.emailVerified) {
          routeToNextStep(this.nextStep, this.$router, this.$route);
          clearInterval(this.verifyEmailPollInterval);
        }
      }, 5000);
    },
  },
};
</script>
