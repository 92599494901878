<template>
  <v-card elevation="4">
    <v-toolbar color="white" elevation="6">
      <v-toolbar-title>No Mosaic Account Found</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <div>
        You have successfully signed in with the Microsoft account <b>{{ $route.query.email }}</b
        >, but there is no Mosaic account with that email address.
      </div>
      <div class="pt-2 pb-4">
        If you think there should be a Mosaic account with that email address then get in touch with your course leads.
      </div>
      <div class="d-flex justify-end">
        <v-btn color="primary" :to="{ name: 'LoginPage' }">Back to login</v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'SSOMicrosoftErrorPage',
};
</script>
