export function capitaliseFirstLetters(phrase: string) {
  if (!phrase) return '';
  return phrase
    .trim()
    .split(' ')
    .map(word => capitaliseFirstLetter(word))
    .join(' ');
}

export function renderFriendlyText(text: string, friendlyTextMap: Record<string, string>) {
  if (!text) return '';
  return text.replace(/\{\{.*?\}\}/g, match => friendlyTextMap[match.substring(2, match.length - 2).trim()]);
}

export function capitaliseFirstLetter(word: string) {
  if (!word) return '';
  return word[0].toUpperCase() + word.substring(1);
}

export function initialiseText(text: string) {
  if (!text) return '';
  return text
    .trim()
    .split(' ')
    .map(x => getFirstAlphanumbericCharacter(x))
    .join('');
}

function getFirstAlphanumbericCharacter(word: string) {
  for (const c of word) {
    if (c.match(/[a-zA-z0-9]/)) return c;
  }
  return word.charAt(0);
}

export function addArticle(phrase: string) {
  if (!phrase) return '';
  if (['a', 'e', 'i', 'o', 'u'].includes(phrase[0].toLowerCase())) {
    return `an ${phrase}`;
  }
  return `a ${phrase}`;
}

export function pluralise(word: string) {
  // Supports some irregular pluralisations (y -> ies, staff -> staff)
  // Also supports pluralising words with brackets at the end (but only at the end!)
  let wordWithoutBrackets = word;
  let bracketsFragment = '';
  if (word[word.length - 1] == ')') {
    const lastOpenBracketIndex = word.lastIndexOf('(');
    if (lastOpenBracketIndex !== -1) {
      wordWithoutBrackets = word.slice(0, lastOpenBracketIndex).trim();
      bracketsFragment = ' ' + word.slice(lastOpenBracketIndex).trim();
    }
  }

  if (wordWithoutBrackets[wordWithoutBrackets.length - 1] == 'y') {
    return wordWithoutBrackets.slice(0, wordWithoutBrackets.length - 1) + 'ies' + bracketsFragment;
  }
  if (wordWithoutBrackets.endsWith('staff') || wordWithoutBrackets.endsWith('Staff')) {
    return wordWithoutBrackets + bracketsFragment;
  }
  return wordWithoutBrackets + 's' + bracketsFragment;
}

export function pluraliseItemsName(count: number, objectType: string, objectTypePlural?: string) {
  const plural = objectTypePlural || pluralise(objectType);
  return count === 1 ? objectType : plural;
}

export function enumerateItems(count: number, objectType: string, objectTypePlural?: string) {
  const enumerator = count > 0 ? count : 'No';
  return `${enumerator} ${pluraliseItemsName(count, objectType, objectTypePlural)}`;
}

export function listItems(items: string[]) {
  if (items.length === 0) return '';
  if (items.length === 1) return items[0];
  const lastItem = items.pop();
  return `${items.join(', ')} and ${lastItem}`;
}

export function countObjectTypeHas(count: number, objectType: string, objectTypePlural?: string) {
  return `${enumerateItems(count, objectType, objectTypePlural)} ${count === 1 ? 'has' : 'have'}`;
}

export function split(name: string): string[] {
  return name.trim().split(/\s+/);
}

export function firstName(name: string): string {
  if (!name) return '';
  return split(name)[0];
}

export function lastName(name: string): string {
  if (!name) return '';
  return name.substr(name.indexOf(' ') + 1);
}

export function ordinalSuffixedNumber(i: number): string {
  const j = i % 10;
  const k = i % 100;
  if (j === 1 && k !== 11) {
    return `${i}st`;
  }
  if (j === 2 && k !== 12) {
    return `${i}nd`;
  }
  if (j === 3 && k !== 13) {
    return `${i}rd`;
  }
  return `${i}th`;
}

export function formatTwoDigits(n: number) {
  return String(n).padStart(2, '0');
}
