<template>
  <div>
    <mosaic-configure-preview-page
      v-model:trigger-background-load="triggerBackgroundLoad"
      v-model="configurePreview"
      :object-type="`Training Plan ${cohortCourseWeekNounCapitalised} Section`"
      :load="load"
      :error="weekSectionError"
    >
      <template #configure>
        <mosaic-save-card
          :save="save"
          :object-type="`Training Plan ${cohortCourseWeekNounCapitalised} Section`"
          :can-save="canSave"
          :hide-return="true"
          :readonly="false"
          :return-to="{
            name: 'TutorAdminCohortCourseWeekPage',
            params: { id: $route.params.weekId },
          }"
        >
          <div class="d-flex">
            <mosaic-card-title class="flex-grow-1" :edit="editThisSection"
              >{{ weekSection?.title }} of {{ cohortCourseWeekNounCapitalised }} Template
              <template #chip>
                <div class="d-flex align-center">
                  <v-chip v-if="weekSection?.subjectId">{{ weekSection?.subjectName }} Only</v-chip>
                </div>
              </template>
            </mosaic-card-title>
            <v-btn
              class="ml-2"
              :disabled="!previousSectionId"
              @click="previousSectionId ? changeSection(previousSectionId) : undefined"
              ><v-icon>mdi-chevron-left</v-icon></v-btn
            >
            <v-btn
              class="ml-2"
              :disabled="!nextSectionId"
              @click="nextSectionId ? changeSection(nextSectionId) : undefined"
              ><v-icon>mdi-chevron-right</v-icon></v-btn
            >
          </div>
          <!-- Probably want section navigation like with assignments -->
          <div class="text-h6 mt-4">Content</div>
          <mosaic-content-layout-builder
            v-model:content-layout="nullableContentLayout"
            :editable="true"
            :get-resource-url-prefix="`/cohort-course/week-template-sections/resources`"
            :presign-url="`/presign/cohorts/${selectedCohort.id}/cohort-course-resources`"
            :save-resource-url="`/cohorts/${selectedCohort.id}/cohort-course/week-template-sections/resources`"
            :subject-ids="weekSection && weekSection.subjectId ? [weekSection.subjectId] : []"
            :save-resource-params="{ id: weekSection?.id }"
            :supported-item-types="[
              'text',
              'resource',
              'comment',
              'taskList',
              'video',
              'youtube',
              'curriculumLink',
              'link',
              'image',
              'columns',
            ]"
            @valid="contentLayoutValid = $event"
            @click-curriculum-statement="clickCurriculumStatement($event)"
          />
        </mosaic-save-card>
      </template>

      <template #preview>
        <mosaic-multi-section-card
          v-if="selectedCohortCourse && contentLayout"
          :title="`${cohortCourseWeekNounCapitalised} Template`"
          :sections="weekTemplateSectionsWithCompletion"
          :section-id="sectionId"
          :can-save="false"
          :save="previewSave"
          :used-with-configure-preview="true"
          :object-type="cohortCourseWeekNounCapitalised"
          hide-buttons
          ><mosaic-content-layout
            :comments="comments"
            :tasks="tasks"
            :content-layout="contentLayout"
            get-resource-url-prefix="/cohort-course/week-template-sections/resources"
            @click-curriculum-statement="clickCurriculumStatement($event)"
        /></mosaic-multi-section-card>
      </template>
    </mosaic-configure-preview-page>
    <unsaved-changes-dialog
      v-model:unsaved-changes-dialog="dialog"
      object-type="Training Plan Week Section"
      is-template-section
      :save="save"
    />
    <cohort-course-week-section-edit-dialog
      v-if="nullableContentLayout"
      v-model:active="editSectionDialog.active"
      :section="editSectionDialog.section"
      :content-layout="contentLayout"
      @save="load(true)"
      is-template-section
    />
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watchEffect } from 'vue';
import { useRouter } from 'vue-router';
import { useApi } from '@/composables/api';
import { useCohortCourseStore } from '@/stores/cohort-course';
import { useUnsavedChanges } from '@/composables/unsaved-changes';
import { notNullableRef } from '@/composables/vue';
import { parseRouteId } from '@/composables/vue-router';
import { mapState, type CurriculumStatement } from '@/store/map-store';
import { useEditWeekSectionDetails } from '@/stores/cohort-course';
import type { ContentLayout } from '@/utils/content-layout';
import UnsavedChangesDialog from '@/components/UnsavedChangesDialog.vue';
import MosaicContentLayoutBuilder from '@/components/mosaic-content-layout/MosaicContentLayoutBuilder.vue';
import { mapContentLayoutToCompletionComments, mapContentLayoutToCompletionTasks } from '@/utils/content-layout';
import { setBreadcrumbs } from '@/utils/breadcrumbs';
import MosaicContentLayout from '@/components/mosaic-content-layout/MosaicContentLayout.vue';
import CohortCourseWeekSectionEditDialog from './CohortCourseWeekSectionEditDialog.vue';

const api = useApi();
const { selectedCohort, selectedInstitution } = mapState();

const {
  selectedCohortCourse,
  cohortCourseWeekNounCapitalised,
  actions: { loadCohortCourse },
} = useCohortCourseStore();

const sectionId = parseRouteId('sectionId');
const weekTemplateSections = computed(() => selectedCohortCourse.value?.cohortCourseWeekTemplateSections || []);
const weekTemplateSectionsWithCompletion = computed(() => {
  return weekTemplateSections.value.map(s => ({
    ...s,
    name: s.title,
    completed: false,
    readonly: false,
    disabled: false,
  }));
});
const weekSection = computed(() => weekTemplateSections.value.find(s => s.id === sectionId.value));

const nullableContentLayout = ref<ContentLayout | null>(null);
const contentLayout = notNullableRef(nullableContentLayout, 'contentLayout');

const weekSectionError = ref(false);
watchEffect(() => {
  if (weekSection.value) {
    weekSectionError.value = false;
    nullableContentLayout.value = weekSection.value.contentLayout;
  } else {
    weekSectionError.value = true;
  }
});
const configurePreview = ref<'configure' | 'preview'>('configure');

interface WeekSection {
  id: number;
  title: string;
  order: number;
  subjectId: number | null;
  contentLayout: ContentLayout;
}

const nextSectionId = computed(() => {
  if (!weekSection.value || !selectedCohortCourse) return null;
  return weekTemplateSections.value.find(s => s.order === weekSection.value!.order + 1)?.id;
});
const previousSectionId = computed(() => {
  if (!weekSection.value || !selectedCohortCourse) return null;
  return weekTemplateSections.value.find(s => s.order === weekSection.value!.order - 1)?.id;
});

function clickCurriculumStatement(statement: CurriculumStatement) {
  const route = selectedCohort.value.has_curriculum
    ? {
        name: 'CohortAdminCurriculumStatementPage',
        params: {
          cohortId: selectedCohort.value.id,
          themeId: statement.theme_id,
          statementId: statement.id,
        },
      }
    : {
        name: 'InstAdminCurriculumStatementPage',
        params: {
          institutionId: selectedInstitution.value.id,
          themeId: statement.theme_id,
          statementId: statement.id,
        },
      };
  router.push(route);
}

//#region load
const triggerBackgroundLoad = ref(false);

const load = async (force?: boolean) => {
  await loadCohortCourse(force);
};

//#endregion

//#region save
const contentLayoutValid = ref(false);
const canSave = computed(() => contentLayoutValid.value && dirty.value);

const save = async () => {
  if (!weekSection.value) return;
  await api.put<unknown, WeekSection>(`/cohort-course/week-template-sections/${weekSection.value.id}/content`, {
    contentLayout: contentLayout.value,
  });
  triggerBackgroundLoad.value = true;
};
const dirty = computed<boolean>(() => {
  return JSON.stringify(nullableContentLayout.value) !== JSON.stringify(weekSection.value?.contentLayout);
});
const { dialog } = useUnsavedChanges(dirty);

//#endregion

//#region preview
const router = useRouter();
function changeSection(sectionId: number) {
  router.push({
    name: 'TutorAdminCohortCourseWeekTemplateSectionPage',
    params: {
      sectionId: sectionId.toString(),
    },
    query: {
      preview: configurePreview.value,
    },
  });
}
async function previewSave(): Promise<void> {
  return;
}

const comments = computed(() => mapContentLayoutToCompletionComments(contentLayout.value, id => ({ templateId: id })));
const tasks = computed(() => mapContentLayoutToCompletionTasks(contentLayout.value, id => ({ templateId: id })));

//#region edit section details
const { editSectionDialog, editSection } = useEditWeekSectionDetails();
const editThisSection = () => {
  editSection(
    weekSection.value || {
      id: -1,
      title: '',
      subjectId: null,
      order: 0,
      subjectName: null,
      contentLayout: { sections: [] },
    }
  );
};

//#endregion

setBreadcrumbs(
  computed(() => [
    {
      text: 'Training Plan',
      to: {
        name: 'TutorAdminCohortCoursePage',
      },
    },
    {
      text: `${cohortCourseWeekNounCapitalised.value} Template`,
      to: {
        name: 'TutorAdminCohortCourseWeekTemplatePage',
      },
    },
    {
      text: weekSection.value?.title || '',
    },
  ])
);
//#endregion
</script>
