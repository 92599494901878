<template>
  <div style="white-space: nowrap">
    <v-chip
      style="border-top-right-radius: 0px; border-bottom-right-radius: 0px"
      class="pr-1"
      v-on="onClick ? { click: onClick } : {}"
    >
      <v-icon class="mr-1">mdi-human-male-board</v-icon>
      <span>{{ staff.name || staff.email }}</span> </v-chip
    ><v-chip
      style="border-top-left-radius: 0px; border-bottom-left-radius: 0px"
      class="pl-2"
      @click.prevent="copyEmail"
    >
      <v-tooltip location="top">
        <template #activator="{ props }">
          <v-icon size="small" v-bind="props">mdi-content-copy</v-icon>
        </template>
        <span>Copy email</span>
      </v-tooltip>
    </v-chip>
  </div>
</template>

<script>
export default {
  name: 'MosaicStaffBadge',
  props: {
    staff: {
      type: Object,
      required: true,
    },
    onClick: {
      type: Function,
      required: false,
    },
  },
  methods: {
    copyEmail() {
      navigator.clipboard.writeText(this.staff.email);
    },
  },
};
</script>
