<template>
  <student-files-list-page v-if="isStudent" />
  <tutor-student-files-list-page v-else />
</template>

<script setup lang="ts">
import { useUserStore } from '@/stores/user';
import StudentFilesListPage from './StudentFilesListPage.vue';
import TutorStudentFilesListPage from './TutorStudentFilesListPage.vue';

const { isStudent } = useUserStore();
</script>
