<template>
  <div>
    <mosaic-preview-subject-sync-query v-model:subject-id="selectedPreviewSubjectId" />
    <mosaic-configure-preview-page
      v-model:trigger-background-load="triggerBackgroundLoad"
      v-model="configurePreview"
      object-type="Training Plan Week Section"
      :load="load"
      :error="weekSectionError"
    >
      <template #preview-as>
        <div class="d-flex align-center">
          <mosaic-select
            class="pr-2"
            v-model="selectedPreviewSubjectId"
            name="add-section-subject"
            label="Subject"
            :items="subjects"
            item-title="name"
            item-value="id"
            prepend-icon="mdi-pencil-ruler"
            ><template #item="{ item, props }">
              <v-divider v-if="item.raw.divider" />
              <v-list-item v-else v-bind="props" :title="item.raw.name" /> </template
          ></mosaic-select>
          <mosaic-help
            >Selecting a Subject will show the {{ cohortCourseWeekNounCapitalised }} as it will appear to a
            {{ traineeNounCapitalised }} linked to that Subject</mosaic-help
          >
        </div>
      </template>
      <template #configure>
        <mosaic-save-card
          :save="save"
          object-type="Training Plan Week Section"
          :can-save="canSave"
          :hide-return="true"
          :readonly="published"
          :return-to="{
            name: 'TutorAdminCohortCourseWeekPage',
            params: { id: $route.params.weekId },
          }"
        >
          <mosaic-card-title v-if="weekSection" class="flex-grow-1" :edit="editThisSection"
            >{{ weekSection?.title }} of {{ week?.name }}
            <template #chip>
              <div class="d-flex align-center">
                <v-chip v-if="weekSection.subjectId">{{ weekSection.subjectName }} Only</v-chip>
                <mosaic-published-draft-chip
                  class="ml-2"
                  mode="card"
                  :published="published"
                  :object-type="cohortCourseWeekNounCapitalised"
                  published-phrase="published"
                />
              </div>
            </template>
            <template #actions
              ><v-btn
                class="ml-2"
                :disabled="!previousSectionId"
                @click="previousSectionId ? changeSection(previousSectionId) : undefined"
                ><v-icon>mdi-chevron-left</v-icon></v-btn
              >
              <v-btn
                class="ml-2"
                :disabled="!nextSectionId"
                @click="nextSectionId ? changeSection(nextSectionId) : undefined"
                ><v-icon>mdi-chevron-right</v-icon></v-btn
              ></template
            >
          </mosaic-card-title>

          <div class="text-h6 mt-4">Content</div>
          <mosaic-content-layout-builder
            v-model:content-layout="nullableContentLayout"
            :editable="!published"
            get-resource-url-prefix="/cohort-course/week-sections/resources"
            :presign-url="`/presign/cohorts/${selectedCohort.id}/cohort-course-resources`"
            :save-resource-url="`/cohorts/${selectedCohort.id}/cohort-course/week-sections/resources`"
            :subject-ids="weekSection && weekSection.subjectId ? [weekSection.subjectId] : []"
            :save-resource-params="{ id: weekSection?.id }"
            :supported-item-types="[
              'text',
              'resource',
              'comment',
              'taskList',
              'video',
              'youtube',
              'curriculumLink',
              'link',
              'image',
              'columns',
            ]"
            @valid="contentLayoutValid = $event"
            @click-curriculum-statement="clickCurriculumStatement($event)"
          />
        </mosaic-save-card>
      </template>

      <template #preview>
        <cohort-course-week-card
          v-if="previewStudentWeek"
          :student-week="previewStudentWeek"
          :section-id="sectionId"
          :used-with-configure-preview="true"
          :preview="true"
          @update:section-id="changeSection($event)"
          @click-section="changeSection($event)"
          @click-curriculum-statement="clickCurriculumStatement($event)"
        />
      </template>
    </mosaic-configure-preview-page>
    <unsaved-changes-dialog
      v-model:unsaved-changes-dialog="dialog"
      object-type="Training Plan Week Section"
      :save="save"
    />
    <cohort-course-week-section-edit-dialog
      v-if="nullableContentLayout"
      v-model:active="editSectionDialog.active"
      :section="editSectionDialog.section"
      :content-layout="contentLayout"
    />
  </div>
</template>

<script setup lang="ts">
import type { StudentWeek } from '@/stores/cohort-course';
import { parseRouteId } from '@/composables/vue-router';
import { setBreadcrumbs } from '@/utils/breadcrumbs';
import { ref, computed, watch } from 'vue';
import { useApi } from '@/composables/api';
import { useRoute, useRouter } from 'vue-router';
import { useUnsavedChanges } from '@/composables/unsaved-changes';
import { mapState, mapGetters, mapActions, type CurriculumStatement } from '@/store/map-store';
import type { ContentLayout } from '@/utils/content-layout';

import { mapContentLayoutToCompletionComments, mapContentLayoutToCompletionTasks } from '@/utils/content-layout';
import { notNullableRef } from '@/composables/vue';
import MosaicContentLayoutBuilder from '@/components/mosaic-content-layout/MosaicContentLayoutBuilder.vue';
import UnsavedChangesDialog from '@/components/UnsavedChangesDialog.vue';
import CohortCourseWeekCard from './CohortCourseWeekCard.vue';
import MosaicPreviewSubjectSyncQuery from './MosaicPreviewSubjectSyncQuery.vue';
import { useCohortCourseStore, useEditWeekSectionDetails, useCohortCourseWeekStore } from '@/stores/cohort-course';
import CohortCourseWeekSectionEditDialog from './CohortCourseWeekSectionEditDialog.vue';

import type { ConfigurePreview } from '@/components/library/configure-preview/configure-preview';

const api = useApi();
const route = useRoute();
const { selectedCohort, selectedInstitution } = mapState();
const { subjectsWithNullOptionAndPrimarySeparation: subjects } = mapGetters();
const { loadSubjects } = mapActions();
loadSubjects();

const {
  selectedCohortCourse,
  cohortCourseWeekNounCapitalised,
  actions: { loadCohortCourse },
} = useCohortCourseStore();

const {
  week,
  actions: { loadCohortCourseWeek },
} = useCohortCourseWeekStore();
const weekId = parseRouteId('weekId');
const termId = parseRouteId('termId');

const term = computed(() => selectedCohortCourse.value?.cohortCourseTerms.find(x => x.id === termId.value));
const sectionId = parseRouteId('sectionId');
const weekSection = computed(() => week.value?.cohortCourseWeekSections.find(s => s.id === sectionId.value));

const published = computed(() => week.value?.status === 'published');

const nullableContentLayout = ref<ContentLayout | null>(weekSection.value ? weekSection.value.contentLayout : null);
const contentLayout = notNullableRef(nullableContentLayout, 'contentLayout');

const weekSectionError = ref(false);
watch(weekSection, () => {
  if (weekSection.value) {
    weekSectionError.value = false;
    nullableContentLayout.value = weekSection.value.contentLayout;
  } else {
    weekSectionError.value = true;
  }
});
const configurePreview = ref<ConfigurePreview>(null);

interface WeekSection {
  id: number;
  title: string;
  order: number;
  subjectName: string | null;
  subjectId: number | null;
  contentLayout: ContentLayout;
}

const nextSectionId = computed(() => {
  if (!weekSection.value || !week.value) return null;
  return week.value?.cohortCourseWeekSections.find(s => s.order === weekSection.value!.order + 1)?.id;
});
const previousSectionId = computed(() => {
  if (!week.value) return null;
  if (!weekSection.value) return null;
  return week.value?.cohortCourseWeekSections.find(s => s.order === weekSection.value!.order - 1)?.id;
});

function clickCurriculumStatement(statement: CurriculumStatement) {
  const route = selectedCohort.value.has_curriculum
    ? {
        name: 'CohortAdminCurriculumStatementPage',
        params: {
          cohortId: selectedCohort.value.id,
          themeId: statement.theme_id,
          statementId: statement.id,
        },
      }
    : {
        name: 'InstAdminCurriculumStatementPage',
        params: {
          institutionId: selectedInstitution.value.id,
          themeId: statement.theme_id,
          statementId: statement.id,
        },
      };
  router.push(route);
}

//#region load
const triggerBackgroundLoad = ref(false);

async function load() {
  await loadCohortCourse();
  await loadWeek();
}

async function loadWeek() {
  await loadCohortCourseWeek(weekId.value);
  configurePreview.value =
    route.query.preview === 'preview'
      ? 'preview'
      : route.query.preview === 'configure'
      ? 'configure'
      : week.value?.status === 'published'
      ? 'preview'
      : 'configure';
}

//#endregion

//#region save
const contentLayoutValid = ref(false);
const canSave = computed(() => contentLayoutValid.value && dirty.value);

const save = async () => {
  if (!weekSection.value) return;

  await api.put<unknown, WeekSection>(`/cohort-course-weeks/sections/${weekSection.value.id}/content`, {
    contentLayout: contentLayout.value,
  });
  triggerBackgroundLoad.value = true;
  loadCohortCourseWeek(weekId.value, true);
};
const dirty = computed<boolean>(() => {
  return JSON.stringify(nullableContentLayout.value) !== JSON.stringify(weekSection.value?.contentLayout);
});
const { dialog } = useUnsavedChanges(dirty);
//#endregion

//#region preview student week
const previewStudentWeek = computed(() => {
  if (!weekSection.value) return null;
  const filteredWeekSections =
    week.value?.cohortCourseWeekSections
      .filter(x => !selectedPreviewSubjectId.value || !x.subjectId || x.subjectId === selectedPreviewSubjectId.value)
      .map(s => {
        if (s.id === sectionId.value) {
          return { ...s, contentLayout: contentLayout.value };
        }
        return s;
      }) || [];
  const sw: StudentWeek = {
    id: -1,
    tasksCompleted: 0,
    commentsCompleted: 0,
    weekTasksCount: 0,
    weekCommentsCount: 0,
    status: 'not_started',
    student: {
      id: -1,
      name: 'Preview',
      email: '',
    },
    cohortCourseWeek: {
      id: -1,
      name: week.value?.name || '',
      startDate: week.value?.startDate || '',
      endDate: week.value?.endDate || '',
      cohortCourseWeekSections:
        filteredWeekSections.map(s => {
          if (s.id === weekSection.value?.id) {
            return {
              ...s,
              contentLayout: contentLayout.value,
            };
          }
          return s;
        }) || [],
    },
    completedAt: '',
    studentCohortCourseWeekSections:
      filteredWeekSections
        .filter(x => !selectedPreviewSubjectId.value || !x.subjectId || x.subjectId === selectedPreviewSubjectId.value)
        .map(s => ({
          id: s.id,
          completed: false,
          cohortCourseWeekSectionId: s.id,
          studentCohortCourseWeekComments: mapContentLayoutToCompletionComments(s.contentLayout, id => ({
            cohortCourseWeekSectionCommentId: id,
          })),
          studentCohortCourseWeekTasks: mapContentLayoutToCompletionTasks(s.contentLayout, id => ({
            cohortCourseWeekSectionTaskId: id,
          })),
          studentWeekSectionFiles: [],
        })) || [],
  };

  return sw;
});

const selectedPreviewSubjectId = ref<number | null>(null);

//#endregion

setBreadcrumbs(
  computed(() => [
    {
      text: 'Training Plan',
      to: {
        name: 'TutorAdminCohortCoursePage',
      },
    },
    {
      text: term.value?.name || '',
      to: {
        name: 'TutorAdminCohortCourseTermPage',
        params: { termId: termId.value },
      },
    },
    {
      text: week.value?.name || '',
      to: {
        name: 'TutorAdminCohortCourseWeekPage',
        params: { id: route.params.weekId },
      },
    },
    {
      text: weekSection.value?.title || '',
    },
  ])
);

//#region edit section details
const { editSectionDialog, editSection } = useEditWeekSectionDetails();
const editThisSection = () => {
  editSection(
    weekSection.value || {
      id: -1,
      title: '',
      subjectId: null,
      order: 0,
      contentLayout: { sections: [] },
      subjectName: null,
    }
  );
};

//#endregion

//#region preview
const router = useRouter();
function changeSection(sectionId: number) {
  router.push({
    name: 'TutorAdminCohortCourseWeekSectionPage',
    params: {
      sectionId: sectionId.toString(),
    },
    query: {
      preview: configurePreview.value,
    },
  });
}
//#endregion
</script>
