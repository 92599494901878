import { hasPermission } from '@/mixins/global-mixins';
import type { UserStaff } from '@/store/map-store';

export function canAuthorInstructorCourseFiles(staff: UserStaff): boolean {
  return (
    hasPermission(staff, 'staff.edit') &&
    hasPermission(staff, 'courseFiles.edit') &&
    !staff.institution.config.early_careers
  );
}
