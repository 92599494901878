<template>
  <div>
    <mosaic-tab-item-list :items="sortedAndFilteredEvents" :empty-text="emptyText" mosaic-key="events">
      <template #filters>
        <mosaic-text-field
          dense
          no-icon
          hide-details
          name="name-filter"
          label="Filter by name"
          v-model="nameFilter"
          style="width: 200px"
        />
        <mosaic-date-range-filter
          class="ml-4"
          object-type-plural="Events"
          v-model:start-date="startDateFilter"
          v-model:end-date="endDateFilter"
        />
      </template>

      <template #item="{ item }">
        <mosaic-list-item
          :icon="icons.instructorTrainingEvent"
          :title="item.name"
          :subtitle="item.subtitle"
          :to="item.to"
        >
        </mosaic-list-item>
      </template>
    </mosaic-tab-item-list>
  </div>
</template>

<script setup lang="ts">
import { filterByDateRange, filterByString } from '@/components/library/filters/filters';
import { syncQueryParam } from '@/composables/query';
import type { StaffTraining } from '@/stores/staff-training';
import { formatDateTime } from '@/utils/date';
import { icons } from '@/utils/icons';
import { enumerateItems } from '@/utils/text';
import { formatDuration } from '@/utils/time';
import { computed, ref } from 'vue';
import type { RouteLocationNamedRaw } from 'vue-router';

const props = defineProps<{
  staffTraining: StaffTraining;
  clickEventTo: (event: StaffTraining['staffTrainingEvents'][number]) => RouteLocationNamedRaw;
  isMe?: boolean;
}>();

const nameFilter = ref('');
syncQueryParam(nameFilter, 'events-name', 'string');
const startDateFilter = ref<string | null>(null);
syncQueryParam(startDateFilter, 'events-start', 'string | null');
const endDateFilter = ref<string | null>(null);
syncQueryParam(endDateFilter, 'events-end', 'string | null');

const events = computed(() =>
  props.staffTraining.staffTrainingEvents
    .filter(
      e =>
        filterByString(e.name, nameFilter.value) &&
        filterByDateRange(e.startsAt, startDateFilter.value, endDateFilter.value)
    )
    .map(e => ({
      ...e,
      subtitle: `${formatDateTime(e.startsAt)} - Duration ${formatDuration(
        e.durationHours,
        e.durationMinutes
      )} - Meets ${enumerateItems(e.staffTrainingEventCompetencies.length, 'Competency')}`,
      to: props.clickEventTo(e),
    }))
);
const sortedAndFilteredEvents = computed(() => events.value.sortBy(e => e.startsAt));

const emptyText = computed(() => {
  const prefix = props.isMe ? 'You have' : 'This Instructor has';
  const suffix = props.staffTraining.staffTrainingEvents.length == 0 ? '' : ' for these filters';
  return `${prefix} attended no Events${suffix}`;
});
</script>
