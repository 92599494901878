<template>
  <mosaic-tooltip-chip :color="chip.color">
    <template #text>
      {{ chip.text }}
    </template>

    <template #tooltip>
      {{ chip.tooltip }}
    </template>
  </mosaic-tooltip-chip>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { createStaffAnnouncementStatusChip } from './staff-announcements';

const props = defineProps<{
  read?: boolean;
}>();

const chip = computed(() => createStaffAnnouncementStatusChip(props.read));
</script>
