<template>
  <mosaic-list-item
    :key="target.id"
    icon="mdi-near-me"
    @click="clickTarget && clickTarget()"
    :to="targetClickTo(target.id)"
    :title="target.goal"
    :subtitle="renderSubTitle(target.created_at)"
  >
    <template #information
      ><comments-badge
        v-if="target.comment_count"
        :comment-count="target.comment_count"
        :seen="!target.unseen_comments"
        :user="user"
      />
      <v-tooltip v-if="isReviewTarget(target)" location="top">
        <template #activator="{ props }">
          <span class="pr-4" v-bind="props">
            <v-icon color="primary">mdi-clipboard-edit-outline</v-icon>
          </span>
        </template>
        <span
          >{{ reviewNounCapitalised }} Target{{
            target.is_approved_review_target ? ` - ${reviewNounCapitalised} approved` : ''
          }}</span
        >
      </v-tooltip>
      <v-tooltip v-else-if="target.admin_target_student_id" location="top">
        <template #activator="{ props }">
          <span class="pr-4" v-bind="props">
            <v-icon color="primary">mdi-cast-education</v-icon>
          </span>
        </template>
        <span>Course Target</span>
      </v-tooltip>

      <v-tooltip v-else-if="target.mentor_meeting" location="top">
        <template #activator="{ props }">
          <span class="pr-4" v-bind="props">
            <v-icon color="primary">mdi-recycle-variant</v-icon>
          </span>
        </template>
        <span>Set in Mentor Meeting, {{ formatDate(target.mentor_meeting.meeting_date) }}</span>
      </v-tooltip>
      <v-tooltip location="top">
        <template #activator="{ props }">
          <span class="pr-4" v-bind="props">
            <v-icon v-if="target.set_by" color="primary">mdi-account-tie-voice</v-icon>
            <v-icon v-else color="primary">mdi-account-tie</v-icon>
          </span>
        </template>
        <span>Set by {{ setBy(target) }}</span>
      </v-tooltip>
      <v-chip v-if="target.status === 'active'" color="accent">Active</v-chip>
      <v-chip v-else-if="target.status === 'approved'" color="secondary">Approved</v-chip>
      <v-chip v-else color="primary">Completed</v-chip>
    </template>

    <template #actions>
      <template v-if="showEvidence && editEvidence">
        <mosaic-evidence-icon-btn
          v-model="targetEvidence_id"
          @click-edit="editEvidence(target)"
          @click-create="createEvidence(target)"
        />
      </template>
      <ndt-icon-button
        v-if="deleteTarget"
        icon="delete"
        :tooltip="deleteTargetTooltip(target.can_delete)"
        :disabled="!target.can_delete.permitted"
        @click.prevent="deleteTarget(target)"
      />
    </template>
  </mosaic-list-item>
</template>

<script>
import NdtIconButton from '@/components/NdtIconButton.vue';
import CommentsBadge from '@/components/CommentsBadge.vue';
import moment from 'moment';
import { mapGetters, mapState } from 'vuex';
import { deleteTargetTooltip } from '../pages/targets/targets';

export default {
  components: { NdtIconButton, CommentsBadge },
  props: {
    target: { type: {}, required: true },
    editEvidence: {
      type: Function,
      default: null,
    },
    createEvidence: {
      type: Function,
      default: null,
    },
    deleteTarget: {
      type: Function,
      default: null,
    },
    targetClickTo: {
      type: Function,
      required: true,
    },
    clickTarget: { type: Function },
  },
  emits: ['clickCreateTarget'],
  data: () => ({
    selectedCurriculumThemeId: null,
    targetFilter: '',
    selectedStatus: null,
    statuses: [
      { text: 'All', value: null },
      { text: 'Active', value: 'active' },
      { text: 'Completed', value: 'completed' },
      { text: 'Approved', value: 'approved' },
    ],
    currentPage: 1,
    pageSize: 10,
    targetEvidence_id: null,
  }),
  computed: {
    ...mapState(['user', 'curriculumThemes', 'userStaff']),
    ...mapGetters(['showEvidence']),
    computedTargetEvidenceId() {
      return this.target.evidence_id;
    },
  },
  created() {
    this.$store.dispatch('loadCurriculum');
    this.targetEvidence_id = this?.target?.evidence_id || null;
  },
  watch: {
    computedTargetEvidenceId(newValue) {
      this.targetEvidence_id = newValue;
    },
  },
  methods: {
    renderSubTitle: function (created_date) {
      return 'Added ' + moment(created_date).fromNow() + ' (' + this.formatDate(created_date) + ')';
    },
    setBy(target) {
      if (target.admin_target_student_id) return 'Course Lead';
      if (!target?.set_by) return this.user.student ? 'Me' : this.traineeNounCapitalised();
      if (this.userStaff && target.set_by.id == this.userStaff.id) return 'Me';
      return target.set_by.name;
    },
    setByMe(target) {
      return this.setBy(target) == 'Me';
    },
    isReviewTarget(target) {
      return (
        target.legacy_student_review_standard_id ||
        target.legacy_student_review_id ||
        target.student_review_standard_id ||
        target.student_review_id
      );
    },
    seenStatusColor(target) {
      return target.unseen_comments ? 'orange' : 'grey';
    },
    deleteTargetTooltip,
  },
};
</script>

<style scoped>
.badge {
  width: 80px;
}
</style>
