<template>
  <files-list
    :load-items="loadItems"
    files-storage-type="Mosaic"
    :get-file-url="getFileUrl"
    :move-item="moveItem"
    :can-add-as-evidence="false"
    :page-breadcrumbs="breadcrumbs"
  />
</template>

<script>
import FilesList from '../../components/files-list/FilesList.vue';
import { mapState } from 'vuex';

export default {
  name: 'TutorAdminStaffFilesPage',
  computed: {
    ...mapState(['selectedInstitution']),
    breadcrumbs() {
      return [
        {
          text: `Instructor Files`,
          to: {
            name: 'TutorAdminStaffFilesPage',
          },
        },
      ];
    },
  },
  methods: {
    async loadItems(folder) {
      const response = await this.$api.get(
        `/institutions/${this.selectedInstitution.id}/admin-staff-files/${this.loadItemsSuffix(folder)}`
      );

      // Add editable at the API instead
      return {
        items: response.data.items.map(x => ({
          ...x,
          sortName: x.name,
          editable: this.isEditable(x),
          isMoveTarget: x.type === 'folder',
          roleId: x.roleId,
          filesApiPath: `/institutions/${this.selectedInstitution.id}/admin-staff-files/${this.roleSuffix(x)}`,
          icon: {
            name: this.getIconName(x),
            color: this.getIconColor(x),
            tooltip: this.getIconTooltip(x),
          },
        })),
        folder: {
          id: folder.id || response.data.folder.id,
          name: folder.name || response.data.folder.name,
          isRootFolder: folder.isRootFolder || false,
          editable: true,
          roleId: response.data.folder.roleId,
          isRootRoleFolder: response.data.folder.isRootRoleFolder || false,
          uploadDirectToExternalStorage: true,
          filesApiPath: `/institutions/${this.selectedInstitution.id}/admin-staff-files/${this.roleSuffix(folder)}`,
          presignFilesApiPath: `/presign/institutions/${
            this.selectedInstitution.id
          }/admin-staff-files/${this.roleSuffix(folder)}`,
          folderApiPath: `/institutions/${this.selectedInstitution.id}/admin-staff-folders/${this.roleSuffix(folder)}`,
        },
      };
    },
    loadItemsSuffix(item) {
      if (item.roleId) {
        const suffix = `roles/${item.roleId}`;
        if (item.isRootRoleFolder) return suffix;
        return `${suffix}/${encodeURIComponent(item.id)}`;
      }

      if (item.id) {
        return `all-staff/${encodeURIComponent(item.id)}`;
      }

      return '';
    },
    isEditable(item) {
      if (!item.id || item.roleName) return false;
      return true;
    },
    roleSuffix(item) {
      return item.roleId ? `roles/${item.roleId}` : 'all-staff';
    },
    getIconName(x) {
      if (x.roleName) return 'folder-lock-open';
      return x.type;
    },
    getIconColor(x) {
      return x.roleName ? 'primary' : null;
    },
    getIconTooltip(x) {
      if (!x.roleName) return null;
      return `This folder and its contents will only be visible to instructors with the ${x.roleName} role.`;
    },
    async getFileUrl(item, download) {
      const r = await this.$api.get(
        `/institutions/${this.selectedInstitution.id}/admin-staff-file/${this.roleSuffix(item)}/${encodeURIComponent(
          item.id
        )}?download=${download}`
      );
      return r.data.webUrl;
    },
    async moveItem(item, destinationFolder, currentFolder) {
      await this.$api.post(
        `/institutions/${this.selectedInstitution.id}/admin-staff-files/${encodeURIComponent(item.id)}/move-item`,
        {
          destinationId: destinationFolder.id,
          destinationRoleId: destinationFolder.roleId || null,
          currentParentId: currentFolder.id,
          currentParentRoleId: currentFolder.roleId || null,
        }
      );
    },
  },
  components: { FilesList },
};
</script>
