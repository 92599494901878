<template>
  <div>
    <v-card class="mb-4">
      <v-card-text>
        <v-list-subheader class="pl-6">Config</v-list-subheader>
        <v-list>
          <v-list-item
            v-if="adminCohort.standard_set"
            ripple
            @click.prevent="standardSetClick(adminCohort.standard_set.id)"
          >
            <template #prepend>
              <v-avatar>
                <v-icon>mdi-list-status</v-icon>
              </v-avatar>
            </template>
            <div class="d-flex align-center">
              <div class="flex-grow-1">
                <v-list-item-title>Standard Set</v-list-item-title>
                <v-list-item-subtitle>
                  {{ adminCohort.standard_set.name }}
                </v-list-item-subtitle>
              </div>

              <v-list-item-action><v-icon>mdi-chevron-right</v-icon></v-list-item-action>
            </div>
          </v-list-item>
          <template v-else>
            <div class="px-16">
              This Cohort is current using the Institution's Standard Set:
              {{ adminInstitution.standard_set.name }}
            </div>
            <v-list-item>
              <template #prepend>
                <v-avatar>
                  <v-icon>mdi-list-status</v-icon>
                </v-avatar>
              </template>
              <div class="d-flex align-center">
                <div class="flex-grow-1 flex-shrink-0">
                  <mosaic-select
                    name="standard-set"
                    v-model="standardSetIdToAdd"
                    :items="unassignedStandardSets"
                    placeholder="Select an unassigned Standard Set"
                    item-title="name"
                    item-value="id"
                    label="Standard Set"
                    no-icon
                    no-data-text="No unassigned Standard Sets. Create a new one or copy from existing"
                  />
                </div>

                <v-btn variant="text" ripple :disabled="!canAddStandardSet" @click.prevent="addStandardSet()"
                  >Confirm</v-btn
                >
              </div>
            </v-list-item>
          </template>
          <v-list-item v-if="adminCohort.curriculum">
            <template #prepend>
              <v-avatar>
                <v-icon>mdi-list-status</v-icon>
              </v-avatar>
            </template>
            <v-list-item-title>Curriculum</v-list-item-title>
            <v-list-item-subtitle>
              {{ adminCohort.curriculum.name }}
            </v-list-item-subtitle>
          </v-list-item>
          <template v-else>
            <div v-if="adminInstitution.curriculum" class="px-16 mt-6">
              This cohort is current using the Institution's Curriculum:
              {{ adminInstitution.curriculum.name }}
            </div>

            <div v-else class="px-16 mt-6">There is currently no Curriculum set for the Institution</div>
            <div class="px-16">Once a Curriculum has been selected for this Cohort it cannot be easily changed.</div>
            <v-list-item>
              <template #prepend>
                <v-avatar>
                  <v-icon>mdi-list-status</v-icon>
                </v-avatar>
              </template>
              <div class="d-flex align-center">
                <div class="flex-grow-1 flex-shrink-0">
                  <mosaic-select
                    name="curriculum-template"
                    label="Curriculum Template"
                    v-model="curriculumTemplateIdToAdd"
                    :items="curriculumTemplates"
                    placeholder="Select a Curriculum"
                    item-title="name"
                    item-value="id"
                    no-icon
                  />
                </div>
                <v-btn
                  variant="text"
                  ripple
                  :disabled="!curriculumTemplateIdToAdd || addingCurriculum"
                  @click.prevent="addCurriculum()"
                  >Confirm</v-btn
                >
              </div>
            </v-list-item>
          </template>
        </v-list>
      </v-card-text>
    </v-card>
    <v-expansion-panels>
      <v-expansion-panel title="Placements"
        ><template #text>
          <mosaic-list :items="placements" empty-text="There are no Placements to display">
            <template #item="{ item: placement }">
              <mosaic-list-item
                :key="placement.id"
                icon="mdi-school"
                :title="placement.name"
                :subtitle="shortDate(placement.start_date, placement.end_date)"
              >
                <template #actions>
                  <ndt-icon-button icon="pencil" tooltip="Edit Placement" @click.prevent="editPlacement(placement)" />
                  <ndt-icon-button
                    icon="delete"
                    tooltip="Delete Placement"
                    @click.prevent="deletePlacement(placement.id)"
                  />
                </template>
              </mosaic-list-item>
            </template>
            <v-list-item>
              <template #prepend>
                <v-avatar>
                  <v-icon>mdi-school</v-icon>
                </v-avatar>
              </template>
              <div class="d-flex align-center">
                <v-text-field v-model="newPlacementName" name="add-placement" label="Placement Name" />
                <mosaic-date-picker
                  name="start-date"
                  v-model:date="newPlacementStartDate"
                  label="Start Date"
                ></mosaic-date-picker>
                <mosaic-date-picker
                  name="end-date"
                  v-model:date="newPlacementEndDate"
                  label="End Date"
                ></mosaic-date-picker>
                <v-btn variant="text" ripple :disabled="!canAddPlacement || processing" @click.prevent="addPlacement()"
                  >Add</v-btn
                >
              </div>
            </v-list-item>
          </mosaic-list>
        </template>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-expansion-panels class="mt-4">
      <v-expansion-panel :title="traineeNounCapitalisedAndPluralised">
        <template #text>
          <div v-if="loadStudentsProcessing">Loading...</div>
          <div v-else-if="loadStudentsError">{{ loadStudentsError }}</div>
          <mosaic-list
            v-else
            :items="sortedStudents"
            :empty-text="`There are no ${traineeNounCapitalisedAndPluralised} in this Cohort`"
          >
            <template #item="{ item: student }">
              <mosaic-list-item
                :key="student.id"
                icon="mdi-account"
                :title="renderStudentInfo(student)"
                :subtitle="student.email"
              >
                <template #information><demo-account-badge v-if="student.is_demo"></demo-account-badge></template>
                <template #actions>
                  <mosaic-icon-btn
                    icon="incognito"
                    tooltip="Impersonate"
                    @click.prevent="impersonate(student.user.id)"
                  />
                  <mosaic-icon-btn icon="lock-reset" tooltip="Reset password" @click.prevent="resetPassword(student)" />

                  <mosaic-icon-btn
                    v-if="student.status === 'active'"
                    icon="package-down"
                    tooltip="Archive trainee/ECT"
                    @click.prevent="updateStudentStatus(student, 'archived')"
                  />

                  <mosaic-icon-btn
                    v-else
                    icon="package-up"
                    icon-color="green"
                    tooltip="Activate trainee/ECT"
                    @click.prevent="updateStudentStatus(student, 'active')"
                  />
                </template>
              </mosaic-list-item>
            </template>
          </mosaic-list>
        </template>
      </v-expansion-panel>
    </v-expansion-panels>
    <ndt-dialog
      v-model:active="resetPasswordDialog.active"
      title="Reset Password"
      :on-close="resetPasswordDialogClose"
      :error-message="resetPasswordDialog.errorMessage"
    >
      <div class="pt-2">
        <v-text-field v-model="resetPasswordDialog.newPassword" name="password" label="Password" type="text" />
        <v-text-field
          v-model="resetPasswordDialog.newPasswordConfirmation"
          name="password"
          label="Confirm Password"
          type="text"
        />
      </div>
      <template #buttons>
        <v-btn variant="text" ripple :disabled="!canSubmitResetPassword" @click.prevent="submitResetPassword()"
          >Submit</v-btn
        >
      </template>
    </ndt-dialog>
    <ndt-dialog
      v-model:active="updateUserStatusDialog.active"
      title="Update User Status"
      :error-message="updateUserStatusDialog.errorMessage"
      :width="500"
    >
      <span class="pb-2">
        Are you sure you want to set {{ updateUserStatusDialog.userName }}'s account status to
        <b>{{ updateUserStatusDialog.newStatus }}</b
        >?
      </span>
      <v-alert v-if="updateUserStatusDialog.newStatus == 'archived'" type="warning"
        >This will mean the user can no longer log in and will be not be visible within their cohort</v-alert
      >
      <v-alert v-if="updateUserStatusDialog.newStatus == 'active'" type="info"
        >This will all the user to log in again and be viewable withint their cohort</v-alert
      >
      <template #buttons>
        <v-btn
          variant="text"
          ripple
          color="error"
          :disabled="updateUserStatusDialog.processing"
          @click.prevent="submitUpdateUserStatus()"
          >Update Status</v-btn
        >
      </template>
    </ndt-dialog>
    <ndt-dialog
      v-model:active="deleteDialog.active"
      title="Delete Placement"
      :error-message="deleteDialog.errorMessage"
    >
      <span>Are you sure you want to delete this placement?</span>
      <template #buttons>
        <v-btn variant="text" ripple color="error" :disabled="processing" @click.prevent="submitDeletePlacement()"
          >Delete</v-btn
        >
      </template>
    </ndt-dialog>

    <ndt-dialog v-model:active="editDialog.active" title="Edit Placement" :error-message="editDialog.errorMessage">
      <v-text-field v-model="editDialog.name" name="update-placement" label="Placement Name" />
      <v-menu
        ref="menu3"
        v-model="editDialog.startMenu"
        v-model:return-value="editDialog.start_date"
        :close-on-content-click="false"
        transition="scale-transition"
        min-width="290px"
      >
        <template #activator="{ props }">
          <v-text-field
            v-model="editDialog.start_date"
            label="Start Date"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="props"
          />
        </template>
        <v-date-picker v-model="editDialog.start_date" :max="editDialog.end_date" no-title scrollable>
          <v-spacer />
          <v-btn variant="text" color="primary" @click.prevent="editDialog.startMenu = false">Cancel</v-btn>
          <v-btn variant="text" color="primary" @click.prevent="$refs.menu3.save(editDialog.start_date)">OK</v-btn>
        </v-date-picker>
      </v-menu>
      <v-spacer />
      <v-menu
        ref="menu4"
        v-model="editDialog.endMenu"
        v-model:return-value="editDialog.end_date"
        :close-on-content-click="false"
        transition="scale-transition"
        min-width="290px"
      >
        <template #activator="{ props }">
          <v-text-field
            ref="ChangedEndField"
            v-model="editDialog.end_date"
            label="End Date"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="props"
          />
        </template>
        <v-date-picker v-model="editDialog.end_date" :min="editDialog.start_date" no-title scrollable>
          <v-spacer />
          <v-btn variant="text" color="primary" @click.prevent="editDialog.endMenu = false">Cancel</v-btn>
          <v-btn variant="text" color="primary" @click.prevent="$refs.menu4.save(editDialog.end_date)">OK</v-btn>
        </v-date-picker>
      </v-menu>
      <template #buttons>
        <v-btn
          variant="text"
          ripple
          color="error"
          :disabled="!canEditPlacement || processing"
          @click.prevent="submitEditPlacement()"
          >Submit</v-btn
        >
      </template>
    </ndt-dialog>
  </div>
</template>

<script>
import NdtDialog from '../../components/NdtDialog.vue';
import NdtIconButton from '../../components/NdtIconButton.vue';
import moment from 'moment';
import { mapState } from 'vuex';
import { impersonate } from '../../utils/auth';
import { generateStongPassword } from '../../utils/passwords';
import DemoAccountBadge from '@/components/DemoAccountBadge.vue';

export default {
  name: 'AdminInstitutionCohortPage',
  data: function () {
    return {
      placements: [],
      addingCurriculum: false,
      curriculumTemplates: [],
      curriculumTemplateIdToAdd: null,
      processing: false,
      deleteDialog: {
        active: false,
        errorMessage: '',
        placementId: null,
      },
      startMenu: false,
      endMenu: false,
      cohortId: null,
      newPlacementName: '',
      newPlacementStartDate: new Date().toISOString().substr(0, 10),
      newPlacementEndDate: new Date().toISOString().substr(0, 10),
      editDialog: {
        active: false,
        id: null,
        startMenu: false,
        endMenu: false,
        name: '',
        start_date: new Date().toISOString().substr(0, 10),
        end_date: new Date().toISOString().substr(0, 10),
        errorMessage: '',
      },
      resetPasswordDialog: {
        active: false,
        name: '',
        userId: null,
        processing: false,
        newPassword: '',
        newPasswordConfirmation: '',
        errorMessage: '',
      },
      updateUserStatusDialog: {
        active: false,
        newStatus: '',
        userName: '',
        userId: null,
        errorMessage: '',
        staffOrStudent: '',
      },
      standardSets: [],
      standardSetIdToAdd: null,
      students: [],
      loadStudentsError: '',
      loadStudentsProcessing: true,
    };
  },
  computed: {
    ...mapState(['user', 'adminInstitution', 'adminCohort']),
    breadcrumbs() {
      return [
        {
          text: this.adminInstitution?.name,
          to: {
            name: 'AdminInstitutionPage',
          },
        },
        {
          text: 'Cohorts',
        },
      ];
    },
    canAddPlacement: function () {
      return this.validDates(this.newPlacementStartDate, this.newPlacementEndDate) && !this.processing;
    },
    canEditPlacement: function () {
      return this.validDates(this.editDialog.start_date, this.editDialog.end_date) && !this.processing;
    },
    canAddStandardSet() {
      return !!this.standardSetIdToAdd;
    },
    unassignedStandardSets() {
      return this.standardSets.filter(x => x.institutions.length === 0);
    },
    canSubmitResetPassword() {
      return (
        !this.resetPasswordDialog.processing &&
        !!this.resetPasswordDialog.newPassword &&
        !!this.resetPasswordDialog.newPasswordConfirmation
      );
    },
    sortedStudents() {
      const students = [...this.students];
      return students.sort((a, b) => {
        const aValue = a.status == 'active' ? 2 : 1;
        const bValue = b.status == 'active' ? 2 : 1;
        return bValue - aValue;
      });
    },
  },
  created() {
    this.updatePlacementsList();

    this.$api.get('/standard-sets').then(r => {
      this.standardSets = r.data.filter(x => x.id != this.adminInstitution.standard_set.id);
    });
    this.$api.get('/curriculum-templates').then(r => {
      this.curriculumTemplates = r.data;
    });
    this.loadAdminCohortStudents();
  },
  methods: {
    async loadAdminCohortStudents() {
      this.loadStudentsProcessing = true;
      this.loadStudentsError = '';
      try {
        const r = await this.$api.get(`/admin/cohorts/${this.adminCohort.id}/students`);
        this.students = r.data;
      } catch (e) {
        this.loadStudentsError = `Sorry, cannot load ${this.traineeNounCapitalisedAndPluralised} at the moment`;
      }
      this.loadStudentsProcessing = false;
    },
    standardSetClick(standardSetId) {
      this.$router.push({
        name: 'AdminStandardSetStandardsPage',
        params: { standardSetId: standardSetId },
      });
    },
    async impersonate(userId) {
      const response = await this.$api.post(`/impersonate`, { target_id: userId });
      impersonate(response.data.auth_token);
    },
    async resetPassword(staffOrStudent) {
      const password = await generateStongPassword(this.$api, this.user);
      this.resetPasswordDialog = {
        active: true,
        name: staffOrStudent.name,
        userId: staffOrStudent.roles ? staffOrStudent.user_id : staffOrStudent.user.id,
        processing: false,
        newPassword: password,
        newPasswordConfirmation: password,
      };
    },
    updateStudentStatus(student, status) {
      this.updateUserStatusDialog = {
        active: true,
        userName: student.display_name,
        userId: student.user.id,
        errorMessage: '',
        staffOrStudent: 'Student',
        newStatus: status,
      };
    },
    renderStudentInfo(student) {
      const status = student.status === 'archived' ? 'ARCHIVED - ' : '';
      const storageStatus = student.storage_set_up ? 'Storage set up' : 'Storage not set up';

      return `${status}${student.name ? student.name : 'No name recorded'} - ${student.email} - ${storageStatus}`;
    },

    async addCurriculum() {
      this.addingCurriculum = true;
      const r = await this.$api.post(`/curriculum-templates/${this.curriculumTemplateIdToAdd}/copy-to-cohort`, {
        cohortId: this.adminCohort.id,
      });

      this.$store.commit('updateAdminCohortWithChanges', {
        curriculum: r.data,
      });
      this.addingCurriculum = false;
    },
    async addPlacement() {
      this.processing = true;
      try {
        const r = await this.$api.post(`/cohorts/${this.adminCohort.id}/placements`, {
          name: this.newPlacementName,
          start_date: this.newPlacementStartDate,
          end_date: this.newPlacementEndDate,
        });
        this.placements.push(r.data);
        this.newPlacementName = `${this.adminInstitution?.config.early_careers ? 'Term' : 'Placement'} ${
          this.placements.length + 1
        }`;
      } catch (e) {
        this.errorMessage =
          (e.response && e.response.data && e.response.data.message) || 'Sorry, cannot add Placement at the moment';
      }
      this.processing = false;
    },
    deletePlacement(id) {
      this.deleteDialog.placementId = id;
      this.deleteDialog.active = true;
    },
    submitDeletePlacement() {
      this.processing = true;
      this.$api
        .delete(`/placements/${this.deleteDialog.placementId}`)
        .then(() => {
          this.deleteDialog.active = false;
          this.updatePlacementsList();
          this.processing = false;
        })
        .catch(e => {
          this.deleteItemDialog.errorMessage =
            (e.response && e.response.data.message) || `Sorry, cannot delete this placement at the moment`;
          this.processing = false;
        });
    },
    validDates(start_date, end_date) {
      return moment(start_date).isBefore(end_date);
    },
    editPlacement(placement) {
      this.editDialog = {
        active: true,
        id: placement.id,
        name: placement.name,
        start_date: placement.start_date,
        end_date: placement.end_date,
      };
    },
    submitEditPlacement() {
      this.processing = true;
      this.$api
        .put(
          `/placements/${this.editDialog.id}`,
          (({ id, name, start_date, end_date }) => ({ id, name, start_date, end_date }))(this.editDialog)
        )
        .then(r => {
          this.editDialog = {
            active: false,
            updatedPlacementName: '',
            updatedPlacementStartDate: new Date().toISOString().substr(0, 10),
            updatedPlacementEndDate: new Date().toISOString().substr(0, 10),
          };
          this.placements.forEach(function (placement, index, placements) {
            placements[index] = placement.id == r.data.id ? r.data : placement;
          });
          this.processing = false;
        })
        .catch(e => {
          this.editDialog.errorMessage =
            (e.response && e.response.data && e.response.data.message) || 'Sorry, cannot edit Placement at the moment';
          this.processing = false;
        });
    },
    updatePlacementsList() {
      this.$api.get(`/cohorts/${this.adminCohort.id}/placements`).then(response => {
        this.placements = response.data;
        this.newPlacementName = `${this.adminInstitution?.config.early_careers ? 'Term' : 'Placement'} ${
          this.placements.length + 1
        }`;
      });
    },
    shortDate(sDate, eDate) {
      return moment(sDate).format('DD/MM/YY') + ' - ' + moment(eDate).format('DD/MM/YY');
    },
    async addStandardSet() {
      await this.$api.post(`/cohorts/${this.adminCohort.id}/standard-sets`, {
        standardSetId: this.standardSetIdToAdd,
      });
      this.$store.commit('updateAdminCohortWithChanges', {
        standard_set: this.standardSets.find(x => x.id === this.standardSetIdToAdd),
      });
    },
    submitResetPassword: async function () {
      this.resetPasswordDialog.processing = true;
      try {
        await this.$api.put(`/users/${this.resetPasswordDialog.userId}/admin-reset-password`, {
          password: this.resetPasswordDialog.newPassword,
          password_confirmation: this.resetPasswordDialog.newPasswordConfirmation,
        });
        this.resetPasswordDialog.processing = false;
        this.resetPasswordDialog.active = false;
      } catch (e) {
        console.log(e);
        this.resetPasswordDialog.errorMessage =
          (e.response && e.response.data && e.response.data.message) || `Sorry, cannot reset password at the moment`;
        this.resetPasswordDialog.processing = false;
      }
    },
    resetPasswordDialogClose() {
      this.resetPasswordDialog = {
        active: false,
        name: '',
        userId: null,
        processing: false,
        newPassword: '',
        newPasswordConfirmation: '',
        errorMessage: '',
      };
    },
    async submitUpdateUserStatus() {
      try {
        await this.$api.put(`users/${this.updateUserStatusDialog.userId}/update-user-status`, {
          status: this.updateUserStatusDialog.newStatus,
        });
        await this.loadAdminCohortStudents();
        this.updateUserStatusDialog.active = false;
      } catch (e) {
        this.updateUserStatusDialog.errorMessage = "Sorry, can't update user status right now";
      }
    },
  },
  components: { NdtDialog, NdtIconButton, DemoAccountBadge },
};
</script>
<style scoped>
.v-btn {
  min-width: 12px;
}
</style>
