<template>
  <div>
    <cohort-action-edit-page
      :action-noun="actionNoun"
      :action-set-date="actionSetDate"
      :action-scheduled-at-date="actionScheduledAtDate"
      :action-schedule-in-the-future="actionScheduleInTheFuture"
      :assignee-noun="traineeNounCapitalised()"
      :assignee-noun-pluralised="traineeNounCapitalisedAndPluralised"
      :load="load"
      :can-continue="canContinue"
      :can-save="canSave"
      :save="saveWithDialog"
      :return-to="returnTo"
      :dirty="dirty"
      :is-editing="isEditing"
      :draft="draft"
    >
      <template #details-step>
        <slot name="details-step"></slot>
      </template>

      <template #assignment-step>
        <mosaic-action-assignment
          v-if="selectedCohortStudents.length > 0"
          :assignees="filteredStudents"
          :selected-ids="selectedStudentIds"
          :initially-selected-ids="initiallySelectedStudentIds"
          :is-editing="isEditing"
          :scheduled-date="actionScheduledAtDate"
          :action-set-in-the-future="
            isEditing ? !actionSetDate && actionScheduleInTheFuture : actionScheduleInTheFuture
          "
          :action-originally-set-in-the-future="isEditing && !actionSetDate"
          :assignee-noun="traineeNounCapitalised()"
          :assignee-noun-pluralised="traineeNounCapitalisedAndPluralised"
          :action-noun="actionNoun"
          @update:selected-ids="emit('update:selectedStudentIds', $event)"
        >
          <template #filters>
            <mosaic-cohort-monitoring-filters
              :students="selectedCohortStudents"
              @update:filtered-student-ids="filteredStudentIds = $event"
            />
            <slot name="action-assignment-filters"></slot>
          </template>

          <template #selected-text>
            <slot name="action-assignment-selected-text"></slot>
          </template>

          <template #status-badge="{ assignee }">
            <slot name="action-assignment-status-badge" :assignee="assignee"></slot>
          </template>

          <template #details-header>
            <slot name="action-assignment-details-header"></slot>
          </template>

          <template #details="{ assignee }">
            <slot name="action-assignment-details" :assignee="assignee"></slot>
          </template>
        </mosaic-action-assignment>

        <div v-else>
          <mosaic-card-heading class="pb-2">{{ traineeNounCapitalisedAndPluralised }}</mosaic-card-heading>
          <div v-if="selectedCohortStudents.length === 0" class="py-4">
            There are no {{ traineeNounCapitalisedAndPluralised }} in this Cohort. Add some on the
            <mosaic-router-link
              :to="{
                name: 'TutorStudentListPage',
              }"
              >{{ traineeNounCapitalisedAndPluralised }} page</mosaic-router-link
            >.
          </div>
        </div>
      </template>
    </cohort-action-edit-page>
    <mosaic-confirm-delete-student-data-dialog
      v-if="actionStatusItems && getActionStatus && canDeleteStudentWithoutConfirmation"
      v-model:active="confirmDeleteDialog.active"
      @confirm="confirmed = true"
      @cancel="cancelled = true"
    >
      <cohort-action-delete-dialog-information
        :action-noun="actionNoun"
        :status-items="actionStatusItems"
        :student-ids-to-be-removed="confirmDeleteDialog.studentIdsToBeRemoved"
        :get-action-status="getActionStatus"
      />
    </mosaic-confirm-delete-student-data-dialog>
  </div>
</template>

<script setup lang="ts">
import CohortActionEditPage from '@/components/cohort-actions/CohortActionEditPage.vue';
import { useCohortStore } from '@/stores/cohort';
import { computed, ref } from 'vue';
import type { RouteLocationNamedRaw } from 'vue-router';
import MosaicActionAssignment from '@/components/cohort-actions/MosaicActionAssignment.vue';
import type { CohortStudent } from '@/store/map-store';
import { until } from '@vueuse/core';
import type { AsyncOperationResult } from '@/composables/processing-and-errors';
import CohortActionDeleteDialogInformation from './CohortActionDeleteDialogInformation.vue';

const props = defineProps<{
  actionNoun: string;
  actionSetDate?: string;
  actionScheduledAtDate?: string;
  actionScheduleInTheFuture: boolean;
  load: () => Promise<void>;
  canContinue: boolean;
  save: () => AsyncOperationResult;
  returnTo: RouteLocationNamedRaw;
  dirty: boolean;
  isEditing: boolean;
  draft?: boolean;
  // should be either selectedCohortStudents or a filtered subset
  students: CohortStudent[];
  // selectedStudentIds may contain ids not in students, e.g. students may be already filtered
  selectedStudentIds: number[];
  initiallySelectedStudentIds: number[];
  actionStatusItems?: { title: string; value: string }[];
  getActionStatus?: (studentId: number) => string | undefined;
  canDeleteStudentWithoutConfirmation?: (studentId: number) => boolean;
}>();

const confirmDeleteProps = [props.canDeleteStudentWithoutConfirmation, props.getActionStatus, props.actionStatusItems];
if (!confirmDeleteProps.every(x => !!x) && !confirmDeleteProps.every(x => !x)) {
  throw 'Must supply all or none of: canDeleteStudentWithoutConfirmation, actionStatusItems and getActionStatus';
}

const emit = defineEmits<{
  (e: 'update:selectedStudentIds', ids: number[]): void;
}>();

const { selectedCohortStudents } = useCohortStore();

const filteredStudentIds = ref<number[]>(selectedCohortStudents.value.map(s => s.id));

const filteredStudents = computed(() => {
  return props.students
    .filter(s => filteredStudentIds.value.includes(s.id))
    .map(s => ({
      displayName: s.display_name,
      ...s,
    }));
});

const canSave = computed(
  () => props.canContinue && props.dirty && (props.selectedStudentIds.length > 0 || props.draft)
);

const confirmDeleteDialog = ref({
  active: false,
  studentIdsToBeRemoved: [] as number[],
});
const confirmed = ref(false);
const cancelled = ref(false);

async function saveWithDialog() {
  const studentIdsToBeRemoved = props.initiallySelectedStudentIds.filter(id => !props.selectedStudentIds.includes(id));
  if (
    studentIdsToBeRemoved.some(
      id => props.canDeleteStudentWithoutConfirmation && !props.canDeleteStudentWithoutConfirmation(id)
    )
  ) {
    confirmed.value = false;
    cancelled.value = false;
    confirmDeleteDialog.value = {
      active: true,
      studentIdsToBeRemoved,
    };
    await until(computed(() => confirmed.value || cancelled.value)).toBe(true);
    if (cancelled.value) {
      return 'no-op';
    }
  }

  await props.save();
}
</script>
