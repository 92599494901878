<template>
  <div>
    <review-part-title :can-edit="canEdit" :completed="completed"
      ><div>{{ comment.label }} {{ comment.optional ? '(optional)' : '' }}</div>
      <div v-if="comment.optional && !reviewApproved && canEdit">
        <v-tooltip location="top">
          <template #activator="{ props }">
            <span v-bind="props">
              <v-btn color="accent" variant="outlined" ripple :disabled="completed" @click.prevent="addNoComment()"
                >Mark n/a</v-btn
              >
            </span>
          </template>
          <template v-if="completed">
            <span v-if="comment.comment === naComment">You have opted to not fill in this comment</span
            ><span v-else>You have already begun making a comment</span></template
          >
          <span v-else>This comment is optional. Click here if you don't want to fill it in</span></v-tooltip
        >
      </div></review-part-title
    >
    <div class="mb-2">
      <mosaic-quill
        v-if="!reviewApproved && canEdit"
        key="1"
        class="pa-2 ml-7"
        :contents="comment.comment"
        :read-only="false"
        @update:contents="emit('update:commentText', $event)"
      />
      <mosaic-quill v-else-if="completed" key="2" class="pa-2 ml-3" :contents="comment.comment" :read-only="true" />
      <div v-else class="text-body-1 pa-2 ml-6">
        To be completed {{ comment.optional ? '(this comment is optional)' : '' }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import ReviewPartTitle from './ReviewPartTitle.vue';
import MosaicQuill from './quill/MosaicQuill.vue';

defineProps<{
  comment: {
    optional: boolean;
    label: string;
    comment: string;
  };
  canEdit: boolean;
  reviewApproved: boolean;
  completed: boolean;
}>();
const emit = defineEmits(['update:commentText']);
const naComment = '{"ops":[{"insert":"n/a\\n"}]}';

function addNoComment() {
  emit('update:commentText', naComment);
}
</script>
