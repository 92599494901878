<template>
  <mosaic-loading-card v-if="loading" type="two-cards" />
  <mosaic-load-error-card v-else-if="loadError" object-type="Reflection" @retry="loadReflection" />
  <div v-else>
    <reflection-card
      :reflection="fromSnakeCaseToCamelCase(reflection)"
      :return-to="{ name: 'ReflectionsListPage' }"
      :editable="false"
    />
    <comments-card
      v-if="reflection"
      ref="commentsCard"
      :key="reflection.id"
      class="mt-6"
      :comments="reflection.journal_entry_comments"
      edit-permission="student.reflections.comments"
      api-path="/reflections/comments"
      :create-api-path="createApiPath"
      @comments-updated="loadReflection"
      @update:dirty="$emit('update:dirty', $event)"
    />
  </div>
</template>

<script>
import config from '@/utils/config';
import { mapState, mapGetters } from 'vuex';
import CommentsCard from '@/components/CommentsCard.vue';
import ReflectionCard from './ReflectionCard.vue';
import { fromSnakeCaseToCamelCase } from '@/utils/transforms';

export default {
  name: 'TutorReflectionPage',
  components: { CommentsCard, ReflectionCard },
  emits: ['update:dirty'],
  expose: ['save'],
  data: function () {
    return {
      reflectionId: null,
      reflection: null,
      loading: true,
      loadError: false,
      config: config,
    };
  },
  created: function () {
    this.reflectionId = this.$route.params.reflectionId;
  },
  computed: {
    ...mapState(['selectedInstitution']),
    ...mapGetters(['curriculumEnabled']),
    breadcrumbs() {
      return [
        {
          text: 'Reflections',
          to: {
            name: 'ReflectionsListPage',
          },
        },
        {
          text: this.reflection?.title,
        },
      ];
    },
    createApiPath() {
      return `/reflections/${this.reflectionId}/comments`;
    },
  },
  watch: {
    $route: {
      handler: function (to) {
        this.reflectionId = to.params.reflectionId;
      },
      deep: true,
    },
    reflectionId: function () {
      this.loadReflection();
    },
  },
  methods: {
    async loadReflection() {
      this.loading = true;
      this.loadError = false;
      try {
        const response = await this.$api.get(`/reflections/${this.reflectionId}`);
        this.reflection = response.data;
        this.originalCurriculumStatementId = this.reflection.curriculum_statement?.id;
      } catch (e) {
        console.log(e);
        this.loadError = true;
      }
      this.loading = false;
    },
    save() {
      return this.$refs.commentsCard.saveIfDirty();
    },
    fromSnakeCaseToCamelCase,
  },
};
</script>
