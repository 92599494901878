<template>
  <ndt-dialog v-model:active="activeNdtDialog" title="Confirmation" :error-message="error">
    <span class="pb-2">{{ updateMessage }}</span>
    <slot name="message" />
    <v-alert
      v-if="(studentsToAdd.length || studentsToRemove.length) && isEditing"
      type="info"
      variant="outlined"
      class="mt-2"
    >
      <div v-if="studentsToAdd.length && isEditing">
        <span
          >{{ studentsToBeAdded.length }}
          {{
            studentsToBeAdded.length === 1
              ? `${traineeNounCapitalised()} is `
              : `${traineeNounCapitalisedAndPluralised} are`
          }}
          being added to the {{ actionType }}.</span
        >
      </div>
      <div v-if="studentsToRemove.length" class="py-2">
        <span>The following {{ traineeNounCapitalisedAndPluralised }} will be removed from the {{ actionType }}:</span>
        <student-review-status-list
          v-for="status in statuses"
          :key="status"
          :students="studentsToBeRemoved"
          :status="status"
        ></student-review-status-list>
      </div>
    </v-alert>
    <template #buttons>
      <v-btn variant="text" ripple :disabled="processing" @click.prevent="submitUpdateAction()">Confirm</v-btn>
    </template>
  </ndt-dialog>
</template>

<script>
import NdtDialog from './NdtDialog.vue';
import StudentReviewStatusList from '@/components/StudentReviewStatusList.vue';

export default {
  name: 'UpdateCourseActionDialog',
  components: { NdtDialog, StudentReviewStatusList },
  props: {
    active: {
      type: Boolean,
      required: true,
    },
    isEditing: {
      type: Boolean,
      required: false,
      default: false,
    },
    actionType: {
      type: String,
      required: true,
    },
    studentsToRemove: {
      type: Array,
      required: true,
    },
    studentsToAdd: {
      type: Array,
      required: true,
    },
    students: {
      type: Array,
      required: true,
    },
    selectedStudents: {
      type: Array,
      required: true,
    },
    processing: {
      type: Boolean,
      required: true,
    },
    error: {
      type: String,
      required: false,
      default: null,
    },
    // This prop doesn't quite make sense at the minute because it's being fed into <student-review-status-list>
    // which wouldn't currently make sense for other course actions
    statuses: {
      type: Array,
      required: true,
    },
  },
  emits: ['submitClicked', 'update:active'],
  data: function () {
    return {
      activeNdtDialog: false,
    };
  },
  computed: {
    updateMessage() {
      const message = `Please confirm you would like to ${this.isEditing ? 'update' : 'set'} this ${
        this.actionType
      } for ${this.selectedStudents.length} ${
        this.selectedStudents.length === 1 ? this.traineeNounCapitalised() : this.traineeNounCapitalisedAndPluralised
      }. This will create${this.isEditing ? '/update' : ''} ${this.actionType}s for the selected ${
        this.traineeNounCapitalisedAndPluralised
      } with immediate effect.`;
      return message;
    },
    studentsToBeRemoved() {
      return this.students.filter(s => this.studentsToRemove.includes(s.id));
    },
    studentsToBeAdded() {
      return this.students.filter(s => this.studentsToAdd.includes(s.id));
    },
  },
  watch: {
    activeNdtDialog: function (x) {
      this.$emit('update:active', x);
    },
    active: function (x) {
      this.activeNdtDialog = x;
    },
  },
  methods: {
    submitUpdateAction() {
      this.$emit('submitClicked');
    },
    renderStudentToBeAddedName(student) {
      return student.details_filled_in ? student.name : student.email + ' (not yet logged in)';
    },
  },
};
</script>
