<template>
  <div>
    <v-list class="py-0">
      <v-list-item ripple @click="selectAllChanged(!selectAll)" :title="readonly ? '' : 'Select all'">
        <template #prepend v-if="!readonly">
          <mosaic-checkbox
            :model-value="selectAll"
            name="select-all"
            color="primary"
            @update:model-value="selectAllChanged"
            no-icon
            density="comfortable"
            :readonly="readonly"
            class="mr-2"
          />
        </template>

        <template #append>
          <slot name="details-header"></slot>
          <div class="d-flex justify-center mr-4" style="width: 160px">Status</div>
        </template>
      </v-list-item>
    </v-list>

    <v-divider />

    <v-list>
      <template v-for="assignee in paginatedAssigneesWithStatus" :key="assignee.id">
        <v-list-item
          ripple
          :value="assignee.id"
          :title="assignee.displayName"
          :prepend-icon="readonly ? mdiIcons.instructorAccounts : undefined"
          @click="selectedChanged(assignee, !assignee.selected)"
        >
          <template #prepend v-if="!readonly">
            <mosaic-checkbox
              no-icon
              name="select"
              :model-value="assignee.selected"
              color="primary"
              @update:model-value="selectedChanged(assignee, $event)"
              density="comfortable"
              :readonly="readonly"
              class="mr-2"
            />
          </template>

          <template #append>
            <div class="d-flex align-center">
              <slot name="details" :assignee="assignee"></slot>
            </div>
            <div class="mr-4 text-center" style="width: 160px">
              <div v-if="assignee.statusChip">
                <mosaic-tooltip-chip :color="assignee.statusChip.color">
                  <template #text>
                    {{ assignee.statusChip.label }}
                  </template>

                  <template #tooltip>
                    {{ assignee.statusChip.tooltip }}
                  </template>
                </mosaic-tooltip-chip>
              </div>
              <div v-else>
                <slot name="status-badge" :assignee="assignee"></slot>
              </div>
            </div>
          </template>
        </v-list-item>
      </template>
    </v-list>

    <mosaic-pagination v-model="currentPage" v-model:page-size="pageSize" :total="paginationTotal" />
  </div>
</template>

<script setup lang="ts">
import { formatDate } from '@/mixins/global-mixins';
import { computed, ref } from 'vue';
import { paginateList } from '@/components/library/pagination/pagination';
import { watchEffect } from 'vue';
import { mdiIcons } from '@/utils/icons';

interface Assignee {
  id: number;
  displayName: string;
}

const props = withDefaults(
  defineProps<{
    assignees: Assignee[];
    // selectedIds may contain ids not in assignees, e.g. assignees may be already filtered
    selectedIds: number[];
    initiallySelectedIds: number[];
    actionNoun: string;
    assigneeNoun: string;
    isEditing: boolean;
    scheduledDate?: string;
    actionSetInTheFuture?: boolean;
    actionOriginallySetInTheFuture?: boolean;
    readonly?: boolean;
    pendingAssignmentLabel?: string;
    pendingAssignmentTooltipAction?: string;
  }>(),
  {
    pendingAssignmentLabel: 'Pending Assignment',
    pendingAssignmentTooltipAction: 'assigned to',
  }
);

const emit = defineEmits<{
  (e: 'update:selectedIds', ids: number[]): void;
}>();

// Selected
const selectedIdsInternal = ref(props.selectedIds);

watchEffect(() => (selectedIdsInternal.value = props.selectedIds));
watchEffect(() => emit('update:selectedIds', selectedIdsInternal.value));

const assigneeIdsPendingAdd = computed(() =>
  selectedIdsInternal.value.filter(id => !props.initiallySelectedIds.includes(id))
);
const assigneeIdsPendingRemove = computed(() =>
  props.initiallySelectedIds.filter(id => !selectedIdsInternal.value.includes(id))
);

function selectedChanged(assignee: Assignee, selected: boolean | null) {
  if (props.readonly) return;
  if (selected) {
    selectedIdsInternal.value.push(assignee.id);
  } else {
    selectedIdsInternal.value = selectedIdsInternal.value.filter(x => x !== assignee.id);
  }
}

// Select all
const selectAll = computed(() => {
  return (
    selectedIdsInternal.value.length > 0 &&
    props.assignees.length > 0 &&
    props.assignees.every(s => selectedIdsInternal.value.includes(s.id))
  );
});

function selectAllChanged(x: boolean | null) {
  if (props.readonly) return;
  const assigneeIds = props.assignees.map(x => x.id);
  if (x) {
    selectedIdsInternal.value = [...selectedIdsInternal.value, ...assigneeIds].unique();
  } else {
    selectedIdsInternal.value = selectedIdsInternal.value.filter(s => !assigneeIds.includes(s));
  }
}

/// Assignees
const {
  paginatedList: paginatedAssignees,
  currentPage,
  pageSize,
  paginationTotal,
} = paginateList(computed(() => props.assignees));

const paginatedAssigneesWithStatus = computed(() =>
  paginatedAssignees.value.map(a => ({
    ...a,
    statusChip: statusChip(a),
    selected: selectedIdsInternal.value.includes(a.id),
  }))
);

function statusChip(assignee: Assignee) {
  if (
    assigneeIdsPendingAdd.value.includes(assignee.id) ||
    (props.selectedIds.includes(assignee.id) && !props.actionSetInTheFuture && props.actionOriginallySetInTheFuture)
  ) {
    return {
      label: props.pendingAssignmentLabel,
      tooltip: `The ${props.actionNoun} will be ${props.pendingAssignmentTooltipAction} the ${props.assigneeNoun} on ${
        props.actionSetInTheFuture && props.scheduledDate
          ? formatDate(props.scheduledDate)
          : props.isEditing
          ? 'save'
          : 'creation'
      }`,
      color: 'green',
    };
  } else if (assigneeIdsPendingRemove.value.includes(assignee.id)) {
    return {
      label: 'Pending Removal',
      tooltip: `The ${props.actionNoun} will be removed from the ${props.assigneeNoun}'s account on save`,
      color: 'red',
    };
  } else if (props.actionSetInTheFuture && props.scheduledDate && props.selectedIds.includes(assignee.id)) {
    return {
      label: 'Scheduled',
      tooltip: `The ${props.actionNoun} will be assigned to the ${props.assigneeNoun} on ${formatDate(
        props.scheduledDate
      )}`,
      color: 'purple',
    };
  } else {
    return null;
  }
}
</script>
