<template>
  <div>
    <v-select
      id="role-select"
      v-model="selectedRoleId"
      prepend-icon="mdi-account-multiple-check"
      :items="roles"
      item-title="name"
      item-value="id"
      label="Role"
    ></v-select>
    <v-autocomplete
      v-if="scopes"
      id="role-select-scope"
      v-model="selectedScopeId"
      :label="selectScopeLabel"
      :items="scopes"
      prepend-icon="mdi-account-multiple"
      color="black"
      item-title="name"
      item-value="scopeId"
      chips
      :custom-filter="filterScopes"
    >
      <template #item="{ item, props }">
        <v-list-item v-bind="props" title="">
          <div class="d-flex">
            <div class="flex-grow-1">{{ item.raw.name }}</div>
            <v-chip v-if="item.raw.chip" color="secondary">{{ item.raw.chip }}</v-chip>
          </div>
        </v-list-item>
      </template>
    </v-autocomplete>
    <v-autocomplete
      v-if="selectedScopeId === -1"
      id="role-select-cohort-no-trainee-yet"
      v-model="selectedNoStudentYetCohortId"
      :label="`Cohort that their ${traineeNounCapitalised()} will be in`"
      :items="activeCohortScopes"
      prepend-icon="mdi-google-classroom"
      color="black"
      item-title="name"
      item-value="id"
      chips
      :custom-filter="filterScopes"
    >
      <template #item="{ item, props }">
        <v-list-item v-bind="props">
          <div class="d-flex flex-grow-1">
            <div class="flex-grow-1">{{ item.name }}</div>
            <v-chip v-if="item.chip" color="secondary">{{ item.chip }}</v-chip>
          </div>
        </v-list-item>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'RoleSelect',
  props: {
    role: { type: Object, required: false },
  },
  emits: ['update:role', 'update:scope', 'update:noStudentYetCohortId', 'update:valid'],
  data: () => ({
    selectedRoleId: null,
    selectedScopeId: null,
    selectedNoStudentYetCohortId: null,
  }),
  computed: {
    ...mapState(['selectedInstitution', 'institutionStudents', 'roles', 'cohorts', 'schools']),
    selectScopeLabel() {
      if (!this.roleTypes) return 'Select Scope';
      const scopes = this.roleTypes.map(x => {
        if (x === 'student') {
          return this.traineeNounCapitalised();
        }
        return `${x[0].toLocaleUpperCase()}${x.substring(1)}`;
      });
      return scopes.join(' or ');
    },
    roleTypes() {
      const role = this.roles.find(x => x.id === this.selectedRoleId);
      if (!role) return null;

      const roleTypes = [];
      if (role.permissions.every(x => x.student_scope)) {
        roleTypes.push('student');
      }
      if (role.permissions.every(x => x.school_scope)) {
        roleTypes.push('school');
      }
      if (role.permissions.every(x => x.cohort_scope)) {
        roleTypes.push('cohort');
      }
      if (role.permissions.every(x => x.institution_scope)) {
        roleTypes.push('institution');
      }

      return roleTypes;
    },
    cohortScopes() {
      return this.cohorts.map(x => ({
        id: x.id,
        scopeId: `cohort-${x.id}`,
        name: x.name,
        type: 'cohort',
        status: x.status,
        chip: x.status === 'closed' ? 'Closed' : null,
      }));
    },
    activeCohortScopes() {
      return this.cohortScopes.filter(x => x.status === 'active');
    },
    scopes() {
      if (!this.roleTypes || (this.roleTypes.length === 1 && this.roleTypes[0] === 'institution')) {
        return null;
      }

      let scopes = [];
      if (this.roleTypes.includes('student')) {
        if (!this.selectedInstitution.config.early_careers) {
          scopes.push({ id: -1, scopeId: -1, name: `No ${this.traineeNounCapitalised()} yet`, type: 'student' });
        }
        scopes = scopes.concat(
          this.institutionStudents.map(x => {
            return {
              id: x.id,
              scopeId: `student-${x.id}`,
              name: x.name ? `${x.name} (${x.email})` : x.email,
              type: 'student',
            };
          })
        );
      }

      if (this.roleTypes.includes('cohort')) {
        scopes = scopes.concat(this.cohortScopes);
      }

      if (this.roleTypes.includes('school')) {
        scopes = scopes.concat(
          this.schools.map(x => ({ id: x.id, scopeId: `school-${x.id}`, name: x.displayName, type: 'school' }))
        );
      }

      if (this.roleTypes.includes('institution')) {
        scopes.push({
          id: `institution`,
          scopeId: `institution`,
          name: this.selectedInstitution.name,
          type: 'institution',
        });
      }

      return scopes;
    },
    selectedScope() {
      const scopeId = this.selectedScopeId;
      if (scopeId === 'institution') {
        return {
          id: this.selectedInstitution.id,
          name: this.selectedInstitution.name,
          type: 'institution',
        };
      } else {
        return this.scopes?.find(x => x.scopeId === scopeId);
      }
    },
    valid() {
      const scopeValid = this.selectedScope && (this.selectedScope.id !== -1 || this.selectedNoStudentYetCohortId);
      return this.selectedRoleId && scopeValid;
    },
  },
  watch: {
    role(role) {
      if (this.selectedRoleId !== role?.id) {
        this.selectedRoleId = role?.id;
      }
    },
    selectedRoleId(id) {
      const role = this.roles.find(x => x.id === id);
      this.$emit('update:role', role);
      if (this.roleTypes && this.roleTypes.length === 1 && this.roleTypes[0] === 'institution') {
        this.selectedScopeId = 'institution';
      } else {
        this.selectedScopeId = null;
      }
    },
    selectedScope(x) {
      this.$emit('update:scope', x);
    },
    selectedNoStudentYetCohortId(id) {
      this.$emit('update:noStudentYetCohortId', id);
    },
    valid(x) {
      this.$emit('update:valid', x);
    },
  },
  async created() {
    this.$store.dispatch('loadAllActiveStudents');
    await Promise.all([
      this.$store.dispatch('loadRoles'),
      this.$store.dispatch('loadCohorts'),
      this.$store.dispatch('loadSchools'),
    ]);
    this.selectedRoleId = this.role?.id;
  },
  methods: {
    filterScopes(name, queryText) {
      const searchText = queryText.toLowerCase();
      return name.toLowerCase().includes(searchText);
    },
  },
};
</script>
