<template>
  <div>
    <v-card v-for="(topic, ind) in topics" :key="ind" class="mb-6">
      <v-card-title class="pb-4">{{ topic.name }}</v-card-title>
      <v-card-text>
        <v-row>
          <v-expansion-panels variant="accordion">
            <v-expansion-panel v-for="(proficiency, index) in topic.proficiencies" :key="index">
              <v-expansion-panel-title>
                <v-row no-gutters>
                  <v-col cols="8" class="pr-6">
                    <span>{{ proficiency.statement }}</span>
                  </v-col>
                  <v-col cols="4" class="text--secondary">
                    <v-row no-gutters style="width: 100%" justify="end">
                      <v-col cols="12">
                        <span class="pr-2">Best Rating:</span>
                        <v-chip label :color="bestAssessmentRating(proficiency).color" variant="flat">
                          {{ bestAssessmentRating(proficiency).label }}
                        </v-chip>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-expansion-panel-title>
              <v-expansion-panel-text>
                <v-divider />
                <div class="font-italic">
                  {{ proficiency.example }}
                </div>
                <div class="font-weight-bold pt-6">Assessments</div>
                <v-list>
                  <v-list-item
                    v-for="(assessment, i) in proficiency.proficiency_assessments"
                    :key="i"
                    :title="assessment.description"
                  >
                    <template #append>
                      <v-list-item-action :end="true">
                        <div>
                          <div>
                            <v-btn-toggle
                              v-model="assessment.rating_int"
                              @update:model-value="
                                $emit('ratingChanged', assessment.id, assessment.rating_int, topic.id, proficiency.id)
                              "
                              divided
                              variant="outlined"
                            >
                              <v-btn
                                v-for="rating in ratings"
                                :key="rating.int"
                                :disabled="readOnly"
                                :color="rating.color"
                              >
                                {{ rating.label }}
                              </v-btn>
                            </v-btn-toggle>
                          </div>
                          <div>{{ assessment.last_updated ? 'Recorded on: ' + assessment.last_updated : '' }}</div>
                        </div></v-list-item-action
                      >
                    </template>
                  </v-list-item>
                </v-list>
              </v-expansion-panel-text>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'ProficienciesList',
  props: {
    topics: {
      type: Array,
      required: true,
    },
    ratings: {
      type: Array,
      required: true,
    },
    readOnly: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['rating-changed', 'ratingChanged'],
  data: function () {
    return {};
  },
  methods: {
    bestAssessmentRating: function (proficiency) {
      let bestRatingInt = -1;
      proficiency.proficiency_assessments
        .filter(a => {
          return a.rating_int !== null;
        })
        .forEach(ra => {
          bestRatingInt = ra.rating_int > bestRatingInt ? ra.rating_int : bestRatingInt;
        });
      if (bestRatingInt == -1) return { int: null, color: 'grey', label: '-' };
      const bestRating = this.ratings.find(r => r.int == bestRatingInt);
      return bestRating;
    },
  },
};
</script>
