<template>
  <div>
    <mosaic-loading-card v-if="busy" type="two-cards" />
    <mosaic-load-error-card v-else-if="error" object-type="Mentor Meeting" @retry="loadMentorMeeting" />
    <mentor-meeting-card
      v-else
      ref="mentorMeetingCard"
      v-model:mentor-meeting="mentorMeeting"
      :mentor-meeting-template="mentorMeetingTemplate"
      :editable="selectedInstitution.config.students_can_edit_mentor_meetings"
      :curriculum-statement-id="
        $route.query.curriculumStatementId ? $route.query.curriculumStatementId.toString() : undefined
      "
      :student-id="userStudent.id"
      :can-add-targets="canAddTargets"
      targets-url="/targets"
      :target-clicked-to="targetClickedTo"
      @click-create-target="createTarget"
      @update:dirty="$emit('update:dirty', $event)"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import MentorMeetingCard from './MentorMeetingCard.vue';
import { fromSnakeCaseToCamelCase } from '@/utils/transforms';

export default {
  name: 'StudentMentorMeetingEditPage',
  components: { MentorMeetingCard },
  emits: ['update:dirty'],
  expose: ['save'],
  data() {
    return {
      id: null,
      mentorMeeting: null,
      error: false,
      busy: true,
    };
  },
  computed: {
    ...mapState(['selectedInstitution', 'userStudent']),
    mentorMeetingTemplate() {
      return fromSnakeCaseToCamelCase(this.userStudent.cohort.mentor_meeting_template);
    },
    isEditing() {
      return this.$route.name === 'MentorMeetingPage';
    },
    breadcrumbs() {
      return [
        {
          text: 'Mentor Meetings',
          to: {
            name: 'MentorMeetingsListPage',
          },
        },
        {
          text: this.isEditing ? this.mentorMeeting?.title : 'New Mentor Meeting',
        },
      ];
    },
    canAddTargets() {
      return this.userStudent.institution.config.students_can_edit_mentor_meeting_targets;
    },
  },
  async created() {
    if (this.isEditing) {
      this.id = this.$route.params.id.toString();
      await this.loadMentorMeeting();
    } else {
      this.busy = false;
    }
  },
  methods: {
    save() {
      return this.$refs.mentorMeetingCard.save();
    },
    async loadMentorMeeting() {
      this.busy = true;
      this.error = false;
      try {
        const response = await this.$api.get(`mentor-meetings/${this.id}`);
        this.mentorMeeting = response.data;
      } catch (e) {
        console.log(e);
        this.error = true;
      }
      this.busy = false;
    },
    // We want to always return to the EditPage (especially if on the CreatePage)
    returnUrl() {
      let url = this.$router.resolve({
        name: 'MentorMeetingPage',
        params: { studentId: this.userStudent.id, id: this.mentorMeeting.id.toString() },
      }).href;
      // It appears that when the URL is used with this.$router.push(this.returnUrl) it needs the starting #/ removing
      if (url.startsWith('#')) {
        url = url.slice(1);
      }
      return url;
    },
    createTarget() {
      this.$router.push({
        name: 'MentorMeetingTargetCreatePage',
        params: { studentId: this.userStudent.id, mentorMeetingId: this.mentorMeeting.id.toString() },
        query: { returnUrl: this.returnUrl(), mentorMeetingDate: this.mentorMeeting.meeting_date },
      });
    },
    targetClickedTo(t) {
      return {
        name: 'MentorMeetingTargetPage',
        params: {
          studentId: this.userStudent.id.toString(),
          mentorMeetingId: this.mentorMeeting.id.toString(),
          targetId: t.id.toString(),
        },
        query: { returnUrl: this.returnUrl() },
      };
    },
  },
};
</script>
