<template>
  <v-card elevation="4">
    <v-toolbar color="white" elevation="6">
      <v-toolbar-title v-if="!busy && !error">Email Succesfully Verified</v-toolbar-title>
      <v-toolbar-title v-if="!busy && error">Email Verification Failed</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <mosaic-error-alert :override-error-message="error" />
      <div v-if="!busy && !error">
        <div>
          You have just succesfully verified your Mosaic email.<span v-if="isMobile">
            You can now close this tab and continue from where you left off, or you can now go to Mosaic and
            login.</span
          >
        </div>
        <div v-if="!isMobile" class="d-flex justify-center pt-4">
          <v-btn color="primary" @click.prevent="continueToMosaic()">Continue to Mosaic</v-btn>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'VerifyEmailClickedPage',
  data: function () {
    return {
      busy: false,
      error: '',
      nextStep: '',
    };
  },
  computed: {
    isMobile() {
      return this.$vuetify.display.mobile;
    },
  },
  created() {
    this.verifyEmail();
  },
  methods: {
    async verifyEmail() {
      this.error = '';
      this.busy = true;
      try {
        const r = await this.$api.post('verify-email-clicked', { token: this.$route.query.token });
        this.nextStep = r.data.next_step;
      } catch (e) {
        if (e.response?.status === 404) {
          this.error = `This verification link has expired, please click the link from your most recent verification email.`;
        } else {
          console.log(e);
          this.error = `Sorry, cannot verify your email at the moment. Please check your internet and, if you're still having issues, send an email to support@penrose.education.`;
        }
      }
      this.busy = false;
    },
    continueToMosaic() {
      this.$router.push({ name: this.nextStep });
    },
  },
};
</script>
