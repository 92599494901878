import type { Cohort, Subject } from '@/store/map-store';
import type { CurriculumThemes, CurriculumStatement } from '@/stores/curriculum';

export function visibilitySubtitle(
  selectedCohort: Cohort | null,
  curriculumTheme: CurriculumThemes[number] | undefined,
  curriculumStatement: CurriculumStatement,
  subject: Subject | undefined,
  traineeNounPluralised: string
) {
  let subtitle = 'Visibility: ';
  if (
    !curriculumStatement.studentVisible ||
    (curriculumTheme && !curriculumTheme.studentVisible) ||
    (selectedCohort && !selectedCohort.show_curriculum_for_students)
  ) {
    subtitle = 'Hidden, but configured for';
  } else {
    subtitle = 'Visible to';
  }

  subtitle += ' ';

  if (!subject || subject.name === 'All Subjects') {
    subtitle += 'all subjects and phases';
  } else {
    subtitle += `${subject.name} ${traineeNounPluralised}`;
  }

  return subtitle;
}
