<template>
  <v-expansion-panels>
    <v-expansion-panel>
      <template #title>
        <div class="d-flex">
          <img :src="resource.imageFileUrl" :alt="resource.imageAltText" height="80" style="display: block" />
          <div class="wrap-text ml-4 flex-grow-1">
            <div class="d-flex align-start">
              <mosaic-card-heading>{{ resource.title }}</mosaic-card-heading>
              <mosaic-icon-btn
                class="pb-0 mb-0"
                icon="mdi-open-in-new"
                color="primary"
                size="x-small"
                @click="openResource(resource.url)"
              />
            </div>
            <div class="text-caption">
              {{ resource.subtitle }}
            </div>
          </div>
        </div>
      </template>
      <template #text>
        <mosaic-quill
          style="margin-left: -14px"
          :read-only="true"
          :contents="resource.description"
          name="add-description"
          label="Description"
        />
      </template>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script setup lang="ts">
import MosaicQuill from '@/components/quill/MosaicQuill.vue';
import type { ProfessionalResource } from './professional-resources';
defineProps<{
  resource: ProfessionalResource;
}>();

const openResource = (url: string) => {
  window.open(url, '_blank');
};
</script>

<style>
.content-container {
  width: 100%;
}
.floated {
  float: left;
}
.wrap-text {
  word-wrap: break-word;
  float: none;
}
</style>
