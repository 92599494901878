<template>
  <mosaic-expansion-list flat :items="targets">
    <template #header="{ item, expanded }">
      <div class="d-flex align-center flex-grow-1">
        <div class="d-flex flex-column flex-grow-1 mr-4">
          <div class="py-2">{{ item.goal }}</div>
          <div v-if="!hideStudentName">
            <em>{{ item.studentDisplayName }}</em>
          </div>
        </div>
        <div class="flex-grow-1" />
        <div style="min-width: 240px" class="d-flex justify-end">
          <div class="pr-2" style="width: 28%">
            <mosaic-icon-badge
              v-if="!expanded"
              :color="item.curriculumStatements.length ? 'primary' : 'secondary'"
              icon="mdi-hexagon"
              :tooltip="`${item.curriculumStatements.length} Curriculum Links`"
            >
              {{ item.curriculumStatements.length }}
            </mosaic-icon-badge>
          </div>
          <div class="pr-2" style="width: 28%">
            <mosaic-icon-badge
              v-if="!expanded"
              color="accent"
              icon="mdi-run"
              :tooltip="`${item.targetActions.length} Actions`"
            >
              {{ item.targetActions.length }}
            </mosaic-icon-badge>
          </div>
          <v-chip :color="statusMap(item).color" style="min-width: 44%" class="mr-2"
            ><span>{{ statusMap(item).text }}</span></v-chip
          >
        </div>
      </div></template
    >
    <template #content="{ item: target }">
      <div>
        <v-table>
          <colgroup>
            <col span="1" style="width: 20%" />
            <col span="1" style="width: 80%" />
          </colgroup>
          <tbody>
            <tr>
              <td>Description</td>
              <td>{{ target.impactGoal || 'Not recorded' }}</td>
            </tr>
            <tr>
              <td>Actions</td>
              <td>
                <ul v-if="target.targetActions.length">
                  <li v-for="action in target.targetActions" :key="action.id">{{ action.text }}</li>
                </ul>
                <div v-else>None</div>
              </td>
            </tr>
            <tr>
              <td>Curriculum Links</td>
              <td>
                {{ curriculumStatementsCSV(target) }}
              </td>
            </tr>
          </tbody>
        </v-table>
      </div></template
    >
  </mosaic-expansion-list>
</template>

<script setup lang="ts">
defineProps<{
  targets: QATarget[];
  hideStudentName?: boolean;
}>();

interface TargetAction {
  text: string;
}

export interface QATarget {
  goal: string;
  impactGoal: string;
  studentDisplayName: string;
  studentId: number;
  studentGroupIds: number[];
  status: 'active' | 'completed' | 'approved';
  curriculumStatements: { code: string; id: number }[];
  targetActions: TargetAction[];
}

const statusMap = (target: QATarget) => {
  switch (target.status) {
    case 'active':
      return {
        color: 'accent',
        text: 'Active',
      };
    case 'completed':
      return {
        color: 'primary',
        text: 'Completed',
      };
    case 'approved':
      return {
        color: 'secondary',
        text: 'Approved',
      };
  }
};

const curriculumStatementsCSV = (target: QATarget) =>
  target.curriculumStatements.length ? target.curriculumStatements.map(s => s.code).join(', ') : 'None';
</script>
