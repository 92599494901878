<template>
  <div>
    <v-card>
      <v-card-title>
        <div class="pl-6">{{ standardName }}</div>
      </v-card-title>

      <v-card-text>
        <div v-if="!substandards.length" class="pl-6">No substandards for this substandard Standard</div>
        <v-alert v-if="institutions.length" color="accent" class="mx-6">
          CAUTION: Any edits made on this page will be reflected in the UI for users in institutions using this standard
          set. This includes ordering.
        </v-alert>
        <div v-if="institutions.length" class="px-6 wrap">
          {{ 'Institutions using this standard set: ' + institutions.map(i => i.name).join(', ') }}
        </div>
        <mosaic-list :items="substandards">
          <template #item="{ item: substandard }">
            <mosaic-list-item
              :key="substandard.id"
              :title="substandard.code + ': ' + substandard.name"
              :subtitle="renderSubstandardParams(substandard)"
            >
              <template #actions>
                <ndt-icon-button
                  v-if="index != 0"
                  icon="chevron-up"
                  tooltip="Change order: move up"
                  @click.prevent="moveUp(substandard)"
                />
                <ndt-icon-button
                  v-if="index != substandards.length - 1"
                  icon="chevron-down"
                  tooltip="Change order: move down"
                  @click.prevent="moveDown(substandard)"
                />
                <ndt-icon-button
                  icon="pencil"
                  tooltip="Edit substandard"
                  @click.prevent="editSubstandard(substandard)"
                />
                <ndt-icon-button
                  icon="delete"
                  tooltip="Delete substandard"
                  :disabled="institutions.length > 0"
                  @click.prevent="deleteSubstandard(substandard)"
                />
              </template>
            </mosaic-list-item>
          </template>
        </mosaic-list>
        <v-divider />
        <v-list-item>
          <template #prepend>
            <v-avatar>
              <v-icon>mdi-plus-circle-outline</v-icon>
            </v-avatar>
          </template>

          <span class="text-h6">New Substandard</span>
          <div class="d-flex">
            <div style="width: 100px">
              <v-text-field v-model="newSubstandard.code" label="Code" hide-details></v-text-field>
            </div>
            <div class="flex-grow-1 px-4">
              <v-text-field v-model="newSubstandard.name" label="Name" hide-details></v-text-field>
            </div>
          </div>
          <div class="d-flex">
            <mosaic-checkbox v-model="newSubstandard.professional" no-icon class="pr-6" label="Part 2" />
            <mosaic-checkbox v-model="newSubstandard.has_review" no-icon class="pr-6" label="Has Review" />
            <div>
              <v-select
                v-model="newSubstandard.subject_id"
                prepend-icon="mdi-pencil-ruler"
                name="edit-student-subject"
                :items="subjectsWithNullOption"
                placeholder="Subject"
                item-title="name"
                item-value="id"
              />
            </div>
          </div>

          <v-list-item-action>
            <v-btn :disabled="!canAddSubstandard" @click.prevent="addSubstandard">Add</v-btn>
          </v-list-item-action>
        </v-list-item>
        <v-alert v-if="errorMessage.length" type="error" closable>{{ errorMessage }}</v-alert>
      </v-card-text>
    </v-card>

    <ndt-dialog
      v-model:active="editSubstandardDialog.active"
      title="Update Substandard Name"
      :error-message="editSubstandardDialog.errorMessage"
      :on-close="onDialogClose"
    >
      <div class="d-flex">
        <div style="width: 70px">
          <mosaic-text-field v-model="editSubstandardDialog.code" no-icon label="Code" hide-details />
        </div>
        <div class="flex-grow-1 px-4">
          <mosaic-text-field v-model="editSubstandardDialog.name" no-icon label="Name" hide-details />
        </div>
      </div>
      <div class="d-flex">
        <mosaic-checkbox v-model="editSubstandardDialog.professional" no-icon class="pr-6" label="Part 2" />
        <mosaic-checkbox v-model="editSubstandardDialog.has_review" no-icon class="pr-6" label="Has Review" />
        <div>
          <v-select
            v-model="editSubstandardDialog.subject_id"
            prepend-icon="mdi-pencil-ruler"
            name="edit-student-subject"
            :items="subjectsWithNullOption"
            placeholder="Subject"
            item-title="name"
            item-value="id"
          />
        </div>
      </div>
      <template #buttons>
        <v-btn
          variant="text"
          ripple
          :disabled="editSubstandardDialog.processing"
          @click.prevent="submitEditSubstandard()"
          >Save</v-btn
        >
      </template>
    </ndt-dialog>
    <ndt-dialog v-model:active="deleteDialog.active" title="Delete Substandard" :width="500">
      <span>Are you sure you want to delete "{{ deleteDialog.substandard }}"?</span>
      <template #buttons>
        <v-btn
          variant="text"
          ripple
          color="error"
          :disabled="deleteDialog.processing"
          @click.prevent="submitDeleteSubstandard()"
          >Delete</v-btn
        >
      </template>
    </ndt-dialog>
  </div>
</template>

<script>
import NdtIconButton from '../../components/NdtIconButton.vue';
import NdtDialog from '../../components/NdtDialog.vue';
import { mapState } from 'vuex';

export default {
  name: 'AdminStandardSetSubstandardsPage',
  data: function () {
    return {
      standardId: null,
      institutions: [],
      standardName: '',
      substandards: [],
      standardSetName: '',
      standardSetId: '',
      newSubstandard: {
        code: '',
        name: '',
        has_review: false,
        professional: false,
        subject_id: null,
      },
      addingSubstandard: false,
      errorMessage: '',
      deleteDialog: {
        active: false,
        substandardName: '',
        substandardId: '',
        processing: '',
      },
      editSubstandardDialog: {
        active: false,
        code: '',
        name: '',
        has_review: false,
        professional: false,
        subject_id: null,
        SubstandardId: null,
        errorMessage: '',
        processing: false,
      },
    };
  },
  created: async function () {
    await this.getSubstandards();
    this.$store.dispatch('loadSubjects');
  },
  computed: {
    ...mapState(['subjects']),
    canAddSubstandard() {
      return this.newSubstandard.code.length && this.newSubstandard.name.length && !this.addingSubstandard;
    },
    subjectsWithNullOption() {
      return this.subjects.concat({
        name: 'All Subjects',
        id: null,
      });
    },
    breadcrumbs() {
      return [
        {
          text: 'Standard Sets',
          to: {
            name: 'AdminStandardSetsPage',
          },
        },
        ...(this.standardId && this.standardSetName && this.standardName
          ? [
              {
                text: this.standardSetName,
                to: {
                  name: 'AdminStandardSetStandardsPage',
                  params: {
                    standardSetId: this.standardSetId,
                  },
                },
              },
              {
                text: this.standardName,
              },
            ]
          : []),
      ];
    },
  },
  methods: {
    async getSubstandards() {
      this.standardId = this.$route.params.standardId.toString();
      const r = await this.$api.get(`/admin-standards/${this.standardId}`);
      this.substandards = r.data.substandards;
      this.standardName = r.data.name;
      this.standardSetName = r.data.standard_set.name;
      this.standardSetId = r.data.standard_set.id;
      this.institutions = r.data.standard_set.institutions;
    },
    renderSubstandardParams(substandard) {
      const flags = [];
      if (substandard.has_review) flags.push('Has Reviews');
      if (substandard.professional) flags.push('Part2 Review Format');
      if (substandard.subject?.name) flags.push(substandard.subject.name);
      return flags.join(', ');
    },
    async addSubstandard() {
      this.errorMessage = '';
      this.addingSubstandard = true;
      const order = this.substandards.length
        ? Math.max.apply(
            null,
            this.substandards.map(t => t.order)
          ) + 1
        : 1;
      try {
        const r = await this.$api.post('/admin-substandards', {
          ...this.newSubstandard,
          standard_id: this.standardId,
          order: order,
        });
        //why not working?
        this.substandards.push(r.data);
        this.newSubstandard.name = '';
        this.newSubstandard.code = '';
      } catch (e) {
        console.log(e);
        this.errorMessage = 'Sorry, cannot add a substandard right now';
      }
      this.addingSubstandard = false;
    },
    editSubstandard(substandard) {
      this.editSubstandardDialog = {
        active: true,
        substandardId: substandard.id,
        subject_id: substandard.subject?.id,
        ...substandard,
      };
    },
    deleteSubstandard(substandard) {
      this.deleteDialog = {
        active: true,
        substandardId: substandard.id,
        substandard: substandard.name,
        processing: false,
      };
    },
    submitDeleteSubstandard() {
      this.deleteDialog.processing = true;
      this.$api.delete(`/admin-substandards/${this.deleteDialog.substandardId}`).then(() => {
        this.deleteDialog.active = false;
        this.getSubstandards();
      });
    },
    async submitEditSubstandard() {
      try {
        this.editSubstandardDialog.processing = true;
        const r = await this.$api.put(`/admin-substandards/${this.editSubstandardDialog.substandardId}`, {
          ...this.editSubstandardDialog,
        });
        this.editSubstandardDialog.processing = false;
        const substandard = r.data;
        const i = this.substandards.findIndex(s => s.id === substandard.id);
        this.substandards.splice(i, 1, substandard);
        this.onDialogClose();
      } catch (e) {
        this.editSubstandardDialog.errorMessage = 'Sorry, cannot update this template right now';
        throw e;
      }
    },
    onDialogClose() {
      this.editSubstandardDialog = {
        active: false,
        name: '',
        SubstandardId: null,
        errorMessage: '',
        processing: false,
      };
    },
    async moveUp(substandard) {
      const SubstandardIndex = this.substandards.findIndex(t => t.id === substandard.id);
      if (SubstandardIndex === 0) return;
      const higherSubstandard = this.substandards[SubstandardIndex - 1];
      const substandardsToUpdate = [
        {
          id: substandard.id,
          order: substandard.order - 1,
        },
        {
          id: higherSubstandard.id,
          order: higherSubstandard.order + 1,
        },
      ];
      await this.$api.post(`admin-substandards/update-ordering`, {
        substandardsToUpdate: substandardsToUpdate,
      });
      await this.getSubstandards();
    },
    async moveDown(substandard) {
      const SubstandardIndex = this.substandards.findIndex(t => t.id === substandard.id);
      if (SubstandardIndex === this.substandards.length - 1) return;
      const lowerSubstandard = this.substandards[SubstandardIndex + 1];
      const substandardsToUpdate = [
        {
          id: substandard.id,
          order: substandard.order + 1,
        },
        {
          id: lowerSubstandard.id,
          order: lowerSubstandard.order - 1,
        },
      ];
      await this.$api.post(`admin-substandards/update-ordering`, {
        substandardsToUpdate: substandardsToUpdate,
      });
      await this.getSubstandards();
    },
  },
  components: { NdtIconButton, NdtDialog },
};
</script>
