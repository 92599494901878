<template>
  <div>
    <mosaic-loading-card v-if="busy || storageConstraintBusy" type="list" />
    <mosaic-load-error-card
      v-else-if="loadError || loadStorageConstraintError"
      object-type="Evidence"
      @retry="loadEvidenceAndStorageConstraint"
    />
    <evidence-list-views
      v-else
      :initial-evidence="evidenceItems"
      :can-view-reflections="userStaffHasPermissionForSelectedStudent('student.reflections.view')"
      :storage-constraint="storageConstraint"
      @click-target="clickTarget"
      @click-reflection="clickReflection"
      @click-lesson-observation="clickLessonObservation"
      @click-mentor-meeting="clickMentorMeeting"
      @click-student-assignment="clickStudentAssignment"
    >
      <div class="pl-6">{{ traineeNounCapitalised() }} has uploaded no evidence</div>
    </evidence-list-views>
  </div>
</template>

<script>
import EvidenceListViews from '../../components/EvidenceListViews.vue';
import { mapState } from 'vuex';

export default {
  name: 'TutorEvidenceListPage',
  components: { EvidenceListViews },
  data() {
    return {
      evidenceItems: [],
      busy: true,
      storageConstraintBusy: true,
      loadError: false,
      loadStorageConstraintError: false,
      storageConstraint: null,
    };
  },
  async created() {
    await this.loadEvidenceAndStorageConstraint();
  },
  computed: {
    ...mapState(['selectedStudent', 'user', 'userStaff', 'selectedInstitution']),
    breadcrumbs() {
      return [
        {
          text: 'Evidence',
        },
      ];
    },
  },
  methods: {
    async loadEvidenceAndStorageConstraint() {
      await Promise.all([this.loadEvidence(), this.loadStorageConstraint()]);
    },
    async loadEvidence() {
      this.busy = true;
      this.loadError = false;
      try {
        const response = await this.$api.get(`/students/${this.selectedStudent.id}/evidence`);
        this.evidenceItems = response.data;
      } catch (e) {
        console.log(e);
        this.loadError = true;
      }
      this.busy = false;
    },
    async loadStorageConstraint() {
      this.loadStorageConstraintError = false;
      this.storageConstraintBusy = true;
      if (this.selectedInstitution.storage_type === 'One Drive' && !this.userStaff.storage_email) {
        try {
          const r = await this.$api.get(`/institution-storage-constraints/${this.userStaff.id}`);
          this.storageConstraint = r.data;
        } catch (e) {
          console.log(e);
          this.loadStorageConstraintError = true;
        }
      }
      this.storageConstraintBusy = false;
    },
    clickReflection(reflection) {
      this.$router.push({
        name: 'ReflectionPage',
        params: { studentId: this.selectedStudent.id, reflectionId: reflection.id },
      });
    },
    clickTarget(target) {
      this.$router.push({
        name: 'TargetPage',
        params: { studentId: this.selectedStudent.id, targetId: target.id },
      });
    },
    clickLessonObservation(lo) {
      this.$router.push({
        name: 'LessonObservationPage',
        params: { studentId: this.selectedStudent.id, id: lo.id },
      });
    },
    clickMentorMeeting(meeting) {
      this.$router.push({
        name: 'MentorMeetingPage',
        params: { studentId: this.selectedStudent.id, id: meeting.id },
      });
    },
    clickStudentAssignment(sa) {
      this.$router.push({
        name: 'TutorAssignmentLatestVersionPage',
        params: { id: sa.assignment_id },
      });
    },
  },
};
</script>
