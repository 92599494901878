<template>
  <div>
    <mosaic-loading-card v-if="busy" type="list" />
    <mosaic-load-error-card v-else-if="error" :object-type="reviewNounCapitalisedAndPluralised" @retry="loadReviews" />
    <reviews-list v-else :reviews="reviews" :review-click-to="reviewClickTo">
      <div class="py-4">
        {{ traineeNounCapitalised() }} has no open {{ reviewNounCapitalisedAndPluralised }}.
        {{ contactProviderMessage }}
      </div>
    </reviews-list>
  </div>
</template>

<script>
import ReviewsList from '@/components/ReviewsList.vue';
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'TutorReviewsListPage',
  data: () => ({ reviews: [], busy: true, error: false, studentId: '' }),
  async created() {
    await this.loadReviews();
  },
  computed: {
    ...mapState(['selectedStudent', 'selectedInstitution']),
    ...mapGetters(['reviewNounPluralised', 'contactProviderMessage']),
    breadcrumbs() {
      return [
        {
          text: this.reviewNounCapitalisedAndPluralised,
        },
      ];
    },
  },
  methods: {
    async loadReviews() {
      this.busy = true;
      this.error = false;
      try {
        const response = await this.$api.get(`/students/${this.selectedStudent.id}/student-reviews`);
        this.reviews = response.data;
      } catch (e) {
        console.log(e);
        this.error = true;
      }
      this.busy = false;
    },
    reviewClickTo(id) {
      return {
        name: 'TutorReviewPage',
        params: { id },
      };
    },
  },
  components: {
    ReviewsList,
  },
};
</script>
