<template>
  <mosaic-tab-card-page
    object-type="Lesson Observations"
    :object-type-is-plural="true"
    title="Lesson Observations"
    :headers="tabHeaders"
    :load="loadLessonObservations"
    type="table"
    @tab-selected="tab = $event"
  >
    <template #monitoring-tab-item>
      <div class="d-flex align-start mb-6">
        <mosaic-cohort-monitoring-filters
          :students="selectedCohortStudents"
          @update:filtered-student-ids="filteredStudentIds = $event"
        />
        <monitoring-placement-select
          v-if="hasPlacements"
          v-model:selected-placement-id="selectedPlacementId"
          class="ml-4"
        />
      </div>
      <mosaic-cohort-weekly-monitoring-filter-table
        v-if="selectedPlacement"
        :placement-id="selectedPlacementId"
        object-type-pluralised="Lesson Observations"
        :rows="monitoringRows"
        :headers="selectedPlacement.headers"
        :name-click-route="nameClickRoute"
        list-page-route-name="LessonObservationsListPage"
      />

      <div v-else class="py-4">
        This Cohort does not have any Monitoring Windows configured. These can be added/updated in Cohort Settings.
      </div>
    </template>

    <template #template-tab-item>
      <mosaic-configure-preview>
        <template #configure>
          <div>
            Lesson Observations within Mosaic can have their structure customised by changing this template. Changes to
            the template will only apply to future Lesson Observations. Lesson Observations have a few static parts that
            cannot currently be configured e.g. observation date.
          </div>
          <mosaic-content-layout-builder
            v-model:content-layout="template"
            :supported-item-types="[
              'columns',
              'comment',
              'fileUpload',
              'image',
              'link',
              'resource',
              'taskList',
              'text',
              'video',
              'youtube',
            ]"
            :presign-url="`/presign/institutions/${selectedInstitution.id}/lesson-observation-template-resources`"
            get-resource-url-prefix="/lesson-observation-templates/resources"
            :save-resource-url="`/institutions/${selectedInstitution.id}/lesson-observation-templates/resources`"
            :save-resource-params="{ lessonObservationTemplateId: selectedCohort.lesson_observation_templates[0].id }"
            @valid="templateValid = $event"
          />
          <div class="pb-1">
            <mosaic-save-buttons
              :save="saveTemplate"
              object-type="Lesson Observation Template"
              hide-return
              :can-save="canSave"
            />
          </div>
        </template>

        <template #preview>
          <div class="pa-1">
            <lesson-observation-card
              :template-content-layout="template"
              :preview="true"
              :is-creating="true"
              :has-create-permissions="false"
              lesson-observation-url=""
              :lesson-observation="null"
              :return-to="{}"
              :student-id="-1"
              file-upload-presign-url="not-a-url"
            />
          </div>
        </template>
      </mosaic-configure-preview>
    </template>
  </mosaic-tab-card-page>

  <unsaved-changes-dialog
    v-model:unsaved-changes-dialog="dialog"
    object-type="Lesson Observation Template"
    :can-save="canSave"
    :save="saveTemplate"
  />
</template>

<script setup lang="ts">
import { useCohortStore } from '@/stores/cohort';
import { setBreadcrumbs } from '@/utils/breadcrumbs';
import { computed, ref } from 'vue';
import type { Row } from '@/components/monitoring/mosaic-table';
import MosaicCohortWeeklyMonitoringFilterTable from '@/components/monitoring/MosaicCohortWeeklyMonitoringFilterTable.vue';
import type { BaseStudentRow, MonitoringPlacement } from '../utils/monitoring';
import { useWeeklyMonitoring } from '../utils/monitoring';
import MonitoringPlacementSelect from '@/components/monitoring/MonitoringPlacementSelect.vue';
import { useApi } from '@/composables/api';
import MosaicContentLayoutBuilder from '@/components/mosaic-content-layout/MosaicContentLayoutBuilder.vue';
import type { ContentLayout } from '@/utils/content-layout';
import LessonObservationCard from '@/pages/lesson-observations/LessonObservationCard.vue';
import { fromSnakeCaseToCamelCase } from '@/utils/transforms';
import { useInstitutionStore } from '@/stores/institution';
import { hasPermissionForSelectedInstitution } from '@/composables/permission';
import { useUnsavedChanges } from '@/composables/unsaved-changes';
import UnsavedChangesDialog from '@/components/UnsavedChangesDialog.vue';

const api = useApi();
const {
  hasPlacements,
  selectedCohort,
  selectedCohortStudents,
  actions: { updateSelectedCohort },
} = useCohortStore();
const { selectedInstitution } = useInstitutionStore();

// Page Setup
setBreadcrumbs([
  {
    text: 'Development Lesson Observations',
  },
]);

type ObservationCount = Record<string, number>;
type StudentRow = BaseStudentRow & ObservationCount;

// Tabs
const tabHeaders = computed(() => {
  const h = [
    {
      text: 'Monitoring',
      key: 'monitoring',
    },
  ];

  if (hasPermissionForSelectedInstitution('Admin')) {
    h.push({
      text: 'Template',
      key: 'template',
    });
  }
  return h;
});
const tab = ref('monitoring');

// Load Lesson Observations
type MonitoringApiResponse = MonitoringPlacement<StudentRow>[];

const placements = ref<MonitoringPlacement<StudentRow>[]>([]);

async function loadLessonObservations() {
  const response = await api.get<MonitoringApiResponse>(
    `/cohorts/${selectedCohort.value.id}/lesson-observation-monitoring`
  );
  placements.value = response.data;
}

// Monitoring
const selectedPlacementId = ref<number>(-1);
const filteredStudentIds = ref<number[]>(selectedCohortStudents.value.map(s => s.id));

const { selectedPlacement, monitoringRows } = useWeeklyMonitoring<StudentRow>(
  placements,
  selectedPlacementId,
  filteredStudentIds
);

type Student = {
  id: number;
  name: string;
};

function nameClickRoute(row: Row) {
  return {
    name: 'LessonObservationsListPage',
    params: {
      studentId: (row as Student).id.toString(),
    },
  };
}

// #region template
const cohortTemplate = computed(() =>
  fromSnakeCaseToCamelCase<ContentLayout>(selectedCohort.value.lesson_observation_templates[0]?.content_layout)
);
const template = ref<ContentLayout>(cohortTemplate.value || { sections: [] });

const templateValid = ref(true);
const dirty = computed(() => JSON.stringify(cohortTemplate.value) != JSON.stringify(template.value));
const canSave = computed(() => dirty.value && templateValid.value);

async function saveTemplate() {
  const t = template.value;
  await api.put(`/cohorts/${selectedCohort.value.id}/lesson-observation-template`, {
    contentLayout: t,
  });
  updateSelectedCohort({
    lesson_observation_templates: [{ ...selectedCohort.value.lesson_observation_templates[0], content_layout: t }],
  });
}
// #endregion

const { dialog } = useUnsavedChanges(dirty);
</script>
