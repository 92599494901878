<template>
  <v-card>
    <v-card-text>
      <mosaic-card-title class="pb-1">Portfolio</mosaic-card-title>
      <mosaic-list :items="standards">
        <template #item="{ item: standard }">
          <mosaic-list-item
            :key="standard.id"
            :to="standardClickTo(standard.id)"
            :title="`${standard.code} - ${standard.name}`"
          >
            <template #subtitle>
              <span class="wrap-but-not-within-words" :class="evidenceCountTextColor(standard)">{{
                renderEvidenceCount(standard)
              }}</span>
              <span v-if="standard.substandard_count > 1"> - </span>
              <span
                class="wrap-but-not-within-words"
                v-if="standard.substandard_count > 1"
                :class="substandardCountTextColor(standard)"
              >
                {{ renderSubstandardStatus(standard) }}
              </span>
            </template>
          </mosaic-list-item>
        </template>
      </mosaic-list>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    standardClickTo: {
      type: Function,
      required: true,
    },
    standards: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    renderSubtitle: function (standard) {
      const evidenceCount = `${standard.evidence_count} piece${standard.evidence_count === 1 ? '' : 's'} of evidence`;
      const substandardStatus = `${standard.substandard_with_evidence_count}/${standard.substandard_count} substandards have evidence`;
      return `${evidenceCount} - ${substandardStatus}`;
    },

    renderEvidenceCount: function (standard) {
      return `${standard.evidence_count} piece${standard.evidence_count === 1 ? '' : 's'} of evidence`;
    },
    renderSubstandardStatus: function (standard) {
      return `${standard.substandard_with_evidence_count}/${standard.substandard_count} substandards have evidence`;
    },
    substandardCountTextColor: function (standard) {
      const evidencedSubstandardCount = standard.substandard_with_evidence_count;
      const substandardCount = standard.substandard_count;
      if (evidencedSubstandardCount == 0) {
        return 'text-red';
      } else if (evidencedSubstandardCount == substandardCount) {
        return 'text-green';
      } else {
        return 'text-orange';
      }
    },
    evidenceCountTextColor: function () {
      return 'text-black';
    },
  },
};
</script>

<style scoped>
.wrap-but-not-within-words {
  white-space: pre-wrap;
  word-break: break-word;
}
</style>
