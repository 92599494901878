<template>
  <div>
    <v-text-field v-model="attributes.title" name="evidence-title" label="Title" type="text" />
    <StandardsAutocomplete
      :selected-substandards="selectedSubstandards"
      @selected-substandards-updated="selectedSubstandardsUpdated"
    />
    <v-select v-model="attributes.type" label="Type" :items="evidenceTypes" :disabled="!canEditType" />
    <v-textarea
      v-model="attributes.description"
      name="evidence-description"
      variant="filled"
      label="Description"
      auto-grow
      type="text"
      rows="2"
    />
  </div>
</template>

<script>
import StandardsAutocomplete from './StandardsAutocomplete.vue';

export default {
  name: 'EvidenceAttributes',
  components: { StandardsAutocomplete },
  props: {
    evidenceTypes: {
      type: Array,
      required: true,
    },
    canEditType: { type: Boolean, default: true },
    title: { type: String, default: '' },
    selectedType: { type: String, default: 'Other' },
    selectedSubstandards: { type: Array, default: () => [] },
    givenDescription: { type: String, default: '' },
    // This isn't very elegant, but used to force the refresh of the description when the evidence changes
    // The issues occurs when:
    // - edit the description for some evidence with an empty description (and then save or cancel)
    // - edit anothter bit of evidence that has an empty description
    // - previous description shows instead of an empty description
    // This should be fixed by changing the data ownership here, but that requires more thought/testing
    id: { type: Number },
  },
  emits: ['attributesUpdated'],
  data: function () {
    return {
      processing: false,
      attributes: {
        title: '',
        type: 'Other',
        description: '',
        selectedSubstandards: [],
      },
    };
  },
  watch: {
    id: function (id, oldId) {
      if (id && oldId && id !== oldId) {
        // this.givenDescription should always be blank here (assuming the givenDescription watcher has already fired when the value has changed)
        this.attributes.description = this.givenDescription;
      }
    },
    title: function (val) {
      this.attributes.title = val;
    },
    selectedSubstandards: {
      handler(val) {
        this.attributes.selectedSubstandards = val;
      },
      deep: true,
    },
    selectedType: function (val) {
      this.attributes.type = val;
    },
    givenDescription: function (val) {
      this.attributes.description = val;
    },
    attributes: {
      handler: function () {
        this.emitToParent();
      },
      deep: true,
    },
  },
  created: function () {
    this.attributes = {
      title: this.title,
      type: this.selectedType,
      description: this.givenDescription,
      selectedSubstandards: this.selectedSubstandards,
    };
  },
  methods: {
    emitToParent() {
      this.$emit('attributesUpdated', this.attributes);
    },
    selectedSubstandardsUpdated(selectedSubstandards) {
      this.attributes.selectedSubstandards = selectedSubstandards;
    },
  },
};
</script>
