<template>
  <div>
    <mosaic-list-item
      class="px-0 px-2"
      :to="notification.to"
      @mouseup="markAsRead(notification)"
      :icon="notification.icon"
      :title="notification.title"
    >
      <template #subtitle>
        <div :class="{ 'text-accent': !notification.read }">
          {{ capitaliseFirstLetter(formatDateFromNow(notification.notifiedAt)) }}
          <span v-if="notification.createdBy"> by {{ notification.createdBy.displayName }}</span>
        </div>
        <div v-if="!smallScreen && notification.subtitle" style="max-height: 30px; overflow-y: hidden">
          <mosaic-quill
            v-if="notification.subtitleIsQuill"
            :read-only="true"
            :contents="notification.subtitle"
            class="notification-subtitle-quill"
            style="cursor: pointer !important"
          />
          <div v-else class="pt-1">
            {{ notification.subtitle }}
          </div>
        </div>
      </template>

      <template #actions v-if="!notification.read">
        <v-icon color="accent" size="small">mdi-circle</v-icon>
      </template>
    </mosaic-list-item>
  </div>
</template>

<script setup lang="ts">
import { mapActions } from '@/store/map-store';
import type { Notification } from './notifications';
import MosaicQuill from '@/components/quill/MosaicQuill.vue';

defineProps<{ notification: Notification }>();

const { markNotificationAsRead } = mapActions();

function markAsRead(notification: Notification) {
  if (!notification.read) {
    markNotificationAsRead({ id: notification.id });
  }
}
</script>
