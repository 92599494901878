import type { Ref } from 'vue';
import { ref, watchEffect, computed } from 'vue';
import moment from 'moment';

export interface WeeklyMonitoringHeader {
  label: string;
  key: string;
  start: string;
  end: string;
}

export type Placement = {
  end_date: string;
  id: number;
  start_date: string;
  name: string;
};

export type BaseStudentRow = {
  group_ids: number[];
  details_filled_in: boolean;
  name: string;
  id: number;
};

export type MonitoringPlacement<Row extends BaseStudentRow> = {
  placement: Placement;
  student_counts: Row[];
  headers: WeeklyMonitoringHeader[];
};

export function useWeeklyMonitoring<Row extends BaseStudentRow>(
  placements: Ref<MonitoringPlacement<Row>[]>,
  selectedPlacementId: Ref<number>,
  filteredStudentIds: Ref<number[]>
) {
  const selectedPlacement = ref<MonitoringPlacement<Row> | null>(
    placements.value[0]
  ) as Ref<MonitoringPlacement<Row> | null>;

  selectedPlacement.value = placements.value[0];

  const selectPlacementBasedOnDate = () => {
    if (placements.value.length) {
      const placementsInRange = placements.value.filter(p => {
        return moment().isBetween(p.placement.start_date, p.placement.end_date);
      });
      selectedPlacementId.value =
        placementsInRange.length == 0 ? placements.value[0].placement.id : placementsInRange[0].placement.id;
    } else selectedPlacement.value = null;
  };
  const selectPlacement = () => {
    selectedPlacement.value =
      placements.value.find(p => p.placement.id === selectedPlacementId.value) || placements.value[0];
  };

  // Triggers when placements array is updated (i.e. on page load)
  watchEffect(() => selectPlacementBasedOnDate());
  // Triggers when selectedPlacementId is updated (i.e. when user selects a placement from dropdown)
  watchEffect(() => selectPlacement());

  const monitoringRows = computed(() => {
    if (!selectedPlacement.value) return [];
    return selectedPlacement.value.student_counts.filter(s => filteredStudentIds.value.includes(s.id));
  });

  return { selectedPlacement, monitoringRows };
}
