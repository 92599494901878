<template>
  <mosaic-load-error-card
    v-if="loadRolesError"
    :object-type="reviewNounCapitalisedAndPluralised"
    @retry="$store.dispatch('loadRoles')"
  />
  <v-card v-else class="flex-grow-1">
    <v-card-text>
      <mosaic-card-title class="pb-1">{{ reviewNounCapitalisedAndPluralised }}</mosaic-card-title>
      <template v-if="reviews.length === 0">
        <slot />
      </template>

      <mosaic-list v-else :items="reviews">
        <template #item="{ item: review }">
          <mosaic-list-item ripple :to="reviewClickTo(review.id)" icon="mdi-check-circle-outline" :title="review.name">
            <template #subtitle>
              <span :class="{ 'text-red': showAsOverdue(review) }">Due {{ formatDate(review.due_date) }}</span>
              <span v-if="review.due_date != review.review.due_date" class="font-italic">
                - This due date has been updated since {{ reviewNounCapitalised }} creation</span
              >
            </template>
            <template #information>
              <template v-if="!review.approved_at">
                <mosaic-role-completed-chip
                  v-if="review.review.is_trainee_contributor"
                  class="pr-1"
                  role-id="student"
                  :completed="!!review.student_submitted_at"
                  verb="Submitted"
                />
                <mosaic-role-completed-chip
                  v-for="role of rolesThatCanComplete(review)"
                  :key="role.id"
                  class="pr-1"
                  :role-id="role.id"
                  :completed="!!submission(review, role)"
                />
              </template>
              <div v-else>
                <v-chip color="secondary">Approved</v-chip>
              </div>
            </template>
          </mosaic-list-item>
        </template>
      </mosaic-list>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { mapStateProcessingAndError } from '@/store/map-store';

export default {
  name: 'ReviewsList',
  props: {
    reviews: {
      type: Array,
      required: true,
    },

    reviewClickTo: {
      type: Function,
      required: true,
    },
  },
  data: () => ({}),
  async created() {
    await this.$store.dispatch('loadRoles');
  },
  computed: {
    ...mapState(['roles']),
    ...mapGetters(['studentHasProfessionalMentor']),
    ...mapStateProcessingAndError(['loadRoles']),
  },
  methods: {
    rolesThatCanComplete(studentReview) {
      const roleIdsThatAreContributors = studentReview.review.review_permissions
        .filter(x => x.permission_type === 'Contributor' && x.edit)
        .map(x => x.role_id);
      return this.roles.filter(x => roleIdsThatAreContributors.includes(x.id));
    },
    submission(studentReview, role) {
      return !!studentReview.student_review_submissions.find(x => x.role_id === role.id);
    },
    studentYetToComplete(review) {
      return review.review.is_trainee_contributor && !review.student_submitted_at;
    },

    showAsOverdue(review) {
      return (
        !review.approved_at &&
        (this.studentYetToComplete(review) ||
          this.rolesThatCanComplete(review).some(role => !this.submission(review, role))) &&
        this.isOverdue(review.due_date)
      );
    },
  },
};
</script>
