<template>
  <div>
    <mosaic-card-heading class="pb-2">{{ assigneeNounPluralised }}</mosaic-card-heading>

    <div>
      <div class="d-flex align-center">
        <mosaic-card-subheading icon="mdi-filter">Filters</mosaic-card-subheading>
        <div class="px-4 text-right flex-shrink-0 font-italic">
          <span>({{ filteredAssignees.length }} / {{ assignees.length }} {{ assigneeNounPluralised }})</span>
        </div>
      </div>

      <div class="px-4 d-flex flex-wrap pb-4" style="column-gap: 16px; row-gap: 8px">
        <mosaic-name-email-filter v-model="nameEmailFilter" />
        <slot name="filters"></slot>
      </div>
    </div>

    <div class="d-flex align-center mb-2">
      <mosaic-card-subheading icon="mdi-account-multiple-check"
        >{{ hideSelectedCount ? '' : 'Selected ' }}{{ assigneeNounPluralised }}</mosaic-card-subheading
      >
      <div class="px-4 text-right flex-shrink-0 font-italic" v-if="!hideSelectedCount">
        <span
          >({{ selectedIds.length }} selected<slot name="selected-text"></slot
          ><span v-if="selectedIds.length !== filteredAndSelectedCount">
            - {{ filteredAndSelectedCount }}
            {{ filteredAndSelectedCount !== 1 ? assigneeNounPluralised : assigneeNoun }} for the current filters</span
          >)</span
        >
      </div>
    </div>

    <mosaic-action-assignment-list
      :selected-ids="selectedIds"
      :initially-selected-ids="initiallySelectedIds"
      :assignees="filteredAssignees"
      :assignee-noun="assigneeNoun"
      :is-editing="isEditing"
      :action-noun="actionNoun"
      :scheduled-date="scheduledDate"
      :action-set-in-the-future="actionSetInTheFuture"
      :action-originally-set-in-the-future="actionOriginallySetInTheFuture"
      :readonly="readonly"
      :pending-assignment-label="pendingAssignmentLabel"
      :pending-assignment-tooltip-action="pendingAssignmentTooltipAction"
      @update:selected-ids="emit('update:selectedIds', $event)"
    >
      <template #status-badge="{ assignee }">
        <slot name="status-badge" :assignee="assignee"></slot>
      </template>

      <template #details-header>
        <slot name="details-header"></slot>
      </template>

      <template #details="{ assignee }">
        <slot name="details" :assignee="assignee"></slot>
      </template>
    </mosaic-action-assignment-list>

    <div v-if="filteredAssignees.length === 0" class="pa-4 pl-6">
      <div v-if="assignees.length !== filteredAssignees.length">
        There are no {{ assigneeNounPluralised }} for these filters
      </div>

      <div v-else>
        {{ emptyText || `There are no ${assigneeNounPluralised}` }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts" generic="T extends Assignee">
import { computed, ref } from 'vue';
import MosaicActionAssignmentList from './MosaicActionAssignmentList.vue';
import { filterByNameOrEmail } from '@/components/library/filters/filters';

export interface Assignee {
  id: number;
  displayName: string;
  name: string;
  email: string;
}

const props = withDefaults(
  defineProps<{
    assignees: T[];
    filter?: (a: T) => boolean;
    // selectedIds may contain ids not in assignees, e.g. assignees may be already filtered
    selectedIds: number[];
    initiallySelectedIds: number[];
    isEditing: boolean;
    assigneeNoun: string;
    assigneeNounPluralised: string;
    actionNoun: string;
    scheduledDate?: string;
    actionSetInTheFuture?: boolean;
    actionOriginallySetInTheFuture?: boolean;
    readonly?: boolean;
    hideSelectedCount?: boolean;
    emptyText?: string;
    pendingAssignmentLabel?: string;
    pendingAssignmentTooltipAction?: string;
  }>(),
  {
    filter: (_: Assignee) => true,
  }
);

const emit = defineEmits<{
  (e: 'update:selectedIds', ids: number[]): void;
}>();

// Filters
const nameEmailFilter = ref('');
const filteredAssignees = computed(() =>
  props.assignees.filter(a => filterByNameOrEmail(a, nameEmailFilter.value) && props.filter(a))
);

const filteredAndSelectedCount = computed(() => {
  const filteredIds = filteredAssignees.value.map(x => x.id);
  return props.selectedIds.filter(id => filteredIds.includes(id)).length;
});
</script>
