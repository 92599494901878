<template>
  <files-list
    :load-items="loadItems"
    files-storage-type="Mosaic"
    :get-file-url="getFileUrl"
    :can-add-as-evidence="false"
    :page-breadcrumbs="breadcrumbs"
  />
</template>

<script setup lang="ts">
import FilesList from '@/components/files-list/FilesList.vue';
import { useApi } from '@/composables/api';
import { useInstitutionStore } from '@/stores/institution';
import { computed } from 'vue';
import { useStudentStore } from '@/stores/student';
import { setBreadcrumbs } from '@/utils/breadcrumbs';

const api = useApi();
const { selectedInstitution } = useInstitutionStore();
const { userOrSelectedStudent } = useStudentStore();

const breadcrumbs = computed(() => {
  return [
    {
      text: `Course Files`,
      to: {
        name: 'CourseFilesListPage',
        params: { studentId: userOrSelectedStudent.value.id },
      },
    },
  ];
});
setBreadcrumbs(breadcrumbs);

async function loadItems(folder: { id: string; name: string; webUrl: string; isRootFolder?: boolean }) {
  const urlRoot = `institutions/${selectedInstitution.value.id}/course-files`;

  const response = await api.get<{
    items: { id: string; name: string; type: 'file' | 'folder' }[];
    folder: { id: string; name: string; webUrl: string };
  }>(`/${urlRoot}/${encodeURIComponent(folder.id)}`);

  const items = response.data.items.map(x => ({
    ...x,
    sortName: x.name,
    isMoveTarget: false,
    icon: {
      name: x.type === 'file' ? 'file' : 'folder-home',
      color: null,
      tooltip: '',
    },
    editable: false,
    canLinkEvidence: false,
    filesApiPath: `/${urlRoot}`,
  }));

  return {
    items,
    folder: {
      id: folder.id || response.data.folder.id,
      name: folder.name || response.data.folder.name,
      webUrl: folder.webUrl || response.data.folder.webUrl,
      isRootFolder: folder.isRootFolder || false,
      editable: false,
    },
  };
}

async function getFileUrl(item: { id: number }, download: boolean) {
  const r = await api.get<{ webUrl: string }>(
    `/institutions/${selectedInstitution.value.id}/course-file/${encodeURIComponent(item.id)}?download=${download}`
  );
  return r.data.webUrl;
}
</script>
