// config is an object with the following structue
// { componentData1: { query: 'how_this_appears_in_the_url', type: 'boolean' }, componentData2: { query: 'how_this_appears_in_the_url', type: 'integer' } }
// If no type is supplied then it's treated as a string

// keyPrefixProp allows same component to be in the page multiple times and all be synced to the query
// It uses a prop to dynamically retrieve the prefix
export function syncQueryParamsMixin(config, queryKeyPrefxProp = '', ignoreKey = '') {
  const watch = {};
  const methods = {};
  Object.keys(config).forEach(property => {
    const type = config[property].type;

    watch[property] = function (x) {
      this.updateQuery(x);
    };

    methods[`set${property}`] = function (param) {
      if (this[property] === param) return;

      param = convertQueryToData(type, param);

      if (param !== undefined) {
        this[property] = param;
      }
    };
  });

  return {
    created() {
      if (ignoreKey && !this[ignoreKey]) return;
      Object.keys(config).forEach(property => {
        this.$watch(`$route.query.${this.getQueryParamKey(property)}`, function (param) {
          this[`set${property}`](param);
        });
      });
    },
    mounted() {
      // Runs as mounted so it runs after component.created
      // This means any values set in the component.data/created will be overwritten with what's in the query
      Object.keys(config).forEach(property => {
        const queryParam = this.getQueryParamKey(property);
        this[`set${property}`](this.$route.query[queryParam]);
      });
      // Then ensure any values set in the component.data/created are updated in the query
      this.updateQuery();
    },
    watch,
    methods: {
      ...methods,
      getQueryParamKey(property) {
        let key = '';
        if (queryKeyPrefxProp) {
          key = this[queryKeyPrefxProp];
        }
        return key + config[property].query;
      },
      updateQuery() {
        if (ignoreKey && !this[ignoreKey]) return;
        const queryChanged = Object.keys(config).some(
          property =>
            this[property] !==
            convertQueryToData(config[property].type, this.$route.query[this.getQueryParamKey(property)])
        );
        if (!queryChanged) return;

        if (!this.queryStore) {
          console.log(
            'No setup() exposing this.queryStore for component: ' + (this.$options.name || this.$options.__file)
          );

          // Mixins cannot add a setup() function to components (https://github.com/vuejs/core/issues/1866#issuecomment-674881439),
          // so it needs to be added manually
          // e.g.
          // import { useQueryStore } from '@/stores/query';
          // ...
          // setup() {
          //   const queryStore = useQueryStore();
          //   return { queryStore };
          // },
        }
        const queryParamsRef = this.queryStore.queryParams;
        const queryParams = queryParamsRef.value;

        Object.keys(config).forEach(property => {
          if (config[property]?.ignoreSync && this[config[property].ignoreSync]) return;
          queryParams[this.getQueryParamKey(property)] = convertDataToQuery(config[property].type, this[property]);
        });

        queryParamsRef.value = { ...queryParams };
      },
    },
  };
}

// Returning undefined means the component's data won't be changed
function convertQueryToData(type, param) {
  if (param === 'null') {
    return null;
  }

  if (type === 'integer' && typeof param === 'string') {
    const value = parseInt(param);
    return isNaN(value) ? undefined : value;
  }

  if (type === 'boolean' && typeof param === 'string') {
    return param === 'false' ? false : param === 'true' ? true : undefined;
  }

  if (type === 'array') {
    return param?.split(',') || undefined;
  }

  return param;
}

function convertDataToQuery(type, param) {
  if (type === 'array') {
    return param.join(',');
  }
  return param === null ? 'null' : param;
}
