<template>
  <div>
    <v-card v-if="stateError && !stateProcessing">
      <v-card-text>
        <div class="pa-4">Sorry, cannot load your school at the moment</div>
      </v-card-text>
    </v-card>
    <div v-if="!stateError && (!stateProcessing || selectedSchool)">
      <v-card class="mb-4">
        <v-card-text>
          <mosaic-card-title>{{ selectedSchool.name }}</mosaic-card-title>
          <div class="px-2">
            <mosaic-text-field
              v-model="name"
              :readonly="detailsReadonly"
              prepend-icon="mdi-domain"
              name="name"
              label="Name"
            />
            <mosaic-select
              v-model="settingType"
              :readonly="detailsReadonly"
              name="setting-type"
              label="Setting type"
              :items="settingTypes"
              prepend-icon="mdi-shape"
            />
            <template v-if="isBrightFuturesAB">
              <mosaic-select
                v-model="mat"
                :readonly="detailsReadonly"
                name="mat-select"
                label="Multi-academy Trust (N/A if no MAT)"
                :items="brightFuturesMATs"
                prepend-icon="mdi-office-building"
              />
              <mosaic-text-field
                v-if="mat === 'Other'"
                v-model="matOther"
                :readonly="detailsReadonly"
                prepend-icon="mdi-office-building"
                name="mat-other"
                label="Other Multi-academy Trust"
              />
            </template>
            <mosaic-text-field
              v-else
              v-model="mat"
              :readonly="detailsReadonly"
              prepend-icon="mdi-office-building"
              name="mat"
              label="Multi-academy Trust (N/A if no MAT)"
            />
            <mosaic-text-field
              v-model="urn"
              :readonly="detailsReadonly"
              prepend-icon="mdi-pound"
              :rules="schoolUrnRules"
              name="urn"
              label="URN"
            />
            <mosaic-text-field
              v-model="addressFirstLine"
              :readonly="detailsReadonly"
              prepend-icon="mdi-postage-stamp"
              name="address-1"
              label="Address Line 1"
            />
            <mosaic-text-field
              v-model="addressSecondLine"
              :readonly="detailsReadonly"
              name="address-2"
              label="Address Line 2"
            />
            <mosaic-text-field v-model="addressCity" :readonly="detailsReadonly" name="address-city" label="City" />
            <mosaic-text-field
              v-model="addressPostcode"
              :readonly="detailsReadonly"
              prepend-icon
              name="address-postcode"
              label="Postcode"
            />
            <mosaic-select
              v-model="localAuthority"
              :readonly="detailsReadonly"
              name="local-authority"
              label="Local Authority"
              :items="localAuthoritiesWithOther"
              prepend-icon="mdi-timetable"
            />
            <mosaic-text-field
              v-if="localAuthority === 'Other'"
              v-model="localAuthorityOther"
              :readonly="detailsReadonly"
              name="local-authority-other"
              label="Other Local Authority Name"
            />
            <mosaic-text-field
              v-model="addressCountry"
              :readonly="detailsReadonly"
              name="address-country"
              label="Country"
            />
            <mosaic-text-field
              v-model="phoneNumber"
              :readonly="detailsReadonly"
              prepend-icon="mdi-phone"
              name="phone-number"
              label="Phone Number"
            />
            <mosaic-card-subheading>Finance Details</mosaic-card-subheading>
            <mosaic-text-field
              v-model="financeLeadName"
              :readonly="detailsReadonly"
              name="finance-name"
              label="Finance Lead Name"
              prepend-icon="mdi-account-cash-outline"
            />
            <mosaic-text-field
              v-model="financeEmail"
              :readonly="detailsReadonly"
              name="finance-email"
              label="Finance Office email (e.g. finance@...)"
              prepend-icon="mdi-email"
            />
          </div>
          <mosaic-error-alert :override-error-message="updateError" />
          <div v-if="!detailsReadonly" class="pr-2 text-right">
            <v-btn variant="text" ripple :disabled="!canUpdateSchool" @click.prevent="updateSchool()">Save</v-btn>
          </div>
          <mosaic-snackbar v-model="updateSuccessSnackbar" color="success" :message="updateSuccess" />
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { mapStateError, mapStateProcessing } from '@/store/map-store';

import { schoolUrnRules, validationsPass } from '../../utils/validations';
import { brightFuturesMATs, settingTypes, localAuthoritiesByAB } from '../../utils/school';

export default {
  name: 'TutorSchoolEditPage',
  data: () => ({
    processing: false,
    updateError: null,
    updateSuccessSnackbar: false,
    updateSuccess: '',
    schoolId: '',
    brightFuturesMATs,
    settingTypes,
    localAuthoritiesByAB,
    name: '',
    mat: '',
    matOther: '',
    settingType: '',
    localAuthority: '',
    localAuthorityOther: '',
    financeEmail: '',
    financeLeadName: '',
    addressFirstLine: '',
    addressSecondLine: '',
    addressCity: '',
    addressPostcode: '',
    addressCountry: '',
    phoneNumber: '',
    urn: '',
    schoolUrnRules,
  }),
  computed: {
    ...mapState(['selectedInstitution', 'selectedSchool']),
    ...mapStateProcessing(['loadSchool']),
    ...mapStateError(['loadSchool']),
    ...mapGetters(['isBrightFuturesAB']),
    breadcrumbs() {
      return [{ text: 'Details' }];
    },
    localAuthorities() {
      return this.localAuthoritiesByAB.find(x => x.abName === this.selectedInstitution.name)?.localAuthorities || [];
    },
    localAuthoritiesWithOther() {
      return this.localAuthorities.length > 0 ? this.localAuthorities.concat(['Other']) : [];
    },
    detailsReadonly() {
      return !this.userStaffHasPermissionForSelectedSchool('schools.edit');
    },
    canUpdateSchool() {
      return !this.processing && this.addressPostcode && this.name && validationsPass(this.schoolUrnRules, this.urn);
    },
  },
  watch: {
    selectedSchool(school) {
      if (school) {
        this.updateSchoolDetails(school);
      }
    },
  },
  created() {
    this.schoolId = this.$route.params.schoolId;
    if (this.selectedSchool) {
      this.updateSchoolDetails(this.selectedSchool);
    }
  },
  methods: {
    updateSchoolDetails(school) {
      this.name = school.name;
      if (this.isBrightFuturesAB) {
        if (school.mat && !this.brightFuturesMATs.includes(school.mat)) {
          this.mat = 'Other';
          this.matOther = school.mat;
        } else this.mat = school.mat;
      } else this.mat = school.mat;
      if (
        school.localAuthority &&
        this.localAuthorities.length > 0 &&
        !this.localAuthorities.includes(school.localAuthority)
      ) {
        this.localAuthority = 'Other';
        this.localAuthorityOther = school.localAuthority;
      } else this.localAuthority = school.localAuthority;
      this.settingType = school.settingType;
      this.financeEmail = school.financeEmail;
      this.financeLeadName = school.financeLeadName;
      this.addressFirstLine = school.addressFirstLine;
      this.addressSecondLine = school.addressSecondLine;
      this.addressCity = school.addressCity;
      this.addressPostcode = school.addressPostcode;
      this.addressCountry = school.addressCountry;
      this.phoneNumber = school.phoneNumber;
      this.urn = school.urn;
    },
    async updateSchool() {
      this.updateError = null;
      this.processing = true;
      try {
        await this.$api.put(`/schools/${this.schoolId}`, {
          name: this.name,
          urn: this.urn,
          mat: this.mat === 'Other' ? this.matOther : this.mat,
          settingType: this.settingType,
          financeEmail: this.financeEmail,
          financeLeadName: this.financeLeadName,
          addressFirstLine: this.addressFirstLine,
          addressSecondLine: this.addressSecondLine,
          addressCity: this.addressCity,
          addressPostcode: this.addressPostcode,
          localAuthority: this.localAuthority === 'Other' ? this.localAuthorityOther : this.localAuthority,
          addressCountry: this.addressCountry,
          phoneNumber: this.phoneNumber,
        });
        this.updateSuccessSnackbar = true;
        this.updateSuccess = 'School updated';
        this.$store.dispatch('loadSchool', { schoolId: this.selectedSchool.id, force: true });
      } catch (e) {
        console.log(e);
        this.updateError = 'Sorry, cannot update this school at the moment';
      }
      this.processing = false;
    },
  },
};
</script>
