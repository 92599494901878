<template>
  <mosaic-card>
    <mosaic-card-title>{{ curriculumStatement.code }} - {{ curriculumStatement.statement }}</mosaic-card-title>
    <mosaic-tabs v-if="showTabs" :headers="tabHeaders">
      <template #theory>
        <mosaic-content-layout
          :content-layout="curriculumStatement.theory_content_layout"
          get-resource-url-prefix="/curriculum-statements/theory-resources"
          :comments="[]"
          :tasks="[]"
        />
      </template>

      <template #practice>
        <curriculum-statement-card-practice-tab
          :click-target-to="clickTargetTo"
          :click-reflection-to="clickReflectionTo"
          :click-lesson-observation-to="clickLessonObservationTo"
          :click-mentor-meeting-to="clickMentorMeetingTo"
          :click-student-assignment-to="clickStudentAssignmentTo"
          :curriculum-statement="curriculumStatement"
          :editable="editable"
          :learning-journey-items="learningJourneyItems"
          :can-access-reflections="canAccessReflections"
          :edit-evidence="editEvidence"
          :create-evidence="createEvidence"
          @click-add-target="emit('clickAddTarget')"
          @click-add-reflection="emit('clickAddReflection')"
          @click-add-lesson-observation="emit('clickAddLessonObservation')"
          @click-add-mentor-meeting="emit('clickAddMentorMeeting')"
        ></curriculum-statement-card-practice-tab>
      </template>

      <template #teaching-strategies>
        <mosaic-list :items="curriculumStatement.curriculum_teaching_strategies.sortBy('order')">
          <template #item="{ item: ts }">
            <mosaic-list-item
              :key="ts.id"
              :to="teachingStrategyTo(ts)"
              :icon="icons.teachingStrategy"
              :title="ts.name"
              @click="teachingStrategyClick(ts)"
            >
              <template #actions v-if="ts.strategy_type === 'external_link'">
                <v-icon>mdi-launch</v-icon>
              </template>
            </mosaic-list-item>
          </template>
        </mosaic-list>
      </template>

      <template #training-plan>
        <mosaic-list :items="curriculumStatement.student_cohort_course_weeks">
          <template #item="{ item: w }">
            <mosaic-list-item
              :key="w.id"
              two-line
              :to="clickCohortCourseWeekTo(w)"
              icon="mdi-hexagon"
              :title="w.cohort_course_week.name"
              :subtitle="w.cohort_course_week.cohort_course_term.name"
              @click="emit('clickCohortCourseWeek')"
            >
            </mosaic-list-item>
          </template>
        </mosaic-list>
      </template>
    </mosaic-tabs>

    <curriculum-statement-card-practice-tab
      v-else
      class="mt-4"
      :click-target-to="clickTargetTo"
      :click-reflection-to="clickReflectionTo"
      :click-lesson-observation-to="clickLessonObservationTo"
      :click-mentor-meeting-to="clickMentorMeetingTo"
      :click-student-assignment-to="clickStudentAssignmentTo"
      :curriculum-statement="curriculumStatement"
      :editable="editable"
      :learning-journey-items="learningJourneyItems"
      :can-access-reflections="canAccessReflections"
      :edit-evidence="editEvidence"
      :create-evidence="createEvidence"
      @click-add-target="emit('clickAddTarget')"
      @click-add-reflection="emit('clickAddReflection')"
      @click-add-lesson-observation="emit('clickAddLessonObservation')"
      @click-add-mentor-meeting="emit('clickAddMentorMeeting')"
    ></curriculum-statement-card-practice-tab>
  </mosaic-card>
</template>

<script setup lang="ts">
import type { ContentLayout } from '@/utils/content-layout';
import { computed } from 'vue';
import CurriculumStatementCardPracticeTab from './CurriculumStatementCardPracticeTab.vue';
import type { RouteLocationNamedRaw } from 'vue-router';
import { hasPermissionForSelectedStudent } from '@/composables/permission';
import { useUserStore } from '@/stores/user';
import MosaicContentLayout from '@/components/mosaic-content-layout/MosaicContentLayout.vue';
import { icons } from '@/utils/icons';

type ClickTo = (artefact: { id: number }) => RouteLocationNamedRaw | undefined;

interface Target {
  id: number;
  goal: string;
  created_at: string;
}

interface Reflection {
  id: number;
  created_at: string;
}

interface LessonObservation {
  id: number;
  observation_date: string;
}

interface MentorMeeting {
  id: number;
  meeting_date: string;
}
interface StudentAssignment {
  id: number;
  name: string;
  due_date: string | null;
}

interface CurriculumTeachingStrategy {
  id: number;
  name: string;
  order: number;
  strategy_type: 'content_layout' | 'external_link';
  content_layout: ContentLayout;
  external_link: string;
}

const props = withDefaults(
  defineProps<{
    curriculumStatement: {
      code: string;
      statement: string;
      theory_content_layout: ContentLayout;
      student_cohort_course_weeks: {
        id: number;
        name: string;
        start_date: string;
        end_date: string;
        cohort_course_week: {
          name: string;
          cohort_course_term: {
            name: string;
          };
        };
      }[];
      curriculum_teaching_strategies: CurriculumTeachingStrategy[];
      targets: Target[];
      reflections: Reflection[];
      lesson_observations: LessonObservation[];
      mentor_meetings: MentorMeeting[];
      student_assignments: StudentAssignment[];
    };
    editable?: boolean;
    canAccessReflections?: boolean;
    showTheory?: boolean;
    previewMode?: boolean;
    createEvidence?: (e: unknown) => void;
    editEvidence?: (e: unknown) => void;
    clickTargetTo?: ClickTo;
    clickReflectionTo?: ClickTo;
    clickLessonObservationTo?: ClickTo;
    clickMentorMeetingTo?: ClickTo;
    clickCohortCourseWeekTo?: ClickTo;
    clickStudentAssignmentTo?: ClickTo;
    clickCurriculumTeachingStrategyTo: ClickTo;
  }>(),
  {
    editable: true,
    canAccessReflections: true,
    showTheory: true,
    previewMode: false,
    clickTargetTo: (_: { id: number }) => undefined,
    clickReflectionTo: (_: { id: number }) => undefined,
    clickLessonObservationTo: (_: { id: number }) => undefined,
    clickMentorMeetingTo: (_: { id: number }) => undefined,
    clickCohortCourseWeekTo: (_: { id: number }) => undefined,
    clickStudentAssignmentTo: (_: { id: number }) => undefined,
  }
);

const emit = defineEmits<{
  clickAddTarget: [];
  clickAddReflection: [];
  clickAddLessonObservation: [];
  clickAddMentorMeeting: [];
  clickCohortCourseWeek: [];
}>();

// Tabs
const hasTheory = computed(
  () => props.curriculumStatement.theory_content_layout.sections.length > 0 && props.showTheory
);
const hasTrainingPlanLinks = computed(() => props.curriculumStatement.student_cohort_course_weeks.length > 0);
const hasTeachingStrategies = computed(() => props.curriculumStatement.curriculum_teaching_strategies.length > 0);
const showTabs = computed(() => hasTheory.value || hasTrainingPlanLinks.value || hasTeachingStrategies.value);

const tabHeaders = computed(() => {
  const tabs = [];
  if (hasTheory.value) tabs.push({ key: 'theory', text: 'Theory' });
  tabs.push({ key: 'practice', text: 'Practice' });
  if (hasTeachingStrategies.value) tabs.push({ key: 'teaching-strategies', text: 'Teaching Strategies' });
  if (hasTrainingPlanLinks.value) tabs.push({ key: 'training-plan', text: 'Training Plan' });
  return tabs;
});

// Teaching strategies
function teachingStrategyTo(ts: CurriculumTeachingStrategy): RouteLocationNamedRaw | undefined {
  if (ts.strategy_type === 'external_link') return undefined;
  return props.clickCurriculumTeachingStrategyTo(ts);
}

function teachingStrategyClick(ts: CurriculumTeachingStrategy) {
  if (ts.strategy_type === 'content_layout') return;
  window.open(ts.external_link, '_blank');
}

// Practice tab
const hasReflectionsViewPermission = hasPermissionForSelectedStudent('student.reflections.view');
const { isStudent } = useUserStore();

const learningJourneyItems = computed(() => {
  const reflections = (props.curriculumStatement.reflections || []).map(x => ({
    ...x,
    type: 'reflection',
    icon: 'mirror',
    date: x.created_at,
  }));
  const targets = (props.curriculumStatement.targets || []).map(x => ({
    ...x,
    title: x.goal,
    type: 'target',
    icon: 'near-me',
    date: x.created_at,
  }));
  const lessonObservations = (props.curriculumStatement.lesson_observations || []).map(x => ({
    ...x,
    type: 'lesson-observation',
    icon: 'file-eye',
    date: x.observation_date,
  }));
  const mentorMeetings = (props.curriculumStatement.mentor_meetings || []).map(x => ({
    ...x,
    type: 'mentor-meeting',
    icon: 'recycle-variant',
    date: x.meeting_date,
  }));
  const studentAssignments = (props.curriculumStatement.student_assignments || []).map(x => ({
    ...x,
    title: x.name,
    type: 'student-assignment',
    icon: 'clipboard-text',
    date: x.due_date || '',
  }));
  const items: (Target | Reflection | LessonObservation | MentorMeeting | StudentAssignment)[] = [
    ...targets,
    ...lessonObservations,
    ...mentorMeetings,
    ...studentAssignments,
  ];
  return props.previewMode || hasReflectionsViewPermission.value || isStudent ? items.concat(reflections) : items;
});
</script>
