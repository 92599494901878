// Exports data to excel
import * as XLSX from 'xlsx';

export type ExcelRow = {
  [key: string]: unknown;
};

export type ConfigTableExport = {
  title?: string;
  tabTitle?: string;
  data: ExcelRow[];
  otherSheets?: { tabTitle?: string; data: [] }[];
  emptyMessage: string;
};

export function exportDataToExcel(config: ConfigTableExport) {
  const worksheet =
    config.data.length > 0
      ? XLSX.utils.json_to_sheet(config.data)
      : XLSX.utils.aoa_to_sheet([[config.emptyMessage ? config.emptyMessage : 'No results.']]);

  const workbook = XLSX.utils.book_new();

  XLSX.utils.book_append_sheet(workbook, worksheet, config.tabTitle);

  if (config.otherSheets) {
    config.otherSheets.forEach((s, i) => {
      const extraWorksheetSheet = XLSX.utils.aoa_to_sheet(s.data as []);
      XLSX.utils.book_append_sheet(workbook, extraWorksheetSheet, s.tabTitle || `Sheet ${i + 1}`);
    });
  }

  XLSX.writeFile(workbook, `${config.title || 'Document'}.xlsx`);
}
