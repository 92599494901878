<template>
  <cohort-course-weeks-list
    :cohort-course-url="`/students/${selectedStudent.id}/cohort-course`"
    :student-cohort-course-weeks-url="`/students/${selectedStudent.id}/student-cohort-course-weeks`"
    :week-click-to="weekClickTo"
  />
</template>

<script>
import CohortCourseWeeksList from './CohortCourseWeeksList.vue';
import { mapState } from 'vuex';

export default {
  name: 'TutorCohortCoursePage',
  components: { CohortCourseWeeksList },
  computed: {
    ...mapState(['selectedStudent']),
    breadcrumbs() {
      return [
        {
          text: 'Training Plan',
        },
      ];
    },
  },
  methods: {
    weekClickTo(id) {
      return {
        name: 'TutorCohortCourseWeekPage',
        params: { studentId: this.selectedStudent.id, studentWeekId: id },
      };
    },
  },
};
</script>
