<template>
  <div>
    <mosaic-info-alert v-if="selectedCohort && !selectedCohort.show_curriculum_for_students">
      The entire {{ curriculumNoun }} is not currently visible to {{ traineeNounPluralised }} in this Cohort.
    </mosaic-info-alert>
    <mosaic-info-alert v-else-if="curriculumStatement && !curriculumStatement.studentVisible">
      This {{ curriculumStatementNoun }} is not currently visible to {{ traineeNounPluralised }}.
    </mosaic-info-alert>
    <mosaic-info-alert v-else-if="curriculumTheme && !curriculumTheme.studentVisible">
      This {{ curriculumStatementNoun }} is not currently visible to {{ traineeNounPluralised }} as the
      {{ curriculumThemeNoun }} is not currently visible.
    </mosaic-info-alert>
    <mosaic-info-alert v-else-if="selectedCurriculum && !selectedCurriculum.showTheory">
      The theory section is currently switched off for {{ traineeNounPluralised }}. They will only see the practice
      section.
    </mosaic-info-alert>
    <mosaic-info-alert class="mt-2" v-if="canEditCurriculum && usingCohorts.length > 0">
      Edits to this Curriculum will show across all using Cohorts.
    </mosaic-info-alert>
    <mosaic-configure-preview-page
      v-model="configurePreview"
      v-model:trigger-background-load="triggerBackgroundLoad"
      :load="load"
      :object-type="curriculumStatementNoun"
    >
      <template v-if="selectedCurriculum && curriculumStatement" #configure>
        <mosaic-save-card
          :save="save"
          :can-save="canSave"
          :object-type="curriculumStatementNoun"
          :valid="valid"
          :readonly="currentTab === 'teaching-strategies' || currentTab === 'training-plan' || !canEditCurriculum"
          :return-to="
            isCohortPage ? { name: 'TutorAdminCohortCurriculumThemePage' } : { name: 'TutorAdminCurriculumThemePage' }
          "
        >
          <mosaic-card-title>
            <span>{{ curriculumStatement.code }} - {{ curriculumStatement.statement }}</span>
            <template #actions>
              <!-- some sort of edit button -->
            </template>
          </mosaic-card-title>
          <div class="pb-2">
            {{
              visibilitySubtitle(selectedCohort, curriculumTheme, curriculumStatement, subject, traineeNounPluralised)
            }}
          </div>

          <mosaic-tabs :headers="tabHeaders" @tab-selected="currentTab = $event">
            <template #theory>
              <mosaic-content-layout-builder
                v-if="theoryContentLayout"
                v-model:content-layout="theoryContentLayout"
                :supported-item-types="['text', 'resource', 'video', 'youtube', 'link', 'image', 'columns']"
                :presign-url="`/presign/curriculum/${selectedCurriculum.id}/curriculum-statement-theory-resources`"
                get-resource-url-prefix="/curriculum-statements/theory-resources"
                :save-resource-url="`/curriculum-statements/${curriculumStatementId}/theory-resources`"
                :save-resource-params="{
                  curriculumStatementId: curriculumStatementId,
                }"
                @valid="theoryContentLayoutValid = $event"
                :editable="canEditCurriculum"
              />
              <div v-if="!theoryContentLayout?.sections.length && !canEditCurriculum">
                No Theory content has been added to this {{ curriculumStatementNoun }} yet.
              </div>
            </template>

            <template #practice>
              <mosaic-content-layout-builder
                v-if="practiceContentLayout"
                v-model:content-layout="practiceContentLayout"
                :supported-item-types="['text', 'resource', 'video', 'youtube', 'link', 'image', 'columns']"
                :presign-url="`/presign/curriculum/${selectedCurriculum.id}/curriculum-statement-practice-resources`"
                get-resource-url-prefix="/curriculum-statements/practice-resources"
                :save-resource-url="`/curriculum-statements/${curriculumStatementId}/practice-resources`"
                :save-resource-params="{
                  curriculumStatementId: curriculumStatementId,
                }"
                @valid="practiceContentLayoutValid = $event"
                :editable="canEditCurriculum"
              />
              <div v-if="!practiceContentLayout?.sections.length && !canEditCurriculum">
                No Practice content has been added to this {{ curriculumStatementNoun }} yet.
              </div>
            </template>

            <template #teaching-strategies>
              <mosaic-error-snackbar
                v-model="publishError"
                :action="`${publishing ? 'publish' : 'unpublish'} this Teaching Strategy`"
              />

              <legacy-mosaic-inline-creation-list
                v-model:trigger-background-load="triggerBackgroundLoad"
                object-type="Teaching Strategy"
                :items="teachingStrategies"
                :icon="icons.teachingStrategy"
                :can-add-item="canAddTeachingStrategy"
                :add-item="addTeachingStrategy"
                :can-retitle-item="canRenameTeachingStrategy"
                :retitle-item-dialog-opened="renameTeachingStrategy"
                :retitle-item="saveRenameTeachingStrategy"
                :move-item="moveTeachingStrategy"
                :hide-add="!canEditCurriculum"
                :hide-actions="!canEditCurriculum"
                delete-url-stem="curriculum-statements/teaching-strategies"
              >
                <template #information="{ item }">
                  <mosaic-published-draft-chip
                    :published="item.status === 'published'"
                    object-type="Teaching Strategy"
                    :disabled="!canEditCurriculum"
                  />
                </template>

                <template #actions="{ item }">
                  <mosaic-icon-btn
                    v-if="item.status === 'draft'"
                    icon="publish"
                    :tooltip="
                      cannotPublishTeachingStrategyReason(item)
                        ? `Cannot publish this Teaching Strategy as ${cannotPublishTeachingStrategyReason(item)}`
                        : 'Publish Teaching Strategy'
                    "
                    :disabled="publishProcessing || !!cannotPublishTeachingStrategyReason(item)"
                    @click.prevent="publishStrategy(item, true)"
                  ></mosaic-icon-btn>
                  <mosaic-icon-btn
                    v-else
                    icon="publish-off"
                    tooltip="Unpublish Teaching Strategy"
                    :disabled="publishProcessing || !canEditCurriculum"
                    @click.prevent="publishStrategy(item, false)"
                  ></mosaic-icon-btn>
                </template>

                <template #add-item-fields>
                  <mosaic-text-field
                    v-model="newTeachingStrategyName"
                    name="add-name"
                    label="Name"
                    prepend-icon="mdi-pencil"
                    class="flex-grow-1"
                  />
                </template>

                <template #retitle-item-fields="{ onKeyupEnter }">
                  <mosaic-text-field
                    v-model="renameTeachingStrategyDialog.name"
                    name="rename-teaching-strategy-name"
                    label="Name"
                    prepend-icon="mdi-pencil"
                    @keyup.enter="onKeyupEnter"
                  />
                </template>
              </legacy-mosaic-inline-creation-list>
            </template>

            <template #training-plan>
              <mosaic-list :items="curriculumStatement.cohortCourseWeeks" class="pb-4">
                <template #item="{ item: w }">
                  <mosaic-list-item
                    :to="cohortCourseWeekTo(w)"
                    icon="mdi-hexagon"
                    :title="w.name"
                    :subtitle="`${w.cohortCourseTerm.cohortCourse.cohort.name} - ${w.cohortCourseTerm.name}`"
                  >
                    <template #information>
                      <mosaic-published-draft-chip
                        mode="list-no-tooltip"
                        :published="w.status === 'published'"
                        object-type="Training Plan Week"
                      />
                    </template>
                  </mosaic-list-item>
                </template>
              </mosaic-list>
            </template>
          </mosaic-tabs>
        </mosaic-save-card>
      </template>

      <template #preview-as>
        <mosaic-select
          v-if="previewCohorts.length > 0 && !selectedCohort"
          v-model="previewCohortId"
          hide-details
          density="compact"
          prepend-icon="mdi-google-classroom"
          :items="previewCohorts"
          item-title="name"
          item-value="id"
          label="Preview as Cohort"
          name="preview-as-cohort"
        />
      </template>

      <template #preview>
        <curriculum-statement-card
          v-if="previewCurriculumStatement"
          :curriculum-statement="previewCurriculumStatement"
          :preview-mode="true"
          :click-curriculum-teaching-strategy-to="x => teachingStrategyTo(x, true)"
          @click-add-target="showPreviewMessage = true"
          @click-add-reflection="showPreviewMessage = true"
          @click-cohort-course-week="showPreviewMessage = true"
        />
      </template>
    </mosaic-configure-preview-page>

    <unsaved-changes-dialog
      v-model:unsaved-changes-dialog="dialog"
      :object-type="curriculumStatementNoun"
      :save="save"
    />
    <mosaic-snackbar v-model="showPreviewMessage" color="accent" message="Not available in preview mode" />
  </div>
</template>

<script setup lang="ts">
import { parseRouteId } from '@/composables/vue-router';
import type { CurriculumStatement, CurriculumTeachingStrategy } from '@/stores/curriculum';
import { useCurriculumStore } from '@/stores/curriculum';
import { useStudentStore } from '@/stores/student';
import { useSubjectsStore } from '@/stores/subjects';
import { computed, ref, watch } from 'vue';
import CurriculumStatementCard from './CurriculumStatementCard.vue';
import { useCohortStore } from '@/stores/cohort';
import type { ConfigurePreview } from '@/components/library/configure-preview/configure-preview';
import { setBreadcrumbs } from '@/utils/breadcrumbs';
import { useRoute } from 'vue-router';
import MosaicContentLayoutBuilder from '@/components/mosaic-content-layout/MosaicContentLayoutBuilder.vue';
import type { ContentLayout } from '@/utils/content-layout';
import { contentLayoutDirty } from '@/utils/content-layout';
import { useUnsavedChanges } from '@/composables/unsaved-changes';
import UnsavedChangesDialog from '@/components/UnsavedChangesDialog.vue';
import type { Cohort } from '@/store/map-store';
import { icons } from '@/utils/icons';
import { useMoveListItem, useRetitleListItem } from '@/components/library/mosaic-list/list-item-helpers';
import { withProcessingAndError } from '@/composables/processing-and-errors';
import { useApi } from '@/composables/api';
import { visibilitySubtitle } from './admin-curriculum-statements';
import { hasPermissionForSelectedInstitution } from '@/composables/permission';

const api = useApi();

// Load/Setup
const configurePreview = ref<ConfigurePreview>('configure');

const route = useRoute();
const isCohortPage = computed(() => route.name === 'CohortAdminCurriculumStatementPage');
const curriculumStatementId = parseRouteId('statementId');

let selectedCohort = computed<Cohort | null>(() => null);
if (isCohortPage.value) {
  ({ selectedCohort } = useCohortStore());
}

const {
  actions: { loadCurriculum },
  curriculumStatements,
  curriculumThemes,
  selectedCurriculum,
  curriculumNoun,
  curriculumThemeNoun,
  curriculumStatementNoun,
} = useCurriculumStore();
const {
  actions: { loadSubjects },
  subjectsWithNullOptionAndPrimarySeparation,
} = useSubjectsStore();

async function load() {
  await Promise.all([loadCurriculum({ force: true, throwError: true }), loadSubjects()]);
}

const curriculumStatement = computed<CurriculumStatement | undefined>(() =>
  curriculumStatements.value ? curriculumStatements.value[curriculumStatementId.value] : undefined
);

const curriculumTheme = computed(() =>
  curriculumStatement.value?.themeId && curriculumThemes.value
    ? curriculumThemes.value[curriculumStatement.value.themeId]
    : undefined
);

const theoryContentLayout = ref<ContentLayout | null>(null);
watch(curriculumStatement, () => {
  theoryContentLayout.value = curriculumStatement.value?.theoryContentLayout || null;
});
const practiceContentLayout = ref<ContentLayout | null>(null);
watch(curriculumStatement, () => {
  practiceContentLayout.value = curriculumStatement.value?.practiceContentLayout || null;
});

setBreadcrumbs(
  computed(() => {
    return [
      {
        text: curriculumNoun.value,
        to: {
          name: isCohortPage.value ? 'TutorAdminCohortCurriculumPage' : 'TutorAdminCurriculumPage',
        },
      },
      {
        text: curriculumTheme.value?.code || '',
        to: {
          name: isCohortPage.value ? 'TutorAdminCohortCurriculumThemePage' : 'TutorAdminCurriculumThemePage',
          params: {
            themeId: curriculumTheme.value?.id.toString() || '-1',
          },
        },
      },
      {
        text: curriculumStatement.value?.code || '',
      },
    ];
  })
);

const usingCohorts = computed(
  () =>
    selectedCurriculum.value?.usingCohorts.filter(c => !isCohortPage.value || c.id !== selectedCohort.value?.id) || []
);
const canEditCurriculum = computed(() => hasPermissionForSelectedInstitution('curriculum.edit').value);

const subject = computed(() => {
  return subjectsWithNullOptionAndPrimarySeparation.value.find(s => s.id === curriculumStatement.value?.subjectId);
});

// Teaching strategies tab
const teachingStrategies = computed(() => {
  if (!curriculumStatement.value) return [];
  return curriculumStatement.value.curriculumTeachingStrategies.map(ts => ({
    ...ts,
    title: ts.name,
    hideMove: false,
    disableDelete: ts.status === 'published',
    deleteDisabledTooltip: 'Cannot delete a published Teaching Strategy',
    clickTo: teachingStrategyTo(ts),
  }));
});

function teachingStrategyTo(ts: { id: number }, goToPreview = false) {
  const component = isCohortPage.value
    ? 'CohortAdminCurriculumTeachingStrategyPage'
    : 'InstAdminCurriculumTeachingStrategyPage';
  return {
    name: component,
    params: { strategyId: ts.id.toString() },
    query: { preview: goToPreview ? 'preview' : undefined },
  };
}

const triggerBackgroundLoad = ref(false);

const newTeachingStrategyName = ref('');
const canAddTeachingStrategy = computed(() => !!newTeachingStrategyName.value);
async function addTeachingStrategy() {
  await api.post(`/curriculum-statements/${curriculumStatementId.value}/teaching-strategies`, {
    name: newTeachingStrategyName.value,
  });
}

const renameTeachingStrategyDialog = ref({
  name: '',
  originalName: '',
});
const canRenameTeachingStrategy = computed(
  () =>
    !!renameTeachingStrategyDialog.value.name &&
    renameTeachingStrategyDialog.value.name !== renameTeachingStrategyDialog.value.originalName
);
async function renameTeachingStrategy(item: (typeof teachingStrategies)['value'][number]) {
  renameTeachingStrategyDialog.value = {
    name: item.title,
    originalName: item.title,
  };
}

const body = computed(() => ({
  name: renameTeachingStrategyDialog.value.name,
}));
const { retitleListItem: saveRenameTeachingStrategy } = useRetitleListItem(
  '/curriculum-statements/teaching-strategies',
  body,
  api
);

const { moveListItem: moveTeachingStrategy } = useMoveListItem('/curriculum-statements/teaching-strategies', api);

function cannotPublishTeachingStrategyReason(teachingStrategy: CurriculumTeachingStrategy): false | string {
  if (teachingStrategy.strategyType === 'external_link') {
    if (!teachingStrategy.externalLink) return 'the link has not been filled in';
  } else {
    if (teachingStrategy.contentLayout.sections.length === 0) return 'it has no content';
    if (teachingStrategy.contentLayout.sections.some(s => s.items.length === 0)) return 'it has empty sections';
  }
  return false;
}

const publishing = ref(true);
const {
  action: publishStrategy,
  processing: publishProcessing,
  error: publishError,
} = withProcessingAndError(async (strategy: (typeof teachingStrategies)['value'][number], publish: boolean) => {
  publishing.value = publish;
  await api.post(`/curriculum-statements/teaching-strategies/${strategy.id}/publish`, {
    publish,
  });

  await loadCurriculum({ force: true, throwError: true });
});

// Training Plan tab
function cohortCourseWeekTo(week: CurriculumStatement['cohortCourseWeeks'][number]) {
  return {
    name: 'TutorAdminCohortCourseWeekPage',
    params: {
      cohortId: week.cohortCourseTerm.cohortCourse.cohort.id,
      termId: week.cohortCourseTerm.id,
      weekId: week.id,
    },
  };
}

// Save/dirty checking
async function save() {
  await api.put(`/curriculum-statements/${curriculumStatement.value?.id}`, {
    theoryContentLayout: theoryContentLayout.value,
    practiceContentLayout: practiceContentLayout.value,
  });
  await loadCurriculum({ force: true, throwError: true });
}

const theoryContentLayoutValid = ref(true);
const practiceContentLayoutValid = ref(true);
const valid = computed(() => theoryContentLayoutValid.value && practiceContentLayoutValid.value);
const theoryDirty = computed(() =>
  contentLayoutDirty(theoryContentLayout.value, curriculumStatement.value?.theoryContentLayout)
);
const practiceDirty = computed(() =>
  contentLayoutDirty(practiceContentLayout.value, curriculumStatement.value?.practiceContentLayout)
);
const dirty = computed(() => theoryDirty.value || practiceDirty.value);
const canSave = computed(() => dirty.value);

const currentTab = ref('');
const tabHeaders = computed(() => {
  const tabs = [
    { key: 'theory', text: 'Theory', dirty: theoryDirty.value },
    { key: 'practice', text: 'Practice', dirty: practiceDirty.value },
    { key: 'teaching-strategies', text: 'Teaching Strategies', dirty: false },
  ];
  if (curriculumStatement.value!.cohortCourseWeeks.length > 0) {
    tabs.push({ key: 'training-plan', text: 'Training Plan', dirty: false });
  }
  return tabs;
});

const { dialog } = useUnsavedChanges(dirty);

// Preview
const showPreviewMessage = ref(false);
const previewCohortId = ref(0);
const previewCohorts = computed(() => {
  if (!curriculumStatement.value || curriculumStatement.value.cohortCourseWeeks.length === 0) return [];
  if (isCohortPage.value) return selectedCohort.value ? [selectedCohort.value] : [];
  return selectedCurriculum.value?.usingCohorts || [];
});
watch(previewCohorts, () => {
  if (previewCohorts.value.length > 0) {
    previewCohortId.value = previewCohorts.value[0].id;
  } else {
    previewCohortId.value = 0;
  }
});
const { aTraineeNoun, traineeNounPluralised } = useStudentStore();

const previewCurriculumStatement = computed(() => {
  if (!curriculumStatement.value) return null;
  const cs = curriculumStatement.value;
  return {
    id: cs.id,
    code: cs.code,
    statement: cs.statement,
    theory_content_layout: theoryContentLayout.value || { sections: [] },
    practice_content_layout: practiceContentLayout.value || { sections: [] },
    student_cohort_course_weeks: cs.cohortCourseWeeks
      .filter(x => x.cohortCourseTerm.cohortCourse.cohort.id === previewCohortId.value && x.status === 'published')
      .map(x => ({
        id: x.id,
        name: x.name,
        start_date: x.startDate,
        end_date: x.endDate,
        cohort_course_week: {
          id: x.id,
          name: x.name,
          cohort_course_term: {
            name: x.cohortCourseTerm.name,
          },
        },
      })),
    curriculum_teaching_strategies: cs.curriculumTeachingStrategies
      .filter(ts => ts.status === 'published')
      .map(ts => ({
        ...ts,
        strategy_type: ts.strategyType,
        content_layout: ts.contentLayout,
        external_link: ts.externalLink,
      })),
    targets: [
      {
        id: 0,
        goal: 'An example Target for ' + aTraineeNoun.value,
        created_at: '',
      },
    ],
    reflections: [
      {
        id: 0,
        title: 'An example Reflection for ' + aTraineeNoun.value,
        created_at: '',
      },
    ],
    lesson_observations: [
      {
        id: 0,
        title: 'An example Lesson Observation for ' + aTraineeNoun.value,
        observation_date: '',
      },
    ],
    mentor_meetings: [
      {
        id: 0,
        title: 'An example Mentor Meeting for ' + aTraineeNoun.value,
        meeting_date: '',
      },
    ],
    student_assignments: [
      {
        id: 0,
        name: 'An example Course Avtivity for ' + aTraineeNoun.value,
        due_date: null,
        status: 'not_started',
      },
    ],
  };
});
</script>
