<template>
  <div>
    <div class="d-flex justify-space-between py-2">
      <mosaic-btn @click="lastWeek()"><v-icon>mdi-chevron-left</v-icon></mosaic-btn>
      <v-chip color="primary" label>Current Week: {{ currentWeek.startDate }} - {{ currentWeek.endDate }}</v-chip>
      <mosaic-btn @click="nextWeek()"><v-icon>mdi-chevron-right</v-icon></mosaic-btn>
    </div>
    <q-a-target-week-sync-query v-model:start="startDate" v-model:end="endDate" />
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import moment from 'moment';
import { watch } from 'vue';
import QATargetWeekSyncQuery from './QATargetWeekSyncQuery.vue';
export interface Week {
  startDate: string;
  endDate: string;
}
const startDate = ref(moment().startOf('week').format('DD/MM/YY'));
const endDate = ref(moment().endOf('week').format('DD/MM/YY'));
const currentWeek = computed<Week>(() => ({
  startDate: startDate.value,
  endDate: endDate.value,
}));
const nextWeek = () => {
  startDate.value = moment(currentWeek.value.startDate, 'DD/MM/YY').add(1, 'week').format('DD/MM/YY');
  endDate.value = moment(currentWeek.value.endDate, 'DD/MM/YY').add(1, 'week').format('DD/MM/YY');
};
const lastWeek = () => {
  startDate.value = moment(currentWeek.value.startDate, 'DD/MM/YY').subtract(1, 'week').format('DD/MM/YY');
  endDate.value = moment(currentWeek.value.endDate, 'DD/MM/YY').subtract(1, 'week').format('DD/MM/YY');
};

const emit = defineEmits<{
  (e: 'update:week', value: Week): void;
}>();

watch(currentWeek, value => emit('update:week', value));
</script>
