<template>
  <v-card>
    <v-list v-model:opened="open">
      <v-list-group value="panel">
        <template #activator="{ props }">
          <v-list-item v-bind="props" :prepend-icon="'mdi-' + icon" :title="title" color="primary" />
        </template>

        <div v-if="filteredEvidence.length === 0" class="pl-6 pa-3">
          {{ user.student ? 'You have' : $store.state.selectedStudent.name + ' has' }} no {{ title
          }}{{ onlyFavourites ? ' marked as best evidence' : '' }}
          for this
          {{ standardNoun }}
          <template v-if="user.student">
            <br />
            <br />
            {{ 'Either tag some existing ' + title + ' or add some new ones' }}
          </template>
        </div>

        <mosaic-list :items="filteredEvidence" class="pa-2">
          <template #item="{ item: e }">
            <evidence-list-item
              :key="e.id"
              :evidence="e"
              :edit-evidence="editEvidence"
              :delete-evidence="deleteEvidence"
              :can-view-reflections="canViewReflections"
              @click-favourite="favouriteEvidenceClick"
              @click-target="x => $emit('clickTarget', x)"
              @click-reflection="x => $emit('clickReflection', x)"
              @click-lesson-observation="x => $emit('clickLessonObservation', x)"
              @click-mentor-meeting="x => $emit('clickMentorMeeting', x)"
              @click-student-assignment="x => $emit('clickStudentAssignment', x)"
            />
          </template>
        </mosaic-list>
      </v-list-group>
    </v-list>
  </v-card>
</template>

<script>
import EvidenceListItem from './EvidenceListItem.vue';
import config from '../utils/config';
import { mapState } from 'vuex';

export default {
  props: {
    evidence: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    editEvidence: {
      type: Function,
      default: null,
    },
    deleteEvidence: {
      type: Function,
      default: null,
    },
    onlyFavourites: {
      type: Boolean,
      default: false,
    },
    canViewReflections: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'clickTarget',
    'clickReflection',
    'clickLessonObservation',
    'clickMentorMeeting',
    'clickStudentAssignment',
    'clickFavourite',
  ],
  data: () => ({
    config: config,
    open: false,
  }),
  created() {
    this.open = this.evidence.length > 0 ? ['panel'] : [];
  },
  computed: {
    ...mapState(['user']),
    filteredEvidence() {
      return this.evidence.filter(e => {
        return e.favourite || !this.onlyFavourites;
      });
    },
  },
  methods: {
    favouriteEvidenceClick(evidence) {
      this.$emit('clickFavourite', evidence);
    },
  },
  components: { EvidenceListItem },
};
</script>
