<template>
  <mosaic-loading-card v-if="busy" type="list" />
  <mosaic-load-error-card v-else-if="loadError" object-type="Reflections" @retry="loadReflections" />
  <reflections-list v-else :reflections="reflections" :reflection-click-to="reflectionClickTo" :show-title="true">
    <div>{{ traineeNounCapitalised() }} has added no reflections.</div>
  </reflections-list>
</template>

<script>
import ReflectionsList from './ReflectionsList.vue';
import { mapState } from 'vuex';

export default {
  name: 'TutorReflectionsListPage',
  data: function () {
    return {
      reflections: [],
      busy: false,
      loadError: false,
    };
  },
  async created() {
    await this.loadReflections();
  },
  computed: {
    ...mapState(['selectedStudent']),
    breadcrumbs() {
      return [
        {
          text: 'Reflections',
        },
      ];
    },
  },
  methods: {
    async loadReflections() {
      this.loadError = false;
      this.busy = true;
      try {
        const r = await this.$api.get(`/students/${this.selectedStudent.id}/reflections`);
        this.reflections = r.data;
      } catch (e) {
        console.log(e);
        this.loadError = true;
      }

      this.busy = false;
    },
    reflectionClickTo(reflectionId) {
      return {
        name: 'ReflectionPage',
        params: { studentId: this.selectedStudent.id, reflectionId: reflectionId },
      };
    },
  },
  components: { ReflectionsList },
};
</script>
