<template>
  <mosaic-judgement-descriptor-chip
    v-if="grade && (isReviewedStatus || forceShowGrade)"
    :descriptor="grade"
    field-name="grade"
    object-type="Course Activity"
  />
</template>

<script setup lang="ts">
import type { JudgementDescriptor } from '@/store/map-store';
import type { StudentAssignmentStatus } from './student-assignments';
import { computed } from 'vue';

const props = defineProps<{
  status: StudentAssignmentStatus;
  grade: JudgementDescriptor | null;
  forceShowGrade?: boolean;
}>();

const isReviewedStatus = computed(() => props.status === 'reviewed' || props.status === 'reviewed_requires_changes');
</script>
