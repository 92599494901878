import type { ComputedRef, Ref } from 'vue';
import { computed } from 'vue';

export function notNullableRef<T>(ref: Ref<T | null>, variableName: string): ComputedRef<T> {
  return computed(() => {
    if (ref.value === null) throw `${variableName} should not be null`;
    return ref.value;
  });
}

export function notUndefinedRef<T>(ref: Ref<T | undefined>, variableName: string): ComputedRef<T> {
  return computed(() => {
    if (ref.value === undefined) throw `${variableName} should not be undefined`;
    return ref.value;
  });
}
