<template>
  <ndt-dialog v-model:active="activeNdtDialog" :title="title" :error-message="errorMessage" :width="950">
    <template v-if="preSelectedEct">
      <mosaic-text-field
        prepend-icon="mdi-account"
        :readonly="true"
        name="ect"
        label="ECT"
        :model-value="preSelectedEct.name || preSelectedEct.email"
      ></mosaic-text-field>
    </template>
    <template v-if="ectToUpdate">
      <template v-if="canUpdateSchool">
        <div style="width: 50%" class="mt-4">
          <mosaic-date-picker
            v-model:date="leaveDate"
            label="Leave date"
            :max="returnDate"
            :min="minLeaveDate"
          ></mosaic-date-picker>
        </div>
        <mosaic-text-area
          v-model="leaveNote"
          name="leave-note"
          variant="filled"
          label="Leaving Notes"
          auto-grow
          type="text"
          rows="2"
        />
        <div class="mt-4 d-flex flex-wrap" style="column-gap: 8px">
          <div style="flex-basis: 48%" class="flex-grow-1">
            <mosaic-date-picker v-model:date="returnDate" label="Join date" :min="minReturnDate"></mosaic-date-picker>
          </div>
          <div style="flex-basis: 48%">
            <mosaic-select
              v-model="selectedSchool"
              name="select-school"
              label="New School"
              :items="schoolsWithCurrent"
              item-value="id"
              item-title="nameAndCurrent"
              no-data-text="No schools found"
              prepend-icon="mdi-domain"
              return-object
            />
          </div>
          <div style="flex-basis: 48%">
            <mosaic-text-field
              v-model="fteRate"
              name="new-fte-fraction"
              label="Full time equivalent (FTE) fraction"
              type="number"
              step="0.1"
              :rules="fteRateRules"
              prepend-icon="mdi-timetable"
            ></mosaic-text-field>
          </div>
        </div>
        <mosaic-text-area
          v-model="returnNote"
          name="return-note"
          variant="filled"
          label="Joining Notes"
          auto-grow
          type="text"
          rows="2"
        />
      </template>
      <template v-else>
        <v-alert type="warning" variant="outlined">
          Mosaic cannot update this ECTs school as there is an issue with their induction events. Please visit the
          <mosaic-router-link
            :to="{
              name: 'TutorEctProgressPage',
              params: { studentId: ectToUpdate.studentId },
            }"
            >Manage Induction page</mosaic-router-link
          >
          to resolve this.
        </v-alert>
      </template>
    </template>
    <template #buttons>
      <v-btn variant="text" ripple :disabled="processing || !canSubmit" @click.prevent="submitUpdateSchool()">
        Submit
      </v-btn>
    </template>
  </ndt-dialog>
</template>

<script setup lang="ts">
import NdtDialog from '../NdtDialog.vue';
import { ref, computed, onBeforeMount, watchEffect } from 'vue';
import type { Ref } from 'vue';
import moment from 'moment';
import { mapState } from '../../store/map-store';
import { fteRateRules } from '@/utils/validations';
import { useApi } from '@/composables/api';

const api = useApi();

const props = defineProps<{
  active: boolean;
  title: string;
  preSelectedEct?: {
    id: number;
    name?: string;
    email: string;
    currentSchoolId: number;
    leftDate: string;
    startDate: string;
    studentId: number;
    startSchoolId: number;
  };
}>();

interface School {
  id: number;
  displayName: string;
  displayNameWithUrn: string;
}

const { selectedInstitution } = mapState();
const schools: Ref<School[]> = ref([]);
onBeforeMount(async () => {
  const r2 = await api.get<School[]>(`institutions/${selectedInstitution.value.id}/schools`);
  schools.value = r2.data;
});

// Would be good to catch any form of progress error here and refer user to manage induction page
const canUpdateSchool = computed(() => ectCurrentSchool.value || ectToUpdate.value?.leftDate);

const emit = defineEmits(['update:active', 'update:changed-school']);
const activeNdtDialog = ref(false);
watchEffect(() => (activeNdtDialog.value = props.active));
watchEffect(() => emit('update:active', activeNdtDialog.value));

const ectCurrentSchool = computed(() => schools.value?.find(s => s.id == ectToUpdate.value?.currentSchoolId));
const minReturnDate = computed(() => {
  if (ectToUpdate.value?.leftDate) return moment(ectToUpdate.value.leftDate).format('YYYY-MM-DD');
  else return leaveDate.value;
});
const minLeaveDate = computed(() => moment(ectToUpdate.value?.startDate).format('YYYY-MM-DD'));
const selectedSchool: Ref<School | null> = ref(null);
const leaveDate = ref(moment.utc().format('YYYY-MM-DD'));
const leaveNote = ref('');
const returnDate = ref(moment.utc().format('YYYY-MM-DD'));
const fteRate = ref('1');
const returnNote = ref('');
const selectedEct = ref(null);
const ectToUpdate = computed(() => {
  return props.preSelectedEct ? props.preSelectedEct : selectedEct.value;
});
const schoolsWithCurrent = computed(() =>
  schools.value.map(s => {
    return {
      ...s,
      nameAndCurrent:
        s.id === props.preSelectedEct?.currentSchoolId ? `${s.displayName} (Current School)` : s.displayNameWithUrn,
    };
  })
);

const errorMessage = ref('');
const processing = ref(false);
const canSubmit = computed(
  () =>
    !!(
      canUpdateSchool.value &&
      leaveDate.value <= returnDate.value &&
      selectedSchool.value &&
      ectToUpdate.value &&
      fteRateRules.every(r => r(fteRate.value))
    )
);
async function submitUpdateSchool() {
  processing.value = true;
  try {
    await api.put(`/ects/update-school`, {
      ects: ectToUpdate.value?.id,
      leaveDate: leaveDate.value,
      returnDate: returnDate.value,
      newSchoolId: selectedSchool.value?.id,
      leaveNote: leaveNote.value,
      returnNote: returnNote.value,
      returnFteRate: parseFloat(fteRate.value),
    });

    emit('update:changed-school');
    processing.value = false;
    selectedSchool.value = null;
  } catch (e) {
    console.log(e);
    errorMessage.value = 'Sorry, cannot update school for this ECT right now';
    processing.value = false;
  }
}
</script>
