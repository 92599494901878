<template>
  <div>
    <mosaic-tab-card-page
      :object-type="reviewNounCapitalisedAndPluralised"
      :object-type-is-plural="true"
      :headers="tabHeaders"
    >
      <template v-if="userStaffHasPermission('reviews.edit')" #actions>
        <mosaic-btn icon="mdi-plus" :to="{ name: 'TutorAdminReviewCreatePage', params: { cohortId: cohortId } }">{{
          reviewNounCapitalised
        }}</mosaic-btn>
      </template>

      <template #live-tab-item>
        <cohort-reviews-page-live-tab />
      </template>

      <template #monitoring-tab-item>
        <cohort-reviews-page-monitoring-tab />
      </template>
    </mosaic-tab-card-page>
  </div>
</template>

<script setup lang="ts">
import MosaicTabCardPage from '@/components/library/pages/MosaicTabCardPage.vue';
import { parseRouteId } from '@/composables/vue-router';
import CohortReviewsPageLiveTab from './CohortReviewsPageLiveTab.vue';
import CohortReviewsPageMonitoringTab from './CohortReviewsPageMonitoringTab.vue';
import { setBreadcrumbs } from '@/utils/breadcrumbs';
import { mapGetters } from '@/store/map-store';

const { reviewNounCapitalisedAndPluralised } = mapGetters();
setBreadcrumbs([
  {
    text: reviewNounCapitalisedAndPluralised.value,
  },
]);

const tabHeaders = [
  {
    text: 'Live',
    key: 'live',
  },
  {
    text: 'Monitoring',
    key: 'monitoring',
  },
];

const cohortId = parseRouteId('cohortId');
</script>
