<template>
  <mosaic-loading-card v-if="loading || loadingStorageConstraints" type="list" />
  <mosaic-load-error-card v-else-if="loadError || loadStorageContraintsError" object-type="portfolio" @retry="load" />
  <portfolio-standard
    v-else
    :standard="standard"
    :standard-evidence="standardEvidence"
    :can-view-reflections="true"
    :storage-constraint="storageConstraint"
    @evidence-edited="evidenceEdited"
    @evidence-deleted="evidenceDeleted"
    @click-favourite="favouriteEvidenceClick"
    @click-target="clickTarget"
    @click-reflection="clickReflection"
    @click-lesson-observation="clickLessonObservation"
    @click-mentor-meeting="clickMentorMeeting"
    @click-student-assignment="clickStudentAssignment"
  >
  </portfolio-standard>
</template>

<script>
import PortfolioStandard from '../../components/PortfolioStandard.vue';
import portfolioService from '../../utils/portfolio-service';
import { mapState } from 'vuex';

export default {
  beforeRouteUpdate(to, from, next) {
    this.standardId = to.params.standardId;
    next();
  },
  data() {
    return {
      standardId: null,
      standard: null,
      standardEvidence: null,
      loading: true,
      loadError: false,
      loadingStorageConstraints: true,
      loadStorageContraintsError: false,
      storageConstraint: null,
    };
  },
  async created() {
    this.standardId = this.$route.params.standardId;
    await this.loadStorageContraints();
  },
  computed: {
    ...mapState(['user', 'selectedInstitution', 'userStudent']),
    breadcrumbs() {
      return [
        {
          text: 'Portfolio',
          to: {
            name: 'StudentPortfolioOverviewPage',
          },
        },
        {
          text: this.standard?.code,
        },
      ];
    },
  },
  watch: {
    standardId(newStandardId) {
      this.standardId = newStandardId;
      this.loadStandardsAndEvidence();
    },
  },
  methods: {
    async load() {
      await Promise.all([this.loadStandardsAndEvidence(), this.loadStorageContraints()]);
    },
    async loadStandardsAndEvidence() {
      this.loading = true;
      this.loadError = false;
      try {
        const response = await this.$api.get(`/standards/${this.standardId}`);
        this.standard = response.data;
        const evidenceResponse = await portfolioService.getStandardEvidenceSummary(this.standard.code, null, this.$api);
        this.standardEvidence = evidenceResponse;
      } catch (e) {
        console.log(e);
        this.loadError = true;
      }
      this.loading = false;
    },
    async loadStorageContraints() {
      this.loadingStorageConstraints = true;
      this.loadStorageContraintsError = false;
      if (this.selectedInstitution.storage_type === 'One Drive' && !this.user.student.storage_email) {
        try {
          const r = await this.$api.get(`/storage-constraint`);
          this.storageConstraint = r.data;
        } catch (e) {
          console.log(e);
          this.loadStorageContraintsError = true;
        }
      }
      this.loadingStorageConstraints = false;
    },
    evidenceEdited(evidence) {
      const selectedTypeEvidence = this.standardEvidence[this.getEvidenceGroup(evidence)];
      const i = selectedTypeEvidence.findIndex(e => e.id === evidence.id);
      selectedTypeEvidence.splice(i, 1, evidence);
    },
    evidenceDeleted(evidence) {
      const selectedTypeEvidence = this.standardEvidence[this.getEvidenceGroup(evidence)];
      const i = selectedTypeEvidence.findIndex(e => e.id === evidence.id);
      selectedTypeEvidence.splice(i, 1);
    },
    getEvidenceGroup(evidence) {
      return evidence.reflection
        ? 'reflection'
        : evidence.target
        ? 'target'
        : evidence.lesson_observation
        ? 'lessonObservation'
        : evidence.mentor_meeting
        ? 'mentorMeeting'
        : evidence.student_assignment
        ? 'courseActivity'
        : 'evidenceFile';
    },

    favouriteEvidenceClick(evidence) {
      let i;
      let skipNext = false;
      Object.keys(this.standardEvidence).forEach(async k => {
        if (skipNext) return;
        i = this.standardEvidence[k].findIndex(e => e.id === evidence.id);
        if (i === -1) return;
        else {
          this.standardEvidence[k][i].favourite = !evidence.favourite;
          try {
            await this.$api.put(`/evidence/${evidence.id}`, {
              favourite: this.standardEvidence[k][i].favourite,
            });
          } catch (e) {
            console.log(e);
            this.evidence[i].favourite = evidence.favourite;
          }
          skipNext = true;
        }
      });
    },
    clickReflection(reflection) {
      this.$router.push({
        name: 'ReflectionPage',
        params: { studentId: this.userStudent.id, reflectionId: reflection.id },
      });
    },
    clickTarget(target) {
      this.$router.push({
        name: 'TargetPage',
        params: { studentId: this.userStudent.id, targetId: target.id },
      });
    },
    clickLessonObservation(lo) {
      this.$router.push({
        name: 'LessonObservationPage',
        params: { studentId: this.userStudent.id, id: lo.id },
      });
    },
    clickMentorMeeting(meeting) {
      this.$router.push({
        name: 'MentorMeetingPage',
        params: { studentId: this.userStudent.id, id: meeting.id },
      });
    },
    clickStudentAssignment(sa) {
      this.$router.push({
        name: 'StudentAssignmentLatestVersionPage',
        params: { id: sa.assignment_id },
      });
    },
  },
  components: {
    PortfolioStandard,
  },
};
</script>
