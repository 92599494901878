<template>
  <mosaic-loading-card v-if="stateProcessing" type="content-layout" />
  <div v-else-if="stateError">Sorry, cannot load this at the moment</div>
  <div v-else-if="contentLayout" class="my-4">
    <div v-for="(section, sectionIndex) of contentLayout.sections" :key="sectionIndex" class="pb-6 d-flex align-top">
      <div class="flex-grow-1-no-overflow">
        <div
          class="section-border-left pl-6 pr-4 pb-2"
          :class="{ 'section-border-right': editable }"
          style="height: 100%"
        >
          <div class="d-flex">
            <mosaic-text-field
              :readonly="!editable"
              name="heading"
              label="Heading"
              :model-value="section.heading"
              :no-icon="true"
              class="mr-2 flex-grow-1-no-overflow"
              @update:model-value="sectionHeadingChanged(section, $event)"
            />
          </div>
          <div>
            <draggable v-model="section.items" item-key="id" handle=".drag-section-handle">
              <template #item="{ element: item, index: itemIndex }">
                <div class="mb-4 d-flex">
                  <div
                    class="item-border-left flex-grow-1-no-overflow d-flex flex-column"
                    :class="{ 'item-border': editable }"
                  >
                    <div class="element-label mt-1 d-flex align-center">
                      <mosaic-icon class="pr-2" :icon="itemIcon(item)" />
                      <span>{{ itemName(item) }}</span>
                    </div>
                    <div class="d-flex align-center flex-grow-1">
                      <div v-if="editable" class="drag-section-handle pb-6">
                        <v-icon size="large">mdi-drag-vertical</v-icon>
                      </div>
                      <mosaic-content-layout-builder-item
                        :item="item"
                        :editable="editable"
                        :presign-url="presignUrl"
                        :get-resource-url-prefix="getResourceUrlPrefix"
                        :save-resource-url="saveResourceUrl"
                        :save-resource-params="saveResourceParams"
                        :supported-item-types="supportedItemTypes"
                        @update:item="updateItemInSection(section, $event)"
                        @click-curriculum-statement="emit('clickCurriculumStatement', $event)"
                      />
                    </div>
                  </div>
                  <div v-if="editable" class="d-flex flex-column" style="min-width: 36px">
                    <ndt-icon-button
                      icon="delete"
                      :tooltip="`Delete ${itemName(item)}`"
                      @click.prevent="deleteItem(section, item)"
                    />
                    <ndt-icon-button
                      :disabled="itemIndex === 0"
                      icon="arrow-up"
                      tooltip="Move up"
                      @click.prevent="moveItemUp(section, item)"
                    />
                    <ndt-icon-button
                      :disabled="!(itemIndex < section.items.length - 1)"
                      icon="arrow-down"
                      tooltip="Move down"
                      @click.prevent="moveItemDown(section, item)"
                    />
                  </div>
                </div>
              </template>
            </draggable>
          </div>

          <div v-if="editable">
            <mosaic-btn-with-menu text="Element" icon="mdi-plus" :menu-items="addItemMenuItems(section)" />
          </div>
        </div>
      </div>
      <div v-if="editable" class="d-flex flex-column pl-1">
        <ndt-icon-button icon="delete" tooltip="Delete section" @click.prevent="deleteSection(section)" />
        <ndt-icon-button
          icon="arrow-up"
          tooltip="Move up"
          :disabled="sectionIndex === 0"
          @click.prevent="moveSectionUp(section)"
        />
        <ndt-icon-button
          icon="arrow-down"
          tooltip="Move down"
          :disabled="!(sectionIndex < contentLayout.sections.length - 1)"
          @click.prevent="moveSectionDown(section)"
        />
      </div>
    </div>

    <v-btn v-if="editable" @click="addSection">
      <v-icon>mdi-plus</v-icon>
      <span>Section</span>
    </v-btn>

    <ndt-dialog v-model:active="deleteSectionDialog.active" title="Delete Section" :width="500">
      <span>This section contains elements. Deleting the section will also delete all of the elements.</span>
      <span class="pt-2">Are you sure you want to delete this section?</span>
      <template #buttons>
        <v-btn variant="text" ripple color="error" @click.prevent="submitDeleteSection(deleteSectionDialog.section)">
          Delete
        </v-btn>
      </template>
    </ndt-dialog>

    <upload-dialog
      v-model:active="addVideoDialogRef.active"
      object-type="video"
      :presign-url="presignUrl"
      :save-resource-url="saveResourceUrl"
      :save-resource-params="saveResourceParams"
      @uploaded="videoAdded"
    />

    <upload-dialog
      v-model:active="addImageDialogRef.active"
      object-type="image"
      :presign-url="presignUrl"
      :save-resource-url="saveResourceUrl"
      :save-resource-params="saveResourceParams"
      @uploaded="imageAdded"
    />

    <add-resource-dialog
      v-model:active="addResourceDialogRef.active"
      :presign-url="presignUrl"
      :save-resource-url="saveResourceUrl"
      :save-resource-params="saveResourceParams"
      @resource-added="resourceAdded"
    />

    <link-to-curriculum-multiple-admin-dialog
      v-if="supportedItemTypes.includes('curriculumLink')"
      v-model:active="linkToCurriculumDialog.active"
      :curriculum-statements="[]"
      :subject-ids="subjectIds"
      @curriculum-statements-selected="curriculumStatementsSelected"
    />

    <nasbtt-modules-unavailable-dialog v-model:active="nasbttModulesUnavailableDialogActive" />
  </div>
</template>

<script setup lang="ts">
import { v4 as uuid } from 'uuid';
import NdtIconButton from '../NdtIconButton.vue';
import UploadDialog from './UploadDialog.vue';
import AddResourceDialog from './AddResourceDialog.vue';
import NdtDialog from '../NdtDialog.vue';
import LinkToCurriculumMultipleAdminDialog from '../LinkToCurriculumMultipleAdminDialog.vue';
import draggable from 'vuedraggable';
import type {
  ContentLayout,
  ContentLayoutItemType,
  ContentLayoutItem,
  ContentLayoutSection,
} from '@/utils/content-layout';
import { ref, toRef } from 'vue';
import type { CurriculumStatement } from '@/store/map-store';
import { mapActions } from '@/store/map-store';
import { computed } from 'vue';
import { watchEffect } from 'vue';
import { mapStateProcessingAndError } from '@/store/map-state-error-and-processing';
import MosaicContentLayoutBuilderItem from './MosaicContentLayoutBuilderItem.vue';
import type { ContentLayoutItemWithoutId } from './content-layout-builder';
import { useContentLayoutBuilder } from './content-layout-builder';
import type { MenuItem } from '@/components/library/buttons/mosaic-button-with-menu';
import NasbttModulesUnavailableDialog from './NasbttModulesUnavailableDialog.vue';
import { notNullableRef } from '@/composables/vue';

const props = withDefaults(
  defineProps<{
    contentLayout: ContentLayout | null;
    supportedItemTypes: ContentLayoutItemType[];
    presignUrl: string;
    getResourceUrlPrefix: string;
    saveResourceUrl: string;
    saveResourceParams: Record<string, unknown>;
    editable?: boolean;
    subjectIds?: number[];
  }>(),
  {
    editable: true,
    subjectIds: () => [],
  }
);

const emit = defineEmits<{
  (e: 'valid', valid: boolean): void;
  (e: 'update:contentLayout', contentLayout: ContentLayout): void;
  (e: 'clickCurriculumStatement', statement: CurriculumStatement): void;
}>();

if (!props.contentLayout) {
  emit('update:contentLayout', { sections: [] });
}
const notNullContentLayout = notNullableRef(toRef(props, 'contentLayout'), 'props.contentLayout');

const { stateError, stateProcessing } = mapStateProcessingAndError('loadCurriculum', 'loadSubjects');
const { loadCurriculum, loadSubjects } = mapActions();
loadCurriculum();
// Subjects are used by MosaicContentLayoutBuilderItem
loadSubjects();

const deleteSectionDialog = ref<{ active: boolean; section: ContentLayoutSection }>({
  active: false,
  section: { id: '', heading: '', items: [] },
});

function itemIsValid(i: ContentLayoutItem): boolean {
  switch (i.itemType) {
    case 'text':
      return !!i.value;
    case 'taskList':
      return i.tasks && i.tasks.every(t => t.title);
    case 'comment':
      return !!i.title;
    case 'youtube':
      return !!i.url;
    case 'fileUpload':
      return i.maxFiles >= i.minFiles && i.maxFiles <= 10 && i.minFiles > 0;
    case 'image':
      return i.displayType === 'fullWidth' || (i.widthPercentage >= 10 && i.widthPercentage <= 100);
    case 'columns':
      return (!i.left || itemIsValid(i.left)) && (!i.right || itemIsValid(i.right));
    case 'nasbttMentorModule':
    case 'nasbttTraineeModule':
      return !!i.publicationCode;
    case 'scormPackage':
      return i.scormPackageId != -1;
    default:
      return true;
  }
}

const isValid = computed(() => {
  return notNullContentLayout.value.sections.every(s => {
    return s.items.every(i => itemIsValid(i));
  });
});

watchEffect(() => {
  emit('valid', isValid.value);
});

// Add elements
const {
  addResourceDialogRef,
  resourceAdded,
  addVideoDialogRef,
  videoAdded,
  addImageDialogRef,
  imageAdded,
  linkToCurriculumDialog,
  curriculumStatementsSelected,
  nasbttModulesUnavailableDialogActive,
  createAddItemMenuItems,
  itemIcon,
  itemName,
} = useContentLayoutBuilder();

function addItemMenuItems(section: ContentLayoutSection): MenuItem[] {
  return createAddItemMenuItems(props.supportedItemTypes, true, item => addItemInSection(section, item));
}

// Sections
function sectionHeadingChanged(section: ContentLayoutSection, heading: string) {
  const contentLayout = {
    ...props.contentLayout,
    sections: notNullContentLayout.value.sections.map(s => {
      if (s.id === section.id) {
        return { ...s, heading };
      }
      return s;
    }),
  };
  emit('update:contentLayout', contentLayout);
}

function addSection() {
  emit('update:contentLayout', {
    ...props.contentLayout,
    sections: [...notNullContentLayout.value.sections, { id: uuid(), heading: '', items: [] }],
  });
}

function deleteSection(section: ContentLayoutSection) {
  if (section.items.length === 0) {
    submitDeleteSection(section);
  } else {
    deleteSectionDialog.value = {
      active: true,
      section: section,
    };
  }
}

function submitDeleteSection(section: ContentLayoutSection) {
  emit('update:contentLayout', {
    ...props.contentLayout,
    sections: [...notNullContentLayout.value.sections.filter(s => s.id !== section.id)],
  });

  deleteSectionDialog.value = {
    active: false,
    section: { id: '', heading: '', items: [] },
  };
}

// Helpers
function deleteItem(section: ContentLayoutSection, item: ContentLayoutItem) {
  updateSectionItems(section, items => items.filter(i => i.id !== item.id));
}
function moveSectionUp(section: ContentLayoutSection) {
  const sections = notNullContentLayout.value.sections;
  const index = sections.findIndex(i => i.id === section.id);
  emit('update:contentLayout', {
    ...props.contentLayout,
    sections: [...sections.slice(0, index - 1), sections[index], sections[index - 1], ...sections.slice(index + 1)],
  });
}
function moveSectionDown(section: ContentLayoutSection) {
  const sections = notNullContentLayout.value.sections;
  const index = sections.findIndex(i => i.id === section.id);
  emit('update:contentLayout', {
    ...props.contentLayout,
    sections: [...sections.slice(0, index), sections[index + 1], sections[index], ...sections.slice(index + 2)],
  });
}
function moveItemUp(section: ContentLayoutSection, item: ContentLayoutItem) {
  updateSectionItems(section, items => {
    const index = items.findIndex(i => i.id === item.id);
    return [...items.slice(0, index - 1), items[index], items[index - 1], ...items.slice(index + 1)];
  });
}
function moveItemDown(section: ContentLayoutSection, item: ContentLayoutItem) {
  updateSectionItems(section, items => {
    const index = items.findIndex(i => i.id === item.id);
    return [...items.slice(0, index), items[index + 1], items[index], ...items.slice(index + 2)];
  });
}

function addItemInSection(section: ContentLayoutSection, item: ContentLayoutItemWithoutId) {
  updateSectionItems(section, items => [...items, { id: uuid(), ...item }]);
}

function updateItemInSection<T extends ContentLayoutItem>(section: ContentLayoutSection, item: T) {
  function isItem(i: ContentLayoutItem): i is T {
    if (i.id === item.id) {
      return true;
    }
    return false;
  }
  updateSectionItems(section, items => {
    return items.map(i => {
      if (isItem(i)) {
        return item;
      }
      return i;
    });
  });
}
function updateSectionItems(
  section: ContentLayoutSection,
  newItemsFunction: (items: ContentLayoutItem[]) => ContentLayoutItem[]
) {
  emit('update:contentLayout', {
    ...props.contentLayout,
    sections: notNullContentLayout.value.sections.map(s => {
      if (s.id === section.id) {
        return {
          ...s,
          items: newItemsFunction(s.items),
        };
      }
      return s;
    }),
  });
}
</script>

<style scoped>
.section-border-left {
  border-left: rgb(var(--v-theme-primary)) 3px solid;
}

.item-border-left {
  border-left: rgb(var(--v-theme-primary)) 5px solid;
}

.section-border-right {
  border-right: rgb(var(--v-theme-secondary)) 1px solid;
}

.item-border {
  border-left: rgb(var(--v-theme-primary)) 5px solid;
  border-top: rgb(var(--v-theme-secondary)) 1px solid;
  border-bottom: rgb(var(--v-theme-secondary)) 1px solid;
  border-right: rgb(var(--v-theme-secondary)) 1px solid;
}

.element-label {
  font-size: 13px;
  padding: 0px 0px 6px 8px;
}

.drag-section-handle {
  cursor: move;
  cursor: -webkit-grabbing;
}
</style>
