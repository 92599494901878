<template>
  <student-mentor-meeting-edit-page v-if="isStudent" @update:dirty="dirty = $event" ref="page" />
  <tutor-mentor-meeting-edit-page v-else @update:dirty="dirty = $event" ref="page" />

  <unsaved-changes-dialog
    v-model:unsaved-changes-dialog="dialog"
    object-type="Mentor Meeting"
    :save="isCreating ? undefined : page?.save"
  />
</template>

<script setup lang="ts">
import { useUserStore } from '@/stores/user';
import StudentMentorMeetingEditPage from './StudentMentorMeetingEditPage.vue';
import TutorMentorMeetingEditPage from './TutorMentorMeetingEditPage.vue';
import { computed, ref } from 'vue';
import { useUnsavedChanges } from '@/composables/unsaved-changes';
import UnsavedChangesDialog from '@/components/UnsavedChangesDialog.vue';
import { useRoute } from 'vue-router';

const { isStudent } = useUserStore();

const route = useRoute();
const isCreating = computed(() => route.name?.toString().includes('Create'));

// This only needs to be done if the child pages are options API
const dirty = ref(false);
const { dialog } = useUnsavedChanges(dirty);

const page = ref<(HTMLInputElement & { save: () => Promise<void> }) | null>(null);
</script>
