<template>
  <cohort-action-edit-page
    action-noun="Instructor Announcements"
    :action-set-date="announcement.setDate"
    :action-scheduled-at-date="scheduledDate"
    :action-schedule-in-the-future="scheduleInTheFuture"
    assignee-noun="Instructor"
    assignee-noun-pluralised="Instructors"
    :load="load"
    :can-continue="canContinue"
    :can-save="canSave"
    :save="save"
    :return-to="returnTo"
    :dirty="dirty"
    :is-editing="isEditing"
  >
    <template #details-step>
      <mosaic-text-field v-model="title" prepend-icon="mdi-pencil" name="title" label="Title" />
      <mosaic-quill-field v-model="body" name="body" label="Contents" />
      <mosaic-text-field prepend-icon="mdi-account" name="from" label="From" :model-value="createdByName" readonly />
      <schedule-in-the-future-picker
        v-if="!isEditing || announcementStatus == 'scheduled'"
        v-model:schedule-in-the-future="scheduleInTheFuture"
        v-model:scheduled-date="scheduledDate"
      />
      <div v-if="isEmailVerificationOnForSelectedInstitution" class="d-flex align-center">
        <mosaic-checkbox
          v-model="sendEmail"
          prepend-icon="mdi-email"
          name="send-email"
          label="Notify by email"
          :disabled="announcementStatus === 'live'"
          hide-details
          density="compact"
        />
        <div class="ml-1">
          <mosaic-help>
            <span v-if="scheduleInTheFuture && announcementStatus != 'live'"
              >Emails will be sent at approximately 8am on the scheduled date</span
            >
            <span v-else>Emails will be sent within 10 minutes of Announcement creation</span>
          </mosaic-help>
        </div>
        <mosaic-count-chip
          v-if="sendEmail && newlySelectedCantReceiveEmailCount > 0"
          class="ml-2"
          color="accent"
          :count="newlySelectedCantReceiveEmailCount"
          :tooltip="`${enumerateItems(newlySelectedCantReceiveEmailCount, 'Instructor')} won't receive the email`"
        />
      </div>
    </template>

    <template #assignment-step>
      <mosaic-action-assignment
        v-if="cohortStaff.length > 0"
        v-model:selected-ids="selectedStaffIds"
        :assignees="filteredCohortStaff"
        :initially-selected-ids="announcement.adminStaffAnnouncementStaff.map(s => s.staffId)"
        :is-editing="isEditing"
        :scheduled-date="scheduledDate"
        :action-set-in-the-future="isEditing ? !announcement.setDate && scheduleInTheFuture : scheduleInTheFuture"
        :action-originally-set-in-the-future="isEditing && !announcement.setDate"
        assignee-noun="Instructor"
        assignee-noun-pluralised="Instructors"
        action-noun="Instructor Announcement"
      >
        <template #filters>
          <role-filter :roles="studentScopedRoles" @update:role-id="selectedRoleId = $event" />
          <div style="min-width: max-content">
            <v-select
              v-model="assignedToTraineeFilter"
              hide-details
              :items="assignedToTraineeOptions"
              :label="`Assigned to ${traineeNounCapitalised()}?`"
            ></v-select>
          </div>
        </template>

        <template v-if="sendEmail && newlySelectedCantReceiveEmailCount > 0" #selected-text>
          <span> - {{ enumerateItems(newlySelectedCantReceiveEmailCount, 'Instructor') }} won't receive the email</span>
        </template>

        <template #status-badge="{ assignee }">
          <staff-announcement-status-chip :read="hasRead(assignee)" />
        </template>

        <template v-if="sendEmail" #details-header>
          <div class="d-flex justify-center" style="width: 90px">Email Status</div>
        </template>

        <template v-if="sendEmail" #details="{ assignee }">
          <div class="d-flex justify-center" style="width: 90px">
            <email-status-chip v-bind="getUserEmailStatus(assignee)" />
          </div>
        </template>
      </mosaic-action-assignment>

      <div v-else>
        <mosaic-card-heading class="pb-2">Instructors</mosaic-card-heading>
        <div>
          There are no Instructors for this Cohort. Instructors can be added by assiging them a role to
          {{ traineeNounCapitalisedAndPluralised }} in the Cohort.
        </div>
      </div>
    </template>
  </cohort-action-edit-page>
</template>

<script setup lang="ts">
import { useUserStore } from '@/stores/user';
import { setBreadcrumbs } from '@/utils/breadcrumbs';
import { computed, ref } from 'vue';
import { useRoute } from 'vue-router';
import MosaicActionAssignment from '@/components/cohort-actions/MosaicActionAssignment.vue';
import { useCohortStore } from '@/stores/cohort';
import { parseRouteId } from '@/composables/vue-router';
import RoleFilter from './RoleFilter.vue';
import { useRoleStore } from '@/stores/role';
import { institutionTimeTomorrowAsDate } from '@/utils/time';
import { mapActions, mapGetters, mapState } from '@/store/map-store';
import EmailStatusChip from '@/components/user/EmailStatusChip.vue';
import { enumerateItems } from '@/utils/text';
import StaffAnnouncementStatusChip from './StaffAnnouncementStatusChip.vue';
import CohortActionEditPage from '@/components/cohort-actions/CohortActionEditPage.vue';
import ScheduleInTheFuturePicker from '@/components/cohort-actions/ScheduleInTheFuturePicker.vue';
import { createReturnTo } from '@/components/cohort-actions/cohort-actions';
import { useApi } from '@/composables/api';

const api = useApi();

// Setup
const route = useRoute();
const isEditing = computed(() => route.name !== 'CohortAdminStaffAnnouncementCreatePage');
const announcementId = parseRouteId('id');

const { user } = useUserStore();
const { selectedInstitution } = mapState();
const { traineeNounCapitalised, isEmailVerificationOnForSelectedInstitution } = mapGetters();
const { loadCurriculum } = mapActions();
loadCurriculum();

type AdminStaffAnnouncement = {
  id: number;
  title: string;
  body?: string;
  setDate: string;
  scheduledAtDate: string;
  sendEmail: boolean;
  createdBy: { id: number; name: string };
  adminStaffAnnouncementStaff: { id: number; staffId: number; staffAnnouncement?: { read: boolean } }[];
};

const announcement = ref<AdminStaffAnnouncement>({
  id: -1,
  title: '',
  body: undefined,
  setDate: '',
  scheduledAtDate: '',
  sendEmail: false,
  createdBy: { id: -1, name: ' ' },
  adminStaffAnnouncementStaff: [],
});
const announcementStatus = computed<'live' | 'scheduled'>(() => (announcement.value.setDate ? 'live' : 'scheduled'));

// Fields
const title = ref('');
const body = ref<string>();

const createdByName = ref(user.value.name);

const scheduleInTheFuture = ref(false);

const scheduledDate = ref<string>(institutionTimeTomorrowAsDate(selectedInstitution.value.config.region));

const sendEmail = ref(false);

const canContinue = computed(
  () => !!title.value && !!body.value && (!scheduleInTheFuture.value || !!scheduledDate.value)
);

const returnTo = createReturnTo('CohortAdminStaffAnnouncementsPage', scheduleInTheFuture, announcementStatus);

setBreadcrumbs(
  computed(() => [
    {
      text: `Instructor Announcements`,
      to: returnTo.value,
    },
    {
      text: isEditing.value ? announcement.value.title : `New Instructor Announcement`,
    },
  ])
);

// Selected staff
const selectedStaffIds = ref<number[]>([]);

const selectedRoleId = ref<number>(-1);
const {
  studentScopedRoles,
  actions: { loadRoles },
} = useRoleStore();

function hasRead({ id }: { id: number }) {
  const adminStaffAnnouncementStaff = announcement.value.adminStaffAnnouncementStaff.find(s => s.staffId === id);
  return adminStaffAnnouncementStaff?.staffAnnouncement?.read;
}

function getUserEmailStatus({ id }: { id: number }) {
  return (
    cohortStaff.value.find(s => s.id === id)?.user || {
      optedOutOfEmails: false,
      emailBounced: false,
      emailVerified: false,
    }
  );
}

const newlySelectedCantReceiveEmailCount = computed(() => {
  const initiallyUserStaffds = announcement.value.adminStaffAnnouncementStaff.map(s => s.staffId);
  const newlyselectedStaffIds = selectedStaffIds.value.filter(id => !initiallyUserStaffds.includes(id));

  return cohortStaff.value.filter(s => {
    return (
      newlyselectedStaffIds.includes(s.id) &&
      (s.user.emailBounced || !s.user.emailVerified || s.user.optedOutOfEmails || s.user.isDemo)
    );
  }).length;
});

// Dirty
const isCreatingAndReturning = ref(false);
const dirty = computed(() => {
  const a = announcement.value;
  if (isEditing.value) {
    const scheduledDirty = a.scheduledAtDate
      ? !scheduleInTheFuture.value || a.scheduledAtDate !== scheduledDate.value
      : scheduleInTheFuture.value;
    const userStaffDirty =
      selectedStaffIds.value.length !== a.adminStaffAnnouncementStaff.length ||
      a.adminStaffAnnouncementStaff.map(s => s.staffId).some(id => !selectedStaffIds.value.includes(id));
    return (
      title.value !== a.title ||
      body.value !== a.body ||
      scheduledDirty ||
      sendEmail.value !== a.sendEmail ||
      userStaffDirty
    );
  } else {
    if (isCreatingAndReturning.value) return false;
    return (
      !!title.value || !!body.value || scheduleInTheFuture.value || sendEmail.value || selectedStaffIds.value.length > 0
    );
  }
});

// Load
async function load() {
  await Promise.all([loadCohortStaff(), loadRoles(), loadAnnouncement()]);
}

const {
  selectedCohort,
  cohortStaff,
  actions: { loadCohortStaff },
} = useCohortStore();

const assignedToTraineeFilter = ref<'all' | 'assigned' | 'noStudentYet'>('all');
const assignedToTraineeOptions = [
  { title: 'All', value: 'all' },
  { title: 'Assigned', value: 'assigned' },
  { title: `No ${traineeNounCapitalised.value()} yet`, value: 'noStudentYet' },
];

const filteredCohortStaff = computed(() => {
  return cohortStaff.value.filter(s => {
    const staffRoles =
      selectedRoleId.value === -1 ? s.staffRoles : s.staffRoles.filter(sr => sr.role.id === selectedRoleId.value);
    if (staffRoles.length === 0) return false;

    return (
      assignedToTraineeFilter.value === 'all' ||
      (assignedToTraineeFilter.value === 'assigned' && staffRoles.some(sr => !!sr.studentId)) ||
      (assignedToTraineeFilter.value === 'noStudentYet' && staffRoles.every(sr => !sr.studentId))
    );
  });
});

async function loadAnnouncement() {
  if (!isEditing.value) return;
  const r = await api.get<AdminStaffAnnouncement>(`/admin-staff-announcements/${announcementId.value}`);
  announcement.value = r.data;
  title.value = r.data.title;
  body.value = r.data.body;
  createdByName.value = r.data.createdBy.name;
  scheduleInTheFuture.value = !!r.data.scheduledAtDate;
  scheduledDate.value = r.data.scheduledAtDate;
  sendEmail.value = r.data.sendEmail;
  selectedStaffIds.value = r.data.adminStaffAnnouncementStaff.map(s => s.staffId);
}

// Save
const canSave = computed(() => canContinue.value && selectedStaffIds.value.length > 0 && dirty.value);

async function save() {
  const requestBody = {
    title: title.value,
    body: body.value,
    scheduledAtDate:
      (!isEditing.value || announcementStatus.value == 'scheduled') && scheduleInTheFuture.value
        ? scheduledDate.value
        : null,
    sendEmail: sendEmail.value,
    selectedAll: selectedStaffIds.value.length === cohortStaff.value.length,
    staffIds: selectedStaffIds.value,
  };

  if (isEditing.value) {
    const r = await api.put<unknown, AdminStaffAnnouncement>(
      `/admin-staff-announcements/${announcementId.value}`,
      requestBody
    );
    announcement.value = r.data;
  } else {
    await api.post(`/cohorts/${selectedCohort.value.id}/admin-staff-announcements`, requestBody);
    isCreatingAndReturning.value = true;
  }
}
</script>
