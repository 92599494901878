import type { CancelToken } from 'axios';
import type { Api } from '@/composables/api';

export function useFilePond(presignUrl: string, api: Api) {
  async function getUploadUrlAndHeaders(cancelToken: CancelToken, contentType: string, filename: string) {
    const r = await api.post(presignUrl, { contentType, filename }, cancelToken);
    return r.data;
  }
  return { getUploadUrlAndHeaders };
}
