<template>
  <div>
    <v-card class="mb-4">
      <v-card-text>
        <div class="d-flex align-center">
          <v-text-field
            v-model="searchTerm"
            density="compact"
            hide-details
            variant="outlined"
            label="Search for Users by email, name or id"
            prepend-inner-icon="mdi-magnify"
          />
          <div class="flex-grow-1"></div>
        </div>
      </v-card-text>
    </v-card>
    <v-card v-if="searchTerm && (users.length || error || !busy)">
      <v-card-text>
        <mosaic-list v-if="users.length" :items="users">
          <template #item="{ item: u }">
            <mosaic-list-item
              :key="u.id"
              :title="u.name || u.email"
              :subtitle="`${u.student ? 'Student' : 'Instructor'} - ${
                u.student
                  ? u.student.institution.name
                  : u.staff
                  ? u.staff.map(st => st.institution.name).join(', ')
                  : ''
              } - ${u.email}`"
              :to="{ name: 'AdminUserPage', params: { userId: u.id } }"
            >
              <template #information>
                <v-chip
                  :color="u.status.trim().toLowerCase() === 'active' ? 'accent' : 'secondary'"
                  style="margin-right: 8px; text-transform: capitalize"
                >
                  {{ u.status }}
                </v-chip>
                <demo-account-badge v-if="u.is_demo"
              /></template>
            </mosaic-list-item>
          </template>
        </mosaic-list>
        <div v-else-if="error">{{ error }}</div>
        <div v-else-if="searchTerm && !busy">No users found</div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import DemoAccountBadge from '../../components/DemoAccountBadge.vue';
import axios from 'axios';

export default {
  name: 'AdminUsersPage',
  components: { DemoAccountBadge },
  data: () => ({
    searchTerm: null,
    users: [],
    cancelSource: null,
    error: '',
    busy: false,
  }),
  computed: {
    breadcrumbs() {
      return [{ text: 'Users' }];
    },
  },
  watch: {
    async searchTerm(x) {
      this.error = '';
      this.busy = true;
      if (this.cancelSource) this.cancelSource.cancel();
      if (!x) {
        this.users = [];
        return;
      }
      this.cancelSource = axios.CancelToken.source();
      try {
        const r = await this.$api.get(`/admin/users?q=${encodeURIComponent(x)}`, this.cancelSource.token);
        this.users = r.data;
      } catch (e) {
        if (!axios.isCancel(e)) {
          console.log(e);
          this.users = [];
          this.error = 'Sorry, cannot load users at the moment';
        }
      }
      this.busy = false;
    },
  },
};
</script>
