<template>
  <div>
    <v-card>
      <v-card-title>
        <div class="pl-6">{{ standardSetName }}</div>
        <div class="pl-4">
          <v-btn :disabled="copying" @click.prevent="copy()">Make a copy</v-btn>
        </div>
      </v-card-title>
      <v-alert v-if="copyErrorMessage" class="mx-4" density="compact" type="error">{{ copyErrorMessage }}</v-alert>

      <v-card-text>
        <div v-if="!standards.length" class="pl-6">No Standards for this Standard</div>
        <div v-if="institutions.length" class="px-6 wrap">
          {{ 'Institutions using this standard set: ' + institutions.map(i => i.name).join(', ') }}
        </div>
        <div v-if="cohort" class="px-6 wrap">{{ 'Cohort using this standard set: ' + cohort.name }}</div>
        <v-alert v-if="cohort || institutions.length" color="accent" class="mx-6">
          CAUTION: Any edits made on this page will be reflected in the UI for users in institutions and cohorts using
          this standard set. This includes ordering.
        </v-alert>

        <div class="px-6 d-flex align-center">
          <v-text-field
            v-model="localStandardNoun"
            class="pr-4"
            style="max-width: 30%"
            name="title"
            label="Standard Noun"
            type="text"
          />
          <v-text-field
            v-model="localStandardNounPlural"
            name="title"
            style="max-width: 30%"
            label="Standard Noun Plural"
            type="text"
          />
          <v-btn class="ml-6" @click.prevent="updateStandardNoun()">Update</v-btn>
        </div>
        <v-list>
          <mosaic-list :items="standards">
            <template #item="{ item: standard }">
              <mosaic-list-item
                :title="`${standard.code}: ${standard.name}`"
                :subtitle="renderStandardParams(standard)"
                :to="{ name: 'AdminStandardSetSubstandardsPage', params: { standardId: standard.id } }"
              >
                <template #actions>
                  <ndt-icon-button
                    v-if="index != 0"
                    icon="chevron-up"
                    tooltip="Change order: move up"
                    @click.prevent="moveUp(standard)"
                  />
                  <ndt-icon-button
                    v-if="index != standards.length - 1"
                    icon="chevron-down"
                    tooltip="Change order: move down"
                    @click.prevent="moveDown(standard)"
                  />
                  <ndt-icon-button icon="pencil" tooltip="Edit standard" @click.prevent="editStandard(standard)" />
                  <ndt-icon-button
                    icon="delete"
                    tooltip="Delete Standard"
                    :disabled="institutions.length > 0"
                    @click.prevent="deleteStandard(standard)"
                  />
                  <v-icon>mdi-chevron-right</v-icon>
                </template>
              </mosaic-list-item>
            </template>
          </mosaic-list>
          <v-divider />

          <v-list-item>
            <template #prepend>
              <v-avatar>
                <v-icon>mdi-plus-circle-outline</v-icon>
              </v-avatar>
            </template>

            <span class="text-h6">New Standard</span>
            <div class="d-flex">
              <div style="width: 100px">
                <v-text-field v-model="newStandard.code" label="Code" hide-details></v-text-field>
              </div>
              <div class="flex-grow-1 px-4">
                <v-text-field v-model="newStandard.name" label="Name" hide-details></v-text-field>
              </div>
            </div>
            <div class="d-flex">
              <mosaic-checkbox v-model="newStandard.professional" no-icon class="pr-6" label="Part 2" />
              <mosaic-checkbox v-model="newStandard.review_only" no-icon class="pr-6" label="Review Only" />
              <div>
                <v-select
                  v-model="newStandard.subject_id"
                  prepend-icon="mdi-pencil-ruler"
                  name="edit-student-subject"
                  :items="subjectsWithNullOption"
                  placeholder="Subject"
                  item-title="name"
                  item-value="id"
                />
              </div>
            </div>

            <v-list-item-action>
              <v-btn :disabled="!canAddStandard" @click.prevent="addStandard">Add</v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-alert v-if="errorMessage.length" type="error" closable>{{ errorMessage }}</v-alert>
      <mosaic-snackbar v-model="snackbar.active" :color="snackbar.color" :message="snackbar.message" />
    </v-card>

    <ndt-dialog
      v-model:active="editStandardDialog.active"
      title="Update Standard Name"
      :error-message="editStandardDialog.errorMessage"
      :on-close="onDialogClose"
    >
      <v-alert color="accent"
        >CAUTION: Any edits made will be reflected in the UI for users in institutions using this standard set</v-alert
      >
      <div class="d-flex mt-2">
        <div style="width: 70px">
          <mosaic-text-field v-model="editStandardDialog.code" label="Code" hide-details no-icon />
        </div>
        <div class="flex-grow-1 px-4">
          <mosaic-text-field v-model="editStandardDialog.name" label="Name" hide-details no-icon />
        </div>
      </div>
      <div class="d-flex">
        <mosaic-checkbox v-model="editStandardDialog.professional" no-icon class="pr-6" label="Part 2" />
        <mosaic-checkbox v-model="editStandardDialog.review_only" no-icon class="pr-6" label="Review Only" />
        <div>
          <v-select
            v-model="editStandardDialog.subject_id"
            prepend-icon="mdi-pencil-ruler"
            name="edit-student-subject"
            :items="subjectsWithNullOption"
            placeholder="Subject"
            item-title="name"
            item-value="id"
          />
        </div>
      </div>
      <template #buttons>
        <v-btn variant="text" ripple :disabled="editStandardDialog.processing" @click.prevent="submitEditStandard()"
          >Save</v-btn
        >
      </template>
    </ndt-dialog>
    <ndt-dialog v-model:active="deleteDialog.active" title="Delete Standard" :width="500">
      <span>Are you sure you want to delete "{{ deleteDialog.standardName }}"?</span>
      <template #buttons>
        <v-btn
          variant="text"
          ripple
          color="error"
          :disabled="deleteDialog.processing"
          @click.prevent="submitDeleteStandard()"
          >Delete</v-btn
        >
      </template>
    </ndt-dialog>
  </div>
</template>

<script>
import NdtIconButton from '../../components/NdtIconButton.vue';
import NdtDialog from '../../components/NdtDialog.vue';
import { mapState } from 'vuex';

export default {
  name: 'AdminStandardSetStandardsPage',
  data: function () {
    return {
      copying: false,
      copyErrorMessage: '',
      standardSetId: null,
      standardSetName: '',
      standards: [],
      localStandardNoun: '',
      localStandardNounPlural: '',
      newStandard: {
        code: '',
        name: '',
        professional: false,
        review_only: false,
        subject_id: null,
      },
      errorMessage: '',
      addingStandard: false,
      institutions: [],
      cohort: null,
      deleteDialog: {
        active: false,
        standardName: '',
        standardId: '',
        processing: '',
      },
      editStandardDialog: {
        active: false,
        name: '',
        code: '',
        subject_id: null,
        review_only: false,
        professional: false,
        standardId: null,
        errorMessage: '',
        processing: false,
      },
      snackbar: {
        active: false,
        color: 'success',
        message: 'Success!',
      },
    };
  },
  watch: {
    $route: {
      handler: function () {
        this.getStandards();
      },
      deep: true,
    },
  },
  created: async function () {
    this.getStandards();
    this.$store.dispatch('loadSubjects');
  },
  computed: {
    ...mapState(['subjects']),
    canAddStandard() {
      return this.newStandard.code.length && this.newStandard.name.length && !this.addingStandard;
    },
    subjectsWithNullOption() {
      return this.subjects.concat({
        name: 'All Subjects',
        id: null,
      });
    },
    breadcrumbs() {
      return [
        {
          text: 'Standard Sets',
          to: {
            name: 'AdminStandardSetsPage',
          },
        },
        {
          text: this.standardSetName,
        },
      ];
    },
  },
  methods: {
    async getStandards() {
      this.standardSetId = this.$route.params.standardSetId.toString();
      const r = await this.$api.get(`/standard-sets/${this.standardSetId}`);
      this.standardSetName = r.data.name;
      this.localStandardNoun = r.data.standard_noun;
      this.localStandardNounPlural = r.data.standard_noun_plural;
      this.standards = r.data.standards;
      this.institutions = r.data.institutions;
      this.cohort = r.data.cohort;
    },
    async updateStandardNoun() {
      try {
        await this.$api.put(`/standard-sets/${this.standardSetId}`, {
          standard_noun: this.localStandardNoun,
          standard_noun_plural: this.localStandardNounPlural,
        });
        this.snackbar = {
          active: true,
          color: 'success',
          message: 'Success!',
        };
      } catch (e) {
        console.log(e);
        this.errorMessge = 'Sorry cannot update standard nouns right now';
        this.snackbar = {
          active: true,
          color: 'error',
          message: 'Sorry cannot update standard nouns right now',
        };
      }
    },
    renderStandardParams(standard) {
      const flags = [];
      if (standard.review_only) flags.push('Review Only');
      if (standard.professional) flags.push('Part2 Review Format');
      if (standard.subject?.name) flags.push(standard.subject.name);
      return flags.join(', ');
    },
    async addStandard() {
      this.errorMessage = '';
      this.addingStandard = true;
      const order = this.standards.length
        ? Math.max.apply(
            null,
            this.standards.map(t => t.order)
          ) + 1
        : 1;
      try {
        const r = await this.$api.post('/admin-standards', {
          ...this.newStandard,
          standard_set_id: this.standardSetId,
          order: order,
        });
        this.standards.push(r.data);
        this.newStandard.name = '';
        this.newStandard.code = '';
      } catch (e) {
        console.log(e);
        this.errorMessage = 'Sorry, cannot add a standard right now';
      }
      this.addingStandard = false;
    },
    editStandard(standard) {
      this.editStandardDialog = {
        active: true,
        standardId: standard.id,
        subject_id: standard.subject?.id,
        ...standard,
      };
    },
    async submitEditStandard() {
      try {
        this.editStandardDialog.processing = true;
        const r = await this.$api.put(`/admin-standards/${this.editStandardDialog.standardId}`, {
          ...this.editStandardDialog,
        });
        this.editStandardDialog.processing = false;
        const standard = r.data;
        const i = this.standards.findIndex(t => t.id === standard.id);
        this.standards.splice(i, 1, standard);
        this.onDialogClose();
      } catch (e) {
        this.editStandardDialog.errorMessage = 'Sorry, cannot update this template right now';
        throw e;
      }
    },
    deleteStandard(standard) {
      this.deleteDialog = {
        active: true,
        standardId: standard.id,
        standardName: standard.name,
        processing: false,
      };
    },
    submitDeleteStandard() {
      this.deleteDialog.processing = true;
      this.$api.delete(`/admin-standards/${this.deleteDialog.standardId}`).then(() => {
        this.deleteDialog.active = false;
        this.getStandards();
      });
    },
    onDialogClose() {
      this.editStandardDialog = {
        active: false,
        name: '',
        StandardId: null,
        errorMessage: '',
        processing: false,
      };
    },
    async moveUp(standard) {
      const StandardIndex = this.standards.findIndex(t => t.id === standard.id);
      if (StandardIndex === 0) return;
      const higherStandard = this.standards[StandardIndex - 1];
      const standardsToUpdate = [
        {
          id: standard.id,
          order: standard.order - 1,
        },
        {
          id: higherStandard.id,
          order: higherStandard.order + 1,
        },
      ];
      await this.$api.post(`admin-standards/update-ordering`, { standardsToUpdate: standardsToUpdate });
      await this.getStandards();
    },
    async moveDown(standard) {
      const StandardIndex = this.standards.findIndex(t => t.id === standard.id);
      if (StandardIndex === this.standards.length - 1) return;
      const lowerStandard = this.standards[StandardIndex + 1];
      const standardsToUpdate = [
        {
          id: standard.id,
          order: standard.order + 1,
        },
        {
          id: lowerStandard.id,
          order: lowerStandard.order - 1,
        },
      ];
      await this.$api.post(`admin-standards/update-ordering`, { standardsToUpdate: standardsToUpdate });
      await this.getStandards();
    },
    async copy() {
      this.copying = true;
      this.copyErrorMessage = null;
      try {
        const r = await this.$api.post(`/standard-sets/${this.standardSetId}/copy`);
        this.$router.push({ name: 'AdminStandardSetStandardsPage', params: { standardSetId: r.data.id } });
      } catch (e) {
        this.copyErrorMessage = 'Error copying this standard set';
        console.log(e);
      }
      this.copying = false;
    },
  },
  components: { NdtDialog, NdtIconButton },
};
</script>

<style scoped>
.wrap {
  overflow-wrap: normal;
}
</style>
