import { ecfProgrammeItems } from './ect';
import type { EcfProgramme } from './ect';
import moment from 'moment';
import XLSX from 'xlsx';
import { lastName, firstName } from '../text';

type Institution = {
  name: string;
  establishment_id: string;
  headteacher_role_id: number;
  induction_tutor_role_id: number;
};

type Ect = {
  trn: string;
  name: string;
  email: string;
  dateOfBirth: string;
  termsCompletedBeforeCurrentInductionPeriod: number;
  currentInductionPeriodExtensionTermsCount: number;
  endOfYear2Review: {
    status: string;
    dueDate: string;
  };
  startDate: string;
  ecfProgramme: EcfProgramme;
  student: {
    staffRoles: {
      roleId: number;
      staff: {
        email: string;
      };
    }[];
  };
};

type Header =
  | {
      name: string;
      value: (ect: Ect, institution: Institution) => string;
      validation: (value: string) => boolean;
    }
  | {
      name: 'Not for TRA >>>';
      value: (ect: Ect, institution: Institution) => '';
      validation: (value: string) => boolean;
    };

function isValidDateForTraExport(dateStr: string) {
  return moment(dateStr, 'DD/MM/YYYY', true).isValid();
}

const isValidInductionType = (x: string) => x === '' || ecfProgrammeItems.some(item => item === x);
const inductionOutcomes = ['In Progress', 'Pass'];
const isValidInductionOutcome = (x: string) => inductionOutcomes.includes(x);

const dfeBulkUploadHeaders: Header[] = [
  {
    name: 'Establishment ID',
    value: (_ect: Ect, institution: Institution) => institution.establishment_id,
    validation: (x: string) => !!x,
  },
  {
    name: 'TRN',
    validation: (x: string) => !!x && new RegExp(/^\d{7}$/).test(x),
    value: (ect: Ect, _institution: Institution) => ect.trn,
  },
  {
    name: 'Surname',
    validation: (x: string) => !!x,
    value: (ect: Ect, _institution: Institution) => lastName(ect.name),
  },
  {
    name: 'First Name',
    validation: (x: string) => !!x,
    value: (ect: Ect, _institution: Institution) => firstName(ect.name),
  },
  {
    name: 'Date of Birth',
    validation: (x: string) => isValidDateForTraExport(x),
    value: (ect: Ect, _institution: Institution) => moment(ect.dateOfBirth).format('DD/MM/YYYY'),
  },
  {
    name: 'Transition Arrangements Eligible',
    //Not a mandatory field and will not update DfE system - it's a field calculated by the DfE themselves
    validation: (x: string) => x === '',
    value: (_ect: Ect, _institution: Institution) => '',
  },
  {
    name: 'Induction Programme Type',
    //This is not required if the ect is "transition arrangements eligible"
    validation: (x: string) => isValidInductionType(x),
    value: (ect: Ect, _institution: Institution) => ect.ecfProgramme,
  },
  {
    name: 'Induction Outcome',
    validation: (x: string) => isValidInductionOutcome(x),
    value: (ect: Ect, _institution: Institution) =>
      ect.endOfYear2Review.status === 'approved' ? 'Pass' : 'In Progress',
  },
  {
    name: 'Induction Period Start Date',
    validation: (x: string) => isValidDateForTraExport(x),
    value: (ect: Ect, _institution: Institution) => moment(ect.startDate).format('DD/MM/YYYY'),
  },
  {
    name: 'Induction Period End Date',
    validation: (x: string) => isValidDateForTraExport(x) || x === '',
    value: (ect: Ect, _institution: Institution) =>
      ect.endOfYear2Review.status === 'approved' ? moment(ect.endOfYear2Review.dueDate).format('DD/MM/YYYY') : '',
  },
  {
    name: 'Induction Period Number Of Terms',
    //Needs updating when processing more than claims/passes
    validation: (x: string) => x === '' || (Number.isInteger(parseInt(x)) && parseInt(x) <= 6),
    value: (ect: Ect, _institution: Institution) =>
      ect.endOfYear2Review.status === 'approved' ? (6 - ect.termsCompletedBeforeCurrentInductionPeriod).toString() : '',
  },
  {
    name: 'Induction Period Extended Number Of Terms',
    //Needs updating when processing more than claims/passes, note is mandatory if induction outcome == 'induction extended'
    validation: (x: string) => x === '' || Number.isInteger(parseInt(x)),
    value: (ect: Ect, _institution: Institution) =>
      ect.currentInductionPeriodExtensionTermsCount > 0 ? ect.currentInductionPeriodExtensionTermsCount.toString() : '',
  },
  {
    name: 'Not for TRA >>>',
    value: (_ect: Ect, _institution: Institution) => '' as const,
    validation: (x: string) => x === '',
  },
  {
    name: 'Headteacher/Principal Email(s)',
    validation: (_x: string) => true,
    value: (ect: Ect, institution: Institution) =>
      ect.student.staffRoles
        .filter(role => role.roleId === institution.headteacher_role_id)
        .map(role => role.staff.email)
        .join(';'),
  },
  {
    name: 'Induction Tutor Email(s)',
    validation: (_x: string) => true,
    value: (ect: Ect, institution: Institution) =>
      ect.student.staffRoles
        .filter(role => role.roleId === institution.induction_tutor_role_id)
        .map(role => role.staff.email)
        .join(';'),
  },
  {
    name: 'ECT Email',
    validation: (_x: string) => true,
    value: (ect: Ect, _institution: Institution) => ect.email,
  },
];

export function exportEcts(action: string, ects: Ect[], institution: Institution) {
  return exportEctsToClaim(filteredEcts(action, ects), institution);
}

function filteredEcts(type: string, ects: Ect[]): Ect[] {
  return ects.filter(e => (type === 'passes' && e.endOfYear2Review.status === 'approved') || type === 'all');
}

function exportEctsToClaim(ects: Ect[], institution: Institution) {
  let hasErrors = false;
  const headers = dfeBulkUploadHeaders.map(h => h.name);
  const rows = ects.map(e => {
    const errors: string[] = [];
    const row = dfeBulkUploadHeaders.map(h => {
      const fieldValue = h.value(e, institution);
      const error = validateField(h, fieldValue);
      if (error) {
        errors.push(error);
        hasErrors = true;
      }
      return fieldValue;
    });
    return row.concat(errors.join(', '));
  });
  const data = [hasErrors ? [...headers, 'Errors'] : headers, ...rows];
  exportSpreadsheet(data, institution);
  return !hasErrors;
}

function validateField(h: Header, fieldValue: string) {
  return h.validation(fieldValue) ? '' : `${h.name} is invalid`;
}

function exportSpreadsheet(data: string[][], institution: Institution) {
  const xlxs = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(xlxs, XLSX.utils.aoa_to_sheet(data), `Claims`);
  XLSX.writeFile(xlxs, `${institution.name} Claims (${moment().format('DD-MM-YYYY')}).xlsx`);
}
