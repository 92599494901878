<template>
  <div>
    <mosaic-info-alert class="mt-2" v-if="canEditCurriculum && usingCohorts.length > 0">
      Edits to this Curriculum will show across all using Cohorts.
    </mosaic-info-alert>
    <mosaic-configure-preview-page
      v-model="configurePreview"
      v-model:trigger-background-load="triggerBackgroundLoad"
      :load="load"
      object-type="Teaching Strategy"
    >
      <template #configure>
        <mosaic-save-card
          v-if="teachingStrategy && selectedCurriculum"
          :readonly="published || !canEditCurriculum"
          :save="save"
          :can-save="canSave"
          object-type="Teaching Strategy"
          :valid="valid"
          :return-to="{
            name: isCohortPage ? 'CohortAdminCurriculumStatementPage' : 'InstAdminCurriculumStatementPage',
            params: {
              themeId: curriculumStatement?.id.toString() || '-1',
            },
            query: {
              tab: 'teaching-strategies',
              preview: configurePreview || 'configure',
            },
          }"
        >
          <mosaic-card-title>
            <span>{{ teachingStrategy.name }}</span>
            <template #chip>
              <mosaic-published-draft-chip
                :published="teachingStrategy.status === 'published'"
                object-type="Teaching Strategy"
                mode="card"
              />
            </template>
          </mosaic-card-title>
          <mosaic-radio-buttons
            v-model="type"
            :radios="typeOptions"
            inline
            :readonly="published || !canEditCurriculum"
          ></mosaic-radio-buttons>
          <div v-if="type === 'content_layout'">
            <mosaic-content-layout-builder
              v-model:content-layout="contentLayout"
              :editable="!published && canEditCurriculum"
              :supported-item-types="['text', 'resource', 'video', 'youtube', 'link', 'image', 'columns']"
              :presign-url="`/presign/curriculum/${selectedCurriculum.id}/teaching-strategy-resources`"
              get-resource-url-prefix="/curriculum-statements/teaching-strategies/resources"
              :save-resource-url="`/curriculum-statements/teaching-strategies/${teachingStrategy.id}/resources`"
              :save-resource-params="{}"
            />
          </div>
          <div v-else>
            <mosaic-text-field
              v-model="externalLink"
              :readonly="published"
              name="external-link"
              label="External link"
              prepend-icon="mdi-link"
              required
            />
          </div>
        </mosaic-save-card>
      </template>

      <template #preview>
        <curriculum-teaching-strategy-card
          v-if="type === 'content_layout' && previewTeachingStrategy"
          :teaching-strategy="previewTeachingStrategy"
        />
        <mosaic-card v-else>
          <div>
            External links cannot be previewed, but will open in a new tab when clicked by
            {{ traineeNounCapitalisedAndPluralised }} and Instructors.
          </div>
        </mosaic-card>
      </template>
    </mosaic-configure-preview-page>

    <unsaved-changes-dialog v-model:unsaved-changes-dialog="dialog" object-type="Teaching Strategy" :save="save" />
  </div>
</template>

<script setup lang="ts">
import type { ConfigurePreview } from '@/components/library/configure-preview/configure-preview';
import MosaicContentLayoutBuilder from '@/components/mosaic-content-layout/MosaicContentLayoutBuilder.vue';
import { parseRouteId } from '@/composables/vue-router';
import type { CurriculumStatement, CurriculumTeachingStrategy } from '@/stores/curriculum';
import { useCurriculumStore } from '@/stores/curriculum';
import { setBreadcrumbs } from '@/utils/breadcrumbs';
import type { ContentLayout } from '@/utils/content-layout';
import { contentLayoutDirty } from '@/utils/content-layout';
import { computed, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import CurriculumTeachingStrategyCard from './CurriculumTeachingStrategyCard.vue';
import { useUnsavedChanges } from '@/composables/unsaved-changes';
import UnsavedChangesDialog from '@/components/UnsavedChangesDialog.vue';
import { useApi } from '@/composables/api';
import { useCohortStore } from '@/stores/cohort';
import type { Cohort } from '@/store/map-store';

import { hasPermissionForSelectedInstitution } from '@/composables/permission';

const api = useApi();

// Load/setup
const {
  actions: { loadCurriculum },
  curriculumNoun,
  curriculumThemes,
  curriculumStatements,
  selectedCurriculum,
} = useCurriculumStore();

let selectedCohort = computed<Cohort | null>(() => null);
const route = useRoute();

const isCohortPage = computed(() => route.name === 'CohortAdminCurriculumTeachingStrategyPage');
if (isCohortPage.value) {
  ({ selectedCohort } = useCohortStore());
}
const configurePreview = ref<ConfigurePreview>('configure');

const triggerBackgroundLoad = ref(false);
async function load() {
  await loadCurriculum({ throwError: true, force: false });
}

const curriculumTheme = computed(() =>
  curriculumStatement.value?.themeId && curriculumThemes.value
    ? curriculumThemes.value[curriculumStatement.value.themeId]
    : undefined
);

const curriculumStatementId = parseRouteId('statementId');
const curriculumStatement = computed<CurriculumStatement | undefined>(() =>
  curriculumStatements.value ? curriculumStatements.value[curriculumStatementId.value] : undefined
);

const teachingStrategyId = parseRouteId('strategyId');
const teachingStrategy = computed(() => {
  return curriculumStatement.value
    ? curriculumStatement.value?.curriculumTeachingStrategies.find(ts => ts.id === teachingStrategyId.value)
    : undefined;
});

const published = computed(() => teachingStrategy.value?.status === 'published' || false);

const usingCohorts = computed(
  () =>
    selectedCurriculum.value?.usingCohorts.filter(c => !isCohortPage.value || c.id !== selectedCohort.value?.id) || []
);
const canEditCurriculum = computed(() => hasPermissionForSelectedInstitution('curriculum.edit').value);

setBreadcrumbs(
  computed(() => [
    {
      text: curriculumNoun.value,
      to: {
        name: isCohortPage.value ? 'TutorAdminCohortCurriculumPage' : 'TutorAdminCurriculumPage',
      },
    },
    {
      text: curriculumTheme.value?.code || '',
      to: {
        name: isCohortPage.value ? 'TutorAdminCohortCurriculumThemePage' : 'TutorAdminCurriculumThemePage',
        params: {
          themeId: curriculumTheme.value?.id.toString() || '-1',
        },
      },
    },
    {
      text: curriculumStatement.value?.code || '',
      to: {
        name: isCohortPage.value ? 'CohortAdminCurriculumStatementPage' : 'InstAdminCurriculumStatementPage',
        params: {
          themeId: curriculumStatement.value?.id.toString() || '-1',
        },
        query: {
          tab: 'teaching-strategies',
          preview: configurePreview.value || 'configure',
        },
      },
    },
    {
      text: teachingStrategy.value?.name || '',
    },
  ])
);

// Data
const type = ref<'content_layout' | 'external_link'>('content_layout');
const typeOptions = [
  { label: 'Create in Mosaic', value: 'content_layout' },
  { label: 'External link', value: 'external_link' },
];

const externalLink = ref('');
const contentLayout = ref<ContentLayout | null>(null);
watch(teachingStrategy, teachingStrategyUpdated);
teachingStrategyUpdated();

function teachingStrategyUpdated() {
  type.value = teachingStrategy.value?.strategyType || 'content_layout';
  contentLayout.value = teachingStrategy.value?.contentLayout || null;
  externalLink.value = teachingStrategy.value?.externalLink || '';
}

const contentLayoutValid = ref(true);
const valid = computed(() => type.value !== 'content_layout' || contentLayoutValid.value);

// Save/dirty checking
const dirty = computed(
  () =>
    teachingStrategy.value?.strategyType !== type.value ||
    (type.value === 'content_layout' &&
      contentLayoutDirty(contentLayout.value, teachingStrategy.value?.contentLayout)) ||
    (type.value === 'external_link' && teachingStrategy.value?.externalLink !== externalLink.value)
);

const { dialog } = useUnsavedChanges(dirty);
const canSave = computed(() => (type.value !== 'external_link' || !!externalLink.value) && dirty.value);

async function save() {
  await api.put(`/curriculum-statements/teaching-strategies/${teachingStrategyId.value}`, {
    strategyType: type.value,
    externalLink: externalLink.value,
    contentLayout: contentLayout.value,
  });
  await loadCurriculum({ force: true, throwError: true });
}

// Preview
const previewTeachingStrategy = computed<CurriculumTeachingStrategy | undefined>(() => {
  if (!teachingStrategy.value || !contentLayout.value) return undefined;
  const ts: CurriculumTeachingStrategy = { ...teachingStrategy.value, contentLayout: contentLayout.value };
  return ts;
});
</script>
