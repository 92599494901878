<template>
  <div>
    <mosaic-tab-card-page object-type="Mentor Meetings" :object-type-is-plural="true" :headers="tabHeaders">
      <template #monitoring-tab-item>
        <cohort-mentor-meetings-page-monitoring-tab v-if="hasPlacements" />
        <div v-else class="py-4">
          This Cohort does not have any Placements configured. Please get in touch with support if you'd like to view
          monitoring for this Cohort.
        </div>
      </template>
      <template #template-tab-item> <cohort-mentor-meetings-page-template-tab /> </template>
    </mosaic-tab-card-page>
  </div>
</template>

<script setup lang="ts">
import { hasPermissionForSelectedCohort } from '@/composables/permission';
import { useCohortStore } from '@/stores/cohort';
import { setBreadcrumbs } from '@/utils/breadcrumbs';
import { computed } from 'vue';
import CohortMentorMeetingsPageMonitoringTab from './CohortMentorMeetingsPageMonitoringTab.vue';
import CohortMentorMeetingsPageTemplateTab from './CohortMentorMeetingsPageTemplateTab.vue';

setBreadcrumbs([
  {
    text: 'Mentor Meetings',
  },
]);

const hasAdminPermission = hasPermissionForSelectedCohort('Admin');
const hasTemplateEditPermission = hasPermissionForSelectedCohort('mentorMeetingTemplates.edit');
const { hasPlacements } = useCohortStore();

const tabHeaders = computed(() => {
  const headers = [];
  if (hasAdminPermission.value) {
    headers.push({
      text: 'Monitoring',
      key: 'monitoring',
    });
  }
  if (hasTemplateEditPermission.value) {
    headers.push({
      text: 'Template',
      key: 'template',
    });
  }
  return headers;
});
</script>
