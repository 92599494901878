<template>
  <mosaic-card :class="{ 'card-width': !smallScreen }">
    <h1>Password Security</h1>
    <div class="mt-6 body-text">
      <div>
        The password you have nominated for your account is not secure and has been found on public data breach
        registries. This breach has <b><u>not</u></b> occurred from Mosaic.
      </div>
      <div class="mt-2">
        Protect your password with these handy tips:
        <ul>
          <li>Don't share your password with anyone</li>
          <li>Use a unique password for every account you create</li>
          <li>
            Make passwords at least 12 characters (see
            <a href="https://www.ncsc.gov.uk/collection/top-tips-for-staying-secure-online/three-random-words"
              >this guidance</a
            >
            from the National Cyber Security Centre)
          </li>
          <li>Use a password manager to generate and store your passwords</li>
          <li>Don't use personal or school related information in your password</li>
        </ul>
      </div>
      <div class="mt-2">
        To check if your password has been compromised, use
        <a href="https://haveibeenpwned.com/">this website</a>, which has been recommended by the
        <a href="https://www.ncsc.gov.uk/blog-post/passwords-passwords-everywhere">National Cyber Security Centre</a>.
      </div>
    </div>
  </mosaic-card>
</template>

<script>
export default {
  name: 'PasswordSecurityPage',
};
</script>

<style scoped>
.card-width {
  width: 80%;
  margin: auto;
}

.body-text {
  font-size: 1rem;
}
</style>
