<template>
  <mosaic-tooltip-chip color="error">
    <template #text>
      <v-icon>mdi-lock</v-icon>
    </template>
    <template #tooltip>
      User's account is locked due to too many login attempts. Unlock it by resetting their password.</template
    >
  </mosaic-tooltip-chip>
</template>
