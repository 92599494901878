<template>
  <div>
    <v-card v-if="error">
      <v-card-text>
        <div class="pa-4">{{ error }}</div>
      </v-card-text>
    </v-card>
    <v-card v-else class="mb-4">
      <mosaic-snackbar v-model="snackbar.active" :color="snackbar.color" :message="snackbar.message" />
      <v-card-text>
        <div class="d-flex align-center flex-wrap" style="row-gap: 16px; column-gap: 16px">
          <div class="flex-grow-1"></div>
          <div>
            <span>Remaining Credits: </span><v-chip label :color="creditSumColor">{{ creditSum }}</v-chip>
          </div>
          <v-btn ripple @click.prevent="addTransaction()">
            <div class="d-flex align-center">
              <v-icon>mdi-plus</v-icon>
              <span>transaction</span>
            </div>
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
    <v-card>
      <mosaic-list :items="transactions" empty-text="There are no transactions for this institution">
        <template #item="{ item: t }">
          <mosaic-list-item
            :key="t.id"
            icon="mdi-bank-transfer"
            :icon-color="transactionColor(t)"
            :title="renderTransactionTimeFrame(t)"
            :subtitle="renderTransactionDetails(t)"
          >
            <template #actions>
              <ndt-icon-button
                v-if="t.transactionType === 'consumed'"
                icon="file"
                tooltip="Open Report"
                :disabled="!t.fileId"
                @click.prevent="openCreditReport(t.id)"
              />

              <ndt-icon-button
                v-if="t.transactionType == 'purchased'"
                icon="pencil"
                tooltip="Edit Transaction"
                @click.prevent="updateTransaction(t)"
              />

              <ndt-icon-button
                icon="text"
                :tooltip="`${t.note ? 'Show note' : 'No note recorded'}`"
                :disabled="!t.note"
                @click.prevent="showNote(t)"
              />

              <ndt-icon-button icon="delete" tooltip="Delete Transaction" @click.prevent="deleteTransaction(t.id)" />
            </template>
          </mosaic-list-item>
        </template>
      </mosaic-list>
    </v-card>
    <ndt-dialog
      v-model:active="editTransactionDialog.active"
      :title="`${editTransactionDialog.id ? 'Edit' : 'Add'} Transaction`"
      :error-message="editTransactionDialog.errorMessage"
    >
      <div class="pl-4 pb-2">
        <div style="width: 65%">
          <div v-if="!editTransactionDialog.id">
            <v-radio-group v-model="editTransactionDialog.transactionType" inline>
              <v-radio label="Consumption" value="consumed"></v-radio>
              <v-radio label="Purchase" value="purchased"></v-radio>
            </v-radio-group>
          </div>
          <div v-else>Credit Purchase</div>
          <v-select
            v-if="editTransactionDialog.transactionType == 'consumed'"
            v-model="editTransactionDialog.termId"
            prepend-icon="mdi-hexagon-slice-2"
            class="mr-2"
            label="Term"
            :items="terms"
            item-value="id"
            item-title="name"
          />
          <mosaic-date-picker
            v-if="editTransactionDialog.transactionType == 'purchased'"
            v-model:date="editTransactionDialog.transactionDate"
            label="Transaction Date"
          ></mosaic-date-picker>
          <mosaic-text-field
            name="credits"
            v-if="editTransactionDialog.transactionType == 'purchased'"
            v-model="editTransactionDialog.credits"
            prepend-icon="mdi-cash-100"
            label="Credits"
            class="mr-2"
            type="number"
            :rules="[v => (!isNaN(parseInt(v)) && v >= 0) || 'Credits should be positive number']"
          >
            <template #prepend>
              <v-avatar><v-icon>mdi-cash-100</v-icon></v-avatar>
            </template>
          </mosaic-text-field>
        </div>
        <mosaic-text-area
          name="notes"
          v-model="editTransactionDialog.note"
          variant="filled"
          label="Notes"
          auto-grow
          type="text"
          rows="2"
        />
        <v-alert v-if="editTransactionDialog.id && editTransactionDialog.transactionType === 'consumed'" type="info"
          >N.B. Clicking update will always re-run the credits consumption calculation, even if the term has not
          changed</v-alert
        >
      </div>
      <template #buttons>
        <v-btn variant="text" ripple :disabled="!canUpdateTransaction" @click.prevent="submitUpdateTransaction()">{{
          editTransactionDialog.id ? 'Update' : 'Add'
        }}</v-btn>
      </template>
    </ndt-dialog>
    <ndt-dialog
      v-model:active="deleteDialog.active"
      title="Delete transaction"
      :error-message="deleteDialog.errorMessage"
    >
      <span>Are you sure you want to delete this transaction?</span>
      <template #buttons>
        <v-btn
          variant="text"
          ripple
          color="error"
          :disabled="deleteDialog.processing"
          @click.prevent="submitDeleteTransaction()"
          >Delete</v-btn
        >
      </template>
    </ndt-dialog>
    <ndt-dialog
      v-model:active="noteDialog.active"
      :title="
        noteDialog.transactionType === 'consumed'
          ? `Consumption: ${noteDialog.termName}`
          : `Purchase: ${noteDialog.transactionDate}`
      "
      close-button-text="Close"
    >
      <span>{{ noteDialog.note }}</span>
    </ndt-dialog>
  </div>
</template>

<script>
import NdtIconButton from '../../components/NdtIconButton.vue';
import NdtDialog from '../../components/NdtDialog.vue';
import { mapState } from 'vuex';
import moment from 'moment';

const editTransactionDialogDefaults = {
  active: false,
  transactionDate: moment().toISOString(),
  transactionType: 'consumed',
  credits: 1000,
  termId: null,
  note: '',
  errorMessage: '',
  processing: false,
};

export default {
  name: 'AdminInstitutionCreditsPage',
  components: {
    NdtDialog,
    NdtIconButton,
  },
  data: function () {
    return {
      transactions: [],
      terms: [],
      error: '',
      editTransactionDialog: { ...editTransactionDialogDefaults },
      deleteDialog: {
        processing: false,
        active: false,
        id: null,
        errorMessage: '',
      },
      snackbar: {
        active: false,
        color: 'error',
        message: '',
      },
      noteDialog: {
        active: false,
        note: '',
        termName: '',
        transactionDate: '',
      },
    };
  },
  watch: {
    selectedTermIdClashes(x) {
      this.editTransactionDialog.errorMessage =
        x && this.editTransactionDialog.termId
          ? 'Cannot add consumption transaction for same term as existing transaction. Please delete the existing transaction and try again.'
          : '';
    },
  },
  computed: {
    ...mapState(['adminInstitution']),
    breadcrumbs() {
      return [
        {
          text: this.adminInstitution?.name,
          to: {
            name: 'AdminInstitutionPage',
          },
        },
        {
          text: 'AB credits',
        },
      ];
    },
    selectedTermIdClashes() {
      return (
        !!this.editTransactionDialog.termId &&
        this.editTransactionDialog.transactionType === 'consumed' &&
        this.transactions.some(
          x => x.id !== this.editTransactionDialog.id && x.termId === this.editTransactionDialog.termId
        )
      );
    },
    creditSum() {
      if (this.transactions.length === 0) return 0;
      return this.transactions.reduce((sum, transaction) => {
        return sum + (transaction.transactionType == 'consumed' ? -1 : 1) * transaction.credits;
      }, 0);
    },
    creditSumColor() {
      if (this.creditSum === 0) return 'secondary';
      return this.creditSum > 0 ? 'success' : 'error';
    },
    canUpdateTransaction() {
      return (
        !this.editTransactionDialog.processing &&
        ((this.editTransactionDialog.termId && this.editTransactionDialog.transactionType == 'consumed') ||
          (this.editTransactionDialog.credits &&
            this.editTransactionDialog.transactionType == 'purchased' &&
            this.editTransactionDialog.transactionDate)) &&
        this.editTransactionDialog.transactionType &&
        !this.selectedTermIdClashes
      );
    },
  },

  async created() {
    await this.loadTransactions();
    try {
      const r2 = await this.$api.get(`institutions/${this.adminInstitution.id}/terms`);
      this.terms = r2.data;
    } catch (e) {
      console.log(e);
      this.error = 'Sorry, cannot load terms right now';
    }
  },

  methods: {
    async loadTransactions() {
      try {
        const r = await this.$api.get(`institutions/${this.adminInstitution.id}/credit-transactions`);
        this.transactions = r.data;
      } catch (e) {
        console.log(e);
        this.error = 'Sorry, cannot load transactions right now';
      }
    },
    renderTransactionTimeFrame(transaction) {
      return transaction.transactionType === 'consumed'
        ? transaction.termName
        : moment(transaction.transactionDate).format('MMM YYYY');
    },
    renderSignedCredits(transaction) {
      const sign = transaction.transactionType === 'consumed' ? '-' : '+';
      return sign + transaction.credits;
    },
    renderTransactionDetails(transaction) {
      if (transaction.transactionType === 'consumed') {
        return `Consumed ${transaction.credits} credit${transaction.credits > 1 ? 's' : ''} during term: ${
          transaction.termName
        }. Report Generated: ${moment(transaction.createdAt).format('DD-MM-YY')}`;
      } else {
        return `Purchased ${transaction.credits} credits on: ${moment(transaction.transactionDate).format(
          'DD-MM-YYYY'
        )}`;
      }
    },
    transactionColor(t) {
      return t.transactionType === 'purchased' ? 'success' : 'error';
    },
    async submitUpdateTransaction() {
      this.editTransactionDialog.processing = true;
      let apiCall;
      const creditsUrl = `institutions/${this.adminInstitution.id}/credit-transactions`;
      if (!this.editTransactionDialog.id) {
        apiCall = x => this.$api.post(creditsUrl, x);
      } else {
        apiCall = x => this.$api.put(`${creditsUrl}/${this.editTransactionDialog.id}`, x);
      }
      try {
        await apiCall({
          credits: this.editTransactionDialog.transactionType == 'consumed' ? null : this.editTransactionDialog.credits,
          transactionDate:
            this.editTransactionDialog.transactionType == 'consumed'
              ? null
              : this.editTransactionDialog.transactionDate,
          termId: this.editTransactionDialog.transactionType == 'purchased' ? null : this.editTransactionDialog.termId,
          transactionType: this.editTransactionDialog.transactionType,
          institutionId: this.adminInstitution.id,
          note: this.editTransactionDialog.note,
        });
        this.loadTransactions();
        this.editTransactionDialog = { ...editTransactionDialogDefaults };
      } catch (e) {
        console.log(e);
        this.editTransactionDialog.errorMessage = 'Sorry, cannot update this transaction right now';
        this.editTransactionDialog.processing = false;
      }
    },
    updateTransaction(transaction) {
      this.editTransactionDialog = {
        ...this.editTransactionDialog,
        active: true,
        id: transaction.id,
        termId: transaction.termId,
        transactionType: transaction.transactionType,
        transactionDate: transaction.transactionDate,
        note: transaction.note,
      };
    },
    addTransaction() {
      this.editTransactionDialog = {
        ...editTransactionDialogDefaults,
        active: true,
      };
    },
    deleteTransaction(id) {
      this.deleteDialog.id = id;
      this.deleteDialog.errorMessage = '';
      this.deleteDialog.active = true;
    },
    async submitDeleteTransaction() {
      this.deleteDialog.processing = true;
      try {
        await this.$api.delete(`institutions/${this.adminInstitution.id}/credit-transactions/${this.deleteDialog.id}`);
        this.loadTransactions();
        this.deleteDialog.active = false;
      } catch (e) {
        console.log(e);
        this.deleteDialog.errorMessage = 'Sorry, cannot delete this transaction right now';
      }
      this.deleteDialog.processing = false;
    },
    showNote(transaction) {
      this.noteDialog = {
        ...transaction,
        active: true,
        transactionDate: moment(transaction.transactionDate).format('DD-MM-YY'),
      };
    },
    async openCreditReport(id) {
      try {
        const r = await this.$api.get(`institutions/${this.adminInstitution.id}/credit-transactions/${id}/report`);
        window.open(r.data.web_url, '_blank');
      } catch (e) {
        console.log(e);
        this.snackbar = {
          active: true,
          color: 'error',
          message: 'Sorry, cannot open this file right now',
        };
      }
    },
  },
};
</script>
