<template>
  <div>
    <v-card v-if="error && !busy">
      <v-card-text>
        <div class="pa-4">{{ error }}</div>
      </v-card-text>
    </v-card>
    <mosaic-card v-if="!error && !busy">
      <mosaic-card-title>Folder Structure Templates</mosaic-card-title>
      <div class="pt-2">
        <div>
          Folder Structure Templates are collections of folders that will be added to
          {{ traineeNoun() }} accounts when they first log into Mosaic (if assigned via their cohort).
        </div>
        <div>Each cohort can have a different folder structure template if desired.</div>
      </div>
      <mosaic-list
        :items="templates"
        empty-text="You currently do not have any folder templates. Add one below to be able to assign it to a cohort."
      >
        <template #item="{ item: template }">
          <mosaic-list-item
            :to="templateClickTo(template.id)"
            icon="mdi-file-tree"
            :title="template.name"
            :subtitle="`Assigned to cohorts: ${template.cohorts.map(x => x.name).join(', ') || 'None'}`"
          >
            <template #actions>
              <mosaic-icon-btn icon="pencil" tooltip="Rename Folder Template" @click.prevent="editTemplate(template)" />
            </template>
          </mosaic-list-item>
        </template>
      </mosaic-list>

      <mosaic-error-alert :override-error-message="creationError" />
      <div class="d-flex align-center">
        <v-text-field
          v-model="newTemplate"
          prepend-icon="mdi-pencil"
          name="folder-template-name"
          label="Folder Structure Template Name"
        ></v-text-field>
        <mosaic-btn class="ml-4 mb-2" :disabled="!newTemplate.length" :loading="addItemProcessing" @click="addTemplate"
          >Add</mosaic-btn
        >
      </div>
    </mosaic-card>

    <ndt-dialog v-model:active="editDialog.active" title="Rename Template" :error-message="editDialog.error">
      <div class="d-flex">
        <div class="flex-grow-1 px-4">
          <v-text-field v-model="editDialog.name" label="Folder Structure Template Name" hide-details></v-text-field>
        </div>
      </div>
      <template #buttons>
        <v-btn variant="text" ripple :disabled="editDialog.processing" @click.prevent="submitEditTemplate()"
          >Save</v-btn
        >
      </template>
    </ndt-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import NdtDialog from '../../components/NdtDialog.vue';

export default {
  name: 'TutorAdminFolderTemplatesListPage',
  data: () => ({
    error: '',
    busy: true,
    creationError: '',
    templates: [],
    newTemplate: '',
    editDialog: {
      active: false,
      processing: false,
      id: null,
      name: '',
      error: '',
    },
    addItemProcessing: false,
  }),
  computed: {
    ...mapState(['selectedInstitution']),
    breadcrumbs() {
      return [
        {
          text: 'Folder Structure Templates',
        },
      ];
    },
  },
  async created() {
    await this.loadTemplates();
  },
  methods: {
    templateClickTo(id) {
      return {
        name: 'TutorAdminFolderTemplatePage',
        params: { templateId: id },
      };
    },
    async loadTemplates() {
      try {
        this.busy = true;
        const response = await this.$api.get(`/institutions/${this.selectedInstitution.id}/folder-structure-templates`);
        this.templates = response.data;
      } catch (e) {
        this.error = 'Sorry, cannot load your folder templates';
        console.log(e);
      }
      this.busy = false;
    },
    async addTemplate() {
      this.addItemProcessing = true;
      this.creationError = '';
      try {
        const r = await this.$api.post(`/institutions/${this.selectedInstitution.id}/folder-structure-templates`, {
          name: this.newTemplate,
        });
        this.templates.push(r.data);
        this.newTemplate = '';
      } catch (e) {
        console.log(e);
        this.creationError = 'Sorry, cannot add a template at the moment';
      }
      this.addItemProcessing = false;
    },
    editTemplate(template) {
      this.editDialog.name = template.name;
      this.editDialog.id = template.id;
      this.editDialog.active = true;
    },
    async submitEditTemplate() {
      this.editDialog.processing = true;
      this.editDialog.error = '';
      try {
        await this.$api.put(`/folder-structure-templates/${this.editDialog.id}`, {
          name: this.editDialog.name,
        });
        this.editDialog.active = false;
        this.loadTemplates();
      } catch (e) {
        console.log(e);
        this.editDialog.error = 'Sorry, cannot edit your folder template at the moment';
      }
      this.editDialog.processing = false;
    },
  },
  components: { NdtDialog },
};
</script>
