<template>
  <mosaic-load-error-card
    v-if="loadCohortStudentsError"
    :object-type="traineeNounCapitalisedAndPluralised"
    :object-type-is-plural="true"
    @retry="loadCohortStudents()"
  />
  <!-- This should match App.vue -->
  <mosaic-loading-card v-else-if="loadCohortStudentsProcessing" type="list" />
  <router-view v-else />
</template>

<script>
import { useCohortStore } from '@/stores/cohort';

export default {
  name: 'TutorCohortWrapper',
  setup() {
    const {
      loadCohortStudentsProcessing,
      loadCohortStudentsError,
      actions: { loadCohortStudents },
    } = useCohortStore();
    return { loadCohortStudentsProcessing, loadCohortStudentsError, loadCohortStudents };
  },
};
</script>
