<template>
  <div v-if="curriculumStatements.length > 0">
    <div v-if="curriculumEnabled">
      <v-chip v-if="curriculumStatements.every(cs => !cs.hidden)" color="accent" class="mr-4"
        >Curriculum Links: {{ curriculumStatements.map(s => s.code).join(', ') }}</v-chip
      >
      <mosaic-tooltip-chip v-else color="grey" class="mr-4">
        <template #text> Curriculum Links: {{ curriculumStatements.map(s => s.code).join(', ') }} </template>

        <template #tooltip>
          {{ curriculumStatements.every(cs => cs.hidden) ? 'The' : 'Some' }} Curriculum Links will not be visible to any
          {{ traineeNounPluralised }} because the Curriculum Statements linked to this Target are hidden
        </template>
      </mosaic-tooltip-chip>
    </div>
    <mosaic-tooltip-chip v-else color="grey" class="mr-4">
      <template #text> Curriculum Links: {{ curriculumStatements.map(s => s.code).join(', ') }} </template>

      <template #tooltip>
        The Curriculum Links will not be visible to any {{ traineeNounPluralised }} because the Curriculum's visibility
        to the Cohort is disabled
      </template>
    </mosaic-tooltip-chip>
  </div>
</template>

<script setup lang="ts">
import { mapGetters } from '@/store/map-store';
import { useStudentStore } from '@/stores/student';

defineProps<{
  curriculumStatements: {
    hidden: boolean;
    code: string;
  }[];
}>();

const { traineeNounPluralised } = useStudentStore();
const { curriculumEnabled } = mapGetters();
</script>
