import type { Api } from '@/composables/api';
import store from '../store/index';
import type { Institution } from '@/store/map-store';

// These should be vuex actions
export async function refreshAdminInstitutions(api: Api, force = true) {
  if (!force && store.state.adminIstitutions.length > 0) {
    return;
  }
  const r = await api.get('/institutions');
  const institutions = r.data;
  store.commit('updateAdminInstitutions', institutions);
}

export async function getFullAdminInstitution(institutionId: number, api: Api) {
  const r = await api.get<Institution>(`/institutions/${institutionId}`);
  const institution = r.data;
  store.commit('updateAdminInstitution', institution);
  store.commit('updateAdminCohorts', institution.cohorts);
}
