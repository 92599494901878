<template>
  <div>
    <v-card v-if="loadGroupsError">
      <v-card-text>
        <div class="pa-4">Sorry, cannot load your groups right now</div>
      </v-card-text>
    </v-card>
    <v-card v-if="!loadGroupsError && !loadGroupsProcessing" class="mb-4">
      <v-card-text>
        <mosaic-card-title>
          Groups

          <template #actions>
            <v-btn ripple @click.prevent="addGroupDialog.active = true">
              <div class="d-flex align-center">
                <v-icon>mdi-plus</v-icon>
                <span>Group</span>
              </div>
            </v-btn>
          </template>
        </mosaic-card-title>
        <div class="d-flex align-center">
          <div>
            <v-text-field
              v-model="filterTerm"
              hide-details
              label="Filter groups by name"
              prepend-icon="mdi-magnify"
              style="min-width: 250px"
            />
          </div>
        </div>
      </v-card-text>
    </v-card>
    <v-card class="flex-grow-1">
      <v-card-text>
        <mosaic-list
          :items="filteredGroups"
          :empty-text="`You have no Groups${groups.length > 0 ? ' for these filters' : ''}`"
        >
          <template #item="{ item: g }">
            <mosaic-list-item :to="clickGroupTo(g.id)" icon="mdi-account-group" :title="g.name">
              <template #actions>
                <ndt-icon-button
                  icon="delete"
                  :tooltip="g.has_students > 0 ? 'Cannot delete groups containing students' : 'Delete group'"
                  :disabled="g.has_students"
                  @click.prevent="deleteGroup(g)"
                />
              </template>
            </mosaic-list-item>
          </template>
        </mosaic-list>
      </v-card-text>
    </v-card>

    <ndt-dialog
      v-model:active="deleteDialog.active"
      :title="`Delete group`"
      :error-message="deleteDialog.error"
      :width="500"
    >
      <span>Are you sure you want to delete "{{ deleteDialog.title }}"?</span>
      <template #buttons>
        <v-btn
          variant="text"
          ripple
          color="error"
          :disabled="deleteDialog.processing"
          @click.prevent="submitDeleteGroup()"
          >Delete</v-btn
        >
      </template>
    </ndt-dialog>
    <ndt-dialog v-model:active="addGroupDialog.active" title="Add Group" :error-message="addGroupDialog.error">
      <v-text-field v-model="addGroupDialog.name" prepend-icon="mdi-account" label="Name" type="text" />
      <v-autocomplete
        v-model="addGroupDialog.students"
        chips
        multiple
        :label="traineeNounCapitalisedAndPluralised"
        :items="autocompleteStudents"
        item-title="nameOrEmail"
        item-value="id"
        :no-data-text="'No ' + traineeNounCapitalisedAndPluralised + ' found'"
        prepend-inner-icon="mdi-magnify"
        color="black"
        :custom-filter="filterStudents"
      ></v-autocomplete>
      <template #buttons>
        <v-btn variant="text" ripple :disabled="!canSubmitAddGroup" @click.prevent="submitAddGroup">Add</v-btn>
      </template>
    </ndt-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import NdtDialog from '../../components/NdtDialog.vue';
import { mapStateError, mapStateProcessing } from '@/store/map-store';
import NdtIconButton from '../../components/NdtIconButton.vue';
import { useCohortStore } from '@/stores/cohort';

export default {
  name: 'TutorGroupsListPage',
  components: { NdtDialog, NdtIconButton },
  setup() {
    const {
      selectedCohortStudents,
      actions: { loadCohortStudents },
    } = useCohortStore();
    return { selectedCohortStudents, loadCohortStudents };
  },
  data: () => ({
    name1: '',
    group1: null,
    students1: [],
    filterTerm: '',
    addGroupDialog: {
      active: false,
      error: null,
      processing: false,
      name: '',
      students: [],
    },
    deleteDialog: {
      active: false,
      id: null,
      title: '',
      error: '',
      processing: false,
    },
  }),
  computed: {
    ...mapState(['selectedCohort', 'groups']),
    ...mapStateProcessing(['loadGroups']),
    ...mapStateError(['loadGroups']),
    breadcrumbs() {
      return [
        {
          text: 'Groups',
        },
      ];
    },
    filteredGroups() {
      return this.groups.filter(x => x.name.toLowerCase().includes(this.filterTerm.toLowerCase()));
    },
    canSubmitAddGroup() {
      return !this.addGroupDialog.processing && this.addGroupDialog.name;
    },
    autocompleteStudents() {
      return this.selectedCohortStudents.map(x => ({
        ...x,
        nameOrEmail: x.name ? `${x.name} (${x.email})` : x.email,
      }));
    },
  },

  methods: {
    async loadGroups() {
      await this.$store.dispatch('loadGroups', { force: true });
    },
    async submitAddGroup() {
      try {
        this.addGroupDialog.processing = true;
        this.addGroupDialog.error = '';
        await this.$api.post('/groups', {
          name: this.addGroupDialog.name,
          cohortId: this.selectedCohort.id,
          studentIds: this.addGroupDialog.students,
        });
        this.loadGroups();

        if (this.addGroupDialog.students.length > 0) {
          this.loadCohortStudents(true, true);
        }
      } catch (e) {
        console.log(e);
        this.addGroupDialog.error = 'Sorry, cannot add this group at the moment';
        this.addGroupDialog.processing = false;
        return;
      }
      this.addGroupDialog = {
        active: false,
        error: null,
        processing: false,
        mame: '',
        students: [],
      };
    },
    deleteGroup(group) {
      this.deleteDialog = {
        active: true,
        id: group.id,
        title: group.name,
        processing: false,
      };
    },
    submitDeleteGroup() {
      this.deleteDialog.processing = true;
      this.$api.delete(`/groups/${this.deleteDialog.id}`).then(
        () => {
          this.deleteDialog = {
            active: false,
            processing: false,
          };
          this.loadGroups();
        },
        () => {
          this.deleteDialog.error = `Sorry, cannot delete this group at the moment`;
          this.deleteDialog.processing = false;
        }
      );
    },
    clickGroupTo(id) {
      return { name: 'TutorGroupPage', params: { id } };
    },
    filterStudents(nameOrEmail, queryText) {
      const searchText = queryText.toLowerCase();
      return nameOrEmail.toLowerCase().includes(searchText);
    },
  },
};
</script>
