<template>
  <div>
    <legacy-mosaic-inline-creation-list-page
      object-type="Page"
      :title="name"
      :load="load"
      :items="sections"
      icon="mdi-list-box-outline"
      :hide-add="published"
      :can-add-item="canAddSection"
      :add-item="addSectionItem"
      :can-retitle-item="canRenameSection"
      :retitle-item-dialog-opened="renameSection"
      :retitle-item="saveRenameSection"
      :delete-url-stem="deleteUrlStem"
      :move-item="moveSectionListItem"
      :move-disabled-message="moveDisabledMessage"
      :edit-title="editThisTemplate"
    >
      <template #title-chip>
        <mosaic-role-completed-chip
          class="pr-2"
          :completed="false"
          :role-id="assignmentTemplate?.roleId || 'student'"
          :role-scoped-tooltip="(role: RoleWithStudent) => `Activities created using this Template are to be completed by ${role.pluralisedName}`"
        />
        <assignment-templates-published-draft-chip :published="published" mode="card" class="pr-2" />
      </template>

      <template #title-actions>
        <mosaic-disabled-tooltip :disabled="!!noPublishReason" :tooltip="cannotPublishTooltip(noPublishReason)">
          <template #default="{ disabled }">
            <mosaic-btn-with-menu
              text="Course Activity"
              icon="mdi-plus"
              :menu-items="activeCohortMenuItems"
              :disabled="disabled"
            ></mosaic-btn-with-menu>
          </template>
        </mosaic-disabled-tooltip>
      </template>

      <template #subtitle>
        <div v-if="assignments.length > 0">
          Course Activities using this Template:
          <span v-for="(a, index) of assignments" :key="a.id">
            <mosaic-router-link
              :to="{
                name: 'CohortAssignmentViewPage',
                params: { cohortId: a.cohort.id, id: a.id },
              }"
              >{{ a.name }} ({{ a.cohort.name }})</mosaic-router-link
            ><span v-if="index !== assignments.length - 1">, </span>
          </span>
        </div>
      </template>

      <template #add-item-fields>
        <mosaic-text-field
          v-model="newSectionName"
          name="add-section-name"
          label="Name"
          prepend-icon="mdi-pencil"
          class="flex-grow-1"
        />
      </template>
      <template #retitle-item-fields="{ onKeyupEnter }">
        <mosaic-text-field
          v-model="renameDialog.name"
          name="rename-section-name"
          label="Name"
          prepend-icon="mdi-pencil"
          @keyup.enter="onKeyupEnter"
        />
      </template>
    </legacy-mosaic-inline-creation-list-page>
    <assignment-template-edit-dialog
      v-model:active="editTemplateDialog.active"
      :template="editTemplateDialog.template"
      @save="load()"
    />
  </div>
</template>

<script setup lang="ts">
import { useRouter } from 'vue-router';
import { setBreadcrumbs } from '@/utils/breadcrumbs';
import { computed, ref } from 'vue';
import AssignmentTemplatesPublishedDraftChip from './AssignmentTemplatesPublishedDraftChip.vue';
import AssignmentTemplateEditDialog from './AssignmentTemplateEditDialog.vue';
import { mapActions, mapGetters } from '@/store/map-store';
import type { NoPublishReason } from './assignment-templates';
import { cannotPublishTooltip } from './assignment-templates';
import type { Item } from '@/components/library/mosaic-list/list-item-helpers';
import { useMoveListItem, useRetitleListItem } from '@/components/library/mosaic-list/list-item-helpers';
import { parseRouteId } from '@/composables/vue-router';
import { useApi } from '@/composables/api';
import type { AssignmentTemplate } from './assignment-templates';
import { useEditAssignmentTemplate } from './assignment-templates';
import type { RoleWithStudent } from '@/store/map-store';

const api = useApi();

const assignmentTemplateId = parseRouteId('id');

export type AssignmentTemplateFull = AssignmentTemplate & {
  assignments: { id: number; name: string; cohort: { id: number; name: string } }[];
};

const assignmentTemplate = ref<AssignmentTemplateFull | null>(null);
const name = computed(() => assignmentTemplate.value?.name || '');
const published = computed(() => assignmentTemplate.value?.status === 'published');
const assignments = computed(() => assignmentTemplate.value?.assignments || []);
const noPublishReason = computed<NoPublishReason>(() => assignmentTemplate.value?.noPublishReason || null);

const { editTemplate, editTemplateDialog } = useEditAssignmentTemplate();
const editThisTemplate = () => {
  editTemplate(assignmentTemplate.value);
};

async function load() {
  const r = await api.get<AssignmentTemplateFull>(`/assignment-templates/${assignmentTemplateId.value}`);
  assignmentTemplate.value = r.data;
}

setBreadcrumbs(
  computed(() => [
    {
      text: `Course Activity Templates`,
      to: {
        name: 'TutorAdminAssignmentTemplatesListPage',
      },
    },
    {
      text: name.value,
    },
  ])
);

// Transform Sections to Simple List Items and add Review page

const sections = computed(() => {
  let sections: Item[] = [];
  if (assignmentTemplate.value) {
    sections = assignmentTemplate.value.assignmentTemplateSections.sortBy('order').map((s, index, items) => ({
      ...s,
      title: s.name,
      hideMove: published.value,
      disableDelete: published.value,
      deleteDisabledTooltip: 'Cannot delete Pages from a published Course Activity Template',
      last: index === items.length - 1,
      clickTo: {
        name: 'TutorAdminAssignmentTemplateSectionPage',
        params: { assignmentTemplateId: assignmentTemplateId.value.toString(), id: s.id.toString() },
      },
    }));
  }
  sections.push({
    id: -1,
    icon: 'order-bool-ascending-variant',
    hideMove: true,
    disableDelete: true,
    deleteDisabledTooltip: 'The Review Page is mandatory',
    disableEdit: true,
    editDisabledTooltip: 'The Review Page name is not editable',
    title: 'Review',
    clickTo: {
      name: 'TutorAdminAssignmentTemplateSectionPage',
      params: { assignmentTemplateId: assignmentTemplateId.value.toString(), id: (-1).toString() },
    },
    order: sections.length,
  });
  return sections;
});

const newSectionName = ref('');
const canAddSection = computed(() => !!newSectionName.value);

async function addSectionItem() {
  await api.post(`/assignment-templates/${assignmentTemplateId.value}/sections`, { name: newSectionName.value });
}

// Move Section
const moveDisabledMessage = published.value
  ? 'This Course Activity Template cannot be edited because it has been published'
  : '';

const { moveListItem: moveSectionListItem } = useMoveListItem('/assignment-templates/sections', api);

// Delete Section
const deleteUrlStem = '/assignment-templates/sections';

// Retitle Section
const renameDialog = ref({ name: '', originalName: '' });
const canRenameSection = computed(
  () => !!renameDialog.value.name && renameDialog.value.name !== renameDialog.value.originalName
);
function renameSection(item: Item) {
  renameDialog.value = {
    name: item.title,
    originalName: item.title,
  };
}

const body = computed(() => ({
  name: renameDialog.value.name,
}));
const { retitleListItem: saveRenameSection } = useRetitleListItem('/assignment-templates/sections', body, api);

// Cohorts
const { loadCohorts } = mapActions();
loadCohorts();

const { activeCohorts } = mapGetters();
const router = useRouter();
const activeCohortMenuItems = computed(() =>
  activeCohorts.value.map(c => ({
    icon: 'mdi-google-classroom',
    text: c.name,
    click: () =>
      router.push({
        name: 'CohortAssignmentCreatePage',
        params: { cohortId: c.id.toString() },
        query: { assignmentTemplateId: assignmentTemplate.value?.id.toString() },
      }),
  }))
);
</script>
