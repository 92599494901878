<template>
  <mosaic-alert v-if="!$slots.customAlert?.() && !approving" type="info" class="mb-2">
    {{
      `For any issues/questions with registration, please contact
      ${selectedInstitution.name}${
        selectedInstitution.contact_email ? ` on ${selectedInstitution.contact_email}` : ''
      }.`
    }}</mosaic-alert
  >
  <template v-if="ect && ect.rejectionReason">
    <mosaic-alert v-if="ect.status === 'rejected'" type="error" class="mb-2">
      This ECT has been rejected for the following reason:
      <div class="pt-2">
        <em>"{{ ect.rejectionReason }}"</em>
      </div>
      <div v-if="approving" class="pt-2">
        If a change to the ECT data is required, the registering school will be able to re-register the ECT once the
        issue has been resolved.
      </div>
      <div v-else class="pt-2">
        If a change to the ECT data is required, then you will be able to re-register the ECT once updated.
      </div>
    </mosaic-alert>
    <mosaic-info-alert v-else>
      This ECT was previously rejected for the following reason:
      <div class="py-2">
        <em>"{{ ect.rejectionReason }}"</em>
      </div>
      and has now been re-registered.
    </mosaic-info-alert>
  </template>
  <slot></slot>
  <!-- Message to contact support below as email validation rules protect against any emails that are institution scoped -->
  <mosaic-error-alert :action="actionError" :error="!!actionError" />
  <div v-if="!$slots.customAlert" class="pb-4 pt-8 helper-text" :class="{ 'pl-4': step === 1 }">
    <strong>N.B.</strong> All sections must be fully completed before continuing. Alternatively you can save and return
    later to fully complete.
  </div>
  <slot name="customAlert"></slot>

  <div class="d-flex justify-end pb-2">
    <v-btn v-if="step !== 1" class="mr-2" @click.prevent="emit('back', $event)"> Back </v-btn>
    <v-btn
      v-if="!hideSave"
      class="mr-2"
      :disabled="saveProcessing"
      :loading="saveProcessing"
      @click.prevent="save($event)"
      >Save</v-btn
    >
    <slot name="buttons"></slot>
  </div>
</template>

<script setup lang="ts">
import { mapState } from '../store/map-store';

defineProps<{
  step: number;
  hideSave?: boolean;
  actionError: string;
  approving?: boolean;
  saveProcessing?: boolean;
  ect?: {
    rejectionReason?: string;
    status?: string;
  } | null;
}>();

const emit = defineEmits(['save', 'back']);
const { selectedInstitution } = mapState();

function save(event: Event) {
  emit('save', event);
}
</script>

<style>
.helper-text {
  color: rgba(0, 0, 0, 0.6);
}
</style>
