<template>
  <div class="d-flex align-center" v-if="selectedInstitution.config.allow_forcing_microsoft_sso">
    <div>
      <mosaic-checkbox
        name="demo-account-check"
        class="mr-2 ml-0"
        hide-details
        density="compact"
        label="Force signing in with Microsoft"
        no-icon
        :model-value="modelValue"
        :disabled="disabled"
        @update:model-value="emit('update:model-value', $event)"
      />
    </div>
    <mosaic-help>
      <div v-if="!disabled">
        {{ infoMessage }}
      </div>
      <div v-else>
        <!-- isBulk not currently applicable for this use case. This is only used when updating individual Instructor state -->
        Cannot disable this as this Instructor is in multiple Institutions. Please contact support if this is a problem.
      </div>
    </mosaic-help>
  </div>
</template>

<script setup lang="ts">
import { useInstitutionStore } from '@/stores/institution';
import { useStudentStore } from '@/stores/student';
import { computed } from 'vue';

const props = defineProps<{
  modelValue: boolean;
  disabled?: boolean;
  isBulk?: boolean;
}>();

const emit = defineEmits<{
  'update:model-value': [v: boolean];
}>();

const infoMessage = computed(() => {
  return `If enabled, ${
    props.isBulk ? `these  ${traineeNounPluralised.value}` : `this ${traineeNoun.value}`
  } will only be able to sign in with Microsoft and not using a password`;
});

const { selectedInstitution } = useInstitutionStore();
const { traineeNoun, traineeNounPluralised } = useStudentStore();
</script>
