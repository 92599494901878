<template>
  <mosaic-tooltip-chip color="accent">
    <template #text> <v-icon class="pr-2">mdi-account-star</v-icon> Demo Account </template>
    <template #tooltip>
      <div>Demo accounts are intended for testing or demonstrating functionality</div>
      <div>and will not trigger any automated emails from Mosaic.</div>
    </template>
  </mosaic-tooltip-chip>
</template>

<script>
export default {
  name: 'DemoAccountBadge',
};
</script>
