<template>
  <div>
    <review-template-header>
      <template #configure>
        <v-card>
          <v-card-text>
            <div class="pa-2">
              <div class="text-h6">Configuring "{{ reviewTemplate.name }}" General section</div>
              <div>
                Here you can set what comments you'd like on the general performance of the {{ traineeNoun() }}. This
                can include comments from different instructors, as well as more generic comments, e.g. strengths, and
                whether you'd like a comment from the {{ traineeNoun() }} themselves. You can set whether the
                {{ traineeNoun() }} should be able to set targets related to the general {{ reviewNoun }}. You can
                create a set of statements that can be used for things like checking whether the {{ traineeNoun() }} has
                received particular entitlements.
              </div>
              <div class="text-h6 pt-4">Notes</div>
              <mosaic-quill
                v-model:contents="reviewTemplate.overall_note"
                :read-only="false"
                @change="noteChanged"
              ></mosaic-quill>
            </div>
          </v-card-text>
        </v-card>
        <v-card class="mt-4">
          <v-card-text>
            <div class="pa-2">
              <div class="text-h6">Targets</div>
              <v-switch
                class="ml-2"
                v-model="reviewTemplate.can_add_targets_overall"
                density="compact"
                color="primary"
                hide-details
                :label="'Targets can be set as part of this ' + reviewNoun + '?'"
                @update:model-value="updateCanAddTargets"
              />
              <div v-if="reviewTemplate.can_add_targets_overall" class="d-flex align-center">
                <review-role-select
                  :roles="targetAddPermissions"
                  label="Who can set Targets"
                  @selected-roles-updated="rolesAddTargetsChanged($event)"
                />
              </div>
            </div>
          </v-card-text>
        </v-card>
        <v-card class="mt-4">
          <v-card-text>
            <div class="pa-2">
              <div class="text-h6">Assessment of Progress</div>
              <review-template-read-only review-template-in-use tooltip="left">
                <template #default="readOnlySlotProps">
                  <mosaic-disabled-tooltip
                    v-slot="slotProps"
                    :disabled="judgementSets.length === 0 && !reviewTemplateInUse"
                    :tooltip="`No Judgement Sets have been configured. ${
                      userStaffHasPermission('judgementSets.edit') ? 'These can be configured under settings.' : ''
                    }`"
                  >
                    <v-switch
                      class="ml-2"
                      :model-value="!!reviewTemplate.review_overall_institution_judgement_set_id"
                      density="compact"
                      hide-details
                      color="primary"
                      label="Overall assessment of progress recorded?"
                      :disabled="readOnlySlotProps.disabled || slotProps.disabled"
                      @update:model-value="useOverallJudgementChanged"
                    />
                  </mosaic-disabled-tooltip>
                </template>
              </review-template-read-only>
              <div v-if="judgementSets.length === 0"><no-judgement-sets-alert class="ma-2" /></div>
              <div v-else-if="!!reviewTemplate.review_overall_institution_judgement_set_id">
                <div class="d-flex align-center">
                  <div>
                    <review-template-read-only review-template-in-use tooltip="left">
                      <template #default="{ disabled }">
                        <mosaic-judgement-set-select
                          v-model="reviewTemplate.review_overall_institution_judgement_set_id"
                          class="mr-4"
                          :items="judgementSets"
                          :disabled="disabled"
                          @update:model-value="overallJudgementSetChanged"
                        />
                      </template>
                    </review-template-read-only>
                  </div>
                </div>
                <review-role-select
                  :roles="overallJudgementPermissions"
                  label="Who can make assessments of progress"
                  @selected-roles-updated="rolesOverallJudgementEditChanged($event)"
                />
                <div style="width: 400px">
                  <mosaic-disabled-tooltip
                    v-slot="{ disabled }"
                    :disabled="!reviewTemplate.can_trainee_view_review"
                    :tooltip="`${traineeNounCapitalised()} is not marked as a viewer for this template`"
                  >
                    <v-switch
                      class="ml-2"
                      :label="traineeNounCapitalisedAndPluralised + ' can see assessment of progress?'"
                      :disabled="disabled"
                      color="primary"
                      density="compact"
                      hide-details
                      :model-value="
                        reviewTemplate.can_trainee_view_review && reviewTemplate.can_trainees_view_overall_judgement
                      "
                      @update:model-value="canTraineesViewOverallJudgementChanged"
                    />
                  </mosaic-disabled-tooltip>
                </div>
                <div class="mt-2">
                  <div class="font-weight-bold mb-1">Assessment Guidance</div>
                  <div>
                    <mosaic-quill
                      :contents="reviewTemplate.overall_assessment_guidance"
                      :read-only="false"
                      @change="updateOverallAssessmentGuidance"
                    />
                  </div>
                </div>
              </div>
            </div>
          </v-card-text>
        </v-card>
        <v-card class="mt-4">
          <v-card-text>
            <div class="pa-2">
              <div class="text-h6">Absences</div>
              <v-switch
                class="ml-2"
                v-model="reviewTemplate.record_absences"
                density="compact"
                color="primary"
                hide-details
                label="A count of absences is recorded?"
                @update:model-value="updateRecordAbsences"
              />
              <div v-if="reviewTemplate.record_absences">
                <review-role-select
                  :roles="recordAbsencesPermissions"
                  label="Who can record absences"
                  @selected-roles-updated="rolesRecordAbsencesChanged($event)"
                />
              </div>
            </div>
          </v-card-text>
        </v-card>
        <v-card class="mt-4">
          <v-card-text>
            <div class="pa-2">
              <div class="text-h6">Statements</div>

              <review-template-read-only tooltip="left" offset="8" review-template-in-use>
                <template #default="readOnlySlotProps">
                  <mosaic-disabled-tooltip
                    v-slot="slotProps"
                    :disabled="judgementSets.length === 0 && !reviewTemplateInUse"
                    :tooltip="`No Judgement Sets have been configured. ${
                      userStaffHasPermission('judgementSets.edit') ? 'These can be configured under settings.' : ''
                    }`"
                  >
                    <v-switch
                      :model-value="!!reviewTemplate.review_overall_statement_institution_judgement_set_id"
                      class="ml-2"
                      density="compact"
                      color="primary"
                      hide-details
                      :disabled="readOnlySlotProps.disabled || slotProps.disabled"
                      label="Collect responses to a series of statements determined by you"
                      @update:model-value="useStatementsChanged"
                    />
                  </mosaic-disabled-tooltip>
                </template>
              </review-template-read-only>

              <div v-if="judgementSets.length === 0"><no-judgement-sets-alert class="ma-2" /></div>
              <template v-else-if="!!reviewTemplate.review_overall_statement_institution_judgement_set_id">
                <div>
                  <div class="d-flex align-center">
                    <div>
                      <review-template-read-only review-template-in-use tooltip="left">
                        <template #default="{ disabled }">
                          <mosaic-judgement-set-select
                            v-model="reviewTemplate.review_overall_statement_institution_judgement_set_id"
                            class="mr-4 pt-2"
                            :items="judgementSets"
                            :disabled="disabled"
                            @update:model-value="overallStatementJudgementSetChanged"
                          />
                        </template>
                      </review-template-read-only>
                    </div>
                  </div>
                </div>

                <div>
                  <div v-for="g in overallStatementGroups" :key="g.id" class="mb-4">
                    <div class="d-flex align-center">
                      <review-template-read-only review-template-in-use>
                        <template #default="{ disabled }">
                          <v-text-field
                            v-model="g.name"
                            prepend-icon="mdi-format-list-bulleted-square"
                            hide-details
                            density="compact"
                            :disabled="disabled"
                            @change="updateStatementGroupName(g)"
                          ></v-text-field>
                        </template>
                      </review-template-read-only>

                      <div class="pl-2">
                        <ndt-icon-button
                          icon="delete"
                          :disabled="reviewTemplateInUse"
                          :tooltip="
                            reviewTemplateInUse ? 'Cannot delete as statements are in use' : 'Delete Statement Group'
                          "
                          @click.prevent="removeStatementGroup(g)"
                        />
                      </div>
                    </div>

                    <div class="pl-8">
                      <div v-for="s in g.overallStatements" :key="s.id">
                        <div class="d-flex align-center">
                          <review-template-read-only review-template-in-use>
                            <template #default="{ disabled }">
                              <v-text-field
                                v-model="s.statement"
                                prepend-icon="mdi-square-small"
                                hide-details
                                density="compact"
                                :disabled="disabled"
                                @update:model-value="updateStatement(s, g.id)"
                              ></v-text-field>
                            </template>
                          </review-template-read-only>

                          <div class="pl-2">
                            <ndt-icon-button
                              icon="delete"
                              :disabled="reviewTemplateInUse"
                              :tooltip="
                                reviewTemplateInUse ? 'Cannot delete as statements are in use' : 'Delete Statement'
                              "
                              @click.prevent="removeStatement(s, g.id)"
                            />
                          </div>
                        </div>

                        <div class="pl-10">
                          <review-role-select
                            :roles="s.editPermissions"
                            label="Who can answer"
                            label-width="120px"
                            @selected-roles-updated="rolesStatementEditChanged(s, g.id, $event)"
                          />
                          <review-role-select
                            :roles="s.viewPermissions"
                            label="Who can view"
                            label-width="120px"
                            @selected-roles-updated="rolesStatementViewChanged(s, g.id, $event)"
                          />
                        </div>
                      </div>

                      <div v-if="!reviewTemplateInUse" class="d-flex align-center mt-2">
                        <v-text-field
                          v-model="newStatementNames[g.id]"
                          prepend-icon="mdi-square-small"
                          hide-details
                          density="compact"
                          label="New Statement"
                        />

                        <v-btn class="ml-4" :disabled="!newStatementNames[g.id]" @click.prevent="addStatement(g)"
                          >Add</v-btn
                        >
                      </div>
                    </div>
                  </div>

                  <div v-if="!reviewTemplateInUse" class="d-flex align-center">
                    <v-text-field
                      v-model="newStatementGroupName"
                      prepend-icon="mdi-format-list-bulleted-square"
                      hide-details
                      density="compact"
                      label="New Statement Group Name"
                    />

                    <v-btn class="ml-4" :disabled="!newStatementGroupName.length" @click.prevent="addStatementGroup()"
                      >Add</v-btn
                    >
                  </div>
                </div>
              </template>
            </div>
          </v-card-text>
        </v-card>
        <v-card v-if="!reviewTemplateInUse || sortedComments.length > 0" class="mt-4">
          <v-card-text>
            <div class="pa-2">
              <div class="text-h6">Comments</div>
              <review-template-comment-box
                v-for="c of sortedComments"
                :key="c.id"
                class="mb-2"
                :review-template-in-use="reviewTemplateInUse"
                :comment="c"
                :can-trainee-view-review="reviewTemplate.can_trainee_view_review"
                :roles="reviewTemplateRoles"
                @update:label="updateLabel(c, $event)"
                @delete="deleteComment(c)"
                @update:placeholder="updatePlaceholder(c, $event)"
                @update:optionality="optionalityChanged(c, $event)"
                @selected-roles-updated="rolesCommentEditChanged(c, $event)"
              />
              <v-btn v-if="!reviewTemplateInUse" class="mt-4" @click.prevent="addComment()">
                <div class="d-flex align-center">
                  <v-icon>mdi-plus</v-icon>
                  <span>Comment</span>
                </div>
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </template>
      <template #preview>
        <review-overall-card
          :student-review="reviewTemplateAsStudentReview"
          :staff-viewer="previewUser.staff"
          :student-viewer="previewUser.student"
          :student="previewStudent"
          :review-path="reviewPath"
        />
      </template>
    </review-template-header>
  </div>
</template>

<script>
import ReviewOverallCard from '@/components/ReviewOverallCard.vue';
import { mapState, mapGetters } from 'vuex';
import NdtIconButton from '@/components/NdtIconButton.vue';
import MosaicQuill from '@/components/quill/MosaicQuill.vue';
import ReviewTemplateHeader from '@/components/ReviewTemplateHeader.vue';
import ReviewTemplateReadOnly from '@/components/ReviewTemplateReadOnly.vue';
import ReviewTemplateCommentBox from '@/components/ReviewTemplateCommentBox.vue';
import NoJudgementSetsAlert from '@/components/templates/NoJudgementSetsAlert.vue';
import ReviewRoleSelect from './ReviewRoleSelect.vue';

export default {
  name: 'TutorAdminReviewTemplateOverallPage',
  data: () => ({ newStatementGroupName: '', newStatementNames: {} }),
  async created() {
    await this.$store.dispatch('loadReviewTemplate', { id: this.$route.params.templateId });
  },
  computed: {
    ...mapState(['user', 'reviewTemplate', 'selectedInstitution', 'judgementSets']),
    ...mapGetters([
      'reviewTemplateRoles',
      'reviewTemplateAsStudentReview',
      'previewUser',
      'previewStudent',
      'reviewNounCapitalised',
      'reviewTemplateInUse',
      'createPermissionsArray',
    ]),
    reviewPath() {
      return {
        text: this.reviewTemplate?.name,
        to: {
          name: 'TutorAdminReviewTemplatePage',
        },
      };
    },
    breadcrumbs() {
      return [
        {
          text: `${this.reviewNounCapitalised} Templates`,
          to: {
            name: 'TutorAdminReviewTemplatesListPage',
          },
        },
        {
          text: this.reviewTemplate?.name,
          to: {
            name: 'TutorAdminReviewTemplatePage',
          },
        },
        {
          text: 'General',
        },
      ];
    },
    sortedComments() {
      return this.reviewTemplate.review_template_overall_comments
        .map(x => {
          const c = {
            ...x,
          };
          for (const r of this.reviewTemplateRoles) {
            c[`${r.id}_edit`] = c.review_template_permissions.find(x => x.role_id === r.id)?.edit || false;
          }
          return c;
        })
        .sort((a, b) => (a.order > b.order ? 1 : -1));
    },
    targetAddPermissions() {
      return [
        {
          roleId: 'student',
          selected: this.reviewTemplate.can_trainee_add_targets_overall,
          canView: this.reviewTemplate.can_trainee_view_review,
        },
        ...this.createPermissionsArray('AddTargetsOverall'),
      ];
    },
    recordAbsencesPermissions() {
      return [
        {
          roleId: 'student',
          selected: this.reviewTemplate.can_trainee_record_absences,
          canView: this.reviewTemplate.can_trainee_view_review,
        },
        ...this.createPermissionsArray('RecordAbsences'),
      ];
    },
    overallJudgementPermissions() {
      return this.createPermissionsArray('OverallJudgement');
    },
    overallStatementGroups() {
      return this.reviewTemplate.review_template_overall_statement_groups
        .map(g => {
          return {
            ...g,
            newStatement: '',
            overallStatements: g.review_template_overall_statements
              .map(s => {
                const statement = { ...s };
                statement.editPermissions = [
                  {
                    roleId: 'student',
                    selected: s.can_trainee_edit,
                    canView: this.reviewTemplate.can_trainee_view_review,
                  },
                  ...this.reviewTemplateRoles.map(r => ({
                    roleId: r.id,
                    selected: s.review_template_permissions.find(x => x.role_id === r.id)?.edit || false,
                    canView: r.canView,
                  })),
                ];
                statement.viewPermissions = [
                  {
                    roleId: 'student',
                    selected: s.can_trainee_view,
                    canView: this.reviewTemplate.can_trainee_view_review,
                  },
                  ...this.reviewTemplateRoles.map(r => ({
                    roleId: r.id,
                    selected: s.review_template_permissions.find(x => x.role_id === r.id)?.view || false,
                    canView: r.canView,
                  })),
                ];
                // TODO: delete
                for (const r of this.reviewTemplateRoles) {
                  statement[`${r.id}_edit`] =
                    s.review_template_permissions.find(x => x.role_id === r.id)?.edit || false;
                  statement[`${r.id}_view`] =
                    s.review_template_permissions.find(x => x.role_id === r.id)?.view || false;
                }
                return statement;
              })
              .sort((a, b) => (a.order > b.order ? 1 : -1)),
          };
        })
        .sort((a, b) => (a.order > b.order ? 1 : -1));
    },
  },
  methods: {
    noteChanged(x) {
      this.$store.dispatch('updateReviewTemplate', {
        overall_note: x,
      });
    },
    updateOverallAssessmentGuidance(x) {
      this.$store.dispatch('updateReviewTemplate', {
        overall_assessment_guidance: x,
      });
    },
    updateLabel(c, x) {
      this.$store.dispatch('updateReviewTemplateOverallComment', { id: c.id, updates: { label: x } });
    },
    updatePlaceholder(c, x) {
      this.$store.dispatch('updateReviewTemplateOverallComment', { id: c.id, updates: { placeholder: x } });
    },
    rolesCommentEditChanged(c, roles) {
      const review_template_permissions = roles
        .filter(r => r.roleId !== 'student')
        .map(r => ({ role_id: r.roleId, edit: r.selected }));
      this.$store.dispatch('updateReviewTemplateOverallComment', {
        id: c.id,
        updates: { review_template_permissions, can_trainee_edit: roles.find(r => r.roleId === 'student').selected },
      });
    },
    traineeEditChanged(c, x) {
      this.$store.dispatch('updateReviewTemplateOverallComment', { id: c.id, updates: { can_trainee_edit: x } });
    },
    optionalityChanged(c, x) {
      this.$store.dispatch('updateReviewTemplateOverallComment', { id: c.id, updates: { optional: x } });
    },
    addComment() {
      this.$store.dispatch('addReviewTemplateOverallComment');
    },
    deleteComment(c) {
      this.$store.dispatch('deleteReviewTemplateOverallComment', c.id);
    },
    updateCanAddTargets(x) {
      this.$store.dispatch('updateReviewTemplate', { can_add_targets_overall: x });
    },
    rolesAddTargetsChanged(roles) {
      this.$store.dispatch('updateReviewTemplatePermissions', {
        roles: roles
          .filter(r => r.roleId !== 'student')
          .map(role => ({
            roleId: role.roleId,
            edit: role.selected,
            permission: 'AddTargetsOverall',
          })),
        traineePermissionUpdates: { can_trainee_add_targets_overall: roles.find(r => r.roleId === 'student').selected },
      });
    },
    updateRecordAbsences(x) {
      this.$store.dispatch('updateReviewTemplate', { record_absences: x });
    },
    rolesRecordAbsencesChanged(roles) {
      this.$store.dispatch('updateReviewTemplatePermissions', {
        roles: roles
          .filter(r => r.roleId !== 'student')
          .map(role => ({
            roleId: role.roleId,
            edit: role.selected,
            permission: 'RecordAbsences',
          })),
        traineePermissionUpdates: { can_trainee_record_absences: roles.find(r => r.roleId === 'student').selected },
      });
    },
    traineeRecordAbsencesChanged(x) {
      this.$store.dispatch('updateReviewTemplate', { can_trainee_record_absences: x });
    },
    useOverallJudgementChanged(x) {
      if (!x || this.judgementSets.length === 0) {
        this.$store.dispatch('resetReviewTemplateOverallJudgement');
      } else {
        this.$store.dispatch('updateReviewTemplate', {
          review_overall_institution_judgement_set_id: this.judgementSets[0].id,
        });
      }
    },
    overallJudgementSetChanged(x) {
      this.$store.dispatch('updateReviewTemplate', { review_overall_institution_judgement_set_id: x });
    },
    rolesOverallJudgementEditChanged(roles) {
      this.$store.dispatch('updateReviewTemplatePermissions', {
        roles: roles.map(role => ({
          roleId: role.roleId,
          edit: role.selected,
          permission: 'OverallJudgement',
        })),
      });
    },
    canTraineesViewOverallJudgementChanged(x) {
      this.$store.dispatch('updateReviewTemplate', { can_trainees_view_overall_judgement: x });
    },
    standardJudgementSetChanged(x) {
      this.$store.dispatch('updateReviewTemplate', { review_overall_institution_judgement_set_id: x });
    },
    useStatementsChanged(x) {
      if (!x || this.judgementSets.length === 0) {
        this.$store.dispatch('resetReviewTemplateOverallStatements');
      } else {
        const judgementSet =
          this.judgementSets.find(x => x.name === 'Yes/No') ||
          this.judgementSets.find(x => x.institutionJudgementDescriptors.length === 2) ||
          this.judgementSets[0];
        this.$store.dispatch('updateReviewTemplate', {
          review_overall_statement_institution_judgement_set_id: judgementSet.id,
        });
      }
    },
    overallStatementJudgementSetChanged(x) {
      this.$store.dispatch('updateReviewTemplate', { review_overall_statement_institution_judgement_set_id: x });
    },
    addStatementGroup() {
      this.$store.dispatch('addReviewTemplateOverallStatementGroup', {
        name: this.newStatementGroupName,
      });
      this.newStatementGroupName = '';
    },
    removeStatementGroup(x) {
      this.$store.dispatch('removeReviewTemplateOverallStatementGroup', {
        id: x.id,
      });
    },
    updateStatementGroupName(x) {
      this.$store.dispatch('updateReviewTemplateOverallStatementGroup', {
        id: x.id,
        name: x.name,
      });
    },
    addStatement(group) {
      const statement = this.newStatementNames[group.id];
      this.$store.dispatch('addReviewTemplateOverallStatement', {
        groupId: group.id,
        statement,
      });
      this.newStatementNames[group.id] = '';
    },
    removeStatement(x, groupId) {
      this.$store.dispatch('removeReviewTemplateOverallStatement', {
        id: x.id,
        groupId,
      });
    },
    updateStatement(x, groupId) {
      this.$store.dispatch('updateReviewTemplateOverallStatement', {
        id: x.id,
        updates: { statement: x.statement },
        groupId,
      });
    },
    rolesStatementEditChanged(s, groupId, roles) {
      const traineePermissionUpdates = { can_trainee_edit: roles.find(r => r.roleId === 'student').selected };
      if (traineePermissionUpdates.can_trainee_edit) {
        traineePermissionUpdates.can_trainee_view = true;
      }

      this.$store.dispatch('updateReviewTemplateOverallStatementPermissions', {
        groupId,
        id: s.id,
        roles: roles
          .filter(r => r.roleId !== 'student')
          .map(role => {
            const updates = { edit: role.selected };
            if (updates.edit) {
              updates.view = true;
            }
            return {
              roleId: role.roleId,
              updates,
            };
          }),
        traineePermissionUpdates,
      });
    },
    rolesStatementViewChanged(s, groupId, roles) {
      const traineePermissionUpdates = { can_trainee_view: roles.find(r => r.roleId === 'student').selected };
      if (!traineePermissionUpdates.can_trainee_view) {
        traineePermissionUpdates.can_trainee_edit = false;
      }

      this.$store.dispatch('updateReviewTemplateOverallStatementPermissions', {
        groupId,
        id: s.id,
        roles: roles
          .filter(r => r.roleId !== 'student')
          .map(role => {
            const updates = { view: role.selected };
            if (!updates.view) {
              updates.edit = false;
            }
            return {
              roleId: role.roleId,
              updates,
            };
          }),
        traineePermissionUpdates,
      });
    },
  },
  components: {
    ReviewOverallCard,
    ReviewTemplateHeader,
    NdtIconButton,
    MosaicQuill,
    ReviewTemplateReadOnly,
    ReviewTemplateCommentBox,
    NoJudgementSetsAlert,
    ReviewRoleSelect,
  },
};
</script>
