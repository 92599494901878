<template>
  <mosaic-loading-and-error-cards :object-type="objectType" :load="load">
    <mosaic-card class="mb-4">
      <div class="d-flex row-wrap">
        <mosaic-card-title class="flex-grow-1 mb-1">{{ title }}</mosaic-card-title>
        <div class="d-flex">
          <slot name="actions"></slot>
        </div>
      </div>

      <slot name="card-content"></slot>
    </mosaic-card>

    <mosaic-card>
      <div v-if="$slots['list-filters']" class="d-flex flex-wrap pb-2" style="column-gap: 16px; row-gap: 8px">
        <slot name="list-filters" />
      </div>
      <mosaic-list v-if="paginatedList.length" :items="paginatedList">
        <template #item="{ item }">
          <slot name="list-item" v-bind="{ item }"></slot>
        </template>
      </mosaic-list>
      <div v-else class="pa-4">There are no {{ objectTypePluralised }} for these filters</div>
    </mosaic-card>
    <mosaic-pagination v-model="currentPage" v-model:page-size="pageSize" :total="paginationTotal" />
  </mosaic-loading-and-error-cards>
</template>

<script setup lang="ts" generic="T">
import { paginateList } from '@/components/library/pagination/pagination';
import { toRef, computed } from 'vue';
import { pluralise } from '@/utils/text';

const props = defineProps<{
  objectType: string;
  title: string;
  load: () => Promise<void>;
  listItems: T[];
}>();

// Can be written as toRef(() => props.items) in Vue 3.3+
const itemsRef = toRef(props, 'listItems');
const objectTypePluralised = computed(() => pluralise(props.objectType));
const { paginatedList, currentPage, pageSize, paginationTotal } = paginateList(itemsRef);
</script>
