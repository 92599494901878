<template>
  <mosaic-multi-section-card
    v-if="studentWeek"
    :title="studentWeek.cohortCourseWeek.name"
    :sections="sectionsWithCompletion"
    :section-id="sectionId"
    :can-save="canSave"
    :save="save"
    :used-with-configure-preview="usedWithConfigurePreview"
    :object-type="cohortCourseWeekNounCapitalised"
    :hide-buttons="readonly"
    @update:section-id="emit('update:sectionId', $event)"
    @click-section="emit('clickSection', $event)"
  >
    <template #title-chip>
      <student-cohort-course-week-status-chip
        :status="studentWeek.status"
        :student-viewer="!!studentViewer"
        :week-noun="cohortCourseWeekNounCapitalised"
      />
    </template>
    <template #title-actions v-if="studentViewer || staffViewer">
      <div v-if="studentViewer">
        <div v-if="completed">
          <mosaic-btn
            :disabled="markAsCompleteProcessing"
            :loading="markAsCompleteProcessing"
            @click="markAsComplete(false)"
          >
            Undo Mark as Complete
          </mosaic-btn>
          <mosaic-error-snackbar
            v-model="markAsCompleteError"
            :action="`undo marking the ${cohortCourseWeekNounCapitalised} as complete`"
          />
        </div>
        <div v-else>
          <mosaic-btn :loading="markAsCompleteProcessing" :disabled="!canMarkAsComplete" @click="markAsComplete(true)">
            Mark as Complete
          </mosaic-btn>
          <mosaic-error-snackbar
            v-model="markAsCompleteError"
            :action="`mark the ${cohortCourseWeekNounCapitalised} as complete`"
          />
        </div>
      </div>
      <div class="d-flex">
        <v-btn v-if="previous" @click.prevent="$router.push(previous)">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-btn v-if="next" class="ml-2" @click.prevent="$router.push(next)">
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </div>
    </template>
    <template #subtitle>
      <div class="text-subtitle" :class="{ 'mt-1': smallScreen }">
        {{ formatDate(studentWeek.cohortCourseWeek.startDate) }} -
        {{ formatDate(studentWeek.cohortCourseWeek.endDate) }}
      </div>
    </template>
    <mosaic-content-layout
      v-if="section?.contentLayout"
      v-model:comments="comments"
      v-model:tasks="tasks"
      class="mb-4"
      :readonly="readonly"
      :content-layout="section.contentLayout"
      get-resource-url-prefix="/cohort-course/week-sections/resources"
      @click-curriculum-statement="$event => emit('clickCurriculumStatement', $event)"
    />
    <template v-if="!sectionContentLayoutIsCompletable && studentViewer" #beside-buttons>
      <mosaic-checkbox
        v-model="sectionManuallyCompleted"
        name="completed"
        label="I confirm I have read all of the information on this page"
        hide-details
        density="compact"
        no-icon
      />
    </template>
  </mosaic-multi-section-card>
  <mark-as-complete-prompt-dialog
    v-model:active="markAsCompleteDialogActive"
    :title="`Is this ${cohortCourseWeekNounCapitalised} Complete?`"
    :object-type="`${cohortCourseWeekNounCapitalised}`"
    :mark-as-complete-api-call="() => markAsCompleteApiCall(true)"
  >
    <p>You have filled in all the required elements of this Couse Activity, would you like to mark it as complete?</p>
    <div>This will indicate to your course leads that this Course Activity is ready to be assessed.</div>
  </mark-as-complete-prompt-dialog>
</template>

<script setup lang="ts">
import { mapGetters, type CurriculumStatement } from '@/store/map-store';
import { ref, computed, watch, nextTick } from 'vue';
import MosaicContentLayout from '@/components/mosaic-content-layout/MosaicContentLayout.vue';
import type { StudentWeek } from '@/stores/cohort-course';
import { useApi } from '@/composables/api';
import { createContentLayoutCompletionComments, createContentLayoutCompletionTasks } from '@/utils/content-layout';
import type { RouteLocationNamedRaw } from 'vue-router';
import { withProcessingAndError } from '@/composables/processing-and-errors';
import StudentCohortCourseWeekStatusChip from './StudentCohortCourseWeekStatusChip.vue';
import MarkAsCompletePromptDialog from '@/components/library/has-status/MarkAsCompletePromptDialog.vue';
import { until } from '@vueuse/core';
const props = withDefaults(
  defineProps<{
    studentWeek: StudentWeek;
    studentViewer?: { id: number };
    previous?: RouteLocationNamedRaw;
    next?: RouteLocationNamedRaw;
    staffViewer?: { id: number };
    usedWithConfigurePreview?: boolean;
    sectionId: number;
    readonly?: boolean;
  }>(),
  {
    usedWithConfigurePreview: false,
    readonly: false,
  }
);

const emit = defineEmits<{
  (e: 'clickCurriculumStatement', cs: CurriculumStatement): void;
  (e: 'update:dirty', dirty: boolean): void;
  (e: 'update:studentWeek', studentWeek: StudentWeek): void;
  (e: 'update:sectionId', sectionId: number): void;
  (e: 'clickSection', sectionId: number): void;
}>();

const confirm = ref(!!props.studentWeek.completedAt);
watch(
  () => props.studentWeek,
  () => {
    (confirm.value = !!props.studentWeek.completedAt), { immediate: true };
  }
);

const section = computed(() =>
  props.studentWeek.cohortCourseWeek.cohortCourseWeekSections.find(x => x.id === props.sectionId)
);
const studentWeekSection = computed(() =>
  props.studentWeek.studentCohortCourseWeekSections.find(x => x.cohortCourseWeekSectionId === props.sectionId)
);

const { comments, commentsDirty } = createContentLayoutCompletionComments(
  computed(() => studentWeekSection.value?.studentCohortCourseWeekComments || []),
  c => c.cohortCourseWeekSectionCommentId
);

const { tasks, tasksDirty } = createContentLayoutCompletionTasks(
  computed(() => studentWeekSection.value?.studentCohortCourseWeekTasks || []),
  t => t.cohortCourseWeekSectionTaskId
);

const sectionContentLayoutIsCompletable = computed(() => comments.value.length > 0 || tasks.value.length > 0);
const sectionManuallyCompleted = ref(studentWeekSection.value?.completed || false);
watch(
  () => studentWeekSection.value,
  sws => {
    if (sws) {
      sectionManuallyCompleted.value = sws.completed;
    }
  }
);

const sectionsWithCompletion = computed(() => {
  return props.studentWeek.cohortCourseWeek.cohortCourseWeekSections.map(ws => ({
    ...ws,
    name: ws.title,
    completed:
      props.studentWeek.studentCohortCourseWeekSections.find(sws => sws.cohortCourseWeekSectionId === ws.id)
        ?.completed || false,
    readonly: false,
    disabled: false,
  }));
});

watch(
  () => sectionsWithCompletion.value,
  () => {
    if (
      sectionsWithCompletion.value.filter(x => x.id === props.sectionId).length === 0 &&
      sectionsWithCompletion.value.length > 0
    )
      emit('update:sectionId', sectionsWithCompletion.value[0].id);
  }
);

const dirty = computed(
  () =>
    confirm.value !== !!props.studentWeek.completedAt ||
    commentsDirty.value ||
    tasksDirty.value ||
    sectionManuallyCompleted.value !== studentWeekSection.value?.completed
);

const api = useApi();

const canSave = computed(() => {
  return dirty.value;
});
const { cohortCourseWeekNounCapitalised } = mapGetters();
async function save() {
  const body = sectionContentLayoutIsCompletable.value
    ? {
        comments: comments.value.map(c => ({
          comment: c.comment,
          cohortCourseWeekSectionCommentId: c.templateId,
        })),
        tasks: tasks.value.map(t => ({
          completed: t.completed,
          cohortCourseWeekSectionTaskId: t.templateId,
        })),
      }
    : { completed: sectionManuallyCompleted.value };
  const r = await api.put<unknown, StudentWeek>(
    `/student-cohort-course-week-sections/${studentWeekSection.value?.id}${
      studentWeekSection.value?.id === -1 ? `?studentWeekId=${props.studentWeek.id}` : ''
    }`,
    body
  );
  emit('update:studentWeek', r.data);
  await nextTick();
  if (completable.value && props.studentViewer && !(props.studentWeek.status === 'completed')) {
    markAsCompleteDialogActive.value = true;
  }
  await until(markAsCompleteDialogActive).toBe(false);
}
watch(
  () => dirty.value,
  x => emit('update:dirty', x)
);

// Mark as complete
async function markAsCompleteApiCall(completed: boolean) {
  const r = await api.post<unknown, StudentWeek>(
    `/student-cohort-course-weeks/${props.studentWeek.id}/mark-as-completed`,
    {
      completed,
    }
  );
  emit('update:studentWeek', r.data);
}
const {
  action: markAsComplete,
  processing: markAsCompleteProcessing,
  error: markAsCompleteError,
} = withProcessingAndError(markAsCompleteApiCall);

const completable = computed(() => sectionsWithCompletion.value.every(x => x.completed));
const canMarkAsComplete = computed(() => !markAsCompleteProcessing.value && completable.value);
const markAsCompleteDialogActive = ref(false);

const completed = computed(() => !!(props.studentWeek.status === 'completed'));
</script>
