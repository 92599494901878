<template>
  <div class="mt-5">
    <div v-if="loadError" class="pt-2">
      <span v-if="isString(loadError)">{{ loadError }}</span>
      <span v-else>
        Sorry, cannot load {{ isMe ? 'your' : "this Instructor's" }} Training Requirements right now. If this problem
        persists, please contact support.
      </span>
    </div>
    <div v-else-if="!frameworksWithThemes.length">{{ renderNoRequirementsMessage() }}</div>
    <template v-for="framework in frameworksWithThemes" v-else :key="framework.id">
      <div>
        <mosaic-title level="h6">{{ framework.name }} </mosaic-title>
        <mosaic-list
          :items="framework.staffTrainingCompetencyThemes.filter(t => t.staffTrainingCompetencies.length > 0)"
        >
          <template #item="{ item: theme }">
            <mosaic-list-item
              :key="theme.id"
              :icon="icons.instructorTrainingCompetencyTheme"
              :title="theme.name"
              :subtitle="themeSubtitle(theme)"
              :to="clickThemeTo(theme)"
              :chip="chip(theme)"
            >
            </mosaic-list-item>
          </template>
        </mosaic-list>
      </div>
      <v-divider class="mb-4 mt-3" />
    </template>
  </div>
</template>

<script setup lang="ts">
import type { StaffTraining, StaffTrainingCompetencyTheme } from '@/stores/staff-training';
import type { RouteLocationNamedRaw } from 'vue-router';
import { icons } from '@/utils/icons';
import { computed } from 'vue';
import { isString } from 'lodash';
import MosaicTitle from '@/components/library/headers/MosaicTitle.vue';

// Display requirements
const props = withDefaults(
  defineProps<{
    frameworks: StaffTraining['staffTrainingFrameworks'];
    loadError?: boolean | string;
    clickThemeTo: (theme: StaffTrainingCompetencyTheme) => RouteLocationNamedRaw;
    isMe?: boolean;
  }>(),
  {
    loadError: false,
    isMe: false,
  }
);

const frameworksWithThemes = computed(() => props.frameworks.filter(f => f.staffTrainingCompetencyThemes.length > 0));

function themeSubtitle(theme: StaffTrainingCompetencyTheme) {
  if (theme.status === 'met') return 'All Competencies met';
  const requirementsCount = theme.requiredModuleCount;
  const incompleteRequirementsCount = theme.incompleteRequiredModuleCount;
  const completedRequirementsCount = requirementsCount - incompleteRequirementsCount;
  if (requirementsCount === 0) return 'No Requirements';
  else if (theme.startedRequiredModuleCount === 0)
    return `${completedRequirementsCount}/${requirementsCount} Requirements completed`;
  else
    return `${theme.startedRequiredModuleCount}/${requirementsCount} Requirements started, ${completedRequirementsCount} completed`;
}

function chip(theme: StaffTrainingCompetencyTheme) {
  if (theme.status === 'not_met') return { text: 'Not Met', color: 'accent' };
  return { text: theme.status === 'up_to_date' ? 'Up to date' : 'Met', color: 'primary' };
}

function renderNoRequirementsMessage() {
  return props.isMe
    ? "You don't have any Training Requirements."
    : 'There are no Training Requirements for this Instructor.';
}
</script>
