export function delay(ms: number) {
  return new Promise(resolve => {
    setTimeout(resolve, ms);
  });
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function waitForAtLeast<TArgs extends Array<{}>, TResult>(
  asyncAction: (...args: TArgs) => Promise<TResult>,
  timeInMs = 1000
) {
  return async (...args: TArgs) => {
    const [result] = await Promise.all([asyncAction(...args), delay(timeInMs)]);
    return result;
  };
}
