<template>
  <div>
    <div>
      You are deleting {{ pluralise(actionNoun) }} for
      {{ enumerateItems(totalCount, traineeNounCapitalised(), traineeNounCapitalisedAndPluralised) }}:
    </div>
    <ul>
      <li v-for="s in statusCounts" :key="s.status">
        {{ s.status }}:
        {{ enumerateItems(s.count, traineeNounCapitalised(), traineeNounCapitalisedAndPluralised) }}
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { enumerateItems } from '@/utils/text';
import { computed } from 'vue';

const props = defineProps<{
  actionNoun: string;
  statusItems: { title: string; value: string }[];
  studentIdsToBeRemoved: number[];
  getActionStatus: (studentId: number) => string | undefined;
}>();

const statusCounts = computed(() => {
  const statuses = props.studentIdsToBeRemoved.map(id => props.getActionStatus(id));
  return props.statusItems.map(status => ({
    status: status.title,
    count: statuses.filter(s => s === status.value).length,
  }));
});

const totalCount = computed(() => statusCounts.value.map(c => c.count).sum());
</script>
