<template>
  <v-card>
    <div class="text-h6 px-8 pt-4 pb-2">Feature Configuration for {{ selectedInstitution.name }}*</div>
    <div class="px-8 pb-2">
      <mosaic-table
        table-id="afc"
        :columns="columns"
        :rows="filteredFeatureConfigs"
        object-type-pluralised="configurations"
        no-overflow
      />
    </div>
    <div class="pb-4 pl-8">
      * Please contact
      <span class="font-weight-bold">support@penrose.education</span> if you would like to discuss your feature setup.
    </div>
  </v-card>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import MosaicTable from '@/components/monitoring/MosaicTable.vue';

export default {
  name: 'TutorAdminFeatureConfigPage',
  components: { MosaicTable },
  data() {
    // TODO: add busy and error states
    return {
      fields: ['feature', 'description', { key: 'switched_on', label: 'Switched On?', thStyle: 'min-width: 140px;' }],
    };
  },
  async created() {
    await this.$store.dispatch('loadInstitutionFeatureConfiguration');
  },
  computed: {
    ...mapGetters(['friendlyInstitutionFeatureConfiguration']),
    ...mapState(['selectedInstitution']),
    breadcrumbs() {
      return [{ text: `Features` }];
    },
    filteredFeatureConfigs() {
      return this.friendlyInstitutionFeatureConfiguration
        .filter(c => c.expose_to_customer != false)
        .map(c => ({
          ...c,
          feature: c.really_friendly_name,
          description: c.really_friendly_description,
        }));
    },
    columns() {
      return [
        { name: 'Feature', key: 'feature', text: r => r.feature },
        { name: 'Description', key: 'description', text: r => r.description, width: '60%' },
        {
          name: 'Switched on?',
          key: 'on',
          chip: r => ({
            text: r.switched_on ? 'On' : 'Off',
            color: r.switched_on ? 'green' : 'red',
            value: r.switched_on ? 'on' : 'off',
          }),
          filter: {
            type: 'select',
            items: [
              { title: 'All', value: null },
              { title: 'On', value: 'on' },
              { title: 'Off', value: 'off' },
            ],
          },
          width: '110px',
        },
      ];
    },
  },
};
</script>
