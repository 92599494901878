import { capitaliseFirstLetters } from '@/utils/text';

export type InstitutionType = 'ITT' | 'Early Careers' | 'Unknown' | 'Any';

export function institutionNoun(institutionType: InstitutionType): string {
  if (institutionType === 'ITT') return 'Training Provider';
  if (institutionType === 'Early Careers') return 'Appropriate Body';
  return 'Training Provider/Appropriate Body';
}

export function traineeNoun(institutionType: InstitutionType): string {
  if (institutionType === 'ITT') return 'trainee';
  else if (institutionType === 'Early Careers') return 'ECT';
  else return 'trainee/ECT';
}

export function traineeNounCapitalised(institutionType: InstitutionType): string {
  return capitaliseFirstLetters(traineeNoun(institutionType));
}
