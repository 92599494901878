<template>
  <ndt-dialog v-model:active="activeNdtDialog" title="Add a Resource" :on-close="close" :error-message="errorMessage">
    <mosaic-file-pond-external-storage
      ref="filepond"
      :get-upload-url-and-headers="getUploadUrlAndHeaders"
      :max-files="1"
      @file-uploaded="fileUploaded"
      @file-count-updated="fileCountUpdated"
    ></mosaic-file-pond-external-storage>
    <div v-if="fileId" class="pt-2">
      <v-text-field v-model="title" name="title" label="Title" type="text" />
      <v-textarea
        v-model="description"
        name="description"
        variant="filled"
        label="Description"
        auto-grow
        type="text"
        rows="2"
        :placeholder="descriptionPlaceholder"
      />
    </div>
    <template #buttons>
      <v-btn variant="text" ripple :disabled="!canSubmit" :loadiing="processing" @click="submitAddResource()"
        >Add</v-btn
      >
    </template>
  </ndt-dialog>
</template>

<script>
import NdtDialog from '../NdtDialog.vue';
import MosaicFilePondExternalStorage from '../file-pond/MosaicFilePondExternalStorage.vue';
import { mapState } from 'vuex';

export default {
  name: 'AddResourceDialog',
  props: {
    active: {
      type: Boolean,
      required: true,
    },
    presignUrl: {
      type: String,
      required: true,
    },
    saveResourceUrl: {
      type: String,
      required: true,
    },
    saveResourceParams: {
      type: Object,
      required: true,
    },
  },
  emits: ['update:active', 'resourceAdded'],
  data: function () {
    return {
      activeNdtDialog: false,
      fileId: null,
      title: '',
      description: '',
      fileExtension: '',
      errorMessage: '',
      processing: false,
    };
  },
  computed: {
    ...mapState(['selectedCohort']),
    descriptionPlaceholder() {
      return `Why should someone engage with this resource?`;
    },
    canSubmit: function () {
      return this.fileId && this.title && !this.processing;
    },
  },
  watch: {
    activeNdtDialog: function (x) {
      this.$emit('update:active', x);
    },
    active: function (x) {
      this.activeNdtDialog = x;
    },
  },
  methods: {
    fileUploaded(x) {
      this.title = x.title;
      const split = x.title.split('.');
      this.fileExtension = split[split.length - 1];
      this.fileId = x.fileId;
    },
    fileCountUpdated(count) {
      if (count === 0) {
        // Delete uploaded files?
        this.fileId = '';
      }
    },
    async getUploadUrlAndHeaders(cancelToken, contentType, filename) {
      const r = await this.$api.post(this.presignUrl, { contentType, filename }, cancelToken);
      return r.data;
    },
    async submitAddResource() {
      this.processing = true;
      this.errorMessage = '';
      try {
        const r = await this.$api.post(this.saveResourceUrl, {
          fileId: this.fileId,
          ...this.saveResourceParams,
        });
        this.$emit('resourceAdded', {
          resourceId: r.data.id,
          title: this.title,
          description: this.description,
        });
        this.close();
      } catch (e) {
        this.errorMessage = 'Sorry, cannot add resources at the moment';
      }
      this.processing = false;
    },
    close() {
      this.$refs.filepond.onClose();
      this.activeNdtDialog = false;
      this.fileId = null;
      this.title = '';
      this.description = '';
    },
  },
  components: { NdtDialog, MosaicFilePondExternalStorage },
};
</script>
