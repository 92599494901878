<template>
  <div>
    <mosaic-tab-card-page
      v-model:trigger-background-load="triggerBackgroundLoad"
      object-type="Training Plan Week"
      :edit="() => editWeek(week)"
      :title="week?.name"
      :headers="tabHeaders"
      :load="load"
      @tab-selected="tab = $event"
    >
      <template v-if="week" #subtitle
        >{{ moment(week.startDate).format('DD/MM/YY') }} - {{ moment(week.endDate).format('DD/MM/YY') }}</template
      >
      <template #chip>
        <mosaic-published-draft-chip
          mode="list"
          :published="week?.status === 'published'"
          :object-type="cohortCourseWeekNounCapitalised"
        />
      </template>
      <template #actions>
        <v-btn v-if="previous" class="ml-2" @click.prevent="router.push(previous)">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-btn v-if="next" class="ml-2" @click.prevent="router.push(next)">
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </template>

      <template #sections-tab-item>
        <mosaic-inline-creation-list
          v-model:trigger-background-load="triggerBackgroundLoad"
          :object-type="`${cohortCourseWeekNounCapitalised} Page`"
          :items="weekSections"
          :icon="icons.trainingPlanWeekSection"
          :can-add-item="canAddSection"
          :add-item="() => addWeekSection()"
          :edit-item-clicked="editSection"
          edit-action="Edit"
          :hide-add="published"
          :delete-url-stem="deleteUrlStem"
          :move-item="moveSectionListItem"
          :move-disabled-message="moveDisabledMessage"
        >
          <template #information="{ item }">
            <v-chip v-if="item.subjectId">{{ item.subjectName }} Only</v-chip>
          </template>
          <template #add-item-fields>
            <mosaic-text-field
              v-model="newSectionName"
              name="add-section-name"
              label="Name"
              prepend-icon="mdi-pencil"
              class="flex-grow-1"
            />
            <mosaic-select
              class="pl-4 pr-1"
              v-model="newSectionSubject"
              name="add-section-subject"
              label="Subject"
              :items="subjects"
              item-title="name"
              item-value="id"
              prepend-icon="mdi-pencil-ruler"
              return-object
              ><template #item="{ item, props }">
                <v-divider v-if="item.raw.divider" />
                <v-list-item v-else v-bind="props" :title="item.raw.name" /> </template
            ></mosaic-select>
            <mosaic-help
              >Selecting a Subject will ensure the Page is only visible to {{ traineeNounPluralised }} linked to the
              same Subject</mosaic-help
            >
          </template>
        </mosaic-inline-creation-list>
      </template>

      <template #monitoring-tab-item>
        <v-card-text class="py-5 pl-0" v-if="week">
          <div>
            <div v-if="!(week.status === 'published')" class="pt-2">
              {{ traineeNounCapitalised() }} monitoring will be available for this
              {{ cohortCourseWeekNounCapitalised }} once published.
            </div>
            <template v-else>
              <div class="pb-6 d-flex align-start">
                <div>
                  <mosaic-cohort-monitoring-filters
                    :students="selectedCohortStudents"
                    @update:filtered-student-ids="filteredStudentIds = $event"
                    ref="cohortFilters"
                  />
                </div>
                <div class="flex-grow-1" />
                <v-btn @click.prevent="clearFilters()">Clear Filters</v-btn>
              </div>
              <v-table>
                <thead>
                  <tr>
                    <th
                      v-for="h in headers"
                      :key="h.name"
                      class="px-2"
                      style="vertical-align: top; height: auto !important; font-size: 14px"
                    >
                      <div
                        class="d-flex align-center"
                        style="cursor: pointer !important"
                        @click.prevent="sortByHeader(h)"
                      >
                        <div class="flex-grow-1">
                          <span>{{ h.name }}</span>
                        </div>
                        <div>
                          <v-icon v-if="sortBy !== h.key">mdi-swap-vertical</v-icon>
                          <v-icon v-else-if="sortDesc">mdi-arrow-down</v-icon>
                          <v-icon v-else>mdi-arrow-up</v-icon>
                        </div>
                      </div>
                    </th>
                  </tr>
                  <tr>
                    <th
                      v-for="h in headers"
                      :key="h.key"
                      class="px-2"
                      style="vertical-align: top; height: auto !important"
                    >
                      <div v-if="h.filter" class="pb-2 d-flex align-top">
                        <v-select
                          v-model="h.filter.selectedValue"
                          class="v-select-auto-width"
                          :items="h.filter.items"
                          hide-details
                          density="compact"
                          item-title="name"
                          item-value="name"
                          return-object
                        />
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="filteredStudentWeeks.length === 0">
                    <td colspan="100" class="text-center">
                      There are no {{ traineeNounPluralised }} for these filters
                    </td>
                  </tr>
                  <tr v-for="sw in filteredStudentWeeks" :key="sw.id">
                    <td
                      v-for="h in headers"
                      :key="`${sw.id}-${h.key}`"
                      class="px-2"
                      :style="{ cursor: h.clickRoute && h.clickRoute(sw) ? 'pointer' : 'default' }"
                      v-on="h.clickRoute ? { click: () => h.clickRoute && $router.push(h.clickRoute(sw)) } : {}"
                    >
                      <div v-if="h.text">
                        <span>{{ h.text(sw) }}</span>
                      </div>
                      <div v-else-if="h.hasStatusChip">
                        <student-cohort-course-week-status-chip
                          :status="sw.status"
                          :student-viewer="false"
                          :week-noun="cohortCourseWeekNounCapitalised"
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </v-table>
            </template>
          </div>
        </v-card-text>
      </template>
    </mosaic-tab-card-page>
    <cohort-course-week-section-edit-dialog
      v-model:active="editSectionDialog.active"
      :section="editSectionDialog.section"
      :published="published"
      @save="triggerBackgroundLoad = true"
    />
    <cohort-course-week-edit-dialog
      v-model:active="editWeekDialog.active"
      :week="editWeekDialog.week"
      :term="term"
      @save="loadWeek()"
    />
  </div>
</template>

<script setup lang="ts">
import { setBreadcrumbs } from '@/utils/breadcrumbs';
import { computed, ref, watchEffect } from 'vue';
import type { ComputedRef } from 'vue';
import { mapGetters, mapState, mapActions } from '@/store/map-store';
import { useApi } from '@/composables/api';
import {
  useCohortCourseStore,
  useEditWeekSectionDetails,
  useCohortCourseWeekStore,
  useEditWeekDetails,
} from '@/stores/cohort-course';
import { useCohortStore } from '@/stores/cohort';
import type { Week, StudentWeek, WeekSection } from '@/stores/cohort-course';
import { useRoute, useRouter } from 'vue-router';
import type { RouteLocationNamedRaw } from 'vue-router';
import { useStudentStore } from '@/stores/student';
import { icons } from '@/utils/icons';
import MosaicCohortMonitoringFilters from '@/components/library/filters/MosaicCohortMonitoringFilters.vue';
import { parseRouteId } from '@/composables/vue-router';
import { useMoveListItem } from '@/components/library/mosaic-list/list-item-helpers';
import CohortCourseWeekSectionEditDialog from './CohortCourseWeekSectionEditDialog.vue';
import CohortCourseWeekEditDialog from './CohortCourseWeekEditDialog.vue';
import StudentCohortCourseWeekStatusChip from './StudentCohortCourseWeekStatusChip.vue';
import { mapWeekStatusToSortOrder } from '@/components/library/has-status/has-status';
import moment from 'moment';
import { hasHiddenCurriculumStatements, type ContentLayout } from '@/utils/content-layout';

const api = useApi();
const route = useRoute();
const router = useRouter();
const { traineeNounPluralised } = useStudentStore();
const { subjectsWithNullOptionAndPrimarySeparation: subjects } = mapGetters();
const { loadSubjects } = mapActions();
loadSubjects();

//#region Week state
const { selectedCohort } = mapState();
const {
  selectedCohortCourse,
  cohortCourseWeekNounCapitalised,
  actions: { loadCohortCourse },
} = useCohortCourseStore();

const {
  week,
  actions: { loadCohortCourseWeek },
} = useCohortCourseWeekStore();
const { selectedCohortStudents } = useCohortStore();

const studentWeeks = ref<StudentWeek[]>([]);
const weekId = parseRouteId('weekId');
async function loadStudentWeeks() {
  const response = await api.get<StudentWeek[]>(
    `cohorts/${selectedCohort.value.id}/course/weeks/${weekId.value}/student-weeks`
  );

  studentWeeks.value = response.data;
}

const triggerBackgroundLoad = ref(false);

async function loadWeek() {
  await loadCohortCourse();
  loadCohortCourseWeek(weekId.value, true);
}

const published = computed(() => week.value?.status === 'published');

async function load() {
  await loadWeek();
  await loadStudentWeeks();
}

const termId = parseRouteId('termId');
const term = computed(() => selectedCohortCourse.value?.cohortCourseTerms.find(x => x.id === termId.value) || null);

const previous = ref<RouteLocationNamedRaw | null>(null);
const next = ref<RouteLocationNamedRaw | null>(null);

watchEffect(async () => {
  const weekValue = week.value;
  if (!term.value || !weekValue) return;
  const index = term.value.cohortCourseWeeks.findIndex(x => x.id === weekValue.id);
  if (index !== 0) {
    previous.value = {
      name: 'TutorAdminCohortCourseWeekPage',
      params: { weekId: term.value.cohortCourseWeeks[index - 1].id },
      query: route.query,
    };
  } else {
    previous.value = null;
  }

  if (index !== term.value.cohortCourseWeeks.length - 1) {
    next.value = {
      name: 'TutorAdminCohortCourseWeekPage',
      params: { weekId: term.value.cohortCourseWeeks[index + 1].id },
      query: route.query,
    };
  } else {
    next.value = null;
  }
});

const { traineeNounCapitalised } = mapGetters();

const tabHeaders = [
  {
    text: 'Pages',
    key: 'sections',
  },
  {
    text: 'Monitoring',
    key: 'monitoring',
  },
];
const tab = ref('sections');

//#endregion

//#region Week Details

const { editWeekDialog, editWeek } = useEditWeekDetails();

//#endregion

//#region Sections

type ListWeekSection = WeekSection & {
  hideMove: boolean;
  disableDelete: boolean;
  clickTo?: RouteLocationNamedRaw;
  warningMessage?: string;
};
const { loadCurriculum } = mapActions();
loadCurriculum();
const { curriculumStatements } = mapState();

const warningMessage = (contentLayout: ContentLayout) => {
  if (contentLayout.sections.every(s => s.items.length === 0)) return `This Page has no content`;
  const hasEmptySections = contentLayout.sections.some(s => s.items.length === 0);
  const hiddenCurriculumStatements = curriculumStatements.value
    ? hasHiddenCurriculumStatements(contentLayout, curriculumStatements.value)
    : false;
  if (hasEmptySections || hiddenCurriculumStatements) {
    return `This Page has ${hasEmptySections ? 'empty Sections' + (hiddenCurriculumStatements ? ' and ' : '') : ''}${
      hiddenCurriculumStatements ? 'hidden Curriculum Statements' : ''
    }`;
  }
  return '';
};

const weekSections: ComputedRef<ListWeekSection[]> = computed(() => {
  if (!week.value) return [];
  return week.value.cohortCourseWeekSections.map(s => ({
    ...s,
    title: s.title,
    hideMove: published.value,
    disableDelete: published.value,
    warningMessage: warningMessage(s.contentLayout),
    deleteDisabledTooltip: `Cannot delete ${cohortCourseWeekNounCapitalised.value} Pages from a published ${cohortCourseWeekNounCapitalised.value}`,
    clickTo: {
      name: 'TutorAdminCohortCourseWeekSectionPage',
      params: {
        weekId: route.params.weekId,
        sectionId: s.id,
      },
    },
  }));
});

//#region Edit Sections
interface Subject {
  id: number;
  name: string;
}
const newSectionName = ref('');
const newSectionSubject = ref<Subject | null>(null);

const canAddSection = computed(() => !!newSectionName.value);

async function addWeekSection() {
  await api.post(`/cohort-course-weeks/${weekId.value}/sections`, {
    title: newSectionName.value,
    subjectId: newSectionSubject.value?.id,
  });
}

// Move Section
const moveDisabledMessage = published.value
  ? `This ${cohortCourseWeekNounCapitalised} cannot be edited because it has been published`
  : '';

const { moveListItem: moveSectionListItem } = useMoveListItem('/cohort-course-weeks/sections', api);

//Delete Section
const deleteUrlStem = '/cohort-course-weeks/sections';

//#region Edit Section details
const { editSectionDialog, editSection } = useEditWeekSectionDetails();

//#endregion

//#region Monitoring
type HeaderKey = 'student' | 'status' | 'tasks' | 'questions';

interface Header {
  name: string;
  key: HeaderKey;
  text?: (x: StudentWeek) => string;
  hasStatusChip?: boolean;
  filter?: {
    items: { name: string; filter: (x: StudentWeek, week: Week) => boolean }[];
    selectedValue: { name: string; filter: (x: StudentWeek) => boolean };
  };
  sortValue: (x: StudentWeek) => string | number;
  clickRoute?: (x: StudentWeek) => RouteLocationNamedRaw;
}
const headers = ref<Header[]>([]);
function createHeaders() {
  headers.value = [
    {
      name: 'Name',
      key: 'student',
      text: x => x.student.name || x.student.email,
      sortValue: x => x.student.name || x.student.email,
      clickRoute: x => ({
        name: 'TutorCohortCourseWeekPage',
        params: {
          studentId: x.student.id,
          studentWeekId: x.id,
        },
      }),
    },
    {
      name: 'Status',
      key: 'status',
      hasStatusChip: true,
      filter: {
        items: [
          {
            name: 'Any',
            filter: () => true,
          },
          {
            name: 'Completed',
            filter: x => x.status === 'completed',
          },
          {
            name: 'Started',
            filter: x => x.status === 'started',
          },
          {
            name: 'Not Started',
            filter: x => x.status === 'not_started',
          },
        ],
        selectedValue: {
          name: 'Any',
          filter: () => true,
        },
      },
      sortValue: x => mapWeekStatusToSortOrder(x.status),
    },
    {
      name: 'Tasks marked as complete',
      key: 'tasks',
      text: x => `${x.tasksCompleted}/${x.weekTasksCount}`,
      filter: {
        items: [
          { name: 'Any', filter: () => true },
          {
            name: 'Some',
            filter: x => x.tasksCompleted > 0,
          },
          {
            name: 'None',
            filter: x => x.tasksCompleted === 0,
          },
          {
            name: 'All',
            filter: x => x.tasksCompleted === x.weekTasksCount,
          },
        ],

        selectedValue: { name: 'Any', filter: () => true },
      },
      sortValue: x => x.tasksCompleted,
    },
    {
      name: 'Text Questions answered',
      key: 'questions',
      text: x => `${x.commentsCompleted}/${x.weekCommentsCount}`,
      filter: {
        items: [
          { name: 'Any', filter: () => true },
          {
            name: 'Some',
            filter: x => x.commentsCompleted > 0,
          },
          {
            name: 'None',
            filter: x => x.commentsCompleted === 0,
          },
          {
            name: 'All',
            filter: x => x.commentsCompleted === x.weekCommentsCount,
          },
        ],

        selectedValue: { name: 'Any', filter: () => true },
      },
      sortValue: x => x.commentsCompleted,
    },
  ];
}
createHeaders();
const sortBy = ref('student');
const sortDesc = ref(false);

function sortByHeader(h: Header) {
  if (h.key === sortBy.value) {
    sortDesc.value = !sortDesc.value;
  } else {
    sortBy.value = h.key;
    sortDesc.value = false;
  }
}

const cohortFilters = ref<InstanceType<typeof MosaicCohortMonitoringFilters>>();
function clearFilters() {
  createHeaders();
  cohortFilters.value ? cohortFilters.value.clearCohortFilters() : undefined;
}
const filteredStudentIds = ref<number[]>([]);
const filteredStudentWeeks = computed(() => {
  if (headers.value.length === 0) return [];
  if (!week.value) return [];
  const sortSelector = headers.value.find(h => h.key === sortBy.value)?.sortValue;
  if (!sortSelector) return [];
  return studentWeeks.value
    .filter(x => {
      return (
        filteredStudentIds.value.includes(x.student.id) &&
        headers.value.every(h => {
          return h.filter?.selectedValue ? h.filter.selectedValue.filter(x) : true;
        })
      );
    })
    .sortBy(sortSelector, sortDesc.value ? 'desc' : 'asc');
});
//#endregion

setBreadcrumbs(
  computed(() => [
    {
      text: 'Training Plan',
      to: {
        name: 'TutorAdminCohortCoursePage',
      },
    },
    {
      text: term.value?.name || '',
      to: {
        name: 'TutorAdminCohortCourseTermPage',
        termId: term.value?.id,
      },
    },
    {
      text: week.value?.name || '',
    },
  ])
);
</script>
