<template>
  <div>
    <v-card class="mb-4">
      <v-card-text>
        <div class="pa-2">
          <div class="d-flex align-start" :class="{ 'flex-wrap justify-end': smallScreen }" style="row-gap: 8px">
            <div class="text-h6">{{ studentReviewStandard.title }}</div>
            <div v-if="dirty" class="mx-2 pt-2">(unsaved)</div>
            <div v-else style="width: 63px" class="mx-2"></div>

            <div class="flex-grow-1"></div>
            <template v-if="!studentReview.approved">
              <mosaic-role-completed-chip
                class="pl-1"
                v-for="x in studentReviewStandard.completed"
                :key="x.name"
                :role-id="x.roleId"
                :completed="x.completed"
              />
            </template>
            <div>
              <v-btn
                v-if="studentReviewStandard.previous"
                class="ml-2"
                @click.prevent="$router.push(studentReviewStandard.previous)"
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn
                v-if="studentReviewStandard.next"
                class="ml-2"
                @click.prevent="$router.push(studentReviewStandard.next)"
              >
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
              <v-btn v-else class="ml-2" @click.prevent="backToSummary()">to Summary</v-btn>
            </div>
          </div>
          <div v-if="studentReview.approved" class="pl-1 pt-2">
            <v-chip color="secondary">Approved</v-chip>
            <span class="pl-1">This {{ reviewPointName() }} has been approved and can no longer be edited</span>
          </div>
          <mosaic-quill v-if="studentReviewStandard.note" :contents="studentReviewStandard.note" :read-only="true" />
        </div>
      </v-card-text>
    </v-card>
    <v-card v-if="showCard" class="mb-4">
      <v-card-text>
        <div class="text-h5 pb-2">Review of Progress</div>
        <div class="pl-2 py-2">
          <div v-if="studentReview.review.standardJudgementSet && canViewJudgement">
            <review-part-title
              :can-edit="!studentReview.approved && canEditJudgement"
              :completed="!!judgementDescriptorId"
              >Assessment of {{ traineeNounCapitalised() }} Progress</review-part-title
            >
            <template v-if="!studentReview.approved && canEditJudgement">
              <div class="d-flex pl-4">
                <mosaic-judgement-select
                  v-model="judgementDescriptorId"
                  :readonly="false"
                  :items="judgementDescriptors"
                />
              </div>
            </template>

            <div v-else class="pa-2 pb-4 ml-7">
              <div v-if="judgementDescriptor" class="d-flex align-center">
                <v-chip :color="judgementDescriptor.color">{{ judgementDescriptor.descriptor }}</v-chip>
              </div>
              <div v-else class="ml-1 text-body-1">To be completed</div>
            </div>
          </div>
          <review-comment-box
            v-for="c in comments"
            :key="c.id"
            :comment="c"
            :review-approved="studentReview.approved"
            :can-edit="canEdit(c)"
            :completed="commentCompleted(c)"
            @update:comment-text="c.comment = $event"
          >
          </review-comment-box>
          <template v-if="studentReviewStandard.statementGroups.length > 0">
            <div class="d-flex flex-column">
              <review-part-title :can-edit="canEditStatementJudgement" :completed="allStatementsHaveJudgements"
                >Statements</review-part-title
              >
              <template v-for="g in statementGroups" :key="'group-name-' + g.id">
                <div class="font-weight-bold py-2">{{ g.name }}</div>
                <template v-for="s in g.statements" :key="'statement-' + s.id">
                  <div class="flex-grow-1 d-flex align-center">
                    <div class="d-flex align-top">
                      <div>
                        <ndt-icon-button
                          v-if="guidanceExists"
                          icon="playlist-check"
                          :tooltip="
                            s.guidance ? 'Show assessment guidance' : 'No assessment guidance for this statement'
                          "
                          :color="s.expanded ? 'primary' : ''"
                          :icon-color="s.expanded ? 'primary' : ''"
                          :disabled="!s.guidance"
                          @click.prevent="updateExpansion(g, s)"
                        />
                        <v-icon v-else class="pb-2 pl-2 pt-1 pr-1">mdi-playlist-check</v-icon>
                      </div>
                      <div class="pl-2 flex-grow-1">
                        <div class="pt-3">{{ s.statement }}</div>
                        <div v-if="s.expanded" class="d-flex mt-4 mb-2 guidance-box">
                          <div class="mx-2 pt-2 pl-2">
                            <div class="text-subtitle-1">Assessment Guidance</div>
                            <div>
                              <mosaic-quill :contents="s.guidance" :read-only="true" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="d-flex justify-end align-end mb-2"
                    :class="{
                      'flex-column': smallScreen,
                      'flex-column-reverse': smallScreen,
                    }"
                    style="row-gap: 8px; column-gap: 16px"
                  >
                    <div v-if="!studentReview.approved && canEditStatementJudgement" class="d-flex align-top">
                      <mosaic-judgement-select
                        v-model="s.judgementDescriptorId"
                        :readonly="false"
                        :items="statementJudgementDescriptors"
                      />
                    </div>
                    <div v-else class="px-2">
                      <div>Assessment</div>
                      <div v-if="statementJudgementDescriptor(s)" class="pb-2">
                        <v-chip :color="statementJudgementDescriptor(s).color">{{
                          statementJudgementDescriptor(s).descriptor
                        }}</v-chip>
                      </div>
                      <div v-else class="text-body-1 pt-1 pb-3">To be completed</div>
                    </div>
                    <div v-if="studentReview.previousStudentReviewId">
                      <div class="d-flex align-center">
                        <div class="mr-1">Previous</div>
                        <mosaic-help
                          >This assessment was made as part of the previous {{ reviewNounCapitalised }}</mosaic-help
                        >
                      </div>
                      <div v-if="previousStatementJudgementDescriptor(s)" class="pb-2">
                        <v-chip :color="previousStatementJudgementDescriptor(s).color">{{
                          previousStatementJudgementDescriptor(s).descriptor
                        }}</v-chip>
                      </div>
                      <div v-else class="d-flex align-top">
                        <v-chip color="grey">Not recorded</v-chip>
                      </div>
                    </div>
                  </div>
                </template>
              </template>
            </div>
          </template>
        </div>
        <v-alert density="compact" class="my-4 mx-4" v-if="error" type="error">{{ error }}</v-alert>
        <div class="pt-2 d-flex">
          <div class="flex-grow-1">
            <mosaic-snackbar v-model="snackbar" color="success" :message="snackbarMessage" />
          </div>
          <v-btn v-if="studentReview.approved" class="ml-2" @click.prevent="nextPage()">{{
            studentReviewStandard.next ? 'Continue' : 'Return'
          }}</v-btn>
          <template v-else>
            <v-btn v-if="showSave" ripple :disabled="saveDisabled" @click.prevent="save()">Save</v-btn>
            <v-btn class="ml-2" @click.prevent="saveAndContinue()"
              >Save and {{ studentReviewStandard.next ? 'Continue' : 'Return' }}</v-btn
            ></template
          >
        </div>
      </v-card-text>
    </v-card>
    <mosaic-mark-as-complete-dialog
      v-model:active="markAsCompleteDialog.active"
      :roles-that-can-complete="myRolesThatCanMarkAsComplete"
      :student-viewer="studentViewer"
      :staff-viewer="staffViewer"
      :next="markAsCompleteDialog.next"
      :student-review-id="studentReview.id"
      :review-path="reviewPath.to"
    ></mosaic-mark-as-complete-dialog>
    <review-targets-list
      v-if="studentReview.review.canAddTargetsPerStandard"
      :student-review="studentReview"
      :student-review-standard-id="studentReviewStandard.id"
      :is-overall-review="false"
      :staff-viewer="staffViewer"
      :student-viewer="studentViewer"
      :student="student"
    />
  </div>
</template>

<script>
import MosaicQuill from './quill/MosaicQuill.vue';
import ReviewTargetsList from './ReviewTargetsList.vue';
import { roleHasPermission, rolesThatCanComplete, canStudentMarkAsComplete } from '@/utils/student-review';
import NdtIconButton from './NdtIconButton.vue';
import { mapGetters, mapState } from 'vuex';
import ReviewCommentBox from './ReviewCommentBox.vue';
import ReviewPartTitle from './ReviewPartTitle.vue';
import MosaicMarkAsCompleteDialog from './library/dialogs/reviews/MosaicMarkAsCompleteDialog.vue';

export default {
  name: 'ReviewStandardCard',
  components: {
    ReviewTargetsList,
    MosaicQuill,
    NdtIconButton,
    ReviewCommentBox,
    ReviewPartTitle,
    MosaicMarkAsCompleteDialog,
  },
  props: {
    studentReviewStandard: {
      type: Object,
      required: true,
    },
    studentReview: {
      type: Object,
      required: true,
    },
    staffViewer: {
      type: Object,
      default: null,
    },
    studentViewer: {
      type: Object,
      default: null,
    },
    student: {
      type: Object,
      required: true,
    },
    reviewPath: {
      type: Object,
      required: true,
    },
  },
  emits: ['update:dirty'],
  data: () => ({
    error: '',
    processing: false,
    snackbar: false,
    snackbarMessage: '',
    comments: [],
    judgementDescriptorId: null,
    statementGroups: [],
    guidanceExists: false,
    markAsCompleteDialog: {
      active: false,
      next: null,
      afterSave: true,
    },
  }),
  watch: {
    studentReviewStandard(srs, old) {
      if (srs.id !== old.id) {
        this.judgementDescriptorId = null;
        this.comments = [];
        this.statementGroups = [];
      }
      this.mapData();
    },
    dirty(x) {
      this.$emit('update:dirty', x);
    },
  },
  created() {
    this.mapData();
  },
  computed: {
    ...mapState(['selectedInstitution', 'roles', 'studentReviewPages']),
    ...mapGetters(['reviewNoun']),
    myRolesThatCanMarkAsComplete() {
      return this.staffViewer
        ? rolesThatCanComplete(this.roles, this.studentReview, this.staffViewer, this.student)
        : [];
    },
    canMarkAnyAsComplete() {
      return this.staffViewer ? this.myRolesThatCanMarkAsComplete.length : canStudentMarkAsComplete(this.studentReview);
    },
    showCard() {
      return (
        this.studentReviewStandard.statementGroups.length > 0 ||
        this.studentReviewStandard.standardComments.length > 0 ||
        (this.studentReview.review.standardJudgementSet && this.canViewJudgement)
      );
    },
    showSave() {
      if (this.studentViewer) {
        return this.studentReviewStandard.standardComments.some(x => x.canTraineeEdit);
      }
      return (
        (this.canEditJudgement && this.studentReview.review.standardJudgementSet) ||
        (this.canEditStatementJudgement && this.studentReviewStandard.statementGroups.length > 0) ||
        this.studentReviewStandard.standardComments.some(x => this.canEdit(x))
      );
    },
    allStatementsHaveJudgements() {
      return this.statementGroups.every(g => {
        return g.statements.every(s => !!s.judgementDescriptorId);
      });
    },
    saveDisabled() {
      return this.processing || this.studentReview.approved;
    },
    canViewJudgement() {
      if (!this.studentViewer) return true;
      return this.studentReview.review.canTraineesViewJudgements;
    },
    canEditJudgement() {
      if (this.studentViewer) return false;
      return roleHasPermission(this.studentReview.review.roleIdsThatCanEditJudgements, this.staffViewer, this.student);
    },
    canEditStatementJudgement() {
      if (this.studentViewer) return this.studentReview.review.canTraineesEditStandardStatementJudgements;

      return roleHasPermission(
        this.studentReview.review.roleIdsThatCanEditStatementJudgements,
        this.staffViewer,
        this.student
      );
    },
    judgementDescriptors() {
      return this.studentReview.review.standardJudgementSet?.institutionJudgementDescriptors;
    },
    judgementDescriptor() {
      if (!this.judgementDescriptorId) return;
      return this.judgementDescriptors.find(x => x.id === this.judgementDescriptorId);
    },
    statementJudgementDescriptors() {
      return this.studentReview.review.standardStatementJudgementSet?.institutionJudgementDescriptors;
    },
    statementGroupFlexBasis() {
      return '100%';
    },

    dirty() {
      const commentsDirty = this.studentReviewStandard.standardComments.some(x => {
        const comment = this.comments.find(c => c.id === x.id);
        return comment?.comment !== x.comment && comment?.comment !== x.placeholder;
      });
      const statements = this.statementGroups.map(x => x.statements).flat();
      const statementsDirty = this.studentReviewStandard.statementGroups.some(x => {
        return x.statements.some(s => {
          const statement = statements.find(x => x.id === s.id);
          // Deliberately != so that null == undefined
          return statement?.judgementDescriptorId != s.judgementDescriptorId;
        });
      });
      // Deliberately != so that null == undefined
      const judgementDirty = this.studentReviewStandard.judgementDescriptorId != this.judgementDescriptorId;

      return commentsDirty || statementsDirty || judgementDirty;
    },
  },
  methods: {
    mapData() {
      this.comments = this.studentReviewStandard.standardComments.map(x => {
        const comment = this.comments.find(c => c.id === x.id);
        return { ...x, comment: comment?.comment || x.comment || x.placeholder || null };
      });
      this.judgementDescriptorId = this.judgementDescriptorId || this.studentReviewStandard.judgementDescriptorId;
      const statements = this.statementGroups.map(x => x.statements).flat();
      let first = true;
      this.statementGroups = this.studentReviewStandard.statementGroups.map(sg => {
        return {
          ...sg,
          statements: sg.statements.map(s => {
            const statement = statements.find(x => x.id === s.id);

            if (s.guidance) {
              this.guidanceExists = true;
            }
            first = first && this.guidanceExists;

            const mappedStatement = {
              ...s,
              judgementDescriptorId: statement?.judgementDescriptorId || s.judgementDescriptorId,
              expanded: first,
            };
            first = !this.guidanceExists;

            return mappedStatement;
          }),
        };
      });
    },
    backToSummary() {
      this.$router.push(this.reviewPath.to);
    },
    canEdit(comment) {
      if (this.studentViewer) {
        return comment.canTraineeEdit;
      }
      return roleHasPermission(comment.roleIdsThatCanEdit, this.staffViewer, this.student);
    },
    commentCompleted(c) {
      return !!(c.comment && c.comment !== c.placeholder);
    },
    statementJudgementDescriptor(statement) {
      if (!statement.judgementDescriptorId) return;
      return this.statementJudgementDescriptors.find(x => x.id === statement.judgementDescriptorId);
    },
    previousStatementJudgementDescriptor(statement) {
      if (!statement.previousJudgementDescriptorId) return;
      return this.statementJudgementDescriptors.find(x => x.id === statement.previousJudgementDescriptorId);
    },
    reviewPointName() {
      return this.selectedInstitution.config.review_noun;
    },
    updateExpansion(g, s) {
      const expansionState = s.expanded;
      this.statementGroups = this.statementGroups.map(sg => {
        return {
          ...sg,
          statements: sg.statements.map(st => {
            return {
              ...st,
              expanded: s.id === st.id ? !expansionState : false,
            };
          }),
        };
      });
    },

    async saveToApi(next) {
      this.error = '';

      this.processing = true;
      try {
        const body = {
          comments: this.comments.filter(this.canEdit).map(x => ({
            id: x.id,
            comment: x.comment === x.placeholder ? null : x.comment,
          })),
        };
        if (this.canEditJudgement && this.studentReview.review.standardJudgementSet) {
          body.institution_judgement_descriptor_id = this.judgementDescriptorId;
        }
        body.statements = this.canEditStatementJudgement
          ? this.statementGroups
              .map(g =>
                g.statements.map(s => ({
                  id: s.studentReviewStatementId,
                  institution_judgement_descriptor_id: s.judgementDescriptorId,
                }))
              )
              .flat()
          : [];
        await this.$api.put(`/student-review-standards/${this.studentReviewStandard.id}`, body);
        await this.$store.commit('updateStudentReviewStandard', { ...body, id: this.studentReviewStandard.id });
        next();
      } catch (e) {
        console.log(e);
        this.error = `Sorry, cannot save your ${this.reviewPointName()} at the moment`;
      }
      this.processing = false;
    },
    async save() {
      await this.saveToApi(() => {
        if (this.canMarkAnyAsComplete && this.studentReviewPages.showCompletedModal) {
          this.markAsCompleteDialog = {
            active: true,
            next: null,
            afterSave: true,
          };
        } else {
          this.snackbarMessage = `${this.reviewNounCapitalised} saved`;
          this.snackbar = true;
        }
      });
    },
    async saveAndContinue() {
      await this.saveToApi(() => {
        const next = this.studentReviewStandard.next || this.reviewPath.to;
        if (this.canMarkAnyAsComplete && this.studentReviewPages.showCompletedModal) {
          this.markAsCompleteDialog = {
            active: true,
            next: next,
            afterSave: true,
          };
        } else this.nextPage();
      });
    },
    nextPage() {
      if (this.studentReviewStandard.next) this.$router.push(this.studentReviewStandard.next);
      else this.backToSummary();
    },
  },
};
</script>

<style scoped>
.grid-container {
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 12px;
}
.grid-container-mobile {
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 12px;
}

.guidance-box {
  border: 2px solid rgb(var(--v-theme-primary));
  border-radius: 10px;
  width: 95%;
}
</style>
