<template>
  <div>
    <mosaic-create-edit-card-page
      object-type="Certificate"
      :is-creating="isCreating"
      :load="load"
      :dirty="dirty"
      :save="save"
      :can-save="canSave"
      :readonly="!editable || certificate?.status == 'approved'"
      :return-to="getReturnTo(breadcrumbs)"
      :return-is-primary="!isMyStaffTraining && certificate?.status != 'awaiting_approval'"
      :title-chip="certificate ? staffTrainingCertificateStatusChip(certificate.status) : undefined"
    >
      <template
        #title-chip
        v-if="
          isMyStaffTraining && certificate?.status != 'approved' && !!certificate?.mostRecentOtherInstitutionApprovedAt
        "
      >
        <mosaic-tooltip-chip color="secondary" class="ml-2">
          <template #text> Approved Elsewhere </template>

          <template #tooltip>
            This Certificate has been approved in a different Institution so only limited edits can be made
          </template>
        </mosaic-tooltip-chip>
      </template>

      <div v-if="certificate" class="mb-2">
        <mosaic-card-subtitle v-if="certificate.status == 'rejected'">
          Rejected by {{ certificate.rejector.name }} on
          {{ formatDate(certificate.rejectedAt) }}
        </mosaic-card-subtitle>
        <mosaic-card-subtitle v-else-if="certificate.status == 'approved'">
          Approved by {{ certificate.approver.name }} on
          {{ formatDate(certificate.approvedAt) }}
        </mosaic-card-subtitle>
        <mosaic-card-subtitle v-else>
          Created by {{ certificate.creator.name }} on
          {{ formatDate(certificate.createdAt) }}
        </mosaic-card-subtitle>
      </div>

      <mosaic-info-alert v-if="certificate?.status == 'rejected'" class="mb-4">
        <div>This Certificate has been rejected for the following reason:</div>
        <div class="pt-2" style="white-space: pre-wrap">
          <em>"{{ certificate!.rejectionMessage }}"</em>
        </div>
      </mosaic-info-alert>

      <div>
        <mosaic-select
          name="type"
          label="Certificate Type"
          v-model="certificateTypeId"
          :items="acceptedCertificates"
          no-items-text="Your course leads currently do not accept any certificates"
          item-title="name"
          :prepend-icon="icons.certificate"
          :readonly="
            !editable || certificate?.status == 'approved' || !!certificate?.mostRecentOtherInstitutionApprovedAt
          "
        />
      </div>
      <div v-if="selectedCertificate" style="margin-left: 26px">
        <mosaic-quill
          v-if="selectedCertificate.description"
          :read-only="true"
          :contents="selectedCertificate.description"
          style="margin-top: -16px"
        />
        <staff-training-competency-multi-select
          class="pl-4"
          :label="`Competencies this Certificate ${certificate?.status == 'approved' ? 'meets' : 'will meet'}`"
          :empty-text="`Meets no Competencies for ${selectedInstitution.name}`"
          :model-value="
            selectedCertificate.staffTrainingAcceptedCertificateCompetencies.map(c => c.staffTrainingCompetencyId)
          "
          :frameworks="institutionStaffTrainingFrameworks || []"
          :readonly="true"
          :assigned-framework-ids="assignedStaffTrainingFrameworks.map(f => f.id)"
          :staff-training-competency-to="staffTrainingCompetencyTo"
          no-icon
          :is-me="isMyStaffTraining"
        />
      </div>
      <mosaic-file-upload-field
        name="evidence"
        label="Evidence"
        v-model="files"
        :min-files="1"
        :max-files="10"
        :presign-url="`/presign/staff-training-certificate-evidence`"
        :get-file-url-prefix="`/staff/${staffId}/staff-training-certificates/${certificate?.id}/evidence`"
        @uploading="filesUploading = $event"
        :readonly="!editable || certificate?.status == 'approved'"
        can-only-add-files-disabled-tooltip="Cannot delete Evidence added before this Certificate was approved"
      />
      <mosaic-quill-field
        name="notes"
        label="Notes"
        v-model="note"
        :readonly="!editable || certificate?.status == 'approved'"
      />

      <mosaic-error-alert :error="approveError" :action="`approve this Certificate`" />
      <mosaic-error-alert :error="unapproveError" :action="`unapprove this Certificate`" />
      <mosaic-error-alert :error="unrejectError" :action="`unreject this Certificate`" />
      <template #buttons v-if="canApprove">
        <template v-if="certificate?.status == 'awaiting_approval'">
          <mosaic-btn color="error" class="mr-2" :disabled="approving" @click="reject()"> Reject </mosaic-btn>
          <mosaic-btn color="primary" :disabled="approving" :loading="approving" @click="approve()">
            Approve
          </mosaic-btn>
        </template>
        <mosaic-btn
          v-else-if="certificate?.status == 'approved'"
          :disabled="unapproving"
          :loading="unapproving"
          @click="unapprove()"
        >
          Unapprove
        </mosaic-btn>
        <mosaic-btn
          v-else-if="certificate?.status == 'rejected'"
          :disabled="unrejecting"
          :loading="unrejecting"
          @click="unreject()"
        >
          Unreject
        </mosaic-btn>
      </template>
    </mosaic-create-edit-card-page>

    <mosaic-dialog
      v-model:active="rejectDialogActive"
      title="Reject Certificate"
      :error-message="rejectError ? 'Sorry, cannot reject this Certificate at the moment' : ''"
    >
      <mosaic-text-area name="reason" label="Rejection Reason" v-model="rejectionMessage" prepend-icon="text" />
      <template #buttons>
        <mosaic-btn
          variant="text"
          color="error"
          :disabled="!canReject || rejecting"
          :loading="rejecting"
          @click="submitRejection"
        >
          Reject
        </mosaic-btn>
      </template>
    </mosaic-dialog>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { useRoute } from 'vue-router';
import { icons } from '@/utils/icons';
import { useStaffTrainingStore } from '@/stores/staff-training';
import MosaicQuill from '@/components/quill/MosaicQuill.vue';
import type { FileDetails } from '@/components/mosaic-content-layout/FileUpload.vue';
import { myStaffTrainingBreadcrumbs, selectedStaffTrainingBreadcrumbs, useStaffTraining } from './staff-training';
import { setBreadcrumbs, getReturnTo } from '@/utils/breadcrumbs';
import { useInstitutionStore } from '@/stores/institution';
import { useApi } from '@/composables/api';
import { parseRouteId } from '@/composables/vue-router';
import { mapState } from '@/store/map-store';
import { staffTrainingCertificateStatusChip } from './staff-training';
import { hasPermissionForSelectedInstitution } from '@/composables/permission';
import { withProcessingAndError } from '@/composables/processing-and-errors';
import StaffTrainingCompetencyMultiSelect from './StaffTrainingCompetencyMultiSelect.vue';
import { useInstitutionStaffTrainingStore } from '@/stores/institution-staff-training';

// #region setup
const api = useApi();
const route = useRoute();
const certificateId = parseRouteId('certificateId');
const isCreating = computed(() => (route.name || '').toString().includes('Create'));
const editable = computed(() => isMyStaffTraining.value);

const {
  staffTrainingCertificates,
  staffTrainingAcceptedCertificates,
  assignedStaffTrainingFrameworks,
  actions: { loadStaffTraining },
} = useStaffTrainingStore();
const {
  institutionStaffTrainingFrameworks,
  actions: { loadInstitutionStaffTrainingFrameworks },
} = useInstitutionStaffTrainingStore();

const { user } = mapState();
const { selectedInstitution } = useInstitutionStore();

const { staff, staffId, isMyStaffTraining, loadStaff, staffTrainingCompetencyTo } = useStaffTraining();

const certificateTypeId = ref<number>();
const acceptedCertificates = computed(() => {
  const c = staffTrainingAcceptedCertificates.value.map(ac => {
    const inUse = staffTrainingCertificates.value.some(
      c =>
        c.certificateType.id == ac.certificateType.id &&
        (isCreating.value || certificate.value?.certificateType.id !== c.certificateType.id)
    );
    return {
      ...ac,
      value: ac.certificateType.id,
      name: inUse ? `${ac.certificateType.name} (already in use)` : ac.certificateType.name,
      props: { disabled: inUse },
    };
  });

  if (certificate.value && c.every(ac => ac.certificateType.id !== certificate.value?.certificateType.id)) {
    c.push({
      id: -1,
      description: '',
      certificateType: certificate.value.certificateType,
      staffTrainingAcceptedCertificateCompetencies: [],
      value: certificate.value.certificateType.id,
      name: certificate.value.certificateType.name,
      props: { disabled: false },
    });
  }

  return c;
});
const selectedCertificate = computed(() =>
  acceptedCertificates.value.find(c => c.certificateType.id === certificateTypeId.value)
);
// #endregion

// #region data
type StaffTrainingCertificateResponse = {
  id: number;
  certificateType: {
    id: number;
    name: string;
  };
  note: string;
  staffTrainingCertificateEvidence: {
    title: string;
    fileId: string;
    createdAt: string;
  }[];
  createdAt: string;
  creator: {
    id: number;
    name: string;
  };
  mostRecentOtherInstitutionApprovedAt?: string;
} & (
  | {
      status: 'awaiting_approval';
      // rejectionMessage could be here if the certificate is rejected and then unrejected (this is deliberately saved)
      rejectionMessage: string;
    }
  | {
      status: 'approved';
      approvedAt: string;
      approver: {
        id: number;
        name: string;
      };
    }
  | {
      status: 'rejected';
      rejectedAt: string;
      rejector: {
        id: number;
        name: string;
      };
      rejectionMessage: string;
    }
);
const certificate = ref<StaffTrainingCertificateResponse>();

const files = ref<FileDetails[]>([]);
const filesUploading = ref(false);

const note = ref<string>();
//#endregion

// #region breadcrumbs
const breadcrumbs = computed(() => {
  return [
    ...(isMyStaffTraining.value
      ? myStaffTrainingBreadcrumbs('certificates')
      : selectedStaffTrainingBreadcrumbs(
          'certificates',
          selectedInstitution.value.id,
          staffId.value,
          route,
          staff.value?.user.name
        )),
    {
      text: isCreating.value ? 'New Certificate' : certificate.value?.certificateType.name || '',
    },
  ];
});
setBreadcrumbs(breadcrumbs);
// #endregion

// #region dirty checking
const isCreatingAndReturning = ref(false);
const dirty = computed(() => {
  if (isCreatingAndReturning.value) return false;
  if (isCreating.value) {
    return files.value.length > 0 || !!certificateTypeId.value || !!note.value;
  } else {
    const c = certificate.value;
    const certificateFileIds = c?.staffTrainingCertificateEvidence.map(e => e.fileId) || [];
    return (
      certificateTypeId.value != c?.certificateType.id ||
      note.value != c?.note ||
      files.value.length != c?.staffTrainingCertificateEvidence.length ||
      files.value.some(f => !certificateFileIds.includes(f.fileId))
    );
  }
});
// #endregion

// #region load
async function load() {
  const apiCalls = [
    loadInstitutionStaffTrainingFrameworks(),
    loadStaff(),
    loadCertificate(),
    loadStaffTraining(staffId.value),
  ];
  await Promise.all(apiCalls);
}

async function loadCertificate() {
  if (!isCreating.value) {
    const r = await api.get<StaffTrainingCertificateResponse>(
      `/staff/${staffId.value}/staff-training-certificates/${certificateId.value}`
    );
    certificate.value = r.data;
    certificateTypeId.value = r.data.certificateType.id;
    files.value = r.data.staffTrainingCertificateEvidence.map(e => ({
      ...e,
      unsaved: false,
      disabled: r.data.mostRecentOtherInstitutionApprovedAt
        ? e.createdAt < r.data.mostRecentOtherInstitutionApprovedAt
        : false,
    }));
    note.value = r.data.note;
    if (r.data.status !== 'approved') {
      rejectionMessage.value = r.data.rejectionMessage;
    }
  } else {
    if (route.query.certificateTypeId) {
      certificateTypeId.value = parseInt(route.query.certificateTypeId.toString()) || undefined;
    }
  }
}
//#endregion

// #region save
const canSave = computed(
  () => !filesUploading.value && !!certificateTypeId.value && files.value.length > 0 && dirty.value
);

async function save() {
  const body = {
    certificateTypeId: selectedCertificate.value!.certificateType.id,
    evidence: files.value,
    note: note.value,
  };

  if (isCreating.value) {
    await api.post(`/staff/${staffId}/staff-training-certificates`, body);
    isCreatingAndReturning.value = true;
  } else {
    const r = await api.put<typeof body, StaffTrainingCertificateResponse>(
      `/staff/${staffId.value}/staff-training-certificates/${certificate.value!.id}`,
      body
    );
    certificate.value = r.data;
    files.value = files.value.map(f => ({ ...f, unsaved: false }));
  }
  loadStaffTrainingForUser();
}
// #endregion

// #region approve
const canApprove = computed(
  () => !isMyStaffTraining.value && hasPermissionForSelectedInstitution('staff.training.certificates.approve').value
);

const {
  action: approve,
  processing: approving,
  error: approveError,
} = withProcessingAndError(async () => {
  const r = await api.post<unknown, StaffTrainingCertificateResponse>(
    `/staff/${staffId.value}/staff-training-certificates/${certificate.value!.id}/approve`,
    {}
  );
  certificate.value = r.data;
  loadStaffTrainingForUser();
});

const rejectionMessage = ref('');
const rejectDialogActive = ref(false);

const canReject = computed(() => !!rejectionMessage.value);

async function reject() {
  rejectDialogActive.value = true;
}

const {
  action: submitRejection,
  processing: rejecting,
  error: rejectError,
} = withProcessingAndError(async () => {
  const r = await api.post<unknown, StaffTrainingCertificateResponse>(
    `/staff/${staffId.value}/staff-training-certificates/${certificate.value!.id}/reject`,
    {
      rejectionMessage: rejectionMessage.value,
    }
  );
  certificate.value = r.data;
  rejectDialogActive.value = false;
  loadStaffTrainingForUser();
});

const {
  action: unapprove,
  processing: unapproving,
  error: unapproveError,
} = withProcessingAndError(async () => {
  const r = await api.post<unknown, StaffTrainingCertificateResponse>(
    `/staff/${staffId.value}/staff-training-certificates/${certificate.value!.id}/unapprove`,
    {}
  );
  certificate.value = r.data;
  loadStaffTrainingForUser();
});

const {
  action: unreject,
  processing: unrejecting,
  error: unrejectError,
} = withProcessingAndError(async () => {
  const r = await api.post<unknown, StaffTrainingCertificateResponse>(
    `/staff/${staffId.value}/staff-training-certificates/${certificate.value!.id}/unreject`,
    {}
  );
  certificate.value = r.data;
  loadStaffTrainingForUser();
});

async function loadStaffTrainingForUser() {
  if (user.value.staff?.some(s => s.id == staffId.value)) {
    await Promise.all(user.value.staff.map(staff => loadStaffTraining(staff.id, true)));
  } else {
    loadStaffTraining(staffId.value, true);
  }
}
// #endregion
</script>
