<template>
  <div>
    <mosaic-filter-list-page
      v-model:trigger-background-load="triggerBackgroundLoad"
      object-type="Course Activity"
      :items="assignments"
      :filtered-items="filteredAssignments"
      :load="load"
      @add="add"
    >
      <template #switches>
        <mosaic-switch
          name="show-assignments"
          label="Visible to Cohort"
          v-model="selectedCohort.show_assignments"
          object-type="visibility"
          :update-value="updateVisibility"
        />
        <mosaic-error-snackbar v-model="deleteError" action="delete this Course Activity" />
      </template>
      <template #filters>
        <mosaic-text-field
          v-model="nameFilter"
          name="name-filter"
          label="Filter by name"
          style="width: 250px"
          prepend-icon="magnify"
          hide-details
        />
        <div
          class="d-flex align-center"
          :class="{
            'vertical-flex-spacing-small': smallScreen,
            'horizontal-flex-spacing-small': !smallScreen,
            'flex-wrap': smallScreen,
          }"
        >
          <span class="pt-4">Show due dates between</span>
          <div class="d-flex align-center">
            <mosaic-date-picker
              v-model:date="startDate"
              placeholder="start date"
              density="compact"
              hide-details
              active-icon-colour
            />
            <span class="pt-4">and</span>
          </div>
          <div class="d-flex align-center">
            <mosaic-date-picker
              v-model:date="endDate"
              placeholder="end date"
              hide-details
              active-icon-colour
              density="compact"
            />
            <div style="margin-bottom: -20px">
              <mosaic-icon-btn icon="close" tooltip="Clear dates" @click.prevent="clearFilters()" />
            </div>
          </div>
        </div>
      </template>

      <template v-if="!selectedCohort.show_assignments && assignments.length > 0" #alert>
        <mosaic-info-alert>
          These Course Activities are not currently visible to {{ traineeNounCapitalisedAndPluralised }} as "Visible to
          Cohort" is switched off.
        </mosaic-info-alert>
      </template>

      <template #list-item="{ item: a }">
        <mosaic-list-item
          :key="a.id"
          :title="a.name"
          :subtitle="a.dueDate ? `Due ${formatDate(a.dueDate)}` : 'No due date'"
          icon="mdi-clipboard-text"
          :to="{
            name: 'CohortAssignmentViewPage',
            params: { id: a.id },
          }"
        >
          <template #information>
            <mosaic-role-completed-chip
              class="pr-2"
              :completed="true"
              :role-id="a.roleId || 'student'"
              :role-scoped-tooltip="(role: RoleWithStudent) => `This Activity is to be completed by ${role.pluralisedName}`"
            />
            <cohort-action-curriculum-links-badge :curriculum-statements="a.curriculumStatements" />
            <mosaic-trainee-count-chip :count="a.studentAssignmentCount" object-type="Course Activity" />
          </template>

          <template #actions>
            <mosaic-icon-btn
              icon="mdi-pencil"
              tooltip="Edit Course Activity"
              :to="{ name: 'CohortAssignmentEditPage', params: { id: a.id } }"
            />
            <mosaic-delete-icon-btn
              object-type="Course Activity"
              :disabled="deleteProcessing"
              @click.prevent="deleteAssignment(a.id)"
            />
          </template>
        </mosaic-list-item>
      </template>
    </mosaic-filter-list-page>

    <mosaic-delete-dialog
      v-model:active="deleteDialog.active"
      object-type="Course Activity"
      :object-name="deleteDialog.name"
      :url="deleteDialog.url"
      :deleting-student-data="deleteDialog.deletingStudentData"
      @delete="triggerBackgroundLoad = true"
    >
      <div v-if="deleteDialog.deletingStudentData">
        <div>You are about to delete Course Activities with the following statuses:</div>
        <delete-dialog-information :status-counts="deleteDialog.statusCounts" />
      </div>
    </mosaic-delete-dialog>
  </div>
</template>

<script setup lang="ts">
import { mapState, type CurriculumStatementSlim } from '@/store/map-store';
import { setBreadcrumbs } from '@/utils/breadcrumbs';
import { ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import MosaicFilterListPage from '@/components/library/pages/MosaicFilterListPage.vue';
import { mapMutations } from '@/store/map-store';
import { withProcessingAndError } from '@/composables/processing-and-errors';
import type { Assignment as AssignmentFull } from './assignment';
import type { StudentAssignmentStatus } from './student-assignments';
import { studentAssignmentStatuses } from './student-assignments';
import DeleteDialogInformation from './DeleteDialogInformation.vue';
import { useApi } from '@/composables/api';
import { dateIsInclusiveBetween } from '@/utils/date';
import CohortActionCurriculumLinksBadge from '@/components/cohort-actions/CohortActionCurriculumLinksBadge.vue';
import type { RoleWithStudent } from '@/store/map-store';

const api = useApi();

const { selectedCohort } = mapState();
const { updateSelectedCohortShowAssignments } = mapMutations();

setBreadcrumbs([
  {
    text: `Course Activities`,
  },
]);

interface Assignment {
  id: number;
  name: string;
  dueDate: string;
  studentAssignmentCount: number;
  roleId: number | null;
  isTraineeContributer: boolean;
  curriculumStatements: CurriculumStatementSlim[];
}

const assignments = ref<Assignment[]>([]);
async function load() {
  const r = await api.get<Assignment[]>(`/cohorts/${selectedCohort.value.id}/assignments`);
  assignments.value = r.data;
}

const nameFilter = ref('');
const startDate = ref<null | string>(null);
const endDate = ref<null | string>(null);
const filteredAssignments = computed(() => {
  return assignments.value.filter(
    a =>
      a.name.toLowerCase().includes(nameFilter.value.toLowerCase()) &&
      ((startDate.value === null && endDate.value === null) || a.dueDate) &&
      dateIsInclusiveBetween(a.dueDate, startDate.value, endDate.value)
  );
});
const clearFilters = () => {
  startDate.value = null;
  endDate.value = null;
};

const router = useRouter();
function add() {
  router.push({
    name: 'CohortAssignmentCreatePage',
  });
}

async function updateVisibility(showAssignments: boolean) {
  await api.put(`cohorts/${selectedCohort.value.id}/show-assignments`, { showAssignments });
  updateSelectedCohortShowAssignments(showAssignments);
}

const deleteDialog = ref({
  active: false,
  url: '',
  name: '',
  deletingStudentData: false,
  statusCounts: [] as {
    status: StudentAssignmentStatus;
    count: number;
  }[],
});
const triggerBackgroundLoad = ref(false);
const {
  action: deleteAssignment,
  processing: deleteProcessing,
  error: deleteError,
} = withProcessingAndError(async (assignmentId: number) => {
  const r = await api.get<AssignmentFull>(`/assignments/${assignmentId}`);

  deleteDialog.value = {
    active: true,
    url: `/assignments/${assignmentId}`,
    name: r.data.name,
    deletingStudentData: r.data.studentAssignments.some(sa => sa.status !== 'not_started'),
    statusCounts: studentAssignmentStatuses.map(status => ({
      status,
      count: r.data.studentAssignments.filter(sa => sa.status === status).length,
    })),
  };
});
</script>
