<template>
  <div>
    <mosaic-tab-card-page
      object-type="Reflections"
      :object-type-is-plural="true"
      title="Reflections"
      :headers="tabHeaders"
      :load="loadReflections"
      type="table"
      @tab-selected="tab = $event"
    >
      <template #monitoring-tab-item>
        <div class="d-flex align-center mb-6">
          <mosaic-cohort-monitoring-filters
            :students="selectedCohortStudents"
            @update:filtered-student-ids="filteredStudentIds = $event"
          />
          <monitoring-placement-select
            v-if="hasPlacements"
            v-model:selected-placement-id="selectedPlacementId"
            class="ml-4"
          />
        </div>

        <mosaic-cohort-weekly-monitoring-filter-table
          v-if="selectedPlacement"
          :placement-id="selectedPlacementId"
          object-type-pluralised="Reflections"
          :rows="monitoringRows"
          :headers="selectedPlacement.headers"
          :name-click-route="nameClickRoute"
        />

        <div v-else class="py-4">
          This Cohort does not have any Monitoring Windows configured. These can be added/updated in Cohort Settings.
        </div>
      </template>

      <template #template-tab-item>
        <mosaic-configure-preview>
          <template #configure>
            <div>
              Reflections within Mosaic can have their structure customised by changing this template. Changes to the
              template will only apply to future Reflections.
            </div>
            <mosaic-content-layout-builder
              v-model:content-layout="template"
              :supported-item-types="[
                'columns',
                'comment',
                'fileUpload',
                'image',
                'link',
                'resource',
                'taskList',
                'text',
                'video',
                'youtube',
              ]"
              :presign-url="`/presign/institutions/${selectedInstitution.id}/reflection-template-resources`"
              get-resource-url-prefix="/reflection-templates/resources"
              :save-resource-url="`/institutions/${selectedInstitution.id}/reflection-templates/resources`"
              :save-resource-params="{ reflectionTemplateId: selectedCohort.reflection_templates[0]?.id }"
              @valid="templateValid = $event"
            />
            <div class="pb-1">
              <mosaic-save-buttons
                :save="saveTemplate"
                object-type="Reflection Template"
                hide-return
                :can-save="canSave"
              />
            </div>
          </template>

          <template #preview>
            <div class="pa-1">
              <reflection-card
                reflection-url="x"
                :reflection="undefined"
                :return-to="{}"
                :editable="true"
                :template-content-layout="template"
                preview
              />
            </div>
          </template>
        </mosaic-configure-preview>
      </template>
    </mosaic-tab-card-page>

    <unsaved-changes-dialog
      v-model:unsaved-changes-dialog="dialog"
      object-type="Reflection Template"
      :can-save="canSave"
      :save="saveTemplate"
    />
  </div>
</template>

<script setup lang="ts">
import { useCohortStore } from '@/stores/cohort';
import { setBreadcrumbs } from '@/utils/breadcrumbs';
import { computed, ref } from 'vue';
import type { Row } from '@/components/monitoring/mosaic-table';
import MosaicCohortWeeklyMonitoringFilterTable from '@/components/monitoring/MosaicCohortWeeklyMonitoringFilterTable.vue';
import type { BaseStudentRow, MonitoringPlacement } from '../utils/monitoring';
import { useWeeklyMonitoring } from '../utils/monitoring';
import MonitoringPlacementSelect from '@/components/monitoring/MonitoringPlacementSelect.vue';
import { useApi } from '@/composables/api';
import { hasPermissionForSelectedInstitution } from '@/composables/permission';
import { useInstitutionStore } from '@/stores/institution';
import { fromSnakeCaseToCamelCase } from '@/utils/transforms';
import type { ContentLayout } from '@/utils/content-layout';
import { useUnsavedChanges } from '@/composables/unsaved-changes';
import MosaicContentLayoutBuilder from '@/components/mosaic-content-layout/MosaicContentLayoutBuilder.vue';
import UnsavedChangesDialog from '@/components/UnsavedChangesDialog.vue';
import ReflectionCard from '@/pages/reflections/ReflectionCard.vue';

const api = useApi();
const {
  hasPlacements,
  selectedCohort,
  selectedCohortStudents,
  actions: { updateSelectedCohort },
} = useCohortStore();
const { selectedInstitution } = useInstitutionStore();

//vPage Setup

setBreadcrumbs([
  {
    text: 'Development Reflections',
  },
]);

type ReflectionCount = Record<string, number>;
type StudentRow = BaseStudentRow & ReflectionCount;

// Tabs
const tabHeaders = computed(() => {
  const h = [
    {
      text: 'Monitoring',
      key: 'monitoring',
    },
  ];

  if (hasPermissionForSelectedInstitution('Admin')) {
    h.push({
      text: 'Template',
      key: 'template',
    });
  }
  return h;
});
const tab = ref('monitoring');

// Load Reflections

type MonitoringApiResponse = MonitoringPlacement<StudentRow>[];

const placements = ref<MonitoringPlacement<StudentRow>[]>([]);

async function loadReflections() {
  const response = await api.get<MonitoringApiResponse>(`/cohorts/${selectedCohort.value.id}/reflection-monitoring`);
  placements.value = response.data;
}

// Monitoring
const selectedPlacementId = ref<number>(-1);
const filteredStudentIds = ref<number[]>(selectedCohortStudents.value.map(s => s.id));

const { selectedPlacement, monitoringRows } = useWeeklyMonitoring<StudentRow>(
  placements,
  selectedPlacementId,
  filteredStudentIds
);

type Student = {
  id: number;
  name: string;
};

function nameClickRoute(row: Row) {
  return {
    name: 'ReflectionsListPage',
    params: {
      studentId: (row as Student).id.toString(),
    },
  };
}

// #region template
const cohortTemplate = computed(() =>
  fromSnakeCaseToCamelCase<ContentLayout>(selectedCohort.value.reflection_templates[0]?.content_layout)
);
const template = ref<ContentLayout>(cohortTemplate.value || { sections: [] });

const templateValid = ref(true);
const dirty = computed(() => JSON.stringify(cohortTemplate.value) != JSON.stringify(template.value));
const canSave = computed(() => dirty.value && templateValid.value);

async function saveTemplate() {
  const t = template.value;
  await api.put(`/cohorts/${selectedCohort.value.id}/reflection-template`, {
    contentLayout: t,
  });
  updateSelectedCohort({
    reflection_templates: [{ ...selectedCohort.value.reflection_templates[0], content_layout: t }],
  });
}
// #endregion

const { dialog } = useUnsavedChanges(dirty);
</script>
