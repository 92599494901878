<template>
  <ndt-dialog
    v-model:active="activeNdtDialog"
    :title="`Upload file to ${uploadToText}`"
    :on-close="close"
    :error-message="errorMessage"
    close-button-text="Done"
    :width="950"
  >
    <mosaic-file-pond-external-storage
      v-if="folder.uploadDirectToExternalStorage"
      ref="filepond"
      :get-upload-url-and-headers="getUploadUrlAndHeaders"
      @file-uploaded="fileUploaded"
      @file-count-updated="fileCountUpdated"
    ></mosaic-file-pond-external-storage>
    <mosaic-file-pond
      v-else
      ref="filepond"
      :url="fileUploadUrl"
      :upload-data="uploadData"
      @file-count-updated="fileCountUpdated"
      @file-uploaded="fileUploaded"
    ></mosaic-file-pond>
    <mosaic-checkbox
      v-if="fileCount == 1 && canAddAsEvidence"
      v-model="addAsEvidence"
      no-icon
      :label="`Add as evidence`"
    />

    <template v-if="addAsEvidence">
      <EvidenceAttributes :title="title" :evidence-types="evidenceTypes" @attributes-updated="attributesUpdated" />
    </template>
    <template #buttons>
      <v-btn
        v-if="addAsEvidence"
        variant="text"
        ripple
        :disabled="processing || !canSubmit"
        @click.prevent="submitAddFilesCreateEvidence()"
      >
        Create Evidence
      </v-btn>
    </template>
  </ndt-dialog>
</template>

<script>
import NdtDialog from '../NdtDialog.vue';
import EvidenceAttributes from './EvidenceAttributes.vue';
import { fileEvidenceTypes } from '../../models/evidence-types';
import MosaicFilePond from '../file-pond/MosaicFilePond.vue';
import MosaicFilePondExternalStorage from '../file-pond/MosaicFilePondExternalStorage.vue';

export default {
  name: 'AddFilesDialog',
  components: { NdtDialog, EvidenceAttributes, MosaicFilePond, MosaicFilePondExternalStorage },
  props: {
    active: {
      type: Boolean,
      required: true,
    },
    onClose: {
      type: Function,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      default: () => {},
    },
    folder: {
      type: Object,
      required: true,
    },
    canAddAsEvidence: {
      type: Boolean,
      required: true,
    },
    uploadToText: {
      type: String,
      default: null,
    },
  },
  emits: ['update:active'],
  data() {
    return {
      activeNdtDialog: false,
      fileId: '',
      title: '',
      evidenceType: '',
      evidenceDescription: '',
      selectedSubstandards: [],
      processing: false,
      errorMessage: '',
      fileCount: 0,
      addAsEvidence: false,
      evidenceTypes: fileEvidenceTypes,
    };
  },
  computed: {
    canSubmit() {
      return this.fileId && this.title && this.selectedSubstandards.length && this.evidenceType;
    },
    fileUploadUrl() {
      return this.folder.filesApiPath || '/';
    },
    uploadData() {
      return {
        folderId: this.folder.id,
        folderName: this.folder.name,
      };
    },
  },
  watch: {
    activeNdtDialog(x) {
      this.$emit('update:active', x);
    },
    active(x) {
      this.activeNdtDialog = x;
    },
  },
  methods: {
    fileCountUpdated(count) {
      this.fileCount = count;
    },
    fileUploaded(x) {
      this.title = x.title;
      this.fileId = x.fileId;
    },
    async getUploadUrlAndHeaders(cancelToken, contentType, filename) {
      const r = await this.$api.post(
        this.folder.presignFilesApiPath,
        { contentType, filename, folderId: this.folder.id },
        cancelToken
      );
      return r.data;
    },
    submitAddFilesCreateEvidence() {
      this.processing = true;
      this.errorMessage = '';
      this.$api
        .post('/evidence', {
          title: this.title,
          substandards: this.selectedSubstandards.map(s => s.code),
          file_id: this.fileId,
          evidence_type: this.evidenceType,
          evidence_description: this.evidenceDescription,
        })
        .then(() => {
          this.fileId = '';
          this.title = '';
          this.selectedSubstandards = [];
          this.evidenceType = '';
          this.evidenceDescription = '';
          this.processing = false;
          this.fileCount = 0;
          this.close();
        })
        .catch(e => {
          this.errorMessage =
            (e.response && e.response.data && e.response.data.message) || 'Sorry, cannot create evidence at the moment';
          this.processing = false;
        });
    },
    attributesUpdated(attributes) {
      this.evidenceDescription = attributes.description;
      this.title = attributes.title;
      this.evidenceType = attributes.type;
      this.selectedSubstandards = attributes.selectedSubstandards;
    },
    close() {
      this.$refs.filepond.onClose();
      this.activeNdtDialog = false;
      this.addAsEvidence = false;
      this.fileId = '';
      this.title = '';
      this.selectedSubstandards = [];
      this.evidenceType = '';
      this.evidenceDescription = '';
      this.onClose();
    },
  },
};
</script>
