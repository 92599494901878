<template>
  <div style="border: 1px solid #ccc" class="px-3">
    <div class="py-2">
      <span>{{ files.length === 0 ? 'No' : files.length }} {{ files.length === 1 ? 'file' : 'files' }} uploaded</span>
      <template v-if="minFiles !== 0">
        <span v-if="maxFiles === minFiles"> ({{ maxFiles }} expected)</span>
        <span v-else> (between {{ minFiles }} and {{ maxFiles }} expected)</span>
      </template>
    </div>
    <mosaic-file-pond-external-storage
      v-if="props.files.length < maxFiles && !readonly"
      ref="filepond"
      class="mb-5"
      :get-upload-url-and-headers="getUploadUrlAndHeaders"
      :max-files="maxFiles - fileCount"
      :allowed-content-types="allowedContentTypes"
      @file-uploaded="fileUploaded"
      @file-count-updated="fileCountUpdated"
    ></mosaic-file-pond-external-storage>
    <mosaic-error-snackbar v-model="openFileError" action="open this file" />
    <mosaic-snackbar v-model="notSaved" color="warning" message="To open this file, please save" />
    <mosaic-list v-if="files.length > 0" :items="files" class="pt-1">
      <template #item="{ item: f }">
        <mosaic-list-item icon="mdi-progress-upload" :title="f.title" @click="clickFile(f)">
          <template #actions>
            <mosaic-icon-btn
              v-if="!f.unsaved"
              icon="mdi-download"
              tooltip="Download file"
              @click.prevent="downloadFile(f.fileId)"
            />
            <mosaic-delete-icon-btn
              v-if="!readonly"
              object-type="file"
              :disabled="f.disabled"
              :disabled-tooltip="canOnlyAddFilesDisabledTooltip"
              @click.prevent="deleteFile(f)"
            />
          </template>
        </mosaic-list-item>
      </template>
    </mosaic-list>
  </div>
</template>

<script setup lang="ts">
import MosaicFilePondExternalStorage from '@/components/file-pond/MosaicFilePondExternalStorage.vue';
import { useFilePond } from '@/components/file-pond/mosaic-file-pond';
import { useOpenS3Files } from '@/utils/files';
import { watchEffect } from 'vue';
import { computed, ref } from 'vue';
import { useApi } from '@/composables/api';

const api = useApi();

export type FileDetails = {
  fileId: string;
  title: string;
  disabled?: boolean;
  unsaved?: boolean;
};

const props = defineProps<{
  files: FileDetails[];
  readonly?: boolean;
  minFiles: number;
  maxFiles: number;
  presignUrl: string;
  getFileUrlPrefix: string;
  canOnlyAddFilesDisabledTooltip?: string;
  allowedContentTypes?: string[];
}>();

const emit = defineEmits<{
  (e: 'fileAdded', file: FileDetails): void;
  (e: 'fileRemoved', file: FileDetails): void;
  (e: 'uploading', uploading: boolean): void;
}>();

const filepondFileCount = ref(0);
const fileCount = computed(() => filepondFileCount.value + props.files.length);

const filepond = ref<(HTMLElement & { removeFile(fileId: string): void }) | null>(null);

async function fileUploaded(x: { id: string; fileId: string; title: string }) {
  emit('fileAdded', { fileId: x.fileId, title: x.title, unsaved: true });

  if (filepond.value) {
    filepond.value.removeFile(x.id);
  } else {
    throw 'Cannot find filepond in FileUpload component';
  }
}

function fileCountUpdated(count: number) {
  filepondFileCount.value = count;
}
const uploading = computed(() => filepondFileCount.value > 0);
watchEffect(() => {
  emit('uploading', uploading.value);
});

const { getUploadUrlAndHeaders } = useFilePond(props.presignUrl, api);

function deleteFile(f: FileDetails) {
  emit('fileRemoved', f);
}

const notSaved = ref(false);
function clickFile(file: FileDetails) {
  if (file.unsaved) {
    notSaved.value = true;
    return;
  }
  open(file.fileId);
}
const { open, download: downloadFile, error: openFileError } = useOpenS3Files(props.getFileUrlPrefix, api);
</script>
