<template>
  <assignments-list
    :url="`/students/${selectedStudent.id}/student-assignments`"
    :click-student-assignment-to="
      sa => ({ name: 'TutorAssignmentLatestVersionPage', params: { id: sa.assignment.id.toString() } })
    "
    :show-bud="showBud"
  />
</template>

<script setup lang="ts">
import { setBreadcrumbs } from '@/utils/breadcrumbs';
import AssignmentsList from './AssignmentsList.vue';
import { mapState } from '@/store/map-store';
import { computed } from 'vue';
import { useStaffStore } from '@/stores/staff';

const { selectedStudent, selectedInstitution } = mapState();
const {
  actions: { userStaffHasPermissionForSelectedStudent },
} = useStaffStore();

const showBud = computed(
  () =>
    selectedInstitution.value?.config.use_bud_integration &&
    userStaffHasPermissionForSelectedStudent('student.assignments.markAsReviewed')
);
setBreadcrumbs([
  {
    text: 'Course Activities',
  },
]);
</script>
