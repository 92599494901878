<template>
  <ndt-dialog v-model:active="activeNdtDialog" title="Edit Resource" :error-message="errorMessage">
    <v-text-field v-model="title" name="title" label="Title" type="text" />
    <v-textarea
      v-model="description"
      name="description"
      variant="filled"
      label="Description"
      auto-grow
      type="text"
      rows="2"
      :placeholder="descriptionPlaceholder"
    />
    <template #buttons>
      <v-btn variant="text" ripple :disabled="!canSubmit" @click.prevent="submitEditResource()">Update</v-btn>
    </template>
  </ndt-dialog>
</template>

<script>
import NdtDialog from '../NdtDialog.vue';

export default {
  name: 'EditResourceDialog',
  components: { NdtDialog },
  props: {
    active: {
      type: Boolean,
      required: true,
    },
    resource: {
      type: Object,
      required: true,
    },
  },
  emits: ['update:active', 'resourceEdited'],
  data: function () {
    return {
      activeNdtDialog: false,
      title: this.resource?.title,
      description: this.resource?.description,
      processing: false,
      errorMessage: '',
    };
  },
  computed: {
    descriptionPlaceholder() {
      return `Why should ${this.traineeNoun(true)} engage with this resource?`;
    },
    canSubmit() {
      return this.title;
    },
  },
  watch: {
    activeNdtDialog(x) {
      this.$emit('update:active', x);
    },
    active(x) {
      this.activeNdtDialog = x;
    },
    resource(r) {
      this.title = r.title;
      this.description = r.description;
    },
  },
  methods: {
    async submitEditResource() {
      this.$emit('resourceEdited', {
        title: this.title,
        description: this.description,
      });
      this.activeNdtDialog = false;
    },
  },
};
</script>
