import type { Ref } from 'vue';
import { computed } from 'vue';

export type AdminTargetStatus = 'live' | 'scheduled' | 'draft';

export function createReturnTo(pageName: string, scheduleInTheFuture: Ref<boolean>, status: Ref<AdminTargetStatus>) {
  return computed(() => ({
    name: pageName,
    query: {
      tab:
        pageName === 'CohortCourseTargetsPage' && status.value === 'draft'
          ? 'draft'
          : scheduleInTheFuture.value && status.value !== 'live'
          ? 'scheduled'
          : 'live',
    },
  }));
}
