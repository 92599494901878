<template>
  <div>
    <mosaic-content-layout
      v-if="curriculumStatement.practice_content_layout"
      :content-layout="curriculumStatement.practice_content_layout"
      get-resource-url-prefix="/curriculum-statements/practice-resources"
      :comments="[]"
      :tasks="[]"
    />
    <div class="text-h6 mb-2 d-flex align-center flex-wrap justify-end" style="row-gap: 8px; column-gap: 8px">
      <span class="flex-grow-1">Learning Journey</span>
      <v-btn v-if="editable" ripple @click.prevent="$emit('clickAddTarget')">
        <div class="d-flex align-center">
          <v-icon>mdi-plus</v-icon>
          <span>Target</span>
        </div>
      </v-btn>
      <v-btn
        v-if="selectedInstitution.config.students_can_add_reflections && editable"
        ripple
        @click.prevent="$emit('clickAddReflection')"
      >
        <div class="d-flex align-center">
          <v-icon>mdi-plus</v-icon>
          <span>Reflection</span>
        </div>
      </v-btn>
      <v-btn
        v-if="selectedInstitution.config.show_lesson_observations && editable"
        ripple
        @click.prevent="$emit('clickAddLessonObservation')"
      >
        <div class="d-flex align-center">
          <v-icon>mdi-plus</v-icon>
          <span>Lesson Observation</span>
        </div>
      </v-btn>
      <v-btn
        v-if="
          selectedInstitution.config.show_mentor_meetings &&
          selectedInstitution.config.mentor_meetings_link_to_curriculum &&
          editable
        "
        ripple
        @click.prevent="$emit('clickAddMentorMeeting')"
      >
        <div class="d-flex align-center">
          <v-icon>mdi-plus</v-icon>
          <span>Mentor Meeting</span>
        </div>
      </v-btn>
    </div>
    <div>
      <mosaic-list :items="learningJourneyItems">
        <template #item="{ item }">
          <mosaic-list-item
            :to="clickTo(item)"
            :icon="item.icon"
            :title="item.title"
            :subtitle="item.date.substring(0, 10)"
            empty-state="There is nothing linked to this Curriculum Statement"
          >
            <template #information>
              <div v-if="item.type === 'target'">
                <v-chip v-if="item.status === 'active'" color="accent">Active</v-chip>
                <v-chip v-else-if="item.status === 'approved'" color="secondary">Approved</v-chip>
                <v-chip v-else color="primary">Completed</v-chip>
              </div>
              <div v-if="item.type === 'student-assignment'">
                <assignment-grade-chip
                  :status="item.status"
                  :grade="item.review_page_judgement_descriptor"
                  class="mr-2"
                />
                <assignment-status-chip :status="item.status" />
              </div>
            </template>

            <template #actions v-if="showEvidence && createEvidence && editEvidence">
              <mosaic-evidence-icon-btn
                v-model="item.evidence_id"
                @click-edit="editEvidence(item)"
                @click-create="createEvidence(item)"
              />
            </template>
          </mosaic-list-item>
        </template>
      </mosaic-list>
    </div>
  </div>
</template>

<script>
import MosaicContentLayout from '@/components/mosaic-content-layout/MosaicContentLayout.vue';
import AssignmentGradeChip from '../assignments/AssignmentGradeChip.vue';
import AssignmentStatusChip from '../assignments/AssignmentStatusChip.vue';
import { mapGetters, mapState } from 'vuex';

export default {
  components: { MosaicContentLayout, AssignmentGradeChip, AssignmentStatusChip },
  props: {
    curriculumStatement: {
      type: Object,
      default: null,
    },
    editable: {
      type: Boolean,
      required: true,
    },
    learningJourneyItems: {
      type: Array,
      required: true,
    },
    canAccessReflections: {
      type: Boolean,
      required: true,
    },
    editEvidence: {
      type: Function,
      default: null,
    },
    createEvidence: {
      type: Function,
      default: null,
    },
    clickTargetTo: {
      type: Function,
      required: true,
    },
    clickReflectionTo: {
      type: Function,
      required: true,
    },
    clickLessonObservationTo: {
      type: Function,
      required: true,
    },
    clickMentorMeetingTo: {
      type: Function,
      required: true,
    },
    clickStudentAssignmentTo: {
      type: Function,
      required: true,
    },
  },
  emits: ['clickAddTarget', 'clickAddReflection', 'clickAddLessonObservation', 'clickAddMentorMeeting'],
  computed: {
    ...mapGetters(['showEvidence']),
    ...mapState(['selectedInstitution']),
  },
  methods: {
    clickTo(item) {
      if (item.type === 'target') return this.clickTargetTo(item);
      if (item.type === 'reflection' && this.canAccessReflections) return this.clickReflectionTo(item);
      if (item.type === 'lesson-observation') return this.clickLessonObservationTo(item);
      if (item.type === 'mentor-meeting') return this.clickMentorMeetingTo(item);
      if (item.type === 'student-assignment') return this.clickStudentAssignmentTo(item);
    },
  },
};
</script>
