<template>
  <mosaic-loading-card v-if="loading || loadingStorageConstraints" type="list" />
  <mosaic-load-error-card v-else-if="loadError || loadStorageContraintsError" object-type="portfolio" @retry="load" />
  <portfolio-standard
    v-else
    :standard="standard"
    :standard-evidence="standardEvidence"
    :can-view-reflections="userStaffHasPermissionForSelectedStudent('student.reflections.view')"
    :storage-constraint="storageConstraint"
    :has-storage-email="!!userStaff.storage_email"
    @click-target="clickTarget"
    @click-reflection="clickReflection"
    @click-lesson-observation="clickLessonObservation"
    @click-mentor-meeting="clickMentorMeeting"
    @click-student-assignment="clickStudentAssignment"
  >
  </portfolio-standard>
</template>

<script>
import PortfolioStandard from '../../components/PortfolioStandard.vue';
import portfolioService from '../../utils/portfolio-service';
import { mapState } from 'vuex';

export default {
  name: 'TutorPorfolioStandardPage',
  data() {
    return {
      studentId: null,
      standardId: null,
      standard: null,
      standardEvidence: null,
      loadError: false,
      loading: true,
      loadingStorageConstraints: false,
      loadStorageContraintsError: false,
      storageConstraint: null,
    };
  },
  async created() {
    this.studentId = this.$route.params.studentId;
    this.standardId = this.$route.params.standardId;
    await this.load();
  },
  computed: {
    ...mapState(['user', 'selectedStudent', 'selectedInstitution', 'userStaff']),
    breadcrumbs() {
      return [
        {
          text: 'Portfolio',
          to: {
            name: 'TutorPortfolioOverviewPage',
          },
        },
        {
          text: this.standard?.code,
        },
      ];
    },
  },
  watch: {
    $route: {
      handler(to) {
        this.standardId = to.params.standardId;
        this.studentId = to.params.studentId;
        this.getStandards();
      },
      deep: true,
    },
  },
  methods: {
    async load() {
      await Promise.all([this.getStandards(), this.loadStorageContraints()]);
    },
    async getStandards() {
      this.loading = true;
      this.loadError = false;
      try {
        const r = await this.$api.get(`/students/${this.studentId}/portfolio/${this.standardId}`);
        this.standard = r.data;
        const evidence = await portfolioService.getStandardEvidenceSummary(
          this.standard.code,
          this.studentId,
          this.$api
        );
        this.standardEvidence = evidence;
      } catch (e) {
        console.log(e);
        this.loadError = true;
      }
      this.loading = false;
    },
    async loadStorageContraints() {
      if (this.selectedInstitution.storage_type === 'One Drive' && !this.userStaff.storage_email) {
        this.loadingStorageConstraints = true;
        this.loadStorageContraintsError = false;
        try {
          const r = await this.$api.get(`/institution-storage-constraints/${this.userStaff.id}`);
          this.storageConstraint = r.data;
        } catch (e) {
          console.log(e);
          this.loadStorageContraintsError = true;
        }
        this.loadingStorageConstraints = false;
      }
    },
    clickReflection(reflection) {
      this.$router.push({
        name: 'ReflectionPage',
        params: { studentId: this.selectedStudent.id, reflectionId: reflection.id },
      });
    },
    clickTarget(target) {
      this.$router.push({
        name: 'TargetPage',
        params: { studentId: this.selectedStudent.id, targetId: target.id },
      });
    },
    clickLessonObservation(lo) {
      this.$router.push({
        name: 'LessonObservationPage',
        params: { studentId: this.selectedStudent.id, id: lo.id },
      });
    },
    clickMentorMeeting(meeting) {
      this.$router.push({
        name: 'MentorMeetingPage',
        params: { studentId: this.selectedStudent.id, id: meeting.id },
      });
    },
    clickStudentAssignment(sa) {
      this.$router.push({
        name: 'TutorAssignmentLatestVersionPage',
        params: { id: sa.assignment_id },
      });
    },
  },
  components: { PortfolioStandard },
};
</script>
