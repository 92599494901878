<template>
  <div>
    <v-card v-if="(stateError || ectError) && !((stateProcessing && !selectedSchool) || busy)" ref="errorCard">
      <v-card-text>
        <mosaic-error-alert :action="`load this ${ectError ? 'ECT' : 'page'}`" :error="stateError" class="mb-2" />
      </v-card-text>
    </v-card>
    <div v-if="pageReady">
      <mosaic-alert v-if="approving && ect.status === 'awaiting_qts'" type="info" class="mb-2">
        This {{ traineeNounCapitalised() }} has not had their QTS checked yet. This happens automatically in the
        background and should happen within an hour.
      </mosaic-alert>
      <mosaic-alert v-if="approving && ect.status === 'does_not_have_qts'" type="warning" class="mb-2">
        <div>This {{ traineeNoun() }} does not have QTS. You should verify this on the TRA portal.</div>
      </mosaic-alert>
      <mosaic-alert v-if="ect && ect.status === 'teacher_not_found'" type="warning" class="mb-2">
        <div>
          This {{ traineeNoun() }} was not found on the TRA portal. You should check their TRN and date of birth and
          retry the QTS check.
        </div>
      </mosaic-alert>
      <mosaic-alert v-if="ect && approving && anyTrnConflictForApprovers" type="warning" class="mb-2">
        This TRN matches another ECT in your institution. If this is due to a school change, you can approve and
        transfer this data onto the existing ECT.
      </mosaic-alert>

      <v-card class="mb-4">
        <v-stepper v-model="step" editable hide-actions @update:model-value="validateOnLeave()">
          <v-stepper-header>
            <v-stepper-item :complete="complete1" editable :value="1" style="cursor: pointer"
              >Choose Package</v-stepper-item
            >
            <template v-if="selectedPackage !== 2">
              <v-divider></v-divider>
              <v-stepper-item :complete="complete2" :value="2" :editable="canContinue1 || approving"
                >School</v-stepper-item
              >
              <v-divider></v-divider>
              <v-stepper-item
                :complete="complete3"
                :value="3"
                :editable="canContinue2 || approving"
                :rules="stepperItemRules(3)"
                >ECT
              </v-stepper-item>
              <v-divider></v-divider>
              <v-stepper-item
                :complete="complete4"
                :value="4"
                :editable="canContinue3 || approving"
                :rules="stepperItemRules(4)"
                >Contract</v-stepper-item
              >
              <v-divider></v-divider>
              <v-stepper-item
                :complete="complete5"
                :value="5"
                :editable="canContinue4 || approving"
                :rules="stepperItemRules(5)"
                >Training</v-stepper-item
              >
              <v-divider></v-divider>
              <v-stepper-item
                :value="6"
                :complete="complete6"
                :editable="canContinue5 || approving"
                :rules="stepperItemRules(6)"
                >Induction Support</v-stepper-item
              >
              <v-divider></v-divider>
              <v-stepper-item :value="7" :complete="complete7" :editable="canContinue6 || approving"
                >Confirmation</v-stepper-item
              >
            </template>
          </v-stepper-header>
          <v-stepper-window>
            <v-stepper-window-item eager :value="1">
              <mosaic-ect-registration-stepper-content
                :ect="ect"
                :approving="approving"
                :action-error="actionError"
                :step="1"
                hide-save
                @back="back()"
              >
                <mosaic-card-subheading>Choose Package</mosaic-card-subheading>
                <v-radio-group v-model="selectedPackage" class="ml-4 mt-4 package-radios">
                  <div v-for="(ect_package, index) in ectPackages" :key="index" class="mb-4">
                    <v-radio :value="index">
                      <template #label>
                        <div
                          :class="index === selectedPackage ? 'selected-package-box' : 'package-box'"
                          class="flex-grow-1 flex-shrink-0"
                        >
                          <div>
                            <strong>{{ ect_package.name }}</strong>
                          </div>
                          <div>{{ ect_package.description }}</div>
                        </div>
                      </template>
                    </v-radio>
                  </div>
                </v-radio-group>

                <div class="pt-2 px-4 helper-text">
                  Please remember in all instances above, schools are also required to report ECF programme details to
                  the DfE via their online service. You can view their step-by-step guidance on the
                  <a href="https://manage-training-for-early-career-teachers.education.gov.uk/" target="_blank"
                    >GOV.UK website</a
                  >.
                </div>
                <template v-if="selectedPackage !== 2" #buttons>
                  <div data-test-name="buttons1">
                    <v-btn
                      color="primary"
                      :disabled="!canContinue1"
                      class="mr-2"
                      @click.prevent="validateAndContinue(2)"
                    >
                      Continue
                    </v-btn>
                  </div>
                </template>
                <template v-else #customAlert>
                  <v-alert type="info" variant="outlined" class="mt-4"
                    >Mosaic is not currently being used for ECF registration. Please visit the
                    <a v-if="selectedInstitution.ecf_web_url" :href="selectedInstitution.ecf_web_url" target="_blank">
                      {{ selectedInstitution.name }} website</a
                    >
                    <span v-else> {{ selectedInstitution.name }} website</span> instead for ECF registration.</v-alert
                  >
                </template>
              </mosaic-ect-registration-stepper-content></v-stepper-window-item
            >
            <v-stepper-window-item eager :value="2">
              <mosaic-ect-registration-stepper-content
                ref="step2"
                :ect="ect"
                :approving="approving"
                :action-error="actionError"
                :save-processing="saveProcessing"
                :step="2"
                @back="back()"
                @save="saveSchool()"
              >
                <mosaic-form :ref="stepFormMap[2].name" v-if="selectedSchool">
                  <div class="pb-2">Please confirm your school details below are correct:</div>
                  <mosaic-text-field
                    v-model="selectedSchool.name"
                    :readonly="true"
                    prepend-icon="mdi-domain"
                    name="name"
                    label="Name"
                    required
                  />
                  <mosaic-select
                    v-model="schoolSettingType"
                    :readonly="!!selectedSchool.settingType"
                    name="setting-type"
                    label="Setting type"
                    :items="settingTypes"
                    prepend-icon="mdi-shape"
                    required
                  />
                  <template v-if="isBrightFuturesAB">
                    <mosaic-select
                      v-model="schoolMAT"
                      :readonly="!!selectedSchool.mat"
                      name="mat-select"
                      label="Multi-academy Trust (N/A if no MAT)"
                      :items="brightFuturesMATs"
                      prepend-icon="mdi-office-building"
                      required
                    />
                    <mosaic-text-field
                      v-if="schoolMAT === 'Other'"
                      v-model="schoolMatOther"
                      :readonly="!!selectedSchool.mat"
                      prepend-icon="mdi-office-building"
                      name="mat-other"
                      label="Other Multi-academy Trust"
                      required
                    />
                  </template>
                  <mosaic-text-field
                    v-else
                    v-model="schoolMAT"
                    :readonly="!!selectedSchool.mat"
                    prepend-icon="mdi-office-building"
                    name="mat"
                    label="Multi-academy Trust (N/A if no MAT)"
                    required
                  />

                  <mosaic-text-field
                    v-model="schoolURN"
                    :readonly="!!selectedSchool.urn"
                    prepend-icon="mdi-pound"
                    name="urn"
                    label="URN"
                    required
                  />
                  <mosaic-text-field
                    v-model="addressFirstLine"
                    :readonly="!!selectedSchool.addressFirstLine"
                    prepend-icon="mdi-postage-stamp"
                    name="address-1"
                    label="Address Line 1"
                    required
                  />
                  <mosaic-text-field
                    v-model="addressSecondLine"
                    :readonly="!!(selectedSchool.addressSecondLine || selectedSchool.addressFirstLine)"
                    name="address-2"
                    label="Address Line 2"
                  />
                  <mosaic-text-field
                    v-model="addressCity"
                    :readonly="!!selectedSchool.addressCity"
                    name="address-city"
                    label="City"
                    required
                  />
                  <mosaic-text-field
                    v-model="addressPostcode"
                    :readonly="!!selectedSchool.addressPostcode"
                    prepend-icon
                    name="address-postcode"
                    label="Postcode"
                    required
                  />
                  <mosaic-select
                    v-model="localAuthority"
                    :readonly="!!selectedSchool.localAuthority"
                    name="local-authority"
                    label="Local Authority"
                    :items="localAuthoritiesWithOther"
                    prepend-icon="mdi-timetable"
                    required
                  />
                  <mosaic-text-field
                    v-if="localAuthority === 'Other'"
                    v-model="localAuthorityOther"
                    :readonly="!!selectedSchool.localAuthority"
                    name="local-authority-other"
                    label="Other Local Authority Name"
                    required
                  />
                  <mosaic-text-field
                    v-model="selectedSchool.addressCountry"
                    :readonly="true"
                    name="address-country"
                    label="Country"
                    required
                  />
                  <mosaic-text-field
                    v-model="phoneNumber"
                    :readonly="!!selectedSchool.phoneNumber"
                    prepend-icon="mdi-phone"
                    name="phone-number"
                    label="Phone Number"
                    required
                  />
                  <mosaic-card-subheading>Finance Details</mosaic-card-subheading>
                  <mosaic-text-field
                    v-model="schoolFinanceLeadName"
                    :readonly="!!selectedSchool.financeLeadName"
                    name="finance-name"
                    label="Finance Lead Name"
                    prepend-icon="mdi-account-cash-outline"
                    required
                  />
                  <mosaic-text-field
                    v-model="schoolFinanceEmail"
                    :readonly="!!selectedSchool.financeEmail"
                    name="finance-email"
                    label="Finance Office email (e.g. finance@...)"
                    prepend-icon="mdi-email"
                    required
                  />

                  <mosaic-select
                    v-model="schoolDetailsConfirmed"
                    name="school-details-confirmation"
                    label="Are the above school details correct?"
                    :items="schoolDetailsConfirmedItems"
                    item-value="confirmed"
                    item-title="title"
                    style="width: 45%"
                    required
                  />
                  <mosaic-text-area
                    v-if="schoolDetailsConfirmed === false"
                    v-model="schoolDetailChanges"
                    prepend-icon="mdi-text"
                    name="school-detail-changes"
                    label="Please list any changes needed here."
                    filled
                    required
                  ></mosaic-text-area>
                </mosaic-form>
                <template #buttons>
                  <div data-test-name="buttons2">
                    <v-btn color="primary" class="mr-2" @click.prevent="validateAndContinue(3)"> Continue </v-btn>
                  </div>
                </template>
              </mosaic-ect-registration-stepper-content>
            </v-stepper-window-item>
            <v-stepper-window-item eager :value="3">
              <mosaic-ect-registration-stepper-content
                :ect="ect"
                :approving="approving"
                :action-error="actionError"
                :save-processing="saveProcessing"
                :step="3"
                @back="back()"
                @save="save()"
                ><mosaic-form :ref="stepFormMap[3].name">
                  <mosaic-card-subheading>ECT details</mosaic-card-subheading>

                  <mosaic-text-field
                    v-model="name"
                    prepend-icon="mdi-account"
                    name="name"
                    label="Full Name (First and last name only e.g John Smith)"
                    required
                  />
                  <v-form ref="emailField">
                    <mosaic-text-field
                      v-model="email"
                      prepend-icon="mdi-email"
                      name="email"
                      label="Email (e.g. the ECT's work email address)"
                      :rules="[isValidEmail, notExistingEmail]"
                      required
                      @update:focused="$event => (!$event ? checkForExistingEmails() : null)"
                  /></v-form>
                  <mosaic-date-picker
                    v-model:date="dateOfBirth"
                    :readonly="hasQts"
                    name="date-of-birth"
                    label="Date of birth"
                    view-mode="year"
                    required
                    :exact-width="false"
                  />
                  <div v-if="!hasQts" class="d-flex">
                    <mosaic-text-field
                      v-model="trn"
                      prepend-icon="mdi-pound"
                      name="trn"
                      label="TRN"
                      :rules="(trainedOutsideEngland ? [] : schoolTrnRules).concat([notExistingTrn])"
                      :disabled="trainedOutsideEngland"
                      style="width: 250px"
                      :required="!trainedOutsideEngland"
                    />
                    <mosaic-checkbox
                      v-model="trainedOutsideEngland"
                      class="ml-2"
                      name="trained-outside-england"
                      label="Did the ECT train outside of England?"
                      :no-icon="true"
                    />
                  </div>

                  <div v-else class="d-flex align-center">
                    <mosaic-text-field
                      :readonly="true"
                      prepend-icon="mdi-pound"
                      name="trn"
                      label="TRN"
                      :model-value="trn"
                    />
                    <v-alert class="ml-8 mt-3" variant="outlined" density="compact" type="success"
                      >This {{ traineeNoun() }} has been awarded QTS</v-alert
                    >
                  </div>
                  <div class="px-8 mb-4 helper-text">
                    <strong>N.B.</strong> Having a teacher reference number (TRN) does not necessarily mean a teacher
                    has Qualified Teacher Status (QTS). TRN's are issued at the beginning of their teacher training with
                    QTS being awarded by the Teaching Regulation Agency (TRA) following successful completion of their
                    ITT. If a teacher needs a reminder of their TRN, they can visit the
                    <a href="https://www.gov.uk/guidance/teacher-reference-number-trn" target="_blank"
                      >GOV.UK website</a
                    >
                    for further information.
                  </div>
                  <mosaic-date-picker
                    v-model:date="qtsDate"
                    :readonly="hasQts"
                    name="qts-date"
                    label="Date awarded QTS"
                    :disabled="trainedOutsideEngland"
                    :max="customStartDate"
                    view-mode="month"
                    required
                    :exact-width="false"
                  />
                  <div class="px-8 mb-8 helper-text">
                    <strong>N.B.</strong> Schools must verify QTS by carrying out the correct teacher status checks. If
                    a teacher does not have QTS, they are not an ECT and therefore cannot undergo ECT induction. Further
                    information regarding teacher status checks can be found on the
                    <a
                      href="https://www.gov.uk/guidance/teacher-status-checks-information-for-employers"
                      target="_blank"
                      >GOV.UK website</a
                    >. If a teacher needs to provide proof of qualifications/QTS certificate they can obtain these
                    details from the government
                    <a href="https://teacherservices.education.gov.uk/SelfService/Login" target="_blank"
                      >teacher self-service portal</a
                    >.
                  </div>
                  <mosaic-date-picker
                    v-model:date="customStartDate"
                    name="custom-start-date"
                    label="Induction start date at school"
                    :min="qtsDate"
                    view-mode="month"
                    required
                    :exact-width="false"
                  />
                  <mosaic-card-subheading class="py-2">Previous Induction details</mosaic-card-subheading>
                  <mosaic-text-field
                    v-model="termsAlreadyCompleted"
                    prepend-icon="mdi-clipboard-text-clock"
                    name="terms-already-completed"
                    label="FTE terms of induction completed prior to joining your school"
                    type="number"
                    :rules="termsCompletedRules"
                    required
                  />
                  <mosaic-text-area
                    v-model="previousInductionPeriods"
                    prepend-icon="mdi-text"
                    name="previous-induction-periods"
                    label="Where known/applicable, please list details of any previous induction periods"
                    filled
                    :required="termsAlreadyCompleted > 0"
                  ></mosaic-text-area>
                  <template v-if="termsAlreadyCompleted > 0">
                    <div class="pl-8 helper-text">
                      Have you obtained copies of the previous induction progress reviews/formal assessments from the
                      previous appropriate body?
                    </div>
                    <mosaic-select
                      v-if="termsAlreadyCompleted > 0"
                      v-model="priorReviewsObtained"
                      name="prior-reviews-obtained"
                      label="Prior reviews/assessments obtained?"
                      :items="priorReviewItems"
                      item-title="descriptor"
                      item-value="reviewsObtained"
                      prepend-icon="mdi-circle-check-outline"
                      :required="termsAlreadyCompleted > 0"
                    />
                  </template>
                  <mosaic-error-alert :action="retryQtsErrorAction" :error="!!retryQtsErrorAction" />
                </mosaic-form>
                <template #buttons>
                  <v-btn
                    v-if="retryQtsAvailable"
                    color="primary"
                    :disabled="!canRetryQts"
                    :loading="retryQtsProcessing"
                    class="mr-2"
                    @click.prevent="retryQts()"
                  >
                    Retry QTS Check
                  </v-btn>
                  <v-btn
                    class="mr-2"
                    :color="retryQtsAvailable ? '' : 'primary'"
                    @click.prevent="validateAndContinue(4)"
                  >
                    Continue
                  </v-btn>
                </template>
              </mosaic-ect-registration-stepper-content></v-stepper-window-item
            >
            <v-stepper-window-item eager :value="4">
              <mosaic-ect-registration-stepper-content
                :ect="ect"
                :approving="approving"
                :action-error="actionError"
                :save-processing="saveProcessing"
                :step="4"
                @back="back()"
                @save="save()"
              >
                <mosaic-form :ref="stepFormMap[4].name">
                  <mosaic-card-subheading class="py-2">Contract details</mosaic-card-subheading>
                  <div class="helper-text px-2 pb-4">
                    <div>Please Note:</div>
                    <div>
                      Only teaching contracts are eligible for induction to take place and each post must meet the
                      minimum requirements set out by the DfE on pages 20 and 21 of
                      <a
                        href="https://www.gov.uk/government/publications/induction-for-early-career-teachers-england"
                        target="_blank"
                        >statutory guidance</a
                      >.
                    </div>
                    <div class="pt-2">
                      The minimum period of employment that can count towards induction is 1 full term if full time. If
                      part-time, it's Full Time Equivalent (FTE).
                    </div>
                    <div class="pt-2">
                      As an example, if an ECT is on a 0.5 contract (teaching 2.5 days a week), 2 full school terms
                      would be the minimum period that could be counted towards induction as it is not possible to
                      fairly assess an ECT in anything less than a full term. Part-time ECTs would be required to
                      complete the FTE of a 2-year statutory induction period.
                    </div>
                  </div>
                  <mosaic-select
                    v-model="employmentType"
                    name="employment-type"
                    label="Employment Type"
                    :items="employmentTypeOptions"
                    prepend-icon="mdi-timetable"
                    required
                  />
                  <mosaic-select
                    v-if="employmentType === 'Part Time'"
                    v-model="ptFteRate"
                    name="pt-fte-rate"
                    label="Full time equivalent (FTE) fraction"
                    :items="partTimeOptions"
                    item-title="descriptor"
                    item-value="rate"
                    prepend-icon="mdi-timetable"
                    return-object
                    :required="employmentType === 'Part Time'"
                  />

                  <mosaic-select
                    v-model="contractType"
                    name="contract-type"
                    label="Contract type"
                    :items="contractTypeItems"
                    prepend-icon="mdi-file-sign"
                    required
                  />
                  <div class="helper-text pl-2 pb-2">
                    Please note: The minimum period of employment that can count towards induction for a full time ECT
                    is 1 full term (FTE if part-time). If temporary or supply, this cannot be a series of short-term
                    contracts adding up to a term as induction must be planned for in advance.
                  </div>

                  <div v-if="contractType && contractType !== 'Permanent'" class="pl-6">
                    <mosaic-date-picker
                      v-model:date="contractStartDate"
                      name="contract-start"
                      label="Contract start date"
                      :exact-width="false"
                      view-mode="month"
                      :required="contractType !== 'Permanent'"
                    />
                    <mosaic-date-picker
                      v-model:date="contractEndDate"
                      name="contract-end"
                      label="Contract end date"
                      :exact-width="false"
                      view-mode="month"
                      :required="contractType !== 'Permanent'"
                    />
                    <mosaic-text-field
                      v-model="contractDetails"
                      name="contract-details"
                      label="Other contract details"
                      prepend-icon="mdi-file-sign"
                    />
                  </div>

                  <mosaic-autocomplete
                    v-model="yearGroups"
                    name="year-groups"
                    :items="yearGroupItems"
                    multiple
                    label="Year groups being taught"
                    no-data-text="No year groups found"
                    prepend-icon="mdi-numeric"
                    required
                  />
                  <mosaic-text-field
                    v-if="yearGroups.includes('Other')"
                    v-model="otherYearGroup"
                    name="other-year-group"
                    label="Other year group, please specify"
                    prepend-icon="mdi-numeric"
                    :required="yearGroups.includes('Other')"
                  />
                  <mosaic-autocomplete
                    v-model="teachingSubjects"
                    name="teaching-subjects"
                    prepend-icon="mdi-pencil-ruler"
                    :items="ectRegistrationSubjectsIncludingOther"
                    multiple
                    label="Subjects being taught"
                    no-data-text="No subjects found"
                    required
                  />
                  <mosaic-text-field
                    v-if="teachingSubjects.includes('Other')"
                    v-model="otherTeachingSubject"
                    name="other-teaching-subject"
                    label="Other subject, please specify"
                    prepend-icon="mdi-pencil-ruler"
                    :required="teachingSubjects.includes('Other')"
                  />
                  <mosaic-card-subheading class="py-2">Confirmations</mosaic-card-subheading>
                  <mosaic-checkbox
                    v-model="dbsConfirmation"
                    name="dbs-confirmation"
                    label="I confirm the ECT has been DBS checked"
                    :disabled="approving"
                    required
                  />
                  <mosaic-checkbox
                    v-model="statutoryRequirementsConfirmed"
                    name="statutory-requirements-confirmation"
                    label="I confirm that this ECT's contract satisfies the statutory requirements"
                    :disabled="approving"
                    required
                  />
                </mosaic-form>
                <template #buttons>
                  <div data-test-name="buttons4">
                    <v-btn color="primary" @click.prevent="validateAndContinue(5)"> Continue </v-btn>
                  </div>
                </template>
              </mosaic-ect-registration-stepper-content></v-stepper-window-item
            >
            <v-stepper-window-item eager :value="5">
              <mosaic-ect-registration-stepper-content
                :ect="ect"
                :approving="approving"
                :action-error="actionError"
                :save-processing="saveProcessing"
                :step="5"
                @back="back()"
                @save="save()"
                ><mosaic-form :ref="stepFormMap[5].name">
                  <div data-test-name="stepper-5">
                    <mosaic-card-subheading class="py-2">Training Details</mosaic-card-subheading>
                    <mosaic-text-field
                      v-model="teacherTrainingInstitution"
                      name="teacher-training-institution"
                      :label="`Teacher training institution${traIttEstablishment ? ' (manually entered)' : ''}`"
                      prepend-icon="mdi-school"
                      required
                    />
                    <mosaic-text-field
                      v-if="traIttEstablishment"
                      v-model="traIttEstablishment"
                      :readonly="true"
                      name="tra-teacher-training-institution"
                      label="Teacher training institution (from TRA)"
                      prepend-icon="mdi-school"
                      required
                    />
                    <mosaic-select
                      v-model="qualificationsAwarded"
                      name="qualifications-awarded"
                      label="Qualifications awarded"
                      item-value="value"
                      item-title="title"
                      :items="
                        qualificationsAwardedItems.map(q => {
                          return { value: q, title: q };
                        })
                      "
                      multiple
                      prepend-icon="mdi-certificate"
                      required
                    />
                    <mosaic-autocomplete
                      v-model="qualifiedSubjects"
                      name="qualified-subjects"
                      prepend-icon="mdi-pencil-ruler"
                      label="Qualified subjects"
                      :items="ectRegistrationSubjectsIncludingOther"
                      no-data-text="No subjects found"
                      multiple
                      required
                    />
                    <mosaic-text-field
                      v-if="qualifiedSubjects.includes('Other')"
                      v-model="otherQualifiedSubject"
                      name="other-qualified-subject"
                      label="Other Subject, please specify"
                      prepend-icon="mdi-pencil-ruler"
                      :required="qualifiedSubjects.includes('Other')"
                    />
                    <mosaic-select
                      v-model="qualifiedAgeRanges"
                      name="qualified-age-ranges"
                      label="Qualified age ranges"
                      item-value="value"
                      item-title="title"
                      :items="
                        qualifiedAgeRangesItemsIncludingOther.map(a => {
                          return { title: a, value: a };
                        })
                      "
                      multiple
                      prepend-icon="mdi-numeric"
                      required
                    />
                    <mosaic-text-field
                      v-if="qualifiedAgeRanges.includes('Other')"
                      v-model="otherQualifiedAgeRange"
                      name="other-qualified-age-range"
                      label="Other age range, please specify"
                      prepend-icon="mdi-numeric"
                      :required="qualifiedAgeRanges.includes('Other')"
                    />
                    <mosaic-card-subheading class="py-2">ECF Programme Details</mosaic-card-subheading>
                    <div v-if="selectedPackage === null" class="helper-text pl-2">
                      You must select an ECT package to complete this section
                    </div>
                    <template v-else-if="selectedPackage === 1">
                      <mosaic-select
                        v-model="ecfProgramme"
                        item-value="value"
                        item-title="title"
                        name="ecf-programme"
                        label="ECF programme"
                        :items="ecfProgrammeItems"
                        prepend-icon="mdi-school-outline"
                        :required="selectedPackage === 1"
                      />
                      <v-alert
                        v-if="
                          (ecfProgramme === 'Core Induction Programme' ||
                            ecfProgramme === 'School-based Induction Programme') &&
                          registeringOrApproving === 'registering'
                        "
                        type="info"
                        elevation="1"
                        class="ml-8"
                      >
                        <div v-if="ecfProgramme === 'Core Induction Programme'">
                          The AB will conduct additional quality assurance for this programme
                        </div>
                        <div v-if="ecfProgramme === 'School-based Induction Programme'">
                          The AB will need to conduct a detail fidelity check for this programme
                        </div>
                      </v-alert>
                      <mosaic-select
                        v-if="ecfProgramme && ecfProgramme !== 'School-based Induction Programme'"
                        v-model="ecfProvider"
                        name="ecf-provider"
                        label="ECF provider"
                        :items="ecfProviderItems"
                        prepend-icon="mdi-school-outline"
                        :required="selectedPackage === 1 && ecfProgramme !== 'School-based Induction Programme'"
                      />
                    </template>
                    <template v-else-if="selectedPackage === 0">
                      <mosaic-select
                        v-model="ecfTermsCompletedCohort"
                        name="ecf-cohort"
                        label="ECF cohort"
                        :items="ecfTermsCompletedCohortItems"
                        item-value="termsCompleted"
                        prepend-icon="mdi-school-outline"
                        :required="selectedPackage === 0"
                      />
                    </template>
                  </div>
                </mosaic-form>
                <template #buttons>
                  <div data-test-name="buttons5">
                    <v-btn color="primary" @click.prevent="validateAndContinue(6)"> Continue </v-btn>
                  </div>
                </template>
              </mosaic-ect-registration-stepper-content></v-stepper-window-item
            >
            <v-stepper-window-item eager :value="6">
              <mosaic-ect-registration-stepper-content
                :ect="ect"
                :approving="approving"
                :action-error="actionError"
                :save-processing="saveProcessing"
                :step="6"
                @back="back()"
                @save="save()"
              >
                <mosaic-form :ref="stepFormMap[6].name">
                  <mosaic-card-subheading class="py-2">Induction Staff with Mosaic accounts</mosaic-card-subheading>
                  <div class="helper-text pl-2">
                    Assign an Induction Tutor and Headteacher/Principal to this ECT. If they do not already have a
                    Mosaic account, you will need to contact {{ selectedInstitution.name
                    }}{{ selectedInstitution.contact_email ? ` on ${selectedInstitution.contact_email}` : '' }} to set
                    up these accounts so you can proceed.
                  </div>
                  <div class="pt-2 d-flex align-center">
                    <mosaic-autocomplete
                      v-model="headteacher"
                      style="flex-basis: 45%"
                      name="headteacher"
                      label="Headteacher/Principal"
                      :items="instructorItems"
                      item-value="id"
                      item-title="text"
                      no-data-text="There are no induction staff with Mosaic accounts"
                      prepend-icon="mdi-account"
                      return-object
                      required
                    />
                  </div>
                  <v-alert
                    border="start"
                    border-color="primary"
                    color="primary"
                    class="helper-text alert-border-no-opacity"
                    variant="outlined"
                  >
                    The Headteacher/Principal is, along with the appropriate body, jointly responsible for the
                    monitoring, support, and assessment of the ECT during induction. The Head/Principal is responsible
                    for nominating suitable members of staff to fulfil the roles below ensuring sufficient time is
                    allocated (and protected) to allow them to successfully carry out the roles. More detailed
                    clarification about the Headteacher/Principal role can be found in the DfE
                    <a
                      href="https://www.gov.uk/government/publications/induction-for-early-career-teachers-england"
                      target="_blank"
                      >statutory guidance</a
                    >
                    <br />
                    <br />
                    Headteachers / Principals are required to have a Mosaic account so that they have access to progress
                    reviews and end of year assessments.
                  </v-alert>

                  <div class="pt-2 d-flex align-center">
                    <mosaic-autocomplete
                      v-model="inductionTutor"
                      style="flex-basis: 45%"
                      name="induction-tutor"
                      label="Induction Tutor"
                      :items="instructorItems"
                      item-value="id"
                      item-title="text"
                      no-data-text="There are no induction staff with Mosaic accounts"
                      prepend-icon="mdi-account"
                      return-object
                      required
                    />
                  </div>
                  <v-alert
                    border="start"
                    border-color="primary"
                    color="primary"
                    class="helper-text alert-border-no-opacity"
                    variant="outlined"
                  >
                    Please note: Induction tutors are expected to hold QTS and have the necessary skills and knowledge
                    to work successfully in this role. They are responsible for overseeing the statutory induction side
                    of things i.e. providing regular monitoring and support, carrying out formal observations of the
                    ECTs performance against the government
                    <a href="https://www.gov.uk/government/publications/teachers-standards" target="_blank"
                      >teacher standards</a
                    >, completing end of term induction progress reviews and end of year formal assessments, making
                    rigorous and fair judgements about the ECTs progress towards meeting/embedding the teacher
                    standards, taking prompt action if an ECT is experiencing difficulties and putting in place
                    additional support. More detailed clarification about this role can be found in the DfE
                    <a
                      href="https://www.gov.uk/government/publications/induction-for-early-career-teachers-england"
                      target="_blank"
                      >statutory guidance</a
                    >
                    alongside the additional guidance published by the DfE on
                    <a
                      href="https://www.gov.uk/guidance/how-to-set-up-training-for-early-career-teachers"
                      target="_blank"
                      >managing ECF-based training</a
                    >
                    <br />
                    <br />
                    Induction Tutors are required to have a Mosaic account so that they have access to and can complete
                    progress reviews and end of year assessments.
                  </v-alert>

                  <mosaic-card-subheading class="py-2">ECF Mentor Details</mosaic-card-subheading>

                  <mosaic-text-field
                    v-model="mentorName"
                    name="mentor-name"
                    label="ECF mentor name (first and last name only e.g. Jane Doe)"
                    prepend-icon="mdi-account"
                    half-width
                    required
                  />
                  <mosaic-text-field
                    v-model="mentorEmail"
                    name="mentor-email"
                    label="ECF mentor email"
                    prepend-icon="mdi-at"
                    half-width
                    :rules="[isValidEmail]"
                    required
                  />
                  <mosaic-text-field
                    v-model="mentorJobTitle"
                    name="mentor-job-title"
                    label="ECF mentor Job title/role"
                    prepend-icon="mdi-briefcase"
                    half-width
                    required
                  />
                  <template v-if="selectedPackage === 0">
                    <mosaic-text-field
                      v-model="mentorTrn"
                      prepend-icon="mdi-pound"
                      name="mentor-trn"
                      label="Mentor TRN"
                      :rules="schoolTrnRules"
                      style="width: 250px"
                      :required="selectedPackage === 0"
                    />
                    <div class="pl-8 pb-2 helper-text">
                      If this mentor has ever undertaken ECF training in the past, please provide details below of date
                      period(s) and provider(s) so that we can allocate the correct seminar programme for them. If none,
                      please write N/A.
                    </div>
                    <mosaic-text-area
                      v-model="mentorTrainingDetails"
                      prepend-icon="mdi-text"
                      name="mentor-training-details"
                      label="Mentor training details"
                      filled
                      :required="selectedPackage === 0"
                    ></mosaic-text-area>
                  </template>
                  <v-alert
                    border="start"
                    border-color="primary"
                    color="primary"
                    class="helper-text alert-border-no-opacity"
                    variant="outlined"
                  >
                    Please note: ECF mentors are expected to hold QTS and have the necessary skills and knowledge to
                    work successfully in this role. ECF mentors are responsible for supporting ECTs through the early
                    career framework (ECF) programme of professional development i.e., engaging with and where
                    applicable, participating in training as part of the ECF programme, providing
                    coaching/modelling/mentoring to the ECT as part of the ECF programme, and along with the induction
                    tutor taking prompt action if an ECT is experiencing difficulties. More detailed clarification about
                    this role can be found in the DfE
                    <a
                      href="https://www.gov.uk/government/publications/induction-for-early-career-teachers-england"
                      target="_blank"
                      >statutory guidance</a
                    >
                    alongside the additional guidance published by the DfE on
                    <a
                      href="https://www.gov.uk/guidance/how-to-set-up-training-for-early-career-teachers"
                      target="_blank"
                      >managing ECF-based training</a
                    >
                    <br />
                    <br />
                    ECF Mentors are not required to have Mosaic accounts as they are not responsible for completion of
                    induction progress reviews or formal assessments.
                  </v-alert>
                  <mosaic-card-subheading class="py-2">Induction Coordinator/Lead Details</mosaic-card-subheading>
                  <mosaic-text-field
                    v-model="coordinatorName"
                    name="coordinator-name"
                    label="Induction Coordinator/Lead Name"
                    prepend-icon="mdi-account"
                    half-width
                    required
                  />
                  <mosaic-text-field
                    v-model="coordinatorEmail"
                    name="coordinator-email"
                    label="Induction Coordinator/Lead email"
                    prepend-icon="mdi-at"
                    half-width
                    :rules="[isValidEmail]"
                    required
                  />
                  <mosaic-text-field
                    v-model="coordinatorJobTitle"
                    name="coordinator-job-title"
                    label="Induction Coordinator/Lead Job title/role"
                    prepend-icon="mdi-briefcase"
                    half-width
                    required
                  />
                  <v-alert
                    border="start"
                    border-color="primary"
                    color="primary"
                    class="helper-text alert-border-no-opacity"
                    variant="outlined"
                  >
                    This is usually one person per school who holds the login details for the
                    <a href="https://manage-training-for-early-career-teachers.education.gov.uk/" target="_blank"
                      >DfE Portal</a
                    >. It is often the same person as the Induction Tutor or the Senior Induction Tutor where the school
                    has many ECTs allocated to a range of senior staff as their Induction Tutor.
                    <br />
                    <br />
                    Induction Coordinators are not required to have Mosaic accounts.
                  </v-alert>
                </mosaic-form>
                <template #buttons>
                  <div data-test-name="buttons6">
                    <v-btn color="primary" @click.prevent="validateAndContinue(7)"> Continue </v-btn>
                  </div>
                </template>
              </mosaic-ect-registration-stepper-content>
            </v-stepper-window-item>
            <v-stepper-window-item eager :value="7">
              <mosaic-ect-registration-stepper-content
                :ect="ect"
                :approving="approving"
                :action-error="actionError"
                :save-processing="saveProcessing"
                :step="7"
                @back="back()"
                @save="save()"
              >
                <mosaic-card-subheading>Confirmation</mosaic-card-subheading>
                <div v-if="activeCohorts.length === 0 && approving" class="pb-2">
                  <v-alert type="warning" density="compact" class="mb-0">
                    Your institution currently has no active cohorts configured. Please get in touch with Mosaic support
                    to resolve this.
                  </v-alert>
                </div>
                <div class="my-2 pl-2 helper-text">Please confirm the following details:</div>
                <div class="my-2 pl-2 helper-text">School Details:</div>
                <div style="display: grid; grid-template-columns: 1fr 1fr" class="mb-4 pl-2 helper-text">
                  <div class="font-weight-bold">School Details correct?</div>
                  <mosaic-empty-state-div :text="schoolDetailsConfirmed ? 'Yes' : 'No'" />
                  <template v-if="!schoolDetailsConfirmed">
                    <div class="font-weight-bold">School detail changes</div>
                    <mosaic-empty-state-div :text="schoolDetailChanges" />
                  </template>
                </div>
                <div class="my-2 pl-2 helper-text">ECT Details:</div>
                <div style="display: grid; grid-template-columns: 1fr 1fr" class="mb-4 pl-2 helper-text">
                  <div class="font-weight-bold">Package</div>
                  <mosaic-empty-state-div :text="selectedPackage !== null ? ectPackages[selectedPackage].name : null" />
                  <div class="font-weight-bold">Name</div>
                  <mosaic-empty-state-div :text="name" />
                  <div class="font-weight-bold">Email</div>
                  <mosaic-empty-state-div :text="email" />
                  <div class="font-weight-bold">Date of birth</div>
                  <mosaic-empty-state-div :text="dateOfBirth ? formatDate(dateOfBirth) : ''" />
                  <div class="font-weight-bold">TRN</div>
                  <mosaic-empty-state-div
                    :text="trn ? trn : trainedOutsideEngland ? 'Trained outside of England' : ''"
                  />
                  <div class="font-weight-bold">Date awarded QTS</div>
                  <mosaic-empty-state-div :text="qtsDate ? formatDate(qtsDate) : ''" />
                  <div class="font-weight-bold">Start date</div>
                  <mosaic-empty-state-div :text="customStartDate ? formatDate(customStartDate) : ''" />
                  <div class="font-weight-bold">Terms of induction period already completed</div>
                  <mosaic-empty-state-div :text="termsAlreadyCompleted ? termsAlreadyCompleted : 'None'" />
                  <template v-if="termsAlreadyCompleted > 0">
                    <div class="font-weight-bold">Previous induction periods</div>
                    <mosaic-empty-state-div :text="previousInductionPeriods" />
                    <div class="font-weight-bold">Prior reviews obtained?</div>
                    <mosaic-empty-state-div :text="priorReviewsObtained ? 'Yes' : 'No'" />
                  </template>
                </div>
                <div class="my-2 pl-2 helper-text">Contract Details:</div>
                <div style="display: grid; grid-template-columns: 1fr 1fr" class="mb-4 pl-2 helper-text">
                  <div class="font-weight-bold">Employment Type</div>
                  <mosaic-empty-state-div :text="employmentType" />
                  <template v-if="employmentType === 'Part Time'">
                    <div class="font-weight-bold">Full time equivalent (FTE) fraction</div>
                    <mosaic-empty-state-div :text="ptFteRate && ptFteRate.descriptor" />
                  </template>
                  <div class="font-weight-bold">Contract type</div>
                  <mosaic-empty-state-div :text="contractType" />
                  <template v-if="contractType && contractType !== 'Permanent'">
                    <div class="font-weight-bold">Contract details</div>
                    <mosaic-empty-state-div :text="contractDetails" />

                    <div class="font-weight-bold">Contract start date</div>
                    <mosaic-empty-state-div :text="contractStartDate ? formatDate(contractStartDate) : ''" />

                    <div class="font-weight-bold">Contract end date</div>
                    <mosaic-empty-state-div :text="contractEndDate ? formatDate(contractEndDate) : ''" />
                  </template>

                  <div class="font-weight-bold">Year groups being taught</div>
                  <mosaic-empty-state-div :text="yearGroups.join(', ')" />
                  <template v-if="teachingSubjects.length > 0">
                    <div class="font-weight-bold">Subjects being taught</div>
                    <mosaic-empty-state-div :text="teachingSubjects.join(', ')" />
                  </template>
                  <div class="font-weight-bold">Has the ECT been DBS checked?</div>
                  <mosaic-empty-state-div :text="dbsConfirmation ? 'Yes' : 'No'" />
                  <div class="font-weight-bold">Contract meets statutory requirements?</div>
                  <mosaic-empty-state-div :text="statutoryRequirementsConfirmed ? 'Yes' : 'No'" />
                </div>
                <div class="my-2 pl-2 helper-text">Training Details:</div>
                <div style="display: grid; grid-template-columns: 1fr 1fr" class="mb-4 pl-2 helper-text">
                  <div class="font-weight-bold">Teacher training institution</div>
                  <mosaic-empty-state-div :text="teacherTrainingInstitution" />
                  <template v-if="qualificationsAwarded.length > 0">
                    <div class="font-weight-bold">Qualifications awarded</div>
                    <mosaic-empty-state-div :text="qualificationsAwarded.join(', ')" />
                  </template>
                  <template v-if="qualifiedSubjectsToSave.length > 0">
                    <div class="font-weight-bold">Qualified subjects</div>
                    <mosaic-empty-state-div :text="qualifiedSubjectsToSave.join(', ')" />
                  </template>
                  <template v-if="qualifiedAgeRangesToSave.length > 0">
                    <div class="font-weight-bold">Qualified age ranges</div>
                    <mosaic-empty-state-div :text="qualifiedAgeRangesToSave.join(', ')" />
                  </template>
                  <template v-if="selectedPackage === 0">
                    <div class="font-weight-bold">ECF programme</div>
                    <mosaic-empty-state-div :text="`${ecfProgramme} (${ectPackages[0].name} package)`" />
                    <template v-if="ecfTermsCompletedCohort !== null">
                      <div class="font-weight-bold">ECF cohort</div>
                      <mosaic-empty-state-div
                        :text="
                          ecfTermsCompletedCohortItems.find(x => x.termsCompleted === ecfTermsCompletedCohort).text
                        "
                      />
                    </template>
                  </template>
                  <template v-else>
                    <template v-if="ecfProgramme">
                      <div class="font-weight-bold">ECF programme</div>
                      <mosaic-empty-state-div :text="ecfProgramme" />
                    </template>
                    <template v-if="ecfProvider">
                      <div class="font-weight-bold">ECF provider</div>
                      <mosaic-empty-state-div :text="ecfProvider" />
                    </template>
                  </template>
                </div>
                <div class="my-2 pl-2 helper-text">Induction Support:</div>
                <div style="display: grid; grid-template-columns: 1fr 1fr" class="mb-4 pl-2 helper-text">
                  <div class="font-weight-bold">Induction Tutor</div>
                  <mosaic-empty-state-div :text="inductionTutor ? inductionTutor.text : null" />

                  <div class="font-weight-bold">Headteacher / Principal</div>
                  <mosaic-empty-state-div :text="headteacher ? headteacher.text : null" />

                  <div class="font-weight-bold">ECF Mentor name</div>
                  <mosaic-empty-state-div :text="mentorName" />
                  <div class="font-weight-bold">ECF Mentor email</div>
                  <mosaic-empty-state-div :text="mentorEmail" />
                  <div class="font-weight-bold">ECF Mentor job title</div>
                  <mosaic-empty-state-div :text="mentorJobTitle" />
                  <template v-if="selectedPackage === 0">
                    <div class="font-weight-bold">ECF Mentor TRN</div>
                    <mosaic-empty-state-div :text="mentorTrn" />
                    <div class="font-weight-bold">ECF Mentor training details</div>
                    <mosaic-empty-state-div :text="mentorTrainingDetails" />
                  </template>

                  <div class="font-weight-bold">Induction Coordinator/Lead name</div>
                  <mosaic-empty-state-div :text="coordinatorName" />
                  <div class="font-weight-bold">Induction Coordinator/Lead email</div>
                  <mosaic-empty-state-div :text="coordinatorEmail" />
                  <div class="font-weight-bold">Induction Coordinator/Lead job title</div>
                  <mosaic-empty-state-div :text="coordinatorJobTitle" />
                </div>
                <mosaic-card-subheading class="mt-4">Details of person filling in form</mosaic-card-subheading>
                <mosaic-form :ref="stepFormMap[7].name">
                  <mosaic-text-field
                    v-model="registeringName"
                    name="registering-name"
                    label="Your name"
                    prepend-icon="mdi-account"
                    style="width: 45%"
                    required
                  />
                  <mosaic-text-field
                    v-model="registeringEmail"
                    name="registering-email"
                    label="Your email"
                    prepend-icon="mdi-at"
                    style="width: 45%"
                    :rules="[isValidEmail]"
                    required
                  />
                  <mosaic-text-field
                    v-model="registeringRole"
                    name="registering-role"
                    label="Your role"
                    prepend-icon="mdi-briefcase"
                    style="width: 45%"
                    required
                  />
                  <mosaic-card-subheading
                    v-if="registeringOrApproving === 'registering' || selectedInstitution.ab_sla_link"
                    class="mt-4"
                    >Declarations</mosaic-card-subheading
                  >
                  <mosaic-checkbox
                    v-if="registeringOrApproving === 'registering'"
                    v-model="detailsConfirmed"
                    name="details-check"
                    label="I confirm the details in this form are correct"
                    required
                  />
                  <template v-if="selectedInstitution.ab_sla_link">
                    <div v-if="registeringOrApproving === 'registering'" class="pl-2">
                      Please read the SLA below carefully
                    </div>

                    <mosaic-icon-link
                      class="ml-6"
                      :link="selectedInstitution.ab_sla_link"
                      :label="`${selectedInstitution.name} SLA`"
                      icon="file-pdf-box"
                    ></mosaic-icon-link>
                    <mosaic-checkbox
                      v-model="slaAgreed"
                      name="sla-check"
                      label="I agree to the terms of the SLA"
                      :disabled="approving"
                      :required="registeringOrApproving === 'registering'"
                    />
                  </template>
                  <mosaic-checkbox
                    v-if="isBrightFuturesAB"
                    v-model="marketingAgreed"
                    name="marketing-check"
                    label="I agree to receiving marketing communications from Bright Futures"
                    :disabled="approving"
                  />
                  <template v-if="approving">
                    <template v-if="showCohortSelect">
                      <mosaic-card-subheading class="mb-2 mt-2"
                        >Select the cohort this ECT will belong to</mosaic-card-subheading
                      >
                      <mosaic-select
                        v-model="cohortId"
                        name="cohort"
                        label="Cohort"
                        item-value="id"
                        item-title="name"
                        :items="activeCohorts"
                        prepend-icon="mdi-google-classroom"
                        class="pl-1"
                        required
                      />
                    </template>
                    <mosaic-text-field
                      v-model="ecfCohort"
                      name="ecf-cohort"
                      label="Internal AB Cohort"
                      prepend-icon="mdi-school-outline"
                      style="width: 45%"
                    ></mosaic-text-field>
                    <template v-if="isBrightFuturesAB">
                      <mosaic-text-field
                        v-model="invoiceRef1"
                        name="invoice-ref-1"
                        label="Invoice reference 1"
                        prepend-icon="mdi-currency-gbp"
                        style="width: 45%"
                      ></mosaic-text-field>
                      <mosaic-text-field
                        v-model="invoiceRef2"
                        name="invoice-ref-2"
                        label="Invoice reference 2"
                        prepend-icon="mdi-currency-gbp"
                        style="width: 45%"
                      ></mosaic-text-field>
                    </template>
                  </template>
                </mosaic-form>
                <template #buttons>
                  <div>
                    <mosaic-btn
                      v-if="approving"
                      color="error"
                      :disabled="ect.status === 'rejected' || rejectionDialog.processing"
                      :loading="rejectionDialog.processing"
                      @click.prevent="rejectEct()"
                      class="mr-2"
                      >Reject</mosaic-btn
                    >
                    <template v-if="retryQtsAvailable">
                      <mosaic-btn
                        color="primary"
                        :disabled="!canRetryQts"
                        :loading="retryQtsProcessing"
                        @click.prevent="retryQts()"
                      >
                        Retry QTS Check
                      </mosaic-btn>
                      <template v-if="approving">
                        <mosaic-btn
                          v-if="anyTrnConflictForApprovers"
                          color="primary"
                          @click.prevent="transferRegistrationData()"
                          >Approve and Transfer</mosaic-btn
                        >
                        <mosaic-btn
                          v-else
                          :color="retryQtsAvailable ? '' : 'primary'"
                          :disabled="saveProcessing"
                          :loading="saveProcessing"
                          @click.prevent="registerOrApproveEct()"
                          >Approve</mosaic-btn
                        >
                      </template>
                    </template>
                    <template v-else>
                      <mosaic-btn
                        v-if="approving && anyTrnConflictForApprovers"
                        color="primary"
                        @click.prevent="transferRegistrationData()"
                        >Approve and Transfer</mosaic-btn
                      >
                      <mosaic-btn
                        v-else
                        color="primary"
                        :disabled="saveProcessing || registerProcessing"
                        :loading="registerProcessing"
                        @click.prevent="registerOrApproveEct()"
                      >
                        {{ approving ? 'Approve' : 'Register ECT' }}
                      </mosaic-btn>
                    </template>
                  </div>
                </template>
                <mosaic-info-alert v-if="approving && anyTrnConflictForApprovers" class="mt-4">
                  This ECT has a TRN conflict with another ECT in your Institution. Clicking Approve and Transfer will
                  provide more information on the existing ECT and give you the option to transfer over this
                  registration data to the existing record.
                </mosaic-info-alert>
              </mosaic-ect-registration-stepper-content></v-stepper-window-item
            >
          </v-stepper-window>
        </v-stepper>
        <mosaic-snackbar
          v-model="snackbar.active"
          :color="snackbar.color"
          :message="snackbar.message"
          location="bottom"
        />
      </v-card>
    </div>
    <mosaic-dialog
      v-model:active="rejectionDialog.active"
      title="Reject Registration"
      :error-message="rejectionDialog.error"
    >
      <mosaic-form ref="rejectionForm">
        <div>Are you sure you want to reject this ECT registration?</div>
        <div class="pt-2">Please provide a reason for rejecting this registration.</div>
        <mosaic-text-area
          v-model="rejectionDialog.reason"
          name="rejection-reason"
          class="mt-4"
          variant="filled"
          label="Reason for rejection"
          auto-grow
          no-icon
          type="text"
          rows="2"
          required
        />
        <mosaic-info-alert
          >To ensure Mosaic always sends emails to real addresses, Mosaic will always use the email address held against
          the registering User's Mosaic account. This may not be the same as the email given by the registering User in
          the registration form.
        </mosaic-info-alert>

        <mosaic-checkbox
          v-model="rejectionDialog.sendEmail"
          name="email-rejection"
          label="Email registering user to inform them of rejection"
          prepend-icon="mdi-email"
          :disabled="!!sendRejectionEmailError"
        >
        </mosaic-checkbox>
        <mosaic-alert v-if="sendRejectionEmailError" type="warning" class="mb-2">
          <div v-if="ect && ect.registeredBy">
            Registering User's Mosaic account email:<strong> {{ ect.registeredBy.user.email }}</strong>
          </div>
          <div class="mt-2">We can't send an email to the registering user for the following reason:</div>
          <div class="mt-2">
            <ul>
              <li>
                <strong>{{ sendRejectionEmailError }}</strong>
              </li>
            </ul>
          </div>
          <div class="mt-2">You may wish to email them separately.</div>
        </mosaic-alert>
        <template v-if="rejectionDialog.sendEmail">
          <mosaic-text-field
            v-model="ect.registeredBy.user.email"
            :readonly="true"
            name="rejection-email"
            label="Registering User's Mosaic account email"
            prepend-icon="mdi-at"
            style="width: 45%"
            :rules="[isValidEmail]"
          />
        </template>
        <div>
          This registration will appear as "Rejected" in your pending ECTs list. The registering school will be able to
          update the registration and resubmit it for approval.
        </div>
        <div class="pt-2">N.B. Confirming this rejection will also save any changes you have made.</div>
      </mosaic-form>
      <template #buttons>
        <v-btn
          variant="text"
          ripple
          color="primary"
          :disabled="rejectionDialog.processing"
          :loading="rejectionDialog.processing"
          @click.prevent="validateAndSubmitRejectEct()"
          >Confirm</v-btn
        >
      </template>
    </mosaic-dialog>
    <mosaic-dialog v-model:active="updateTraDataDialog.active" title="Update fields for TRA">
      <div>You must update the ECT's TRN and/or date of birth to retry the QTS check</div>
    </mosaic-dialog>
    <mosaic-dialog
      v-model:active="transferRegistrationDialog.active"
      title="Transfer registration data to existing ECT"
      :error-message="transferRegistrationDialog.loadError || transferRegistrationDialog.submitError"
    >
      <div v-if="transferRegistrationDialog.loadProcessing">
        <em>Loading existing ECT data... </em>
      </div>
      <template v-if="transferRegistrationDialog.existingEct">
        <div>Mosaic has identified an existing ECT with the same TRN and the following details:</div>
        <div style="display: grid; grid-template-columns: 1fr 1fr" class="mt-4 pl-2 helper-text">
          <div class="font-weight-bold">Name</div>
          <mosaic-empty-state-div :text="transferRegistrationDialog.existingEct.name" />
          <div class="font-weight-bold">Email</div>
          <div>{{ transferRegistrationDialog.existingEct.email }}</div>
          <div class="font-weight-bold">
            {{ transferRegistrationDialog.existingEct.leftDate ? 'Previous' : 'Current' }} School
          </div>
          <mosaic-empty-state-div :text="transferRegistrationDialog.existingEct.previousSchool.displayName" />
          <div class="font-weight-bold">Marked as Leaving</div>
          <mosaic-empty-state-div
            :text="
              transferRegistrationDialog.existingEct.leftDate ||
              transferRegistrationDialog.existingEct.futureLeavingDate
                ? formatDate(
                    transferRegistrationDialog.existingEct.leftDate ||
                      transferRegistrationDialog.existingEct.futureLeavingDate
                  )
                : 'Not marked as leaving'
            "
          />
        </div>

        <div class="d-flex pr-6 mt-2 mb-4">
          <div class="flex-grow-1" />
          <mosaic-router-link
            :to="{
              name: 'TutorStudentDetailsPage',
              params: { studentId: transferRegistrationDialog.existingEct.studentId },
            }"
            target="_blank"
          >
            View full details
            <v-icon size="small">mdi-open-in-new</v-icon>
          </mosaic-router-link>
        </div>
        <mosaic-alert v-if="transferLeaveAndJoinDateConflict" type="error">
          <div>
            The existing ECT is marked as leaving their current school after the registration ECT's start date. Please
            update either record to resolve this issue before continuing.
          </div>
          <div class="pt-2">You may need to refresh the page for the changes to pull through.</div>
        </mosaic-alert>
        <template v-else>
          <div>Confirming this transfer will do the following:</div>
          <ol class="pt-2 pl-6">
            <li>Store the original ECT data in the Mosaic database in case it needs to be retrieved later.</li>
            <li>
              Copy over the data from this registration form onto the existing ECT record, including updating the email
              address for login.
            </li>
            <li>Create a "Change School" event for the new Induction Period.</li>
            <li>Archive this Pending ECT record.</li>
          </ol>
          <div class="mt-2">Please contact Support if you need a different set of actions to be taken</div>
          <mosaic-card-subheading class="mt-4">Change School Details</mosaic-card-subheading>
          <div
            v-if="
              !(
                transferRegistrationDialog.existingEct.leftDate ||
                transferRegistrationDialog.existingEct.futureLeavingDate
              )
            "
            class="mt-2"
          >
            This existing ECT is not marked as leaving their current school. Please provide leaving details below:
            <mosaic-date-picker
              v-model:date="transferRegistrationDialog.leavingDate"
              name="leaving-date"
              label="Leaving Date"
              :exact-width="false"
              view-mode="months"
              required
            />
            <mosaic-text-area
              v-model="transferRegistrationDialog.leavingNote"
              name="leaving-note"
              label="Leaving notes"
              variant="filled"
              prepend-icon="mdi-text"
            ></mosaic-text-area>
          </div>
          <div>You can optionally add notes to the existing ECT's record about joining the new school:</div>
          <mosaic-text-area
            v-model="transferRegistrationDialog.returnNote"
            class="mt-2"
            name="return-note"
            label="Return notes"
            prepend-icon="mdi-text"
            variant="filled"
          ></mosaic-text-area>
          <mosaic-checkbox
            v-model="transferRegistrationDialog.removeOldStaffRoles"
            name="remove-old-staff-roles"
            label="Remove existing Induction Tutor and Headteacher"
            no-icon
          />
          <mosaic-info-alert class="pt-2">
            We strongly recommend checking the ECT's
            <mosaic-router-link
              :to="{
                name: 'TutorStudentDetailsPage',
                params: { studentId: transferRegistrationDialog.existingEct.studentId },
              }"
              target="_blank"
            >
              Manage Induction Page
              <v-icon size="small">mdi-open-in-new</v-icon>
            </mosaic-router-link>
            after completing this action to ensure the ECT's details are correct.
          </mosaic-info-alert>
        </template>
      </template>
      <template #buttons>
        <v-btn
          variant="text"
          ripple
          color="primary"
          :disabled="!canSubmitEctTransfer"
          :loading="transferRegistrationDialog.submitProcessing"
          @click.prevent="submitTransferRegistrationData()"
          >Confirm</v-btn
        >
      </template>
    </mosaic-dialog>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { mapStateError, mapStateProcessing } from '@/store/map-store';
import MosaicEmptyStateDiv from '@/components/library/display/MosaicEmptyStateDiv.vue';
import MosaicIconLink from '@/components/library/display/MosaicIconLink.vue';
import {
  fteRateRules,
  termsCompletedRules,
  schoolTrnRules,
  validationsPass,
  schoolUrnRules,
  isValidEmail,
} from '../../utils/validations';
import { validateEmail } from '../../utils/email';
import {
  yearGroupItems,
  qualifiedAgeRangesItems,
  contractTypeItems,
  qualificationsAwardedItems,
  ecfProgrammeItems,
  ecfProviderItems,
  brightFuturesEcfProviderItems,
  ectRegistrationSubjects,
  ecfTermsCompletedItems,
  partTimeOptions,
} from '../../utils/ect/ect';
import { brightFuturesMATs, settingTypes, localAuthoritiesByAB } from '../../utils/school';
import MosaicCardSubheading from '@/components/library/mosaic-card/MosaicCardSubheading.vue';
import MosaicEctRegistrationStepperContent from '@/components/MosaicEctRegistrationStepperContent.vue';
import { cannotSendEmailToUser } from '@/utils/email-verification';
import _ from 'lodash';
import moment from 'moment';
import { syncQueryParamsMixin } from '@/mixins/query-mixins';
import { useQueryStore } from '@/stores/query';
// import { createFuturePlacementsDateItems } from '../../utils/placements';

const transferRegistrationDialogDefaults = {
  active: false,
  loadProcessing: false,
  submitProcessing: false,
  loadError: '',
  submitError: '',
  existingEct: null,
  leavingNote: '',
  returnNote: '',
  leavingDate: null,
  removeOldStaffRoles: false,
};

export default {
  name: 'TutorEctRegistrationPage',
  setup() {
    const queryStore = useQueryStore();
    return { queryStore };
  },
  mixins: [
    syncQueryParamsMixin({
      step: { query: 'step', type: 'integer' },
    }),
  ],
  components: {
    MosaicCardSubheading,
    MosaicEctRegistrationStepperContent,
    MosaicEmptyStateDiv,
    MosaicIconLink,
  },
  data: () => ({
    ectError: '',
    actionError: '',
    busy: true,
    step: 1,
    conflictingEmail: false,
    conflictEmail: '',
    selectedPackage: null,
    settingTypes,
    brightFuturesMATs,
    addressFirstLine: '',
    addressCity: '',
    addressSecondLine: '',
    phoneNumber: '',
    addressPostcode: '',
    localAuthority: '',
    localAuthorityOther: '',
    schoolURN: '',
    schoolMAT: '',
    schoolMatOther: '',
    schoolSettingType: '',
    schoolFinanceLeadName: '',
    schoolFinanceEmail: '',
    schoolDetailsConfirmed: null,
    schoolDetailsConfirmedItems: [
      {
        title: 'No',
        confirmed: false,
      },
      {
        title: 'Yes',
        confirmed: true,
      },
    ],
    schoolDetailChanges: '',
    name: '',
    dateOfBirth: null,
    email: '',
    trn: '',
    qtsDate: null,
    startDate: null,
    customStartDate: null,
    termsAlreadyCompleted: 0,
    previousInductionPeriods: '',
    priorReviewsObtained: null,
    statutoryRequirementsConfirmed: false,
    ptFteRate: 1,
    partTimeOptions,
    employmentTypeOptions: ['Full Time', 'Part Time'],
    employmentType: 'Full Time',
    contractType: null,
    contractTypeItems,
    contractDetails: '',
    contractStartDate: null,
    contractEndDate: null,
    dbsConfirmation: false,
    trainedOutsideEngland: false,
    yearGroups: [],
    otherYearGroup: '',
    yearGroupItems,
    teachingSubjects: [],
    otherTeachingSubject: '',
    teacherTrainingInstitution: '',
    traIttEstablishment: '',
    qualificationsAwarded: [],
    qualificationsAwardedItems,
    ectRegistrationSubjects,
    otherQualifiedSubject: '',
    qualifiedSubjects: [],
    qualifiedAgeRanges: [],
    qualifiedAgeRangesItems,
    otherQualifiedAgeRange: '',
    ecfProgramme: null,
    ecfProgrammeItems,
    ecfProvider: null,
    ecfProviderItems: [],
    ecfTermsCompletedCohort: null,
    ecfTermsCompletedCohortItems: ecfTermsCompletedItems,
    instructors: [],
    headteacher: null,
    inductionTutor: null,
    mentorName: '',
    mentorEmail: '',
    mentorJobTitle: '',
    mentorTrn: '',
    mentorTrainingDetails: '',
    coordinatorName: '',
    coordinatorEmail: '',
    coordinatorJobTitle: '',
    registeringName: '',
    registeringEmail: '',
    registeringRole: '',
    detailsConfirmed: false,
    slaAgreed: false,
    marketingAgreed: false,
    cohortId: null,
    ect: null,
    registeringOrApproving: '',
    retryQtsProcessing: false,
    retryQtsErrorAction: '',
    saveProcessing: false,
    registerProcessing: false,
    fteRateRules,
    termsCompletedRules,
    schoolTrnRules,
    isValidEmail,
    emailsCheckedForConflict: [],
    ecfCohort: '',
    invoiceRef1: '',
    invoiceRef2: '',
    preNavigationStep: 1,
    snackbar: {
      message: '',
      color: '',
      active: false,
    },
    rejectionDialog: {
      active: false,
      processing: false,
      reason: '',
      sendEmail: false,
    },
    updateTraDataDialog: {
      active: false,
    },
    stepFormMap: {
      1: {
        name: 'packageForm',
        valid: true,
      },
      2: {
        name: 'schoolForm',
        valid: true,
      },
      3: {
        name: 'ectForm',
        valid: true,
      },
      4: {
        name: 'contractForm',
        valid: true,
      },
      5: {
        name: 'trainingForm',
        valid: true,
      },
      6: {
        name: 'staffForm',
        valid: true,
      },
      7: {
        name: 'confirmForm',
        valid: true,
      },
    },
    transferRegistrationDialog: { ...transferRegistrationDialogDefaults },
  }),
  computed: {
    ...mapState(['selectedSchool', 'subjects', 'roles', 'selectedInstitution', 'user', 'institutionStudents']),
    ...mapGetters(['isBrightFuturesAB', 'activeCohorts', 'ectPackages']),
    ...mapStateProcessing(['loadSchool']),
    ...mapStateError(['loadSchool']),
    pageReady() {
      return !(this.stateError || this.ectError) && !((this.stateProcessing && !this.selectedSchool) || this.busy);
    },
    priorReviewItems() {
      return [
        {
          reviewsObtained: true,
          descriptor: 'Yes - we have obtained copies from the appropriate body',
        },
        {
          reviewsObtained: false,
          descriptor: `No - we do not have copies and would like ${this.selectedInstitution.name} to obtain these on our behalf`,
        },
      ];
    },
    breadcrumbs() {
      if (this.registeringOrApproving === 'registering') {
        return [
          {
            text: 'ECTs',
            to: {
              name: 'TutorSchoolStudentsPage',
            },
          },
          {
            text: `Register New ECT`,
          },
        ];
      }

      if (this.approving) {
        return [
          {
            text: 'Pending ECTs',
            to: {
              name: 'TutorPendingEctsPage',
            },
          },
          {
            text: `Approve ECT`,
          },
        ];
      }
      return [];
    },
    transferLeaveAndJoinDateConflict() {
      if (!this.transferRegistrationDialog.existingEct) return false;
      return (
        this.transferRegistrationDialog.existingEct.futureLeavingDate &&
        moment(this.transferRegistrationDialog.existingEct.futureLeavingDate).isAfter(moment(this.customStartDate))
      );
    },
    // We have no cohort here so don't have terms
    // startDateItems() {
    //   return createFuturePlacementsDateItems();
    // },
    ectForm() {
      return this.$refs.ectForm;
    },
    sendRejectionEmailError() {
      if (!this.ect) return 'No Ect loaded';
      const registeredBy = this.ect.registeredBy;
      if (!this.ect.registeredBy) {
        return "The registering User's Mosaic account was not recorded on registration";
      } else
        return cannotSendEmailToUser(
          registeredBy.user.emailVerified,
          registeredBy.user.emailBounced,
          registeredBy.user.optedOutOfEmails
        );
    },
    approving() {
      return this.registeringOrApproving === 'approving';
    },
    localAuthorities() {
      return localAuthoritiesByAB.find(x => x.abName === this.selectedInstitution.name)?.localAuthorities || [];
    },
    localAuthoritiesWithOther() {
      return this.localAuthorities.length > 0 ? this.localAuthorities.concat(['Other']) : ['Default Local Authority'];
    },
    fteRate() {
      return this.employmentType === 'Full Time' ? 1.0 : this.ptFteRate?.rate;
    },
    ectRegistrationSubjectsIncludingOther() {
      return this.ectRegistrationSubjects.concat(['Other']);
    },
    qualifiedAgeRangesItemsIncludingOther() {
      return this.qualifiedAgeRangesItems.concat(['Other']);
    },
    yearGroupItemsIncludingOther() {
      return this.yearGroupItems.concat(['Other']);
    },
    qualifiedSubjectsToSave() {
      return this.otherTransformedSelection(this.qualifiedSubjects, this.otherQualifiedSubject);
    },
    qualifiedAgeRangesToSave() {
      return this.otherTransformedSelection(this.qualifiedAgeRanges, this.otherQualifiedAgeRange);
    },
    yearGroupsToSave() {
      return this.otherTransformedSelection(this.yearGroups, this.otherYearGroup);
    },
    teachingSubjectsToSave() {
      return this.otherTransformedSelection(this.teachingSubjects, this.otherTeachingSubject);
    },
    instructorItems() {
      if (!this.selectedSchool) return [];
      const instructors = this.selectedSchool.staffRoles.reduce((instructors, staffRole) => {
        const instructor = instructors.find(x => x.id === staffRole.staff.id);
        if (!instructor) {
          return [
            ...instructors,
            {
              id: staffRole.staff.id,
              name: staffRole.staff.name,
              email: staffRole.staff.email,
              roleIds: [staffRole.role_id],
            },
          ];
        } else {
          return instructors.map(x => {
            if (x.id === instructor.id) {
              return { ...x, roleIds: [...x.roleIds, staffRole.role_id] };
            }
            return x;
          });
        }
      }, []);
      return instructors.map(x => ({ text: x.name || x.email, id: x.id }));
    },
    canContinue1() {
      return !(this.selectedPackage === null);
    },
    canContinue2() {
      return !!(
        this.canContinue1 &&
        this.schoolSettingType &&
        this.schoolURN &&
        this.schoolMAT &&
        (this.schoolMAT !== 'Other' || this.schoolMatOther) &&
        this.localAuthority &&
        (this.localAuthority !== 'Other' || this.localAuthorityOther) &&
        this.addressFirstLine &&
        this.addressPostcode &&
        this.phoneNumber &&
        this.schoolFinanceLeadName &&
        this.schoolFinanceEmail &&
        (this.schoolDetailsConfirmed || (!this.schoolDetailsConfirmed && this.schoolDetailChanges))
      );
    },
    canContinue3() {
      return !!(
        this.canContinue2 &&
        this.name &&
        this.dateOfBirth &&
        this.email &&
        this.customStartDate &&
        validationsPass(this.termsCompletedRules, this.termsAlreadyCompleted) &&
        (this.termsAlreadyCompleted === 0 || this.priorReviewsObtained !== null) &&
        (this.trainedOutsideEngland || (validationsPass(this.schoolTrnRules, this.trn) && this.qtsDate)) &&
        !this.matchesAnyLoadedEmails &&
        !this.matchesAnySchoolTrns
      );
    },
    canContinue4() {
      return (
        this.canContinue3 &&
        validationsPass(this.fteRateRules, this.fteRate) &&
        (this.employmentType === 'Full Time' || this.ptFteRate) &&
        this.contractType &&
        (this.contractType === 'Permanent' || (this.contractStartDate && this.contractEndDate)) &&
        this.dbsConfirmation &&
        this.yearGroups.length &&
        this.teachingSubjects.length &&
        (!this.teachingSubjects.includes('Other') || this.otherTeachingSubject) &&
        (!this.yearGroups.includes('Other') || this.otherYearGroup) &&
        this.statutoryRequirementsConfirmed
      );
    },
    canContinue5() {
      return !!(
        this.canContinue4 &&
        this.teacherTrainingInstitution &&
        this.qualifiedSubjectsToSave.length > 0 &&
        (!this.qualifiedSubjects.includes('Other') || this.otherQualifiedSubject) &&
        (!this.qualifiedAgeRanges.includes('Other') || this.otherQualifiedAgeRange) &&
        this.qualifiedAgeRangesToSave.length > 0 &&
        ((this.selectedPackage === 1 &&
          this.ecfProgramme &&
          (this.ecfProgramme === 'School-based Induction Programme' || this.ecfProvider)) ||
          this.ecfTermsCompletedCohort !== null)
      );
    },
    canContinue6() {
      return !!(
        this.canContinue5 &&
        this.headteacher &&
        this.inductionTutor &&
        this.mentorName &&
        this.mentorEmail &&
        this.mentorJobTitle &&
        (this.selectedPackage !== 0 ||
          (this.mentorTrn && validationsPass(this.schoolTrnRules, this.mentorTrn) && this.mentorTrainingDetails)) &&
        this.coordinatorName &&
        this.coordinatorEmail &&
        this.coordinatorJobTitle
      );
    },

    complete1() {
      return this.canContinue1;
    },
    complete2() {
      if (this.registeringOrApproving == 'registering') {
        return !!this.canContinue2;
      }
      return this.step > 1 && !!this.canContinue2;
    },
    complete3() {
      if (this.registeringOrApproving == 'registering') {
        return !!this.canContinue3;
      }
      return this.step > 2 && !!this.canContinue3;
    },
    complete4() {
      if (this.registeringOrApproving == 'registering') {
        return !!this.canContinue4;
      }
      return this.step > 3 && !!this.canContinue4;
    },
    complete5() {
      if (this.registeringOrApproving == 'registering') {
        return !!this.canContinue5;
      }
      return this.step > 4 && !!this.canContinue5;
    },
    complete6() {
      if (this.registeringOrApproving == 'registering') {
        return !!this.canContinue6;
      }
      return this.step > 5 && !!this.canContinue6;
    },
    complete7() {
      const complete = !!(
        this.registeringName &&
        this.registeringEmail &&
        this.registeringRole &&
        (this.approving || this.detailsConfirmed) &&
        (!this.selectedInstitution.ab_sla_link || this.slaAgreed)
      );
      if (this.registeringOrApproving == 'registering') {
        return complete;
      }
      return Boolean(this.step > 6 && complete && this.cohortId);
    },
    canRetryQts() {
      return !this.retryQtsProcessing && this.dateOfBirth && validationsPass(this.schoolTrnRules, this.trn);
    },
    retryQtsAvailable() {
      return (
        this.ect &&
        (this.ect.status === 'teacher_not_found' || (this.approving && this.ect.status === 'does_not_have_qts'))
      );
    },
    showCohortSelect() {
      return this.approving && this.activeCohorts.length > 1;
    },
    hasQts() {
      return this.registeringOrApproving == 'approving' && !!this.ect.traQtsDate;
    },
    inductionTutorRoleId() {
      return this.selectedInstitution.induction_tutor_role_id;
    },
    headteacherRoleId() {
      return this.selectedInstitution.headteacher_role_id;
    },
    schoolPendingEctEmails() {
      if (!this.selectedSchool) return [];
      return this.selectedSchool.pendingEcts.map(e => e.email);
    },
    matchesPendingEctEmail() {
      return this.emailDirty && this.schoolPendingEctEmails.includes(this.email);
    },
    schoolActiveEctEmails() {
      if (!this.selectedSchool) return [];
      return this.selectedSchool.ects.map(e => e.email);
    },
    matchesActiveEctEmail() {
      return this.emailDirty && this.schoolActiveEctEmails.includes(this.email);
    },
    schoolPendingEctTrns() {
      if (!this.selectedSchool) return [];
      return this.selectedSchool.pendingEcts
        .filter(e => !this.ect || e.id !== this.ect.id)
        .map(e => e.trn)
        .filter(t => t);
    },
    matchesSchoolPendingEctTrn() {
      return this.schoolPendingEctTrns.includes(this.trn);
    },
    schoolActiveEctTrns() {
      if (!this.selectedSchool) return [];
      return this.selectedSchool.ects
        .filter(e => !this.ect || e.id !== this.ect.id)
        .map(e => e.trn)
        .filter(t => t);
    },
    matchesSchoolActiveEctTrn() {
      return this.schoolActiveEctTrns.includes(this.trn);
    },
    matchesAnySchoolTrns() {
      return this.matchesSchoolPendingEctTrn || this.matchesSchoolActiveEctTrn;
    },
    institutionStudentEmails() {
      return this.institutionStudentsExcludingEct.map(s => s.email);
    },
    matchesInstitutionStudentEmail() {
      return this.emailDirty && this.institutionStudentEmails.includes(this.email);
    },
    institutionStudentTrns() {
      return this.institutionStudentsExcludingEct.map(s => s.ect.trn).filter(t => t);
    },
    institutionStudentsExcludingEct() {
      return this.institutionStudents.filter(s => !this.ect || s.ect.id !== this.ect.id);
    },
    anyTrnConflictForApprovers() {
      // This code should be removed later. It is here to support the case where ECTs with conflicting TRNs were registered prior
      // to update which prevents registration in these case.
      // Deliberately not bothering to check against all ECTs in Mosaic (just the institutions) as currently only have two well-geographically
      // separated customers, and from a users perspective it's not actually a huge problem if they have an ECT with the same ECT as in another institution
      // as they'll always need to go through the claims process.
      return this.institutionStudentTrns.includes(this.trn);
    },

    matchesAnyLoadedEmails() {
      return this.matchesPendingEctEmail || this.matchesActiveEctEmail || this.matchesInstitutionStudentEmail;
    },
    emailDirty() {
      return (!this.ect && this.email) || this.email !== this.ect?.email;
    },
    trnDirty() {
      return (!this.ect && this.trn) || this.trn !== this.ect?.trn;
    },
    schoolDirty() {
      return (
        this.addressFirstLine !== this.selectedSchool.addressFirstLine ||
        this.addressSecondLine !== this.selectedSchool.addressSecondLine ||
        this.addressPostcode !== this.selectedSchool.addressPostcode ||
        this.phoneNumber !== this.selectedSchool.phoneNumber ||
        this.addressCity !== this.selectedSchool.addressCity ||
        this.schoolURN !== this.selectedSchool.urn ||
        (this.schoolMAT === 'Other' && this.selectedSchool.mat !== this.schoolMatOther) ||
        (this.schoolMAT !== 'Other' && this.selectedSchool.mat !== this.schoolMAT) ||
        (this.localAuthority === 'Other' && this.selectedSchool.localAuthority !== this.localAuthorityOther) ||
        (this.localAuthority !== 'Other' && this.selectedSchool.localAuthority !== this.localAuthority) ||
        this.schoolSettingType !== this.selectedSchool.settingType ||
        this.schoolFinanceLeadName !== this.selectedSchool.financeLeadName ||
        this.schoolFinanceEmail !== this.selectedSchool.financeEmail
      );
    },
    canSubmitEctTransfer() {
      return (
        !this.transferLeaveAndJoinDateConflict &&
        !this.transferRegistrationDialog.submitProcessing &&
        this.transferRegistrationDialog.existingEct &&
        (this.transferRegistrationDialog.existingEct.leftDate ||
          this.transferRegistrationDialog.existingEct.futureLeavingDate ||
          this.transferRegistrationDialog.leavingDate)
      );
    },
  },
  watch: {
    activeCohorts(x) {
      if (x.length === 1) {
        this.cohortId = x[0].id;
      }
    },
    pageReady(x) {
      this.$nextTick(() => {
        if (x) {
          Object.keys(this.stepFormMap).forEach(step => {
            if (this.completedStep(step)) this.setFormValidity(step);
          });
        }
      });
    },
    trainedOutsideEngland(x) {
      if (x) {
        this.trn = '';
      }
    },
    step(newValue, oldValue) {
      if (oldValue !== newValue) {
        window.scrollTo(0, 0);
      }
      if (newValue === 3) {
        if (this.conflictingEmail) {
          this.ectForm.validate();
        }
      }
    },
    email(x) {
      if (!x || x !== this.conflictEmail) {
        this.conflictingEmail = false;
      } else {
        this.conflictingEmail = true;
      }
    },
  },
  async created() {
    this.$store.dispatch('loadSubjects');
    this.$store.dispatch('loadRoles');

    // This page supports both the registering and approving flows
    if (this.$route.name === 'TutorEctApproveRegistrationPage') {
      this.registeringOrApproving = 'approving';
      this.$store.dispatch('loadCohorts');
      if (this.activeCohorts.length === 1) {
        this.cohortId = this.activeCohorts[0].id;
      }
      await this.loadEct(`/ects/${this.$route.params.ectId}/details`);
      this.busy = false;
      this.$store.dispatch('loadAllActiveStudents');
    } else if (this.$route.name === 'TutorEctReviewRegistrationPage') {
      this.registeringOrApproving = 'registering';
      await this.loadEct(`/schools/${this.selectedSchool.id}/pending-ects/${this.$route.params.ectId}/details`);
      this.busy = false;
    } else {
      this.registeringOrApproving = 'registering';
      this.registeringName = this.user.name;
      this.registeringEmail = this.user.email;
      this.busy = false;
    }
    this.setSchoolFields();
    this.ecfProviderItems = this.isBrightFuturesAB ? brightFuturesEcfProviderItems : ecfProviderItems;

    this.preNavigationStep = this.step;
  },
  beforeRouteLeave(to, from, next) {
    if (from.name === 'TutorEctApproveRegistrationPage') {
      this.$store.commit('clearSelectedSchool');
    }
    next();
  },
  methods: {
    completedStep(step) {
      switch (step) {
        case 1:
          return this.canContinue1;
        case 2:
          return this.canContinue2;
        case 3:
          return this.canContinue3;
        case 4:
          return this.canContinue4;
        case 5:
          return this.canContinue5;
        case 6:
          return this.canContinue6;
        case 7:
          return this.canContinue7;
        default:
          return false;
      }
    },
    stepperItemRules(step) {
      return this.completedStep(step) || this.step > step
        ? [() => this.step === step || this.stepFormMap[step].valid]
        : [];
    },
    currentForm() {
      return this.$refs[this.stepFormMap[this.preNavigationStep].name];
    },
    async validateAndContinue(nextStep) {
      if (nextStep < this.step || this.step === 1) {
        this.step = nextStep;
        this.preNavigationStep = nextStep;
        return;
      }
      if (this.step === 3) {
        await this.checkForExistingEmails();
      }
      const isValid = await this.currentForm().validate?.();
      if (isValid || this.approving) {
        this.step = nextStep;
        this.preNavigationStep = nextStep;
      }
    },
    async validateOnLeave() {
      // Step one has no form to validate
      if (this.preNavigationStep !== 1 && this.step > this.preNavigationStep) {
        await this.setFormValidity(this.preNavigationStep);
      }
      this.preNavigationStep = this.step;
    },
    async setFormValidity(step) {
      if (step === 3) await this.checkForExistingEmails();
      const form = this.$refs[this.stepFormMap[step].name];
      const isValid = await form.validate?.(true);
      this.stepFormMap[step].valid = isValid;
    },
    async checkForExistingEmails() {
      const scopeQuery = this.approving
        ? `?institution_id=${this.selectedInstitution.id}`
        : `?school_id=${this.selectedSchool.id}`;
      const queryParams = `&email=${this.email}`;
      if (
        this.email &&
        this.emailDirty &&
        !this.matchesAnyLoadedEmails &&
        !this.emailsCheckedForConflict.includes(this.email)
      ) {
        const r = await this.$api.get(`/ects/validate${scopeQuery}${queryParams}`);
        if (r.data.email_conflict) {
          this.conflictingEmail = true;
          this.conflictEmail = this.email;
        } else {
          this.conflictingEmail = false;
          this.conflictEmail = '';
          this.emailsCheckedForConflict.push(this.email);
        }
      }
      // Sentry issue suggests that the emailField may be null/undefined at some points. Potentially on a slow network connection and when changing tabs?
      this.$refs.emailField?.validate();
    },
    back() {
      this.step = this.step - 1;
      this.preNavigationStep = this.step;
    },
    async loadEct(url) {
      this.busy = true;
      try {
        const r = await this.$api.get(url);

        this.ect = r.data;
        this.schoolDetailsConfirmed = r.data.schoolDetailsConfirmed;
        this.schoolDetailChanges = r.data.schoolDetailChanges;
        this.name = r.data.name;
        this.email = r.data.email;
        this.dateOfBirth = r.data.dateOfBirth;
        this.trn = r.data.trn;
        this.qtsDate = r.data.dateAwardedQts;
        this.trainedOutsideEngland = r.data.trainedOutsideEngland;
        this.customStartDate = r.data.startDate;
        this.termsAlreadyCompleted = r.data.termsAlreadyCompleted;
        this.previousInductionPeriods = r.data.previousInductionDetails;
        this.priorReviewsObtained = r.data.priorReviewsObtained;
        this.ptFteRate = r.data.fteRate === 1 ? null : this.partTimeOptions.find(x => x.rate === r.data.fteRate);
        this.employmentType = r.data.fteRate === 1 ? 'Full Time' : 'Part Time';
        this.contractType = r.data.contractType;
        this.contractDetails = r.data.contractDetails;
        this.contractStartDate = r.data.contractStartDate;
        this.contractEndDate = r.data.contractEndDate;
        this.dbsConfirmation = true;
        this.statutoryRequirementsConfirmed = true;
        this.yearGroups = this.separateOtherFromItems(r.data.yearGroups, yearGroupItems).items;
        this.otherYearGroup = this.separateOtherFromItems(r.data.yearGroups, yearGroupItems).other;
        this.teachingSubjects = this.separateOtherFromItems(r.data.teachingSubjects, ectRegistrationSubjects).items;
        this.otherTeachingSubject = this.separateOtherFromItems(r.data.teachingSubjects, ectRegistrationSubjects).other;
        this.teacherTrainingInstitution = r.data.teacherTrainingInstitution;
        this.traIttEstablishment = r.data.traIttEstablishment;
        this.qualificationsAwarded = r.data.qualificationsAwarded || [];
        this.qualifiedSubjects = this.separateOtherFromItems(r.data.qualifiedSubjects, ectRegistrationSubjects).items;
        this.otherQualifiedSubject = this.separateOtherFromItems(
          r.data.qualifiedSubjects,
          ectRegistrationSubjects
        ).other;
        this.qualifiedAgeRanges = this.separateOtherFromItems(r.data.qualifiedAgeRanges, qualifiedAgeRangesItems).items;
        this.otherQualifiedAgeRange = this.separateOtherFromItems(
          r.data.qualifiedAgeRanges,
          qualifiedAgeRangesItems
        ).other;
        this.ecfProgramme = r.data.ecfProgramme;
        this.ecfProvider = r.data.ecfProvider;
        this.ecfTermsCompletedCohort = r.data.ecfTermsCompleted;
        this.selectedPackage = r.data.institutionEctPackage;
        this.instructors = r.data.student.staffRoles.map(sr => ({
          id: sr.staff.id,
          text: sr.staff.name || sr.staff.email,
          roleId: sr.role.id,
          roleText: sr.role.name,
        }));
        const inductionTutorStaffRole = r.data.student.staffRoles.find(sr => sr.role.id === this.inductionTutorRoleId);
        this.inductionTutor = inductionTutorStaffRole
          ? {
              id: inductionTutorStaffRole?.staff.id,
              text: inductionTutorStaffRole?.staff.name || inductionTutorStaffRole?.staff.email,
            }
          : null;
        const headteacherStaffRole = r.data.student.staffRoles.find(sr => sr.role.id === this.headteacherRoleId);
        this.headteacher = headteacherStaffRole
          ? {
              id: headteacherStaffRole?.staff.id,
              text: headteacherStaffRole?.staff.name || headteacherStaffRole?.staff.email,
            }
          : null;
        this.mentorName = r.data.mentorName;
        this.mentorEmail = r.data.mentorEmail;
        this.mentorJobTitle = r.data.mentorJobTitle;
        this.mentorTrn = r.data.mentorTrn;
        this.mentorTrainingDetails = r.data.mentorTrainingDetails;
        this.coordinatorName = r.data.coordinatorName;
        this.coordinatorEmail = r.data.coordinatorEmail;
        this.coordinatorJobTitle = r.data.coordinatorJobTitle;
        this.registeringName = r.data.registeringName;
        this.registeringEmail = r.data.registeringEmail;
        this.registeringRole = r.data.registeringRole;
        this.slaAgreed = r.data.slaAgreed;
        this.marketingAgreed = r.data.marketingAgreed;
        this.invoiceRef1 = r.data.invoiceRef1;
        this.invoiceRef2 = r.data.invoiceRef2;
        this.ecfCohort = r.data.ecfCohort;
        if (this.ect.status === 'teacher_not_found') this.step = 3;

        await this.$store.dispatch('loadSchool', { schoolId: this.ect.startSchoolId });
        //Needs to be start school id as currentSchool could be nil if start event in the future
      } catch (e) {
        console.log(e);
        this.ectError = 'Sorry, cannot load this ECT at the moment';
      }
      this.busy = false;
    },
    setSchoolFields() {
      // To prevent https://penrose-education.sentry.io/share/issue/3e16f700c047449aa810c076bdd9cfce/ (this.selectedSchool being null/undefined)
      if (!this.selectedSchool) return;
      this.addressFirstLine = this.selectedSchool.addressFirstLine;
      this.addressSecondLine = this.selectedSchool.addressSecondLine;
      this.addressPostcode = this.selectedSchool.addressPostcode;
      this.phoneNumber = this.selectedSchool.phoneNumber;
      this.addressCity = this.selectedSchool.addressCity;
      this.localAuthority = this.selectedSchool.localAuthority;
      this.schoolURN = this.selectedSchool.urn;
      if (this.isBrightFuturesAB) {
        if (this.selectedSchool.mat && !this.brightFuturesMATs.includes(this.selectedSchool.mat)) {
          this.schoolMAT = 'Other';
          this.schoolMatOther = this.selectedSchool.mat;
        } else this.schoolMAT = this.selectedSchool.mat;
      } else this.schoolMAT = this.selectedSchool.mat;
      if (
        this.localAuthorities.length > 0 &&
        this.selectedSchool.localAuthority &&
        !this.localAuthorities.includes(this.selectedSchool.localAuthority)
      ) {
        this.localAuthority = 'Other';
        this.localAuthorityOther = this.selectedSchool.localAuthority;
      } else this.localAuthority = this.selectedSchool.localAuthority;
      this.schoolSettingType = this.selectedSchool.settingType;
      this.schoolFinanceLeadName = this.selectedSchool.financeLeadName;
      this.schoolFinanceEmail = this.selectedSchool.financeEmail;
    },
    async transferRegistrationData() {
      this.transferRegistrationDialog.active = true;
      this.transferRegistrationDialog.loadError = '';
      this.transferRegistrationDialog.loadProcessing = true;
      const ectId = this.institutionStudents.find(s => s.ect.trn === this.trn && s.ect.id !== this.ect.id).ect.id;
      try {
        const r = await this.$api.get(`/ects/${ectId}/existing`);
        this.transferRegistrationDialog.existingEct = r.data;
      } catch (e) {
        console.log(e);
        this.transferRegistrationDialog.loadError =
          'Sorry, cannot load existing ECT with conflicting TRN at the moment';
      }
      this.transferRegistrationDialog.loadProcessing = false;
    },
    async submitTransferRegistrationData() {
      this.transferRegistrationDialog.submitError = '';
      this.transferRegistrationDialog.submitProcessing = true;

      try {
        // Ensures backend ECT record is up to date with any changes made on this page by the approver
        await this.saveRegistration(false);
        await this.$api.post(`/ects/${this.ect.id}/transfer-registration-data`, {
          ..._.pick(this.transferRegistrationDialog, 'leavingDate', 'leavingNote', 'returnNote', 'removeOldStaffRoles'),
          existingEctId: this.transferRegistrationDialog.existingEct.id,
        });
        this.transferRegistrationDialog.active = { ...this.transferRegistrationDialogDefaults };
        this.$router.push({ name: 'TutorPendingEctsPage', params: { institutionId: this.selectedInstitution.id } });
      } catch (e) {
        console.log(e);
        this.transferRegistrationDialog.submitError =
          'Sorry, cannot transfer registration to existing ECT with conflicting TRN at the moment';
      }
      this.transferRegistrationDialog.submitProcessing = false;
    },
    async saveSchool() {
      if (!this.schoolDirty) {
        if (this.step === 2) {
          this.snackbar = {
            message: 'Success!',
            color: 'success',
            active: true,
          };
        }
        return;
      }
      this.actionError = '';
      this.saveProcessing = true;
      try {
        await this.$api.put(`/schools/ect-registration/${this.selectedSchool.id}`, {
          name: this.selectedSchool.name,
          addressCountry: this.selectedSchool.addressCountry,
          urn: this.schoolURN,
          addressFirstLine: this.addressFirstLine,
          addressCity: this.addressCity,
          addressSecondLine: this.addressSecondLine,
          phoneNumber: this.phoneNumber,
          addressPostcode: this.addressPostcode,
          mat: this.schoolMAT === 'Other' ? this.schoolMatOther : this.schoolMAT,
          localAuthority: this.localAuthority === 'Other' ? this.localAuthorityOther : this.localAuthority,
          settingType: this.schoolSettingType,
          financeLeadName: this.schoolFinanceLeadName,
          financeEmail: this.schoolFinanceEmail,
        });
        if (this.step === 2) {
          this.snackbar = {
            message: 'Success!',
            color: 'success',
            active: true,
          };
        }
        this.$store.dispatch('loadSchool', { schoolId: this.selectedSchool.id, force: true });
      } catch (e) {
        console.log(e);
        this.actionError = 'update your school';
      }
      this.saveProcessing = false;
    },
    notExistingEmail(v) {
      if (!v) return false;
      // Prevents rule from triggering against self for already saved ECT:
      const yourOrThis = this.approving ? 'this' : 'your';
      if (this.ect && this.ect.email === v) return true;
      if (this.matchesPendingEctEmail) {
        return `This email belongs to an existing pending ECT for ${yourOrThis} school`;
      } else if (this.matchesActiveEctEmail) {
        return `This email belongs to an ECT already registered with ${yourOrThis} school`;
      } else if (this.approving && this.matchesInstitutionStudentEmail) {
        return `This email belongs to an ECT already registered with ${this.selectedInstitution.name}`;
      } else if (this.conflictingEmail) {
        return `This email belongs to a User already registered on Mosaic - contact ${this.selectedInstitution.name} for support`;
      } else {
        return true;
      }
    },
    notExistingTrn(v) {
      if (!v) return true;

      const yourOrThis = this.approving ? 'this' : 'your';
      if (this.matchesSchoolPendingEctTrn) {
        return `This TRN belongs to an existing pending ECT for ${yourOrThis} school - have you already started this registration?`;
      } else if (this.matchesSchoolActiveEctTrn) {
        return `This TRN belongs to an ECT already registered with ${yourOrThis} school`;
      } else {
        return true;
      }
    },

    separateOtherFromItems(selectedItems, presetItems) {
      if (!selectedItems) return [];
      const otherItem = selectedItems.find(x => !presetItems.includes(x));
      return otherItem
        ? {
            items: selectedItems.filter(x => presetItems.includes(x)).concat(['Other']),
            other: otherItem,
          }
        : {
            items: selectedItems,
            other: '',
          };
    },
    otherTransformedSelection(selectedItems, other) {
      if (!selectedItems) return [];
      const otherIndex = selectedItems.findIndex(x => x === 'Other');
      return other && otherIndex !== -1 ? selectedItems.toSpliced(otherIndex, 1).concat([other]) : selectedItems;
    },
    rejectEct() {
      this.rejectionDialog.active = true;
    },
    validateAndSubmitRejectEct() {
      const hideSnackbar = true;
      if (this.$refs.rejectionForm.validate(hideSnackbar)) this.submitRejectEct();
    },
    async submitRejectEct() {
      this.rejectionDialog.processing = true;
      const url = `/ects/${this.ect.id}/reject-registration`;
      try {
        await this.submitEctData(url, 'update', {
          rejection_reason: this.rejectionDialog.reason,
          send_email: this.rejectionDialog.sendEmail,
        });
        this.rejectionDialog.active = false;
        this.ect = {
          ...this.ect,
          rejectionReason: this.rejectionDialog.reason,
          status: 'rejected',
        };
        this.$store.dispatch('loadSchool', { schoolId: this.selectedSchool.id, force: true });
      } catch (e) {
        console.log(e);
        this.rejectionDialog.error = 'Sorry, cannot reject this ECT at the moment';
      }
      this.rejectionDialog.processing = false;
    },
    async retryQts() {
      if (
        this.ect.status === 'teacher_not_found' &&
        this.trn === this.ect.trn &&
        this.dateOfBirth === this.ect.dateOfBirth
      ) {
        this.updateTraDataDialog.active = true;
      } else {
        this.retryQtsProcessing = true;
        this.retryQtsErrorAction = '';
        try {
          await this.$api.post(`/ects/${this.ect.id}/retry-qts`, {
            date_of_birth: this.dateOfBirth,
            trn: this.trn,
          });
          this.$store.dispatch('loadSchool', { schoolId: this.selectedSchool.id, force: true });
          this.$router.push(this.findPendingEctsPage());
        } catch (e) {
          console.log(e);
          this.retryQtsErrorAction = `retry the QTS check`;
        }
        this.retryQtsProcessing = false;
      }
    },
    findPendingEctsPage() {
      return this.approving
        ? { name: 'TutorPendingEctsPage', params: { institutionId: this.selectedInstitution.id } }
        : { name: 'TutorSchoolStudentsPage', params: { schoolId: this.selectedSchool.id } };
    },
    setConflictingEmail() {
      this.conflictingEmail = true;
      this.conflictEmail = this.email;
    },
    async save() {
      this.actionError = '';
      this.saveProcessing = true;

      if (
        !validateEmail(this.email.trim()) ||
        !this.name ||
        this.selectedPackage === null ||
        this.customStartDate === null
      ) {
        this.snackbar = {
          message:
            'Please provide a valid ECT email, name, induction start date and package selection to save this ECT',
          color: 'error',
          active: true,
        };
        this.saveProcessing = false;
        return;
      }
      if (!this.schoolURN || !validationsPass(schoolUrnRules, this.schoolURN)) {
        this.snackbar = {
          message: 'Please provide a valid school URN to save this ECT',
          color: 'error',
          active: true,
        };
        this.saveProcessing = false;
        return;
      }

      if (!this.ect) await this.createRegistration();
      else await this.saveRegistration();
      this.saveProcessing = false;
    },
    async createRegistration() {
      const url = `schools/${this.selectedSchool.id}/ects/pending`;
      try {
        await this.saveSchool();
        const r = await this.submitEctData(url, 'create');
        this.snackbar = {
          message: 'Success!',
          color: 'success',
          active: true,
        };
        this.ect = r.data;
        this.$router.replace({
          name: 'TutorEctReviewRegistrationPage',
          params: { ectId: this.ect.id },
          query: { step: this.step },
        });
      } catch (e) {
        this.handleSaveError(e);
      }
    },
    handleSaveError(e) {
      if (e.response?.status === 409) {
        if (e.response.data.error_code === 'email_conflict') {
          this.setConflictingEmail();
        }

        if (this.step === 3) {
          this.ectForm.validate();
        } else {
          this.snackbar = {
            message: `The ${
              this.conflictingEmail ? 'email' : 'TRN'
            } you have entered is already in use. Please check and try again.`,
            color: 'error',
            active: true,
          };
        }
      } else {
        console.log(e);
        this.actionError = 'save this registration';
      }
    },

    async saveRegistration(showSnackbar = true) {
      const url =
        this.registeringOrApproving === 'registering'
          ? `/schools/${this.selectedSchool.id}/pending-ects/${this.ect.id}/update-pending-registration`
          : `/ects/${this.ect.id}/update-registration`;
      try {
        await this.saveSchool();
        const r = await this.submitEctData(url, 'update', {});
        if (showSnackbar) {
          this.snackbar = {
            message: 'Success!',
            color: 'success',
            active: true,
          };
        }
        this.ect = r.data;
      } catch (e) {
        this.handleSaveError(e);
      }
    },
    async registerOrApproveEct() {
      if (await this.$refs.confirmForm.validate()) {
        this.actionError = '';
        this.registerProcessing = true;
        try {
          await this.saveSchool();
          if (this.registeringOrApproving === 'registering') {
            await this.submitRegisterEct();
          } else {
            await this.submitApproveEct();
          }
          await this.$store.dispatch('loadSchool', { schoolId: this.selectedSchool.id, force: true });
          this.$router.push(this.findPendingEctsPage());
        } catch (e) {
          console.log(e);
          this.actionError = `${this.registeringOrApproving === 'registering' ? 'register' : 'approve'} this ECT`;
        }
        this.registerProcessing = false;
      }
    },
    async submitRegisterEct() {
      let url, updateOrCreate;
      if (!this.ect) {
        url = `/schools/${this.selectedSchool.id}/ects/register`;
        updateOrCreate = 'create';
      } else {
        url = `/schools/${this.selectedSchool.id}/pending-ects/${this.ect.id}/update-and-register`;
        updateOrCreate = 'update';
      }
      await this.submitEctData(url, updateOrCreate, {});
    },
    async submitApproveEct() {
      const url = `/ects/${this.ect.id}/approve-registration`;
      await this.submitEctData(url, 'update', { registrationSchoolId: this.selectedSchool.id });
    },
    async submitEctData(url, createOrUpdate, additionalParams = {}) {
      const body = {
        ...additionalParams,
        email: this.email.trim(),
        name: this.name,
        start_date: this.customStartDate,
        fte_rate: parseFloat(this.fteRate),
        date_awarded_qts: this.qtsDate,
        terms_already_completed: parseFloat(this.termsAlreadyCompleted),
        previous_induction_details: this.previousInductionPeriods,
        prior_reviews_obtained: this.termsAlreadyCompleted > 0 ? this.priorReviewsObtained : null,
        date_of_birth: this.dateOfBirth,
        trn: this.trn,
        trained_outside_england: this.trainedOutsideEngland,
        contract_type: this.contractType,
        contract_details: this.contractDetails,
        contract_start_date: this.contractStartDate,
        contract_end_date: this.contractEndDate,
        year_groups: this.yearGroupsToSave,
        teaching_subjects: this.teachingSubjectsToSave,
        teacher_training_institution: this.teacherTrainingInstitution,
        qualifications_awarded: this.qualificationsAwarded,
        qualified_subjects: this.qualifiedSubjectsToSave,
        qualified_age_ranges: this.qualifiedAgeRangesToSave,
        ecf_programme: this.ecfProgramme,
        ecf_provider: this.ecfProvider,
        ecf_terms_completed: this.ecfTermsCompletedCohort,
        mentor_name: this.mentorName,
        mentor_email: this.mentorEmail,
        mentor_job_title: this.mentorJobTitle,
        mentor_trn: this.selectedPackage === 0 ? this.mentorTrn : null,
        mentor_training_details: this.selectedPackage === 0 ? this.mentorTrainingDetails : null,
        coordinator_name: this.coordinatorName,
        coordinator_email: this.coordinatorEmail,
        coordinator_job_title: this.coordinatorJobTitle,
        headteacher_id: this.headteacher?.id,
        induction_tutor_id: this.inductionTutor?.id,
        cohort_id: this.registeringOrApproving === 'registering' ? null : this.cohortId,
        institution_ect_package: this.selectedPackage,
        school_details_confirmed: this.schoolDetailsConfirmed,
        school_detail_changes: this.schoolDetailsConfirmed ? null : this.schoolDetailChanges,
        registering_name: this.registeringName,
        registering_email: this.registeringEmail,
        registering_role: this.registeringRole,
        sla_agreed: this.slaAgreed,
        marketing_agreed: this.marketingAgreed,
        invoice_ref_1: this.invoiceRef1,
        invoice_ref_2: this.invoiceRef2,
        ecf_cohort: this.ecfCohort,
      };
      const apiCall = createOrUpdate === 'create' ? () => this.$api.post(url, body) : () => this.$api.put(url, body);

      return await apiCall();
    },
  },
};
</script>

<style>
.package-radios .v-label {
  flex-grow: 1;
}
</style>

<style scoped>
.package-box {
  border: 1px solid rgb(var(--v-theme-primary));
  border-radius: 10px;
  width: 95%;
  padding: 12px;
}

.selected-package-box {
  border: 1px solid rgb(var(--v-theme-accent));
  border-radius: 10px;
  width: 95%;
  padding: 12px;
}

.helper-text {
  color: rgba(0, 0, 0, 0.6);
}
</style>
