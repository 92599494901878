<template>
  <div>
    <review-template-header>
      <template #configure>
        <v-card v-if="reviewTemplateStandard">
          <v-card-text>
            <div class="pa-2">
              <div class="text-h6">
                Configuring "{{ reviewTemplateStandard.code }} - {{ reviewTemplateStandard.name }}"
              </div>
              <div>
                Here you can configure individual statements to be assessed against and any notes to be displayed
                alongside the {{ selectedInstitution.config.review_noun }} (e.g. termly expectations).
              </div>
              <div class="d-flex pt-2 align-center">
                <div>
                  <review-template-read-only review-template-in-use tooltip="right" offset="32">
                    <template #default="{ disabled }">
                      <mosaic-select
                        v-model="reviewTemplateStandard.subject_id"
                        style="max-width: 400px; min-width: 250px"
                        prepend-icon="mdi-pencil-ruler"
                        name="edit-student-subject"
                        :items="subjectsWithNullOptionAndPrimarySeparation"
                        placeholder="Subject"
                        label="Subject (or Primary)"
                        item-title="name"
                        item-value="id"
                        :disabled="disabled"
                        @update:model-value="subjectChanged"
                      >
                        <template #item="{ props, item }">
                          <v-divider v-if="item.raw.divider" />
                          <v-list-item v-else v-bind="props" :title="item.raw.name" />
                        </template>
                      </mosaic-select>
                    </template>
                  </review-template-read-only>
                </div>
                <mosaic-help
                  >Only {{ traineeNounPluralised() }} associated with this subject will see this part of the
                  {{ selectedInstitution.config.review_noun }}.
                  <br />
                  If "All Subjects" is selected, all {{ traineeNounPluralised() }} will see this area</mosaic-help
                >
              </div>

              <div>
                <div class="text-h6">Notes</div>
                <mosaic-quill
                  v-model:contents="reviewTemplateStandard.note"
                  :read-only="false"
                  @change="noteChanged"
                ></mosaic-quill>
              </div>

              <div>
                <div
                  v-if="!reviewTemplateStatementsInUse || reviewTemplateStatementGroups.length > 0"
                  class="text-h6 pt-4"
                >
                  Statements
                </div>
                <div v-if="reviewTemplate.review_standard_statement_institution_judgement_set_id">
                  <div>
                    <div v-for="g in reviewTemplateStatementGroups" :key="g.id" class="mb-4">
                      <div class="d-flex align-center">
                        <review-template-read-only review-template-statements-in-use>
                          <template #default="{ disabled }">
                            <v-text-field
                              v-model="g.name"
                              prepend-icon="mdi-format-list-bulleted-square"
                              hide-details
                              density="compact"
                              :disabled="disabled"
                              @update:focused="x => !x && updateStatementGroupName(g)"
                            ></v-text-field>
                          </template>
                        </review-template-read-only>

                        <div class="pl-2">
                          <ndt-icon-button
                            icon="delete"
                            :disabled="reviewTemplateStatementsInUse"
                            :tooltip="
                              reviewTemplateStatementsInUse
                                ? 'Cannot delete as statements are in use'
                                : 'Delete Statement Group'
                            "
                            @click.prevent="removeStatementGroup(g)"
                          />
                        </div>
                      </div>

                      <div class="pl-8">
                        <div v-for="s in g.review_template_statements" :key="s.id">
                          <div class="d-flex align-center mb-2">
                            <review-template-read-only review-template-statements-in-use>
                              <template #default="{ disabled }">
                                <v-text-field
                                  v-model="s.statement"
                                  prepend-icon="mdi-square-small"
                                  hide-details
                                  density="compact"
                                  :disabled="disabled"
                                  @update:focused="x => !x && updateStatement(s)"
                                ></v-text-field>
                              </template>
                            </review-template-read-only>

                            <div class="pl-2">
                              <ndt-icon-button
                                icon="delete"
                                :disabled="reviewTemplateStatementsInUse"
                                :tooltip="
                                  reviewTemplateStatementsInUse
                                    ? 'Cannot delete as statements are in use'
                                    : 'Delete Statement'
                                "
                                @click.prevent="removeStatement(s)"
                              />
                            </div>
                          </div>

                          <div class="ml-10 mr-14 mb-4">
                            <div class="font-weight-bold mb-1">Assessment Guidance</div>
                            <div>
                              <mosaic-quill
                                v-model:contents="s.guidance"
                                :read-only="false"
                                @change="updateStatement(s)"
                              />
                            </div>
                          </div>
                        </div>

                        <div v-if="!reviewTemplateStatementsInUse" class="d-flex align-center mt-2">
                          <v-text-field
                            v-model="g.newStatement"
                            prepend-icon="mdi-square-small"
                            hide-details
                            density="compact"
                            label="New Statement"
                          />

                          <v-btn class="ml-4" :disabled="!g.newStatement" @click.prevent="addStatement(g)">Add</v-btn>
                        </div>
                      </div>
                    </div>

                    <div v-if="!reviewTemplateStatementsInUse" class="d-flex align-center">
                      <v-text-field
                        v-model="newStatementGroupName"
                        prepend-icon="mdi-format-list-bulleted-square"
                        hide-details
                        density="compact"
                        label="New Statement Group Name"
                      />

                      <v-btn class="ml-4" :disabled="!newStatementGroupName.length" @click.prevent="addStatementGroup()"
                        >Add</v-btn
                      >
                    </div>
                  </div>
                </div>
                <div v-else class="pt-4">
                  Judgements against statements have not been configured for this
                  {{ selectedInstitution.config.review_noun }} template. Go to the "Per
                  {{ standardNounCapitalised }} Assessments" area to configure them.
                </div>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </template>
      <template #preview>
        <review-standard-card
          :student-review-standard="studentReviewStandard"
          :student-review="reviewTemplateAsStudentReview"
          :staff-viewer="previewUser.staff"
          :student-viewer="previewUser.student"
          :student="previewStudent"
          :review-path="reviewPath"
        />
      </template>
    </review-template-header>
  </div>
</template>

<script>
import NdtIconButton from '@/components/NdtIconButton.vue';
import ReviewStandardCard from '@/components/ReviewStandardCard.vue';
import MosaicQuill from '@/components/quill/MosaicQuill.vue';
import { mapState, mapGetters } from 'vuex';
import ReviewTemplateHeader from '@/components/ReviewTemplateHeader.vue';
import config from '@/utils/config';
import ReviewTemplateReadOnly from '@/components/ReviewTemplateReadOnly.vue';

export default {
  name: 'TutorAdminReviewTemplateStandardPage',
  data: () => ({ config, newStatementGroupName: '' }),
  async created() {
    await this.$store.dispatch('loadReviewTemplate', { id: this.$route.params.templateId });
  },
  computed: {
    ...mapState(['roles', 'user', 'reviewTemplate', 'judgementSets', 'subjects', 'selectedInstitution']),
    ...mapGetters([
      'reviewTemplateAsStudentReview',
      'previewUser',
      'previewStudent',
      'subjectsWithNullOptionAndPrimarySeparation',
      'reviewNounCapitalised',
      'reviewTemplateInUse',
      'reviewTemplateStatementsInUse',
    ]),
    reviewPath() {
      return {
        text: this.reviewTemplate?.name,
        to: {
          name: 'TutorAdminReviewTemplatePage',
        },
      };
    },
    breadcrumbs() {
      return [
        {
          text: `${this.reviewNounCapitalised} Templates`,
          to: {
            name: 'TutorAdminReviewTemplatesListPage',
          },
        },
        this.reviewPath,
        {
          text: this.reviewTemplateStandard?.code,
        },
      ];
    },
    reviewTemplateStandard() {
      const id = this.$route.params.id;
      return this.reviewTemplate?.review_template_standards.find(x => x.id == id);
    },
    reviewTemplateStatementGroups() {
      return this.reviewTemplate.review_template_statement_set.review_template_statement_groups.filter(
        x =>
          (x.standard_id && x.standard_id == this.reviewTemplateStandard.standard_id) ||
          (x.substandard_id && x.substandard_id == this.reviewTemplateStandard.substandard_id)
      );
    },
    studentReviewStandard() {
      const id = this.$route.params.id;
      return this.reviewTemplateAsStudentReview.reviewStandards.find(x => x.id == id);
    },
  },
  methods: {
    noteChanged(x) {
      this.$store.dispatch('updateReviewTemplateStandard', {
        id: this.reviewTemplateStandard.id,
        updates: { note: x },
      });
    },
    subjectChanged(x) {
      this.$store.dispatch('updateReviewTemplateStandard', {
        id: this.reviewTemplateStandard.id,
        updates: { subject_id: x },
      });
    },
    addStatementGroup() {
      this.$store.dispatch('addReviewTemplateStatementGroup', {
        reviewTemplateStandardId: this.reviewTemplateStandard.id,
        name: this.newStatementGroupName,
      });
      this.newStatementGroupName = '';
    },
    removeStatementGroup(x) {
      this.$store.dispatch('removeReviewTemplateStatementGroup', {
        id: x.id,
      });
    },
    updateStatementGroupName(x) {
      this.$store.dispatch('updateReviewTemplateStatementGroup', {
        id: x.id,
        name: x.name,
      });
    },
    addStatement(reviewTemplateStatementGroup) {
      this.$store.dispatch('addReviewTemplateStatement', {
        groupId: reviewTemplateStatementGroup.id,
        statement: reviewTemplateStatementGroup.newStatement,
      });
      reviewTemplateStatementGroup.newStatement = '';
    },
    removeStatement(x) {
      this.$store.dispatch('removeReviewTemplateStatement', {
        id: x.id,
      });
    },
    updateStatement(x) {
      this.$store.dispatch('updateReviewTemplateStatement', {
        id: x.id,
        statement: x.statement,
        guidance: x.guidance,
      });
    },
  },
  components: {
    NdtIconButton,
    ReviewStandardCard,
    ReviewTemplateHeader,
    MosaicQuill,
    ReviewTemplateReadOnly,
  },
};
</script>
<style>
.v-select-fit .v-select__selections input {
  display: none;
}
</style>
