<template>
  <div>
    <v-card v-if="error">
      <v-card-text>{{ error }}</v-card-text>
    </v-card>
    <div v-else-if="!loadingPage">
      <v-card class="mb-4">
        <v-card-text>
          <div class="d-flex align-center pb-2">
            <mosaic-card-title class="pr-4">Calendar</mosaic-card-title>
            <div class="pl-4">
              <mosaic-switch
                name="calendar-visibility"
                :label="'Show Calendar for ' + traineeNounCapitalisedAndPluralised"
                v-model="enabledForStudents"
                object-type="visibility"
                :update-value="updateStudentVisibility"
              />
            </div>

            <v-spacer />
            <div>
              <v-select
                v-if="cohortsToSelect.length > 0"
                v-model="calendarSelectedCohortId"
                :items="cohortsToFilter"
                item-title="name"
                item-value="id"
                density="comfortable"
                hide-details
              >
                <template #item="{ item, props }">
                  <v-list-item v-bind="props" title="">
                    <span
                      v-if="item.raw.id != -2"
                      class="dot mr-2"
                      :style="`background-color: ${item.raw.borderColor} ;`"
                    ></span>
                    <span> {{ item.raw.name }}</span>
                  </v-list-item>
                </template>
                <template #selection="{ item }">
                  <span
                    v-if="item.raw.id != -2"
                    class="dot mr-2"
                    :style="`background-color: ${item.raw.borderColor} ;`"
                  ></span>
                  <span> {{ item.raw.name }}</span>
                </template>
              </v-select>
            </div>
          </div>
          <full-calendar
            :events="fullCalendarEvents"
            @on-event-click="onEventClick($event)"
            @on-select="onSelect($event)"
          />
        </v-card-text>
      </v-card>

      <ndt-dialog
        v-model:active="eventDialog.active"
        :title="eventDialog.editing ? 'Edit Event' : 'Create Event'"
        :error-message="eventDialog.error"
      >
        <v-text-field v-model="eventDialog.name" prepend-icon="mdi-calendar-clock" label="Name" type="text" autofocus />
        <mosaic-quill v-model:contents="eventDialog.description" :read-only="false" class="mb-4" />
        <v-select
          v-if="activeCohorts.length > 1"
          v-model="eventDialog.selectedCohortId"
          label="Cohort"
          :items="cohortsToSelect"
          item-title="name"
          item-value="id"
          prepend-icon="mdi-google-classroom"
        >
          <template #item="{ item, props }">
            <v-list-item v-bind="props" title="">
              <span
                v-if="item.raw.id != -2"
                class="dot mr-2"
                :style="`background-color: ${item.raw.borderColor} ;`"
              ></span>
              <span> {{ item.raw.name }}</span>
            </v-list-item>
          </template>
          <template #selection="{ item }">
            <span
              v-if="item.raw.id != -2"
              class="dot mr-2"
              :style="`background-color: ${item.raw.borderColor} ;`"
            ></span>
            <span> {{ item.raw.name }}</span>
          </template>
        </v-select>
        <div class="d-flex">
          <div style="flex-basis: 50%">
            <mosaic-date-picker v-model:date="eventDialog.startDate" label="Start Date" :exact-width="false" />
          </div>
          <div style="flex-basis: 50%">
            <mosaic-time-picker v-model="eventDialog.startTime" label="Start Time" />
          </div>
        </div>
        <div class="d-flex">
          <div style="flex-basis: 50%">
            <mosaic-date-picker v-model:date="eventDialog.endDate" label="End Date" :exact-width="false" />
          </div>
          <div style="flex-basis: 50%">
            <mosaic-time-picker v-model="eventDialog.endTime" label="End Time" />
          </div>
        </div>
        <template #header>
          <ndt-icon-button
            v-if="eventDialog.editing"
            icon="delete"
            tooltip="Delete Event"
            icon-color="black"
            @click.prevent="deleteEvent()"
          />
        </template>
        <template #buttons>
          <v-btn
            variant="text"
            ripple
            color="primary"
            :disabled="!canCreateEvent"
            @click.prevent="submitCreateEvent()"
            >{{ eventDialog.editing ? 'Save' : 'Create' }}</v-btn
          >
        </template>
      </ndt-dialog>
    </div>
  </div>
</template>

<script>
import NdtDialog from '@/components/NdtDialog.vue';
import NdtIconButton from '@/components/NdtIconButton.vue';
import MosaicQuill from '@/components/quill/MosaicQuill.vue';
import moment from 'moment';
import { calendarColors } from '@/theme';
import { mapState, mapGetters } from 'vuex';
import { DateTime } from 'luxon';
import { useTheme } from 'vuetify';
import FullCalendar from '@/components/calendar/FullCalendar.vue';
import { toISOString } from '@/utils/date';

export default {
  name: 'TutorAdminCalendarPage',
  components: { NdtDialog, NdtIconButton, MosaicQuill, FullCalendar },
  setup() {
    return {
      theme: useTheme(),
    };
  },
  data: function () {
    return {
      events: [],
      enabledForStudents: false,
      error: null,
      calendarSelectedCohortId: -2,
      loadingPage: true,
      eventDialog: {
        active: false,
        processing: false,
        editing: false,
        error: '',
        eventId: null,
        name: '',
        description: '',
        selectedCohortId: null,
        startDate: null,
        startTime: null,
        endDate: null,
        endTime: null,
      },
      allCohortColors: {
        backgroundColor: this.theme.global.current.value.colors['primary-lighten-1'],
        borderColor: this.theme.global.current.value.colors.primary,
      },
    };
  },
  computed: {
    ...mapState(['selectedInstitution']),
    ...mapGetters(['activeCohorts']),
    breadcrumbs() {
      return [{ text: 'Calendar' }];
    },
    canCreateEvent() {
      return this.eventDialog.name && !this.eventDialog.processing;
    },
    calendars() {
      const allCohorts = [
        {
          id: -1,
          name: 'All cohorts',
          ...this.allCohortColors,
        },
      ];
      if (!this.activeCohorts) return allCohorts;
      return allCohorts.concat(this.cohortsWithColors);
    },
    cohortsWithColors() {
      return this.activeCohorts.map((x, index) => ({
        id: x.id,
        name: x.name,
        ...this.cohortColors(index % calendarColors.length),
      }));
    },
    cohortsToFilter() {
      return [
        {
          id: -2,
          name: 'All events',
        },
        { id: -1, name: '"All Cohorts" events', ...this.allCohortColors },
      ].concat(this.cohortsWithColors);
    },
    cohortsToSelect() {
      if (!this.activeCohorts || this.activeCohorts.length === 1) return [];
      return [{ id: -1, name: 'All Cohorts', ...this.allCohortColors }].concat(this.cohortsWithColors);
    },
    fullCalendarEvents() {
      return this.events
        .map(e => {
          const calendar = this.calendars.find(c => c.id === e.cohort_id || (!e.cohort_id && c.id === -1));
          return {
            ...e,
            title: e.name,
            backgroundColor: calendar?.backgroundColor,
            borderColor: calendar?.borderColor,
          };
        })
        .filter(
          e =>
            e.cohort_id === this.calendarSelectedCohortId ||
            (!e.cohort_id && this.calendarSelectedCohortId === -1) ||
            this.calendarSelectedCohortId === -2
        );
    },
  },
  created() {
    this.loadCalendar();
    this.$store.dispatch('loadCohorts');
    this.enabledForStudents = this.selectedInstitution.config.show_calendar_for_students;
  },
  methods: {
    async loadCalendar() {
      try {
        const r = await this.$api.get(`/institutions/${this.selectedInstitution.id}/admin/calendar`);
        this.events = r.data;
        this.loadingPage = false;
      } catch (e) {
        console.log(e);
        this.error = 'Sorry, cannot load your calendar at the moment';
      }
    },
    // Creating new events by clicking or dragging on the calendar
    onSelect(x) {
      const dateTimes = parseDateTimes(x);

      this.eventDialog = {
        ...this.eventDialog,
        name: '',
        description: '',
        selectedCohortId: this.calendarSelectedCohortId > -2 ? this.calendarSelectedCohortId : -1,
        active: true,
        editing: false,
        ...dateTimes,
      };
    },
    // Edit existing event
    onEventClick(x) {
      const dateTimes = parseDateTimes(x.event);

      // Deliberately ==
      const event = this.events.find(e => e.id == x.event.id);
      this.eventDialog = {
        ...this.eventDialog,
        error: '',
        active: true,
        editing: true,
        eventId: event.id,
        name: event.name,
        description: event.description || null,
        selectedCohortId: event.cohort_id || -1,
        ...dateTimes,
      };
    },
    async submitCreateEvent() {
      this.eventDialog.error = '';
      const { start, end } = stringifyDateTimes(this.eventDialog);
      if (moment(start) > moment(end)) {
        this.eventDialog.error = 'The start of the event must be before the end';
        return;
      }

      this.eventDialog.processing = true;
      try {
        const cohortId = this.eventDialog.selectedCohortId === -1 ? null : this.eventDialog.selectedCohortId;
        if (this.eventDialog.editing) {
          await this.$api.put(`/admin/calendar/${this.eventDialog.eventId}`, {
            name: this.eventDialog.name,
            description: this.eventDialog.description,
            cohort_id: cohortId,
            start,
            end,
          });
        } else {
          await this.$api.post(`institutions/${this.selectedInstitution.id}/admin/calendar`, {
            name: this.eventDialog.name,
            description: this.eventDialog.description,
            cohort_id: cohortId,
            start,
            end,
          });
        }
        this.loadCalendar();
        this.calendarSelectedCohortId = this.calendarSelectedCohortId === -2 ? -2 : this.eventDialog.selectedCohortId;
        this.eventDialog.active = false;
      } catch (e) {
        this.eventDialog.error = `Sorry, cannot ${
          this.eventDialog.editing ? 'edit' : 'create'
        } your event at the moment`;
        console.log(e);
      }
      this.eventDialog.processing = false;
    },
    async deleteEvent() {
      await this.$api.delete(`/admin/calendar/${this.eventDialog.eventId}`);
      this.loadCalendar();
      this.eventDialog.active = false;
    },
    async updateStudentVisibility(enabledForStudents) {
      await this.$api.put(`institutions/${this.selectedInstitution.id}/admin/calendar/settings`, {
        show_calendar_for_students: enabledForStudents,
      });
      this.$store.commit('updateCalendarShowForStudents', enabledForStudents);
    },
    cohortColors(index) {
      return calendarColors[index];
    },
  },
};

function parseDateTimes(event) {
  const start = DateTime.fromJSDate(event.start);
  const startDate = start.toISO();
  const startTime = start.toFormat('HH:mm');

  // event.end doesn't exist when the start and end are the same
  const end = DateTime.fromJSDate(event.end || event.start);
  const endDate = end.toISO();
  const endTime = end.toFormat('HH:mm');

  return { startDate, startTime, endDate, endTime };
}

function stringifyDateTimes(x) {
  return { start: toISOString(x.startDate, x.startTime), end: toISOString(x.endDate, x.endTime) };
}
</script>

<style scoped>
.dot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
}
</style>
