<template>
  <div>
    <mosaic-error-alert :override-error-message="error" />
    <file-pond
      ref="filepond"
      name="file"
      :allow-multiple="allowMultiple"
      :allow-revert="allowRevert"
      :server="server"
      :max-file-size="maxFileSize"
      :before-add-file="beforeAddFile"
      @processfile="onProcessFile"
      @error="onError"
    />
  </div>
</template>

<script>
import vueFilePond from 'vue-filepond';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import { invalidCharacters } from '@/utils/files';
import { getAuthHeader, refreshAuthToken } from '@/utils/auth';

const FilePond = vueFilePond(FilePondPluginImagePreview, FilePondPluginFileValidateSize);

export default {
  name: 'MosaicFilePond',
  components: { FilePond },
  props: {
    url: {
      type: String,
      required: true,
    },
    maxFiles: {
      type: Number,
      default: null,
    },
    uploadData: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['fileCountUpdated', 'fileUploaded'],
  data: function () {
    return {
      error: '',
      allowMultiple: !this.maxFiles || this.maxFiles > 1,
      allowRevert: false,
      // OneDrive file size limit because of our API implementation
      maxFileSize: '60MB',
      server: {
        maxFiles: this.maxFiles,
        url: this.url,
        headers: getAuthHeader(),
        process: {
          url: '',
          method: 'POST',
          withCredentials: false,
          timeout: 30000,
          onload: x => {
            return JSON.parse(x).id;
          },
          ondata: formData => {
            Object.keys(this.uploadData).forEach(x => {
              formData.append(x, this.uploadData[x]);
            });
            return formData;
          },
          onerror: response => {
            if (response) {
              response = JSON.parse(response);
              if (response.error_code === 'storage_limit_exceeded') {
                this.error =
                  'You have exceeded the storage limit for your cloud storage account and therefore cannot upload any more files at the moment';
              }
            }
          },
        },
        // Are any of these useful?
        revert: null,
        restore: null,
        load: null,
        fetch: null,
      },
    };
  },
  methods: {
    onProcessFile(error, x) {
      if (!error) {
        this.$emit('fileCountUpdated', this.$refs.filepond.getFiles().length);
        this.$emit('fileUploaded', {
          title: x.file.name,
          fileId: x.serverId,
        });
      }
    },
    async onError(error, x) {
      console.log('onError', error, x);
      if (error?.code === 401) {
        await refreshAuthToken(this.$store);
      }
    },
    onClose() {
      this.$refs.filepond.removeFiles();
      this.$emit('fileCountUpdated', 0);
    },
    beforeAddFile(x) {
      this.error = '';
      const invalidChars = invalidCharacters.filter(c => x.filename.includes(c));
      if (invalidChars.length > 0) {
        this.error = `File name contains an invalid character: ${invalidChars.join(' ')}`;
        return false;
      }
      return true;
    },
  },
};
</script>

<style>
@import 'filepond/dist/filepond.min.css';
@import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
</style>
