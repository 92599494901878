<template>
  <mosaic-dialog
    :active="active"
    @update:active="emit('update:active', $event)"
    title="NASBTT Modules Not Enabled"
    close-button-text="Close"
  >
    <div>If you'd like to use NASBTT Modules with Mosaic then please get in touch with your account manager.</div>
  </mosaic-dialog>
</template>

<script setup lang="ts">
defineProps<{
  active: boolean;
}>();

const emit = defineEmits<{
  'update:active': [active: boolean];
}>();
</script>
