<template>
  <div />
</template>

<script>
import { logout } from '@/utils/auth';

export default {
  name: 'LogoutPage',
  created: function () {
    logout(this.$store).then(
      () => {
        this.$router.push({ name: 'LoginPage' });
      },
      e => console.log(e)
    );
  },
};
</script>
