<template>
  <mosaic-tooltip-chip color="secondary" variant="outlined"
    ><template #text
      ><mosaic-icon icon="mosaic-bud" />
      <mosaic-icon :icon="isCompleted ? 'mdi-check' : 'mdi-alert-outline'" :color="isCompleted ? 'success' : 'warning'"
    /></template>
    <template #tooltip>{{ tooltip }}</template>
  </mosaic-tooltip-chip>
</template>

<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps<{
  budActivity: {
    statusName: string;
    name: string;
  };
  mosaicObject: string;
}>();

const isCompleted = computed(() => {
  return props.budActivity.statusName === 'Completed';
});

const isInProgress = computed(() => {
  return props.budActivity.statusName === 'In Progress';
});

const tooltip = computed(() => {
  return isCompleted.value
    ? `This ${props.mosaicObject} has a submission against Bud Activity: ${props.budActivity.name}, which has been marked as complete`
    : isInProgress.value
    ? `This ${props.mosaicObject} has previously had a submission against this Bud Activity, but the submission has since been removed. A new submission can only be made via Bud`
    : `This ${props.mosaicObject} has a submission agaist a Bud Activity, but the Bud Activity is not marked as complete. This will need to be done via Bud`;
});
</script>
