a
<template>
  <div>
    <mosaic-loading-card v-if="loading || stateProcessing" type="card" />
    <mosaic-load-error-card v-else-if="loadError || stateError" :object-type="curriculumNoun" @retry="load" />
    <curriculum-statement-card
      v-else
      :curriculum-statement="curriculumStatement"
      :editable="editable"
      :can-access-reflections="userStaffHasPermission('Admin')"
      :show-theory="selectedCurriculum.show_theory"
      :click-reflection-to="clickReflectionTo"
      :click-target-to="clickTargetTo"
      :click-lesson-observation-to="clickLessonObservationTo"
      :click-mentor-meeting-to="clickMentorMeetingTo"
      :click-cohort-course-week-to="clickCohortCourseWeekTo"
      :click-student-assignment-to="clickStudentAssignmentTo"
      :click-curriculum-teaching-strategy-to="clickCurriculumTeachingStrategyTo"
    />
  </div>
</template>

<script>
import CurriculumStatementCard from './CurriculumStatementCard.vue';
import { mapState } from 'vuex';
import { mapStateProcessingAndError } from '@/store/map-store';
import { fromSnakeCaseToCamelCase } from '@/utils/transforms';

export default {
  name: 'TutorCurriculumStatementPage',
  data: function () {
    return {
      curriculumStatementId: null,
      curriculumStatement: null,
      editable: false,
      loading: true,
      loadError: false,
    };
  },
  async created() {
    this.curriculumStatementId = this.$route.params.id.toString();
    await this.load();
  },
  computed: {
    ...mapState(['user', 'selectedStudent', 'selectedCurriculum']),
    ...mapStateProcessingAndError(['loadCurriculum']),
    breadcrumbs() {
      return [
        {
          text: this.curriculumNoun,
          to: {
            name: 'TutorCurriculumPage',
          },
        },
        {
          text: this.curriculumStatement?.code,
        },
      ];
    },
    title: function () {
      let title = 'Curriculum';
      if (!this.curriculumStatement) return title;
      title += ' - ';
      title += this.curriculumStatement.curriculum_theme.name;
      return title;
    },
  },
  methods: {
    async load() {
      await Promise.all([this.loadCurriculumStatement(), this.$store.dispatch('loadCurriculum')]);
    },
    async loadCurriculumStatement() {
      this.loading = true;
      this.loadError = false;
      try {
        const r = await this.$api.get(
          `/students/${this.selectedStudent.id}/curriculum-statements/${this.curriculumStatementId}`
        );
        this.curriculumStatement = r.data;
        this.curriculumStatement.theory_content_layout = fromSnakeCaseToCamelCase(r.data.theory_content_layout);
        this.curriculumStatement.practice_content_layout = fromSnakeCaseToCamelCase(r.data.practice_content_layout);
      } catch (e) {
        console.log(e);
        this.loadError = true;
      }
      this.loading = false;
    },
    clickTargetTo(target) {
      return {
        name: 'TargetPage',
        params: { targetId: target.id },
      };
    },
    clickReflectionTo(reflection) {
      if (this.userStaffHasPermissionForSelectedStudent('student.reflections.view')) {
        return {
          name: 'ReflectionPage',
          params: { reflectionId: reflection.id },
        };
      }
    },
    clickLessonObservationTo(lo) {
      return {
        name: 'LessonObservationPage',
        params: { id: lo.id },
      };
    },
    clickMentorMeetingTo(meeting) {
      return {
        name: 'MentorMeetingPage',
        params: { id: meeting.id },
      };
    },
    clickCohortCourseWeekTo(week) {
      return {
        name: 'TutorCohortCourseWeekPage',
        params: { studentWeekId: week.id },
      };
    },
    clickStudentAssignmentTo(sa) {
      return {
        name: 'TutorAssignmentLatestVersionPage',
        params: { id: sa.assignment_id },
      };
    },
    clickCurriculumTeachingStrategyTo(ts) {
      return {
        name: 'TutorCurriculumTeachingStrategyPage',
        params: { id: ts.id },
      };
    },
  },
  components: { CurriculumStatementCard },
};
</script>
