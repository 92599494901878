// Has an equivalent s3_file_extensions.rb that has to be manually kept in sync
// These aren't identical lists (the front end is more permissivie for ease), but the backend should catch and return errors
const allowedS3ContentTypes = [
  // Images/audio/video
  'image/*', // includes .jpg .jpeg .jfif .jpf .png .svg .gif
  'audio/*', // includes .wav .m4a .wma .mp3
  'video/*', // includes .mov .mp4 .m4v .wmv

  // PDF
  'application/pdf', // .pdf

  // Word processing
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
  'application/msword', //.doc .dot
  'application/vnd.openxmlformats-officedocument.wordprocessingml.template', // .dotx
  'application/vnd.ms-word.document.macroEnabled.12', // .docm
  'application/vnd.ms-word.template.macroEnabled.12', // .dotm
  'application/vnd.oasis.opendocument.text', // .odt
  'application/vnd.google-apps.document', // .gdoc
  'application/vnd.google-apps.kix', // readonly .gdoc
  'application/vnd.apple.pages', // .pages
  'application/x-iwork-pages-sffpages', // .pages

  // Presentation
  'application/vnd.ms-powerpoint', // .ppt .pot .pps .ppa
  'application/vnd.openxmlformats-officedocument.presentationml.presentation', // .pptx
  'application/vnd.openxmlformats-officedocument.presentationml.template', // .potx
  'application/vnd.openxmlformats-officedocument.presentationml.slideshow', // .ppsx
  'application/vnd.ms-powerpoint.addin.macroEnabled.12', // .ppam
  'application/vnd.ms-powerpoint.presentation.macroEnabled.12', // .pptm
  'application/vnd.ms-powerpoint.template.macroEnabled.12', // .potm
  'application/vnd.ms-powerpoint.slideshow.macroEnabled.12', // .ppsm
  'application/vnd.oasis.opendocument.presentation', // .odp
  'application/vnd.google-apps.presentation', //  .gslides
  'application/vnd.apple.keynote', // .keynote
  'application/x-iwork-keynote-sffkey', // .keynote

  // Spreadsheets
  'application/vnd.ms-excel', // .xls .xlt .xla
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx
  'application/vnd.openxmlformats-officedocument.spreadsheetml.template', // .xltx
  'application/vnd.ms-excel.sheet.macroEnabled.12', // .xlsm
  'application/vnd.ms-excel.template.macroEnabled.12', // .xltm
  'application/vnd.ms-excel.addin.macroEnabled.12', // .xlam
  'application/vnd.ms-excel.sheet.binary.macroEnabled.12', // .xlsb
  'application/vnd.oasis.opendocument.spreadsheet', // .ods
  'application/vnd.google-apps.spreadsheet', // .gsheet
  'application/vnd.apple.numbers', // .numbers
  'application/x-iwork-numbers-sffnumbers', // .numbers

  // Text
  'text/plain', // .txt
  'application/rtf', // .rtf

  // Others
  'text/csv', // .csv
  'application/x-mspublisher', // .pub
  'application/x-font-ttf', // .ttf
  'application/epub+zip', // .epub
  'message/rfc822', // .eml
  'application/vnd.ms-outlook', // .msg
  'application/x-sibelius-score', // .sib
  'video/x-fli', // .flipchart
  'application/x-flipchart', // .flipchart - made up mimetype, custom validation used
  '.flipchart', // .flipchart - prevents filepicker from greying out
  'image/vnd.adobe.photoshop', // .psd
  'application/xspf+xml', // .xspf
  'application/x-notebook', // .notebook - made up mimetype, custom validation used
  '.notebook', // .notebook - prevents filepicker from greying out where custom validation used
  'application/x-mspublisher', // .pub - I don't think this is made up, but doesn't seem to be autodetected
  '.pub', // .pub - prevents filepicker from greying out where custom validation used
];

export { allowedS3ContentTypes };
