import { countObjectTypeHas } from '@/utils/text';

export function countHasBeenAssignedTooltip(
  objectType: string,
  count: number,
  countObjectType: string,
  countObjectTypePlural?: string
) {
  return countObjectTypeHas(count, countObjectType, countObjectTypePlural) + ' been assigned this ' + objectType;
}
