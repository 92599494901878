<template>
  <div>
    <v-card v-if="error">
      <v-card-text>
        <div class="pa-4">{{ error }}</div>
      </v-card-text>
    </v-card>
    <review-overall-card
      v-else-if="studentReviewForStaff"
      :student-review="studentReviewForStaff"
      :staff-viewer="userStaff"
      :student="selectedStudent"
      :review-path="reviewPath"
      @update:dirty="dirty = $event"
    />
    <unsaved-changes-dialog
      v-model:unsaved-changes-dialog="unsavedChangesDialog"
      :object-type="reviewNounCapitalised"
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import ReviewOverallCard from '@/components/ReviewOverallCard.vue';
import unsavedChangesMixin from '@/components/unsaved-changes-mixin';
import UnsavedChangesDialog from '@/components/UnsavedChangesDialog.vue';
import clearStudentReviewMixin from '@/mixins/clear-student-review';

export default {
  name: 'TutorReviewOverallPage',
  mixins: [unsavedChangesMixin, clearStudentReviewMixin],
  ...unsavedChangesMixin,
  created() {
    this.$store.dispatch('loadStudentReview', { id: this.$route.params.id });
  },
  computed: {
    ...mapState(['selectedStudent', 'userStaff']),
    ...mapState({
      error: state => state.studentReviewPages.error,
    }),
    ...mapGetters(['studentReviewForStaff']),
    reviewPath() {
      return {
        text: this.studentReviewForStaff?.name,
        to: {
          name: 'TutorReviewPage',
          params: { id: this.studentReviewForStaff?.id },
        },
      };
    },
    breadcrumbs() {
      return [
        {
          text: this.reviewNounCapitalisedAndPluralised,
          to: {
            name: 'TutorReviewsListPage',
          },
        },
        {
          text: this.studentReviewForStaff?.name,
          to: {
            name: 'TutorReviewPage',
            params: { id: this.studentReviewForStaff?.id },
          },
        },
        {
          text: 'General',
        },
      ];
    },
  },
  components: { ReviewOverallCard, UnsavedChangesDialog },
};
</script>
