<template>
  <div>
    <v-card v-if="error">
      <v-card-text>
        <div class="pa-4">{{ error }}</div>
      </v-card-text>
    </v-card>
    <v-card v-if="!busy && !error" class="mb-4">
      <v-card-text>
        <div class="text-h6">Copy From Existing {{ reviewNounCapitalised }} Template</div>
        <div class="pa-2">
          <v-select
            v-model="reviewTemplateId"
            prepend-icon="mdi-clipboard-list"
            :items="reviewTemplates"
            item-title="name"
            item-value="id"
            :placeholder="`Select ${reviewNounCapitalised} Template`"
          ></v-select>
          <v-text-field v-model="name" prepend-icon="mdi-pencil" label="Template Name"></v-text-field>
          <v-select
            v-if="selectedInstitution.config.early_careers"
            v-model="reviewType"
            class="pl-8"
            :items="reviewTypeItems"
            :label="`${reviewNounCapitalised} Type`"
          />
          <div v-if="hasStatements">
            <mosaic-checkbox
              v-model="useStatementSet"
              no-icon
              hide-detailsdensity="compact"
              label="Use the same statements"
            />
            <div class="pl-2 pt-1">
              Using the same statements allows assessments of progress against the statements to be tracked between
              multiple {{ reviewNounCapitalisedAndPluralised }}, but they cannot be edited. Unchecking this makes a copy
              of the statements, which allows editing, but no tracking between {{ reviewNounCapitalisedAndPluralised }}.
            </div>
          </div>
          <mosaic-error-alert :override-error-message="saveError" />
          <div class="mt-2 text-right">
            <v-btn variant="text" ripple :disabled="processing || !canSubmit" @click.prevent="next()">Next</v-btn>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'TutorAdminReviewTemplateCopyPage',
  data: () => ({
    reviewTemplates: null,
    busy: true,
    processing: false,
    error: '',
    saveError: '',
    name: '',
    reviewTemplateId: null,
    useStatementSet: true,
    reviewType: null,
  }),
  async created() {
    this.loadReviewTemplates();
  },
  computed: {
    ...mapState(['selectedInstitution', 'reviewTypeItems']),
    ...mapGetters(['reviewNounCapitalised', 'reviewNoun']),
    breadcrumbs() {
      return [
        {
          text: `${this.reviewNounCapitalised} Templates`,
          to: {
            name: 'TutorAdminReviewTemplatesListPage',
          },
        },
        {
          text: `Copy ${this.reviewNounCapitalised} Template`,
        },
      ];
    },
    canSubmit() {
      return this.reviewTemplateId && this.name;
    },
    reviewTemplate() {
      if (!this.reviewTemplates) return null;
      return this.reviewTemplates.find(x => x.id === this.reviewTemplateId);
    },
    hasStatements() {
      if (!this.reviewTemplate) return false;
      return this.reviewTemplate.has_statements;
    },
  },
  watch: {
    reviewTemplate(x) {
      if (x) {
        this.reviewType = x.review_type;
      }
    },
  },
  methods: {
    async loadReviewTemplates() {
      this.busy = true;
      try {
        const response = await this.$api.get(`/institutions/${this.selectedInstitution.id}/review-templates-for-copy`);
        this.reviewTemplates = response.data;
      } catch (e) {
        this.error = `Sorry, cannot load your ${this.reviewNoun} templates`;
        console.log(e);
      }
      this.busy = false;
    },
    async next() {
      this.processing = true;
      this.saveError = '';
      try {
        const template = await this.$api.post(`review-templates/${this.reviewTemplateId}/copy`, {
          name: this.name,
          use_statement_set: this.hasStatements && this.useStatementSet,
          review_type: this.reviewType,
        });
        this.$router.push({
          name: 'TutorAdminReviewTemplatePage',
          params: { templateId: template.data.id },
        });
      } catch (e) {
        console.log(e);
        this.saveError = `Sorry, cannot create your ${this.reviewNoun} template`;
      }
      this.processing = false;
    },
  },
};
</script>
