<template>
  <div>
    <div v-if="selectedCohortStudents.length === 0" class="py-4">
      There are no {{ traineeNounCapitalisedAndPluralised }} in this Cohort. Add some on the
      <mosaic-router-link
        :to="{
          name: 'TutorStudentListPage',
        }"
        >{{ traineeNounCapitalisedAndPluralised }} page</mosaic-router-link
      >.
    </div>
    <slot v-else />
  </div>
</template>

<script setup lang="ts">
import { useCohortStore } from '@/stores/cohort';

const { selectedCohortStudents } = useCohortStore();
</script>
