<template>
  <div :style="`min-width: ${minWidth}`">
    <!-- All props passed, but not defined in the props section are available as $attrs -->
    <v-select
      v-if="!readonly"
      v-bind="$attrs"
      :id="name"
      :label="label"
      :model-value="modelValue"
      :name="name"
      color="primary"
      :class="{ 'pl-8': !prependIcon && !noIcon && !noMargin }"
      :style="{ width: halfWidth ? '45%' : 'unset' }"
      :multiple="multiple"
      :chips="multiple && !shouldReplaceLabel && !hasSelectionSlot"
      :items="items"
      :item-value="itemValue"
      :item-title="itemTitle"
      :rules="allRules"
      :hide-details="hideDetails"
      :density="dense ? 'compact' : 'default'"
      :variant="variant"
      :no-data-text="noItemsText"
      :persistent-placeholder="multiple"
      @update:model-value="$emit('update:modelValue', $event)"
      @update:focused="focused = $event"
      @update:menu="onMenuUpdate"
    >
      <template v-if="$slots.item" #item="{ item, props }">
        <slot name="item" v-bind="{ item, props }"></slot>
      </template>
      <template #prepend v-if="prependIcon">
        <mosaic-icon :color="focused ? 'primary' : undefined" :icon="prependIcon" />
      </template>
      <template v-if="$slots.selection" #chip="{ item, props }">
        <slot name="selection" v-bind="{ item, props }"></slot>
      </template>
      <template v-if="(multiple && shouldReplaceLabel) || hasSelectionSlot" #selection="{ index, item }">
        <span v-if="multiple && shouldReplaceLabel && index === 0">
          <v-tooltip v-if="!isMenuOpen">
            <template #activator="{ props }">
              <span class="no-wrap" v-bind="props">{{ summarizedLabel }} </span>
            </template>
            <div>Selected:</div>
            <ul v-for="(sd, i) in selectedItemsDetails" :key="i">
              <li>{{ sd[itemTitle] }}</li>
            </ul>
          </v-tooltip>
          <span v-else>
            <span class="no-wrap"> {{ summarizedLabel }}</span></span
          >
        </span>
        <span v-if="hasSelectionSlot && !shouldReplaceLabel">
          <slot name="custom-selection" :index="index" :item="item"></slot>
        </span>
      </template>
      <template #prepend-inner v-if="multiple && modelValue.length === 0">
        <span class="text-secondary no-wrap">Nothing selected</span>
      </template>
    </v-select>
    <div
      v-else
      class="d-flex align-center"
      :class="{ 'mb-4': !hideDetails }"
      :style="{ marginTop: dense ? '-12px' : 'unset' }"
      :data-test-name="name"
    >
      <mosaic-icon v-if="prependIcon" :icon="prependIcon" class="mt-5" />
      <div v-else :class="{ 'pl-6': !noIcon }"></div>
      <div class="pl-4">
        <div class="text-caption">{{ label }}</div>
        <div class="text-body-1">{{ readOnlyValue }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { requiredListInput } from '../../../utils/validations';
export default {
  name: 'MosaicSelect',
  props: {
    readonly: {
      type: Boolean,
      default: false,
    },
    minWidth: {
      type: String,
      default: 'unset',
    },
    noMargin: {
      type: Boolean,
    },
    prependIcon: {
      type: String,
      required: false,
    },
    variant: {
      type: String,
      default: 'underlined',
    },
    noIcon: {
      type: Boolean,
      default: false,
    },
    halfWidth: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: '',
    },
    label: {
      type: String,
    },
    // eslint-disable-next-line vue/require-prop-types
    modelValue: {
      required: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: () => [],
    },
    itemValue: {
      type: String,
      default: 'value',
    },
    itemTitle: {
      type: String,
      default: 'text',
    },
    rules: {
      type: Array,
      default: () => [],
    },
    required: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    noItemsText: {
      type: String,
      required: false,
    },
  },
  emits: ['update:modelValue'],
  data: () => {
    return {
      requiredListInput,
      focused: false,
      isMenuOpen: false,
    };
  },
  computed: {
    selectedItemsDetails() {
      if (this.modelValue.length === this.items.length) return this.items;
      return this.modelValue.map(id => this.items.find(item => item[this.itemValue] === id));
    },
    isObjectItems() {
      return this.items.some(x => typeof x === 'object');
    },
    hasSelectionSlot() {
      return !!this.$slots['custom-selection'];
    },
    readOnlyValue() {
      if (this.isObjectItems) {
        if (this.multiple) {
          return (this.modelValue || []).length > 0
            ? this.modelValue.map(x => this.findTextFromValue(x)).join(', ')
            : 'Not Recorded';
        } else return this.findTextFromValue(this.modelValue) || 'Not Recorded';
      } else {
        if (this.multiple) {
          return (this.modelValue || []).length > 0 ? this.modelValue.join(', ') : 'Not Recorded';
        } else return this.modelValue || 'Not Recorded';
      }
    },
    allRules() {
      return this.required ? [this.requiredListInput(this.multiple), ...this.rules] : this.rules;
    },
    summarizedLabel() {
      return this.modelValue.length === 0
        ? 'Nothing selected'
        : this.items.length === this.modelValue.length
        ? 'All selected'
        : this.items.length > this.modelValue.length && this.modelValue.length > 2
        ? `${this.modelValue.length} selected`
        : '';
    },
    shouldReplaceLabel() {
      return (
        this.modelValue.length === 0 ||
        this.modelValue.length === this.items.length ||
        (this.items.length > this.modelValue.length && this.modelValue.length > 2)
      );
    },
  },
  methods: {
    onMenuUpdate(isOpen) {
      this.isMenuOpen = isOpen;
    },
    findTextFromValue(value) {
      const item = this.items.find(x => x[this.itemValue] === value);
      if (!item) return null;
      return item[this.itemTitle];
    },
  },
};
</script>
<style scoped>
.no-wrap {
  white-space: nowrap;
}
</style>
