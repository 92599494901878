<template>
  <div>
    <evidence-filters :standards="standards" />

    <mosaic-card v-if="showStorageMessage" class="mb-4">
      <v-alert type="info">
        <div>
          In order to open {{ userStudent ? 'your' : traineeNounCapitalised() }} files linked as Evidence, you must link
          a storage account.
        </div>
        <div v-if="storageConstraint" class="pt-2">{{ storageConstraintMessage }}</div>
      </v-alert>
      <v-alert v-if="storageEmailError" type="error"
        ><div>{{ storageEmailError }}</div>
        <div class="mt-2">
          Please try again with an allowable One Drive account. You may need to
          <a href="https://login.microsoftonline.com/logout.srf" target="_blank">logout</a> of your One Drive account
          first.
        </div>
      </v-alert>
      <div class="d-flex">
        <div class="flex-grow-1" />
        <div class="mt-4 align-right">
          <v-btn class="ml-6" @click.prevent="navigateToStorageLogin()"
            >Confirm {{ selectedInstitution.storage_type }} Email</v-btn
          >
        </div>
      </div>
    </mosaic-card>

    <v-card>
      <v-card-text class="pa-0">
        <mosaic-tabs :headers="tabHeaders" @tab-selected="currentTab = $event">
          <template #evidence>
            <template v-if="filteredEvidence.length === 0">
              <div class="pl-6 pa-4">
                No Evidence has been added<span v-if="evidence.length > 0"> for these filters</span>.
                <template v-if="userStudent">
                  <br />
                  <br />
                  Either tag some existing evidence with this type or create some evidence from
                  <a @click.prevent="navigateToMyFiles()" class="text-link">your files</a>.
                </template>
              </div>
            </template>

            <div class="pt-2 pb-4 px-5" v-else>
              <mosaic-list :two-line="!smallScreen" :three-line="smallScreen" :items="paginatedEvidence" class="pb-0">
                <template #item="{ item: e }">
                  <evidence-list-item
                    :class="{ 'px-2': smallScreen }"
                    :key="e.id"
                    :evidence="e"
                    :edit-evidence="editEvidence"
                    :delete-evidence="deleteEvidence"
                    :can-view-reflections="canViewReflections"
                    @click-favourite="favouriteEvidenceClick"
                    @click-target="x => $emit('clickTarget', x)"
                    @click-reflection="x => $emit('clickReflection', x)"
                    @click-lesson-observation="x => $emit('clickLessonObservation', x)"
                    @click-mentor-meeting="x => $emit('clickMentorMeeting', x)"
                    @click-student-assignment="x => $emit('clickStudentAssignment', x)"
                  />
                </template>
              </mosaic-list>
              <mosaic-pagination v-model="currentPage" v-model:page-size="pageSize" :total="filteredEvidence.length" />
            </div>
          </template>
          <template #standards>
            <template v-for="(standard, index) in filteredStandardEvidence" :key="index">
              <standard-card
                class="mb-4"
                :standard="standard"
                :edit-evidence="editEvidence"
                :delete-evidence="deleteEvidence"
                :can-view-reflections="canViewReflections"
                @click-favourite="favouriteEvidenceClick"
                @click-target="x => $emit('clickTarget', x)"
                @click-reflection="x => $emit('clickReflection', x)"
                @click-lesson-observation="x => $emit('clickLessonObservation', x)"
                @click-mentor-meeting="x => $emit('clickMentorMeeting', x)"
                @click-student-assignment="x => $emit('clickStudentAssignment', x)"
              />
            </template>
          </template>
        </mosaic-tabs>
      </v-card-text>
    </v-card>
    <EditEvidenceDialog
      v-model:active="editEvidenceDialog.active"
      :on-close="evidenceEdited"
      :evidence-types="types"
      :evidence="editEvidenceDialog.evidence"
    />
    <ndt-dialog v-model:active="deleteDialog.active" title="Delete Evidence" :width="500">
      <span>Are you sure you want to delete "{{ deleteDialog.title }}"?</span>
      <template #buttons>
        <v-btn
          variant="text"
          ripple
          color="error"
          :disabled="deleteDialog.processing"
          @click.prevent="submitDeleteEvidence()"
        >
          Delete
        </v-btn>
      </template>
    </ndt-dialog>
  </div>
</template>

<script>
import { evidenceTypes } from '../models/evidence-types';
import evidenceMethods from '../utils/evidence-methods';
import EvidenceFilters from './EvidenceFilters.vue';
import EvidenceListItem from './EvidenceListItem.vue';
import StandardCard from './StandardCard.vue';
import NdtDialog from './NdtDialog.vue';
import EditEvidenceDialog from './student/EditEvidenceDialog.vue';
import config from '../utils/config';
import { mapState, mapGetters } from 'vuex';
import { storageConstraintMessage, storageEmailError } from '../utils/storage-constraints';
import {
  startStudentLinkOneDriveFlow,
  startStaffLinkOneDriveEmailMicrosoftFlow,
  startStudentLinkGoogleDriveFlow,
  startStaffLinkGoogleDriveEmailFlow,
} from '../utils/external-auth';

export default {
  name: 'EvidenceListViews',
  props: {
    initialEvidence: {
      type: Array,
      required: true,
    },
    canViewReflections: {
      type: Boolean,
      required: true,
    },
    storageConstraint: {
      type: Object,
      default: undefined,
    },
  },
  emits: ['clickTarget', 'clickReflection', 'clickLessonObservation', 'clickMentorMeeting', 'clickStudentAssignment'],
  data: function () {
    return {
      config: config,
      editEvidenceDialog: {
        active: false,
        evidence: {},
      },
      deleteDialog: {
        active: false,
        id: null,
        title: '',
        processing: false,
      },
      evidence: JSON.parse(JSON.stringify(this.initialEvidence)),
      tabHeaders: [
        {
          key: 'evidence',
          text: 'Evidence',
          icon: 'mdi-file-document-outline',
        },
        {
          key: 'standards',
          text: 'Standards',
          icon: 'mdi-book-open-page-variant',
        },
      ],
      currentPage: 1,
      pageSize: 10,
    };
  },

  computed: {
    ...mapState(['standards', 'selectedInstitution', 'selectedStudent', 'evidenceFilters', 'userStudent']),
    ...mapGetters(['storageSetUp', 'useOnDemandSharing']),
    types() {
      return evidenceTypes.filter(x => !this.selectedInstitution.config.early_careers || x != 'Reflection');
    },
    storageConstraintMessage() {
      if (!this.storageConstraint) return false;
      return storageConstraintMessage(this.storageConstraint);
    },
    storageEmailError() {
      return storageEmailError(this.$route.query, this.storageConstraint);
    },
    showStorageMessage() {
      return (
        this.selectedInstitution.storage_type != 'S3' &&
        !this.useOnDemandSharing &&
        !this.storageSetUp &&
        this.evidence.some(e => e.evidence_file)
      );
    },

    filteredEvidence() {
      return this.evidence.filter(e => {
        // Nothing selected is the same as everything selected
        const matchesStandardFilter =
          this.evidenceFilters.standardCodes.length > 0
            ? e.substandards.some(ss => this.evidenceFilters.standardCodes.includes(ss.standard.code))
            : true;

        const matchesEvidenceFilter =
          this.evidenceFilters.types.length > 0
            ? this.evidenceFilters.types.includes(e.evidence_type) ||
              (this.evidenceFilters.types.includes('No type') && e.evidence_type == null)
            : true;

        return matchesStandardFilter && matchesEvidenceFilter && (!this.evidenceFilters.onlyFavourites || e.favourite);
      });
    },
    paginatedEvidence() {
      const index = (this.currentPage - 1) * this.pageSize;
      return this.filteredEvidence.slice(index, index + this.pageSize);
    },
    filteredStandards() {
      return this.standards.filter(s => {
        return this.evidenceFilters.standardCodes.length > 0
          ? this.evidenceFilters.standardCodes.includes(s.code)
          : true;
      });
    },
    filteredStandardEvidence() {
      const standardEvidence = evidenceMethods.simpleCrissCross(this.filteredEvidence, this.filteredStandards);
      return standardEvidence;
    },
  },
  watch: {
    filteredEvidence() {
      this.currentPage = 1;
    },
  },
  methods: {
    editEvidence(evidence) {
      this.editEvidenceDialog = {
        active: true,
        evidence,
      };
    },
    deleteEvidence(evidence) {
      this.deleteDialog = {
        active: true,
        id: evidence.id,
        title: evidence.title,
        processing: false,
      };
    },
    navigateToStorageLogin() {
      if (this.selectedInstitution.storage_type === 'One Drive') {
        if (this.userStudent) startStudentLinkOneDriveFlow(this.$route.path);
        else startStaffLinkOneDriveEmailMicrosoftFlow(this.$route.path, this.selectedInstitution.id);
      } else {
        if (this.userStudent) startStudentLinkGoogleDriveFlow(this.$route.path, this.$api);
        else startStaffLinkGoogleDriveEmailFlow(this.$route.path, this.$api);
      }
    },
    async favouriteEvidenceClick(evidence) {
      const i = this.evidence.findIndex(e => e.id === evidence.id);
      this.evidence[i].favourite = !evidence.favourite;
      try {
        await this.$api.put(`/evidence/${evidence.id}`, {
          favourite: this.evidence[i].favourite,
        });
      } catch (e) {
        console.log(e);
        this.evidence[i].favourite = evidence.favourite;
      }
    },
    fileUploading: function () {
      this.createDialog.processing = true;
    },
    evidenceEdited: function (evidence) {
      const i = this.evidence.findIndex(e => e.id === evidence.id);
      // updates the array such that Vue knows it has changed
      this.evidence.splice(i, 1, evidence);
    },
    submitDeleteEvidence: function () {
      this.deleteDialog.processing = true;
      this.$api.delete(`/evidence/${this.deleteDialog.id}`).then(() => {
        this.deleteDialog.active = false;
        this.evidence = this.evidence.filter(e => e.id !== this.deleteDialog.id);
      });
    },
    navigateToMyFiles: function () {
      this.$router.push({ name: 'FilesListPage', params: this.userStudent ? { studentId: this.userStudent.id } : {} });
    },
  },
  components: {
    EvidenceFilters,
    StandardCard,
    EvidenceListItem,
    NdtDialog,
    EditEvidenceDialog,
  },
};
</script>
