<template>
  <div>
    <div :key="readOnly">
      <div ref="editor" :style="hasMinHeight || minHeight !== '200px' ? `min-height: ${minHeight}` : ''" />
    </div>
    <mosaic-error-alert :override-error-message="error" />
  </div>
</template>

<script>
import Quill from 'quill';
import LoadingImage from './loading-image';
import { ImageData } from 'quill-image-drop-and-paste';
import { mapState } from 'vuex';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import CustomColor from './spell-check-fix';

export default {
  name: 'MosaicQuill',
  props: {
    readOnly: {
      type: Boolean,
      required: true,
    },
    readOnlyBorder: {
      type: Boolean,
      default: false,
    },
    contents: {
      type: [String, null],
      default: null,
    },
    hasMinHeight: {
      type: Boolean,
      default: false,
    },
    // Most times we want this default min height, but this allows a custom amount to be passed in
    minHeight: {
      type: String,
      default: '200px',
    },
  },
  emits: ['update:contents', 'update:contents-by-user', 'change'],
  data: () => ({
    currentContent: '',
    error: null,
  }),
  watch: {
    contents(content) {
      if (content != this.currentContent) {
        this.setContents(content);
      }
    },
    readOnly() {
      this.$nextTick(() => this.createEditor());
    },
  },
  mounted() {
    this.createEditor();
  },
  computed: {
    ...mapState(['user', 'selectedInstitution']),
    presignUrl() {
      return this.user.isAdmin
        ? `/presign/admin/institutions/${this.selectedInstitution.id}/quill`
        : `/presign/institutions/${this.selectedInstitution.id}/quill`;
    },
  },
  methods: {
    createEditor() {
      this.editor = new Quill(this.$refs.editor, {
        modules: {
          toolbar: !this.readOnly && [
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ font: [] }],
            ['bold', 'italic', 'underline', 'link'], // toggled buttons
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
            [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
            [{ color: [] }, { background: [] }], // dropdown with defaults from theme
            [{ align: [] }],
            ['image'],
            ['clean'], // remove formatting button
          ],
          imageDropAndPaste: !this.readOnly
            ? {
                handler: this.imageHandler,
              }
            : undefined,
          imageResize: !this.readOnly
            ? {
                modules: ['Resize', 'DisplaySize'],
              }
            : undefined,
        },
        readOnly: this.readOnly,
        theme: this.readOnly && !this.readOnlyBorder ? null : 'snow',
      });
      this.editor.root.setAttribute('spellcheck', true);
      this.editor.on('text-change', (_delta, _oldDelta, source) => {
        const jsonContent = this.getEditorContentJson(this.editor);
        this.$emit('update:contents', jsonContent);
        // TODO: a proper review of component usage to see if everyone can use the user stream
        if (source === 'user') {
          this.$emit('update:contents-by-user', jsonContent);
        }
        this.currentContent = jsonContent;
      });
      this.editor.on('selection-change', range => {
        // An unfocus event
        if (!range) {
          this.$emit('change', this.currentContent);
        }
      });

      if (!this.readOnly) {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const componentThis = this;
        this.editor.getModule('toolbar').addHandler('image', function (clicked) {
          if (clicked) {
            let fileInput = this.container.querySelector('input.ql-image[type=file]');
            if (fileInput == null) {
              fileInput = document.createElement('input');
              fileInput.setAttribute('type', 'file');
              fileInput.setAttribute('accept', 'image/*');
              fileInput.classList.add('ql-image');
              fileInput.addEventListener('change', function (e) {
                const files = e.target.files;
                let file;
                if (files.length > 0) {
                  file = files[0];
                  const type = file.type;
                  const reader = new FileReader();
                  reader.onload = e => {
                    const dataUrl = e.target.result;
                    componentThis.imageHandler(dataUrl, type, new ImageData(dataUrl, type, file.name));
                    fileInput.value = '';
                  };
                  reader.readAsDataURL(file);
                }
              });
            }
            fileInput.click();
          }
        });
      }

      if (this.contents) {
        this.setContents(this.contents);
      }
    },
    setContents(contents) {
      try {
        // Had an instance on production where an unescaped tab literal was saved inside the contents
        // This shouldn't be possible from saving via a quill, but this prevents it from erroring (and fixes it)
        if (contents) {
          contents = contents.replace(/\t/g, '\\t');
        }
        // contents is sometimes '' and we should treat that identically to null
        this.editor.setContents(JSON.parse(contents || null));
      } catch (e) {
        console.log(`Error setting quill contents to: '${contents}'`, contents);
        throw e;
      }
    },
    getEditorContentJson(editor) {
      const contents = JSON.stringify(editor.getContents());
      if (contents == '{"ops":[{"insert":"\\n"}]}') {
        return null;
      }

      return contents
        .replace(/\\n/g, '\\n')
        .replace(/\\'/g, "\\'")
        .replace(/\\"/g, '\\"')
        .replace(/\\&/g, '\\&')
        .replace(/\\r/g, '\\r')
        .replace(/\\t/g, '\\t')
        .replace(/\\b/g, '\\b')
        .replace(/\\f/g, '\\f');
    },
    async imageHandler(dataUrl, type, imageData) {
      this.error = null;

      let index = (this.editor.getSelection() || {}).index;
      if (index === undefined || index < 0) index = this.editor.getLength();
      let insertedLoadingImage = false;
      try {
        const miniImageData = await imageData.minify({
          maxWidth: 1500,
          maxHeight: 1500,
          quality: 0.7,
        });

        this.editor.insertEmbed(index, LoadingImage.blotName, URL.createObjectURL(miniImageData.toBlob()), 'user');
        insertedLoadingImage = true;
        this.editor.setSelection(index + 1, 'user');

        const file = miniImageData.toFile();
        const r = await this.$api.post(this.presignUrl);
        const uploadUrl = r.data.url;
        await this.$api.putFile(uploadUrl, file, file.type);
        const imageUrl = uploadUrl.split('?')[0];

        // This was cribbed, but it's resilient to the editor contents changing during the image upload
        this.editor.deleteText(index, 3, 'user');
        this.editor.insertEmbed(index, 'image', imageUrl, 'user');
        this.editor.setSelection(index + 1, 'user');
      } catch (e) {
        console.log(e);
        if (insertedLoadingImage) {
          this.editor.deleteText(index, 3, 'user');
        }
        this.error = 'Sorry, cannot upload your image at the moment. Please try again.';
      }
    },
  },
};
</script>

<style>
/* Bundle this with the application to avoid errors where loading has failed */
@import 'quill/dist/quill.snow.css';

.ql-editor p {
  margin-bottom: 0px;
}

.image-uploading {
  position: relative;
  display: inline-block;
}

.image-uploading img {
  max-width: 98% !important;
  filter: blur(5px);
  opacity: 0.3;
}

.image-uploading::before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 30px;
  height: 30px;
  margin-top: -15px;
  margin-left: -15px;
  border-radius: 50%;
  border: 3px solid #ccc;
  border-top-color: #3ea0ab;
  z-index: 1;
  animation: spinner 0.6s linear infinite;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}
</style>
