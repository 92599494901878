<template>
  <div style="visibility: hidden"></div>
</template>

<script>
import { syncQueryParamsMixin } from '@/mixins/query-mixins';
import { useQueryStore } from '@/stores/query';

export default {
  setup() {
    const queryStore = useQueryStore();
    return { queryStore };
  },
  props: {
    subjectId: Number,
  },
  emits: ['update:subjectId'],
  data() {
    return {
      internalSubjectId: this.subjectId,
    };
  },
  created() {
    this.$emit('update:subjectId', this.internalSubjectId);
  },
  mixins: [
    syncQueryParamsMixin({
      internalSubjectId: { query: 'subjectId', type: 'integer' },
    }),
  ],
  watch: {
    subjectId(x) {
      this.internalSubjectId = x;
    },
    internalSubject(x) {
      this.$emit('update:subjectId', x);
    },
  },
};
</script>
