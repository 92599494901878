<template>
  <mosaic-save-dialog
    :active="active"
    :title="`Add ${capitaliseFirstLetter(objectType)}`"
    :object-type="objectType"
    action="add"
    :save="add"
    :can-save="canAdd"
    @update:active="emit('update:active', $event)"
  >
    <mosaic-file-pond-external-storage
      ref="filepond"
      :get-upload-url-and-headers="getUploadUrlAndHeaders"
      :max-files="1"
      :allowed-content-types="[objectType + '/*']"
      @file-uploaded="fileUploaded"
      @file-count-updated="fileCountUpdated"
    ></mosaic-file-pond-external-storage>
  </mosaic-save-dialog>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import MosaicFilePondExternalStorage from '@/components/file-pond/MosaicFilePondExternalStorage.vue';
import { useApi } from '@/composables/api';
import { useFilePond } from '@/components/file-pond/mosaic-file-pond';

const api = useApi();

const props = defineProps<{
  active: boolean;
  objectType: 'video' | 'image';
  presignUrl: string;
  saveResourceUrl: string;
  saveResourceParams: Record<string, unknown>;
}>();

const emit = defineEmits<{
  (e: 'update:active', active: boolean): void;
  (e: 'uploaded', upload: { resourceId: number; title: string }): void;
}>();

const uploadId = ref('');
const title = ref('');
const canAdd = computed(() => !!uploadId.value);

const { getUploadUrlAndHeaders } = useFilePond(props.presignUrl, api);

async function add() {
  const r = await api.post<{ fileId: string } & Record<string, unknown>, { id: number }>(props.saveResourceUrl, {
    fileId: uploadId.value,
    ...props.saveResourceParams,
  });
  emit('uploaded', { resourceId: r.data.id, title: title.value });
}

function fileUploaded(x: { fileId: string; title: string }) {
  uploadId.value = x.fileId;
  title.value = x.title;
}

function fileCountUpdated(count: number) {
  if (count === 0) {
    // Delete uploaded files?
    uploadId.value = '';
  }
}
</script>
