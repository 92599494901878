<template>
  <div>
    <mosaic-list-filters-card title="Targets">
      <curriculum-theme-filter v-model:curriculum-theme-id="selectedCurriculumThemeId" />
      <div>
        <mosaic-select
          name="status-filter"
          min-width="160px"
          v-model="selectedStatus"
          label="Filter by status"
          :items="statuses"
          hide-details
          no-icon
        />
      </div>
      <div class="flex-grow-1">
        <mosaic-text-field name="filter-field" v-model="targetFilter" hide-details label="Filter by Target" no-icon />
      </div>

      <template #actions>
        <v-btn v-if="canCreateTargets" ripple @click.prevent="$emit('clickCreateTarget')">
          <div class="d-flex align-center">
            <v-icon>mdi-plus</v-icon>
            <span>Target</span>
          </div>
        </v-btn>
      </template>
    </mosaic-list-filters-card>

    <v-card class="flex-grow-1">
      <v-card-text>
        <div v-if="targets.length === 0" class="pa-4 pl-6">
          <slot />
        </div>
        <div v-else-if="filteredTargets.length === 0" class="pa-4 pl-6">There are no Targets for these filters.</div>
        <mosaic-list v-else two-line :items="paginatedTargets">
          <template #item="{ item: target }">
            <target-list-item
              :edit-evidence="editEvidence"
              :create-evidence="createEvidence"
              :delete-target="deleteTarget"
              :target="target"
              :target-click-to="targetClickTo"
            ></target-list-item>
          </template>
        </mosaic-list>
        <mosaic-pagination v-model="currentPage" v-model:page-size="pageSize" :total="filteredTargets.length" />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import CurriculumThemeFilter from '@/components/CurriculumThemeFilter.vue';
import { mapState } from 'vuex';
import TargetListItem from '../../components/TargetListItem.vue';

export default {
  components: { TargetListItem, CurriculumThemeFilter },
  props: {
    targets: {
      type: Array,
      required: true,
    },
    editEvidence: {
      type: Function,
      default: null,
    },
    createEvidence: {
      type: Function,
      default: null,
    },
    deleteTarget: {
      type: Function,
      required: true,
    },
    targetClickTo: {
      type: Function,
      required: true,
    },
    canCreateTargets: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['clickCreateTarget'],
  data: () => ({
    selectedCurriculumThemeId: null,
    targetFilter: '',
    selectedStatus: null,
    statuses: [
      { text: 'All', value: null },
      { text: 'Active', value: 'active' },
      { text: 'Completed', value: 'completed' },
      { text: 'Approved', value: 'approved' },
    ],
    currentPage: 1,
    pageSize: 10,
  }),
  computed: {
    ...mapState(['curriculumThemes']),
    filteredTargets() {
      return this.targets.filter(target => {
        const curriculumFilter =
          this.selectedCurriculumThemeId && this.curriculumThemes
            ? this.curriculumThemes[this.selectedCurriculumThemeId].curriculum_statements
                .map(s => s.id)
                .some(x => target.curriculum_statements.map(s => s.id).includes(x))
            : true;
        const statusFilter = !this.selectedStatus || target.status === this.selectedStatus;
        const targetFilter = target.goal.toLowerCase().includes(this.targetFilter.toLowerCase());
        return curriculumFilter && statusFilter && targetFilter;
      });
    },
    sortedTargets() {
      return this.filteredTargets
        .map(t => ({ ...t, statusOrder: t.status === 'active' ? 1 : t.status === 'completed' ? 2 : 3 }))
        .sortBy('statusOrder', 'created_at');
    },
    paginatedTargets() {
      const index = (this.currentPage - 1) * this.pageSize;
      return this.sortedTargets.slice(index, index + this.pageSize);
    },
  },
  watch: {
    filteredTargets() {
      this.currentPage = 1;
    },
  },
  created() {
    this.$store.dispatch('loadCurriculum');
  },
};
</script>

<style scoped>
.badge {
  width: 80px;
}
</style>
