export function getEmailStatusChipParamsFromUser(user: {
  email_verified: boolean;
  email_bounced: boolean;
  opted_out_of_emails: boolean;
}) {
  return getEmailStatusChipParams(user.email_verified, user.email_bounced, user.opted_out_of_emails);
}

export function getEmailStatusChipParams(verified: boolean, bounced: boolean, optedOut?: boolean): StatusChipParams {
  if (optedOut) {
    return {
      color: 'error',
      icon: 'mdi-email-off',
      tooltip: `User has opted out of receiving emails from Mosaic`,
      value: 'optedOut',
      friendlyValue: 'Opted out of emails',
    };
  }
  if (bounced) {
    return {
      color: 'error',
      icon: 'mdi-email-remove',
      tooltip: `An email sent by Mosaic has failed to reach this user`,
      value: 'bounced',
      friendlyValue: 'Verification email bounced',
    };
  }
  if (verified) {
    return {
      color: 'success',
      icon: 'mdi-email-check',
      tooltip: `User verified their email and can receive emails from Mosaic`,
      value: 'verified',
      friendlyValue: 'Verified',
    };
  }
  return {
    color: 'secondary',
    icon: 'mdi-email-alert',
    tooltip: `User has not yet verified their email`,
    value: 'notVerified',
    friendlyValue: 'Not verified',
  };
}

export function cannotSendEmailToUser(verified: boolean, bounced: boolean, optedOut: boolean): string | false {
  if (optedOut) {
    return 'User has opted out of receiving emails from Mosaic';
  }
  if (bounced) {
    return 'An email sent by Mosaic has previously failed to reach this user';
  }
  if (!verified) {
    return 'User has not yet verified their email';
  }
  return false;
}

interface StatusChipParams {
  color: string;
  icon: string;
  tooltip: string;
  value: string;
  friendlyValue: string;
}
