<template>
  <div>
    <v-card v-if="error">
      <v-card-text>
        <div class="pa-4">{{ error }}</div>
      </v-card-text>
    </v-card>
    <review-overall-card
      v-else-if="studentReviewForTrainee"
      :student-review="studentReviewForTrainee"
      :student-viewer="userStudent"
      :student="userStudent"
      :review-path="reviewPath"
      @update:dirty="dirty = $event"
    />
    <unsaved-changes-dialog
      v-model:unsaved-changes-dialog="unsavedChangesDialog"
      :object-type="reviewNounCapitalised"
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import ReviewOverallCard from '@/components/ReviewOverallCard.vue';
import unsavedChangesMixin from '@/components/unsaved-changes-mixin';
import UnsavedChangesDialog from '@/components/UnsavedChangesDialog.vue';
import clearStudentReviewMixin from '@/mixins/clear-student-review';

export default {
  name: 'StudentReviewOverallPage',
  components: { ReviewOverallCard, UnsavedChangesDialog },
  mixins: [unsavedChangesMixin, clearStudentReviewMixin],
  ...unsavedChangesMixin,
  async created() {
    await this.$store.dispatch('loadStudentReview', { id: this.$route.params.id });
  },
  computed: {
    ...mapState(['selectedStudent', 'userStudent']),
    ...mapState({
      error: state => state.studentReviewPages.error,
    }),
    ...mapGetters(['studentReviewForTrainee']),
    reviewPath() {
      return {
        text: this.studentReviewForTrainee?.name,
        to: {
          name: 'StudentReviewPage',
          params: { id: this.studentReviewForTrainee?.id },
        },
      };
    },
    breadcrumbs() {
      return [
        {
          text: this.reviewNounCapitalisedAndPluralised,
          to: {
            name: 'StudentReviewsListPage',
          },
        },
        {
          text: this.studentReviewForTrainee?.name,
          to: {
            name: 'StudentReviewPage',
            params: { id: this.studentReviewForTrainee?.id },
          },
        },
        {
          text: 'General',
        },
      ];
    },
  },
};
</script>
