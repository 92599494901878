<template>
  <div>
    <mosaic-loading-card v-if="loadingCourse || loadingWeeks" type="list" />
    <mosaic-load-error-card
      v-else-if="loadCourseError || loadWeeksError"
      :object-type="cohortCourseWeekNounCapitalisedAndPluralised"
      @retry="load"
    />

    <template v-else>
      <mosaic-list-filters-card title="Training Plan">
        <div>
          <v-select
            v-model="selectedTerm"
            :label="`Filter by ${cohortCourseTermNounCapitalised}`"
            :items="terms"
            hide-details
            style="min-width: 150px"
          />
        </div>
        <div>
          <v-select
            v-model="selectedStatus"
            label="Filter by status"
            :items="statuses"
            hide-details
            style="min-width: 150px"
          />
        </div>
      </mosaic-list-filters-card>
      <v-card>
        <v-card-text>
          <template v-if="currentCourseWeeks.length > 0">
            <mosaic-card-subheading>
              Current
              {{
                currentCourseWeeks.length > 1
                  ? cohortCourseWeekNounCapitalisedAndPluralised
                  : cohortCourseWeekNounCapitalised
              }}
            </mosaic-card-subheading>
            <mosaic-list two-line :items="currentCourseWeeks">
              <template #item="{ item: currentCourseWeek }">
                <mosaic-list-item
                  :key="currentCourseWeek.id"
                  icon="mdi-hexagon-slice-1"
                  :to="weekClickTo(currentCourseWeek.id)"
                  :title="currentCourseWeek.cohortCourseWeek.name"
                  :subtitle="`${currentCourseWeek.cohortCourseWeek.cohortCourseTerm.name} -
                    ${formatDate(currentCourseWeek.cohortCourseWeek.startDate)} -
                    ${formatDate(currentCourseWeek.cohortCourseWeek.endDate)}`"
                >
                  <template #information>
                    <student-cohort-course-week-status-chip
                      :status="currentCourseWeek.status"
                      :week-noun="cohortCourseWeekNounCapitalised"
                      :student-viewer="studentViewer"
                    />
                  </template>
                </mosaic-list-item>
              </template>
            </mosaic-list>
          </template>
          <mosaic-card-subheading>Upcoming {{ cohortCourseWeekNounCapitalisedAndPluralised }}</mosaic-card-subheading>
          <template v-if="upcomingWeeks.length > 0">
            <mosaic-list :items="upcomingWeeks">
              <template #item="{ item: week }">
                <mosaic-list-item
                  :key="week.id"
                  icon="mdi-hexagon-slice-1"
                  :to="weekClickTo(week.id)"
                  :title="week.cohortCourseWeek.name"
                  :subtitle="`${week.cohortCourseWeek.cohortCourseTerm.name} -
                    ${formatDate(week.cohortCourseWeek.startDate)} -
                    ${formatDate(week.cohortCourseWeek.endDate)}`"
                >
                  <template #information>
                    <student-cohort-course-week-status-chip
                      :status="week.status"
                      :week-noun="cohortCourseWeekNounCapitalised"
                      :student-viewer="studentViewer"
                    />
                  </template>
                </mosaic-list-item>
              </template>
            </mosaic-list>
          </template>
          <div v-else class="ml-6 pt-2 pb-6">
            There are no upcoming {{ cohortCourseWeekNounCapitalisedAndPluralised }}
            {{ selectedTerm || selectedStatus ? 'for these filters' : '' }}
          </div>
          <mosaic-card-subheading>Past {{ cohortCourseWeekNounCapitalisedAndPluralised }}</mosaic-card-subheading>
          <template v-if="pastWeeks.length > 0">
            <mosaic-list :items="pastWeeks">
              <template #item="{ item: week }">
                <mosaic-list-item
                  :key="week.id"
                  icon="mdi-hexagon-slice-1"
                  :to="weekClickTo(week.id)"
                  :title="week.cohortCourseWeek.name"
                  :subtitle="`${week.cohortCourseWeek.cohortCourseTerm.name} -
                    ${formatDate(week.cohortCourseWeek.startDate)} -
                    ${formatDate(week.cohortCourseWeek.endDate)}`"
                >
                  <template #information>
                    <student-cohort-course-week-status-chip
                      :status="week.status"
                      :student-viewer="studentViewer"
                      :week-noun="cohortCourseWeekNounCapitalised"
                    />
                  </template>
                </mosaic-list-item>
              </template>
            </mosaic-list>
          </template>
          <div v-else class="ml-6 pt-2 pb-6">
            There are no past {{ cohortCourseWeekNounCapitalisedAndPluralised }}
            {{ selectedTerm || selectedStatus ? 'for these filters' : '' }}
          </div>
        </v-card-text>
      </v-card>
    </template>
  </div>
</template>

<script>
import { syncQueryParamsMixin } from '@/mixins/query-mixins';
import { useQueryStore } from '@/stores/query';
import moment from 'moment';
import StudentCohortCourseWeekStatusChip from './StudentCohortCourseWeekStatusChip.vue';

export default {
  name: 'CohortCourseWeeksList',
  components: { StudentCohortCourseWeekStatusChip },
  setup() {
    const queryStore = useQueryStore();
    return { queryStore };
  },
  mixins: [
    syncQueryParamsMixin({
      selectedTerm: { query: 'term', type: 'integer' },
    }),
  ],
  props: {
    studentViewer: { type: Boolean, default: false },
    cohortCourseUrl: { type: String, required: true },
    studentCohortCourseWeeksUrl: { type: String, required: true },
    weekClickTo: { type: Function, required: true },
  },
  data() {
    return {
      loadingCourse: true,
      loadingWeeks: true,
      loadCourseError: false,
      loadWeeksError: false,
      course: null,
      weeks: [],
      selectedTerm: null,
      selectedStatus: null,
      statuses: [
        { title: 'All', value: null },
        { title: 'Active', value: 'active' },
        { title: 'Completed', value: 'completed' },
      ],
    };
  },
  computed: {
    filteredWeeks() {
      return this.weeks.filter(x => {
        const termFilter = !this.selectedTerm || x.cohortCourseWeek.cohortCourseTermId === this.selectedTerm;
        const statusFilter =
          !this.selectedStatus ||
          (this.selectedStatus === 'active' && !x.completedAt) ||
          (this.selectedStatus === 'completed' && x.completedAt);
        return termFilter && statusFilter;
      });
    },
    currentCourseWeeks() {
      return this.filteredWeeks.filter(w =>
        this.todayFallsWithin(w.cohortCourseWeek.startDate, w.cohortCourseWeek.endDate)
      );
    },
    upcomingWeeks() {
      return this.filteredWeeks
        .filter(w => moment(w.cohortCourseWeek.startDate).isAfter(moment()))
        .sort((a, b) => (a.cohortCourseWeek.startDate < b.cohortCourseWeek.startDate ? -1 : 1));
    },
    pastWeeks() {
      return this.filteredWeeks
        .filter(w => moment(w.cohortCourseWeek.endDate).isBefore(moment()))
        .sort((a, b) => (a.cohortCourseWeek.startDate < b.cohortCourseWeek.startDate ? 1 : -1));
    },
    terms() {
      if (!this.course) return [];
      return [
        { title: 'All', value: null },
        ...this.course.cohortCourseTerms.map(x => ({ title: x.name, value: x.id })),
      ];
    },
  },
  async created() {
    await this.load();
  },
  methods: {
    async load() {
      await Promise.all([this.loadCourse(), this.loadWeeks()]);
    },
    async loadCourse() {
      this.loadingCourse = true;
      this.loadCourseError = false;
      try {
        const r = await this.$api.get(this.cohortCourseUrl);
        this.course = r.data;
      } catch (e) {
        this.loadCourseError = true;
        console.log(e);
      }
      this.loadingCourse = false;
    },
    async loadWeeks() {
      this.loadingWeeks = true;
      this.loadWeeksError = false;
      try {
        const r = await this.$api.get(this.studentCohortCourseWeeksUrl);
        this.weeks = r.data;
      } catch (e) {
        console.log(e);
        this.loadWeeksError = true;
      }
      this.loadingWeeks = false;
    },
  },
};
</script>
