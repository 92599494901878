<template>
  <mosaic-dialog v-model:active="internalActive" title="Mark Induction Complete" :error-message="error">
    <mosaic-alert type="warning" class="mb-2">
      This action will remove the ECT from the main views within Mosaic. You will be able to retrieve the ECT record by
      visiting the Completed ECTs page.
    </mosaic-alert>
    <div>Are you sure you want to mark this ECT's induction as complete?</div>
    <template #buttons>
      <v-btn
        variant="text"
        ripple
        color="error"
        :disabled="processing"
        :loading="processing"
        @click.prevent="submitCompleteInduction()"
        >Mark Complete</v-btn
      >
    </template>
  </mosaic-dialog>
</template>

<script setup lang="ts">
import { useApi } from '@/composables/api';
import { ref, watch } from 'vue';

const api = useApi();

const props = defineProps<{
  active: boolean;
  ect: {
    id: number;
  };
}>();

const internalActive = ref(props.active);

const emit = defineEmits<{
  (e: 'update:active', active: boolean): void;
  (e: 'submit'): void;
}>();

watch(internalActive, x => {
  emit('update:active', x);
});

watch(
  () => props.active,
  x => {
    internalActive.value = x;
  }
);

const processing = ref(false);
const error = ref('');

async function submitCompleteInduction() {
  if (processing.value) return;

  processing.value = true;
  error.value = '';
  try {
    await api.put(`/ects/${props.ect.id}/complete-induction`, {});
    emit('submit');
    emit('update:active', false);
  } catch (e) {
    console.log(e);
    error.value = `Sorry, cannot mark this ECT's induction as complete at the moment`;
  }
  processing.value = false;
}
</script>
