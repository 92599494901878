export const settingTypes = [
  'All Through',
  'Primary',
  'Secondary',
  'Primary and Secondary',
  'Nursery (Maintained)',
  'Nursery (PVI)',
  'Alternative Provision (PRU)',
  'Special School',
  'Independent',
  'FE College',
] as const;

type SettingTypeTuple = typeof settingTypes;
export type SettingType = SettingTypeTuple[number];

export const brightFuturesMATs = [
  'N/A',
  'Oasis',
  'Co-op Academies',
  'Consilium',
  'Delius',
  'Dean Trust',
  'Omega',
  'Other',
];

export const localAuthoritiesByAB = [
  {
    abName: 'Bright Futures TSH',
    localAuthorities: ['Manchester', 'Salford', 'Stockport', 'Trafford'],
  },
  {
    abName: 'One Cumbria',
    localAuthorities: ['Allerdale', 'Barrow-in-Furness', 'Carlisle', 'Copeland', 'Eden', 'South Lakeland'],
  },
];
