export const icons = {
  announcement: 'bullhorn',
  calendar: 'calendar',
  certificate: 'file-certificate',
  cohort: 'google-classroom',
  course: 'map-marker-path',
  courseActivity: 'clipboard-text',
  courseFiles: 'folder-home',
  courseFilesTrainee: 'folder-account',
  courseFilesInstructor: 'folder-table',
  curriculum: 'hexagon-multiple',
  curriculumStatement: 'hexagon',
  development: 'chart-timeline-variant-shimmer',
  ect: 'mosaic-ect',
  ectsOverview: 'account-search',
  ectsPending: 'account-question',
  ectsCompleted: 'account-check',
  evidence: 'fingerprint',
  evidenceLinked: 'link',
  evidenceNotLinked: 'link-off',
  featureConfig: 'light-switch',
  feedbackComment: 'chat-outline',
  files: 'file-document',
  filesStudent: 'folder',
  folderTemplates: 'file-tree',
  group: 'account-group',
  home: 'home',
  institution: 'school',
  instructor: 'human-male-board',
  instructorAccounts: 'account',
  instructorSharedFiles: 'folder-open-outline',
  instructorTraining: 'sitemap-outline',
  instructorTrainingEvent: 'calendar',
  instructorTrainingFramework: 'layers-triple-outline',
  instructorTrainingCompetencyTheme: 'layers-outline',
  instructorTrainingCompetency: 'rhombus-outline',
  instructorTrainingModule: 'checkbox-intermediate',
  instructorTrainingRecordSession: 'clipboard-play-outline',
  instructorTrainingRecordAudit: 'clipboard-check-outline',
  judgementSets: 'pencil-box-multiple',
  professionalResources: 'book-education-outline',
  lessonObservation: 'file-eye',
  manageInduction: 'chart-timeline-variant',
  mentorMeeting: 'recycle-variant',
  more: 'mdi-dots-horizontal-circle',
  permission: 'key',
  portfolio: 'library',
  proficiencies: 'list-status',
  reflection: 'mirror',
  review: 'chart-arc',
  reviewPoint: 'check-circle-outline',
  search: 'magnify',
  settings: 'cog',
  school: 'domain',
  schoolStaff: 'school',
  student: 'account',
  students: 'account-multiple',
  studentSearch: 'account-search',
  target: 'near-me',
  teachingStrategy: 'transit-connection',
  trainee: 'mosaic-trainee',
  trainingPlan: 'hexagon-slice-4',
  trainingPlanWeek: 'hexagon',
  trainingPlanWeekSection: 'hexagon-slice-1',
  budActivity: 'notebook-outline',
  subject: 'mdi-pencil-ruler',
};

// I don't think this should generally be used, but it's here just in case
export const mdiIcons = Object.fromEntries(Object.entries(icons).map(([key, value]) => [key, `mdi-${value}`]));
