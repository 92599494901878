<template>
  <div>
    <mosaic-loading-card v-if="busy" type="list" />
    <mosaic-load-error-card v-else-if="loadError" object-type="Targets" @retry="loadTargets" />
    <div v-else>
      <targets-list
        :targets="targets"
        :create-evidence="createEvidence"
        :edit-evidence="editEvidence"
        :target-click-to="targetClickTo"
        :delete-target="deleteTarget"
        :can-create-targets="selectedInstitution.config.students_can_add_targets"
        @click-create-target="createTarget"
      >
        <div>You have no Targets. Create a <a @click="createTarget()" class="text-link">new Target</a>.</div>
      </targets-list>
    </div>
    <CreateEvidenceDialog
      v-model:active="createEvidenceDialog.active"
      :on-close="loadTargets"
      :target-id="createEvidenceDialog.targetId"
      :title-placeholder="createEvidenceDialog.title"
      :suggested-codes="createEvidenceDialog.curriculumThemeCodes"
    />
    <EditEvidenceDialog v-model:active="editEvidenceDialog.active" :evidence="editEvidenceDialog.evidence" />
    <ndt-dialog
      v-model:active="deleteTargetDialog.active"
      title="Delete Target"
      :error-message="deleteTargetDialog.errorMessage"
    >
      <div>Are you sure you want to delete target:</div>
      <br />
      <div>
        <span class="font-weight-bold">{{ `"` + deleteTargetDialog.target.goal + `"` }}</span>
        <span>?</span>
      </div>
      <template v-if="deleteTargetDialog.evidence">
        <br />
        <div>
          <span>
            This target is linked to:
            {{
              deleteTargetDialog.evidence.substandards
                .map(s => {
                  return s.code;
                })
                .join(', ')
            }}
            as evidence
          </span>
        </div>
      </template>
      <template #buttons>
        <v-btn
          variant="text"
          ripple
          color="error"
          :disabled="deleteTargetDialog.processing"
          @click.prevent="submitDeleteTarget()"
          >Delete</v-btn
        >
      </template>
    </ndt-dialog>
  </div>
</template>

<script>
import TargetsList from './TargetsList.vue';
import CreateEvidenceDialog from '@/components/student/CreateEvidenceDialog.vue';
import EditEvidenceDialog from '@/components/student/EditEvidenceDialog.vue';
import NdtDialog from '@/components/NdtDialog.vue';
import { mapState } from 'vuex';
import { useActiveTargetsStore } from '@/stores/active-targets';

export default {
  name: 'StudentTargetsListPage',
  data() {
    return {
      targets: [],
      createEvidenceDialog: {
        active: false,
        curriculumThemeCodes: [],
      },
      substandards: [],
      editEvidenceDialog: {
        active: false,
        evidence: {},
      },
      deleteTargetDialog: {
        active: false,
        target: {},
        evidence: null,
        disabled: true,
        errorMessage: '',
      },
      busy: true,
      loadError: false,
    };
  },
  computed: {
    ...mapState(['userStudent', 'selectedInstitution']),
    breadcrumbs() {
      return [
        {
          text: 'Targets',
        },
      ];
    },
  },
  setup() {
    const {
      actions: { removeTarget },
    } = useActiveTargetsStore();
    return {
      removeTarget,
    };
  },
  async created() {
    await this.loadTargets();
  },
  methods: {
    async loadTargets() {
      this.busy = true;
      this.loadError = false;
      try {
        const response = await this.$api.get(`/students/${this.userStudent.id}/targets`);
        this.targets = response.data;
      } catch (e) {
        console.log(e);
        this.loadError = true;
      }
      this.busy = false;
    },
    createTarget() {
      this.$router.push({ name: 'TargetCreatePage' });
    },
    targetClickTo(id) {
      return { name: 'TargetPage', params: { targetId: id } };
    },
    async deleteTarget(target) {
      this.deleteTargetDialog.target = target;
      if (target.evidence_id) {
        this.deleteTargetDialog.disabled = true;
        try {
          const response = await this.$api.get(`/evidence/${target.evidence_id}`);
          this.deleteTargetDialog.evidence = response.data;
          this.deleteTargetDialog.disabled = false;
        } catch (e) {
          console.log(e);
        }
      }
      this.deleteTargetDialog.active = true;
    },
    async submitDeleteTarget() {
      try {
        await this.$api.delete(`/targets/${this.deleteTargetDialog.target.id}`);
        this.deleteTargetDialog.active = false;
        const i = this.targets.findIndex(t => t.id === this.deleteTargetDialog.target.id);
        // updates the array such that Vue knows it has changed
        this.targets.splice(i, 1);
        this.removeTarget(this.deleteTargetDialog.target.id);
        if (this.deleteTargetDialog.target.target_type === 'review') {
          // This ensures that if the target is stored on the cached student review then it's cleared
          // We could do a more nuanced check than this, but I think this is good enough
          this.$store.commit('clearStudentReview');
        }
        this.deleteTargetDialog.processing = false;
      } catch (e) {
        this.deleteTargetDialog.errorMessage =
          (e.response && e.response.data.message) || `Sorry, cannot delete your Target at the moment`;
        this.deleteTargetDialog.processing = false;
      }
    },
    createEvidence(target) {
      this.createEvidenceDialog = {
        active: true,
        title: target.goal,
        targetId: target.id,
        curriculumThemeCodes: target.curriculum_statements.map(s => s.curriculum_theme_code).unique(),
      };
    },
    editEvidence(target) {
      this.$api.get(`/evidence/${target.evidence_id}`).then(response => {
        this.editEvidenceDialog = {
          active: true,
          evidence: response.data,
        };
      });
    },
  },
  components: {
    TargetsList,
    CreateEvidenceDialog,
    EditEvidenceDialog,
    NdtDialog,
  },
};
</script>
