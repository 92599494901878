<template>
  <div>
    <v-card v-if="error">
      <v-card-text>
        <div class="pa-4">{{ error }}</div>
      </v-card-text>
    </v-card>
    <v-card v-if="!error && !busy" class="mb-4">
      <v-card-text>
        <div class="text-h6 mb-4">Details</div>
        <div>
          <mosaic-text-field v-model="name" prepend-icon="mdi-account" label="Name" required type="text" />
        </div>

        <mosaic-error-alert :override-error-message="updateError" />
        <div class="text-right">
          <mosaic-snackbar v-model="updateSuccessSnackbar" contained color="success" :message="updateSuccess" />
          <v-btn variant="text" ripple :disabled="processing" @click.prevent="updateGroup()">Save</v-btn>
        </div>
      </v-card-text>
    </v-card>
    <v-card>
      <v-card-text>
        <div class="d-flex align-top justify-space-between pb-2">
          <div class="text-h6">{{ traineeNounCapitalisedAndPluralised }}</div>
          <mosaic-btn @click.prevent="addStudentsDialog.active = true">
            <div class="d-flex align-center">
              <v-icon>mdi-plus</v-icon>
              <span>{{ traineeNoun() }}</span>
            </div>
          </mosaic-btn>
        </div>
        <mosaic-list
          :items="students"
          :empty-text="`There are no ${traineeNounCapitalisedAndPluralised} in this group`"
        >
          <template #item="{ item: s }">
            <mosaic-list-item :to="studentClickTo(s)" icon="mdi-account" :title="s.name || s.email">
              <template #actions>
                <ndt-icon-button
                  icon="delete"
                  tooltip="Remove from Group"
                  @click.prevent="
                    removeStudentDialog.active = true;
                    removeStudentDialog.student = s;
                  "
                />
              </template>
            </mosaic-list-item>
          </template>
        </mosaic-list>
      </v-card-text>
    </v-card>
    <ndt-dialog
      v-model:active="addStudentsDialog.active"
      :title="'Add ' + traineeNounCapitalisedAndPluralised"
      :error-message="addStudentsDialog.error"
    >
      <v-autocomplete
        v-model="addStudentsDialog.students"
        chips
        multiple
        :label="traineeNounCapitalisedAndPluralised"
        :items="autocompleteStudents"
        item-title="nameOrEmail"
        item-value="id"
        :no-data-text="
          autocompleteStudents.length === 0
            ? 'No more ' + traineeNounCapitalisedAndPluralised + ' in this cohort can be added'
            : 'No ' + traineeNounCapitalisedAndPluralised + ' found'
        "
        prepend-inner-icon="mdi-magnify"
        color="black"
        :custom-filter="filterStudents"
      ></v-autocomplete>
      <template #buttons>
        <v-btn variant="text" ripple :disabled="!canSubmitAddStudents" @click.prevent="submitAddStudents">Save</v-btn>
      </template>
    </ndt-dialog>
    <ndt-dialog
      v-model:active="removeStudentDialog.active"
      :title="'Remove ' + traineeNounCapitalised()"
      :error-message="removeStudentDialog.error"
    >
      <div>
        Are you sure you want to remove "{{ removeStudentDialog.student.name || removeStudentDialog.student.email }}"
        from this group?
      </div>
      <template #buttons>
        <v-btn
          variant="text"
          ripple
          color="error"
          :disabled="removeStudentDialog.processing"
          @click.prevent="submitRemoveStudent()"
          >Remove</v-btn
        >
      </template>
    </ndt-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import NdtDialog from '../../components/NdtDialog.vue';
import NdtIconButton from '../../components/NdtIconButton.vue';
import { findStudentLevelHomePage } from '@/utils/navigation';
import { useCohortStore } from '@/stores/cohort';

export default {
  name: 'TutorGroupPage',
  components: { NdtDialog, NdtIconButton },
  setup() {
    const {
      selectedCohortStudents,
      actions: { loadCohortStudents },
    } = useCohortStore();
    return { selectedCohortStudents, loadCohortStudents };
  },
  data: () => ({
    busy: true,
    processing: false,
    error: null,
    updateError: null,
    updateSuccess: null,
    updateSuccessSnackbar: false,
    name: '',
    group: null,
    students: [],
    addStudentsDialog: {
      active: false,
      error: '',
      processing: false,
      students: [],
    },
    removeStudentDialog: {
      active: false,
      error: '',
      processing: false,
      student: {},
    },
  }),
  async created() {
    await this.loadGroup();
  },
  computed: {
    ...mapState(['selectedCohort', 'userStaff']),
    breadcrumbs() {
      return [
        {
          text: 'Groups',
          to: {
            name: 'TutorGroupsListPage',
          },
        },
        {
          text: this.group?.name,
        },
      ];
    },
    canSubmitAddStudents() {
      return !this.addStudentsDialog.processing && this.addStudentsDialog.students.length > 0;
    },
    autocompleteStudents() {
      const currentStudentIds = this.students.map(x => x.id);
      return this.selectedCohortStudents
        .filter(x => !currentStudentIds.includes(x.id))
        .map(x => ({ ...x, nameOrEmail: x.name ? `${x.name} (${x.email})` : x.email }));
    },
  },
  methods: {
    async loadGroup() {
      try {
        const r = await this.$api.get(`/groups/${this.$route.params.id}`);
        this.group = r.data;
        this.name = r.data.name;
        this.students = r.data.students;
      } catch (e) {
        console.log(e);
        this.error = 'Sorry, cannot load this group at the moment';
      }
      this.busy = false;
    },
    async updateGroup() {
      this.updateError = null;
      this.processing = true;
      try {
        await this.$api.put(`/groups/${this.group.id}`, { name: this.name });
        this.updateSuccessSnackbar = true;
        this.updateSuccess = 'Group name updated';
        // Update store with new group name
        this.$store.dispatch('loadGroups', { force: true });
      } catch (e) {
        console.log(e);
        this.updateError = 'Sorry, cannot update this group at the moment';
      }
      this.processing = false;
    },
    async submitAddStudents() {
      this.addStudentsDialog.processing = true;
      this.addStudentsDialog.error = '';
      try {
        await this.$api.post(`/groups/${this.group.id}/students`, {
          studentIds: this.addStudentsDialog.students,
        });
        this.loadCohortStudents(true, true);
      } catch (e) {
        console.log(e);
        this.addStudentsDialog.error = 'Sorry, cannot add these trainees at the moment';
        this.addStudentsDialog.processing = false;
        return;
      }
      this.loadGroup();

      this.addStudentsDialog = {
        active: false,
        error: '',
        processing: false,
        students: [],
      };
    },
    async submitRemoveStudent() {
      this.removeStudentDialog.processing = true;
      this.removeStudentDialog.error = '';
      try {
        await this.$api.delete(`/groups/${this.group.id}/students/${this.removeStudentDialog.student.id}`);
        this.removeStudentDialog.active = false;
        this.loadGroup();

        this.loadCohortStudents(true, true);
        this.$store.dispatch('loadGroups', { force: true });
      } catch (e) {
        console.log(e);
        this.removeStudentDialog.error = `Sorry, cannot remove this ${this.traineeNoun()} at the moment`;
      }
      this.removeStudentDialog.processing = false;
    },
    filterStudents(nameOrEmail, queryText) {
      const searchText = queryText.toLowerCase();
      return nameOrEmail.toLowerCase().includes(searchText);
    },
    studentClickTo(student) {
      return findStudentLevelHomePage(this.userStaff, student);
    },
  },
};
</script>
