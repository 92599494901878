<template>
  <v-card v-if="!tryingAgain">
    <v-card-text>
      <div class="d-flex justify-center" style="position: relative">
        <img src="@/assets/mosaic_icon.png" style="width: 30vw" />
        <div style="position: absolute; bottom: 15vw">
          <v-icon style="font-size: 8vw">{{ error === 'noAccess' ? 'mdi-lock' : 'mdi-alert' }}</v-icon>
        </div>
      </div>
      <div class="d-flex flex-column align-center mx-4">
        <div class="text-h4 text-center">
          <span>Sorry, </span>
          <span v-if="error === 'noAccess'">you do not have permission to access this {{ scope }}</span>
          <span v-else>Mosaic cannot load this {{ scope }}</span>
        </div>
        <div class="my-4">
          <ul>
            <template v-if="error === 'noAccess'">
              <li>
                If multiple Mosaic accounts are used on this device, please make sure you are logged in with the correct
                account for accessing this {{ scope }}
              </li>
              <li v-if="$route.name === 'LoadStudentErrorPage'">
                If you use multiple Mosaic accounts for different providers, please
                <a @click.prevent="contactSupport()">contact support</a> for an account merger
              </li>
              <li>
                If you think you should have access to this {{ scope }}, then get in touch with your
                {{ institutionNoun }}
              </li>
            </template>
            <template v-else
              ><li>Check your internet connection and <a @click.prevent="tryAgain()">try again</a></li></template
            >
            <li>If you arrived here by mistake, then <a @click.prevent="goHome()">go to your Home page</a></li>
            <li>
              If you keep seeing this page and you're not sure why, then
              <a @click="contactSupport()" class="text-link">contact support</a>
            </li>
          </ul>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'LoadScopeErrorPage',
  inject: ['layoutContainerContactSupport'],
  data: () => ({
    error: '',
    tryingAgain: false,
    scope: '',
  }),
  created() {
    this.$store.commit('clearSelectedStudent');
    this.error = this.$route.query.error || 'cannotLoad';
    if (this.$route.name === 'LoadCohortErrorPage') {
      this.scope = 'Cohort';
    } else {
      this.scope = this.traineeNounCapitalised();
    }
  },
  computed: {
    ...mapGetters(['institutionNoun']),
  },
  methods: {
    goHome() {
      this.$router.push({ name: 'Home' });
    },
    contactSupport() {
      this.layoutContainerContactSupport();
    },
    async tryAgain() {
      this.tryingAgain = true;
      const path = this.$route.query.to;
      if (path) {
        await this.$router.push({ path });
      } else {
        await this.$route.go(-1);
      }
      // Ensure there is a visible delay when trying again
      setTimeout(() => (this.tryingAgain = false), 500);
    },
  },
};
</script>
