import { defineStore } from 'pinia';
import { useStore } from './common';
import { useInstitutionStore } from './institution';
import { computed, ref } from 'vue';
import { CacheMap } from './cache-map';
import { useApi } from '@/composables/api';

export const useInstitutionStaffTrainingStore = useStore(
  defineStore('institutionStaffTraining', () => {
    const api = useApi();

    // #region institution staff training frameworks
    const { selectedInstitution } = useInstitutionStore();
    const institutionId = computed(() => selectedInstitution.value.id);

    const staffTrainingFrameworksCache = ref<CacheMap<StaffTrainingFrameworkResponse>>(new CacheMap());

    async function loadInstitutionStaffTrainingFrameworks(force?: boolean) {
      if (force || !staffTrainingFrameworksCache.value.get(institutionId.value)) {
        const r = await api.get<StaffTrainingFrameworkResponse>(
          `/institutions/${institutionId.value}/staff-training/frameworks/full`
        );
        staffTrainingFrameworksCache.value.set(institutionId.value, r.data);
      }
    }

    const institutionStaffTrainingFrameworks = computed(() =>
      staffTrainingFrameworksCache.value.getEvenIfExpired(institutionId.value)?.filter(f => f.status == 'published')
    );

    const institutionStaffTrainingFrameworksIncludingUnpublished = computed(() =>
      staffTrainingFrameworksCache.value.getEvenIfExpired(institutionId.value)
    );

    function clearInstitutionStaffTrainingFrameworks() {
      staffTrainingFrameworksCache.value.remove(selectedInstitution.value.id);
    }
    // #endregion

    // #region certificate types
    type CertificateTypeResponse = {
      id: number;
      name: string;
    }[];
    const certificateTypes = ref<CertificateTypeResponse>([]);

    async function loadCertificateTypes() {
      if (certificateTypes.value.length === 0) {
        const r = await api.get<CertificateTypeResponse>(`/certificate-types`);
        certificateTypes.value = r.data;
      }
    }
    // #endregion

    return {
      staffTrainingFrameworksCache,
      loadInstitutionStaffTrainingFrameworks,
      clearInstitutionStaffTrainingFrameworks,
      institutionStaffTrainingFrameworks,
      institutionStaffTrainingFrameworksIncludingUnpublished,
      certificateTypes,
      loadCertificateTypes,
    };
  })
);

export type StaffTrainingFrameworkResponse = {
  id: number;
  status: 'draft' | 'published';
  name: string;
  staffTrainingCompetencyThemes: {
    id: number;
    code: string;
    name: string;
    order: number;
    staffTrainingCompetencies: {
      id: number;
      code: string;
      name: string;
      order: number;
    }[];
  }[];
  staffTrainingFrameworkCohorts: {
    publishedToCohortId: number;
  }[];
  staffTrainingFrameworkRoles: {
    publishedToRoleId: number;
  }[];
}[];
