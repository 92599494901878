<template>
  <div>
    <q-a-target-filters-sync-query
      v-model:name="internalNameFilter"
      v-model:display="internalSelectedQATargetDisplayOption"
      v-model:curriculum-linked-status="internalSelectedCurriculumLinkedStatus"
      v-model:action-status="internalSelectedActionStatus"
    />
    <div class="d-flex align-end">
      <div style="width: 120px">Filter {{ traineeNounCapitalisedAndPluralised }} by:</div>

      <mosaic-text-field
        v-model="internalNameFilter"
        name="name-filter"
        hide-details
        label="name or email"
        prepend-icon="mdi-magnify"
        style="width: 180px"
      ></mosaic-text-field
      ><mosaic-cohort-monitoring-filters
        class="ml-2"
        :students="selectedCohortStudents"
        @update:filtered-student-ids="filteredStudentIds = $event"
      />
    </div>
    <div class="d-flex align-end">
      <div style="width: 120px">Filter Targets by:</div>
      <mosaic-select
        v-model="internalSelectedCurriculumLinkedStatus"
        name="curriculum-filter"
        :items="curriculumLinkStatuses"
        label="Has Curriculum Links?"
        item-title="title"
        hide-details
        single-line
        prepend-icon="mdi-hexagon"
      />
      <mosaic-select
        v-model="internalSelectedActionStatus"
        name="action-filter"
        :items="actionStatuses"
        label="Has Actions?"
        hide-details
        single-line
        item-title="title"
        prepend-icon="mdi-run"
        class="ml-2"
      />
      <div class="flex-grow-1" />
    </div>

    <div class="pt-2">
      <mosaic-radio-buttons
        v-model="internalSelectedQATargetDisplayOption"
        inline
        label="Display:"
        :radios="qATargetDisplayOptions"
      ></mosaic-radio-buttons>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, watch, computed } from 'vue';
import QATargetFiltersSyncQuery from './QATargetFiltersSyncQuery.vue';
import { useCohortStore } from '@/stores/cohort';

const { selectedCohortStudents } = useCohortStore();

const qATargetDisplayOptions = [
  {
    label: 'Targets List',
    value: 'list',
  },
  {
    label: 'Group by Trainee',
    value: 'studentGrouped',
  },
];

const curriculumLinkStatuses = [
  {
    title: 'Any Curriculum Link Status',
    value: 'all',
  },
  {
    title: 'Has Curriculum Links',
    value: 'hasLinks',
  },
  {
    title: 'No Curriculum Links',
    value: 'noLinks',
  },
];

const actionStatuses = [
  {
    title: 'Any Action Status',
    value: 'all',
  },
  {
    title: 'Has Actions',
    value: 'hasActions',
  },
  {
    title: 'No Actions',
    value: 'noActions',
  },
];

const emit = defineEmits<{
  (e: 'update:filteredStudentIds', value: number[]): void;
  (e: 'update:display', value: string): void;
  (e: 'update:curriculum-linked-status', value: string): void;
  (e: 'update:action-status', value: string): void;
}>();

const filteredStudentIds = ref<number[]>(selectedCohortStudents.value.map(s => s.id));
const nameFilteredStudentIds = computed(() => {
  return selectedCohortStudents.value
    .filter(s => s.display_name.toLowerCase().includes(internalNameFilter.value.toLowerCase()))
    .map(s => s.id);
});

const fullyFilteredStudentIds = computed(() => {
  return filteredStudentIds.value.filter(id => nameFilteredStudentIds.value.includes(id));
});

const internalNameFilter = ref('');
const internalSelectedQATargetDisplayOption = ref('list');
const internalSelectedCurriculumLinkedStatus = ref('all');
const internalSelectedActionStatus = ref('all');

watch(fullyFilteredStudentIds, value => emit('update:filteredStudentIds', value));
watch(internalSelectedQATargetDisplayOption, value => emit('update:display', value));
watch(internalSelectedCurriculumLinkedStatus, value => emit('update:curriculum-linked-status', value));
watch(internalSelectedActionStatus, value => emit('update:action-status', value));
</script>
