import { defineStore } from 'pinia';
import { mapActions, mapState } from '@/store/map-store';
import { fromSnakeCaseToCamelCase } from '@/utils/transforms';
import { computed } from 'vue';
import { useStore } from './common';
import type { ContentLayout } from '@/utils/content-layout';

export interface Curriculum {
  id: number;
  showTheory: boolean;
  showCurriculumForStudents: boolean;
  usingCohorts: { id: number; name: string }[];
}
interface CurriculumTheme {
  id: number;
  code: string;
  name: string;
  studentVisible: boolean;
  order: number;
}
export type CurriculumThemes = { [id: number]: CurriculumTheme };
export interface CurriculumStatement {
  id: number;
  code: string;
  statement: string;
  studentVisible: boolean;
  subjectId: number | null;
  themeId: number;
  themeOrder: number;
  theoryContentLayout: ContentLayout;
  practiceContentLayout: ContentLayout;
  cohortCourseWeeks: {
    id: number;
    status: 'published' | 'unpublished';
    name: string;
    startDate: string;
    endDate: string;
    cohortCourseTerm: {
      id: number;
      name: string;
      cohortCourse: {
        id: number;
        cohort: {
          id: number;
          name: string;
        };
      };
    };
  }[];
  curriculumTeachingStrategies: CurriculumTeachingStrategy[];
}
export type CurriculumStatements = { [id: number]: CurriculumStatement };

export interface CurriculumTeachingStrategy {
  id: number;
  name: string;
  order: number;
  status: 'published' | 'draft';
  strategyType: 'content_layout' | 'external_link';
  contentLayout: ContentLayout;
  externalLink: string;
}

const store = defineStore('curriculum', () => {
  const { loadCurriculum: loadCurriculumAction } = mapActions();
  function loadCurriculum({ force, throwError }: { force: boolean; throwError: boolean }) {
    return loadCurriculumAction({ force, throwError });
  }

  const {
    curriculumStatements: stateCurriculumStatements,
    selectedCurriculum: stateSelectedCurriculum,
    curriculumThemes: stateCurriculumThemes,
    selectedInstitution,
  } = mapState();

  const isAshton = computed(() => selectedInstitution.value.name == 'Ashton on Mersey SCITT');
  const curriculumNoun = computed(() => 'Curriculum');
  const curriculumThemeNoun = computed(() => (isAshton.value ? 'Learning Theme' : 'Curriculum Theme'));
  const curriculumStatementNoun = computed(() => (isAshton.value ? 'Learning Theme' : 'Curriculum Statement'));
  const curriculumStatementNounPluralised = computed(() =>
    isAshton.value ? 'Learning Themes' : 'Curriculum Statements'
  );

  const selectedCurriculum = computed<Curriculum | null>(() =>
    fromSnakeCaseToCamelCase<Curriculum>(stateSelectedCurriculum.value)
  );

  const curriculumThemes = computed<CurriculumThemes | null>(() =>
    fromSnakeCaseToCamelCase<CurriculumThemes>(stateCurriculumThemes.value)
  );

  const curriculumStatements = computed<CurriculumStatements | null>(() =>
    fromSnakeCaseToCamelCase<CurriculumStatements>(stateCurriculumStatements.value)
  );

  return {
    loadCurriculum,
    selectedCurriculum,
    curriculumThemes,
    curriculumStatements,
    curriculumNoun,
    curriculumThemeNoun,
    curriculumStatementNoun,
    curriculumStatementNounPluralised,
  };
});

export const useCurriculumStore = useStore(store);
