import { createStore } from 'vuex';
import config from '../utils/config';
import { evidenceTypes } from '../models/evidence-types';
import api from '../entrypoints/application/api';
import { hasPermissionForStudent, hasPermission, hasPermissionForSchool } from '../mixins/global-mixins';
import { isOnEcDomain } from '../theme';
import reviewTemplateStore from './review-template';
import studentReviewStore from './student-review';
import moment from 'moment';
import { fromSnakeCaseToCamelCase } from '../utils/transforms';
import { institutionNoun } from '@/utils/institution';
import { regionalisedYearGroups } from '@/utils/regions';
import clientInfo from '@/utils/client-info';
import { pluralise, capitaliseFirstLetters } from '@/utils/text';
import { mapNotifications } from '@/components/notifications/notifications';
import { refreshUser, clearUser } from '@/models/user';
import { renderFriendlyText } from '@/utils/text';
import EventBus from '@/utils/event-bus';

async function actionWithProcessingAndError(commit, actionName, action, throwError = false, background = false) {
  // Could in future implement stateBackgroundProcessing if turns out to be useful
  if (!background) commit('processing', actionName);
  try {
    await action();
    if (!background) commit('processingFinished', actionName);
  } catch (e) {
    commit('error', actionName);
    if (throwError) {
      throw e;
    } else {
      console.log(actionName, e);
    }
  }
}

const defaultState = {
  isNavigating: false,
  now: null,
  breadcrumbs: [],
  appMessages: [],
  appVersion: {
    current: '',
    latest: '',
    breakingVersions: [],
  },
  errors: {},
  processing: {},
  navigationDrawer: true,
  evidenceFilters: {
    standardCodes: [],
    types: [],
    onlyFavourites: false,
  },
  subjects: [],
  compoundSubjects: [
    'PE with Biology',
    'PE with EBacc science',
    'PE with Geography',
    'PE with Maths',
    'PE with SEN',
    'Science with Biology',
    'Science with Chemistry',
  ],
  featureConfigs: [],
  institutionFeatureConfiguration: [],
  permissions: [],
  standards: [],
  staffStudents: {},
  regions: [
    { title: 'England', value: 'english' },
    {
      title: 'Australia',
      value: 'australian',
    },
  ],
  groups: [],
  types: evidenceTypes,
  user: null,
  selectedStudent: null,
  userStaff: null,
  userStudent: null,
  studentStaffRoles: null,
  selectedSchool: null,
  selectedCohort: null,
  adminInstitutions: null,
  adminInstitution: null,
  adminCohorts: [],
  adminCohort: null,
  cohortCache: {},
  selectedInstitution: null,
  selectedCurriculum: null,
  selectedCohortCourse: null,
  curriculumThemes: null,
  curriculumStatements: null,
  isImpersonating: false,
  curriculumResourceTypes: ['Research', 'Best Practice', 'Other'],
  curriculumSelectedTab: 0,
  cohorts: [],
  schools: [],
  roles: [],
  institutionStudents: [],
  standardSets: [],
  judgementSets: [],
  part2JudgementSet: null,
  stPhilipsLegacyPart2JudgementSet: null,
  selectedStandardSet: null,
  reviewTypeItems: [
    { value: 'termly', title: 'Progress Review', completionWindowInWeeks: 2 },
    { value: 'end_of_year_1', title: 'End of Year 1', completionWindowInWeeks: 3 },
    { value: 'end_of_year_2', title: 'End of Year 2', completionWindowInWeeks: 3 },
    { value: 'leavers', title: 'Interim Assessment', completionWindowInWeeks: null },
  ],
  activeEcts: {
    loadTime: null,
    ects: [],
    progress: 0,
  },
  staffTrainingModules: [],
  notificationsState: [],
  notificationsUnreadCount: 0,
  ...reviewTemplateStore.defaultState,
  ...studentReviewStore.defaultState,
};

function updateSelectedCohort(state, selectedCohort) {
  if (selectedCohort) {
    // Sometimes the selectedCohort is updated with a cohort without students and we want to preserve the students - especially in the cache
    const cachedCohort = state.cohortCache[selectedCohort.id];
    const students = selectedCohort.students || cachedCohort?.students;
    state.selectedCohort = { ...selectedCohort, students };
    state.cohortCache[state.selectedCohort.id] = state.selectedCohort;
  } else {
    state.selectedCohort = null;
  }
}

function updateSelectedCohortWithChanges(state, changes) {
  if (!state.selectedCohort) return;
  updateSelectedCohort(state, { ...state.selectedCohort, ...changes });
}

export default createStore({
  state: { ...defaultState },
  mutations: {
    startNavigating(state) {
      state.isNavigating = true;
    },
    stopNavigating(state) {
      state.isNavigating = false;
    },
    processing(state, key) {
      state.processing[key] = true;
      state.errors[key] = false;
    },
    error(state, key) {
      state.processing[key] = false;
      state.errors[key] = true;
    },
    processingFinished(state, key) {
      state.processing[key] = false;
    },
    setPageInformation(state, { breadcrumbs } = { breadcrumbs: [] }) {
      state.breadcrumbs = breadcrumbs;
    },
    updateNavigationDrawer(state, x) {
      state.navigationDrawer = x;
    },
    updateEvidenceStandardCodesFilter(state, standardCodes) {
      state.evidenceFilters.standardCodes = standardCodes;
    },
    updateEvidenceOnlyFavouritesFilter(state, onlyFavourites) {
      state.evidenceFilters.onlyFavourites = onlyFavourites;
    },
    updateEvidenceTypesFilter(state, types) {
      state.evidenceFilters.types = types;
    },
    updateUser(state, user) {
      state.user = user ? { ...user } : null;
    },
    updateSelectedStudent(state, selectedStudent) {
      state.selectedStudent = { ...selectedStudent };
    },
    clearUserStaff(state) {
      state.userStaff = null;
      state.selectedInstitution = null;
      state.roles = [];
      state.institutionStudents = [];
    },
    clearUserStudent(state) {
      state.userStudent = null;
      state.selectedInstitution = null;
      state.institutionStudents = [];
      state.selectedCohortCourse = null;
    },
    clearSelectedSchool(state) {
      state.selectedSchool = null;
    },
    clearSelectedCohort(state) {
      state.selectedCohort = null;
    },
    clearSelectedStudent(state) {
      state.selectedStudent = null;
      state.studentStaffRoles = null;
    },
    clearStudentStaffRoles(state) {
      state.studentStaffRoles = null;
    },
    updateSelectedSchool(state, selectedSchool) {
      state.selectedSchool = selectedSchool ? { ...selectedSchool } : null;
    },
    updateAdminInstitution(state, adminInstitution) {
      state.adminInstitution = adminInstitution ? { ...adminInstitution } : null;
      state.adminInstitutions = state.adminInstitutions.map(i => {
        if (i.id != adminInstitution?.id) return i;
        return adminInstitution;
      });
    },
    updateAdminInstitutionWithChanges(state, changes) {
      if (state.adminInstitution) {
        state.adminInstitution = { ...state.adminInstitution, ...changes };
        state.adminInstitutions = state.adminInstitutions.map(i => {
          if (i.id != state.adminInstitution.id) return i;
          return state.adminInstitution;
        });
      }
    },
    updateAdminCohorts(state, cohorts) {
      state.adminCohorts = cohorts.sortBy('name');
      if (state.adminCohort) {
        state.adminCohort = state.adminCohorts.find(c => c.id === state.adminCohort.id);
      }
    },
    updateAdminCohort(state, cohort) {
      state.adminCohort = cohort;
    },
    updateAdminCohortWithChanges(state, changes) {
      if (state.adminCohort) {
        state.adminCohort = { ...state.adminCohort, ...changes };
      }
    },
    updateSelectedCohort(state, { selectedCohort }) {
      updateSelectedCohort(state, selectedCohort);
    },
    updateSelectedCohortWithChanges(state, changes) {
      updateSelectedCohortWithChanges(state, changes);
    },
    updateSelectedCohortStudents(state, students) {
      updateSelectedCohortWithChanges(state, { students: students ? students : null });
    },
    updateUserStaff(state, staff) {
      // This is deliberately not cleared on force selectUserStaff as the TutorStaffPage relies on not having to reload this (and possibly other bits of code)
      if (state.userStaff?.id !== staff?.id) {
        state.institutionStudents = [];
      }
      state.userStaff = staff ? { ...staff } : null;
      if (staff.institution?.id !== state.selectedInstitution?.id) {
        state.roles = [];
      }
      state.selectedInstitution = staff.institution ? { ...staff.institution } : null;
    },
    updateUserStudent(state, student) {
      if (state.userStudent?.id !== student.id) {
        state.institutionStudents = [];
      }
      state.userStudent = { ...student };
      state.selectedInstitution = { ...student.institution };
      state.selectedCohortCourse = fromSnakeCaseToCamelCase(student.cohort.cohort_course);
    },
    updateSelectedStandardSet(state, standardSet) {
      state.selectedStandardSet = { ...standardSet };
    },
    updateAdminInstitutions(state, institutions) {
      state.adminInstitutions = institutions ? institutions : [];
    },
    updateSelectedInstitutionReflectionTemplateId(state, reflectionTemplateId) {
      state.selectedInstitution.config.reflection_template_id = reflectionTemplateId;
    },
    updateSelectedInstitutionReflectionTemplate(state, reflectionTemplate) {
      state.selectedInstitution.config.course_reflection_template = reflectionTemplate;
    },
    updateSelectedCurriculum(state, curriculum) {
      state.selectedCurriculum = curriculum;
    },
    updateSelectedCohortCourse(state, course) {
      state.selectedCohortCourse = course;
    },
    updateCurriculumThemes(state, curriculumThemesArray) {
      state.curriculumThemes = {};
      curriculumThemesArray.forEach(x => {
        state.curriculumThemes[x.id] = x;
      });

      const curriculumStatementsArray = curriculumThemesArray
        .map(x =>
          x.curriculum_statements.map(y => {
            return {
              ...y,
              theme_id: x.id,
            };
          })
        )
        .flat();
      state.curriculumStatements = {};
      curriculumStatementsArray.forEach(x => {
        state.curriculumStatements[x.id] = x;
      });
    },
    updateCurriculumSelectedTab(state, selectedTab) {
      state.curriculumSelectedTab = selectedTab;
    },
    updateSubjects(state, subjects) {
      state.subjects = subjects ? subjects : [];
    },
    updateGroups(state, groups) {
      state.groups = groups ? groups : [];
    },
    logout(state) {
      for (const key of Object.keys(defaultState)) {
        state[key] = defaultState[key];
      }
    },
    isImpersonating(state) {
      state.isImpersonating = true;
    },
    updateStandards(state, standards) {
      state.standards = standards;
    },
    updateStandardSets(state, standardSets) {
      state.standardSets = standardSets;
    },
    updateSelectedInstitutionHomePageRubrics(state, { homePageRubric, staffHomePageRubric }) {
      state.selectedInstitution.config.home_page_rubric = homePageRubric;
      state.selectedInstitution.config.staff_home_page_rubric = staffHomePageRubric;
    },
    updateInstitutionStudents(state, students) {
      state.institutionStudents = students;
    },
    updateStaffStudents(state, { staffId, students }) {
      state.staffStudents[staffId] = students;
    },
    updateCohorts(state, cohorts) {
      state.cohorts = cohorts.sortBy('name');
    },
    updateSchools(state, schools) {
      state.schools = schools;
    },
    clearSchools(state) {
      state.schools = [];
    },
    updateRoles(state, roles) {
      state.roles = roles;
    },
    updateJudgementSets(state, judgementSets) {
      state.judgementSets = judgementSets;
    },
    updatePart2JudgementSet(state, judgementSet) {
      state.part2JudgementSet = judgementSet;
    },
    updateStPhilipsLegacyPart2JudgementSet(state, judgementSet) {
      state.stPhilipsLegacyPart2JudgementSet = judgementSet;
    },
    updateCalendarShowForStudents(state, showForStudents) {
      state.selectedInstitution = {
        ...state.selectedInstitution,
        config: {
          ...state.selectedInstitution.config,
          show_calendar_for_students: showForStudents,
        },
      };
    },
    updateStudentStaffRoles(state, studentStaffRoles) {
      state.studentStaffRoles = studentStaffRoles;
    },
    updateCurrentAppVersion(state, version) {
      state.appVersion.current = version;
    },
    updateVersionsAndAppMessages(state, { version, breakingVersions, appMessages }) {
      state.appVersion.latest = version;
      state.appVersion.breakingVersions = breakingVersions;
      state.appMessages = fromSnakeCaseToCamelCase(appMessages);
    },
    updateFeatureConfig(state, config) {
      const i = state.featureConfigs.findIndex(c => c.id === config.id);
      const x = state.featureConfigs;
      x.splice(i, 1, config);
      state.featureConfigs = x;
    },
    updateInstitutionFeatureConfiguration(state, institutionConfig) {
      state.institutionFeatureConfiguration = institutionConfig;
    },
    updatePermission(state, permission) {
      const i = state.permissions.findIndex(p => p.id === permission.id);
      const x = state.permissions;
      x.splice(i, 1, permission);
      state.permissions = x;
    },
    updateFeatureConfigs(state, configs) {
      state.featureConfigs = configs;
    },
    updatePermissions(state, permissions) {
      state.permissions = permissions;
    },
    updateSelectedCohortMentorMeetingTemplate(state, mentorMeetingTemplate) {
      updateSelectedCohortWithChanges(state, { mentor_meeting_template: mentorMeetingTemplate });
    },
    updateSelectedCohortShowAssignments(state, showAssignments) {
      updateSelectedCohortWithChanges(state, { show_assignments: showAssignments });
    },
    updateActiveEcts(state, ects) {
      state.activeEcts = {
        ects,
        loadTime: moment(),
      };
    },
    clearActiveEcts(state) {
      state.activeEcts = {
        loadTime: null,
        ects: [],
        progress: 0,
      };
    },
    updateStaffTrainingModules(state, modules) {
      state.staffTrainingModules = modules;
    },
    updateNotifications(state, { notifications, unreadCount }) {
      state.notificationsState = notifications;
      state.notificationsUnreadCount = unreadCount;
    },
    updateNotificationsLastSeen(state, notificationsLastSeenAt) {
      state.user = { ...state.user, notificationsLastSeenAt };
    },
    ...reviewTemplateStore.mutations,
    ...studentReviewStore.mutations,
  },
  actions: {
    refreshUser({ commit, state, dispatch }) {
      return refreshUser(state, commit, dispatch, api);
    },
    clearUser({ commit, dispatch }) {
      return clearUser(commit, dispatch);
    },
    loadStandardSets({ commit, state }) {
      if (state.standardSets.length) return;
      return api.get(`/institutions/${state.selectedInstitution.id}/standard-sets`).then(r => {
        commit('updateStandardSets', r.data);
      });
    },
    loadStandardSetForStudent({ commit, state }) {
      if (
        state.selectedStandardSet &&
        state.selectedStandardSet.cohort_id ===
          (state.user.student ? state.user.student.cohort_id : state.selectedStudent.cohort.id)
      )
        return;
      const url = state.user.student
        ? `/standard-sets/get-my-standard-set`
        : `/students/${state.selectedStudent.id}/standard-set`;
      return api.get(url).then(r => {
        commit('updateSelectedStandardSet', r.data);
      });
    },
    loadStandards({ commit, state }) {
      if (state.standards.length) return;
      return api.get(`/institutions/${state.selectedInstitution.id}/standards/template`).then(r => {
        commit('updateStandards', r.data);
      });
    },
    loadGroups({ commit, state }, { force = false, throwError = false }) {
      if (state.groups.length && !force) return;
      return actionWithProcessingAndError(
        commit,
        'loadGroups',
        async () => {
          const r = await api.get(`/groups?cohortId=${state.selectedCohort.id}`);
          commit('updateGroups', r.data);
        },
        throwError
      );
    },
    loadFeatureConfigsForAdmin({ commit, state }) {
      if (state.featureConfigs.length) return;
      return api.get('admin/feature-configs').then(r => {
        commit('updateFeatureConfigs', r.data);
      });
    },
    loadInstitutionFeatureConfiguration({ commit, state }) {
      if (state.institutionFeatureConfiguration.length) return;
      return api.get(`/institutions/${state.selectedInstitution.id}/institution-feature-configs`).then(r => {
        commit('updateInstitutionFeatureConfiguration', r.data);
        return api.get(`institutions/${state.selectedInstitution.id}/feature-configs`).then(r => {
          commit('updateFeatureConfigs', r.data);
        });
      });
    },
    loadPermissionsForAdmin({ commit, state }) {
      if (state.permissions.length) return;
      return api.get(`/admin/permissions`).then(r => {
        commit('updatePermissions', r.data);
      });
    },
    loadPermissionsForInstitution({ commit, state }) {
      if (state.permissions.length) return;
      return api.get(`/permissions`).then(r => {
        commit('updatePermissions', r.data);
      });
    },
    loadCurriculum({ commit, state, getters }, { force, throwError } = { force: false, throwError: false }) {
      const cohort = state.user.student?.cohort || state.selectedCohort;
      if (!state.selectedInstitution.curriculum_id && !cohort?.curriculum_id) return;

      if (
        getters.stateProcessing('loadCurriculum') ||
        (!force &&
          state.selectedCurriculum &&
          ((cohort?.has_curriculum && cohort?.curriculum_id === state.selectedCurriculum?.id) ||
            (!cohort?.has_curriculum && state.selectedInstitution?.curriculum_id === state.selectedCurriculum?.id)))
      )
        return;
      const urlRoot = state.user.student
        ? `cohorts/${state.user.student.cohort.id}`
        : state.selectedCohort
        ? `cohorts/${state.selectedCohort.id}`
        : `institutions/${state.selectedInstitution.id}`;

      return actionWithProcessingAndError(
        commit,
        'loadCurriculum',
        async () => {
          const r = await api.get(`${urlRoot}/curriculum`);
          commit('updateSelectedCurriculum', r.data);
          commit('updateCurriculumThemes', r.data.curriculum_themes);
        },
        throwError
      );
    },
    loadSubjects({ commit, state }) {
      if (state.subjects.length != 0) return;
      return api.get('subjects').then(r => {
        commit('updateSubjects', r.data);
      });
    },
    selectUserStaff({ commit, state }, { studentId, schoolId, cohortId, institutionId, force = false }) {
      // Deliberately == in the following as they may come from URLs (should change this with typescript)
      let staff = null;
      if (studentId) {
        staff = state.user.staff.find(st => st.staff_roles.some(x => x.student_id == studentId));
      }
      if (schoolId) {
        staff = state.user.staff.find(st => st.staff_roles.some(x => x.school_id == schoolId));
      }
      if (!staff && cohortId) {
        staff = state.user.staff.find(st => st.staff_roles.some(x => x.cohort_id == cohortId));
      }
      if (!staff && institutionId) {
        staff = state.user.staff.find(
          st => st.staff_roles.some(x => x.institution_id == institutionId) || st.institution.id == institutionId
        );
      }

      if (staff && (state.userStaff?.id !== staff.id || force)) {
        commit('updateUserStaff', staff);
        EventBus.emit('dispatch-load-staff-training', { staffId: staff.id, force });
      }
    },
    selectUserStudent({ commit, state, dispatch }, { studentId, institutionId, force = false }) {
      const students = state.user.students || state.user.student ? [state.user.student] : [];

      let student = null;
      if (studentId) {
        student = students.find(s => s.id == studentId);
      }

      if (!student && institutionId) {
        student = students.find(s => s.institution.id == institutionId);
      }

      if (student && (state.userStudent?.id !== student.id || force)) {
        commit('updateUserStudent', student);
        dispatch('loadStandardSetForStudent');
        // Bit odd to load these separately but standards API has some extra filtering for review_only standards
        dispatch('loadStandards');
      }
    },
    loadStudent({ commit, state, dispatch }, studentId) {
      return actionWithProcessingAndError(commit, 'loadStudent', async () => {
        const r = await api.get(`/students/${studentId}`);
        commit('updateSelectedStudent', r.data);
        commit('updateStandards', r.data.standard_set.standards);
        if (!state.selectedCohort || state.selectedCohort.id !== r.data.cohort.id) {
          commit('updateSelectedCohort', { selectedCohort: r.data.cohort });
        }

        if (!state.selectedCohortCourse) {
          commit('updateSelectedCohortCourse', fromSnakeCaseToCamelCase(r.data.cohort.cohort_course));
        }

        if (
          r.data.ect?.current_school &&
          (hasPermissionForSchool(state.userStaff, 'schools.edit', r.data.ect.current_school) ||
            hasPermissionForSchool(state.userStaff, 'schools.view', r.data.ect.current_school)) &&
          (!state.selectedSchool || state.selectedSchool.id !== r.data.ect?.current_school.id)
        ) {
          commit('updateSelectedSchool', fromSnakeCaseToCamelCase(r.data.ect.current_school));
        }

        dispatch('selectUserStaff', { studentId, cohortId: r.data.cohort_id, institutionId: r.data.institution_id });
      });
    },
    loadStudentStaffRoles({ commit, state }) {
      if (state.studentStaffRoles) return;

      let url = '';
      if (state.user.student) {
        url = '/student-staff';
      }
      if (state.user.staff && state.selectedStudent) {
        url = `/students/${state.selectedStudent.id}/student-staff`;
      }
      if (!url) return;

      return actionWithProcessingAndError(commit, 'loadStudentStaffRoles', async () => {
        const r = await api.get(url);
        commit('updateStudentStaffRoles', r.data);
      });
    },
    loadStudentsForStaff({ commit, state }) {
      const staffId = state.userStaff.id;
      if (state.staffStudents[staffId]?.length) return;
      return api.get(`staff/${staffId}/staff-students`).then(r => {
        commit('updateStaffStudents', { staffId, students: r.data });
      });
    },
    loadAllActiveStudents({ commit, state }) {
      if (state.institutionStudents.length) return;

      return actionWithProcessingAndError(commit, 'loadAllActiveStudents', async () => {
        let students = [];
        let offset = 0;
        let firstStudentId = -1;

        while (true) {
          const r = await api.get(`institutions/${state.selectedInstitution.id}/students?offset=${offset}&limit=50`);
          if (r.data.length === 0) break;
          // Hopefully redundant check to avoid infinite loops
          if (firstStudentId === r.data[0].id) break;
          firstStudentId = r.data[0].id;

          offset += r.data.length;
          students = [...students, ...r.data];
        }

        commit('updateInstitutionStudents', students);
      });
    },
    loadSchool({ commit, state, dispatch }, { schoolId, force = false }) {
      // selectedSchool won't have `.ects` if it was set by directly loading an ECT
      if (!state.selectedSchool || !state.selectedSchool.ects || state.selectedSchool.id != schoolId || force) {
        return actionWithProcessingAndError(commit, 'loadSchool', async () => {
          const r = await api.get(`/schools/${schoolId}`);
          const school = r.data;
          school.ects = school.ects.sortBy('details_filled_in', 'name', 'email');
          commit('updateSelectedSchool', school);

          dispatch('selectUserStaff', { schoolId, institutionId: r.data.institutionId });
        });
      }
    },
    loadCohort({ commit, state, dispatch }, { cohortId, force = false, throwError = false, background = false }) {
      if (force || !state.selectedCohort || state.selectedCohort.id !== cohortId) {
        return actionWithProcessingAndError(
          commit,
          'loadCohort',
          async () => {
            let cohort = state.cohortCache[cohortId];
            if (force || !cohort) {
              const r = await api.get(`/cohorts/${cohortId}?skip_students=true`);
              cohort = r.data;
            }

            commit('updateSelectedCohort', { selectedCohort: cohort });
            dispatch('selectUserStaff', { cohortId, institutionId: cohort.institution_id });
          },
          throwError,
          background
        );
      }
    },
    loadCohorts({ commit, state }, { force = false } = {}) {
      if (state.cohorts.length && !force) return;

      return actionWithProcessingAndError(commit, 'loadCohorts', async () => {
        const r = await api.get(`/institutions/${state.selectedInstitution.id}/cohorts`);
        commit('updateCohorts', r.data);
      });
    },
    loadSchools({ commit, state }, { force = false } = {}) {
      if (state.schools.length && !force) return;
      if (!hasPermission(state.userStaff, 'schools.edit') && !hasPermission(state.userStaff, 'schools.edit')) return;

      return actionWithProcessingAndError(commit, 'loadSchools', async () => {
        const r = await api.get(`/institutions/${state.selectedInstitution.id}/schools`);
        commit('updateSchools', r.data);
      });
    },
    loadRoles({ commit, state }) {
      if (state.roles.length) return;
      return actionWithProcessingAndError(commit, 'loadRoles', async () => {
        const r = await api.get(`/institutions/${state.selectedInstitution.id}/roles`);
        commit('updateRoles', r.data);
      });
    },
    loadJudgementSets({ commit, state }, { force = false } = {}) {
      if (state.judgementSets.length && !force) return;
      return api.get(`institutions/${state.selectedInstitution.id}/institution-judgement-sets`).then(r => {
        commit('updateJudgementSets', r.data);
      });
    },
    loadPart2JudgementSet({ commit, state }) {
      if (state.part2JudgementSet) return;
      return api.get('part2-judgement-set').then(r => {
        commit('updatePart2JudgementSet', r.data);
      });
    },
    loadStPhilipsLegacyPart2JudgementSet({ commit, state }) {
      if (state.part2JudgementSet) return;
      return api.get('st-philips-legacy-part2-judgement-set').then(r => {
        commit('updateStPhilipsLegacyPart2JudgementSet', r.data);
      });
    },
    loadVersionsAndAppMessages({ commit }) {
      return api
        .get('/version')
        .then(r => {
          commit('updateVersionsAndAppMessages', {
            version: r.data.version,
            breakingVersions: r.data.breaking_versions,
            appMessages: r.data.app_messages,
          });
        })
        .catch(e => {
          console.log('Cannot load version', e);
        });
    },
    loadStaffTrainingModules({ commit, state }, { force = false } = {}) {
      if (state.staffTrainingModules.length && !force) return;
      return actionWithProcessingAndError(
        commit,
        'loadStaffTrainingModules',
        async () => {
          const r = await api.get(`/institutions/${state.selectedInstitution.id}/staff-training/modules`);
          commit('updateStaffTrainingModules', r.data.sortBy(['status', 'name']));
        },
        true
      );
    },
    loadNotifications({ state, commit }, { throwError = false, force = false } = { throwError: false, force: false }) {
      if (!state.user || state.user.isAdmin) return;
      if (state.notificationsState.length > 0 && !force) return;
      return actionWithProcessingAndError(
        commit,
        'loadNotifications',
        async () => {
          const r = await api.get(`/notifications/top-5`);
          commit('updateNotifications', r.data);
        },
        throwError
      );
    },
    updateNotificationsLastSeen({ commit }) {
      return actionWithProcessingAndError(commit, 'updateNotificationsLastSeen', async () => {
        commit('updateNotificationsLastSeen', moment().toISOString());
        await api.post(`/notifications/last-seen`);
      });
    },
    async markNotificationAsRead({ commit, state }, { id }) {
      try {
        commit('updateNotifications', {
          notifications: state.notificationsState.map(n => {
            if (n.id !== id) return n;
            return {
              ...n,
              read: true,
            };
          }),
          unreadCount: state.notificationsUnreadCount - 1,
        });
        await api.post(`notifications/${id}/mark-as-read`, {});
      } catch (e) {
        console.log('Failed to mark notification as read', e);
      }
    },
    ...reviewTemplateStore.actions,
    ...studentReviewStore.actions,
  },
  getters: {
    clientInfo() {
      return clientInfo;
    },
    isBrightFuturesAB(state) {
      return state.selectedInstitution.name === 'Bright Futures TSH';
    },
    hasAbSla(state) {
      return Boolean(state.selectedInstitution.ab_sla_link);
    },
    ectPackages(state) {
      return [
        {
          name: state.selectedInstitution.ab_and_ecf_ect_package_name,
          description: state.selectedInstitution.ab_and_ecf_ect_package_description,
          ref: 'ab_and_ecf',
        },
        {
          name: state.selectedInstitution.ab_only_ect_package_name,
          description: state.selectedInstitution.ab_only_ect_package_description,
          ref: 'ab_only',
        },
        {
          name: state.selectedInstitution.ecf_only_ect_package_name,
          description: state.selectedInstitution.ecf_only_ect_package_description,
          ref: 'ecf_only',
        },
      ];
    },
    isEmailVerificationOnForCurrentUser(state) {
      return config.isInstitutionConfigTurnedOnForCurrentUser(state.user, 'email_verification');
    },
    isEmailVerificationOnForSelectedInstitution(state) {
      return state.selectedInstitution.config.email_verification;
    },
    activeCohorts(state) {
      return state.cohorts.filter(c => c.status === 'active');
    },
    studentScopedRoles(state) {
      return state.roles.filter(r => r.student_scope === true);
    },
    rolesWithStudent(state, getters) {
      return [...state.roles, { id: 'student', name: getters.traineeNounCapitalised() }].map(r => ({
        ...r,
        pluralisedName: pluralise(r.name),
      }));
    },

    appUpdateAvailable(state) {
      return state.appVersion.latest !== '' && state.appVersion.current !== state.appVersion.latest;
    },
    appVersionInteger(state) {
      if (!state.appVersion) return null;
      return Number.parseInt(state.appVersion.current.slice(1)) || null;
    },
    breakingAppUpdateRequired(state) {
      if (state.appVersion.latest === '') return false;
      const latest = Number.parseInt(state.appVersion.latest.slice(1));
      const current = Number.parseInt(state.appVersion.current.slice(1));
      if (latest === current) return false;
      const breakingVersions = state.appVersion.breakingVersions.map(x => Number.parseInt(x.slice(1)));
      return breakingVersions.some(x => x > current && x <= latest);
    },
    stateError(state) {
      return k => state.errors[k];
    },
    stateErrors(state) {
      return keys => keys.map(x => state.errors[x]).reduce((a, b) => a || b, false);
    },
    stateProcessing(state) {
      return k => state.processing[k];
    },
    stateProcessings(state) {
      return keys => keys.map(x => state.processing[x]).reduce((a, b) => a || b, false);
    },
    isMosaicEarlyCareers(state) {
      if (isOnEcDomain()) return true;

      if (state.selectedInstitution) {
        return state.selectedInstitution.config.early_careers;
      }

      if (state.user?.singleInstitution) {
        return state.user.singleInstitution.config.early_careers;
      }

      return false;
    },
    isIttEarlyCareersOrUnknown(state) {
      let isIttOrEarlyCareers = 'Unknown';

      if (state.selectedInstitution) {
        isIttOrEarlyCareers = state.selectedInstitution.config.early_careers ? 'Early Careers' : 'ITT';
      } else {
        if (state.user.student) {
          isIttOrEarlyCareers = state.user.student.institution.config.early_careers ? 'Early Careers' : 'ITT';
        } else if (state.user.staff) {
          if (state.user.staff.every(s => s.institution.config.early_careers)) {
            isIttOrEarlyCareers = 'Early Careers';
          } else if (state.user.staff.every(s => !s.institution.config.early_careers)) {
            isIttOrEarlyCareers = 'ITT';
          }
        }
      }

      return isIttOrEarlyCareers;
    },
    institutionNoun(_, getters) {
      return institutionNoun(getters.isIttEarlyCareersOrUnknown);
    },
    contactProviderMessage(state, getters) {
      const selectedOrSingleInstitution = state.selectedInstitution || state.user.singleInstitution;
      return `Please contact your
      ${getters.institutionNoun}${
        selectedOrSingleInstitution?.contact_email ? ` on ${selectedOrSingleInstitution.contact_email} ` : ''
      }
      if you think this is incorrect.`;
    },
    traineeNounArticle(state) {
      return state.selectedInstitution?.config.early_careers ? 'an' : 'a';
    },
    traineeNounArticleCapitalised(state, getters) {
      return capitaliseFirstLetters(getters.traineeNounArticle);
    },
    traineeNoun(_, getters) {
      let traineeNoun = '';
      if (getters.isIttEarlyCareersOrUnknown === 'ITT') traineeNoun = 'trainee';
      else if (getters.isIttEarlyCareersOrUnknown === 'Early Careers') traineeNoun = 'ECT';
      else traineeNoun = 'trainee/ECT';

      return article => `${article ? `${getters.traineeNounArticle} ` : ''}${traineeNoun}`;
    },
    traineeNounPluralised(state, getters) {
      return () => pluralise(getters.traineeNoun(false));
    },
    traineeNounCapitalised(state, getters) {
      return article =>
        (article ? `${getters.traineeNounArticle} ` : '') + capitaliseFirstLetters(getters.traineeNoun());
    },
    traineeNounCapitalisedAndPluralised(state, getters) {
      //this should ideally return a function for consistency
      return capitaliseFirstLetters(getters.traineeNounPluralised());
    },
    reviewNoun(state) {
      return state.selectedInstitution?.config.review_noun || 'review point';
    },
    reviewNounPluralised(state, getters) {
      return pluralise(getters.reviewNoun);
    },
    reviewNounCapitalised(state, getters) {
      return capitaliseFirstLetters(getters.reviewNoun);
    },
    reviewNounCapitalisedAndPluralised(state, getters) {
      return capitaliseFirstLetters(getters.reviewNounPluralised);
    },
    curriculumNoun(state) {
      // This should be removed at some point and doesn't quite make sense because they refer to slightly different things
      const isAshton = state.selectedInstitution.name == 'Ashton on Mersey SCITT';
      if (isAshton) {
        return 'Learning Theme';
      } else {
        return 'Curriculum';
      }
    },
    standardNoun(state) {
      return state.selectedStandardSet?.standard_noun;
    },
    standardNounPluralised(state) {
      return state.selectedStandardSet?.standard_noun_plural;
    },
    standardNounCapitalised(state) {
      return capitaliseFirstLetters(state.selectedStandardSet?.standard_noun);
    },
    standardNounCapitalisedAndPluralised(state) {
      return capitaliseFirstLetters(state.selectedStandardSet?.standard_noun_plural);
    },
    cohortCourseTermNoun(state) {
      return state.selectedCohortCourse?.termNoun;
    },
    cohortCourseTermNounCapitalised(state, getters) {
      return capitaliseFirstLetters(getters.cohortCourseTermNoun);
    },
    cohortCourseTermNounPluralised(state) {
      return state.selectedCohortCourse?.termNounPlural;
    },
    cohortCourseTermNounCapitalisedAndPluralised(state, getters) {
      return capitaliseFirstLetters(getters.cohortCourseTermNounPluralised);
    },
    cohortCourseWeekNoun(state) {
      return state.selectedCohortCourse?.weekNoun || '';
    },
    cohortCourseWeekNounCapitalised(state, getters) {
      return capitaliseFirstLetters(getters.cohortCourseWeekNoun);
    },
    cohortCourseWeekNounPluralised(state) {
      return state.selectedCohortCourse.weekNounPlural;
    },
    cohortCourseWeekNounCapitalisedAndPluralised(state, getters) {
      return capitaliseFirstLetters(getters.cohortCourseWeekNounPluralised);
    },

    friendlyTextMap(state, getters) {
      return {
        traineeNoun: getters.traineeNoun(),
        traineeNounCapitalised: getters.traineeNounCapitalised(),
        traineeNounPluralised: getters.traineeNounPluralised(),
        traineeNounCapitalisedAndPluralised: getters.traineeNounCapitalisedAndPluralised,
        reviewNoun: getters.reviewNoun,
        reviewNounCapitalised: getters.reviewNounCapitalised,
        reviewNounPluralised: getters.reviewNounPluralised,
        reviewNounCapitalisedAndPluralised: getters.reviewNounCapitalisedAndPluralised,
      };
    },
    friendlyFeatureConfigs(state, getters) {
      return state.featureConfigs.map(f => ({
        ...f,
        really_friendly_name: renderFriendlyText(f.friendly_name, getters.friendlyTextMap),
        really_friendly_description: renderFriendlyText(f.friendly_description, getters.friendlyTextMap),
      }));
    },
    friendlyInstitutionFeatureConfiguration(state, getters) {
      return getters.friendlyFeatureConfigs.map(f => {
        return {
          ...f,
          switched_on: state.institutionFeatureConfiguration.find(x => f.id === x.feature_config_id)?.on,
        };
      });
    },
    friendlyPermissions(state, getters) {
      return state.permissions.map(p => ({
        ...p,
        really_friendly_name: renderFriendlyText(p.friendly_name, getters.friendlyTextMap),
        really_friendly_description: renderFriendlyText(p.friendly_description, getters.friendlyTextMap),
      }));
    },
    selectedRegion(state) {
      return state.regions.find(x => x.value === state.adminInstitution.config.region);
    },
    institutionYearGroups(state) {
      return regionalisedYearGroups(state.selectedInstitution.config.region);
    },
    showEvidence(state) {
      return state.selectedInstitution?.config.show_evidence;
    },
    showAssignments(state) {
      if (state.user.student) {
        return state.user.student.cohort.show_assignments;
      } else if (state.selectedCohort) {
        return state.selectedCohort.show_assignments;
      }
      return false;
    },
    curriculumEnabled(state) {
      const cohort = state.user.student?.cohort || state.selectedCohort;
      return (
        (state.selectedInstitution.config.has_curriculum || cohort?.has_curriculum) &&
        cohort.show_curriculum_for_students
      );
    },
    curriculumVisible(state, getters) {
      const showCurriculumForCohort =
        state.selectedCohort?.show_curriculum_for_students || state.user.student?.cohort.show_curriculum_for_students;
      return getters.curriculumEnabled && !state.selectedInstitution.config.early_careers && showCurriculumForCohort;
    },
    singleSubstandardPerStandard(state) {
      return state.standards.every(x => x.substandards.length === 1);
    },
    singleStatementPerCurriculumTheme(state) {
      return Object.values(state.curriculumThemes).every(x => x.curriculum_statements.length === 1);
    },
    isProfessionalMentor(state) {
      if (!state.user || !state.selectedStudent) return false;
      return hasPermissionForStudent(state.userStaff, 'Professional Mentor', state.selectedStudent);
    },
    isSubjectMentor(state) {
      if (!state.user || !state.selectedStudent) return false;
      return hasPermissionForStudent(state.userStaff, 'Subject Mentor', state.selectedStudent);
    },
    studentHasProfessionalMentor(state) {
      const student = state.user.student || state.selectedStudent;
      if (!student) return false;
      return student.staff_roles.some(x => x.role.permissions.some(y => y.name == 'Professional Mentor'));
    },
    judgementDescriptors(state) {
      if (!state.selectedInstitution) return [];
      return (
        state.selectedInstitution?.legacy_review_judgement_set.judgement_descriptors.sort(
          (a, b) => a.order > b.order
        ) || []
      );
    },
    professionalJudgementDescriptors(state) {
      if (!state.selectedInstitution) return [];
      return (
        state.selectedInstitution?.legacy_review_professional_judgement_set.judgement_descriptors.sort(
          (a, b) => a.order > b.order
        ) || []
      );
    },
    substandards(state) {
      return state.standards.map(x => x.substandards).flat();
    },
    curriculumThemesOrderedArray(state) {
      return Object.values(state.curriculumThemes).sort((a, b) => a.theme_order - b.theme_order);
    },
    curriculumStatementsOrderedArray(state) {
      return Object.values(state.curriculumStatements).sort((a, b) => a.theme_order - b.theme_order);
    },

    subjectsWithNullOptionAndPrimarySeparation(state) {
      const curriculumSubjects = state.subjects
        .filter(s => {
          return !state.compoundSubjects.includes(s.name);
        })
        .sort((a, b) => (a.name > b.name ? 1 : -1));
      const subjectsWithDivider = curriculumSubjects;
      subjectsWithDivider.unshift({
        divider: true,
      });
      const subjectsWithPrimaryFirst = subjectsWithDivider.sort(function (x, y) {
        return x.name == 'Primary' ? -1 : y.name == 'Primary' ? 1 : 0;
      });
      subjectsWithDivider.unshift({
        divider: true,
      });
      subjectsWithPrimaryFirst.unshift({
        name: 'All Subjects',
        id: null,
      });

      return subjectsWithPrimaryFirst;
    },
    storageSetUp(state) {
      return state.user.student ? state.user.student.storage_set_up : state.userStaff.storage_set_up;
    },
    useOnDemandSharing(state) {
      if (state.user.student) {
        return false;
      } else if (state.selectedStudent) {
        return (
          state.selectedStudent.on_demand_sharing ||
          (state.selectedInstitution.config.on_demand_sharing && state.selectedStudent.on_demand_sharing !== false)
        );
      } else return state.selectedInstitution.config.on_demand_sharing;
    },
    studentStaff(state) {
      return state.studentStaffRoles?.reduce((staff, student_staff) => {
        const existing_staff = staff.find(x => x.email === student_staff.email);

        if (existing_staff) {
          existing_staff.roles.push(student_staff.role);
        } else {
          staff.push({
            name: student_staff.name,
            id: student_staff.id,
            email: student_staff.email,
            roles: [student_staff.role],
          });
        }
        return staff;
      }, []);
    },
    userStaffCohorts(state) {
      return state.userStaff.staff_roles
        .filter(x => x.cohort_id)
        .map(x => ({
          ...x.cohort,
        }));
    },
    notifications(state) {
      if (!state.user) return [];
      return mapNotifications(state.notificationsState, state.user.notificationsLastSeenAt);
    },
    userStaffStudents(state) {
      if (!state.userStaff || !state.staffStudents[state.userStaff.id]) return [];
      return state.staffStudents[state.userStaff.id];
    },
    ...reviewTemplateStore.getters,
    ...studentReviewStore.getters,
  },
});
