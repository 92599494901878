<template>
  <div>
    <mosaic-filter-list-page
      v-model:trigger-background-load="triggerBackgroundLoad"
      object-type="Course Activity"
      :hide-add="true"
      :items="assignments"
      :filtered-items="filteredAssignments"
      :load="load"
    >
      <template #filters>
        <mosaic-text-field
          v-model="nameFilter"
          name="name-filter"
          label="Filter by name"
          style="width: 250px"
          prepend-icon="magnify"
          hide-details
        />
        <mosaic-select
          name="status-filter"
          :items="statuses"
          v-model="statusFilter"
          label="Filter by status"
          style="min-width: 150px"
          density="compact"
          hide-details
          no-icon
        />
        <mosaic-select
          name="overdue-filter"
          :items="['All', 'Overdue', 'Not overdue', 'No due date']"
          v-model="overdueFilter"
          label="Filter by overdue"
          style="min-width: 150px"
          density="compact"
          hide-details
          no-icon
        />
      </template>

      <template #list-snackbar>
        <mosaic-error-snackbar v-model="editEvidenceError" action="edit this Evidence" />
      </template>

      <template #list-item="{ item: sa }">
        <mosaic-list-item
          :key="sa.id"
          two-line
          :title="sa.assignment.name"
          :subtitle="sa.assignment.dueDate ? `Due ${formatDate(sa.assignment.dueDate)}` : ''"
          :red-subtitle="sa.assignment.dueDate ? overdue(sa) : undefined"
          icon="mdi-clipboard-text"
          :to="clickStudentAssignmentTo(sa)"
        >
          <template #information>
            <mosaic-tooltip-chip v-if="showBudInfo(sa)" variant="outlined" color="secondary" class="mr-2">
              <template #text>
                <mosaic-icon icon="mosaic-bud-icon" />
              </template>

              <template #tooltip> A file has been submitted to Bud for this Activity </template>
            </mosaic-tooltip-chip>
            <mosaic-tooltip-chip v-if="sa.versionNumber && sa.status === 'not_started'" color="accent" class="mr-2">
              <template #text>
                <v-icon>mdi-file-replace-outline</v-icon>
              </template>

              <template #tooltip> This Activity requires changes </template>
            </mosaic-tooltip-chip>
            <assignment-grade-chip :status="sa.status" :grade="sa.reviewPageGradeJudgementDescriptor" class="mr-2" />
            <mosaic-role-completed-chip
              class="pr-2"
              :completed="sa.status === 'completed' || sa.status === 'reviewed'"
              :role-id="sa.assignment.roleId || 'student'"
              :role-scoped-tooltip="(role: Role) => `This Activity is for ${role.pluralisedName}`"
            />
            <assignment-status-chip :status="sa.status" />
          </template>

          <template v-if="canEditEvidence" #actions>
            <mosaic-evidence-icon-btn
              v-model="sa.evidence"
              @click-edit="editEvidence(sa)"
              @click-create="createEvidence(sa)"
              :processing="editEvidenceProcessing"
            />
          </template>
        </mosaic-list-item>
      </template>
    </mosaic-filter-list-page>

    <create-evidence-dialog
      v-model:active="createDialog.active"
      :student-assignment-id="createDialog.studentAssignmentId"
      :title-placeholder="createDialog.title"
      :on-close="() => (triggerBackgroundLoad = true)"
    />

    <edit-evidence-dialog v-model:active="editDialog.active" :evidence="editDialog.evidence" />
  </div>
</template>

<script setup lang="ts">
import MosaicFilterListPage from '@/components/library/pages/MosaicFilterListPage.vue';
import AssignmentStatusChip from './AssignmentStatusChip.vue';
import { computed, ref } from 'vue';
import type { StudentAssignmentStatus } from './student-assignments';
import type { RouteLocationNamedRaw } from 'vue-router';
import type { JudgementDescriptor } from '@/store/map-store';
import AssignmentGradeChip from './AssignmentGradeChip.vue';
import { isOverdue } from '@/mixins/global-mixins';
import CreateEvidenceDialog from '@/components/student/CreateEvidenceDialog.vue';
import EditEvidenceDialog from '@/components/student/EditEvidenceDialog.vue';
import { withProcessingAndError } from '@/composables/processing-and-errors';
import { useApi } from '@/composables/api';
import { filterByString } from '@/components/library/filters/filters';
import type { Role } from '@/store/map-store';
import { mapActions } from '@/store/map-store';

const api = useApi();

// This is needed for the role chips. Roles are loaded within the chip component as well, but the api call is made once per list-item.
// Callin on page load prevents the api call from being made multiple times.
const { loadRoles } = mapActions();
loadRoles();

const props = defineProps<{
  url: string;
  clickStudentAssignmentTo: (studentAssignment: StudentAssignment) => RouteLocationNamedRaw;
  canEditEvidence?: boolean;

  showBud?: boolean;
}>();

interface Evidence {
  id: number;
  title: string;
  evidence_description: string;
  substandards: { id: number; code: string; name: string }[];
  evidence_type:
    | 'Lesson Observation'
    | 'Lesson Plan'
    | 'Reflection'
    | 'Target'
    | 'Resource'
    | 'Mentor Meeting'
    | 'Course Activity'
    | 'Other';
  student_assignment?: { id: number; name: string };
}

interface StudentAssignment {
  id: number;
  status: StudentAssignmentStatus;
  reviewPageGradeJudgementDescriptor: JudgementDescriptor | null;
  assignment: {
    id: number;
    name: string;
    dueDate: string;
    roleId: number | null;
  };
  evidence?: { id: number };
  versionNumber?: number;
  budSubmission?: {
    id: number;
  };
}

const assignments = ref<StudentAssignment[]>([]);

function overdue(a: StudentAssignment) {
  return a.status != 'completed' && a.status != 'reviewed' && isOverdue(a.assignment.dueDate);
}

const nameFilter = ref('');
const statuses = [
  { text: 'All', value: null },
  { text: 'Not Started', value: 'not_started' },
  { text: 'Started', value: 'started' },
  { text: 'Completed', value: 'completed' },
  { text: 'Reviewed', value: 'reviewed' },
];
const statusFilter = ref<StudentAssignmentStatus | null>(null);
const overdueFilter = ref('All');

const filteredAssignments = computed(() =>
  assignments.value.filter(
    a =>
      filterByString(a.assignment.name, nameFilter.value) &&
      (statusFilter.value == null || statusFilter.value == a.status) &&
      (overdueFilter.value == 'All' ||
        (overdueFilter.value == 'Overdue' && overdue(a)) ||
        (overdueFilter.value == 'Not overdue' && a.assignment.dueDate && !overdue(a)) ||
        (overdueFilter.value == 'No due date' && !a.assignment.dueDate))
  )
);

const triggerBackgroundLoad = ref(false);
async function load() {
  const r = await api.get<StudentAssignment[]>(props.url);
  assignments.value = r.data;
}

// Evidence
const createDialog = ref({ active: false, studentAssignmentId: -1, title: '' });
function createEvidence(studentAssignment: StudentAssignment) {
  createDialog.value = {
    active: true,
    studentAssignmentId: studentAssignment.id,
    title: studentAssignment.assignment.name,
  };
}

const editDialog = ref<{ active: boolean; evidence: Evidence }>({
  active: false,
  evidence: { id: -1, title: '', evidence_description: '', substandards: [], evidence_type: 'Course Activity' },
});
const {
  action: editEvidence,
  processing: editEvidenceProcessing,
  error: editEvidenceError,
} = withProcessingAndError(async (studentAssignment: StudentAssignment) => {
  const r = await api.get<Evidence>(`/evidence/${studentAssignment.evidence!.id}`);
  editDialog.value = {
    active: true,
    evidence: r.data,
  };
});

const showBudInfo = (sa: StudentAssignment) => sa.budSubmission && props.showBud;
</script>
