/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { CrossTabLock } from './cross-tab-lock';
import type { RouteLocation, Router } from 'vue-router';
import moment from 'moment';

const AUTH_TOKEN_KEY = 'auth_token';
const REFRESH_TOKEN_KEY = 'refresh_token';
const REFRESH_TOKEN_PROCESSING_KEY = 'refresh_token_processing';
const REFRESH_TOKEN_TIME_KEY = 'refresh_token_time';
const REFRESH_TOKEN_LOCK_KEY = 'refresh_token_lock';

export function getAuthToken() {
  return localStorage.getItem(AUTH_TOKEN_KEY);
}

export function getAuthHeader() {
  return { Authorization: `Bearer ${getAuthToken()}` };
}

function now() {
  return new Date().getTime();
}

export function setTokens(tokens: { auth_token: string; refresh_token: string | null }) {
  localStorage.setItem(AUTH_TOKEN_KEY, tokens.auth_token);
  if (tokens.refresh_token) {
    localStorage.setItem(REFRESH_TOKEN_KEY, tokens.refresh_token);
    localStorage.setItem(REFRESH_TOKEN_TIME_KEY, now().toString());
  } else {
    localStorage.removeItem(REFRESH_TOKEN_KEY);
  }
}

function clearTokens() {
  localStorage.removeItem(AUTH_TOKEN_KEY);
  localStorage.removeItem(REFRESH_TOKEN_KEY);
}

export function isLoggedIn() {
  return !!getAuthToken(); // Check expiry here?
}

export function login(email: string, password: string, store: any) {
  return axios
    .post('/auth/login', { email: email, password: password, frontend_app_version: store.getters.appVersionInteger })
    .then(response => {
      setTokens(response.data);
      return response;
    });
}

export async function loginUserWithToken(token: string, store: any) {
  const response = await axios.post('/auth/token', { token, frontend_app_version: store.getters.appVersionInteger });
  setTokens(response.data);
  return response.data.next_step;
}

export function logout(store: any) {
  return axios
    .post('/auth/logout', { refresh_token: localStorage.getItem(REFRESH_TOKEN_KEY) }, { headers: getAuthHeader() })
    .then(
      () => clearLocalData(store),
      () => clearLocalData(store)
    );
}

function clearLocalData(store: any) {
  clearTokens();
  store.dispatch('clearUser');
  sessionStorage.removeItem('password');
}

async function sleep(milliseconds: number) {
  return new Promise(r => setTimeout(r, milliseconds));
}

export async function refreshAuthToken(store: any) {
  type TokenResult = 'doRefresh' | 'tokenAlreadyRefreshed' | 'wait';
  let tokenResult: TokenResult = 'wait';
  const timeoutTime = moment().add(30, 'seconds');

  while (tokenResult === 'wait') {
    tokenResult = await CrossTabLock.awaitLock(REFRESH_TOKEN_LOCK_KEY, () => {
      // It's possible for the tab to be stuck waiting if the processing key isn't cleared by the refreshing tab
      if (isProcessingRefresh() && moment().isBefore(timeoutTime)) {
        return 'wait';
      }

      if (tokenRefreshedRecently()) return 'tokenAlreadyRefreshed';

      setRefreshAsProcessing();
      return 'doRefresh';
    });
    if (tokenResult === 'wait') await sleep(500);
  }

  if (tokenResult === 'tokenAlreadyRefreshed') return;

  try {
    await axios
      .post('auth/refresh', {
        refresh_token: localStorage.getItem(REFRESH_TOKEN_KEY),
        frontend_app_version: store.getters.appVersionInteger,
      })
      .then(response => {
        if (response.data.version) {
          store.commit('updateVersionsAndAppMessages', {
            version: response.data.version,
            breakingVersions: response.data.breaking_versions,
            appMessages: response.data.app_messages,
          });
        }
        setTokens(response.data);
      });
  } finally {
    await CrossTabLock.awaitLock(REFRESH_TOKEN_LOCK_KEY, () => {
      setRefreshAsNotProcessing();
    });
  }
}

function isProcessingRefresh(): boolean {
  return !!localStorage.getItem(REFRESH_TOKEN_PROCESSING_KEY);
}

function setRefreshAsProcessing() {
  localStorage.setItem(REFRESH_TOKEN_PROCESSING_KEY, 'true');
}

function setRefreshAsNotProcessing() {
  localStorage.removeItem(REFRESH_TOKEN_PROCESSING_KEY);
}

function tokenRefreshedRecently(): boolean {
  const refreshTime = parseInt(localStorage.getItem(REFRESH_TOKEN_TIME_KEY) || '');
  if (Number.isNaN(refreshTime)) return false;

  const tenSeconds = 10000;
  return now() - refreshTime < tenSeconds;
}

export function signup(
  name: string,
  email: string,
  password: string,
  passwordConfirmation: string,
  onSuccess: (response: unknown) => void,
  onFailure: (error: unknown) => void
) {
  axios
    .post('/signup', { name: name, email: email, password: password, passwordConfirmation: passwordConfirmation })
    .then(response => {
      setTokens(response.data);
      onSuccess(response);
    })
    .catch(onFailure);
}

export function impersonate(authToken: string) {
  setTokens({ auth_token: authToken, refresh_token: null });
  window.location.replace('/');
}

export function isImpersonating() {
  const authToken = localStorage.getItem(AUTH_TOKEN_KEY);
  if (!authToken) return false;
  const decoded = parseJwt(authToken);
  return decoded['impersonator_id'];
}

function parseJwt(token: string) {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    console.log('Unable to parseJwt', token, e);
    // I don't think this should return null, but want to investigate an issue first https://penrose-education.sentry.io/share/issue/a334f9c8aff24c0a8e9a0f254968be55/
    // So have added the logging above to try and debug the next time it's raised
    return null;
  }
}

export async function routeToNextStep(nextStep: string, router: Router, route: RouteLocation) {
  try {
    if (nextStep === 'Home') {
      sessionStorage.removeItem('password');
      if (route.query.redirect) {
        await router.push({ path: route.query.redirect as string });
      } else {
        await router.push({ name: 'Home' });
      }
    } else {
      await router.push({ name: nextStep, query: { redirect: route.query.redirect } });
    }
  } catch (e) {
    console.log(e);
    // catch navigationGuard errors
  }
}
