import { DateTime } from 'luxon';
import moment from 'moment';

export function fromNowOrNow(date?: string) {
  if (!date || Math.abs(moment().diff(moment(date))) < 3000) {
    return 'just now';
  }
  return moment(date).fromNow();
}

export function isSameOrAfter(date1: string, date2: string) {
  return moment(date1).isSameOrAfter(moment(date2));
}

export function dateIsInclusiveBetween(
  dateToCheck: string | undefined,
  startDate: string | null,
  endDate: string | null
) {
  const momentDateToCheck = moment(dateToCheck);
  if (startDate === null && endDate === null) return true;
  else if (startDate === null) {
    return !momentDateToCheck.isAfter(endDate);
  } else if (endDate === null) {
    return !momentDateToCheck.isBefore(startDate);
  } else return !(momentDateToCheck.isBefore(startDate) || momentDateToCheck.isAfter(endDate));
}

export function todayFallsWithin(startDate: string, endDate: string) {
  return dateIsInclusiveBetween(undefined, startDate, endDate);
}

export function isOverdue(date: string) {
  const nowDate = moment().startOf('day');
  return moment(date).isBefore(nowDate);
}

export function formatDateTime(dateTime: string) {
  if (!dateTime) return '';
  return DateTime.fromISO(dateTime).toFormat('dd/MM/y h:mm a');
}

export function splitIntoLocalDateAndTime(dateTime: string) {
  const local = DateTime.fromISO(dateTime).toLocal();

  const localDate = local.set({ hour: 0, minute: 0 });
  // date is a dateTime here as that's what MosaicDatePicker uses - arguably MosaicDatePicker should use a date
  return { date: `${localDate.toISODate()}T00:00:00.000Z`, time: `${local.hour}:${local.minute}` };
}

export function dateTimeIsInThePast(dateTime: string) {
  return DateTime.fromISO(dateTime).diffNow().milliseconds < 0;
}

export function toISOString(date: string, time: string) {
  const d = DateTime.fromISO(date);
  const [hours, minutes] = time.split(':');
  return DateTime.local(d.year, d.month, d.day, parseInt(hours), parseInt(minutes)).toUTC().toISO();
}
