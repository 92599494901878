<template>
  <div>
    <mosaic-loading-and-error-cards object-type="Course Activity" :load="load">
      <assignment-card
        v-if="studentAssignment && sectionId"
        v-model:student-assignment="studentAssignment"
        v-model:section-id="sectionId"
        :can-review="false"
        :can-mark-as-reviewed="false"
        :can-edit-assignment="studentAssignment.assignment.isTraineeContributor"
        file-upload-presign-url="/presign/assignment-files"
        :version-number="versionNumber"
        :latest-version-number="latestVersionNumber"
        :routing-data="routingData"
        @click-section="onClickSection($event)"
        @update:dirty="dirty = $event"
      />
    </mosaic-loading-and-error-cards>
    <unsaved-changes-dialog
      v-model:unsaved-changes-dialog="dialog"
      object-type="Course Activity Page"
      :custom-leave-action="changeSection"
    ></unsaved-changes-dialog>
  </div>
</template>

<script setup lang="ts">
import AssignmentCard from './AssignmentCard.vue';
import UnsavedChangesDialog from '@/components/UnsavedChangesDialog.vue';
import { parseRouteId } from '@/composables/vue-router';
import { setupAssignmentPage } from './student-assignments';
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { useApi } from '@/composables/api';

const api = useApi();

const route = useRoute();

const routingData = computed(() => ({
  namePrefix: 'Student',
  params: {
    id: assignmentId.value.toString(),
  },
}));

const versionNumber = computed(() => {
  return studentAssignment.value?.versionNumber ?? null;
});
const latestVersionNumber = computed(() => {
  return studentAssignment.value?.latestVersionNumber ?? null;
});

const assignmentId = parseRouteId('id');
const routeVersionNumber = computed(() => route.params.versionNumber || null);

const { studentAssignment, sectionId, load, dirty, dialog, onClickSection, changeSection } = setupAssignmentPage(
  api,
  'StudentAssignmentsListPage',
  `/student-assignments/${assignmentId.value}`,
  true,
  routeVersionNumber
);
</script>
